import { defineMessages } from 'react-intl';

export default defineMessages({
	name: {
		id: 'creatorstudio.components.FormInstance.name',
		defaultMessage: 'Name',
	},
	slug: {
		id: 'creatorstudio.components.FormInstance.slug',
		defaultMessage: 'Slug/URL',
	},
	website: {
		id: 'creatorstudio.components.FormInstance.website',
		defaultMessage: 'Website',
	},
	description: {
		id: 'creatorstudio.components.FormInstance.description',
		defaultMessage: 'Description',
	},
	image: {
		id: 'creatorstudio.components.FormInstance.image',
		defaultMessage: 'Image',
	},
	upload: {
		id: 'creatorstudio.components.FormInstance.upload',
		defaultMessage: 'Upload a image',
	},
	locale: {
		id: 'creatorstudio.components.FormInstance.locale',
		defaultMessage: 'Locale',
	},
	internalBrand: {
		id: 'creatorstudio.components.FormInstance.internalBrand',
		defaultMessage: 'Internal Brand',
	},
	save: {
		id: 'creatorstudio.components.FormInstance.save',
		defaultMessage: 'SAVE',
	},
	discard: {
		id: 'creatorstudio.components.FormInstance.discard',
		defaultMessage: 'DISCARD',
	},
	delete: {
		id: 'creatorstudio.components.FormInstance.delete',
		defaultMessage: 'DELETE',
	},
	replace: {
		id: 'creatorstudio.components.FormInstance.replace',
		defaultMessage: 'REPLACE',
	},
	nameErr: {
		id: 'creatorstudio.components.FormInstance.nameErr',
		defaultMessage: '* Name cannot be empty',
	},
	bioErr: {
		id: 'creatorstudio.components.FormInstance.bioErr',
		defaultMessage: '* Bio cannot be empty',
	},
	imageErr: {
		id: 'creatorstudio.components.FormInstance.imageErr',
		defaultMessage: '* Image cannot be empty',
	},
});
