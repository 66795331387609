import React from 'react';
import AsyncSelect from 'react-select/async';
import T from 'prop-types';
import { DropdownOptionContainer, CenteredText } from './styles';

const AsyncSingleSelectWithDefault = (props) => {
	const {
		placeholder, handleChange, value, options, onBlur, disabled, profile, defaultOptionsList,
	} = props;
	const renderImage = (profile, data) => {
		let url = null;
		if (profile === 'author') {
			if (data.profilePicture) {
				url = data.profilePicture;
			}
		} else if (profile === 'influencer') {
			if (data.profilePicture && data.profilePicture.url) {
				({ url } = data.profilePicture);
			}
		} else if (profile === 'user') {
			if (data.profile_image) {
				url = data.profile_image;
			}
		} else if (data.logo) {
			url = data.logo;
		} else if (data.imagelocation) {
			url = data.imagelocation;
		}
		if (url) {
			return <img src={url} height={32} width={32} alt={data.label} />;
		}
		return null;
	};

	const Option = (props) => {
		Option.propTypes = {
			innerProps: T.object,
			data: T.object,
		};
		const {
			innerProps,
			data,
		} = props;
		return (
			<DropdownOptionContainer {...innerProps} className="p-2" title={profile === 'influencer' ? data.username : ''}>
				<CenteredText>{data.label}</CenteredText>
				{renderImage(profile, data)}
			</DropdownOptionContainer>
		);
	};

	return (
		<div>
			<AsyncSelect
				components={profile ? { Option } : null}
				className="basic-single "
				classNamePrefix="select"
				isClearable
				isSearchable
				placeholder={placeholder}
				value={value}
				defaultOptions={defaultOptionsList}
				loadOptions={options}
				onChange={(value) => handleChange(value)}
				onBlur={onBlur}
				isDisabled={disabled}
				onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}
			/>
		</div>
	);
};

AsyncSingleSelectWithDefault.defaultProps = {
	disabled: false,
};

AsyncSingleSelectWithDefault.propTypes = {
	placeholder: T.string,
	handleChange: T.func,
	value: T.object,
	options: T.func,
	onBlur: T.func,
	disabled: T.bool,
	profile: T.string,
	defaultOptionsList: T.array || T.bool,
};

export default AsyncSingleSelectWithDefault;
