import {
	FETCH_PROFILES_BY_STATUS, FETCH_PROFILES_BY_STATUS_SUCCESS, FETCH_PROFILES_BY_STATUS_FAILURE, SET_DESK_PAGE, SET_DESK_PROFILES_STATUS, UPDATE_DESK_PROFILE_STATUS, UPDATE_DESK_PROFILE_STATUS_SUCCESS, UPDATE_DESK_PROFILE_STATUS_FAILURE, DISABLE_DESK_SEARCH_FLAG, RUN_PROFILE_DESK_SEARCH, RUN_PROFILE_DESK_SEARCH_SUCCESS, RUN_PROFILE_DESK_SEARCH_FAILURE, SET_ALTERNATE_PROFILE_ID,
} from './type';

export function fetchDeskProfilesByStatus(status, page, profileType) {
	return {
		type: FETCH_PROFILES_BY_STATUS,
		status,
		page,
		profileType,
	};
}

export function fetchDeskProfilesByStatusSuccess(profiles) {
	return {
		type: FETCH_PROFILES_BY_STATUS_SUCCESS,
		profiles,
	};
}

export function fetchDeskProfilesByStatusFailure(error) {
	return {
		type: FETCH_PROFILES_BY_STATUS_FAILURE,
		error,
	};
}

export function setDeskPage(page) {
	return {
		type: SET_DESK_PAGE,
		page,
	};
}

export function setDeskProfilesStatus(status) {
	return {
		type: SET_DESK_PROFILES_STATUS,
		status,
	};
}

export function updateDeskProfileStatus(profile, newStatus, page, profileType, alternateProfileId) {
	return {
		type: UPDATE_DESK_PROFILE_STATUS,
		profile,
		newStatus,
		page,
		profileType,
		alternateProfileId,
	};
}

export function updateDeskProfileStatusSuccess(responseMessage) {
	return {
		type: UPDATE_DESK_PROFILE_STATUS_SUCCESS,
		responseMessage,
	};
}

export function updateDeskProfileStatusFailure(error) {
	return {
		type: UPDATE_DESK_PROFILE_STATUS_FAILURE,
		error,
	};
}

export function disableDeskSearchFlag() {
	return {
		type: DISABLE_DESK_SEARCH_FLAG,
	};
}

export function runDeskSearch(searchType, searchValue, status, limit, profileType) {
	return {
		type: RUN_PROFILE_DESK_SEARCH,
		status,
		searchType,
		searchValue,
		limit,
		profileType,
	};
}

export function runDeskSearchSuccess(searchResults) {
	return {
		type: RUN_PROFILE_DESK_SEARCH_SUCCESS,
		searchResults,
	};
}

export function runDeskSearchFailure(error) {
	return {
		type: RUN_PROFILE_DESK_SEARCH_FAILURE,
		error,
	};
}

export function setAlternateProfileId(id) {
	return {
		type: SET_ALTERNATE_PROFILE_ID,
		id,
	};
}
