import { Map } from 'immutable';
import {
	ADD_NEW_AWARD,
	AWARD_ELEMENT_ON_CHANGE,
	CREATE_AWARD,
	CREATE_AWARD_SUCCESS,
	CREATE_AWARD_ERROR,
	SELECT_AWARD,
	UNMOUNT_AWARD_FORM_MESSAGE,
	SET_AWARD_SLUG_UNIQUE_VALUE,
	UPDATE_AWARD,
	UPDATE_AWARD_ERROR,
	UPDATE_AWARD_SUCCESS,
	DELETE_AWARD,
	DELETE_AWARD_ERROR,
	DELETE_AWARD_SUCCESS,
	ADD_SECONDARY_SPONSOR,
	REMOVE_SECONDARY_SPONSOR,
	CLEAR_AWARD_MANAGER_DATA,
} from './type';

import {
	AWARD_CREATE_LOAD_MESSAGE,
	AWARD_CREATE_SUCCESS_MESSAGE,
	AWARD_ERROR_MESSAGE,
	AWARD_DELETE_LOAD_MESSAGE,
	AWARD_DELETE_SUCCESS_MESSAGE,
	AWARD_UPDATE_SUCCESS_MESSAGE,
	AWARD_UPDATE_LOAD_MESSAGE,
} from './constants';

const secondarySponsorObj = {
	name: '',
	website: '',
	logo: {},
	image: null,
};

const initialState = Map({
	operation: null,
	formData: {
		name: '',
		slug: '',
		description: '',
		logo: {},
		banner: {},
		primarySponsor: null,
		secondarySponsors: [{ ...secondarySponsorObj }],
		type: 0,
		date: '',
		dateFormat: 'DATE',
	},
	selectedAward: null,
	uniqueSlug: false,
	formMessage: null,
	loading: false,
});

function awardReducer(state = initialState, action) {
	switch (action.type) {
	case SELECT_AWARD: {
		const { selectedAward } = action;
		if (!selectedAward || Object.keys(selectedAward).length < 1) {
			const form = {
				name: '',
				slug: '',
				description: '',
				logo: { url: null },
				banner: { url: null },
				primarySponsor: null,
				secondarySponsors: [{ ...secondarySponsorObj }],
				type: 0,
				date: '',
				dateFormat: 'DATE',
			};
			return state
				.set('operation', null)
				.set('selectedAward', null)
				.set('uniqueSlug', true)
				.set('formData', form)
				.set('dropdownTitle', null);
		}

		const form = {
			name: action.selectedAward.name,
			slug: action.selectedAward.slug,
			description: action.selectedAward.description,
			logo: action.selectedAward.logo ? action.selectedAward.logo : { url: null },
			banner: action.selectedAward.banner ? action.selectedAward.banner : { url: null },
			subtitle: action.selectedAward.subtitle ? action.selectedAward.subtitle : null,
			type: action.selectedAward.awardType ? action.selectedAward.awardType : 0,
			primarySponsor: action.selectedAward.primarySponsor ? action.selectedAward.primarySponsor : {},
			secondarySponsors: action.selectedAward.secondarySponsors ? action.selectedAward.secondarySponsors : [{ ...secondarySponsorObj }],
			date: action.selectedAward.date ? action.selectedAward.date : null,
			dateFormat: action.selectedAward.dateFormat ? action.selectedAward.dateFormat : 'DATE',
		};
		return state
			.set('selectedAward', action.selectedAward)
			.set('formData', form)
			.set('operation', 'edit')
			.set('uniqueSlug', true)
			.set('formMessage', null)
			.set('dropdownTitle', action.selectedAward);
	}
	case ADD_NEW_AWARD: {
		const formData = {
			name: '',
			slug: '',
			description: '',
			logo: { url: null },
			banner: { url: null },
			primarySponsor: {
				name: '', website: '', logo: { url: null }, image: null,
			},
			secondarySponsors: [{ ...secondarySponsorObj }],
			type: 0,
			date: '',
			dateFormat: 'DATE',
		};
		return state
			.set('operation', 'add')
			.set('formData', formData)
			.set('selectedAward', null)
			.set('dropdownTitle', null)
			.set('uniqueSlug', false)
			.set('formMessage', null);
	}
	case ADD_SECONDARY_SPONSOR: {
		const newState = { ...state.get('formData') };
		newState.secondarySponsors.push(secondarySponsorObj);
		return state
			.set('formData', newState);
	}
	case REMOVE_SECONDARY_SPONSOR: {
		const newState = { ...state.get('formData') };
		newState.secondarySponsors.splice(action.index, 1);
		return state
			.set('formData', newState);
	}
	case AWARD_ELEMENT_ON_CHANGE: {
		const newState = { ...state.get('formData') };
		if (action.elementData.name === 'secondarySponsor') {
			newState.secondarySponsors[action.index] = { ...newState.secondarySponsors[action.index], ...action.elementData.value };
		} else {
			newState[action.elementData.name] = action.elementData.value;
			if (action.elementData.name === 'name') {
				const slug = (action.elementData.value).replace(/ /g, '-').replace(/[^A-Za-z0-9-]/g, '').toLowerCase();
				newState.slug = slug;
			}
			if (action.elementData.name === 'slug') {
				const slug = (action.elementData.value).replace(/ /g, '-').replace(/[^A-Za-z0-9-]/g, '');
				newState.slug = slug;
			}
		}
		return state
			.set('formData', newState);
	}
	case CREATE_AWARD:
		return state
			.set('formMessage', AWARD_CREATE_LOAD_MESSAGE)
			.set('loading', true);
	case CREATE_AWARD_SUCCESS: {
		return state
			.set('selectedAward', null)
			.set('formMessage', AWARD_CREATE_SUCCESS_MESSAGE)
			.set('loading', false);
	}
	case CREATE_AWARD_ERROR:
		return state
			.set('formMessage', AWARD_ERROR_MESSAGE)
			.set('loading', false);
	case UNMOUNT_AWARD_FORM_MESSAGE:
		return state
			.set('formMessage', null);
	case SET_AWARD_SLUG_UNIQUE_VALUE:
		return state
			.set('uniqueSlug', action.value);
	case UPDATE_AWARD:
		return state
			.set('formMessage', AWARD_UPDATE_LOAD_MESSAGE)
			.set('loading', true);
	case UPDATE_AWARD_SUCCESS:
		return state
			.set('selectedAward', null)
			.set('formMessage', AWARD_UPDATE_SUCCESS_MESSAGE)
			.set('loading', false);
	case UPDATE_AWARD_ERROR:
		return state
			.set('formMessage', AWARD_ERROR_MESSAGE)
			.set('loading', false);
	case DELETE_AWARD:
		return state
			.set('formMessage', AWARD_DELETE_LOAD_MESSAGE)
			.set('loading', true);
	case DELETE_AWARD_SUCCESS:
		return state
			.set('selectedAward', null)
			.set('formMessage', AWARD_DELETE_SUCCESS_MESSAGE)
			.set('loading', false);
	case DELETE_AWARD_ERROR:
		return state
			.set('formMessage', AWARD_ERROR_MESSAGE)
			.set('loading', false);
	case CLEAR_AWARD_MANAGER_DATA:
		return initialState;
	default:
		return state;
	}
}

export default awardReducer;
