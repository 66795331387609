import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-styled-flexboxgrid';
import { ListItemWrapper, Button } from '../BoniManager/BoniBrandManagerList/styles';
import 'rc-pagination/assets/index.css';
import ConfirmationModal from '../FormComponents/ConfirmationModal';

const CommentsList = (props) => {
	const [isTrue, setIsTrue] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState('');
	const [activateStatus, setActivateStatus] = useState('UNPUBLISHED');
	const [activateTitle, setActivateTitle] = useState(false);
	const [showToolTipMessage, setShowToolTipMessage] = useState(false);
	const [showToolTipMessageVal, setShowToolTipMessageVal] = useState(null);
	const [reqId, setReqId] = useState(id);
	const SAVING_MESSAGE = { intent: 'info', message: 'Saving data...' };
	const SAVING_SUCCESS_MESSAGE = { intent: 'success', message: 'Data Saved Successfully' };
	const SAVING_FAILURE_MESSAGE = { intent: 'danger', message: 'Data Save Failed. Something went wrong. Try Again!' };

	const {
		id,
		slNo,
		commentItem,
		editComment,
		handleActions,
		userData,
		selectedStatusOfComment,
	} = props;

	const handleActivateModal = () => {
		setActivateTitle(true);
		setIsTrue(!isTrue);
		setActivateStatus(!activateStatus);
	};

	const handleModal = () => {
		setIsTrue(!isTrue);
		setActivateTitle(false);
	};

	const handleConfirm = ({ method, id }) => {
		setShowToolTipMessage(true);
		const callBack = () => {
			setIsTrue(!isTrue);
			setShowToolTipMessage(false);
			setShowToolTipMessageVal(null);
		};
		const callBackFromToolTipMessage = (value) => {
			if (value === 1) {
				setShowToolTipMessageVal(SAVING_MESSAGE);
			} else if (value === 2) {
				setShowToolTipMessageVal(SAVING_SUCCESS_MESSAGE);
			} else if (value === 3) {
				setShowToolTipMessageVal(SAVING_FAILURE_MESSAGE);
			}
		};
		handleActions({
			id: id || reqId,
			method: method || (activateTitle ? 'PATCH' : 'DELETE'),
			selectStatus: selectedStatus.status,
			callBack,
			callBackFromToolTipMessage,
		});
	};

	const handleCancel = () => {
		setIsTrue(!isTrue);
	};

	return (
		<>
			<ListItemWrapper id={slNo} style={{ padding: '15px 0' }} className="py-2">
				<Col xs={false} md={1} className="text-center">
					<span>{slNo}.</span>
				</Col>
				<Col xs={6} md={3} className="text-center">
					<span>{commentItem.name || 'N/A'}</span>
				</Col>
				<Col xs={false} md={2} className="text-center">
					<span>{commentItem.designation || 'N/A'}</span>
				</Col>
				<Col xs={false} md={3} className="text-center">
					<span>{commentItem.comment || 'N/A'}</span>
				</Col>
				<Col xs={false} md={1} className="text-center">
					<span>{commentItem?.createdByUser?.name || 'Not Found'}</span>
				</Col>
				<Col xs={false} md={2} className="text-center">
					{
						// action.map((data, index) =>
						<div className="action">
							{(userData.roles.liveFeedCardManager === 2 && selectedStatusOfComment !== 'DRAFT') && <Button
								className
								title={`${commentItem.status === 'PUBLISHED' ? 'UNPUBLISHED' : 'PUBLISHED'}`}
								onClick={() => {
									handleActivateModal();
									setReqId(id);
									setSelectedStatus({ status: commentItem.status === 'PUBLISHED' ? 'UNPUBLISHED' : 'PUBLISHED' });
								}}
							>
								{
									commentItem.status === 'PUBLISHED'
										? <i className="fa fa-times-circle fa-lg" />
										: <i className="fas fa-check-circle  fa-lg" />
								}
							</Button>}
							<Button
								onClick={() => {
									editComment();
								}}
							>
								<i className="fa fa-pencil-square fa-lg" />
							</Button>
							{userData.roles.liveFeedCardManager === 2 && <Button
								onClick={() => {
									handleModal();
									setReqId(id);
								}}
							>
								<i className="fas fa-trash fa-lg" />
							</Button>}
						</div>
					}

				</Col>
			</ListItemWrapper>

			<ConfirmationModal
				openDialog={isTrue}
				handleCancel={handleCancel}
				closeDialog={handleCancel}
				confirm={handleConfirm}
				closeToolTipMessage={() => {
					setShowToolTipMessage(false);
					setShowToolTipMessageVal(null);
				}}
				showToolTipMessage={showToolTipMessage}
				showToolTipMessageVal={showToolTipMessageVal}
				message={
					activateTitle
						? `Are you sure you would like to ${selectedStatusOfComment === 'PUBLISHED'
							? 'UNPUBLISHED'
							: 'PUBLISHED'} the selected comment ? `
						: 'Are you sure you would like to delete the selected comment ? This action is irreversible.'
				}
			/>
		</>
	);
};
CommentsList.propTypes = {
	id: PropTypes.string,
	slNo: PropTypes.number,
	commentItem: PropTypes.object,
	handleActions: PropTypes.any,
	editComment: PropTypes.func,
	userData: PropTypes.object,
	selectedStatusOfComment: PropTypes.string,
};

export default CommentsList;
