export const FETCH_JOB_DATA = 'FETCH_JOB_DATA';
export const FETCH_JOB_DATA_SUCCESS = 'FETCH_JOB_DATA_SUCCESS';
export const FETCH_JOB_DATA_FAILURE = 'FETCH_JOB_DATA_FAILURE';

export const CREATE_JOB = 'CREATE_JOB';
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS';
export const CREATE_JOB_FAILURE = 'CREATE_JOB_FAILURE';

export const UPDATE_JOB = 'UPDATE_JOB';
export const UPDATE_JOB_SUCCESS = 'UPDATE_JOB_SUCCESS';
export const UPDATE_JOB_FAILURE = 'UPDATE_JOB_FAILURE';

export const UPDATE_JOB_FROM_DATA = 'UPDATE_JOB_FROM_DATA';
export const UPDATE_FORM_MESSAGE = 'UPDATE_FORM_MESSAGE';
export const SHOW_CONFIRMATION_MODAL = 'SHOW_CONFIRMATION_MODAL';

export const HANDLE_BLUR = 'HANDLE_BLUR';
export const SET_ALL_ELEMENTS_BLUR = 'SET_ALL_ELEMENTS_BLUR';
export const JOB_EDITOR_HAS_ERRORS = 'JOB_EDITOR_HAS_ERRORS';

export const SET_JOB_EDITOR_DOC_CONVERSION_DATA = 'SET_JOB_EDITOR_DOC_CONVERSION_DATA';
export const UPDATE_JOB_EDITOR_DOC_CONVERSION_DATA = 'UPDATE_JOB_EDITOR_DOC_CONVERSION_DATA';
