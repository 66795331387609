import {
	LOAD_ALIASES, LOAD_ALIASES_SUCCESS, LOAD_ALIASES_ERROR, LOAD_FILTERED_ALIASES, LOAD_FILTERED_ALIASES_ERROR, LOAD_FILTERED_ALIASES_SUCCESS, PROFILE_SELECT, UPDATE_PROFILE, SAVE_ALIAS, SAVE_ALIAS_SUCCESS, SAVE_ALIAS_ERROR, DISCARD_CHANGES, CHANGE_DROPDOWN, CLEAR_UPDATE_ALIAS_DATA, UNMOUNT_ALIAS_FORM_MESSAGE,
} from './type';

export function loadAliasesList(value, searchValue) {
	return {
		type: LOAD_ALIASES,
		value,
		searchValue,
	};
}

export function aliasesLoadedSuccessfully(aliases) {
	return {
		type: LOAD_ALIASES_SUCCESS,
		aliases,
	};
}

export function aliasesLoadingError(error) {
	return {
		type: LOAD_ALIASES_ERROR,
		error,
	};
}

export function loadFilteredAliases(pageNo, dropDown) {
	return {
		type: LOAD_FILTERED_ALIASES,
		pageNo,
		dropDown,
	};
}

export function filteredAliasesLoadedSuccessfully(aliases) {
	return {
		type: LOAD_FILTERED_ALIASES_SUCCESS,
		aliases,
	};
}

export function filteredAliasesLoadingError(error) {
	return {
		type: LOAD_FILTERED_ALIASES_ERROR,
		error,
	};
}

export function saveProfile(formData) {
	return {
		type: SAVE_ALIAS,
		formData,
	};
}

export function saveProfileSuccessfully(message) {
	return {
		type: SAVE_ALIAS_SUCCESS,
		message,
	};
}

export function saveProfileError(error) {
	return {
		type: SAVE_ALIAS_ERROR,
		error,
	};
}

export function selectProfile(index) {
	return {
		type: PROFILE_SELECT,
		index,
	};
}

export function updateProfile(variableName, value) {
	return {
		type: UPDATE_PROFILE,
		variableName,
		value,
	};
}

export function discardProfile() {
	return {
		type: DISCARD_CHANGES,
	};
}

export function dropDownChange(variableName, value) {
	return {
		type: CHANGE_DROPDOWN,
		variableName,
		value,
	};
}

export function setInitialState() {
	return {
		type: CLEAR_UPDATE_ALIAS_DATA,
	};
}

export function unmountAliasFormMessage() {
	return {
		type: UNMOUNT_ALIAS_FORM_MESSAGE,
	};
}
