import { Map } from 'immutable';
import {
	HANDLE_BLUR,
	SET_ALL_ELEMENTS_BLUR,
	UPDATE_ORG_CHART_FROM_DATA,
	FETCH_ORG_CHART_DATA_SUCCESS,
	UPDATE_FORM_MESSAGE,
	CREATE_ORG_CHART,
	CREATE_ORG_CHART_FAILURE,
	CREATE_ORG_CHART_SUCCESS,
	UPDATE_ORG_CHART,
	UPDATE_ORG_CHART_SUCCESS,
	UPDATE_ORG_CHART_FAILURE,
	SHOW_CONFIRMATION_MODAL,
	ORG_CHART_EDITOR_HAS_ERRORS,
	UPDATE_PEOPLE_PROFILE_DATA,
	SET_ALL_PROFILE_ELEMENTS_BLUR,
	PROFILE_HAS_ERRORS,
	CREATE_PEOPLE_PROFILE,
	CREATE_PEOPLE_PROFILE_SUCCESS,
	CREATE_PEOPLE_PROFILE_FAILURE,
	UPDATE_PEOPLE_FORM_MESSAGE,
	FETCH_PEOPLE_PROFILE_ORG_CHART_DATA_SUCCESS,
	FETCH_PEOPLE_PROFILE_ORG_CHART_DATA_FAILURE,
	FETCH_ORG_CHART_DATA_FAILURE,
} from './type';
import {
	CREATE_MESSAGE,
	CREATE_SUCCESS_MESSAGE,
	CREATE_FAILURE_MESSAGE,
	CREATE_PROFILE_MESSAGE,
	CREATE_PROFILE_SUCCESS_MESSAGE,
	CREATE_PROFILE_FAILURE_MESSAGE,
	UPDATE_SUCCESS_MESSAGE,
	UPDATE_FAILURE_MESSAGE,
} from './constants';

const initialState = Map({
	formData: {
		name: '',
		slug: '',
		company: [],
	},
	peopleProfile: {
		name: '',
		designation: '',
		image: {},
		reportsTo: '',
		orgChartId: '',
		subordinateCount: 0,
		level: 0,
	},
	peopleTouched: {
		name: false,
		designation: false,
		image: false,
		reportsTo: false,
	},
	formTouched: {
		name: false,
		slug: false,
		company: false,
	},
	hasErrors: {
		name: false,
		slug: false,
		company: false,
	},
	peopleHasErrors: {
		name: false,
		designation: false,
		image: false,
		reportsTo: false,
	},
	formMessage: null,
	peopleFormMessage: null,
	peopleProfileModal: false,
	addPeoplePosition: [],
	subOrdinateData: [],
	boardOfDirectors: [],
	composerOrgChartData: {},
	bodOrgChartData: [],
});

function orgChartEditorReducer(state = initialState, action) {
	switch (action.type) {
	case UPDATE_ORG_CHART_FROM_DATA: {
		const { changedData } = action;
		const formData = { ...state.get('formData') };
		formData[changedData.name] = changedData.value;
		return state
			.set('formData', formData);
	}

	case FETCH_ORG_CHART_DATA_SUCCESS:
		return state
			.set('formData', action.orgChart)
			.set('boardOfDirectors', [...action.orgChart.bod])
			.set('bodOrgChartData', [...action.orgChart.bod])
			.set('composerOrgChartData', { name: action.orgChart.name, id: action.orgChart.id })
			.set('addPeoplePosition', [...action.orgChart.peoplesProfile]);

	case FETCH_PEOPLE_PROFILE_ORG_CHART_DATA_SUCCESS:
		return state
			.set('subOrdinateData', action.subordinateOrgData);
	case FETCH_PEOPLE_PROFILE_ORG_CHART_DATA_FAILURE:
		return state
			.set('subOrdinateData', []);
	case CREATE_ORG_CHART:
		return state
			.set('formMessage', CREATE_MESSAGE);

	case CREATE_ORG_CHART_SUCCESS:
		return state
			.set('formMessage', CREATE_SUCCESS_MESSAGE);

	case CREATE_ORG_CHART_FAILURE:
		return state
			.set('formMessage', CREATE_FAILURE_MESSAGE);

	case UPDATE_ORG_CHART:
		return state
			.set('formMessage', UPDATE_SUCCESS_MESSAGE)
			.set('showConfirmationModal', true);
	case UPDATE_ORG_CHART_SUCCESS:
		return state
			.set('formMessage', UPDATE_SUCCESS_MESSAGE)
			.set('showConfirmationModal', false);

	case UPDATE_ORG_CHART_FAILURE:
		return state
			.set('formMessage', UPDATE_FAILURE_MESSAGE)
			.set('showConfirmationModal', false);

	case UPDATE_FORM_MESSAGE:
		return state
			.set('formMessage', action.message);

	case SHOW_CONFIRMATION_MODAL:
		return state
			.set('showConfirmationModal', action.value);

	case HANDLE_BLUR: {
		const touched = { ...state.get('formTouched') };
		touched[action.element] = true;
		return state
			.set('formTouched', touched);
	}

	case UPDATE_PEOPLE_FORM_MESSAGE:
		return state
			.set('peopleFormMessage', action.message);

	case SET_ALL_ELEMENTS_BLUR: {
		const touched = {
			name: true,
			slug: true,
			company: true,
		};
		return state
			.set('formTouched', touched);
	}

	case SET_ALL_PROFILE_ELEMENTS_BLUR: {
		const touched = {
			name: true,
			designation: true,
			image: true,
			reportsTo: true,
		};
		return state
			.set('peopleTouched', touched);
	}

	case CREATE_PEOPLE_PROFILE:
		return state
			.set('peopleFormMessage', CREATE_PROFILE_MESSAGE);

	case CREATE_PEOPLE_PROFILE_SUCCESS:
		return state
			.set('peopleFormMessage', CREATE_PROFILE_SUCCESS_MESSAGE)
			.set('peopleProfileModal', false);

	case CREATE_PEOPLE_PROFILE_FAILURE:
		return state
			.set('peopleFormMessage', CREATE_PROFILE_FAILURE_MESSAGE)
			.set('peopleProfileModal', false);

	case UPDATE_PEOPLE_PROFILE_DATA: {
		const { changedData } = action;
		const peopleProfile = { ...state.get('peopleProfile') };
		peopleProfile[changedData.name] = changedData.value;
		return state
			.set('peopleProfile', peopleProfile);
	}

	case ORG_CHART_EDITOR_HAS_ERRORS:
		return state
			.set('hasErrors', action.errors);

	case PROFILE_HAS_ERRORS:
		return state
			.set('peopleHasErrors', action.errors);

	case FETCH_ORG_CHART_DATA_FAILURE:
		return state
			.set('formData', {})
			.set('boardOfDirectors', [])
			.set('addPeoplePosition', [])
			.set('bodOrgChartData', [])
			.set('composerOrgChartData', {});

	default:
		return state;
	}
}

export default orgChartEditorReducer;
