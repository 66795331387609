import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-styled-flexboxgrid';
import { ListItemWrapper, Button } from '../BoniManager/BoniBrandManagerList/styles';
import 'rc-pagination/assets/index.css';
import ConfirmationModal from '../FormComponents/ConfirmationModal';

const PWA_BASE_URL = process.env.REACT_APP_SERVICE_PWA;

const TopicsListData = (props) => {
	const [isTrue, setIsTrue] = useState(false);
	const [showToolTipMessage, setShowToolTipMessage] = useState(false);
	const [showToolTipMessageVal, setShowToolTipMessageVal] = useState(null);
	const [reqId, setReqId] = useState(id);
	const SAVING_MESSAGE = { intent: 'info', message: 'Saving data...' };
	const SAVING_SUCCESS_MESSAGE = { intent: 'success', message: 'Data Deleted Successfully' };
	const SAVING_FAILURE_MESSAGE = { intent: 'danger', message: 'Data Deletion Failed. Something went wrong. Try Again!' };

	const {
		id,
		slNo,
		topicItem,
		editTopic,
		handleActions,
	} = props;

	const handleModal = () => {
		setIsTrue(!isTrue);
	};

	const handleConfirm = () => {
		setShowToolTipMessage(true);
		const callBack = () => {
			setIsTrue(!isTrue);
			setShowToolTipMessage(false);
			setShowToolTipMessageVal(null);
		};
		const callBackFromToolTipMessage = (value) => {
			if (value === 1) {
				setShowToolTipMessageVal(SAVING_MESSAGE);
			} else if (value === 2) {
				setShowToolTipMessageVal(SAVING_SUCCESS_MESSAGE);
			} else if (value === 3) {
				setShowToolTipMessageVal(SAVING_FAILURE_MESSAGE);
			}
		};
		handleActions({
			id: reqId,
			method: 'DELETE',
			callBack,
			callBackFromToolTipMessage,
		});
	};

	const handleCancel = () => {
		setIsTrue(!isTrue);
	};

	const getSubDescription = (description) => {
		let subDescription = description.replace(/<[^>]+>/g, '').match(/\S+/g);
		subDescription = subDescription.slice(0, 10).toString().replaceAll(',', ' ');
		return `<p class="truncateTextToOneLine">${subDescription}</p>`;
	};

	return (
		<>
			<ListItemWrapper id={slNo} style={{ padding: '15px 0' }} className="py-2 topicManager">
				<Col xs={false} md={1} className="text-center">
					<span>{slNo}.</span>
				</Col>
				<Col xs={6} md={3} className="text-center">
					<span>{topicItem?.name || 'N/A'}</span>
				</Col>
				<Col xs={false} md={4} className="text-center">
					<span dangerouslySetInnerHTML={{ __html: getSubDescription(topicItem.description.replaceAll('&nbsp;', ' ')) || 'N/A' }} />
				</Col>
				{/* <Col xs={false} md={3} className="text-center">
					<span>{topicItem?.comment || 'N/A'}</span>
				</Col> */}
				<Col xs={false} md={2} className="text-center">
					<span>{topicItem?.createdByUser?.name || 'Not Found'}</span>
				</Col>
				<Col xs={false} md={2} className="text-center">
					{
						// action.map((data, index) =>
						<div className="action">
							<Button className="transparentAnchorTag">
								<a href={`${PWA_BASE_URL}/topic/${topicItem.slug}`} target="_blank" rel="noreferrer">
									<i className="fa fa-search fa-lg" title="Preview" />
								</a>
							</Button>
							<Button
								onClick={() => {
									editTopic();
								}}
							>
								<i className="fa fa-pencil-square fa-lg" />
							</Button>
							<Button
								onClick={() => {
									handleModal();
									setReqId(id);
								}}
							>
								<i className="fas fa-trash fa-lg" />
							</Button>
						</div>
					}

				</Col>
			</ListItemWrapper>
			<ConfirmationModal
				openDialog={isTrue}
				handleCancel={handleCancel}
				closeDialog={handleCancel}
				confirm={handleConfirm}
				closeToolTipMessage={() => {
					setShowToolTipMessage(false);
					setShowToolTipMessageVal(null);
				}}
				showToolTipMessage={showToolTipMessage}
				showToolTipMessageVal={showToolTipMessageVal}
				message="Are you sure you would like to delete the selected topic ? This action is irreversible."
			/>
		</>
	);
};
TopicsListData.propTypes = {
	id: PropTypes.string,
	slNo: PropTypes.number,
	topicItem: PropTypes.object,
	handleActions: PropTypes.any,
	editTopic: PropTypes.func,
};

export default TopicsListData;
