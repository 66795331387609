/* eslint-disable no-useless-escape */
import React, {
	useState, useEffect, useReducer, useRef,
} from 'react';
import Modal from 'react-modal';
// import styled from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import { Button, Input } from '../Styles';
import ToastMessage from '../ToastMessage';
import {
	COMPANY_API_BASE_URL, SAVING_FAILURE_MESSAGE, SAVING_MESSAGE, SAVING_SUCCESS_MESSAGE,
} from '../../containers/CompanyEditor/constants';
import { generateCompanySlug } from '../../containers/CompanyEditor/functions';
import { validateImage, validateImageFormat } from '../../utils/common';
import ImageHolder from './ImageHolder';

const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
const UPDATE_ERRORS = 'UPDATE_ERRORS';
const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
const CLEAR_DATA = 'CLEAR_DATA';

const initialState = {
	formData: {
		name: null,
		slug: null,
		description: null,
		logo: null,
		website: null,
		socialLinks: {
			facebook: null,
			twitter: null,
			linkedin: null,
			wikipedia: null,
		},
	},
	blurredElements: {
		name: false,
		slug: false,
		description: false,
		website: false,
		subtitle: false,
		// logo: false
	},
	errors: {
		name: false,
		slug: false,
		description: false,
		subtitle: false,
		// logo: false
		website: false,
	},
	formMessage: null,
};

function updateFormData(changedData) {
	return {
		type: UPDATE_FORM_DATA,
		changedData,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function setFormMessage(message) {
	return {
		type: SET_FORM_MESSAGE,
		message,
	};
}

function clearData() {
	return {
		type: CLEAR_DATA,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case UPDATE_FORM_DATA: {
		const formData = { ...state.formData };
		const { changedData } = action;
		if (['facebook', 'twitter', 'linkedin', 'wikipedia'].includes(changedData.name)) {
			if (!formData.socialLinks) formData.socialLinks = {};
			formData.socialLinks[changedData.name] = changedData.value;
		} else {
			formData[changedData.name] = changedData.value;
		}
		return { ...state, formData };
	}

	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };

	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			name: true,
			slug: true,
			description: true,
			website: true,
			subtitle: true,
			// logo: true
		};
		return { ...state, blurredElements };
	}

	case SET_FORM_MESSAGE:
		return { ...state, formMessage: action.message };

	case CLEAR_DATA: {
		const newState = {
			...initialState,
			formData: {
				...initialState.formData,
				socialLinks: {
					facebook: null,
					twitter: null,
					linkedin: null,
					wikipedia: null,
				},
			},
		};
		return newState;
	}

	default:
		return state;
	}
}

function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

const CompanyForm = (props) => {
	const {
		open, close, addedCompany, hoc,
	} = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		formData, blurredElements, errors, formMessage,
	} = state;
	const [isButtonDisabled, disableButton] = useState(false);
	const [uniqueSlug, setSlugIsUnique] = useState(true);
	const prevIsButtonDisabled = usePrevious(isButtonDisabled);

	useEffect(() => {
		getErrors();
	}, [formData, blurredElements]);

	useEffect(() => {
		async function checkSlug() {
			if (formData.slug && formData.slug.length > 0) {
				const isSlugUnique = await isCompanySlugUnique();
				setSlugIsUnique(isSlugUnique);
			}
		}
		checkSlug();
	}, [formData.slug]);

	useEffect(() => {
		if (!prevIsButtonDisabled && isButtonDisabled) {
			createCompany();
		}
	}, [isButtonDisabled]);

	function onInputChange(e) {
		const changedData = {
			name: e.target.name,
			value: e.target.value,
		};
		return dispatch(updateFormData(changedData));
	}

	function validateForm(company) {
		return {
			name: !company.name || company.name.length < 1,
			slug: !company.slug || company.slug.length < 1,
			description: company.description && company.description.length > 2000,
			subtitle: !company.subtitle || company.subtitle.length < 30 || company.subtitle.length > 250,
			// logo: !company.logo || !company.logo.url || company.logo.url.length < 1
			website: !company.website || company.website.length < 1,
		};
	}

	function handleBlur(element) {
		dispatch(updateBlurredElement(element));
		if (element === 'name' && (formData.name && formData.name.length > 0 && (!formData.slug || formData.slug.length < 1))) {
			const slug = generateCompanySlug(formData.name);
			dispatch(updateFormData({
				name: 'slug',
				value: slug,
			}));
		}
	}

	function getErrors() {
		const validationErrors = validateForm(formData);
		const errors = Object.keys(validationErrors).reduce((acc, curr) => {
			if (validationErrors[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	}

	async function onFormSubmit(e) {
		e.preventDefault();
		const validation = validateForm(formData);
		const validated = !Object.keys(validation).some((i) => validation[i]);
		if (validated) {
			const isSlugUnique = await isCompanySlugUnique();
			if (isSlugUnique) {
				disableButton(true);
			} else {
				setSlugIsUnique(false);
			}
		} else {
			dispatch(setAllElementsBlurred());
		}
	}

	async function createCompany() {
		try {
			dispatch(setFormMessage(SAVING_MESSAGE));
			const payload = getRequestBody();
			const URL = `${COMPANY_API_BASE_URL}/create?source=${hoc}`;
			const options = {
				method: 'POST',
				credentials: 'include',
				body: payload,
			};
			const response = await fetch(URL, options);
			const parsed = await response.json();
			disableButton(false);
			if (parsed.success) {
				const company = {
					value: parsed.data.Company.id,
					label: parsed.data.Company.name,
				};
				dispatch(setFormMessage(SAVING_SUCCESS_MESSAGE));
				addedCompany(company);
				closeModal();
			} else {
				dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			}
		} catch (err) {
			dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			disableButton(false);
		}
	}

	async function isCompanySlugUnique() {
		try {
			const url = `/api/v2/rw/companies/unique-slug/${formData.slug || ''}`;
			const response = await fetch(url, {
				method: 'GET',
				credentials: 'include',
			});
			const isSlugUnique = await response.json();
			return isSlugUnique;
		} catch (err) {
			console.log('slug unique check error', err);
		}
	}

	function getRequestBody() {
		const payload = new FormData();
		payload.append('name', formData.name);
		payload.append('slug', formData.slug);
		payload.append('description', formData.description);
		payload.append('subtitle', formData.subtitle);
		payload.append('socialLinks', JSON.stringify(formData.socialLinks));
		payload.append('website', formData.website);
		if (formData.logo && formData.logo.file) {
			payload.append('imageFile', formData.logo.file);
			payload.append('logo', JSON.stringify({
				width: formData.logo.width,
				height: formData.logo.height,
			}));
		}
		if (hoc === 'company-editor') {
			payload.append('status', 'PUBLISHED');
		} else {
			payload.append('status', 'INREVIEW');
		}
		return payload;
	}

	function getSlugErrorMessage() {
		const { slug } = formData;
		if (slug && slug.length > 0 && slug.match(/[A-Z!@#$%^&*(),.?":{}|/<>_';`~\[\]\\+=\ ]/g)) {
			return '* Slug contains invalid characters';
		}
		return '* Slug cannot be empty';
	}

	async function onUpload(e) {
		e.preventDefault();
		const file = e.target.files[0];
		if (file.size / 1024 / 1024 > 5) {
			return alert(`File size greater than 5 MB (${(file.size / 1024 / 1024).toFixed(2)} MB)!`);
		}
		if (await validateImageFormat(file)) {
			const reader = new FileReader();
			reader.onloadend = () => {
				dispatch(updateFormData({
					name: 'logo',
					value: {
						url: reader.result,
						file,
					},
				}));
			};
			reader.readAsDataURL(file);
		}
	}

	function onUploadClick(e) {
		e.preventDefault();
		const logoInput = document.getElementById('company-logo');
		logoInput.click();
	}

	function onImageLoad(e) {
		if (formData.logo.file) {
			const data = { height: e.target.naturalHeight, width: e.target.naturalWidth };
			if (!validateImage(data.height, data.width)) {
				document.getElementById('company-logo').value = '';
				dispatch(updateFormData({
					name: 'logo',
					value: {
						url: null,
						file: null,
					},
				}));
			} else {
				dispatch(updateFormData({
					name: 'logo',
					value: {
						...formData.logo,
						...data,
					},
				}));
			}
		}
	}

	function closeModal() {
		dispatch(clearData());
		close();
	}

	function renderFormMessage() {
		if (formMessage) {
			return (
				<ToastMessage
					toastData={formMessage}
					unmount={() => dispatch(setFormMessage(null))}
				/>
			);
		}
		return null;
	}

	function renderSlugErrorMessage() {
		if (formData.slug && formData.slug.length > 0 && !uniqueSlug) {
			return <p className="error error-text text-normal">
				* This slug already exists. Pick a unique slug
			</p>;
		}
		if (errors.slug) {
			return <p className="error error-text text-normal">
				{getSlugErrorMessage()}
			</p>;
		}
	}

	function getErrorMessage(element) {
		if (element === 'subtitle') {
			const { subtitle } = formData;
			if (!subtitle || subtitle.length < 1) {
				return '* Subtitle cannot be empty';
			} if (subtitle.length > 0 && subtitle.length < 30) {
				return '* Subtitle must have a minimum length of 30 characters';
			} if (subtitle.length > 250) {
				return '* Subtitle cannot have a length of more than 250 characters';
			}
		}
		if (element === 'description') {
			const { description } = formData;
			if (description.length > 2000) {
				return '* Description cannot have a length of more than 2000 characters';
			}
		}
	}

	return (
		<Modal
			isOpen={open}
			onRequestClose={closeModal}
			contentLabel="Add Company"
			overlayClassName="modal-overlay"
			className="form-modal"
		>
			<div className="w_100 mb-4 text-right">
				<button type="button" onClick={closeModal}>
					<i className="fas fa-times light-text disablePointerEvents" />
				</button>
			</div>
			<h2>Add Company</h2>
			<div>
				{renderFormMessage()}
				<form onSubmit={onFormSubmit}>
					<Row>
						<Col xs={12} sm={6}>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Name *
								</Col>
								<Col xs={12} sm={9}>
									<Input
										name="name"
										type="text"
										className="form-control"
										placeholder="Name of the company"
										value={formData && formData.name ? formData.name : ''}
										onChange={onInputChange}
										onBlur={() => handleBlur('name')}
									/>
									{
										errors.name
											? <p className="error error-text text-normal">
												* Name cannot be empty
											</p>
											: null
									}
								</Col>
							</Row>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Slug *
								</Col>
								<Col xs={12} sm={9}>
									<Input
										name="slug"
										type="text"
										className="form-control"
										placeholder="Unique slug for the company"
										value={formData && formData.slug ? formData.slug : ''}
										onChange={onInputChange}
										onBlur={() => handleBlur('slug')}
									/>
									{renderSlugErrorMessage()}
								</Col>
							</Row>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Subtitle *
								</Col>
								<Col xs={12} sm={9}>
									<textarea
										rows="6"
										className="global-form-control"
										name="subtitle"
										placeholder="Subtitle for the company"
										value={formData && formData.subtitle ? formData.subtitle : ''}
										onChange={onInputChange}
										onBlur={() => handleBlur('subtitle')}
										minLength="30"
										maxLength="250"
									/>
									<div style={{ textAlign: 'right', color: '#aaa' }}>
										{formData && formData.subtitle ? formData.subtitle.length : 0}/250
									</div>
									{
										errors.subtitle
											? <p className="error error-text text-normal">
												{getErrorMessage('subtitle')}
											</p>
											: null
									}
								</Col>
							</Row>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Description
								</Col>
								<Col xs={12} sm={9}>
									<textarea
										rows="6"
										className="global-form-control"
										name="description"
										placeholder="Description about the company"
										value={formData && formData.description ? formData.description : ''}
										onChange={onInputChange}
										onBlur={() => handleBlur('description')}
										maxLength="2000"
									/>
									<div style={{ textAlign: 'right', color: '#aaa' }}>
										{formData && formData.description ? formData.description.length : 0}/2000
									</div>
									{
										errors.description
											? <p className="error error-text text-normal">
												{getErrorMessage('description')}
											</p>
											: null
									}
								</Col>
							</Row>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Website *
								</Col>
								<Col xs={12} sm={9}>
									<Input
										name="website"
										type="url"
										className="form-control"
										placeholder="Website of the company"
										value={formData && formData.website ? formData.website : ''}
										onChange={onInputChange}
										onBlur={() => handleBlur('website')}
									/>
									{
										errors.website
											? <p className="error error-text text-normal">
												* Website cannot be empty
											</p>
											: null
									}
								</Col>
							</Row>
						</Col>
						<Col xs={12} sm={6} className="pl-3">
							<Col xs={12} sm={8} className="ml-0 pl-0">
								<div className="mb-3">
									<div>
										Company Logo
									</div>
									<Input
										id="company-logo"
										type="file"
										style={{ display: 'none' }}
										name="logo"
										onChange={onUpload}
										accept="image/png, image/jpeg"
									/>
									<ImageHolder
										showImageUploader={onUploadClick}
										imageUrl={formData && formData.logo ? formData.logo.url : null}
										onImageLoad={onImageLoad}
										squareDimension="14rem"
									/>
									{/* {
																				errors.logo
																						? <p className="error error-text text-normal">
																								* Company logo cannot be empty
																		</p>
																						: null
																		} */}
								</div>
							</Col>

							<p><b>Social Links:</b></p>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Facebook
								</Col>
								<Col xs={12} sm={9}>
									<Input
										name="facebook"
										type="url"
										className="form-control"
										placeholder="URL of company's Facebook page"
										value={formData && formData.socialLinks.facebook ? formData.socialLinks.facebook : ''}
										onChange={onInputChange}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Twitter
								</Col>
								<Col xs={12} sm={9}>
									<Input
										name="twitter"
										type="url"
										className="form-control"
										placeholder="URL of company's Twitter page"
										value={formData && formData.socialLinks.twitter ? formData.socialLinks.twitter : ''}
										onChange={onInputChange}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									LinkedIn
								</Col>
								<Col xs={12} sm={9}>
									<Input
										name="linkedin"
										type="url"
										className="form-control"
										placeholder="URL of company's LinkedIn page"
										value={formData && formData.socialLinks.linkedin ? formData.socialLinks.linkedin : ''}
										onChange={onInputChange}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Wikipedia
								</Col>
								<Col xs={12} sm={9}>
									<Input
										name="wikipedia"
										type="url"
										className="form-control"
										placeholder="URL of company's Wikipedia page"
										value={formData && formData.socialLinks.wikipedia ? formData.socialLinks.wikipedia : ''}
										onChange={onInputChange}
									/>
								</Col>
							</Row>
						</Col>

					</Row>

					<div className="w_100 text-right">
						<Button
							type="submit"
							success
							no_radius
							disabled={isButtonDisabled}
							style={{ width: '100px' }}
						>
							<b>SAVE</b>
						</Button>
					</div>
				</form>
			</div>
		</Modal>
	);
};

CompanyForm.propTypes = {
	open: T.bool,
	close: T.func,
	addedCompany: T.func,
	hoc: T.string,
};

export default CompanyForm;

// const ImageWrapper = styled.div`
//		 padding-left: 2rem;
//		 @media (max-width: 768px) {
//				 padding-left: 0;
//		 }
// `;
