import React, { useEffect } from 'react';
import Props from 'prop-types';
import { Row, Col } from 'react-styled-flexboxgrid';
import { WrapperContainer } from '../../components/Styles';
import ContactInfoAndProfileBioPage from './ContactInfoAndProfileBio';
import EducationHistoryProfilePage from './EducationHistory';
import MoreAboutYouProfilePage from './MoreAboutYou';
import WorkExperienceProfilePage from './WorkExperience';
import NewJobPreferencesPage from './NewJobPreferences';
import { Button } from '../../components/WebinarComponents/styles';

const EditAdminJobResumePage = (props) => {
	const { pageTitle } = props;
	const [selectedStatus, setSelectedStatus] = React.useState('Contact Info and Profile Bio');
	useEffect(() => {
		pageTitle('editAdminJobResumePage');
	});

	const onStatusChange = (event) => {
		setSelectedStatus(event);
	};

	return (
		<WrapperContainer>
			<Row>
				<Col onClick={() => history.go(-1)}>
					<Button view title="Go Back">
						<i className="fa fa-arrow-circle-left fa-lg" />
					</Button>
				</Col>
				<Col xs={12} md={12}>
					<Row className="mt-3 storylist-tabs">
						<Col xs={1.7} className={`nav-text ${selectedStatus === 'Contact Info and Profile Bio' ? 'nav-b-a' : 'nav-b'}`} onClick={() => onStatusChange('Contact Info and Profile Bio')}>Contact Info and Profile Bio</Col>
						<Col xs={1.7} className={`nav-text ${selectedStatus === 'Education' ? 'nav-b-a' : 'nav-b'}`} onClick={() => onStatusChange('Education')}>Education </Col>
						<Col xs={1.7} className={`nav-text ${selectedStatus === 'Work Experience' ? 'nav-b-a' : 'nav-b'}`} onClick={() => onStatusChange('Work Experience')}>Work Experience</Col>
						<Col xs={1.7} className={`nav-text ${selectedStatus === 'New Job Preferences' ? 'nav-b-a' : 'nav-b'}`} onClick={() => onStatusChange('New Job Preferences')}>New Job Preferences </Col>
						<Col xs={1.7} className={`nav-text ${selectedStatus === 'More about you' ? 'nav-b-a' : 'nav-b'}`} onClick={() => onStatusChange('More about you')}>More about you </Col>
					</Row>
					<Row className="mt-4">
						<Col xs={12}>
							{selectedStatus === 'Contact Info and Profile Bio' && <ContactInfoAndProfileBioPage {...props} />}
							{selectedStatus === 'Education' && <EducationHistoryProfilePage {...props} />}
							{selectedStatus === 'Work Experience' && <WorkExperienceProfilePage {...props} />}
							{selectedStatus === 'New Job Preferences' && <NewJobPreferencesPage {...props} />}
							{selectedStatus === 'More about you' && <MoreAboutYouProfilePage {...props} />}
						</Col>
					</Row>
				</Col>
			</Row>
		</WrapperContainer>
	);
};

EditAdminJobResumePage.propTypes = {
	pageTitle: Props.func,
	// history: Props.object,
};
export default EditAdminJobResumePage;
