import {
	FETCH_STORY_LIST, FETCH_STORY_LIST_SUCCESS, FETCH_STORY_LIST_ERROR, SET_STORY_LIST_STORY_STATUS, UPDATE_STORYLIST_STORYITEM_STATUS, UPDATE_STORYLIST_STORYITEM_STATUS_SUCCESS, UPDATE_STORYLIST_STORYITEM_STATUS_ERROR, FETCH_STORYLIST_BRAND, FETCH_STORYLIST_BRAND_SUCCESS, FETCH_STORYLIST_BRAND_FAILURE, SET_STORY_LIST_SELECTED_BRAND, SET_STORY_LIST_PAGE,
} from './type';

export function fetchStoryList(brand, status, page, storyType) {
	return {
		type: FETCH_STORY_LIST,
		brand,
		status,
		page,
		storyType,
	};
}

export function fetchStoryListSuccess(stories) {
	return {
		type: FETCH_STORY_LIST_SUCCESS,
		stories,
	};
}

export function fetchStoryListError(error) {
	return {
		type: FETCH_STORY_LIST_ERROR,
		error,
	};
}

export function setStoryListStatus(status) {
	return {
		type: SET_STORY_LIST_STORY_STATUS,
		status,
	};
}

export function setStoryListBrand(brand) {
	return {
		type: SET_STORY_LIST_SELECTED_BRAND,
		brand,
	};
}

export function changeStoryListStoryItemStatus(story, newStatus) {
	return {
		type: UPDATE_STORYLIST_STORYITEM_STATUS,
		story,
		newStatus,
	};
}

export function changeStoryListStoryItemStatusSuccess(responseMessage) {
	return {
		type: UPDATE_STORYLIST_STORYITEM_STATUS_SUCCESS,
		responseMessage,
	};
}

export function changeStoryListStoryItemStatusError(error) {
	return {
		type: UPDATE_STORYLIST_STORYITEM_STATUS_ERROR,
		error,
	};
}

export function fetchStoryListBrand() {
	return {
		type: FETCH_STORYLIST_BRAND,
	};
}

export function fetchStoryListBrandSuccess(brands) {
	return {
		type: FETCH_STORYLIST_BRAND_SUCCESS,
		brands,
	};
}

export function fetchStoryListBrandFailure(error) {
	return {
		type: FETCH_STORYLIST_BRAND_FAILURE,
		error,
	};
}

export function setStoryListPage(page) {
	return {
		type: SET_STORY_LIST_PAGE,
		page,
	};
}
