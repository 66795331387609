import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { FETCH_PROFILES, RUN_PROFILE_LIST_SEARCH } from './type';
import { COMPANY_DESK_BASE_API_URL } from './constants';
import {
	fetchDeskProfilesSuccess, fetchDeskProfilesFailure, runProfileListSearchSuccess, runProfileListSearchFailure,
} from './actions';
import { PEOPLE_API_BASE_URL } from '../PeopleManager/constants';

export function* fetchProfilesByLimitAndOffset(action) {
	const url = `${action.profileType === 'company' ? COMPANY_DESK_BASE_API_URL : PEOPLE_API_BASE_URL}/${action.limit}/${action.offset}`;
	const data = new FormData();
	data.append('sortBy', action.sortBy);
	data.append('orderBy', action.orderBy);

	if (action.searchValue) data.append('searchValue', action.searchValue);

	const options = {
		method: 'POST',
		credentials: 'include',
		body: data,
	};

	try {
		const response = yield call(fetch, url, options);

		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const allProfiles = yield response.json();
			yield put(fetchDeskProfilesSuccess(allProfiles));
		}
	} catch (err) {
		yield put(fetchDeskProfilesFailure(err));
	}
}

export function* filterProfiles(action) {
	const url = `${action.profileType === 'company' ? COMPANY_DESK_BASE_API_URL : PEOPLE_API_BASE_URL}/search`;
	const data = new FormData();
	data.append('type', action.searchType);
	data.append('searchValue', action.searchValue);
	data.append('limit', action.limit / 5);
	data.append('offset', action.offset);
	const options = {
		method: 'POST',
		credentials: 'include',
		body: data,
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 403 || response.status === 401) {
			yield put(push('/unauthorized'));
		} else {
			const results = yield response.json();
			yield put(runProfileListSearchSuccess(results));
		}
	} catch (err) {
		yield put(runProfileListSearchFailure(err));
	}
}

export default function* deskData() {
	yield takeLatest(FETCH_PROFILES, fetchProfilesByLimitAndOffset);
	yield takeLatest(RUN_PROFILE_LIST_SEARCH, filterProfiles);
}
