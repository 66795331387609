import { fromJS } from 'immutable';

import {
	LOAD_PROMOTIONS_START, LOAD_PROMOTIONS_ERROR, LOAD_PROMOTIONS_SUCCESS, LOAD_PROMOTIONS_UPDATE_SUCCESS, UPDATE_DISPLAY_MESSAGE, LOAD_CHATBOTS, LOAD_CHATBOTS_SUCCESS, LOAD_CHATBOTS_ERROR,
} from './type';

const initialState = fromJS({
	loading: false,
	error: false,
	promotions: [],
	showMore: false,
	selectedBrand: null,
	displayMessage: null,
	chatbots: [],
});

function messengerPromotionsManagerReducer(state = initialState, action) {
	switch (action.type) {
	case LOAD_PROMOTIONS_START:
		return state
			.set('loading', true)
			.set('error', false)
			.set('showMore', false);
	case LOAD_PROMOTIONS_SUCCESS:
		return state
			.set('loading', false)
			.set('error', false)
			.set('selectedBrand', action.result.brand)
			.set('displayMessage', null)
			.set('showMore', true)
			.set('promotions', action.result.promotions);
	case LOAD_PROMOTIONS_UPDATE_SUCCESS:
		return state
			.set('loading', false)
			.set('error', false)
			.set('selectedBrand', action.result.brand)
			.set('displayMessage', null)
			.set('showMore', action.result.promotions && action.result.promotions.length > 0)
			.update('promotions', (promotions) => promotions.concat(action.result.promotions));
	case LOAD_PROMOTIONS_ERROR:
		return state
			.set('error', action.error)
			.set('loading', false)
			.set('showMore', false);
	case UPDATE_DISPLAY_MESSAGE:
		return state
			.set('displayMessage', action.message);
	case LOAD_CHATBOTS:
		return state
			.set('loading', true)
			.set('error', false)
			.set('showMore', false);
	case LOAD_CHATBOTS_SUCCESS:
		return state
			.set('loading', false)
			.set('error', false)
			.set('showMore', true)
			.set('chatbots', action.result);
	case LOAD_CHATBOTS_ERROR:
		return state
			.set('loading', false)
			.set('error', action.error)
			.set('showMore', false);
	default:
		return state;
	}
}

export default messengerPromotionsManagerReducer;
