import { Map } from 'immutable';
import {
	ON_MEDIA_FORM_DATA_CHANGE, ON_MEDIA_FILE_SELECTED, CREATE_NEW_ASSET, CREATE_NEW_ASSET_SUCCESS, CREATE_NEW_ASSET_FAILURE, UNMOUNT_MEDIA_FORM_MESSAGE, FETCH_STORIES_BY_MEDIA_ID, FETCH_STORIES_BY_MEDIA_ID_SUCCESS, CLEAR_MEDIA_EDITOR_DATA, UPDATE_MEDIA, UPDATE_MEDIA_FAILURE, UPDATE_MEDIA_SUCCESS, START_UPLOADING_MEDIA, DISPLAY_MEDIA_PREVIEW, FETCH_MEDIA_ASSET_BY_ID, FETCH_MEDIA_ASSET_BY_ID_SUCCESS, FETCH_MEDIA_ASSET_BY_ID_FAILURE,
} from './type';
import {
	MEDIA_CREATE_LOAD_MESSAGE, MEDIA_CREATE_SUCCESS_MESSAGE, MEDIA_ERROR_MESSAGE, MEDIA_UPDATE_LOAD_MESSAGE, MEDIA_UPDATE_SUCCESS_MESSAGE,
} from './constants';

const initialSelectedFileInfo = {
	file: null,
	videoDuration: null,
	fileName: null,
	fileType: null,
};

const initialPlayerVideoData = {
	id: null,
	thumbnail: null,
	fileSrc: null,
	mediaId: null,
};

const initialMediaFormData = {
	title: null,
	tags: null,
	description: null,
	thumbnail: null,
	brand: 0,
};

const initialState = Map({
	loading: false,
	error: false,
	mediaType: 'VIDEO',
	selectedFileInfo: { ...initialSelectedFileInfo },
	videoPlayerData: { ...initialPlayerVideoData },
	mediaFormData: { ...initialMediaFormData },
	formMessage: null,
	editMedia: null,
	searchValue: null,
	usedInLoading: false,
	showMediaPreview: false,
	fetchLoading: false,
});

function mediaReducer(state = initialState, action) {
	switch (action.type) {
	case ON_MEDIA_FORM_DATA_CHANGE: {
		const newFormData = { ...state.get('mediaFormData') };
		newFormData[action.name] = action.value;
		return state
			.set('mediaFormData', newFormData);
	}
	case ON_MEDIA_FILE_SELECTED:
		return state
			.set('selectedFileInfo', action.fileInfo);
	case CREATE_NEW_ASSET: {
		const formMessage = {
			intent: 'info',
			message: MEDIA_CREATE_LOAD_MESSAGE,
		};
		return state
			.set('loading', true)
			.set('formMessage', formMessage);
	}

	case CREATE_NEW_ASSET_SUCCESS: {
		const formMessage = {
			intent: 'success',
			message: MEDIA_CREATE_SUCCESS_MESSAGE,
		};
		return state
			.set('loading', false)
			.set('formMessage', formMessage)
			.set('mediaFormData', initialMediaFormData)
			.set('selectedFileInfo', initialSelectedFileInfo)
			.set('videoPlayerData', initialPlayerVideoData)
			.set('showMediaPreview', false);
	}

	case CREATE_NEW_ASSET_FAILURE: {
		const formMessage = {
			intent: 'danger',
			message: MEDIA_ERROR_MESSAGE,
		};
		return state
			.set('loading', false)
			.set('formMessage', formMessage);
	}
	case UNMOUNT_MEDIA_FORM_MESSAGE:
		return state
			.set('formMessage', null);
	case FETCH_STORIES_BY_MEDIA_ID:
		return state
			.set('usedInLoading', true);
	case FETCH_STORIES_BY_MEDIA_ID_SUCCESS:
		return state
			.set('usedInStories', action.stories)
			.set('usedInLoading', false);
	case CLEAR_MEDIA_EDITOR_DATA:
		return state
			.set('mediaFormData', initialMediaFormData)
			.set('selectedFileInfo', initialSelectedFileInfo)
			.set('videoPlayerData', initialPlayerVideoData)
			.set('editMedia', null)
			.set('usedInStories', null)
			.set('usedInLoading', false)
			.set('formMessage', null)
			.set('showMediaPreview', false);

	case UPDATE_MEDIA: {
		const formMessage = {
			intent: 'info',
			message: MEDIA_UPDATE_LOAD_MESSAGE,
		};
		return state
			.set('loading', true)
			.set('formMessage', formMessage);
	}

	case UPDATE_MEDIA_SUCCESS: {
		const formMessage = {
			intent: 'success',
			message: MEDIA_UPDATE_SUCCESS_MESSAGE,
		};
		return state
			.set('loading', false)
			.set('formMessage', formMessage);
	}

	case UPDATE_MEDIA_FAILURE: {
		const formMessage = {
			intent: 'danger',
			message: MEDIA_ERROR_MESSAGE,
		};
		return state
			.set('loading', false)
			.set('formMessage', formMessage);
	}

	case START_UPLOADING_MEDIA: {
		const formMessage = {
			intent: 'info',
			message: 'Uploading!!! This may take a while depending on the file size and internet speed',
		};
		return state
			.set('loading', true)
			.set('formMessage', formMessage);
	}

	case DISPLAY_MEDIA_PREVIEW:
		return state
			.set('showMediaPreview', action.value);

	case FETCH_MEDIA_ASSET_BY_ID:
		return state
			.set('fetchLoading', true);

	case FETCH_MEDIA_ASSET_BY_ID_SUCCESS: {
		const { media } = action;
		const mediaFormData = {
			mediaId: media.mediaId,
			title: media.title !== 'null' ? media.title : '',
			tags: media.tags !== 'null' ? media.tags : '',
			description: media.caption !== 'null' ? media.caption : '',
			transcript: media.transcript !== 'null' ? media.transcript : '',
			thumbnail: media.thumbnail,
			brand: media.brand,
		};
		const videoPlayerData = {
			fileSrc: media.slug,
			thumbnail: media.thumbnail,
			duration: media.duration,
			lastUpdated: media.updatedAt,
			mediaId: media.mediaId,
			id: media.id,
		};
		return state
			.set('fetchLoading', false)
			.set('editMedia', action.media)
			.set('mediaFormData', mediaFormData)
			.set('videoPlayerData', videoPlayerData)
			.set('showMediaPreview', true);
	}

	case FETCH_MEDIA_ASSET_BY_ID_FAILURE:
		return state
			.set('fetchLoading', false)
			.set('error', action.error);

	default:
		return state;
	}
}

export default mediaReducer;
