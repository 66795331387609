import { Map } from 'immutable';
import {
	ADD_NEW_VERTICAL,
	VERTICAL_ELEMENT_ON_CHANGE,
	CREATE_VERTICAL,
	CREATE_VERTICAL_SUCCESS,
	CREATE_VERTICAL_ERROR,
	SELECT_VERTICAL,
	UNMOUNT_VERTICAL_FORM_MESSAGE,
	SET_VERTICAL_SLUG_UNIQUE_VALUE,
	UPDATE_VERTICAL,
	UPDATE_VERTICAL_ERROR,
	UPDATE_VERTICAL_SUCCESS,
	DELETE_VERTICAL,
	DELETE_VERTICAL_ERROR,
	DELETE_VERTICAL_SUCCESS,
	CLEAR_VERTICAL_MANAGER_DATA,
} from './type';

import {
	VERTICAL_CREATE_LOAD_MESSAGE,
	VERTICAL_CREATE_SUCCESS_MESSAGE,
	VERTICAL_ERROR_MESSAGE,
	VERTICAL_DELETE_LOAD_MESSAGE,
	VERTICAL_DELETE_SUCCESS_MESSAGE,
	VERTICAL_UPDATE_SUCCESS_MESSAGE,
	VERTICAL_UPDATE_LOAD_MESSAGE,
} from './constants';

const initialState = Map({
	operation: null,
	formData: {
		name: '',
		slug: '',
		description: '',
	},
	selectedVertical: null,
	uniqueSlug: false,
	formMessage: null,
	loading: false,
});

function verticalReducer(state = initialState, action) {
	switch (action.type) {
	case SELECT_VERTICAL: {
		const { selectedVertical } = action;
		if (!selectedVertical || Object.keys(selectedVertical).length < 1) {
			const form = {
				name: '',
				slug: '',
				description: '',
			};
			return state
				.set('operation', null)
				.set('selectedVertical', null)
				.set('uniqueSlug', true)
				.set('formData', form)
				.set('dropdownTitle', null);
		}
		const form = {
			name: action.selectedVertical.name,
			slug: action.selectedVertical.slug,
			description: action.selectedVertical.description,
		};
		return state
			.set('selectedVertical', action.selectedVertical)
			.set('formData', form)
			.set('operation', 'edit')
			.set('uniqueSlug', true)
			.set('formMessage', null)
			.set('dropdownTitle', action.selectedVertical);
	}
	case ADD_NEW_VERTICAL: {
		const formData = {
			name: '',
			description: '',
			slug: '',
		};
		return state
			.set('operation', 'add')
			.set('formData', formData)
			.set('selectedVertical', null)
			.set('dropdownTitle', null)
			.set('uniqueSlug', false)
			.set('formMessage', null);
	}
	case VERTICAL_ELEMENT_ON_CHANGE: {
		const newState = { ...state.get('formData') };
		newState[action.elementData.name] = action.elementData.value;
		if (action.elementData.name === 'name') {
			const slug = (action.elementData.value).replace(/ /g, '-').replace(/[^A-Za-z0-9-]/g, '').toLowerCase();
			newState.slug = slug;
		}
		if (action.elementData.name === 'slug') {
			const slug = (action.elementData.value).replace(/ /g, '-').replace(/[^A-Za-z0-9-]/g, '');
			newState.slug = slug;
		}
		return state
			.set('formData', newState);
	}
	case CREATE_VERTICAL:
		return state
			.set('formMessage', VERTICAL_CREATE_LOAD_MESSAGE)
			.set('loading', true);
	case CREATE_VERTICAL_SUCCESS: {
		return state
			.set('selectedVertical', null)
			.set('formMessage', VERTICAL_CREATE_SUCCESS_MESSAGE)
			.set('loading', false);
	}
	case CREATE_VERTICAL_ERROR:
		return state
			.set('formMessage', VERTICAL_ERROR_MESSAGE)
			.set('loading', false);
	case UNMOUNT_VERTICAL_FORM_MESSAGE:
		return state
			.set('formMessage', null);
	case SET_VERTICAL_SLUG_UNIQUE_VALUE:
		return state
			.set('uniqueSlug', action.value);
	case UPDATE_VERTICAL:
		return state
			.set('formMessage', VERTICAL_UPDATE_LOAD_MESSAGE)
			.set('loading', true);
	case UPDATE_VERTICAL_SUCCESS:
		return state
			.set('selectedVertical', null)
			.set('formMessage', VERTICAL_UPDATE_SUCCESS_MESSAGE)
			.set('loading', false);
	case UPDATE_VERTICAL_ERROR:
		return state
			.set('formMessage', VERTICAL_ERROR_MESSAGE)
			.set('loading', false);
	case DELETE_VERTICAL:
		return state
			.set('formMessage', VERTICAL_DELETE_LOAD_MESSAGE)
			.set('loading', true);
	case DELETE_VERTICAL_SUCCESS:
		return state
			.set('selectedVertical', null)
			.set('formMessage', VERTICAL_DELETE_SUCCESS_MESSAGE)
			.set('loading', false);
	case DELETE_VERTICAL_ERROR:
		return state
			.set('formMessage', VERTICAL_ERROR_MESSAGE)
			.set('loading', false);
	case CLEAR_VERTICAL_MANAGER_DATA:
		return initialState;
	default:
		return state;
	}
}

export default verticalReducer;
