import {
	ADD_NEW_CITY, CREATE_CITY, CITY_ELEMENT_ON_CHANGE, CREATE_CITY_ERROR, CREATE_CITY_SUCCESS, UPDATE_CITY, UPDATE_CITY_ERROR, UPDATE_CITY_SUCCESS, DELETE_CITY, DELETE_CITY_ERROR, DELETE_CITY_SUCCESS, SELECT_CITY, UNMOUNT_CITY_FORM_MESSAGE, SET_CITY_SLUG_UNIQUE_VALUE, CLEAR_CITY_MANAGER_DATA,
} from './type';

export function addNewCity() {
	return {
		type: ADD_NEW_CITY,
	};
}

export function onCityFormElementChange(elementData) {
	return {
		type: CITY_ELEMENT_ON_CHANGE,
		elementData,
	};
}

export function createCity(city) {
	return {
		type: CREATE_CITY,
		city,
	};
}

export function createCitySuccess(successResponse) {
	return {
		type: CREATE_CITY_SUCCESS,
		successResponse,
	};
}

export function createCityFailure(failureResponse) {
	return {
		type: CREATE_CITY_ERROR,
		failureResponse,
	};
}

export function updateCity(id, updatedData) {
	return {
		type: UPDATE_CITY,
		id,
		updatedData,
	};
}

export function updateCitySuccess(successResponse) {
	return {
		type: UPDATE_CITY_SUCCESS,
		successResponse,
	};
}

export function updateCityFailure(failureResponse) {
	return {
		type: UPDATE_CITY_ERROR,
		failureResponse,
	};
}

export function deleteCity(id) {
	return {
		type: DELETE_CITY,
		id,
	};
}

export function deleteCityFailure(failureResponse) {
	return {
		type: DELETE_CITY_ERROR,
		failureResponse,
	};
}

export function deleteCitySuccess(successResponse) {
	return {
		type: DELETE_CITY_SUCCESS,
		successResponse,
	};
}

export function citySelect(selectedCity) {
	return {
		type: SELECT_CITY,
		selectedCity,
	};
}

export function unmountCityFormMessage() {
	return {
		type: UNMOUNT_CITY_FORM_MESSAGE,
	};
}

export function setCitySlugUniqueValue(value) {
	return {
		type: SET_CITY_SLUG_UNIQUE_VALUE,
		value,
	};
}

export function setInitialState() {
	return {
		type: CLEAR_CITY_MANAGER_DATA,
	};
}
