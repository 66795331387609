import {
	FETCH_CUSTOM_FIELDS,
	FETCH_CUSTOM_FIELDS_SUCCESS,
	FETCH_CUSTOM_FIELDS_FAILURE,
	FILTER_CUSTOM_FIELDS,
	FILTER_CUSTOM_FIELDS_SUCCESS,
	FILTER_CUSTOM_FIELDS_FAILURE,
	SET_CUSTOM_FIELDS_PAGE,
	CLEAR_DATA,
} from './type';

export function fetchCustomFields(offset, limit) {
	return {
		type: FETCH_CUSTOM_FIELDS,
		offset,
		limit,
	};
}

export function fetchCustomFieldsSuccess(formElements) {
	return {
		type: FETCH_CUSTOM_FIELDS_SUCCESS,
		formElements,
	};
}

export function fetchCustomFieldsFailure(error) {
	return {
		type: FETCH_CUSTOM_FIELDS_FAILURE,
		error,
	};
}

export function setCustomFieldPage(page) {
	return {
		type: SET_CUSTOM_FIELDS_PAGE,
		page,
	};
}

export function filterCustomFields(searchValue, page) {
	return {
		type: FILTER_CUSTOM_FIELDS,
		searchValue,
		page,
	};
}

export function filterCustomFieldsSuccess(formElements) {
	return {
		type: FILTER_CUSTOM_FIELDS_SUCCESS,
		formElements,
	};
}

export function filterCustomFieldsFailure(error) {
	return {
		type: FILTER_CUSTOM_FIELDS_FAILURE,
		error,
	};
}

export function clearData() {
	return {
		type: CLEAR_DATA,
	};
}
