import styled from 'styled-components';

const StoryResultCard = styled.div`
		background-color: #fafafa;
		min-height: 90px;
		margin-bottom: 18px;
		position: relative;
		cursor: pointer;
		padding: 6px;
		box-shadow: 0px 2px 2px -2px #C8CECA;
`;

const Image = styled.img`
		max-height: 80px;
		width: 100%;
		padding: 5px;
`;

const ImagePlaceHolder = styled.div`
		max-height: 80px;
		width: 100%;
		padding: 5px;
		background-color: #eee;
		text-align: center;
		vertical-align: middle;
		line-height: 80px;
		font-weight: bold;
		color: grey;
		font-size: 0.8rem;
`;

const ModalContent = styled.div`
		background-color: #fff;
		border-radius: 0;
		color: #333;
		margin: 0 auto;
		max-width: 60%;
		min-width: 60%;
		min-height: 92%;
		height: 100%;
		padding: 10px 10px;
		position: relative;
		border-radius : 0px;
		padding: 25px; 
		@media (max-width: 576px) {
		max-width: 320px;	
		}
		margin-bottom: 10px
`;

const Title = styled.div`
		font-weight: bold;
		margin-bottom: 2rem;
`;

const AuthorName = styled.span`
		font-size: 0.8rem;
		font-weight: 500;
		padding-left: 10%;
`;

const Published = styled.span`
		font-size: 0.8rem;
		font-weight: 500;
		padding-left: 10%;		
`;

const BrandName = styled.span`
		font-size: 0.8rem;
		font-weight: 500;
`;

const Detail = styled.div`
`;

const Result = styled.div`
		margin-top: 15px;
		padding: 0px 10px;
`;

const ModalTitle = styled.div`
		font-weight: 600;
`;

const Input = styled.input`
		margin-top: 10px;
		padding: 10px 15px;
		font-size: 1rem;
		display: block;
		width: 100%;
		line-height: 1.5;
		min-height:35px;
		color: #000;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid #ced4da;
		&:focus {
				outline:none;
		}
		::-webkit-input-placeholder {
				color: black;
				font-weight: 600;
		}
		.element::-moz-placeholder {
				color: blue;
				font-weight: 600;				
		}
`;

const ButtonSection = styled.div`
		padding-top: 30px;
`;

const WarningText = styled.span`
		font-size: 0.8rem;
		font-weight: 500;
		color: red;
`;

export {
	ButtonSection, BrandName, StoryResultCard, Image, ModalContent, Title, AuthorName, Published, Detail, Result, ModalTitle, Input, ImagePlaceHolder, WarningText,
};
