import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { ListHeaderWrapper } from '../BoniManager/BoniProductCategory/style';

export const CommentsListHeader = () => (
	<ListHeaderWrapper>
		<Col xs={false} md={1} className="text-center">
			S.No
		</Col>
		<Col xs={6} md={3} className="text-center">
			Title
		</Col>
		<Col xs={false} md={4} className="text-center">
			Description
		</Col>
		{/* <Col xs={false} md={3} className="text-center">
			Comment
		</Col> */}
		{/* <Col xs={false} md={2} className="text-center d-none">
			Brand Category
		</Col>
		<Col xs={false} md={1} className="text-center d-none">
			Brand
		</Col> */}
		<Col xs={false} md={2} className="text-center">
			Created By
		</Col>
		<Col xs={false} md={2} className="text-center">
			Action
		</Col>
	</ListHeaderWrapper>
);

export default CommentsListHeader;
