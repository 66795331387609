import {
	FETCH_ALL_FEED, FETCH_ALL_FEED_SUCCESS, FETCH_ALL_FEED_ERROR, CREATE_FEED, CREATE_FEED_SUCCESS, CREATE_FEED_ERROR, UPDATE_FEED, UPDATE_FEED_SUCCESS, UPDATE_FEED_ERROR, DELETE_FEED, DELETE_FEED_SUCCESS, DELETE_FEED_ERROR, ON_FEED_SELECTED, FEED_ELEMENT_ON_CHANGE, UNMOUNT_FEED_FORM_MESSAGE, ADD_NEW_FEED,
} from './type';

export function fetchAllFeed() {
	return {
		type: FETCH_ALL_FEED,
	};
}

export function fetchAllFeedSuccess(feeds) {
	return {
		type: FETCH_ALL_FEED_SUCCESS,
		feeds,
	};
}

export function fetchAllFeedError(error) {
	return {
		type: FETCH_ALL_FEED_ERROR,
		error,
	};
}

export function createFeed(feed) {
	return {
		type: CREATE_FEED,
		feed,
	};
}

export function createFeedSuccess(successResponse) {
	return {
		type: CREATE_FEED_SUCCESS,
		successResponse,
	};
}

export function createFeedError(error) {
	return {
		type: CREATE_FEED_ERROR,
		error,
	};
}

export function updateFeed(feedId, updatedFeed) {
	return {
		type: UPDATE_FEED,
		feedId,
		updatedFeed,
	};
}

export function updateFeedSucces(updateResponse) {
	return {
		type: UPDATE_FEED_SUCCESS,
		updateResponse,
	};
}

export function updateFeedError(error) {
	return {
		type: UPDATE_FEED_ERROR,
		error,
	};
}

export function deleteFeed(feedId) {
	return {
		type: DELETE_FEED,
		feedId,
	};
}

export function deleteFeedSuccess(deleteResponse) {
	return {
		type: DELETE_FEED_SUCCESS,
		deleteResponse,
	};
}

export function deleteFeedError(error) {
	return {
		type: DELETE_FEED_ERROR,
		error,
	};
}

export function onFeedSelected(selectedFeed) {
	return {
		type: ON_FEED_SELECTED,
		selectedFeed,
	};
}

export function addNewFeed() {
	return {
		type: ADD_NEW_FEED,
	};
}

export function feedElementOnChange(changedData) {
	return {
		type: FEED_ELEMENT_ON_CHANGE,
		changedData,
	};
}

export function unmountFeedFormMessage() {
	return {
		type: UNMOUNT_FEED_FORM_MESSAGE,
	};
}
