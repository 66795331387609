import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { RSS_FEED_CONTENT_BASE_URL } from './constants';
import { RSS_FEED_LIST_BASE_URL } from '../RssFeedCreator/constants';

import {
	fetchFeedContentsByPageSuccess, fetchFeedContentsByPageError, filterWatchlistStoriesError, filterWatchlistStoriesSuccess, fetchAllFeedSourceSuccess, fetchAllFeedSourceError,
} from './action';
import { FETCH_FEED_CONTENTS_BY_PAGE, FILTER_WATCHLIST_STORIES, FETCH_ALL_FEED_SOURCE } from './type';

export function* getFeedContentByPage(action) {
	const url = `${RSS_FEED_CONTENT_BASE_URL}/feedId/${action.feedId}/page/${action.page}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const allFeeds = yield response.json();
			yield put(fetchFeedContentsByPageSuccess(allFeeds));
		}
	} catch (err) {
		yield put(fetchFeedContentsByPageError(err));
	}
}

export function* filterStories(action) {
	const url = `${RSS_FEED_CONTENT_BASE_URL}/filter/${action.page}`;
	const formData = new FormData();
	formData.append('searchType', action.searchType);
	formData.append('searchValue', action.searchValue);
	formData.append('feedId', action.feedId);
	const options = {
		method: 'POST',
		body: formData,
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const filteredStories = yield response.json();
			yield put(filterWatchlistStoriesSuccess(filteredStories));
		}
	} catch (err) {
		yield put(filterWatchlistStoriesError(err));
	}
}

export function* getFeedSource() {
	const url = `${RSS_FEED_LIST_BASE_URL}/`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const allFeeds = yield response.json();
			yield put(fetchAllFeedSourceSuccess(allFeeds));
		}
	} catch (err) {
		yield put(fetchAllFeedSourceError(err));
	}
}

export default function* feedContentData() {
	yield takeLatest(FETCH_FEED_CONTENTS_BY_PAGE, getFeedContentByPage);
	yield takeLatest(FILTER_WATCHLIST_STORIES, filterStories);
	yield takeLatest(FETCH_ALL_FEED_SOURCE, getFeedSource);
}
