import { fromJS } from 'immutable';
import {
	FETCH_STORY_VERSIONS_BY_STORY_ID, FETCH_STORY_VERSIONS_BY_STORY_ID_SUCCESS, FETCH_STORY_VERSIONS_BY_STORY_ID_FAILURE, SET_SELECTED_STORY_VERSION,
} from './type';

const initialState = fromJS({
	loading: false,
	error: false,
	storyVersions: null,
	selectedStoryVersion: null,
	storyVersionMeta: null,
});

function storyVersionReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_STORY_VERSIONS_BY_STORY_ID:
		return state
			.set('loading', true);
	case FETCH_STORY_VERSIONS_BY_STORY_ID_SUCCESS:
		return state
			.set('storyVersions', action.storyVersions.versions)
			.set('selectedStoryVersion', action.storyVersions.versions[0])
			.set('storyVersionMeta', action.storyVersions.info)
			.set('loading', false);
	case FETCH_STORY_VERSIONS_BY_STORY_ID_FAILURE:
		return state
			.set('error', action.error)
			.set('loading', false);
	case SET_SELECTED_STORY_VERSION:
		return state
			.set('selectedStoryVersion', action.selectedVersion);
	default:
		return state;
	}
}

export default storyVersionReducer;
