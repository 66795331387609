import styled from 'styled-components';

export const USER_BASE_API_URL = '/api/v2/rw/user-manager';

export const ImageWrapper = styled.div`
		display: flex;
		justify-content: center;
		.width {
				width: 50%;
				@media (max-width: 768px) {
						width: 100%; 
				}
		}
`;

export const ALIAS_ERROR_MESSAGE = { intent: 'danger', message: 'ERROR!!!' };
export const ALIAS_CREATE_SAVE_MESSAGE = { intent: 'info', message: 'Saving...' };
export const BRAND_LOADING_MESSAGE = { intent: 'info', message: 'Loading...' };
