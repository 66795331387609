export const BRAND_API_BASE_URL = '/api/v2/rw/brands';

export const BRAND_LOADING_MESSAGE = { intent: 'info', message: 'Loading...' };
export const BRAND_CREATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Brand Saved Successfully' };
export const BRAND_CREATE_LOAD_MESSAGE = { intent: 'info', message: 'Saving...' };
export const BRAND_UPDATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Brand Updated Successfully' };
export const BRAND_UPDATE_LOAD_MESSAGE = { intent: 'info', message: 'Updating...' };
export const BRAND_DELETE_SUCCESS_MESSAGE = { intent: 'success', message: 'Brand Deleted Successfully' };
export const BRAND_DELETE_LOADING_MESSAGE = { intent: 'info', message: 'Deleting...' };
export const BRAND_ERROR_MESSAGE = { intent: 'danger', message: 'ERROR!!!' };

export const localeOptions = [
	{ value: 'en_gb', label: 'en_gb' },
	{ value: 'hi', label: 'hi' },
	{ value: 'bn', label: 'bn' },
	{ value: 'or', label: 'or' },
	{ value: 'ta', label: 'ta' },
	{ value: 'pa', label: 'pa' },
	{ value: 'ur', label: 'ur' },
	{ value: 'mr', label: 'mr' },
	{ value: 'kn', label: 'kn' },
	{ value: 'gu', label: 'gu' },
	{ value: 'ml', label: 'ml' },
	{ value: 'te', label: 'te' },
	{ value: 'kr', label: 'kr' },
	{ value: 'as', label: 'as' },
	{ value: 'de', label: 'de' },
	{ value: 'en_us', label: 'en_us' },
];
