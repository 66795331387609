export const SELECT_TAG = 'SELECT_TAG';
export const ADD_TAG = 'ADD_TAG';
export const WRITE_TAG_SLUG = 'WRITE_TAG_SLUG';
export const TAG_ELEMENT_ON_CHANGE = 'TAG_ELEMENT_ON_CHANGE';

export const CREATE_TAG = 'CREATE_TAG';
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';
export const CREATE_TAG_ERROR = 'CREATE_TAG_ERROR';

export const UPDATE_TAG = 'UPDATE_TAG';
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';
export const UPDATE_TAG_ERROR = 'UPDATE_TAG_ERROR';

export const DELETE_TAG = 'DELETE_TAG';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_ERROR = 'DELETE_TAG_ERROR';

export const REPLACE_TAG = 'REPLACE_TAG';
export const REPLACE_TAG_SUCCESS = 'REPLACE_TAG_SUCCESS';
export const REPLACE_TAG_ERROR = 'REPLACE_TAG_ERROR';
export const TAG_REDIRECTION_ERROR = 'TAG_REDIRECTION_ERROR';

export const UNMOUNT_TAG_FORM_MESSAGE = 'UNMOUNT_TAG_FORM_MESSAGE';
export const SET_TAG_SLUG_UNIQUE_VALUE = 'SET_TAG_SLUG_UNIQUE_VALUE';

export const SHOW_TAG_LOADING_MESSAGE = 'SHOW_CATEGORY_LOADING_MESSAGE';
