export const LOAD_ALL_NAMESPACE = 'LOAD_ALL_NAMESPACE';
export const LOAD_ALL_NAMESPACE_SUCCESS = 'LOAD_ALL_NAMESPACE_SUCCESS';
export const LOAD_ALL_NAMESPACE_ERROR = 'LOAD_ALL_NAMESPACE_ERROR';

export const LOAD_CONFIGS_BY_NAMESPACE = 'LOAD_CONFIGS_BY_NAMESPACE';
export const LOAD_CONFIGS_BY_NAMESPACE_SUCCESS = 'LOAD_CONFIGS_BY_NAMESPACE_SUCCESS';
export const LOAD_CONFIGS_BY_NAMESPACE_ERROR = 'LOAD_CONFIGS_BY_NAMESPACE_ERROR';

export const SELECT_NAMESPACE = 'SELECT_NAMESPACE';
export const DISCARD_CONFIG_CHANGES = 'DISCARD_CONFIG_CHANGES';

export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const UPDATE_CONFIG_SUCCESS = 'UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_ERROR = 'UPDATE_CONFIG_ERROR';

export const LOAD_CONFIG_LATEST_UPDATE_TIMESTAMP = 'LOAD_CONFIG_LAST_UPDATE_TIMESTAMP';
export const LOAD_CONFIG_LATEST_UPDATE_TIMESTAMP_SUCCESS = 'LOAD_CONFIG_LAST_UPDATE_TIMESTAMP_SUCCESS';
export const LOAD_CONFIG_LATEST_UPDATE_TIMESTAMP_ERROR = 'LOAD_CONFIG_LAST_UPDATE_TIMESTAMP_ERROR';

export const ON_CONFIG_CHANGED = 'ON_CONFIG_CHANGED';
export const UNMOUNT_CONFIG_EDITOR_MESSAGE = 'UNMOUNT_CONFIG_FORM_MESSAGE';
export const SET_CONFIG_USER = 'SET_CONFIG_USER';

export const LOAD_BRANDS = 'LOAD_BRANDS';
export const LOAD_ALL_BRANDS_SUCCESS = 'LOAD_ALL_BRANDS_SUCCESS';
export const LOAD_ALL_BRANDS_ERROR = 'LOAD_ALL_BRANDS_ERROR';

export const ON_BRAND_CHANGE = 'ON_BRAND_CHANGE';
export const UPDATE_BRANDS = 'UPDATE_BRANDS';
export const CLEAR_CONFIG_DATA = 'CLEAR_CONFIG_DATA';
