export const SELECT_AWARD = 'SELECT_AWARD';

export const ADD_AWARD = 'ADD_AWARD';
export const AWARD_ELEMENT_ON_CHANGE = 'AWARD_ELEMENT_ON_CHANGE';

export const ADD_NEW_AWARD = 'ADD_NEW_AWARD';

export const CREATE_AWARD = 'CREATE_AWARD';
export const CREATE_AWARD_SUCCESS = 'CREATE_AWARD_SUCCESS';
export const CREATE_AWARD_ERROR = 'CREATE_AWARD_ERROR';

export const UPDATE_AWARD = 'UPDATE_AWARD';
export const UPDATE_AWARD_SUCCESS = 'UPDATE_AWARD_SUCCESS';
export const UPDATE_AWARD_ERROR = 'UPDATE_AWARD_ERROR';

export const DELETE_AWARD = 'DELETE_AWARD';
export const DELETE_AWARD_SUCCESS = 'DELETE_AWARD_SUCCESS';
export const DELETE_AWARD_ERROR = 'DELETE_AWARD_ERROR';

export const UNMOUNT_AWARD_FORM_MESSAGE = 'UNMOUNT_AWARD_FORM_MESSAGE';
export const SET_AWARD_SLUG_UNIQUE_VALUE = 'SET_AWARD_SLUG_UNIQUE_VALUE';

export const ADD_SECONDARY_SPONSOR = 'ADD_SECONDARY_SPONSOR';
export const REMOVE_SECONDARY_SPONSOR = 'REMOVE_SECONDARY_SPONSOR';

export const CLEAR_AWARD_MANAGER_DATA = 'CLEAR_AWARD_MANAGER_DATA';
