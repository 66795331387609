export const SET_TAG_VALUE = 'SET_TAG_VALUE';
export const SET_TAG_TYPE = 'SET_TAG_TYPE';
export const SET_LIST_PAGE = 'SET_LIST_PAGE';
export const UPDATE_TAGGED_STORIES = 'UPDATE_TAGGED_STORIES';
export const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';

export const SEARCH_STORIES_BY_KEYWORDS = 'SEARCH_STORIES_BY_KEYWORDS';
export const SEARCH_STORIES_BY_KEYWORDS_SUCCESS = 'SEARCH_STORIES_BY_KEYWORDS_SUCCESS';
export const SEARCH_STORIES_BY_KEYWORDS_FAILURE = 'SEARCH_STORIES_BY_KEYWORDS_FAILURE';

export const TAG_STORIES = 'TAG_STORIES';
export const TAG_STORIES_SUCCESS = 'TAG_STORIES_SUCCESS';
export const TAG_STORIES_FAILURE = 'TAG_STORIES_FAILURE';

export const CLEAR_STATE = 'CLEAR_STATE';
