export const LOAD_USER = 'LOAD_USER';
export const LOAD_USER_ERROR = 'LOAD_USER_ERROR';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';

export const INVITE_USER = 'INVITE_USER';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_ERROR = 'INVITE_USER_ERROR';

export const UPDATE_DATA = 'UPDATE_DATA';
export const CLEAR_INVITE_USER_DATA = 'CLEAR_INVITE_USER_DATA';
