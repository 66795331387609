import React from 'react';
import T from 'prop-types';

const SearchPreviewContent = (props) => {
	const { title, excerpt, storyUrl } = props;
	const searchTitle = title ? `${title.substring(0, 80)}${title.length > 80 ? '...' : ''}` : 'Title';
	return (
		<div className="p-2">
			<h3 style={{ color: '#1a0dab', fontWeight: 'normal' }}>
				{searchTitle}
			</h3>
			<p className="mb-0 sm-text" style={{ color: '#006621' }}>
				{storyUrl || <span className="light-text">Story URL</span>}
			</p>
			<p style={{ fontSize: '0.9rem' }}>
				{excerpt || <span className="light-text">Description</span>}
			</p>
		</div>
	);
};

SearchPreviewContent.propTypes = {
	title: T.string,
	excerpt: T.string,
	storyUrl: T.string,
};

export default SearchPreviewContent;
