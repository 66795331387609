export const FETCH_CONNECT_REQUESTS_BY_STATUS = 'FETCH_CONNECT_REQUESTS_BY_STATUS';
export const FETCH_CONNECT_REQUESTS_BY_STATUS_SUCCESS = 'FETCH_CONNECT_REQUESTS_BY_STATUS_SUCCESS';
export const FETCH_CONNECT_REQUESTS_BY_STATUS_FAILURE = 'FETCH_CONNECT_REQUESTS_BY_STATUS_FAILURE';

export const UPDATE_CONNECT_REQUEST_STATUS = 'UPDATE_CONNECT_REQUEST_STATUS';
export const UPDATE_CONNECT_REQUEST_STATUS_SUCCESS = 'UPDATE_CONNECT_REQUEST_STATUS_SUCCESS';
export const UPDATE_CONNECT_REQUEST_STATUS_FAILURE = 'UPDATE_CONNECT_REQUEST_STATUS_FAILURE';

export const ON_TAB_CHANGE = 'ON_TAB_CHANGE';
export const SET_PAGE = 'SET_PAGE';

export const FILTER_CONNECT_REQUESTS_BY_COMPANY = 'FILTER_CONNECT_REQUESTS_BY_COMPANY';
export const FILTER_CONNECT_REQUESTS_BY_COMPANY_SUCCESS = 'FILTER_CONNECT_REQUESTS_BY_COMPANY_SUCCESS';
export const FILTER_CONNECT_REQUESTS_BY_COMPANY_FAILURE = 'FILTER_CONNECT_REQUESTS_BY_COMPANY_FAILURE';
