import React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import AsyncSelect from 'react-select/async';
import {
	Input, Button, InputGroup, DropDown,
} from '../Styles';
import messages from '../TopicManagerForm/messages';
import { ImagePlaceHolder } from './styles';
import closeSvg from '../../images/close.svg';
import { getDropdownOptions, validateImageFormat, validateImage } from '../../utils/common';
import ConfirmationModal from './ConfirmationModal';

class BasicForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			replacement: '',
			deleteClicked: false,
			delete: false,
			showReplaceConfirmation: false,
			showDeleteConfirmation: false,
			showVerticalError: false,
			touched: {
				name: false,
				slug: false,
				description: false,
				date: false,
			},
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isSlugUnique !== this.props.isSlugUnique) {
			this.handleBlur('slug');
		}
	}

	handleBlur(field) {
		this.setState({
			touched: { ...this.state.touched, [field]: true },
		});
	}

	displayErrors(field) {
		const errors = this.validateForm();
		const hasError = errors[field];
		let shouldShow;
		switch (field) {
		case 'slugLength':
		case 'slugUnique':
		case 'slugUpperCase':
			shouldShow = this.state.touched.slug;
			break;
		case 'slugSymbol':
			shouldShow = this.state.touched.slug;
			break;
		default:
			shouldShow = this.state.touched[field];
			break;
		}
		return hasError ? shouldShow : false;
	}

	validateForm = () => {
		const { formData, isSlugUnique, hoc } = this.props;
		return {
			name: formData.name.length < 1,
			slugLength: formData.slug.length < 1,
			slugUpperCase: this.hasUpperCase(formData.slug),
			slugSymbol: this.hasSymbols(formData.slug),
			slugUnique: !isSlugUnique,
			type: formData.type === 0 && hoc === 'award',
			date: formData.date < 1 && hoc === 'award',
		};
	}

	displayVerticalError = () => {
		const { deleteClicked, replacement } = this.state;
		if (deleteClicked && replacement === '') {
			return this.setState({ showVerticalError: true });
		}
		return this.setState({ showVerticalError: false });
	}

	hasUpperCase(str) {
		return /[A-Z]/.test(str);
	}

	hasSymbols(str) {
		return /[^A-Za-z0-9-]/.test(str);
	}

	onUpload = async (e) => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		if (await validateImageFormat(file)) {
			reader.onloadend = () => {
				const data = this.changedData('image', reader.result);
				this.props.formElementChange(data);
			};
			reader.readAsDataURL(file);
		}
	}

	onUploadClick = (e) => {
		e.preventDefault();
		document.getElementById('#photoUpload').click();
		this.setState({ touched: { ...this.state.touched, file: true } });
	}

	changedData = (name, value) => {
		const inputName = name;
		const inputValue = value;
		const data = {
			name: inputName,
			value: inputValue,
		};
		return data;
	}

	handleReplace = () => {
		this.setState({ showReplaceConfirmation: true, showVerticalError: false });
	}

	handleDelete = () => {
		if (this.props.hoc === 'award') {
			this.setState({ showDeleteConfirmation: true });
		} else {
			if (!this.state.deleteClicked) {
				this.setState({ deleteClicked: true });
				return;
			}
			this.setState({ showVerticalError: true });
		}
	}

	replaceKey = async () => {
		const { selectedItem, hoc, replaceItem } = this.props;
		replaceItem(hoc, selectedItem, this.state.replacement);
	}

	selectItem = (e) => {
		this.setState({ replacement: e, delete: true, showVerticalError: false });
	}

	getOptions = async (inputValue) => {
		const { hoc } = this.props;
		try {
			const verticals = await getDropdownOptions(hoc, inputValue);
			const verticalOptions = verticals.filter((vertical) => vertical.label !== this.props.formData.name);
			return verticalOptions;
		} catch (err) {
			console.log('Options error: ', err);
		}
	}

	renderReplaceConfirmation = () => (
		<ConfirmationModal
			openDialog={this.state.showReplaceConfirmation}
			closeDialog={() => {
				this.setState({ showReplaceConfirmation: false });
			}}
			message="Are you sure you want to replace and delete this vertical?"
			confirm={this.replaceKey}
		/>
	)

	renderDeleteConfirmation = () => (
		<ConfirmationModal
			openDialog={this.state.showDeleteConfirmation}
			closeDialog={() => {
				this.setState({ showDeleteConfirmation: false });
			}}
			message="Are you sure you want to and delete this award?"
			confirm={() => this.props.deleteItem(this.props.selectedItem.id)}
		/>
	)

	onElementChange = (e) => {
		const form = e.target;
		const data = this.changedData(form.name, form.value);
		this.props.formElementChange(data);
	}

	renderImageUpload = () => {
		if (this.props.hoc === 'award') {
			return (<Row middle="xs" className="mb-3">
				<Col sm={3} xs={12} className="mb-lg-0 mb-2">
					<FormattedMessage {...messages.image} />
				</Col>
				<Col sm={9} xs={12}>
					<Input
						id="photoUpload"
						type="file"
						style={{ display: 'none' }}
						name="photoUpload"
						onChange={this.onUpload}
					/>
					<Button light className="tu" onClick={this.onUploadClick}>
						<FormattedMessage {...messages.upload} />
					</Button>
					{
						this.displayErrors('file')
							? <p className="error" id="file-upload-err"><FormattedMessage {...messages.fileUploadErr} /></p>
							: null
					}
				</Col>
			</Row>);
		}
		return null;
	}

	onSave = (e) => {
		e.preventDefault();
		const form = e.target;
		const errors = this.validateForm();
		const save = !Object.keys(errors).some((i) => errors[i]);
		const {
			operation, createItem, updateItem, selectedItem, hoc, formData,
		} = this.props;

		if (save) {
			const data = new FormData();
			data.append('name', form.name.value);
			data.append('slug', form.slug.value);
			data.append('description', form.description.value ? form.description.value : null);

			if (hoc === 'award') {
				const filteredSponsors = [];
				// let tempBanner = { ...formData.banner };
				// let tempLogo = { ...formData.logo };
				const tempPrimary = JSON.parse(JSON.stringify(formData.primarySponsor));
				const tempSecondary = JSON.parse(JSON.stringify(formData.secondarySponsors));
				data.append('subtitle', form.subtitle.value ? form.subtitle.value : null);
				data.append('awardType', form.type.value);
				// if (formData.bannerImageFile) {
				//		 data.append('bannerImage', formData.bannerImageFile ? formData.bannerImageFile : null);
				//		 delete tempBanner.url;
				// }
				// data.append('banner', tempBanner && tempBanner.height ? JSON.stringify(tempBanner) : null)

				// if (formData.imageFile) {
				//		 data.append('image', formData.imageFile ? formData.imageFile : null);
				//		 delete tempLogo.url;
				// }
				// data.append('logo', tempLogo && tempLogo.height ? JSON.stringify(tempLogo) : null);

				if (formData.secondarySponsors && formData.secondarySponsors.length > 0) {
					tempSecondary.forEach((sponsor, index) => {
						if (sponsor.name) {
							filteredSponsors.push(sponsor);
							if (sponsor.image) {
								delete sponsor.logo.url;
								data.append(sponsor.name, formData.secondarySponsors[index].image);
							}
						}
					});
					data.append('secondarySponsors', filteredSponsors && filteredSponsors.length > 0 ? JSON.stringify(filteredSponsors) : null);
				} else {
					data.append('secondarySponsors', null);
				}
				if (formData.primarySponsor && formData.primarySponsor.name) {
					if (formData.primarySponsor.image) {
						data.append(formData.primarySponsor.name, formData.primarySponsor.image);
						delete tempPrimary.logo.url;
					}
					data.append('primarySponsor', JSON.stringify(tempPrimary));
				} else {
					data.append('primarySponsor', null);
				}
				data.append('dateFormat', formData.dateFormat);
				data.append('date', formData.date);
			}

			if (operation === 'add') createItem(data);

			else if (operation === 'edit') updateItem(selectedItem.id, data);
		} else {
			this.setState({
				touched: {
					name: this.state.touched.name || true,
					slug: this.state.touched.slug || true,
					type: this.state.touched.type || true,
					date: this.state.touched.date || true,
				},
			});
		}
	}

	renderSponsorField = () => {
		const { formData, formElementChange } = this.props;
		return (<Row className="mb-3" style={{ border: '1px solid #ced4da', borderRadius: '0.25rem', padding: '0.75rem' }}>
			<Col xs={8}>
				<Input
					className="mb-3"
					type="text"
					placeholder="Name"
					value={formData.primarySponsor && formData.primarySponsor.name ? formData.primarySponsor.name : ''}
					onChange={(e) => formElementChange({ name: 'primarySponsor', value: { ...this.props.formData.primarySponsor, name: e.target.value } })}
				/>
				<Input
					type="url"
					placeholder="Website"
					value={formData.primarySponsor && formData.primarySponsor.website ? formData.primarySponsor.website : ''}
					onChange={(e) => formElementChange({ name: 'primarySponsor', value: { ...this.props.formData.primarySponsor, website: e.target.value } })}
				/>
			</Col>

			<Col xs={4}>
				<Input
					type="file"
					id="primary-sponsor"
					style={{ display: 'none' }}
					onChange={this.handleImageChange}

				/>
				{formData.primarySponsor && formData.primarySponsor.logo && formData.primarySponsor.logo.url
					? <img
							src={formData.primarySponsor.logo.url}
							width="80"
							height="80"
							onLoad={(e) => this.onImageLoad(e)}
							onClick={() => document.getElementById('primary-sponsor').click()}
							alt="preview"
					/>
					: <ImagePlaceHolder
							onClick={() => document.getElementById('primary-sponsor').click()}
					>
						Upload Logo
					</ImagePlaceHolder>}

			</Col>
		</Row>);
	}

	onImageLoad = (e, index = null) => {
		if (this.props.formData.primarySponsor.image || this.props.formData.secondarySponsors.filter((sponsor) => sponsor.image).length) {
			if (index !== null) {
				if (!validateImage(e.target.naturalHeight, e.target.naturalWidth)) {
					document.getElementById(`secondary-sponsor-${index}`).value = '';
					this.props.formElementChange({
						name: 'secondarySponsor',
						value: {
							image: null,
							logo: null,
						},
					},
					index);
				} else {
					this.props.formElementChange({
						name: 'secondarySponsor',
						value: {
							logo: {
								...this.props.formData.secondarySponsors[index].logo,
								height: e.target.naturalHeight,
								width: e.target.naturalWidth,
							},
						},
					},
					index);
				}
			} else if (!validateImage(e.target.naturalHeight, e.target.naturalWidth)) {
				document.getElementById('primary-sponsor').value = '';
				this.props.formElementChange({
					name: 'primarySponsor',
					value: {
						image: null,
						logo: null,
					},
				});
			} else {
				this.props.formElementChange({
					name: 'primarySponsor',
					value: {
						...this.props.formData.primarySponsor,
						logo: {
							...this.props.formData.primarySponsor.logo,
							height: e.target.naturalHeight,
							width: e.target.naturalWidth,
						},
					},
				});
			}
		}
	}

	handleImageChange = async (e, index = null) => {
		try {
			e.preventDefault();

			const reader = new FileReader();
			const file = e.target.files[0];
			if (await validateImageFormat(file)) {
				reader.onloadend = () => {
					if (index !== null) {
						this.props.formElementChange({
							name: 'secondarySponsor',
							value: {
								logo: {
									url: reader.result,
								},
							},
						},
						index);
						this.props.formElementChange({
							name: 'secondarySponsor',
							value: {
								image: file,
							},
						},
						index);
					} else {
						this.props.formElementChange({
							name: 'primarySponsor',
							value: {
								...this.props.formData.primarySponsor,
								logo: {
									url: reader.result,
								},
							},
						});
						this.props.formElementChange({
							name: 'primarySponsor',
							value: {
								...this.props.formData.primarySponsor,
								image: file,
							},
						});
					}
				};
				reader.readAsDataURL(file);
			}
		} catch (err) {
			console.log('File read failed', err);
		}
	}

	renderSecondarySponsors = () => {
		const { formData, formElementChange, removeSponsor } = this.props;
		if (formData.secondarySponsors && formData.secondarySponsors.length > 0) {
			// eslint-disable-next-line react/no-array-index-key
			return formData.secondarySponsors.map((sponsor, index) => (<Row className="mb-3" key={`sponsor-${index}`} style={{ border: '1px solid #ced4da', borderRadius: '0.25rem', padding: '0.75rem' }}>
				<Col xs={8}>
					<Input
						className="mb-3"
						type="text"
						value={sponsor.name}
						placeholder="Name"
						onChange={(e) => formElementChange({ name: 'secondarySponsor', value: { name: e.target.value } }, index)}
					/>
					<Input
						type="url"
						placeholder="Website"
						value={sponsor.website ? sponsor.website : ''}
						onChange={(e) => formElementChange({ name: 'secondarySponsor', value: { website: e.target.value } }, index)}
					/>
				</Col>

				<Col xs={3}>
					<Input
						type="file"
						id={`secondary-sponsor-${index}`}
						style={{ display: 'none' }}
						onChange={(e) => this.handleImageChange(e, index)}
					/>
					{sponsor.logo && sponsor.logo.url
						? <img
								src={sponsor.logo.url}
								width="80"
								height="80"
								onLoad={(e) => this.onImageLoad(e, index)}
								onClick={() => document.getElementById(`secondary-sponsor-${index}`).click()}
								alt="preview"
						/>
						: <ImagePlaceHolder
								onClick={() => document.getElementById(`secondary-sponsor-${index}`).click()}
						>
							Upload Logo
						</ImagePlaceHolder>}
				</Col>
				<Col xs={1}>
					<img
						src={closeSvg}
						onClick={() => removeSponsor(index)}
						alt="close"
						width="10"
						height="10"
					/>
				</Col>
			</Row>));
		}
	}

	renderAwardFields = () => {
		const { hoc, formData, addSponsor } = this.props;
		if (hoc === 'award') {
			return (<>
				<Row className="mb-3">
					<Col sm={3} xs={12}>
						Date *
					</Col>
					<Col sm={9} xs={12}>
						<Row>
							<Col sm={5}>

								<Input
									name="date"
									type="date"
									value={formData && formData.date ? formData.date : ''}
									onChange={this.onElementChange}
									onBlur={() => this.handleBlur('date')}
								/>
							</Col>
							<Col sm={2} className="text-right m-auto">Format:</Col>
							<Col sm={5}>
								<DropDown
									name="dateFormat"
									value={formData && formData.dateFormat ? formData.dateFormat : 'DATE'}
									className="form-control mt-0"
									id="award-date-dropdown"
									onChange={this.onElementChange}
								>
									<option value="DATE">DATE</option>
									<option value="MONTH">MONTH</option>
									<option value="YEAR">YEAR</option>
								</DropDown>
							</Col>
						</Row>
						{
							this.displayErrors('date')
								? <p className="error" id="file-upload-err"><FormattedMessage {...messages.dateErr} /></p>
								: null
						}
					</Col>
				</Row>
				<Row className="mb-3">
					<Col sm={3} xs={12}>
						Subtitle
					</Col>
					<Col sm={9} xs={12}>
						<textarea
							rows="4"
							className="global-form-control"
							name="subtitle"
							placeholder={`This is the subtitle of the ${hoc}.`}
							value={formData.subtitle ? formData.subtitle : ''}
							onChange={this.onElementChange}
							onBlur={() => this.handleBlur('subtitle')}
							maxLength={1000}
						/>
						<div style={{ textAlign: 'right', color: '#aaa' }}>
							{formData && formData.subtitle ? formData.subtitle.length : 0}/1000
						</div>
					</Col>
				</Row>
				<Row className="mb-3">
					<Col sm={3} xs={12}>
						Primary Sponsor
					</Col>
					<Col sm={9} xs={12}>
						{this.renderSponsorField()}
					</Col>
				</Row>
				<Row className="mb-3">
					<Col sm={3} xs={12}>
						Secondary Sponsors
					</Col>
					<Col sm={9} xs={12}>
						{this.renderSecondarySponsors()}
						<Button
							type="button"
							onClick={addSponsor}
							success
							className="pull-right"
						>
							Add more
						</Button>
					</Col>
				</Row>
				<Row className="mb-3">
					<Col sm={3} xs={12}>
						Award Type *
					</Col>
					<Col sm={9} xs={12}>
						<DropDown
							name="type"
							value={formData && formData.type ? formData.type : 0}
							className="form-control mt-0"
							id="award-type-dropdown"
							onBlur={() => this.handleBlur('type')}
							onChange={this.onElementChange}
						>
							<option value={0}>Select Type</option>
							<option value="YS-INTERNAL">YSInternal</option>
							<option value="EXTERNAL">External</option>
						</DropDown>
						{
							this.displayErrors('type')
								? <p className="error" id="name-err"><FormattedMessage {...messages.typeErr} /></p>
								: null
						}
					</Col>
				</Row>
			</>
			);
		}
		return null;
	}

	render() {
		const { formData, hoc, reset } = this.props;
		return (
			<div>
				<form onSubmit={this.onSave}>
					<Row className="mb-3">
						<Col sm={3} xs={12}>
							Name *
						</Col>
						<Col sm={9} xs={12}>
							<Input
								type="text"
								name="name"
								placeholder="This is the name that is shown"
								value={formData.name ? formData.name : ''}
								onChange={this.onElementChange}
								onBlur={() => this.handleBlur('name')}
							/>
							{
								this.displayErrors('name')
									? <p className="error" id="name-err"><FormattedMessage {...messages.nameErr} /></p>
									: null
							}
						</Col>
					</Row>
					<Row middle="xs" className="mb-3" controlId="Slug">
						<Col sm={3} xs={12} className="mb-lg-0 mb-2">
							Slug *
						</Col>
						<Col sm={9} xs={12}>
							<InputGroup>
								<Input
									id="slug"
									type="text"
									className="form-control"
									name="slug"
									placeholder="This is the slug"
									value={formData.slug ? formData.slug : ''}
									onChange={this.onElementChange}
									onBlur={() => this.handleBlur('slug')}
								/>

							</InputGroup>
							{
								this.displayErrors('slugLength')
									? <p className="error" id="slug-length-err"><FormattedMessage {...messages.slugEmptyErr} /></p>
									: null
							}
							{
								!this.displayErrors('slugLength') && this.displayErrors('slugUpperCase')
									? <p className="error" id="slug-case-err"><FormattedMessage {...messages.slugCaseErr} /></p>
									: null
							}
							{
								!this.displayErrors('slugLength') && this.displayErrors('slugUnique')
									? <p className="error" id="slug-unique-err"><FormattedMessage {...messages.slugUniqueErr} /></p>
									: null
							}
							{
								!this.displayErrors('slugLength') && this.displayErrors('slugSymbol')
									? <p className="error" id="slug-symbol-err"><FormattedMessage {...messages.slugSymbolErr} /></p>
									: null
							}
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={3} xs={12}>
							Description
						</Col>
						<Col sm={9} xs={12}>
							<textarea
								rows="8"
								className="global-form-control"
								name="description"
								placeholder={`This is the description of the ${hoc}. This is shown on the page.`}
								value={formData.description ? formData.description : ''}
								onChange={this.onElementChange}
								onBlur={() => this.handleBlur('description')}
							/>
						</Col>
					</Row>
					{this.renderAwardFields()}
					<Row className="mb-3 mt-3">
						<Col md={12} sm={12} xs={12}>
							<Row>
								<Button
									type="submit"
									success
									disabled={this.props.loading}
									className="mb-4"
								>
									{this.props.operation === 'edit' ? 'UPDATE' : 'SAVE'}
								</Button>
								<Button
									light
									disabled={this.props.loading}
									onClick={(e) => {
										e.preventDefault();
										if (this.props.hoc === 'award' && document.getElementById('photoUpload')) document.getElementById('photoUpload').value = '';
										reset();
									}}
									className="ml-3 mb-4"
								>
									<FormattedMessage {...messages.discard} />
								</Button>
								{this.state.deleteClicked && this.props.operation !== 'add' && this.props.hoc === 'award'
									? <Col md={6} sm={5} xs={5}>
										<AsyncSelect
											className="ml-2"
											placeholder={`Select alternate ${this.props.hoc}`}
											loadOptions={this.getOptions}
											onChange={this.selectItem}
											onBlur={() => {
												if (this.state.replacement === '') this.setState({ showVerticalError: true });
											}}
											isClearable
										/>
										{
											this.state.showVerticalError
												? <p className="error" id="vertical-err"><FormattedMessage {...messages.verticalErr} /></p>
												: null
										}
									</Col>
									: <></>}
								{
									this.state.showReplaceConfirmation
										? this.renderReplaceConfirmation() : <></>
								}
								{
									this.state.showDeleteConfirmation
										? this.renderDeleteConfirmation() : <></>
								}
								<Row md={3} sm={3} xs={3}>
									{this.state.delete && this.props.operation !== 'add' && this.props.hoc !== 'award'
										? <Button
												type="button"
												secondary
												id="deleteBtn"
												onClick={this.handleReplace}
												className="ml-3 mb-4"
										>
											<FormattedMessage {...messages.replace} />
										</Button>
										: this.props.operation !== 'add'
											? <Button
													type="button"
													primary
													id="deleteBtn"
													className="ml-3 mb-4 "
													onClick={this.handleDelete}
											>
												<FormattedMessage {...messages.delete} />
											</Button>
											: <></>}
								</Row>
							</Row>
						</Col>
					</Row>
				</form>
			</div>
		);
	}
}

BasicForm.propTypes = {
	selectedItem: PropTypes.object,
	formData: PropTypes.object,
	reset: PropTypes.func,
	formElementChange: PropTypes.func,
	addNew: PropTypes.bool,
	loading: PropTypes.bool,
	createItem: PropTypes.func,
	updateItem: PropTypes.func,
	deleteItem: PropTypes.func,
	replaceItem: PropTypes.func,
	addSponsor: PropTypes.func,
	removeSponsor: PropTypes.func,
	hoc: PropTypes.string,
	operation: PropTypes.string,
	isSlugUnique: PropTypes.bool,
};

export default BasicForm;
