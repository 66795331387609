import React, { useState } from 'react';
import T from 'prop-types';
import { Row, Col } from 'react-styled-flexboxgrid';
import {
	DropDown, Input, InputGroup, Button, Wrapper,
} from '../Styles';
import { GalleryOptionsWrapper } from './styles';

const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUNE', 'JULY', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

const FundingListOptions = (props) => {
	const {
		filterFundingItems, onSearchClear, module,
	} = props;
	let searchPlaceholder = '';
	if (['funding', 'acquisition'].includes(module)) {
		searchPlaceholder = 'Investee';
	} else if (module === 'ipo') {
		searchPlaceholder = 'Company';
	} else {
		searchPlaceholder = 'Participants';
	}
	const [searchValue, setSearchValue] = useState(null);
	const [filterMonthValue, setFilterMonthValue] = useState(null);
	const [filterYearValue, setFilterYearValue] = useState(null);

	function runSearch(e) {
		e.preventDefault();
		if (searchValue || filterMonthValue || filterYearValue) {
			filterFundingItems(searchValue, filterMonthValue, filterYearValue);
		}
	}
	return (
		<GalleryOptionsWrapper>
			<Wrapper style={{ boxShadow: '0px 5px 5px -3px #C8CECA', paddingTop: '1rem' }}>
				<Row>
					<Col md={6} xs={12}>
						<Row>
							<Col xs={4}>
								<p className="text-bold mb-0">YEAR</p>
								<DropDown
									className="mt-0"
									value={filterYearValue}
									onChange={(e) => {
										setFilterYearValue(e.target.value);
										filterFundingItems(searchValue, filterMonthValue, e.target.value);
									}}
								>
									<option value="none" selected disabled hidden>YEAR</option>
									<option value="2020">2020</option>
									<option value="2021">2021</option>
									<option value="2022">2022</option>
								</DropDown>
							</Col>
							<Col xs={4}>
								<p className="text-bold mb-0">MONTH</p>
								<DropDown
									className="mt-0"
									value={filterMonthValue}
									onChange={(e) => {
										setFilterMonthValue(e.target.value);
										filterFundingItems(searchValue, e.target.value, filterYearValue);
									}}
								>
									<option value="none" selected disabled hidden>MONTH</option>
									{months.map((item, index) => <option value={index + 1}>{item}</option>)}
								</DropDown>
							</Col>
							{(filterYearValue && filterYearValue !== 'none') || (filterMonthValue && filterMonthValue !== 'none')
								? <button
										type="button"
										className="btn default"
										onClick={() => {
											setFilterMonthValue('none');
											setFilterYearValue('none');
											onSearchClear();
										}}
								>Clear</button>
								: null}
						</Row>
					</Col>
					<Col md={6} xs={12}>
						<Row>
							<Col xs={8}>
								<p className="text-bold mb-0">Search</p>
								<form onSubmit={runSearch}>
									<InputGroup>
										<Input
											type="text"
											className="form-control"
											id="search-input"
											placeholder={`Search by ${searchPlaceholder}`}
											name="search"
											value={searchValue}
											onChange={(e) => setSearchValue(e.target.value.trim())}
											autoComplete="off"
										/>
										<div className="append-group" name="content-add-on">
											{
												searchValue && searchValue.length > 0
													? <span
															id="cancel-search-btn"
															className="float-icon"
															onClick={() => {
																setSearchValue('');
																onSearchClear();
															}}
													>
														<i className="fa fa-times" />
													</span>
													: null
											}
											<Button
												primary
												className="append-group-text brand-bg white-text"
												type="submit"
											>
												<i className="fa fa-search" />
											</Button>
										</div>
									</InputGroup>
								</form>
							</Col>
						</Row>
					</Col>
				</Row>
			</Wrapper>
		</GalleryOptionsWrapper>
	);
};
FundingListOptions.propTypes = {
	filterFundingItems: T.func,
	onSearchClear: T.func,
	module: T.string,
};

export default FundingListOptions;
