import React from 'react';
import T from 'prop-types';
import { Col } from 'react-styled-flexboxgrid';
import { ListHeaderWrapper } from '../../WebinarComponents/styles';

const ListHeader = (props) => {
	const { selectedStatus } = props;
	return (
		<ListHeaderWrapper>
			<Col xs={2} sm={1} md={1}>
				S.No
			</Col>
			<Col xs={6} sm={1} md={1}>
				From Year
			</Col>
			<Col xs={6} sm={1} md={1}>
				To Year
			</Col>
			{selectedStatus === 1 && (
				<Col xs={2} sm={3} md={3}>
					Institution Name
				</Col>
			)}
			{selectedStatus === 1 && (
				<Col xs={2} sm={2} md={2}>
					Degree
				</Col>
			)}
			{selectedStatus === 2 && (
				<Col xs={2} sm={2} md={2}>
					Role
				</Col>
			)}
			{selectedStatus === 2 && (
				<Col xs={2} sm={2} md={2}>
					Company Name
				</Col>
			)}
			{selectedStatus === 2 && (
				<Col xs={2} sm={3} md={3}>
					Description
				</Col>
			)}
			<Col xs={2} sm={1} md={1}>
				Actions
			</Col>
		</ListHeaderWrapper>
	);
};

ListHeader.propTypes = {
	selectedStatus: T.number,
};
export default ListHeader;
