import { FETCH_DASHBOARD_USER_BRANDS, FETCH_DASHBOARD_USER_BRANDS_SUCCESS, FETCH_DASHBOARD_USER_BRANDS_ERROR } from './type';

export function fetchDashboardUserBrands() {
	return {
		type: FETCH_DASHBOARD_USER_BRANDS,
	};
}

export function fetchDashboardUserBrandsSuccess(brands) {
	return {
		type: FETCH_DASHBOARD_USER_BRANDS_SUCCESS,
		brands,
	};
}

export function fetchDashboardUserBrandsError(error) {
	return {
		type: FETCH_DASHBOARD_USER_BRANDS_ERROR,
		error,
	};
}
