export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_ERROR = 'USER_LOGOUT_ERROR';

export const FETCH_APP_DATA = 'FETCH_APP_DATA';
export const FETCH_APP_DATA_SUCCESS = 'FETCH_APP_DATA_SUCCESS';
export const FETCH_APP_DATA_FAILURE = 'FETCH_APP_DATA_FAILURE';

export const SET_PAGE_NAME = 'SET_PAGE_NAME';
export const SET_ONLINE_STATUS = 'SET_ONLINE_STATUS';
export const SET_CURRENT_BRAND = 'SET_CURRENT_BRAND';
