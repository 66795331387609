export const LOAD_ALIASES = 'LOAD_ALIASES';
export const LOAD_ALIASES_SUCCESS = 'LOAD_ALIASES_SUCCESS';
export const LOAD_ALIASES_ERROR = 'LOAD_ALIASES_ERROR';

export const LOAD_FILTERED_ALIASES = 'LOAD_FILTERED_ALIASES';
export const LOAD_FILTERED_ALIASES_SUCCESS = 'LOAD_FILTERED_ALIASES_SUCCESS';
export const LOAD_FILTERED_ALIASES_ERROR = 'LOAD_FILTERED_ALIASES_ERROR';

export const SAVE_ALIAS = 'SAVE_ALIAS';
export const SAVE_ALIAS_SUCCESS = 'SAVE_ALIAS_SUCCESS';
export const SAVE_ALIAS_ERROR = 'SAVE_ALIAS_ERROR';

export const PROFILE_SELECT = 'PROFILE_SELECT';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const DISCARD_CHANGES = 'DISCARD_CHANGES';
export const CHANGE_DROPDOWN = 'CHANGE_DROPDOWN';
export const CLEAR_UPDATE_ALIAS_DATA = 'CLEAR_UPDATE_ALIAS_DATA';
export const UNMOUNT_ALIAS_FORM_MESSAGE = 'UNMOUNT_ALIAS_FORM_MESSAGE';
