import {
	call, put, takeLatest, select,
} from 'redux-saga/effects';
import { SEARCH_STORIES_BY_KEYWORDS, TAG_STORIES } from './type';
import { SEARCH_API_BASE_URL } from './constants';
import { STORY_METADATA_BASE_URL } from '../EditorPage/constants';
import {
	searchStoriesByKeywordsFailure, searchStoriesByKeywordsSuccess, tagStoriesFailure, tagStoriesSuccess,
} from './actions';
import { redirectTo } from '../../utils/accessRedirection';
import { makeSelectLocation } from '../App/selectors';

let CURRENT_LOCATION;

function* searchStories(action) {
	try {
		const url = `${SEARCH_API_BASE_URL}/${action.tagType}/${action.searchValueId}/${action.searchValue}/page/${action.page}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const parsed = yield response.json();
			yield put(searchStoriesByKeywordsSuccess(parsed));
		}
	} catch (err) {
		yield put(searchStoriesByKeywordsFailure(err));
	}
}

function* saveTaggedStories(action) {
	try {
		const url = `${STORY_METADATA_BASE_URL}/company-influencer`;
		const options = {
			method: 'POST',
			credentials: 'include',
			body: action.payload,
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const parsed = yield response.json();
			if (parsed.success) {
				yield put(tagStoriesSuccess(parsed));
			} else {
				yield put(tagStoriesFailure('Story Metadata update failed'));
			}
		}
	} catch (err) {
		yield put(tagStoriesFailure(err));
	}
}

export default function* multipleStoriesTaggerData() {
	const location = yield select(makeSelectLocation());
	CURRENT_LOCATION = location.pathname;
	yield takeLatest(SEARCH_STORIES_BY_KEYWORDS, searchStories);
	yield takeLatest(TAG_STORIES, saveTaggedStories);
}
