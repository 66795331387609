import { Map } from 'immutable';
import {
	SET_ERROR_STATE, SET_LOADING_STATE, SET_TAB_STATE, SET_SERIES_DATA, SET_SEARCH_TEXT,
	SET_YSTV_SERIES_DATA,
} from './type';

const initialState = Map({
	loading: {
		status: false,
		type: null,
	},
	error: {
		status: false,
		type: null,
		message: null,
	},
	tab: null,
	series: null,
	searchText: null,
	YSTVSeriesData: null,
});

function SeriesManagerReducer(state = initialState, action) {
	switch (action.type) {
	case SET_ERROR_STATE: return state.set('error', action.value);
	case SET_LOADING_STATE: return state.set('loading', action.value);
	case SET_TAB_STATE: return state.set('tab', action.value);
	case SET_SERIES_DATA: return state.set('series', action.value);
	case SET_YSTV_SERIES_DATA: return state.set('YSTVSeriesData', action.value);
	case SET_SEARCH_TEXT: return state.set('searchText', action.value);
	default: return state;
	}
}

export default SeriesManagerReducer;
