import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { Row, Col } from '../../components/Responsive';
import { WrapperContainer, Button } from '../../components/Styles';

import ProfileDeskActionCard from '../../components/ProfileDeskActionCard/ProfileDeskActionCard';
import {
	fetchDeskProfilesByStatus, setDeskPage, setDeskProfilesStatus, updateDeskProfileStatus, disableDeskSearchFlag, runDeskSearch, setAlternateProfileId,
} from './actions';
import {
	makeSelectDeskProfiles, makeSelectProfileDeskPage, makeSelectProfileDeskStatus, makeSelectProfileDeskSearch, makeSelectProfileDeskSearchType, makeSelectProfileDeskSearchValue, makeSelectProfileDeskLoading, makeSelectAlternateProfileId,
} from './selectors';
import ProfileDeskHeader from '../../components/ProfileDeskHeader/ProfileDeskHeader';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';
import AlertModal from '../../components/AlertModal/AlertModal';
import ReplacementModal from '../../components/ReplacementModal/ReplacementModal';
import { PEOPLE_API_BASE_URL } from '../PeopleManager/constants';

class ProfileDesk extends React.PureComponent {
	constructor(props) {
		super(props);
		this.renderDeskActionCard = this.renderDeskActionCard.bind(this);
		this.changeTab = this.changeTab.bind(this);
		this.updateProfileStatus = this.updateProfileStatus.bind(this);
		this.onShowMoreProfiles = this.onShowMoreProfiles.bind(this);
		this.betweenValue = this.betweenValue.bind(this);
		this.runFilter = this.runFilter.bind(this);
		this.onSearchClear = this.onSearchClear.bind(this);
		this.renderAlertBox = this.renderAlertBox.bind(this);
		this.alertConfirmAction = this.alertConfirmAction.bind(this);
		this.state = {
			alertBox: false,
			profile: null,
			newStatus: null,
			influencerValidationErrors: null,
			showInfluencerValidationErrors: false,
		};
	}

	componentDidMount() {
		const {
			match, getProfilesByStatus, initiateSetDeskProfilesStatus, changeDeskPageNo, history,
		} = this.props;
		if (match.params.status) {
			getProfilesByStatus(match.params.status, 1, match.params.profile);
			initiateSetDeskProfilesStatus(match.params.status);
			changeDeskPageNo(1);
			history.replace(`/manager/${match.params.profile}/desk/${match.params.status}`);
		}
		this.props.pageTitle(`${match.params.profile}Desk`);
	}

	onShowMoreProfiles() {
		const {
			status, page, changeDeskPageNo, getProfilesByStatus, searchFlag, initiateDeskSearch, searchType, searchValue, match,
		} = this.props;
		changeDeskPageNo(page + 1);
		if (searchFlag) {
			initiateDeskSearch(searchType, searchValue, status, page + 1, match.params.profile);
		} else {
			getProfilesByStatus(status, page + 1, match.params.profile);
		}
	}

	onSearchClear() {
		const {
			getProfilesByStatus, status, page, cancelSearch, match,
		} = this.props;
		getProfilesByStatus(status, page, match.params.profile);
		cancelSearch();
	}

	betweenValue() {
		const { searchType, searchValue } = this.props;
		let value;
		if (searchType && searchType.toLowerCase() === 'date') {
			const dateValue = JSON.parse(searchValue);
			value = `${new Date(dateValue[0]).toLocaleDateString()} - ${new Date(dateValue[1]).toLocaleDateString()}`;
		} else {
			value = 'All Time';
		}
		return value;
	}

	changeTab(newStatus) {
		const {
			initiateSetDeskProfilesStatus, getProfilesByStatus, history, changeDeskPageNo, searchFlag, searchValue, searchType, initiateDeskSearch, page, match,
		} = this.props;
		if (searchFlag) {
			initiateDeskSearch(searchType, searchValue, newStatus, page, match.params.profile);
		} else {
			getProfilesByStatus(newStatus, 1, match.params.profile);
			changeDeskPageNo(1);
			history.replace(`/manager/${match.params.profile}/desk/${newStatus}`);
		}
		initiateSetDeskProfilesStatus(newStatus);
	}

	updateProfileStatus(profile, newStatus) {
		const { page, match } = this.props;
		if (profile.status !== 'TRASHED' && ['PUBLISHED', 'INREVIEW', 'VERIFIED', 'TRASHED', 'PENDING-REJECTED', 'PENDING-VERIFIED', 'PENDING-PUBLISHED'].includes(newStatus.toUpperCase())) {
			if (match.params.profile === 'influencer' && ['PUBLISHED', 'VERIFIED'].includes(newStatus)) {
				this.setState({ newStatus, profile });
				this.validateInfluencer(profile.id);
			} else {
				this.setState({ alertBox: true, newStatus, profile });
			}
		} else {
			this.props.initiateUpdateProfileStatus(profile, newStatus, page, match.params.profile);
		}
	}

	runFilter(searchType, searchValue) {
		const {
			status, initiateDeskSearch, changeDeskPageNo, history, match,
		} = this.props;
		changeDeskPageNo(1);
		history.replace(`/manager/${match.params.profile}/desk/${status}`);

		initiateDeskSearch(searchType, searchValue, status, 1, match.params.profile);
	}

	alertConfirmAction(confirm) {
		this.setState({ alertBox: false });
		const { page, match, alternateProfileId } = this.props;
		if (confirm) {
			const { newStatus } = this.state;
			const { profile } = this.state;
			this.props.initiateUpdateProfileStatus(profile, newStatus, page, match.params.profile, alternateProfileId);
		}
		this.setState({ newStatus: null, profile: null });
	}

	async validateInfluencer(influencerId) {
		const url = `${PEOPLE_API_BASE_URL}/validate/${influencerId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			const validation = await response.json();
			if (validation.validated) {
				this.setState({ alertBox: true });
			} else {
				this.setState({ influencerValidationErrors: validation.errors, showInfluencerValidationErrors: true });
			}
		} catch (err) {
			console.log('Validate Influencer Error', err);
		}
	}

	renderInfluencerValidationErrors() {
		if (this.state.influencerValidationErrors && this.state.influencerValidationErrors.length > 0) {
			const errorMessage = this.state.influencerValidationErrors.map((item) => <p className="error" style={{ fontSize: '1.1rem' }}>{item}</p>);
			return <Modal
				isOpen={this.state.showInfluencerValidationErrors}
				onRequestClose={() => this.setState({ showInfluencerValidationErrors: false })}
				contentLabel="Influencer Validation Errors"
				overlayClassName="modal-overlay"
				className="form-modal"
			>
				<div className="w_100 mb-1 text-right">
					<button type="button" onClick={() => this.setState({ showInfluencerValidationErrors: false })}>
						<i className="fas fa-times light-text disablePointerEvents" />
					</button>
				</div>
				<h3>Validation Error</h3>
				<div>{errorMessage}</div>
			</Modal>;
		}
		return null;
	}

	renderAlertBox() {
		const { profile } = this.state;
		if (this.state.alertBox) {
			let action;
			switch (this.state.newStatus.toUpperCase()) {
			case 'PENDING-PUBLISHED':
			case 'PUBLISHED':
				action = 'publish';
				if (profile.status === 'VERIFIED') action = 'unverify';
				break;
			case 'PENDING-VERIFIED':
			case 'VERIFIED':
				action = 'verify';
				break;
			case 'TRASHED':
				action = 'trash';
				break;
			case 'INREVIEW':
				action = 'unpublish';
				break;
			case 'PENDING-REJECTED':
				action = 'reject';
				break;
			default:
				break;
			}
			const item = ['PENDING-PUBLISHED', 'PENDING-VERIFIED', 'PENDING-REJECTED'].includes(this.state.newStatus) ? 'update' : 'profile';
			let message = `Are you sure you want to ${action} this ${item}?`;
			if (profile.unverifiedCount) {
				message = `There ${profile.unverifiedCount === 1 ? 'is' : 'are'} <b>${profile.unverifiedCount}</b> unaddressed ${profile.unverifiedCount === 1 ? 'update' : 'updates'} in this company.<br /> Do you still want to ${action} this ${item}?`;
			}
			if (this.props.match.params.profile === 'company' && action === 'trash') {
				message = 'Please select another company to replace this company';
				return <ReplacementModal comment={message} confirm={this.alertConfirmAction} onReplacementSelect={this.props.setAlternateProfileId} />;
			}

			return <AlertModal comment={message} confirm={this.alertConfirmAction} />;
		}
		return null;
	}

	renderDeskActionCard() {
		const { profiles, status, history } = this.props;
		let hideOption;
		if (status) {
			switch (status.toLowerCase()) {
			case 'published':
				hideOption = 'publish';
				break;
			case 'verified':
				hideOption = 'verify';
				break;
			case 'trashed':
				hideOption = 'trash';
				break;
			case 'inreview':
				hideOption = 'inreview';
				break;
			case 'updatesforreview':
				hideOption = 'pending-';
				break;
			default:
				break;
			}
		}
		let actionCards;
		if (profiles && profiles.length > 0) {
			actionCards = profiles.map((profile) => <ProfileDeskActionCard
				key={profile.id}
				profile={profile}
				updateStatus={this.updateProfileStatus}
				hideOption={hideOption}
				history={history}
				profileType={this.props.match.params.profile}
				pendingInvestor={profile.investorCount}
				pendingTeam={profile.teamCount}
			/>);
		} else {
			actionCards = (
				<Row style={{
					width: '100%', minHeight: '200px', justifyContent: 'center', alignItems: 'center',
				}}
				>
					<div>
						<h1 style={{ color: '#bbb' }}>
							No Profiles To Display
						</h1>
					</div>
				</Row>
			);
		}
		return actionCards;
	}

	render() {
		const { loading, profiles, page } = this.props;
		return (
			<div>
				<WrapperContainer className="mt-5">
					{this.renderAlertBox()}
					<Row>
						<ProfileDeskHeader
							status={this.props.status}
							onTabSelect={this.changeTab}
							search={this.props.searchFlag}
							cancelSearch={this.onSearchClear}
							filterProfiles={this.runFilter}
							profile={this.props.match.params.profile}
						/>
					</Row>
					<Row>
						<div className="mb-2"><b className="tu mr-2">Between:</b> <span>{this.betweenValue()}</span></div>
					</Row>
					<Row>
						{
							loading && page === 1
								? <LoadingIcon />
								: this.renderDeskActionCard()
						}
					</Row>
					<Row>
						{
							loading && page > 1
								? <LoadingIcon />
								: null
						}
					</Row>
					{
						loading || profiles === null || (profiles.length < page * 5)
							? null
							: <Row center="xs">
								<Col xs={6} md={2}>
									<Button
										primary
										btn_rounded
										w_100
										id="more-profiles-btn"
										onClick={() => this.onShowMoreProfiles()}
									>
										Show More
									</Button>
								</Col>
							</Row>
					}
					{this.renderInfluencerValidationErrors()}
				</WrapperContainer>
			</div>
		);
	}
}

export function mapDispatchToProps(dispatch) {
	return {
		getProfilesByStatus: (status, page, profileType) => dispatch(fetchDeskProfilesByStatus(status, page, profileType)),
		changeDeskPageNo: (pageNo) => dispatch(setDeskPage(pageNo)),
		initiateSetDeskProfilesStatus: (status) => dispatch(setDeskProfilesStatus(status)),
		initiateUpdateProfileStatus: (profile, newStatus, page, profileType, alternateProfileId) => dispatch(updateDeskProfileStatus(profile, newStatus, page, profileType, alternateProfileId)),
		cancelSearch: () => dispatch(disableDeskSearchFlag()),
		initiateDeskSearch: (searchType, searchValue, status, limit, profileType) => dispatch(runDeskSearch(searchType, searchValue, status, limit, profileType)),
		setAlternateProfileId: (id) => dispatch(setAlternateProfileId(id)),
		// validateInfluencerData: (id) => dispatch(validateInfluencer(id))
	};
}

const mapStateToProps = createStructuredSelector({
	profiles: makeSelectDeskProfiles(),
	page: makeSelectProfileDeskPage(),
	status: makeSelectProfileDeskStatus(),
	searchFlag: makeSelectProfileDeskSearch(),
	searchType: makeSelectProfileDeskSearchType(),
	searchValue: makeSelectProfileDeskSearchValue(),
	loading: makeSelectProfileDeskLoading(),
	alternateProfileId: makeSelectAlternateProfileId(),
});

ProfileDesk.propTypes = {
	profiles: PropTypes.array,
	page: PropTypes.number,
	getProfilesByStatus: PropTypes.func,
	match: PropTypes.object,
	initiateSetDeskProfilesStatus: PropTypes.func,
	initiateUpdateProfileStatus: PropTypes.func,
	changeDeskPageNo: PropTypes.func,
	status: PropTypes.string,
	history: PropTypes.object,
	searchFlag: PropTypes.bool,
	cancelSearch: PropTypes.func,
	searchType: PropTypes.string,
	searchValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	initiateDeskSearch: PropTypes.func,
	loading: PropTypes.bool,
	pageTitle: PropTypes.func,
	alternateProfileId: PropTypes.string,
	setAlternateProfileId: PropTypes.func,
	// validateInfluencerData: PropTypes.func
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(ProfileDesk);
