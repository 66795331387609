import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	UPDATE_CONFIG, LOAD_ALL_NAMESPACE, LOAD_CONFIGS_BY_NAMESPACE, SELECT_NAMESPACE, LOAD_CONFIG_LATEST_UPDATE_TIMESTAMP, LOAD_BRANDS, UPDATE_BRANDS,
} from './type';
import {
	loadConfigsByNamespaceSuccess, loadConfigsByNamespaceError, onUpdateConfigSuccess, onUpdateConfigFailure, loadAllNamespaceSuccess, loadAllNamespaceFailure, loadAllNamespace, loadConfigLastUpdatedAtSuccess, loadConfigLastUpdatedAtError, brandsLoadedSuccessfully, brandsLoadingError,
} from './actions';
import { CONFIG_API_BASE_URL } from './constants';
import { BRAND_API_BASE_URL } from '../BrandManager/constants';

export function* getAllNamespace() {
	const url = `${CONFIG_API_BASE_URL}/allnamespaces`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const namespaces = yield response.json();
			yield put(loadAllNamespaceSuccess(namespaces));
		}
	} catch (err) {
		yield put(loadAllNamespaceFailure(err));
	}
}

export function* getConfigsByNamespace(action) {
	if (!action.selectedNamespace.name) {
		const namespace = action.namespace ? action.namespace : action.selectedNamespace;
		const url = `${CONFIG_API_BASE_URL}/configs/${namespace}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = yield call(fetch, url, options);
			if (response.status === 401 || response.status === 403) {
				yield put(push('/unauthorized'));
			} else {
				const allConfigs = yield response.json();
				yield put(loadConfigsByNamespaceSuccess(allConfigs));
			}
		} catch (err) {
			yield put(loadConfigsByNamespaceError(err));
		}
	}
}

export function* updateConfig(action) {
	const { updatedConfigs } = action;
	let success = 1;
	let response;
	for (let i = 0; i < updatedConfigs.length; i++) {
		response = yield updateSingleConfig(updatedConfigs[i]);
		if (response.success === false) {
			success = 0;
			break;
		}
	}
	if (success === 1) {
		yield put(onUpdateConfigSuccess(response));
		yield put(loadAllNamespace());
	} else {
		yield put(onUpdateConfigFailure(response));
	}
}

export function* updateSingleConfig(config) {
	const configBody = { ...config };
	delete configBody.id;
	const url = `${CONFIG_API_BASE_URL}/id/${config.id}`;
	const options = {
		method: 'PUT',
		credentials: 'include',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(config),
	};
	const response = yield call(fetch, url, options);
	if (response.status === 401 || response.status === 403) {
		yield put(push('/unauthorized'));
	}
	const responseMessage = yield response.json();
	return responseMessage;
}

function* getLastUpdatedAt() {
	const url = `${CONFIG_API_BASE_URL}/last_updated`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		const responseMessage = yield response.json();
		yield put(loadConfigLastUpdatedAtSuccess(responseMessage));
	} catch (err) {
		yield put(loadConfigLastUpdatedAtError(err));
	}
}

export function* getAllBrands() {
	const url = `${BRAND_API_BASE_URL}/?dataVolume=config`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const brands = yield response.json();
			yield put(brandsLoadedSuccessfully(brands));
		}
	} catch (err) {
		yield put(brandsLoadingError(err));
	}
}

export function* updateBrands(action) {
	const brands = action.updatedBrands.map((brand) => ({ id: brand.id, websiteTakeover: brand.websiteTakeover, stickySidebarAds: brand.stickySidebarAds }));
	const url = `${BRAND_API_BASE_URL}/adconfig`;
	const options = {
		method: 'PUT',
		credentials: 'include',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			reqOrigin: 'CONFIG',
			brands: JSON.stringify(brands),
		}),
	};
	const response = yield call(fetch, url, options);
	if (response.status === 401 || response.status === 403) {
		yield put(push('/unauthorized'));
	} else if (response.status === 200) {
		yield put(onUpdateConfigSuccess(response));
		yield put(loadAllNamespace());
	} else {
		yield put(onUpdateConfigFailure(response));
	}
}

export default function* dynamicConfigData() {
	yield takeLatest(LOAD_ALL_NAMESPACE, getAllNamespace);
	yield takeLatest(SELECT_NAMESPACE, getConfigsByNamespace);
	yield takeLatest(LOAD_CONFIGS_BY_NAMESPACE, getConfigsByNamespace);
	yield takeLatest(UPDATE_CONFIG, updateConfig);
	yield takeLatest(LOAD_CONFIG_LATEST_UPDATE_TIMESTAMP, getLastUpdatedAt);
	yield takeLatest(LOAD_BRANDS, getAllBrands);
	yield takeLatest(UPDATE_BRANDS, updateBrands);
}
