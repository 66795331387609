import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GlobalStyles from './global-styles';
import * as serviceWorker from './serviceWorker';
import 'sanitize.css/sanitize.css';
import './output.css';

ReactDOM.render(
	<>
		<GlobalStyles />
		<App />
	</>,
	document.getElementById('app'),
);

if (module.hot) {
	module.hot.accept('./App', () => { ReactDOM.render(App); });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
