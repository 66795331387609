import React from 'react';
import T from 'prop-types';
import { Col } from 'react-styled-flexboxgrid';
import { ListHeaderWrapper } from '../../WebinarComponents/styles';

const ListHeader = (props) => {
	const { selectedStatus } = props;
	return (
		<ListHeaderWrapper>
			<Col xs={2} sm={1} md={1}>
				S.No
			</Col>
			<Col xs={6} sm={2} md={2}>
				Full Name
			</Col>
			<Col xs={false} sm={2} md={2}>
				Email
			</Col>
			<Col xs={false} sm={2} md={2}>
				Phone Number
			</Col>
			<Col xs={2} sm={2} md={1}>
				Submitted On
			</Col>
			{selectedStatus === 1 && (
				<Col xs={2} sm={1} md={1}>
					Resume Parsing Status
				</Col>
			)}
			{selectedStatus === 1 && (
				<Col xs={2} sm={1} md={2}>
					Actions
				</Col>
			)}
			{selectedStatus === 2 && (
				<Col xs={2} sm={1} md={1}>
					Approved On
				</Col>
			)}
			{selectedStatus === 2 && (
				<Col xs={2} sm={1} md={2}>
					Actions
				</Col>
			)}
			{selectedStatus === 3 && (
				<Col xs={2} sm={1} md={1}>
					Rejected On
				</Col>
			)}
			{selectedStatus === 3 && (
				<Col xs={2} sm={1} md={1}>
					Reason
				</Col>
			)}
			{selectedStatus === 3 && (
				<Col xs={2} sm={1} md={1}>
					Actions
				</Col>
			)}
			{/* {selectedStatus === 3 && (
				<Col xs={2} sm={1} md={1}>
					Hired On
				</Col>
			)}
			{selectedStatus === 3 && (
				<Col xs={2} sm={1} md={1}>
					Company
				</Col>
			)}
			{selectedStatus === 3 && (
				<Col xs={2} sm={1} md={1}>
					Role
				</Col>
			)}
			{selectedStatus === 3 && (
				<Col xs={2} sm={1} md={1}>
					Actions
				</Col>
			)} */}
		</ListHeaderWrapper>
	);
};

ListHeader.propTypes = {
	selectedStatus: T.number,
};
export default ListHeader;
