import { createSelector } from 'reselect';

const selectStoryList = (state) => state.get('storyList');

const makeSelectStoryListStories = () => createSelector(
	selectStoryList,
	(storyListState) => storyListState.get('stories'),
);
const makeSelectStoryListLoading = () => createSelector(
	selectStoryList,
	(storyListState) => storyListState.get('loading'),
);

const makeSelectStoryListError = () => createSelector(
	selectStoryList,
	(storyListState) => storyListState.get('error'),
);

const makeSelectStoryListSelectedStatus = () => createSelector(
	selectStoryList,
	(storyListState) => storyListState.get('selectedStatus'),
);

const makeSelectStoryListBrands = () => createSelector(
	selectStoryList,
	(storyListState) => storyListState.get('brands'),
);

const makeSelectStoryListSelectedBrand = () => createSelector(
	selectStoryList,
	(storyListState) => storyListState.get('selectedBrand'),
);

const makeSelectStoryListPage = () => createSelector(
	selectStoryList,
	(storyListState) => storyListState.get('page'),
);

export {
	makeSelectStoryListStories,
	makeSelectStoryListSelectedStatus,
	makeSelectStoryListBrands,
	makeSelectStoryListSelectedBrand,
	makeSelectStoryListPage,
	makeSelectStoryListLoading,
	makeSelectStoryListError,
};
