import {
	ADD_NEW_AWARD, CREATE_AWARD, AWARD_ELEMENT_ON_CHANGE, CREATE_AWARD_ERROR, CREATE_AWARD_SUCCESS, UPDATE_AWARD, UPDATE_AWARD_ERROR, UPDATE_AWARD_SUCCESS, DELETE_AWARD, DELETE_AWARD_ERROR, DELETE_AWARD_SUCCESS, SELECT_AWARD, UNMOUNT_AWARD_FORM_MESSAGE, SET_AWARD_SLUG_UNIQUE_VALUE, ADD_SECONDARY_SPONSOR, REMOVE_SECONDARY_SPONSOR, CLEAR_AWARD_MANAGER_DATA,
} from './type';

export function addNewAward() {
	return {
		type: ADD_NEW_AWARD,
	};
}

export function onAwardFormElementChange(elementData, index) {
	return {
		type: AWARD_ELEMENT_ON_CHANGE,
		elementData,
		index,
	};
}

export function createAward(award) {
	return {
		type: CREATE_AWARD,
		award,
	};
}

export function createAwardSuccess(successResponse) {
	return {
		type: CREATE_AWARD_SUCCESS,
		successResponse,
	};
}

export function createAwardFailure(failureResponse) {
	return {
		type: CREATE_AWARD_ERROR,
		failureResponse,
	};
}

export function updateAward(id, updatedData) {
	return {
		type: UPDATE_AWARD,
		id,
		updatedData,
	};
}

export function updateAwardSuccess(successResponse) {
	return {
		type: UPDATE_AWARD_SUCCESS,
		successResponse,
	};
}

export function updateAwardFailure(failureResponse) {
	return {
		type: UPDATE_AWARD_ERROR,
		failureResponse,
	};
}

export function deleteAward(id) {
	return {
		type: DELETE_AWARD,
		id,
	};
}

export function deleteAwardFailure(failureResponse) {
	return {
		type: DELETE_AWARD_ERROR,
		failureResponse,
	};
}

export function deleteAwardSuccess(successResponse) {
	return {
		type: DELETE_AWARD_SUCCESS,
		successResponse,
	};
}

export function awardSelect(selectedAward) {
	return {
		type: SELECT_AWARD,
		selectedAward,
	};
}

export function unmountAwardFormMessage() {
	return {
		type: UNMOUNT_AWARD_FORM_MESSAGE,
	};
}

export function setAwardSlugUniqueValue(value) {
	return {
		type: SET_AWARD_SLUG_UNIQUE_VALUE,
		value,
	};
}

export function addSponsor() {
	return {
		type: ADD_SECONDARY_SPONSOR,
	};
}

export function removeSponsor(index) {
	return {
		type: REMOVE_SECONDARY_SPONSOR,
		index,
	};
}

export function setInitialState() {
	return {
		type: CLEAR_AWARD_MANAGER_DATA,
	};
}
