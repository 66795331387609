import { Map } from 'immutable';
import {
	ON_STORY_SELECT, UPDATE_TAGGED_COMPANIES, UPDATE_TAGGED_INFLUENCERS, SAVE_STORY_ASSOCIATIONS, SAVE_STORY_ASSOCIATIONS_SUCCESS, SAVE_STORY_ASSOCIATIONS_FAILURE, FETCH_STORY_ASSOCIATIONS_SUCCESS, FETCH_STORY_ASSOCIATIONS, FETCH_STORY_ASSOCIATIONS_FAILURE, SET_FORM_MESSAGE, CLEAR_STATE,
} from './type';
import { SAVING_MESSAGE, SAVING_SUCCESS_MESSAGE, SAVING_FAILURE_MESSAGE } from './constants';

const initialState = Map({
	selectedStory: null,
	taggedCompanies: [],
	taggedInfluencers: [],
	disableButton: false,
	formMessage: null,
	loading: false,
});

function tagStoriesReducer(state = initialState, action) {
	switch (action.type) {
	case ON_STORY_SELECT:
		return state
			.set('selectedStory', action.story)
			.set('formMessage', null);
	case UPDATE_TAGGED_COMPANIES:
		return state
			.set('taggedCompanies', action.data);
	case UPDATE_TAGGED_INFLUENCERS:
		return state
			.set('taggedInfluencers', action.data);
	case FETCH_STORY_ASSOCIATIONS:
		return state
			.set('loading', true);
	case FETCH_STORY_ASSOCIATIONS_SUCCESS:
		return state
			.set('loading', false)
			.set('taggedCompanies', action.associations.companies)
			.set('taggedInfluencers', action.associations.influencers);
	case FETCH_STORY_ASSOCIATIONS_FAILURE:
		return state
			.set('loading', false);
	case SAVE_STORY_ASSOCIATIONS:
		return state
			.set('formMessage', SAVING_MESSAGE)
			.set('disableButtons', true);
	case SAVE_STORY_ASSOCIATIONS_SUCCESS:
		return state
			.set('formMessage', SAVING_SUCCESS_MESSAGE)
			.set('disableButtons', false);
	case SAVE_STORY_ASSOCIATIONS_FAILURE:
		return state
			.set('formMessage', SAVING_FAILURE_MESSAGE)
			.set('disableButtons', false);
	case SET_FORM_MESSAGE:
		return state
			.set('formMessage', action.message);
	case CLEAR_STATE:
		return initialState;
	default:
		return state;
	}
}

export default tagStoriesReducer;
