import { DESK_BASE_API_URL } from '../containers/StoryDesk/constants';

// generate story url prefix
const generateStoryUrlPrefix = (brand) => {
	try {
		const brandSlug = brand.slug;
		const CURRENT_MONTH = `0${(new Date().getMonth() + 1).toString()}`.slice(-2);
		const CURRENT_YEAR = new Date().getFullYear();
		const domain = process.env.REACT_APP_SERVICE_PWA;
		const captableDomain = process.env.REACT_APP_SERVICE_CAPTABLE_PWA;
		let prefix;
		switch (brandSlug) {
		case 'yourstory':
			prefix = `${domain}/${CURRENT_YEAR}/${CURRENT_MONTH}/`;
			break;
		case 'herstory':
		case 'socialstory':
		case 'germany':
		case 'deutschland':
			prefix = `${domain}/${brandSlug}/${CURRENT_YEAR}/${CURRENT_MONTH}/`;
			break;
		case 'the-captable':
			prefix = `${captableDomain}/${CURRENT_YEAR}/${CURRENT_MONTH}/`;
			break;
		default:
			prefix = `${domain}/${brandSlug}/`;
			break;
		}
		return prefix;
	} catch (err) {
		console.log('Story url prefix generate error:', err.message);
	}
};

// generate video story url prefix
const generateVideoStoryUrlPrefix = () => {
	try {
		const domain = process.env.REACT_APP_SERVICE_PWA;
		const url = `${domain}/video/`;
		return url;
	} catch (err) {
		console.log('Video story url prefix generate error: ', err.message);
	}
};

// generate story url slug
const generateStoryUrlSlug = (title) => {
	try {
		const slug = stringToSlug(title);
		return slug;
	} catch (err) {
		console.log('Story url slug generate error:', err.message);
	}
};

// this function generates slug from title
const stringToSlug = (str) => {
	let mstr = str.replace(/^\s+|\s+$/g, ''); // trim
	mstr = mstr.toLowerCase();
	const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
	const to = 'aaaaeeeeiiiioooouuuunc------';
	for (let i = 0, l = from.length; i < l; i += 1) {
		mstr = mstr.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
	}
	mstr = mstr.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
		.replace(/\s+/g, '-') // collapse whitespace and replace by -
		.replace(/-+/g, '-'); // collapse dashes
	mstr = mstr.slice(0, 50);
	// if (mstr !== '') {
	//		 mstr = `${mstr}-${Math.random().toString(36).substr(2, 10)}`;
	// }
	return mstr;
};

const getTranslatedStorySlug = async (title, brand) => {
	let slug;
	if (!['en_us', 'en_gb'].includes(brand.locale.toLowerCase())) {
		try {
			const url = `${DESK_BASE_API_URL}/translate`;
			const formData = new FormData();
			formData.append('text', title);
			const options = {
				method: 'POST',
				credentials: 'include',
				body: formData,
			};
			const response = await fetch(url, options);
			const translation = await response.json();
			slug = generateStoryUrlSlug(translation);
		} catch (err) {
			console.log('-----Google Translate Error:', err);
		}
	} else {
		slug = generateStoryUrlSlug(title);
	}
	return slug;
};

export {
	generateStoryUrlPrefix, generateStoryUrlSlug, generateVideoStoryUrlPrefix, getTranslatedStorySlug,
};
