import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';

import ProfileImagePlaceholder from '../../images/img-placeholder.png';

const YOURSTORY_URL = process.env.REACT_APP_SERVICE_PWA;

const AuthorCard = styled(Row)`
		min-height: 80px;
		height: auto;
		width: 300px;
		border-radius: 5px;
		box-shadow: 1px 2px 3px 0px #DDDDDD;
		align-items: center;
		
		.employee-font {
				font-size: 0.8rem;
				color: #a1a1a1
				margin: 0;
		}

		.profile-link {
				font-size: 0.9rem;
				:hover {
						color: #e5002d;
						text-decoration: underline;
				}
		}
		${(props) => !props.speaker && css`
				background-color: #f5f5f0;
		`}
`;

const AuthorMetaCard = (props) => {
	AuthorMetaCard.propTypes = {
		author: PropTypes.object,
		speaker: PropTypes.bool,
		type: PropTypes.string,
	};
	let img = ProfileImagePlaceholder;
	if (props.speaker) {
		if (props.author.profilePicture && props.author.profilePicture.url) img = props.author.profilePicture.url;
	} else if (props.author && props.author.image_url) img = props.author.image_url;
	return (
		<div>
			<AuthorCard className="ml-1 mr-1 mt-3" speaker={props.speaker}>
				<Col xs={3}>
					<img
						className="profile-img"
						src={img}
						alt="Profile"
					/>
				</Col>
				<Col xs={9}>
					<p className="m-0">
						<b>{props.author.label.substring(0, 22)}</b>
					</p>
					{
						props.author.employee
							? <p className="employee-font">Employee</p>
							: null
					}
					<a
						href={props.speaker ? `${YOURSTORY_URL}/people/${props.author.username}` : `${YOURSTORY_URL}/author/${props.author.username}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<span className="profile-link">View Profile</span>
					</a>
				</Col>
				{props.speaker && !(props.author && props.author.bio)
					? <div className="employee-font error-text m-2">
						{`This ${props.type} doesn't have a bio. Please add it from Influencer Manager`}
					</div>
					: null}
			</AuthorCard>
		</div>
	);
};

export default AuthorMetaCard;
