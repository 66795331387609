import {
	call, put, takeLatest, select,
} from 'redux-saga/effects';
import { FETCH_MEDIA_BY_BRAND_AND_TYPE, FILTER_MEDIA } from './type';
import { ASSET_BASE_API_URL } from './constants';
import {
	fetchMediaByBrandAndTypeFailure, fetchMediaByBrandAndTypeSuccess, filterMediaFailure, filterMediaSuccess, setGalleryUpdatedTime,
} from './actions';
import { redirectTo } from '../../utils/accessRedirection';
import { makeSelectLocation } from '../App/selectors';

let CURRENT_LOCATION;

export function* getMediaByBrand(action) {
	const url = `${ASSET_BASE_API_URL}/brand/${action.brand}/type/${action.mediaType}/page/${action.page}/sort/${action.sortBy}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			redirectTo(response.status, CURRENT_LOCATION);
		} else {
			const media = yield response.json();
			yield put(fetchMediaByBrandAndTypeSuccess(media, action.concat));
			const time = new Date().toLocaleTimeString('en-gb');
			yield put(setGalleryUpdatedTime(time));
		}
	} catch (err) {
		yield put(fetchMediaByBrandAndTypeFailure(err));
		redirectTo(500, CURRENT_LOCATION);
	}
}

export function* searchMedia(action) {
	const url = `${ASSET_BASE_API_URL}/filter/gallery`;
	const options = {
		method: 'POST',
		credentials: 'include',
		body: action.value,
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403].includes(response.status)) {
			redirectTo(response.status, CURRENT_LOCATION);
		} else if (response.status === 500) {
			yield put(filterMediaFailure('error'));
		} else {
			const parsedResponse = yield response.json();
			yield put(filterMediaSuccess(parsedResponse, (action.concat)));
		}
	} catch (err) {
		yield put(filterMediaFailure(err));
	}
}

export default function* mediaGalleryData() {
	const location = yield select(makeSelectLocation());
	CURRENT_LOCATION = location.pathname;
	yield takeLatest(FETCH_MEDIA_BY_BRAND_AND_TYPE, getMediaByBrand);
	yield takeLatest(FILTER_MEDIA, searchMedia);
}
