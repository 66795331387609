/* eslint-disable no-param-reassign */
import ReactQuill from 'react-quill';

const { Quill } = ReactQuill;
const BlockEmbed = Quill.import('blots/block/embed');
const ATTRIBUTES = ['height', 'width', 'src', 'style', 'align'];

const captionStyle = `
		font-family: aktiv-grotesk;
		font-size: 1rem;
		font-style: italic;
		font-weight: normal;
		line-height: 1.625rem;
		color: #191A1B;
		padding-top: 1rem;
		padding-bottom: 1rem;
		opacity: 0.5;
		text-align: center;
`;

class ImageBlot extends BlockEmbed {
	static create(value) {
		const node = super.create();
		let id;
		let url;
		let caption;
		let alt;
		if (typeof value === 'string') {
			if (value.includes('a12bc34de56fg')) {
				url = value.split('a12bc34de56fg')[0];
				caption = value.split('a12bc34de56fg')[1];
				alt = value.split('a12bc34de56fg')[1];
			}
		} else {
			({
				id, url, caption, alt,
			} = value);

			console.log({
				id, url, caption, alt,
			});
		}

		node.classList.add('embed');
		node.setAttribute('contenteditable', 'false');
		const imageElement = document.createElement('img');
		imageElement.classList.add('disableEmbedPointerEvents');
		const captionElement = document.createElement('figcaption');
		captionElement.classList.add('disableEmbedPointerEvents');
		captionElement.setAttribute('align', 'left');
		captionElement.setAttribute('style', captionStyle);

		if (id) {
			node.dataset.id = id;
			imageElement.setAttribute('id', id);
		}
		if (url) {
			node.dataset.url = url;
			imageElement.setAttribute('src', url);
		}
		if (alt) {
			node.dataset.alt = alt;
			imageElement.setAttribute('alt', alt);
		}
		if (caption) {
			node.dataset.caption = caption;
			captionElement.innerHTML = caption;
		}

		node.appendChild(imageElement);
		node.appendChild(captionElement);
		return node;
	}

	static formats(domNode) {
		return ATTRIBUTES.reduce((formats, attribute) => {
			if (domNode.hasAttribute(attribute)) {
				formats[attribute] = domNode.getAttribute(attribute);
			}
			const imageElement = domNode.querySelector('img');
			if (attribute !== 'style' && imageElement && imageElement.hasAttribute(attribute)) {
				formats[attribute] = imageElement.getAttribute(attribute);
			}
			return formats;
		}, {});
	}

	static match(url) {
		return /\.(jpe?g|gif|png)$/.test(url) || /^data:image\/.+;base64/.test(url);
	}

	static value(domNode) {
		return {
			id: domNode.dataset.id,
			url: domNode.dataset.url,
			caption: domNode.dataset.caption,
			alt: domNode.dataset.alt,
		};
	}

	format(name, value) {
		if (ATTRIBUTES.indexOf(name) > -1) {
			if (name === 'width' || name === 'height' || name === 'src') {
				const imageElement = this.domNode.querySelector('img');
				if (value) {
					imageElement.setAttribute(name, value);
				} else {
					imageElement.removeAttribute(name);
				}
				this.domNode.removeAttribute('style');
				imageElement.removeAttribute('style');
			} else if (name === 'style') {
				if (value) {
					this.domNode.setAttribute(name, value);
				} else {
					this.domNode.removeAttribute(name);
				}
				const imageElement = this.domNode.querySelector('img');
				imageElement.removeAttribute('height');
				imageElement.removeAttribute('width');
				imageElement.setAttribute('style', 'width: 100%; height:auto');
			} else if (value) {
				this.domNode.setAttribute(name, value);
			} else {
				this.domNode.removeAttribute(name);
			}
		} else {
			super.format(name, value);
		}
	}
}

ImageBlot.blotName = 'image';
ImageBlot.tagName = 'figure';
ImageBlot.className = 'image';

export default ImageBlot;
