import { createSelector } from 'reselect';

const selectPeople = (state) => state.get('people');

const makeSelectGetOperation = () => createSelector(
	selectPeople,
	(peopleState) => peopleState.get('operation'),
);

const makeSelectFormData = () => createSelector(
	selectPeople,
	(peopleState) => peopleState.get('formData'),
);

const makeSelectSelectedPerson = () => createSelector(
	selectPeople,
	(peopleState) => peopleState.get('selectedPerson'),
);

const makeSelectFormMessage = () => createSelector(
	selectPeople,
	(peopleState) => peopleState.get('formMessage'),
);

const makeSelectLoading = () => createSelector(
	selectPeople,
	(peopleState) => peopleState.get('loading'),
);

const makeSelectDropdownTitle = () => createSelector(
	selectPeople,
	(peopleState) => peopleState.get('dropdownTitle'),
);

const makeSelectUpdated = () => createSelector(
	selectPeople,
	(peopleState) => peopleState.get('isInfoUpdated'),
);

const makeSelectOrigData = () => createSelector(
	selectPeople,
	(peopleState) => peopleState.get('unchangedFormData'),
);

export {
	makeSelectGetOperation,
	makeSelectFormData,
	makeSelectSelectedPerson,
	makeSelectFormMessage,
	makeSelectLoading,
	makeSelectDropdownTitle,
	makeSelectUpdated,
	makeSelectOrigData,
};
