import React, { useEffect, useReducer, useState } from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import 'rc-pagination/assets/index.css';
import Pagination from 'rc-pagination';
import locale from '../WatchlistedStories/en_US';
import {
	WrapperContainer, Input, Button, DropDown,
} from '../../components/Styles';
import {
	BRAND_API_BASE_URL,
	FETCH_BRAND,
	FETCH_BRAND_SUCCESS,
	FETCH_BRAND_FAILURE,
	SET_BRAND_ACTION,
} from './constants';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';
import WebstoryListHeader from '../../components/DashboardWebStory/WebstoryListHeader';
import WebstoryList from '../../components/DashboardWebStory/WebstoryList';

function fetchBrand() {
	return {
		type: FETCH_BRAND,
	};
}

function setBrandAction(brands) {
	return {
		type: SET_BRAND_ACTION,
		brands,
	};
}

function fetchBrandSuccess({ brands, selectedPage }) {
	return {
		type: FETCH_BRAND_SUCCESS,
		brands,
		selectedPage,
	};
}

function fetchBrandFailure(error) {
	return {
		type: FETCH_BRAND_FAILURE,
		error,
	};
}

const initialState = {
	brands: [],
	brand: {},
	brandCount: null,
	loading: false,
	error: false,
	page: 1,
};

function reducer(state, action) {
	switch (action.type) {
	case FETCH_BRAND:
		return { ...state, loading: true };
	case SET_BRAND_ACTION:
		return {
			...state, loading: false, brand: action.brands.data,
		};
	case FETCH_BRAND_SUCCESS:
		return {
			...state, loading: false, brands: action.brands.data.rows, page: action.selectedPage, brandCount: action.brands.data.count,
		};
	case FETCH_BRAND_FAILURE:
		return {
			...state, loading: false, error: true, brands: [],
		};
	default:
		return state;
	}
}

const DashboardWebStoryManager = (props) => {
	const { pageTitle } = props;
	const history = useHistory();
	const [state, dispatch] = useReducer(reducer, initialState);
	const [searchValue, setSearchValue] = useState(null);
	const [currentSkip, setCurrentSkip] = useState(0);
	const {
		brands, loading, brandCount, page,
	} = state;

	const handleActions = async ({
		id,
		method,
		selectedStatus,
		position,
		callBack,
	}) => {
		const options = {
			method,
			credentials: 'include',
		};
		const postParam = {
			DELETE: `/id?id=${id}`,
			// PATCH: `/id?id=${id}&status=${selectedStatus}`,
			PATCH: position ? `?id=${id}&newPosition=${position}` : `/id/?id=${id}&status=${selectedStatus}`,
			GET: `/id?id=${id}&source=YourStory`,
		};
		try {
			dispatch(fetchBrand());
			const response = await fetch(`${BRAND_API_BASE_URL}${postParam[method]}`, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (method === 'GET' && response.status === 200) {
				const brand = await response.json();
				dispatch(setBrandAction(brand));
			} else if (callBack) callBack();
			dispatch(fetchBrandFailure(response.err));
			handleGetBrands({});
		} catch (err) {
			dispatch(fetchBrandFailure(err));
		}
	};

	const handleGetBrands = async ({ selectedPage, statusFilter = 'all', searchFilter = searchValue }) => {
		dispatch(fetchBrand());
		const limit = 10;
		const skip = (parseInt((selectedPage || 1), 10)) * limit - limit;
		setCurrentSkip(skip);
		const statusFilterPrm = statusFilter && statusFilter.toLowerCase() !== 'all' ? `&status=${statusFilter}` : '';
		const searchFilterPrm = searchFilter && (searchFilter.length >= 3 || searchFilter.length === 0) ? `&title=${searchFilter}` : '';
		let url = `${BRAND_API_BASE_URL}/source?limit=${limit}&skip=${skip}${searchFilterPrm}${statusFilterPrm}&source=YourStory`;
		if (statusFilterPrm?.length > 0 || searchFilterPrm?.length > 0) {
			url = `${BRAND_API_BASE_URL}?limit=${limit}&skip=${skip}${searchFilterPrm}${statusFilterPrm}&source=YourStory`;
		}
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else {
				const brands = await response.json();
				if (brands && Object.keys(brands?.data)?.length > 0) {
					dispatch(fetchBrandSuccess({ brands, selectedPage }));
				}
			}
		} catch (err) {
			dispatch(fetchBrandFailure(err));
		}
	};

	useEffect(() => {
		pageTitle('webStoryManager');
		handleGetBrands({ });
	}, []);

	const getBrandCategoryLists = () => (
		brands && brands.map((data, index) => <WebstoryList
			key={data.id}
			id={data.id}
			snNo={currentSkip + index + 1}
			title={data.title}
			url={data?.webStoryUrl || '-'}
			brand={data.brandName}
			propertySlug={data.propertySlug}
			brandCategory={data.categoryName}
			status={data.status}
			handleActions={handleActions}
			lastIndex={brandCount}
			updatedAt={data.updatedAt}
		/>));

	const renderBrandCategoryLists = () => {
		if (brands && brands.length > 0) {
			return (
				<>
					<WebstoryListHeader />
					{getBrandCategoryLists()}
				</>
			);
		}
		return (
			<Row style={{
				width: '100%', minHeight: '400px', justifyContent: 'center', alignItems: 'center',
			}}
			>
				<div>
					<h1 style={{ color: '#bbb' }}>
						No WebStories To Display.
					</h1>
				</div>
			</Row>
		);
	};

	return (
		<WrapperContainer>
			<Row className="mb-4">
				<Col sm={5} xs={12} className="mb-2">
					<p className="mb-0">A list of web stories related to Your Story</p>
				</Col>
				<Col sm={7} xs={12}>
					<Row style={{ justifyContent: 'flex-end' }} className="mr-1 mb-2">
						<p className="text-bold mb-0 pt-1 d-none">Filter By Status</p>
						<Col sm={3} xs={12} className="mb-2 d-none">
							<DropDown className="mt-0" onChange={(event) => handleGetBrands({ statusFilter: event.target.value })}>
								<option value="ALL">ALL</option>
								<option value="ACTIVE">ACTIVE</option>
								<option value="INACTIVE">INACTIVE</option>
							</DropDown>
						</Col>
						<Col sm={4} xs={12} className="mb-2">
							<Input
								id="search-box"
								type="text"
								className="form-control"
								placeholder="Search Story By Title"
								onChange={(e) => {
									setSearchValue(e.target.value);
									handleGetBrands({ searchFilter: e.target.value });
								}}
							/>
						</Col>
						<Col sm={3} xs={12} className="mb-2">
							<Button
								primary
								no_radius
								className="mb-0"
								onClick={() => history.push('/manager/dashboard/webstory-editor')}
							>
								<b>ADD NEW WEB STORY</b>
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
			{loading
				? <LoadingIcon />
				: <>
					{renderBrandCategoryLists()}
					{<Row
						className="mt-3 pr-4"
						style={{ justifyContent: 'flex-end' }}
					>
						<Pagination
							onChange={(event) => handleGetBrands({ selectedPage: event })}
							current={page || 1}
							total={brandCount}
							locale={locale}
						/>
					</Row>}
				</>}
		</WrapperContainer>
	);
};

export default DashboardWebStoryManager;

DashboardWebStoryManager.propTypes = {
	pageTitle: PropTypes.func,
};
