export const WEBSTORY_API_BASE_URL = '/api/v2/rw/boni/web-stories';

export const WEBSTORY_LOADING_MESSAGE = { intent: 'info', message: 'Loading...' };
export const WEBSTORY_CREATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Webstory Saved Successfully' };
export const WEBSTORY_CREATE_LOAD_MESSAGE = { intent: 'info', message: 'Saving...' };

export const WEBSTORY_UPDATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Webstory Updated Successfully' };
export const WEBSTORY_UPDATE_LOAD_MESSAGE = { intent: 'info', message: 'Updating...' };

export const WEBSTORY_DELETE_SUCCESS_MESSAGE = { intent: 'success', message: 'Webstory Deleted Successfully' };
export const WEBSTORY_DELETE_LOAD_MESSAGE = { intent: 'info', message: 'Deleting...' };
export const WEBSTORY_ERROR_MESSAGE = { intent: 'danger', message: 'ERROR!!!' };
