import { Map } from 'immutable';
import {
	FETCH_FEED_CONTENTS_BY_PAGE, FETCH_FEED_CONTENTS_BY_PAGE_SUCCESS, FETCH_FEED_CONTENTS_BY_PAGE_ERROR, SET_RSS_FEED_CONTENT_PAGE, FILTER_WATCHLIST_STORIES, FILTER_WATCHLIST_STORIES_SUCCESS, FILTER_WATCHLIST_STORIES_ERROR, REMOVE_WATCHLIST_STORIES_FILTER, SET_WATCHLIST_FILTER_TYPE, SET_WATCHLIST_FILTER_VALUE, FETCH_ALL_FEED_SOURCE, FETCH_ALL_FEED_SOURCE_SUCCESS, FETCH_ALL_FEED_SOURCE_ERROR, ON_FEED_SOURCE_SELECT,
} from './type';

const FILTER_TYPE = 'title';
const ALL_FEED = {
	title: 'All',
	id: 0,
};

const initialState = Map({
	loading: false,
	error: false,
	allFeedContents: null,
	allFeedSource: null,
	selectedFeedSource: ALL_FEED,
	page: 1,
	search: false,
	searchType: FILTER_TYPE,
	searchValue: '',
	storiesCount: 0,
});

function feedContentReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_ALL_FEED_SOURCE:
		return state
			.set('loading', true)
			.set('error', false)
			.set('allFeedContents', null)
			.set('allFeedSource', null);
	case FETCH_ALL_FEED_SOURCE_SUCCESS: {
		const temp = [ALL_FEED];
		const sources = action.feedSource;
		sources.forEach((item) => {
			temp.push(item);
		});
		return state
			.set('loading', false)
			.set('error', false)
			.set('allFeedContents', null)
			.set('storiesCount', null)
			.set('allFeedSource', temp);
	}
	case FETCH_ALL_FEED_SOURCE_ERROR:
		return state
			.set('loading', false)
			.set('error', true)
			.set('allFeedContents', null)
			.set('allFeedSource', null);
	case FETCH_FEED_CONTENTS_BY_PAGE:
		return state
			.set('loading', true)
			.set('error', false);
	case FETCH_FEED_CONTENTS_BY_PAGE_SUCCESS:
		return state
			.set('loading', false)
			.set('error', false)
			.set('allFeedContents', action.feedContents[0])
			.set('storiesCount', action.feedContents[1]);
	case FETCH_FEED_CONTENTS_BY_PAGE_ERROR:
		return state
			.set('loading', false)
			.set('error', true)
			.set('allFeedContents', null);
	case SET_RSS_FEED_CONTENT_PAGE:
		return state
			.set('page', action.page);
	case FILTER_WATCHLIST_STORIES:
		return state
			.set('search', true)
			.set('searchType', action.searchType)
			.set('searchValue', action.searchValue)
			.set('loading', true);
	case FILTER_WATCHLIST_STORIES_SUCCESS:
		return state
			.set('allFeedContents', action.filteredStories[0])
			.set('storiesCount', action.filteredStories[1])
			.set('loading', false);
	case FILTER_WATCHLIST_STORIES_ERROR:
		return state
			.set('loading', false)
			.set('error', true);
	case REMOVE_WATCHLIST_STORIES_FILTER:
		return state
			.set('search', false)
			.set('searchType', FILTER_TYPE)
			.set('searchValue', '')
			.set('page', 1);
	case SET_WATCHLIST_FILTER_TYPE:
		return state
			.set('searchType', action.filterType);
	case SET_WATCHLIST_FILTER_VALUE:
		return state
			.set('searchValue', action.filterValue);
	case ON_FEED_SOURCE_SELECT:
		return state
			.set('selectedFeedSource', action.selectedFeed);
	default:
		return state;
	}
}

export default feedContentReducer;
