import {
	GET_LATEST_ACTIVITY_STREAM,
	GET_LATEST_ACTIVITY_STREAM_ERROR,
	GET_LATEST_ACTIVITY_STREAM_SUCCESS,
	GET_ALL_ACTIVITY_STREAM,
	GET_ALL_ACTIVITY_STREAM_ERROR,
	GET_ALL_ACTIVITY_STREAM_SUCCESS,
	LOAD_ALL_BRANDS,
	LOAD_ALL_BRANDS_SUCCESS,
	LOAD_ALL_BRANDS_ERROR,
	SELECT_BRAND,
} from './type';

export function getLatestActivityStream() {
	return {
		type: GET_LATEST_ACTIVITY_STREAM,
	};
}

export function getLatestActivityStreamSuccess(successResponse) {
	return {
		type: GET_LATEST_ACTIVITY_STREAM_SUCCESS,
		latest: successResponse,
	};
}

export function getLatestActivityStreamError(error) {
	return {
		type: GET_LATEST_ACTIVITY_STREAM_ERROR,
		error,
	};
}

export function getAllActivityStream(offset = 0, brand = null, start = null, end = null, clearStream = false) {
	return {
		type: GET_ALL_ACTIVITY_STREAM,
		offset,
		brand,
		start,
		end,
		clearStream,
	};
}

export function getAllActivityStreamSuccess(successResponse, clearStream) {
	return {
		type: GET_ALL_ACTIVITY_STREAM_SUCCESS,
		all: successResponse,
		clearStream,
	};
}

export function getAllActivityStreamError(error) {
	return {
		type: GET_ALL_ACTIVITY_STREAM_ERROR,
		error,
	};
}

export function loadAllBrands() {
	return {
		type: LOAD_ALL_BRANDS,
	};
}

export function brandsLoadedSuccessfully(allBrands) {
	return {
		type: LOAD_ALL_BRANDS_SUCCESS,
		allBrands,
	};
}

export function brandsLoadingError(error) {
	return {
		type: LOAD_ALL_BRANDS_ERROR,
		error,
	};
}

export function brandSelect(selectedBrand) {
	return {
		type: SELECT_BRAND,
		selectedBrand,
	};
}
