import {
	SET_TAB_STATE, SET_LOADING_STATE, SET_ERROR_STATE, GET_SERIES_DATA, SET_SERIES_DATA, SET_SEARCH_TEXT,
	GET_YSTV_SERIES_DATA, SET_YSTV_SERIES_DATA, PUT_YSTV_SERIES_DATA,
} from './type';

export function setLoadingState(value) {
	return {
		type: SET_LOADING_STATE,
		value,
	};
}

export function setErrorState(value) {
	return {
		type: SET_ERROR_STATE,
		value,
	};
}

export function setTabState(value) {
	return {
		type: SET_TAB_STATE,
		value,
	};
}

export function getSeriesData() {
	return {
		type: GET_SERIES_DATA,
	};
}

export function setSeriesData(value) {
	return {
		type: SET_SERIES_DATA,
		value,
	};
}

export function setSearchText(value) {
	return {
		type: SET_SEARCH_TEXT,
		value,
	};
}

export function getYSTVSeriesData() {
	return {
		type: GET_YSTV_SERIES_DATA,
	};
}

export function setYSTVSeriesData(value) {
	return {
		type: SET_YSTV_SERIES_DATA,
		value,
	};
}

export function putYSTVSeriesData(value) {
	return {
		type: PUT_YSTV_SERIES_DATA,
		value,
	};
}
