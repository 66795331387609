import { Map } from 'immutable';
import {
	FETCH_CUSTOM_FIELDS,
	FETCH_CUSTOM_FIELDS_SUCCESS,
	FETCH_CUSTOM_FIELDS_FAILURE,
	FILTER_CUSTOM_FIELDS,
	FILTER_CUSTOM_FIELDS_SUCCESS,
	FILTER_CUSTOM_FIELDS_FAILURE,
	SET_CUSTOM_FIELDS_PAGE,
	CLEAR_DATA,
} from './type';

const initialState = Map({
	showModal: false,
	page: 1,
	limit: 10,
	subtitle: null,
	formElements: [],
	fieldsCount: 0,
	loading: false,
	error: false,
});

function customFieldReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_CUSTOM_FIELDS:
		return state
			.set('loading', true)
			.set('error', false)
			.set('formElements', null);
	case FETCH_CUSTOM_FIELDS_SUCCESS:
		return state
			.set('loading', false)
			.set('error', false)
			.set('formElements', action.formElements[0])
			.set('fieldsCount', action.formElements[1]);
	case FETCH_CUSTOM_FIELDS_FAILURE:
		return state
			.set('loading', false)
			.set('error', true)
			.set('formElements', null);
	case FILTER_CUSTOM_FIELDS:
		return state
			.set('loading', true)
			.set('error', false)
			.set('formElements', null);
	case FILTER_CUSTOM_FIELDS_SUCCESS:
		return state
			.set('loading', false)
			.set('error', false)
			.set('formElements', action.formElements[0])
			.set('fieldsCount', action.formElements[1]);
	case FILTER_CUSTOM_FIELDS_FAILURE:
		return state
			.set('loading', false)
			.set('error', true)
			.set('formElements', null);
	case SET_CUSTOM_FIELDS_PAGE:
		return state
			.set('page', action.page);
	case CLEAR_DATA:
		return initialState;
	default:
		return state;
	}
}

export default customFieldReducer;
