import styled, { css } from 'styled-components';
import { Row } from 'react-styled-flexboxgrid';

const ImageWrapper = styled.div`
		display: flex;
		justify-content: center;
		.width {
				width: 50%;
				@media (max-width: 768px) {
						width: 100%; 
				}
		}
`;

const ListHeaderWrapper = styled(Row)`
		background-color: #eee;
		font-weight: bold;
		min-height: 30px;
		align-items: center;
		margin-left: 0 !important;
		margin-right: 0 !important;
`;

const ListItemWrapper = styled(Row)`
		background-color: #fffff;
		min-height: 30px;
		align-items: center;
		border-bottom: 1px solid #eee;
		margin-left: 0 !important;
		margin-right: 0 !important;
		${(props) => props.op === 'CREATE' && css`
				background-color: #e3f3e9;
		`}
		${(props) => props.op === 'UPDATE' && css`
				background-color: #d9ebf3;
		`}
		${(props) => props.op === 'DELETE' && css`
				background-color: #ffedf0;
		`}
`;

export { ImageWrapper, ListItemWrapper, ListHeaderWrapper };
