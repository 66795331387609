import React from 'react';
import PropTypes from 'prop-types';
import { Card } from './styles';
import AddPlusButton from '../../images/addPlusButton';

const PlusIconCard = (props) => (
	<Card onClick={props.onClick}>
		<AddPlusButton fill="#e5002d" height="75px" width="75px" />
	</Card>
);

PlusIconCard.propTypes = {
	onClick: PropTypes.func,
};

export default PlusIconCard;
