export const PEOPLE_API_BASE_URL = '/api/v2/rw/influencer-profiles';

export const PERSON_LOADING_MESSAGE = { intent: 'info', message: 'Loading...' };
export const PERSON_CREATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Person Saved Successfully' };
export const PERSON_CREATE_LOAD_MESSAGE = { intent: 'info', message: 'Saving...' };

export const PERSON_UPDATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Person Updated Successfully' };
export const PERSON_UPDATE_LOAD_MESSAGE = { intent: 'info', message: 'Updating...' };

export const PERSON_DELETE_SUCCESS_MESSAGE = { intent: 'success', message: 'Person Deleted Successfully' };
export const PERSON_DELETE_LOAD_MESSAGE = { intent: 'info', message: 'Deleting...' };
export const PERSON_ERROR_MESSAGE = { intent: 'danger', message: 'ERROR!!!' };
