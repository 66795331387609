import { fromJS } from 'immutable';

export const NOTIFICATIONS_API_BASE_URL = '/api/v2/rw/notifications';

export const topics = [
	{
		name: 'general',
		displayName: 'General',
		icon: 'https://cdn.yourstory.com/assets/images/ys_192x192.png',
	},
	{
		name: 'funding',
		displayName: 'Funding',
		icon: 'https://cdn.yourstory.com/assets/images/ys_192x192.png',
	},
	{
		name: 'herstory',
		displayName: 'HerStory',
		icon: 'https://cdn.yourstory.com/assets/images/hs_logo.svg',
	},
	{
		name: 'socialstory',
		displayName: 'SocialStory',
		icon: 'https://cdn.yourstory.com/assets/images/ss_logo.svg',
	},
];

export const notification = fromJS({
	title: '',
	body: '',
	click_action: '',
	topic: 'general',
	icon: 'https://cdn.yourstory.com/assets/images/ys_192x192.png',
	image: '',
});

export const notificationItems = [
	{
		name: 'title',
		displayName: 'Title',
		description: 'Title of the Notification',
		type: 'text',
		value: '',
	},
	{
		name: 'body',
		displayName: 'Message Body',
		description: 'Message Body of the Notification',
		type: 'text',
		value: '',
	},
	{
		name: 'click_action',
		displayName: 'Click Action',
		description: 'Url link that user is redirected to when notification is clicked(Don\'t add utm source and medium)',
		type: 'text',
		value: '',
	},
	{
		name: 'topic',
		displayName: 'Topic',
		description: 'Only subscribers of a this topic will receive the notification',
		type: 'dropdown',
		value: '',
	},
];
