import React, { useState, useEffect } from 'react';
import T from 'prop-types';
import Modal from 'react-modal';
import uuid from 'react-uuid';
import styled, { css } from 'styled-components';

import { Col, Row } from '../Responsive';
import {
	Button, WrapperContainer,
} from '../Styles';
import { CURATED_SET_CONFIG_URL } from '../../containers/ListingsManager/constants';
import ProfileForm from './ProfileForm';
import { LoadingIcon } from '../Basic';

const Card = styled.div`
	border: 1px solid #ddd;
	height: 300px;
	width: 200px;
	padding: 5px;
	margin: 0.25rem;
	background-color: #fff;
	cursor: pointer;
	font-size: 0.8rem;
	word-wrap: break-word;
	cursor: pointer;
	display: block;
	position: relative;
	overflow: auto;
`;

const CardWrapper = styled(Row)`
	border: 1px solid #bbb;
	background-color: #eee;
	padding: 0.5rem;
	width: 100%;
	position: relative;
	overflow: auto;
	height: 75vh;
	${(props) => props.source === 'fullPage' && css`
		height: 75vh;
	`}
`;

const ProfileListings = (props) => {
	const { pageTitle, match } = props;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [profiles, setProfileListings] = useState([]);
	const [currentProfile, setCurrentProfile] = useState({});
	const [currentProfileIndex, setCurrentProfileIndex] = useState(-1);
	const [currentCategoryIndex, setCurrentCategoryIndex] = useState(-1);
	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		pageTitle('viewAllProfileListings');
		getProfileListings();
	}, []);

	async function getProfileListings() {
		const url = `${CURATED_SET_CONFIG_URL}?brand=4`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		setLoading(true);
		const response = await fetch(url, options);
		const data = await response.json();
		if (match.params.categoryId) {
			const index = data.category.findIndex((item) => item.id === match.params.categoryId);
			setCurrentCategoryIndex(index > -1 ? index : 0);
			data.category[index].userProfile.forEach((item) => {
				item?.newOrder ? item.newOrder = '' : '';
			});
			setProfileListings(data.category[index].userProfile);
			setCategories(data.category);
		}
		setLoading(false);
	}

	function unmountModal() {
		setIsModalOpen(false);
		setCurrentProfile(null);
	}

	function renderFormModal() {
		return <Modal
			isOpen={isModalOpen}
			onRequestClose={() => unmountModal()}
			overlayClassName="modal-overlay"
			className="form-modal-profile-manager"
			shouldCloseOnOverlayClick={false}
			shouldCloseOnEsc={false}
		>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className="mb-2">
				<h3>Edit/Add New Profile Details</h3>
				<button type="button" onClick={() => unmountModal()}>
					<i className="fas fa-times light-text disablePointerEvents" />
				</button>
			</div>
			<ProfileForm
				profile={currentProfile}
				getProfileListings={getProfileListings}
				unmountModal={unmountModal}
				profileIndex={currentProfileIndex}
				categoryIndex={currentCategoryIndex}
				categoryList={categories}
			/>
		</Modal>;
	}

	async function handleDelete(index) {
		const url = `${CURATED_SET_CONFIG_URL}/brand=4`;
		setCurrentProfileIndex(index);
		const reqCategoryData = [...categories];
		let finalCategories = [];
		if (categories && categories?.length > 0) {
			reqCategoryData[currentCategoryIndex].userProfile.splice(index, 1);
			finalCategories = [...reqCategoryData];
			finalCategories[currentCategoryIndex].userProfile.forEach((item, index) => {
				item.order = index + 1;
				item.previousOrder = index + 1;
				item.newOrder = '';
			});
			console.log('finalCategories', finalCategories);
		}
		const payload = {
			category: categories && categories?.length > 0 ? finalCategories : [],
		};
		const options = {
			method: 'PUT',
			credentials: 'include',
			body: JSON.stringify(payload),
			headers: {
				'Content-Type': 'application/json',
			},
		};
		const response = await fetch(url, options);
		if (response && response.status === 200) {
			getProfileListings();
		}
	}

	return (
		<WrapperContainer>
			<Row className="mt-4">
				<Col md={8} sm={8} xs={12}>
					{categories && <Col md={8} sm={10} className="pl-0">
						This is the profiles manager to view all profiles under <strong>{categories[currentCategoryIndex]?.name.toUpperCase()}</strong> category.
					</Col>}
				</Col>
				<Col md={4} sm={4} xs={12}>
					<Col md={8} xs={8} className="pt-sm-4">
						<Button
							id="add-new-btn"
							primary
							btn_rounded
							w_100
							onClick={() => { setIsModalOpen(true); setCurrentProfileIndex(-1); }}
						>
							ADD NEW
						</Button>
					</Col>
				</Col>
			</Row>
			<Row>
				{loading
					? <LoadingIcon />
					: <>
						{(profiles && profiles?.length > 0) && <CardWrapper>
							{profiles && profiles.map((profile, index) => (
								<Card key={uuid()}>
									<div className="text-right">
										<i title="Edit The Profile" className="fa fa-pencil-square fa-lg" style={{ cursor: 'pointer' }} onClick={() => { setCurrentProfile(profile); setIsModalOpen(true); setCurrentProfileIndex(index); }} />
										<i title="Delete The Profile" className="fa fa-trash fa-lg ml-3" style={{ cursor: 'pointer' }} onClick={() => handleDelete(index)} />
									</div>
									<img src={profile?.image?.url ?? ''} alt="profile" style={{ width: '100px', height: '100px' }} />
									<p><b>Name : {profile.name}</b></p>
									<p><b>Order : </b> {profile.order}</p>
									<p><b>Description : </b> <span dangerouslySetInnerHTML={{ __html: profile.description || 'N/A' }} /></p>
									<p><b>Designation : </b> {profile.designation}</p>
									<p><b>Company Name : </b> {profile.company}</p>
									{/* <p><b>Order : </b> {profile.order}</p> */}
									{/* <p><b>Article URL : </b>
										{profile.url && <a href={profile.url} style={{ textDecoration: 'underline', color: 'blue' }} title="click here to go to company page">
											{profile.url || ''}</a>}
										{!profile.url && <span>N/A</span>}
									</p> */}
								</Card>
							))}
						</CardWrapper>}
						{(!profiles || profiles?.length === 0) && <Row style={{
							width: '100%', minHeight: '400px', justifyContent: 'center', alignItems: 'center',
						}}
						>
							<div>
								<h1 style={{ color: '#bbb' }}>
									No Profile Listings List To Display
								</h1>
							</div>
						</Row>}
					</>}
			</Row>
			{renderFormModal()}
		</WrapperContainer>
	);
};

ProfileListings.propTypes = {
	pageTitle: T.func,
	match: T.object,
};

export default ProfileListings;
