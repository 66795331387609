import { Map } from 'immutable';
import { FETCH_STORY_URL, FETCH_STORY_URL_FAILURE, FETCH_STORY_URL_SUCCESS } from './type';

const initialState = Map({
	storyUrl: null,
	loading: true,
});

function storyPreviewReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_STORY_URL:
		return state
			.set('loading', true);
	case FETCH_STORY_URL_SUCCESS:
		return state
			.set('loading', false)
			.set('storyUrl', action.storyUrl);
	case FETCH_STORY_URL_FAILURE:
		return state
			.set('loading', false);
	default:
		return state;
	}
}

export default storyPreviewReducer;
