import {
	LOAD_BRAND, LOAD_BRAND_SUCCESS, LOAD_BRAND_ERROR, CREATE_ALIAS, CREATE_ALIAS_ERROR, CREATE_ALIAS_SUCCESS, UPDATE_FORM, UPDATE_LOGO, DISCARD_FORM, UNMOUNT_ALIAS_FORM_MESSAGE,
} from './type';

export function loadBrandList() {
	return {
		type: LOAD_BRAND,
	};
}

export function brandsLoadedSuccessfully(brands) {
	return {
		type: LOAD_BRAND_SUCCESS,
		brands,
	};
}

export function brandsLoadingError(error) {
	return {
		type: LOAD_BRAND_ERROR,
		error,
	};
}

export function createAlias(alias) {
	return {
		type: CREATE_ALIAS,
		alias,
	};
}

export function createAliasSuccess(successResponse) {
	return {
		type: CREATE_ALIAS_SUCCESS,
		successResponse,
	};
}

export function createAliasFailure(failureResponse) {
	return {
		type: CREATE_ALIAS_ERROR,
		failureResponse,
	};
}

export function formElementOnChange(element, value) {
	return {
		type: UPDATE_FORM,
		element,
		value,
	};
}

export function onUpload(file, url) {
	return {
		type: UPDATE_LOGO,
		file,
		url,
	};
}

export function onDiscard() {
	return {
		type: DISCARD_FORM,
	};
}

export function unmountAliasFormMessage() {
	return {
		type: UNMOUNT_ALIAS_FORM_MESSAGE,
	};
}
