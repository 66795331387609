import React, { useState, useEffect, useReducer } from 'react';
import Modal from 'react-modal';
import { Row, Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import CreatableSelect from 'react-select/creatable';

// added to prevent html styling from source while pasting
import Delta from 'quill-delta';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import ToastMessage from '../ToastMessage';
import { Button, Input, DropDown } from '../Styles';

const Clipboard = Quill.import('modules/clipboard');

class PlainClipboardAll extends Clipboard {
	onPaste(e) {
		e.preventDefault();
		const range = this.quill.getSelection();
		const text = e.clipboardData.getData('text/plain');
		const delta = new Delta()
			.retain(range.index)
			.delete(range.length)
			.insert(text);
		const index = text.length + range.index;
		const length = 0;
		this.quill.updateContents(delta, 'silent');
		this.quill.setSelection(index, length, 'silent');
		this.quill.scrollIntoView();
	}
}

Quill.register('modules/clipboard', PlainClipboardAll, true);
// end

const customStyles = {
	option: (base) => ({
		...base,
		paddingLeft: '0.9rem',
	}),
	control: (base) => ({
		...base,
		paddingLeft: '0.9rem',
	}),
};

const createOption = (label) => ({
	label,
	value: label.toLowerCase().replace(/\W/g, ''),
});

const BROADCAST_MANAGER_API = '/api/v2/rw/broadcast-manager';

const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
const UPDATE_ERRORS = 'UPDATE_ERRORS';
const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
const SET_FORM_DATA = 'SET_FORM_DATA';
const CLEAR_DATA = 'CLEAR_DATA';

const SAVING_MESSAGE = { intent: 'info', message: 'Saving...' };
const SAVING_FAILURE_MESSAGE = { intent: 'danger', message: 'Something went wrong!' };

const initialState = {
	formData: {
		title: null,
		message: null,
		startTime: null,
		endTime: null,
		category: null,
		editorText: '',
	},
	blurredElements: {
		title: null,
		message: null,
		startTime: null,
		endTime: null,
	},
	errors: {
		title: null,
		message: null,
		startTime: null,
		endTime: null,
	},
	formMessage: null,
};

const CustomToolbar = () => (
	<div id="toolbar" xs={12} sm={9}>
		<select className="ql-size">
			<option value="small">small</option>
			<option value="normal"> Normal </option>
			<option value="large">Large</option>
			<option value="huge">Huge</option>
		</select>
		<span className="ql-formats">
			<button type="button" className="ql-bold" title="Bold" />
			<button type="button" className="ql-italic" title="Italic" />
			<button type="button" className="ql-underline" title="Underline" />
		</span>
		<span className="ql-formats">
			<button type="button" className="ql-link" />
		</span>
	</div>
);

function updateFormData(changedData) {
	return {
		type: UPDATE_FORM_DATA,
		changedData,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function setFormMessage(message) {
	return {
		type: SET_FORM_MESSAGE,
		message,
	};
}

function setFormData(data) {
	return {
		type: SET_FORM_DATA,
		data,
	};
}

function clearData() {
	return {
		type: CLEAR_DATA,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case SET_FORM_DATA:
		return { ...state, formData: { ...action.data } };
	case UPDATE_FORM_DATA: {
		const formData = { ...state.formData };
		const { changedData } = action;
		formData[changedData.name] = changedData.value;
		return { ...state, formData };
	}

	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;

		return { ...state, blurredElements };
	}
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };

	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			title: true,
			message: true,
			startTime: true,
			endTime: true,
		};
		return { ...state, blurredElements };
	}

	case SET_FORM_MESSAGE:
		return { ...state, formMessage: action.message };

	case CLEAR_DATA: {
		return { ...initialState };
	}

	default:
		return state;
	}
}

const BroadcastManagerForm = (props) => {
	const {
		open, close, data, categories,
	} = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const { formData, blurredElements, formMessage } = state;
	const [actionsDisabled, disableActions] = useState(false);
	const [position, setPosition] = useState('bottom-left');
	const [options, setCategoryOptions] = useState([]);

	useEffect(() => {
		dispatch(setFormData(data));
		if (data.message) processClassName(data.message);
		if (data.category) makeCategoryOptionValue(data.category);
	}, [data && data.id]);

	useEffect(() => {
		getErrors();
	}, [formData, blurredElements]);

	useEffect(() => {
		const options = categories.filter((val) => val).map((val) => ({
			label: val,
			value: val.toLowerCase(),
		}));
		setCategoryOptions(options);
	}, [categories]);

	function onInputChange(e) {
		const { name, value } = e.target;
		return dispatch(updateFormData({ name, value }));
	}

	function makeCategoryOptionValue(category) {
		dispatch(updateFormData({ name: 'category', value: { label: category, value: category } }));
	}

	// Extract class Name of <div>, and update position.
	function processClassName(message) {
		let newposition = message.substring(
			message.indexOf('=') + 1,
			message.indexOf('>'),
		);
		newposition = newposition.split('"')[1];
		setPosition(newposition);
	}

	function validateForm() {
		const {
			title, message, startTime, endTime,
		} = formData;
		return {
			title: !title || title.length < 1,
			message: !message || (message && message.replace(/<(.|\n)*?>/g, '').trim().length < 1),
			startTime: !startTime,
			endTime: !endTime,
		};
	}

	function handleBlur(element) {
		dispatch(updateBlurredElement(element));
	}

	function getErrors() {
		const validationErrors = validateForm(formData);
		const errors = Object.keys(validationErrors).reduce((acc, curr) => {
			acc[curr] = validationErrors[curr] && blurredElements[curr];
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	}

	async function onFormSubmit(e) {
		e.preventDefault();
		const validation = validateForm(formData);
		const validated = !Object.keys(validation).some((i) => validation[i]);
		if (validated) {
			saveContent();
		} else {
			dispatch(setAllElementsBlurred());
		}
	}

	async function saveContent() {
		try {
			let {
				message,
			} = formData;
			const {
				title, startTime, endTime, id, category, editorText,
			} = formData;
			if (editorText.trim().length > 175) {
				return alert('Message cannot have a length of more than 175 characters ');
			}
			disableActions(true);
			dispatch(setFormMessage(SAVING_MESSAGE));
			const payload = new FormData();

			message = `<div class="${position}">${message}</div>`;

			payload.append('id', id);
			payload.append('title', title);
			payload.append('message', message);
			payload.append('startTime', startTime);
			payload.append('endTime', endTime);
			payload.append('category', category ? category.label : null);

			const URL = `${BROADCAST_MANAGER_API}/${id ? 'update' : 'create'}`;
			const options = {
				method: id ? 'PUT' : 'POST',
				credentials: 'include',
				body: payload,
			};
			const response = await fetch(URL, options);
			const parsed = await response.json();
			disableActions(false);
			if (parsed.success) {
				setTimeout(() => closeModal(false), 1000);
			} else {
				dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			}
		} catch (err) {
			console.log('Error saving', err);
			dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			disableActions(false);
		}
	}

	function closeModal(unchanged) {
		dispatch(clearData());
		close(unchanged);
	}

	function renderFormMessage() {
		if (formMessage) {
			return (
				<ToastMessage
					toastData={formMessage}
					unmount={() => dispatch(setFormMessage(null))}
				/>
			);
		}
	}

	function displayErrors(field) {
		const errors = validateForm();
		const hasError = errors[field];
		return hasError ? state.blurredElements[field] : false;
	}

	let minStartTime; let
		maxStartTime;
	if (!formData.startTime
		|| (formData.startTime && (new Date(formData.startTime).toLocaleDateString('en-GB') === new Date().toLocaleDateString('en-GB')))) {
		maxStartTime = new Date(new Date(formData.startTime).setHours(23, 59, 0, 0));
		const time = moment(new Date()).tz('Asia/Calcutta').format('HH:mm').split(':');
		minStartTime = new Date(new Date().setHours(time[0], time[1], 0, 0));
	}

	let maxEndTime; let
		minEndTime;
	if (formData.endTime && (new Date(formData.startTime).toLocaleDateString('en-GB') !== new Date(formData.endTime).toLocaleDateString('en-GB'))) {
		maxEndTime = undefined;
		minEndTime = undefined;
	} else {
		const date = new Date() > new Date(formData.startTime) ? new Date() : new Date(formData.startTime);
		const time = moment(date).tz('Asia/Calcutta').format('HH:mm').split(':');
		maxEndTime = new Date(new Date(formData.startTime).setHours(23, 59, 0, 0));
		minEndTime = new Date(new Date(formData.startTime).setHours(time[0], time[1], 0, 0));
	}

	function handlePositionChange(e) {
		setPosition(e.target.value);
	}

	function handleChange(newValue) {
		dispatch(updateFormData({ name: 'category', value: newValue || null }));
	}

	function handleCreate(inputValue) {
		const newOption = createOption(inputValue);
		const newList = [...options, newOption];
		setCategoryOptions(newList);
		dispatch(updateFormData({ name: 'category', value: newOption || null }));
	}

	return (
		<Modal
			style={{ content: { overflow: 'scroll', maxHeight: '95vh' } }}
			isOpen={open}
			onRequestClose={closeModal}
			contentLabel="Add content"
			overlayClassName="modal-overlay"
			className="form-modal"
			shouldCloseOnOverlayClick={false}
		>
			<div className="w_100 mb-4 text-right">
				<button type="button" onClick={() => closeModal(true)}>
					<i className="fas fa-times light-text disablePointerEvents" />
				</button>
			</div>
			<div>
				<h2>Broadcast new message</h2>
				{renderFormMessage()}
				<form onSubmit={onFormSubmit}>
					<Row>
						<Col xs={12} sm={12} md={12}>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Title *
								</Col>
								<Col xs={12} sm={9}>
									<Input
										name="title"
										type="title"
										className="form-control"
										placeholder="Title of the message"
										value={formData && formData.title ? formData.title : ''}
										onChange={onInputChange}
										onBlur={() => handleBlur('title')}
										disabled={actionsDisabled}
										maxLength={60}
									/>
									<div style={{ textAlign: 'right', color: '#aaa' }}>
										{formData && formData.title ? formData.title.length : 0}/60
									</div>
									<ErrorMessage display={displayErrors('title')} element="Title" />
								</Col>
							</Row>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Message *
								</Col>
								<Col xs={12} sm={9}>
									<CustomToolbar />
									<ReactQuill
										id="message"
										name="message"
										style={{ height: '15rem' }}
										placeholder="Message content"
										value={formData.message}
										onChange={(content, delta, source, editor) => {
											const text = editor.getText();
											dispatch(updateFormData({ name: 'message', value: content }));
											dispatch(updateFormData({ name: 'editorText', value: text }));
										}}
										onBlur={() => handleBlur('message')}
										modules={{ toolbar: { container: '#toolbar' } }}
									/>
									<div style={{ textAlign: 'right', color: formData.editorText && formData.editorText.trim().length > 175 ? '#ff0000' : '#aaa' }}>
										{formData && formData.editorText ? formData.editorText.trim().length : 0}/175
									</div>
									<ErrorMessage display={displayErrors('message')} element="Message" />
								</Col>
							</Row>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Position
								</Col>
								<Col xs={12} sm={9}>
									<DropDown className="mt-0" value={position} onChange={handlePositionChange}>
										<option value="bottom-left">Bottom left</option>
										<option value="bottom-right">Bottom right</option>
										<option value="bottom-center">Bottom center</option>
									</DropDown>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Start time *
								</Col>

								<Col xs={12} sm={9}>
									<DatePicker
										id="startTime"
										showTimeSelect
										dateFormat="dd/MM/yyyy HH:mm"
										timeFormat="HH:mm"
										timeIntervals={1}
										timeCaption="Time"
										name="schedule"
										selected={formData.startTime ? new Date(formData.startTime) : null}
										className="meta-datepicker mt-sm-2"
										placeholderText="Click to select date & time"
										autoComplete="off"
										onChange={(val) => {
											let changedData = {
												name: 'startTime',
												value: val ? new Date(val).toISOString() : '',
											};
											dispatch(updateFormData(changedData));
											changedData = {
												name: 'endTime',
												value: null,
											};
											dispatch(updateFormData(changedData));
										}}
										onBlur={() => handleBlur('startTime')}
										minDate={new Date()}
										minTime={minStartTime}
										maxTime={maxStartTime}
									/>
									<ErrorMessage display={displayErrors('startTime')} element="Start time" />
								</Col>
							</Row>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									End time *
								</Col>

								<Col xs={12} sm={9}>
									<DatePicker
										id="endTime"
										showTimeSelect
										dateFormat="dd/MM/yyyy HH:mm"
										timeFormat="HH:mm"
										timeIntervals={1}
										timeCaption="Time"
										name="schedule"
										selected={formData.endTime ? new Date(formData.endTime) : null}
										className="meta-datepicker mt-sm-2"
										placeholderText="Click to select date & time"
										autoComplete="off"
										disabled={!formData.startTime}
										onChange={(val) => {
											const changedData = {
												name: 'endTime',
												value: val ? new Date(val).toISOString() : '',
											};
											dispatch(updateFormData(changedData));
										}}
										onBlur={() => handleBlur('endTime')}
										minDate={formData.startTime ? new Date(formData.startTime) : new Date()}
										maxDate={undefined}
										minTime={minEndTime}
										maxTime={maxEndTime}
										openToDate={formData.startTime ? new Date(formData.startTime) : null}
									/>
									<ErrorMessage display={displayErrors('endTime')} element="End time" />
								</Col>
							</Row>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Category
								</Col>
								<Col xs={12} sm={9}>
									<CreatableSelect
										isClearable
										styles={customStyles}
										onChange={handleChange}
										onCreateOption={handleCreate}
										options={options}
										value={formData.category}
										placeholder="Start typing to to select/create category"
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Col xs={12} sm={12} className="text-center">
						<Button
							type="submit"
							success
							no_radius
							disabled={actionsDisabled}
							style={{ width: '100px' }}
						>
							<b>SAVE</b>
						</Button>
					</Col>
				</form>
			</div>
		</Modal>
	);
};

BroadcastManagerForm.propTypes = {
	open: T.bool,
	close: T.func,
	data: T.object,
	categories: T.object,
};

export default BroadcastManagerForm;
