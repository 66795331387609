import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
	fetchCustomFieldsSuccess, fetchCustomFieldsFailure, filterCustomFieldsSuccess, filterCustomFieldsFailure,
} from './actions';
import { FETCH_CUSTOM_FIELDS, FILTER_CUSTOM_FIELDS } from './type';
import { REGISTRATION_FORM_ELEMENTS_BASE_URL } from './constants';

export function* getFormElementFields(action) {
	const url = `${REGISTRATION_FORM_ELEMENTS_BASE_URL}/offset/${action.offset}/limit/${action.limit}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const formElements = yield response.json();
			yield put(fetchCustomFieldsSuccess(formElements));
		}
	} catch (err) {
		yield put(fetchCustomFieldsFailure(err));
	}
}

export function* filterFormElementFields(action) {
	const url = `${REGISTRATION_FORM_ELEMENTS_BASE_URL}/key/${action.searchValue}`;
	const formData = new FormData();
	formData.append('page', action.page);
	const options = {
		method: 'POST',
		body: formData,
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const formElements = yield response.json();
			yield put(filterCustomFieldsSuccess(formElements));
		}
	} catch (err) {
		yield put(filterCustomFieldsFailure(err));
	}
}

export default function* customFieldData() {
	yield takeLatest(FETCH_CUSTOM_FIELDS, getFormElementFields);
	yield takeLatest(FILTER_CUSTOM_FIELDS, filterFormElementFields);
}
