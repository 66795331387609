import React, { useEffect, useReducer } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-styled-flexboxgrid';
import PropTypes from 'prop-types';

import { Button, Input, WrapperContainer } from '../Styles';
import {
	ImageHolder, TitleWithTooltip,
} from '../FormComponents';
import {
	BRAND_API_BASE_URL,
	FETCH_BRAND,
	FETCH_BRAND_FAILURE,
	UPDATE_BLURRED_ELEMENT,
	SET_ALL_ELEMENTS_BLURRED,
	UPDATE_ERRORS,
	SET_FORM_DATA,
	SET_WHOLE_FORM_DATA,
} from '../../containers/BoniWebstoriesManager/constants';
import { validateImageFormat, validateImageResolution } from '../../utils/common';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

function fetchBrand({ loadingType }) {
	return {
		type: FETCH_BRAND,
		loadingType,
	};
}

function fetchBrandFailure({ error, loadingType }) {
	return {
		type: FETCH_BRAND_FAILURE,
		error,
		loadingType,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function setFormData({ fieldType, fieldValue }) {
	return {
		type: SET_FORM_DATA,
		fieldType,
		fieldValue,
	};
}

function setWholeFormData({ payload }) {
	return {
		type: SET_WHOLE_FORM_DATA,
		payload,
	};
}

const initialState = {
	loading: false,
	error: false,
	portraitImageLoading: false,
	landscapeImageLoading: false,
	blurredElements: {
		title: false,
		brandCategory: false,
		description: false,
		webStoryUrl: false,
		logo: false,
		source: false,
	},
	errors: {
		title: false,
		brandCategory: false,
		description: false,
		webStoryUrl: false,
		logo: false,
		source: false,
	},
	formData: {
		title: '',
		// brandCategory: '',
		description: '',
		webStoryUrl: '',
		logo: '',
		// thumbnailLandscape: '',
		// brandId: '',
		// source: 'YourStory',
	},
};

function reducer(state, action) {
	switch (action.type) {
	case FETCH_BRAND:
		return { ...state, [action.loadingType]: true };
	case FETCH_BRAND_FAILURE:
		return {
			...state, loading: false, error: true, [action.loadingType]: false,
		};
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };
	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			title: true,
			brandCategory: true,
			description: true,
			webStoryUrl: true,
			logo: true,
			source: true,
		};
		return { ...state, blurredElements };
	}
	case SET_FORM_DATA:
		return {
			...state,
			formData: {
				...state.formData, [action.fieldType]: action.fieldValue,
			},
		};
	case SET_WHOLE_FORM_DATA:
		return {
			...state,
			formData: {
				...state.formData,
				...action.payload,
			},
		};
	default:
		return state;
	}
}

const DashboardWebStoryEditor = (props) => {
	const { search } = useLocation();
	const id = new URLSearchParams(search).get('id');
	const history = useHistory();
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		errors, blurredElements, portraitImageLoading, landscapeImageLoading, formData,
	} = state;
	// const [otherInputData, setOtherInputData] = useState({ brandName: '', productCategoryName: '' });

	useEffect(() => {
		const { pageTitle } = props;
		pageTitle('webstoryEditor');
	}, []);

	useEffect(() => {
		getErrors();
	}, [formData, blurredElements]);

	useEffect(() => {
		if (id?.length > 0 || id !== null) handleWebStoryEditor({ method: 'GET' });
	}, [id]);

	function validateForm(data) {
		return {
			title: !data.title,
			// brandCategory: !data.brandCategory,
			// description: !data.description,
			webStoryUrl: !data.webStoryUrl,
			logo: !data.logo,
		};
	}

	async function getErrors() {
		const validationErrors = await validateForm(formData);
		const errors = Object.keys(validationErrors).reduce((acc, curr) => {
			if (validationErrors[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	}

	const handleWebStoryEditor = async ({ method }) => {
		const url = {
			PUT: `${BRAND_API_BASE_URL}/id?id=${id}`,
			GET: `${BRAND_API_BASE_URL}/id?id=${id}&source=YourStory`,
			POST: `${BRAND_API_BASE_URL}?source=YourStory`,
		};
		const options = {
			POST: {
				method: 'POST',
				credentials: 'include',
				body: JSON.stringify(formData),
				headers: {
					'Content-Type': 'application/json',
				},
			},
			PUT: {
				method: 'PUT',
				credentials: 'include',
				body: JSON.stringify(formData),
				headers: {
					'Content-Type': 'application/json',
				},
			},
			GET: {
				method: 'GET',
				credentials: 'include',
			},
		};
		try {
			const response = await fetch(url[method], options[method]);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else {
				dispatch(fetchBrandFailure(''));
				if (method === 'PUT') {
					history.push('/dashboard/web-stories');
				} else if (method === 'POST') {
					dispatch(fetchBrandFailure(''));
					history.push('/dashboard/web-stories');
				} else {
					const { data } = await response.json();
					const payload = {
						title: data.title,
						// brandCategory: data.brandCategory,
						description: data.description || '',
						webStoryUrl: data.webStoryUrl,
						logo: data.logo,
						// thumbnailLandscape: data.thumbnailLandscape,
						// brandId: data.brandId,
					};
					dispatch(setWholeFormData({ payload }));
					// setOtherInputData({ productCategoryName: data.categoryName, brandName: data.brandName });
				}
			}
		} catch (err) {
			dispatch(fetchBrandFailure(err));
		}
	};

	const onFormSubmit = async (e) => {
		e.preventDefault();
		const validation = validateForm(formData);
		const validated = !Object.keys(validation).some((i) => validation[i]);
		if (validated) {
			handleWebStoryEditor({ method: id ? 'PUT' : 'POST' });
		} else {
			dispatch(setAllElementsBlurred());
		}
	};

	function handleBlur(element) {
		dispatch(updateBlurredElement(element));
	}

	const uploadImages = async ({ file, type, loadingType }) => {
		try {
			// setLoading(true);
			dispatch(fetchBrand({ loadingType }));
			const newForm = new FormData();
			newForm.append('imageFile', file);
			newForm.append('storyId', {});
			const options = {
				method: 'POST',
				credentials: 'include',
				body: newForm,
			};
			const resp = await fetch('/api/v2/rw/assets/upload/image', options);
			const imageUrl = await resp.json();
			// setLoading(false);
			dispatch(fetchBrandFailure({ loadingType }));
			dispatch(setFormData({ fieldType: [type], fieldValue: imageUrl }));
		} catch (err) {
			// setLoading(false);
			dispatch(fetchBrandFailure({ loadingType }));
			console.log('S3 image upload error: ', err);
		}
	};

	const handleImageInput = async ({ files, type, loadingType }) => {
		if (files.length > 1) {
			alert('You can only upload upto ONE file at a time!');
		}
		const file = files[0];
		if (file.size / 350 / 500 > 5) {
			console.log('file.size / 350 / 500', file.size / 350 / 500);
			alert(`File size greater than 5 MB (${(file.size / 350 / 500).toFixed(2)} MB)!`);
		} else if (await validateImageFormat(file)) {
			const reader = new FileReader();
			reader.onloadend = (e) => {
				// Initiate the JavaScript Image object.
				const image = new Image();

				// Set the Base64 string return from FileReader as source.
				image.src = e.target.result;

				// Validate the File Height and Width.
				image.onload = function () {
					const res = onImageLoad(image, 'logo');
					res ? uploadImages({ file, type, loadingType }) : '';
				};
			};
			reader.readAsDataURL(file);
		}
	};

	function onImageLoad(image, type) {
		const minWidth = 350;
		const minHeight = 500;
		if (image && !validateImageResolution(image, minWidth, minHeight)) {
			dispatch(setFormData({ fieldType: type, fieldValue: '' }));
		} else {
			return true;
		}
	}

	return (
		<WrapperContainer>
			<form onSubmit={onFormSubmit}>
				<Row>
					<Col xs={6} md={6} className="pb-3">
						<div className="pb-3">
							<TitleWithTooltip
								title="Title *"
								hint="Title of the web story"
							/>
							<Input
								type="text"
								className="form-control"
								onChange={(e) => dispatch(setFormData({ fieldType: 'title', fieldValue: e.target.value }))}
								value={formData.title}
								name="brandName"
								placeholder="Enter Story Title"
								autoComplete="off"
								maxLength="80"
								onBlur={() => handleBlur('title')}
							/>
							<ErrorMessage display={errors.title} element="Title " />
						</div>

						{/* <div>
							<TitleWithTooltip
								title="D2C category *"
								hint="Category"
							/>
						</div>
						<AsyncSingleSelect
							placeholder="Search individual product D2C Category by name"
							options={(value) => getDropdownOptions('boni-brandCategory', value)}
							value={otherInputData.productCategoryName ? { name: otherInputData.productCategoryName, label: otherInputData.productCategoryName } : null}
							handleChange={(category) => {
								if (category) {
									dispatch(setFormData({ fieldType: 'brandCategory', fieldValue: category.id || category.value }));
									setOtherInputData({ ...otherInputData, productCategoryName: category.name || category.label });
								} else {
									dispatch(setFormData({ fieldType: 'brandCategory', fieldValue: null }));
									setOtherInputData({ ...otherInputData, productCategoryName: null });
								}
							}}
							onBlur={() => handleBlur('brandCategory')}
						/>
						<ErrorMessage display={errors.brandCategory} element="D2C Category " /> */}
						{/* <div>
							<TitleWithTooltip
								title="Brand"
								hint="Brand"
							/>
						</div>
						<AsyncSingleSelect
							placeholder="Search individual brands by name"
							options={(value) => getDropdownOptions('boni-companyBrand', value)}
							value={otherInputData.brandName ? { name: otherInputData.brandName, label: otherInputData.brandName } : []}
							handleChange={(category) => {
								if (category) {
									dispatch(setFormData({ fieldType: 'brandId', fieldValue: category.id || category.value }));
									setOtherInputData({ ...otherInputData, brandName: category.name || category.label });
								} else {
									dispatch(setFormData({ fieldType: 'brandId', fieldValue: null }));
									setOtherInputData({ ...otherInputData, brandName: null });
								}
							}}
						/> */}
						<div className="pb-3 pt-3 d-none">
							<TitleWithTooltip
								title="Description *"
								hint="This is the Description/Summary of the Story. *"
							/>
							<textarea
								rows="6"
								className="global-form-control"
								name="description"
								placeholder="Description/Summary of the Story."
								value={formData.description}
								onChange={(e) => dispatch(setFormData({ fieldType: 'description', fieldValue: e.target.value }))}
								onBlur={() => handleBlur('description')}
							/>
							<ErrorMessage display={errors.description} element="Description " />
						</div>

						<div className="pb-3">
							<TitleWithTooltip
								title="Web Story Url *"
								hint="This is the slug for the url of the Brand."
							/>
							<Input
								type="url"
								className="form-control"
								placeholder="Enter Web Story Url"
								value={formData.webStoryUrl}
								onChange={(e) => dispatch(setFormData({ fieldType: 'webStoryUrl', fieldValue: e.target.value }))}
								name="brandSlug"
								autoComplete="off"
								maxLength="255"
								onBlur={() => handleBlur('webStoryUrl')}
							/>
							<ErrorMessage display={errors.webStoryUrl} element="Web story URL " />
						</div>
						<div className="pb-3 pt-3">
							<TitleWithTooltip
								title="Source *"
								hint="Category"
							/>
							<Input
								type="text"
								className="form-control"
								value="YourStory"
								name="webstoryPropertySource"
								disabled
								// onChange={(e) => dispatch(setFormData({ fieldType: 'source', fieldValue: e.target.value }))}
								style={{ minWidth: '60%' }}
								autoComplete="off"
								maxLength="200"
							/>
							{/* <ErrorMessage display={errors.source} element="Web story Source " /> */}
						</div>
					</Col>
					<Col xs={6} md={6}>
						<div className="">
							<TitleWithTooltip
								title="Portrait Thumbnail *"
								hint="Brand Banner to be shown on the website"
							/>
							<div style={{ position: 'relative' }}>
								{
									portraitImageLoading ? <LoadingIcon />
										: <>
											{
												formData.logo ? <div style={{ maxWidth: '200px', maxHeight: '200px', position: 'relative' }}>
													<img
														style={{
															maxWidth: '100%', maxHeight: '100%', width: '200px', height: '200px', objectFit: 'fill',
														}}
														src={formData.logo}
														alt="PotraitThumbnail"
													/>
													<span
														style={{
															zIndex: 1,
															fontSize: '25px',
															position: 'absolute',
															right: '13px',
															cursor: 'pointer',
															top: 0,
														}}
														onClick={() => dispatch(setFormData({ fieldType: 'logo', fieldValue: '' }))}
													>
														<i className="fa fa-times" aria-hidden="true" />
													</span>
												</div> : <>
													<ImageHolder
														sm={4}
														className="p-0 text-center"
														squareDimension="14rem"
														onImageLoad={(e) => onImageLoad(e.target, 'logo')}
													/>
													<label
														style={{
															width: '100%', height: '100%', position: 'absolute', top: '0px', left: '0', cursor: 'pointer',
														}}
														htmlFor="selectPotraitImage"
													>
														<p />
													</label>
													<input
														style={{ display: 'none' }}
														id="selectPotraitImage"
														name="selectPotraitImage"
														type="file"
														accept=".png, .jpg, .jpeg"
														onChange={(e) => {
															if (e.target.files[0]) {
																handleImageInput({ files: e.target.files, type: 'logo', loadingType: 'portraitImageLoading' });
															}
														}}
														onBlur={() => handleBlur('logo')}

													/>
													<ErrorMessage display={errors.logo} element="Thumbnail portrait" />
												</>
											}
										</>
								}
							</div>
						</div>
						<div className="pb-3 d-none pt-3">
							<TitleWithTooltip
								title="Landscape Thumbnail"
								hint="Brand Banner to be shown on the website"
							/>
							<div style={{ position: 'relative' }}>
								{
									landscapeImageLoading ? <LoadingIcon />
										: <>
											{
												formData.thumbnailLandscape ? <div style={{ maxWidth: '100%', maxHeight: '500px', position: 'relative' }}>
													<img style={{ width: '100%', height: '200px', objectFit: 'cover' }} src={formData.thumbnailLandscape} alt="LandscapeThumbnail" />
													<span
														style={{
															zIndex: 1,
															fontSize: '25px',
															position: 'absolute',
															right: '13px',
															cursor: 'pointer',
															top: 0,
														}}
														className="cross-icon"
														onClick={() => dispatch(setFormData({ fieldType: 'thumbnailLandscape', fieldValue: '' }))}
													>
														<i className="fa fa-times" aria-hidden="true" />
													</span>
												</div> : <>
													<ImageHolder
														sm={6}
														className="p-0 text-center"
													/>
													<label
														style={{
															width: '100%', height: '100%', position: 'absolute', top: '0px', left: '0', cursor: 'pointer',
														}}
														htmlFor="selectLandscapeImage"
													>
														<p />
													</label>
													<input
														style={{ display: 'none' }}
														id="selectLandscapeImage"
														name="selectLandscapeImage"
														type="file"
														accept=".png, .jpg, .jpeg"
														onChange={(e) => {
															if (e.target.files[0]) {
																handleImageInput({ files: e.target.files, type: 'thumbnailLandscape', loadingType: 'landscapeImageLoading' });
															}
														}}
													/>
												</>
											}
										</>
								}
							</div>
						</div>
					</Col>
					<Col xs={12} md={12}>
						<div className="mt-3">
							<Button
								success
								className="mr-4 mb-0"
								type="submit"
							>
								{id ? 'Update' : 'Save'}
							</Button>
							<Button
								primary
								className="mr-4 mb-0"
								onClick={() => history.push('/dashboard/web-stories')}
							>
								Discard
							</Button>
						</div>
					</Col>
				</Row>
			</form>
		</WrapperContainer>
	);
};
export default DashboardWebStoryEditor;

DashboardWebStoryEditor.propTypes = {
	pageTitle: PropTypes.func,
};
