/* eslint-disable no-unused-vars */
import { Map } from 'immutable';
import {
	FETCH_EDITOR_GALLERY_MEDIA, FETCH_EDITOR_GALLERY_MEDIA_SUCCESS, FETCH_EDITOR_GALLERY_MEDIA_FAILURE, SET_EDITOR_GALLERY_PAGE_NO, SORT_EDITOR_GALLERY_MEDIA_BY, SET_EDITOR_GALLERY_SEARCH_VALUE, SET_EDITOR_GALLERY_UPDATED_TIME, FILTER_EDITOR_GALLERY_MEDIA, FILTER_EDITOR_GALLERY_MEDIA_SUCCESS, SET_EDITOR_GALLERY_FETCH_BY, SET_EDITOR_GALLERY_MEDIA_TYPE, CLEAR_EDITOR_GALLERY_DATA, SET_EDITOR_GALLERY_FETCH_BY_VALUE,
} from './type';
import { ALL_BRAND_OBJECT } from './constants';

const initialState = Map({
	allMedia: [],
	sortBy: 'recent',
	loading: false,
	error: false,
	page: 1,
	mediaType: null,
	formMessage: null,
	searchValue: null,
	galleryUpdatedAt: null,
	selectedBrand: ALL_BRAND_OBJECT,
	selectedFilterOption: 0,
	fetchBy: 2,
	fetchByValue: null,
});

function editorGalleryReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_EDITOR_GALLERY_MEDIA:
		return state
			.set('loading', true);
	case FETCH_EDITOR_GALLERY_MEDIA_SUCCESS: {
		const { concat } = action;
		let allMedia;
		if (concat) {
			const existingMedia = state.get('allMedia');
			allMedia = existingMedia.concat(action.media);
		} else {
			allMedia = action.media;
		}
		return state
			.set('allMedia', allMedia)
			.set('loading', false);
	}
	case FETCH_EDITOR_GALLERY_MEDIA_FAILURE:
		return state
			.set('loading', false)
			.set('error', action.error);
	case SET_EDITOR_GALLERY_PAGE_NO:
		return state
			.set('page', action.page);
	case SORT_EDITOR_GALLERY_MEDIA_BY:
		return state
			.set('sortBy', action.sortBy);
	case SET_EDITOR_GALLERY_SEARCH_VALUE:
		return state
			.set('searchValue', action.value);
	case SET_EDITOR_GALLERY_UPDATED_TIME:
		return state
			.set('galleryUpdatedAt', action.time);
	case FILTER_EDITOR_GALLERY_MEDIA:
		return state
			.set('loading', true);
	case FILTER_EDITOR_GALLERY_MEDIA_SUCCESS: {
		const { concat } = action;
		let allMedia;
		if (concat) {
			const existingMedia = state.get('allMedia');
			allMedia = existingMedia.concat(action.media);
		} else {
			allMedia = action.media;
		}
		return state
			.set('loading', false)
			.set('allMedia', allMedia);
	}
	case SET_EDITOR_GALLERY_FETCH_BY:
		return state
			.set('fetchBy', action.fetchBy);
	case SET_EDITOR_GALLERY_FETCH_BY_VALUE:
		return state
			.set('fetchByValue', action.fetchByValue);
	case SET_EDITOR_GALLERY_MEDIA_TYPE:
		return state
			.set('mediaType', action.mediaType);
	case CLEAR_EDITOR_GALLERY_DATA:
		return initialState;
	default:
		return state;
	}
}

export default editorGalleryReducer;
