/* eslint-disable no-unused-vars */
import React, { useReducer, useEffect, useState } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import uuid from 'react-uuid';

import { Row, Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import { DropDown, Input, Button } from '../Styles';
import { ImageHolder } from '../FormComponents';
// import { validateImage } from '../../utils/common';
import {
	CURATED_SET_CONFIG_URL, SAVING_FAILURE_MESSAGE, SAVING_MESSAGE, SAVING_SUCCESS_MESSAGE,
} from '../../containers/TechSparksManager/constants';
import ToastMessage from '../ToastMessage';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { extractedCityName } from '../../utils/common';

const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
const UPDATE_ERRORS = 'UPDATE_ERRORS';
const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
const SET_FORM_DATA = 'SET_FORM_DATA';

const initialState = {
	formData: {
		id: null,
		url: '',
		description: '',
		image: '',
		order: 1,
		category: 'PARTNERS',
	},
	blurredElements: {
		url: false,
		description: false,
		image: false,
		order: false,
		category: false,
	},
	errors: {
		url: false,
		description: false,
		image: false,
		order: false,
		category: false,
	},
	formMessage: null,
};

function updateFormData(changedData) {
	return {
		type: UPDATE_FORM_DATA,
		changedData,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function setFormData(partner) {
	return {
		type: SET_FORM_DATA,
		partner,
	};
}

function setFormMessage(message) {
	return {
		type: SET_FORM_MESSAGE,
		message,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case SET_FORM_DATA:
		return { ...state, formData: action.partner };

	case UPDATE_FORM_DATA: {
		const formData = { ...state.formData };
		const { changedData } = action;
		formData[changedData.name] = changedData.value;
		return { ...state, formData };
	}

	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };

	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			url: true,
			// description: true,
			image: true,
			order: true,
			category: true,
		};
		return { ...state, blurredElements };
	}

	case SET_FORM_MESSAGE:
		return { ...state, formMessage: action.message };

	default:
		return state;
	}
}

const PartnerForm = (props) => {
	const {
		partner, getPartners, unmountModal, hoc, city, partnersLength,
	} = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		formData, blurredElements, errors, formMessage,
	} = state;
	const [isButtonDisabled, disableButton] = useState(false);
	const [toUpdate, setToUpdate] = useState(false);

	useEffect(() => {
		if (partner && partner.id) {
			dispatch(setFormData(partner));
			setToUpdate(true);
		}
		const changedData = {
			name: 'order',
			value: partnersLength,
		};
		dispatch(updateFormData(changedData));
	}, []);

	useEffect(() => {
		getErrors();
	}, [formData, blurredElements]);

	function onInputChange(e) {
		const changedData = {
			name: e.target.name,
			value: e.target.value,
		};
		dispatch(updateFormData(changedData));
	}

	function validateForm(partner) {
		return {
			// url: !partner.url || partner.url.length < 1,
			// description: !partner.description || partner.description.length < 1,
			image: !partner.image || partner.image.length < 1,
			order: !partner.order || partner.order.length < 1,
			category: !partner.category || partner.category.length < 1,
		};
	}

	function getErrors() {
		const validationErrors = validateForm(formData);
		const errors = Object.keys(validationErrors).reduce((acc, curr) => {
			if (validationErrors[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	}

	function getSpeakerBody(partner) {
		const formData = new FormData();
		if (toUpdate) {
			formData.append('id', partner.id);
		} else {
			const id = uuid();
			formData.append('id', id);
		}
		formData.append('description', partner.description);
		formData.append('order', parseInt(partner.order));
		formData.append('image', partner.image && partner.image.file ? partner.image.file : JSON.stringify(partner.image));
		formData.append('category', partner.category);
		formData.append('url', partner.url);
		return formData;
	}

	function onFormSubmit(e) {
		e.preventDefault();
		const validation = validateForm(formData);
		const validated = !Object.keys(validation).some((i) => validation[i]);
		if (validated) {
			savePartner();
		} else {
			dispatch(setAllElementsBlurred());
		}
	}

	function handleBlur(element) {
		dispatch(updateBlurredElement(element));
	}

	async function savePartner() {
		try {
			disableButton(true);
			dispatch(setFormMessage(SAVING_MESSAGE));
			const payload = getSpeakerBody(formData);
			const options = {
				method: 'PUT',
				credentials: 'include',
				body: payload,
			};
			const extractCityName = extractedCityName(city);
			const url = `${CURATED_SET_CONFIG_URL}${hoc ?? `${city === 'mumbai' ? `${city}_`
				: city && city.includes('techsparks_2023') ? `2023_${extractCityName}` : ''}partners`}/${toUpdate ? 'update' : 'create'}`;
			const response = await fetch(url, options);
			disableButton(false);
			if (response.status === 200) {
				dispatch(setFormMessage(SAVING_SUCCESS_MESSAGE));
				getPartners();
				unmountModal();
			} else {
				dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			}
		} catch (err) {
			dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			disableButton(false);
			console.log('error', err);
		}
	}

	function renderFormMessage() {
		if (formMessage) {
			return (
				<ToastMessage
					toastData={formMessage}
					unmount={() => dispatch(setFormMessage(null))}
				/>
			);
		}
		return null;
	}

	async function onUpload(e) {
		e.preventDefault();
		const file = e.target.files[0];
		if (file.size / 1024 / 1024 > 5) {
			return alert(`File size greater than 5 MB (${(file.size / 1024 / 1024).toFixed(2)} MB)!`);
		}
		const reader = new FileReader();
		reader.onloadend = () => {
			dispatch(updateFormData({
				name: 'image',
				value: {
					url: reader.result,
					file,
				},
			}));
		};
		reader.readAsDataURL(file);
	}

	function onUploadClick(e) {
		e.preventDefault();
		const logoInput = document.getElementById('company-image');
		logoInput.click();
	}

	function onImageLoad(e) {
		if (formData.image.file) {
			const data = { height: e.target.naturalHeight, width: e.target.naturalWidth };
			dispatch(updateFormData({
				name: 'image',
				value: {
					...formData.image,
					...data,
				},
			}));
		}
	}

	return (
		<form onSubmit={onFormSubmit}>
			{renderFormMessage()}
			<Row>
				<Col sm={12}>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Company Logo *
						</Col>
						<Col xs={12} sm={8}>
							<Input
								id="company-image"
								type="file"
								style={{ display: 'none' }}
								name="image"
								onChange={onUpload}
								accept="image/png, image/jpeg"
								onBlur={() => handleBlur('image')}
							/>
							<ImageHolder
								showImageUploader={onUploadClick}
								imageUrl={formData && formData.image ? formData.image.url : null}
								onImageLoad={onImageLoad}
								squareDimension="13rem"
								clearImage={() => {
									document.getElementById('company-image').value = '';
									dispatch(updateFormData({ name: 'image', value: null }));
								}}
							/>
							<ErrorMessage display={errors.image} element="Company Image" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Redirection URL
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="url"
								type="url"
								className="form-control"
								placeholder="Redirection URL"
								onChange={onInputChange}
								value={formData.url ?? ''}
								onBlur={() => handleBlur('url')}
							/>
							{/* <ErrorMessage display={errors.url} element="Redirection URL" /> */}
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Description
						</Col>
						<Col sm={8} xs={12}>
							<textarea
								rows="4"
								className="global-form-control"
								name="description"
								placeholder="Description"
								onChange={onInputChange}
								value={formData.description ?? ''}
								onBlur={() => handleBlur('description')}
							/>
							{/* <ErrorMessage display={errors.description} element="description" /> */}
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Order *
						</Col>
						{hoc === 'techsparks_partners'
							? <Col sm={8} xs={12}>
								<Input
									name="order"
									type="number"
									className="form-control"
									placeholder="Order of the partner"
									onChange={onInputChange}
									value={formData.order ?? 1}
									onBlur={() => handleBlur('order')}
								/>
								<p className="error mt-2 ml-1">NOTE: Please enter order which is not being used.</p>
								<ErrorMessage display={errors.Order} element="Order" />
							</Col>
							: <Col sm={8} xs={12}>
								<Input
									name="order"
									type="number"
									className="form-control"
									placeholder="Order of the partner"
									onChange={onInputChange}
									value={formData.order ?? 1}
									onBlur={() => handleBlur('order')}
								/>
								<p className="error mt-2 ml-1">NOTE: Please enter order which is not being used.</p>
								<ErrorMessage display={errors.Order} element="Order" />
							</Col>}
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Category *
						</Col>
						<Col sm={8} xs={12}>
							<DropDown
								className="mt-0"
								name="category"
								value={formData && formData.category ? formData.category : ''}
								onChange={onInputChange}
								onBlur={() => handleBlur('category')}
							>
								<option value="PARTNERS">Our Partners</option>
								<option value="ECO-SYSTEM-PARTNERS">Ecosystem Partners</option>
							</DropDown>
						</Col>
					</Row>
					<div className="text-right">
						<Button
							success
							type="submit"
							disabled={isButtonDisabled}
						>
							SAVE
						</Button>
					</div>
				</Col>
				{renderFormMessage()}
			</Row>
		</form>
	);
};

PartnerForm.propTypes = {
	partner: T.object,
	unmountModal: T.func,
	getPartners: T.func,
	hoc: T.string,
	city: T.string,
	partnersLength: T.number,
};

export default PartnerForm;
