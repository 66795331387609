import {
	call, put, takeLatest, select,
} from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { redirectTo } from '../../utils/accessRedirection';
import {
	FETCH_JOB_DATA,
	CREATE_JOB,
	UPDATE_JOB,
	SET_JOB_EDITOR_DOC_CONVERSION_DATA,
} from './type';
import {
	createJobSuccess,
	createJobFailure,
	updateJobSuccess,
	updateJobFailure,
	fetchJobDataSuccess,
	fetchJobDataFailure,
	onJobFormDataChange,
	updateJobEditorDocConversion,
} from './actions';
import {
	JOB_EDITOR_API_BASE_URL, STATUS, MS_DOC_CONVERSION_API, GOOGLE_DOC_CONVERSION_API,
} from './constants';
import { selectJobDocConversion } from './selectors';
import { XMLHttpsUpload, checkNested, checkType } from '../../utils/utility_function';

let CURRENT_LOCATION;

function* fetchJob(action) {
	try {
		const url = `${JOB_EDITOR_API_BASE_URL}/id/${action.jobId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const responseMessage = yield response.json();
			yield put(fetchJobDataSuccess(responseMessage.data));
		}
	} catch (err) {
		yield put(fetchJobDataFailure(err));
	}
}

function* createNewJob(action) {
	try {
		const url = `${JOB_EDITOR_API_BASE_URL}/create`;
		const options = {
			method: 'POST',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(action.job),
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(createJobSuccess(responseMessage.data));
				yield put(replace(`/job/editor/${responseMessage.data.id}`));
			} else {
				yield put(createJobFailure(responseMessage.error));
			}
		}
	} catch (err) {
		yield put(createJobFailure(err));
	}
}

function* updateJob(action) {
	try {
		const url = `${JOB_EDITOR_API_BASE_URL}/update/id/${action.id}`;
		const options = {
			method: 'PUT',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(action.job),
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(updateJobSuccess(responseMessage.data));
			} else {
				yield put(updateJobFailure(responseMessage.error));
			}
		}
	} catch (err) {
		yield put(updateJobFailure(err));
	}
}

function* uploadDocData(action) {
	try {
		const newState = action.value;
		yield put(updateJobEditorDocConversion(newState));
		const docConversion = yield select(selectJobDocConversion(newState));
		if (newState) {
			if (('microsoft' in newState) && newState.microsoft.ready) {
				const { file } = newState.microsoft;
				const data = { docFile: file };
				if (checkNested(file, 'name') && checkType(file.name, 'string')) {
					if (!(file.name.endsWith('docx') || file.name.endsWith('doc'))) {
						throw new Error('Invalid file type, expected a .docx document');
					}
				}
				yield put(updateJobEditorDocConversion({ status: { type: STATUS.UPLOADING, value: 0 } }));
				const response = yield XMLHttpsUpload(MS_DOC_CONVERSION_API, data);
				if (response.type === STATUS.SUCCESS) {
					yield put(onJobFormDataChange({ name: 'details', value: response.data.data }));
					yield put(updateJobEditorDocConversion({ status: { type: STATUS.SUCCESS, value: response.message } }));
				} else if (response.type === STATUS.FAIL) {
					yield put(updateJobEditorDocConversion({ status: { type: STATUS.FAIL, value: response.message } }));
				} else if (response.type === STATUS.ERROR) {
					yield put(updateJobEditorDocConversion({ status: { type: STATUS.ERROR, value: response.message } }));
				}
			} else if (('google' in newState) && newState.google.ready) {
				const { authToken } = docConversion.google;
				const { mimeType } = newState.google;
				const { fileId } = newState.google;
				const options = {
					method: 'POST',
					credentials: 'include',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						authToken,
						mimeType,
						fileId,
					}),
				};
				yield put(updateJobEditorDocConversion({ status: { type: STATUS.UPLOADING, value: 0 } }));
				const response = yield call(fetch, GOOGLE_DOC_CONVERSION_API, options);
				if (response.status === 200) {
					const responseJson = yield response.json();
					const { message } = responseJson;
					yield put(onJobFormDataChange({ name: 'details', value: responseJson.data.value }));
					yield put(updateJobEditorDocConversion({ status: { type: STATUS.SUCCESS, value: message } }));
				} else {
					const responseJson = yield response.json();
					const { message } = responseJson.error;
					yield put(updateJobEditorDocConversion({ status: { type: STATUS.FAIL, value: message } }));
				}
			}
		}
	} catch (err) {
		yield put(updateJobEditorDocConversion({ status: { type: STATUS.ERROR, value: err.message } }));
	}
}

export default function* jobEditorData() {
	yield takeLatest(FETCH_JOB_DATA, fetchJob);
	yield takeLatest(CREATE_JOB, createNewJob);
	yield takeLatest(UPDATE_JOB, updateJob);
	yield takeLatest(SET_JOB_EDITOR_DOC_CONVERSION_DATA, uploadDocData);
}
