/* eslint-disable no-unused-vars */
import React, { useReducer, useEffect, useState } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import uuid from 'react-uuid';

import { Row, Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import { DropDown, Input, Button } from '../Styles';
import { AsyncSingleSelect, ImageHolder } from '../FormComponents';
import { extractedCityName, validateImage } from '../../utils/common';
import {
	CURATED_SET_CONFIG_URL, SAVING_FAILURE_MESSAGE, SAVING_MESSAGE, SAVING_SUCCESS_MESSAGE,
} from '../../containers/TechSparksManager/constants';
import ToastMessage from '../ToastMessage';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
const UPDATE_ERRORS = 'UPDATE_ERRORS';
const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
const SET_FORM_DATA = 'SET_FORM_DATA';

const initialState = {
	formData: {
		id: null,
		name: null,
		designation: null,
		image: null,
		order: '',
		linkedInUrl: '',
	},
	blurredElements: {
		name: false,
		designation: false,
		image: false,
		order: false,
	},
	errors: {
		name: false,
		designation: false,
		image: false,
		order: false,
	},
	formMessage: null,
};

function updateFormData(changedData) {
	return {
		type: UPDATE_FORM_DATA,
		changedData,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function setFormData(speaker) {
	return {
		type: SET_FORM_DATA,
		speaker,
	};
}

function setFormMessage(message) {
	return {
		type: SET_FORM_MESSAGE,
		message,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case SET_FORM_DATA:
		return { ...state, formData: action.speaker };

	case UPDATE_FORM_DATA: {
		const formData = { ...state.formData };
		const { changedData } = action;
		formData[changedData.name] = changedData.value;
		return { ...state, formData };
	}

	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };

	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			name: true,
			designation: true,
			image: true,
			order: true,
		};
		return { ...state, blurredElements };
	}

	case SET_FORM_MESSAGE:
		return { ...state, formMessage: action.message };

	default:
		return state;
	}
}

const SpeakerForm = (props) => {
	const {
		speaker, getSpeakers, unmountModal, hoc, city, speakersLength,
	} = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		formData, blurredElements, errors, formMessage,
	} = state;
	const [isButtonDisabled, disableButton] = useState(false);
	const [toUpdate, setToUpdate] = useState(false);

	useEffect(() => {
		if (speaker && speaker.id) {
			dispatch(setFormData(speaker));
			setToUpdate(true);
		} else {
			const changedData = {
				name: 'order',
				value: speakersLength,
			};
			dispatch(updateFormData(changedData));
		}
		console.log('getSpeakers', speakersLength);
	}, []);

	useEffect(() => {
		getErrors();
	}, [formData, blurredElements]);

	function onInputChange(e) {
		const changedData = {
			name: e.target.name,
			value: e.target.value,
		};
		dispatch(updateFormData(changedData));
	}

	function validateForm(speaker) {
		return {
			name: !speaker.name || speaker.name.length < 1,
			designation: !speaker.designation || speaker.designation.length < 1,
			image: !speaker.image || speaker.image.length < 1,
			order: !speaker.order || speaker.order.length < 1,
		};
	}

	function getErrors() {
		const validationErrors = validateForm(formData);
		const errors = Object.keys(validationErrors).reduce((acc, curr) => {
			if (validationErrors[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	}

	function getSpeakerBody(speaker) {
		const formData = new FormData();
		if (toUpdate) {
			formData.append('id', speaker.id);
		} else {
			const id = uuid();
			formData.append('id', id);
		}
		formData.append('name', speaker.name);
		formData.append('designation', speaker.designation);
		formData.append('order', speaker.order);
		formData.append('image', speaker.image && speaker.image.file ? speaker.image.file : JSON.stringify(speaker.image));
		formData.append('linkedInUrl', speaker.linkedInUrl);
		return formData;
	}

	function onFormSubmit(e) {
		e.preventDefault();
		const validation = validateForm(formData);
		const validated = !Object.keys(validation).some((i) => validation[i]);
		if (validated) {
			saveSpeaker();
		} else {
			dispatch(setAllElementsBlurred());
		}
	}

	function handleBlur(element) {
		dispatch(updateBlurredElement(element));
	}

	async function saveSpeaker() {
		try {
			disableButton(true);
			dispatch(setFormMessage(SAVING_MESSAGE));
			const payload = getSpeakerBody(formData);
			const options = {
				method: 'PUT',
				credentials: 'include',
				body: payload,
			};
			const extractCityName = extractedCityName(city);
			const url = `${CURATED_SET_CONFIG_URL}${hoc ?? `${city === 'mumbai' ? `${city}_`
				: city && city.includes('techsparks_2023') ? `2023_${extractCityName}` : ''}speakers`}/${toUpdate ? 'update' : 'create'}`;
			const response = await fetch(url, options);
			disableButton(false);
			if (response.status === 200) {
				dispatch(setFormMessage(SAVING_SUCCESS_MESSAGE));
				getSpeakers();
				unmountModal();
			} else {
				dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			}
		} catch (err) {
			dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			disableButton(false);
		}
	}

	function renderFormMessage() {
		if (formMessage) {
			return (
				<ToastMessage
					toastData={formMessage}
					unmount={() => dispatch(setFormMessage(null))}
				/>
			);
		}
		return null;
	}

	async function onUpload(e) {
		e.preventDefault();
		const file = e.target.files[0];
		if (file.size / 1024 / 1024 > 5) {
			return alert(`File size greater than 5 MB (${(file.size / 1024 / 1024).toFixed(2)} MB)!`);
		}
		const reader = new FileReader();
		reader.onloadend = () => {
			dispatch(updateFormData({
				name: 'image',
				value: {
					url: reader.result,
					file,
				},
			}));
		};
		reader.readAsDataURL(file);
	}

	function onUploadClick(e) {
		e.preventDefault();
		const logoInput = document.getElementById('speaker-image');
		logoInput.click();
	}

	function onImageLoad(e) {
		if (formData.image.file) {
			const data = { height: e.target.naturalHeight, width: e.target.naturalWidth };
			dispatch(updateFormData({
				name: 'image',
				value: {
					...formData.image,
					...data,
				},
			}));
		}
	}

	return (
		<form onSubmit={onFormSubmit}>
			{renderFormMessage()}
			<Row>
				<Col sm={12}>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Image *
						</Col>
						<Col xs={12} sm={8}>
							<Input
								id="speaker-image"
								type="file"
								style={{ display: 'none' }}
								name="image"
								onChange={onUpload}
								accept="image/png, image/jpeg"
								onBlur={() => handleBlur('image')}
							/>
							<ImageHolder
								showImageUploader={onUploadClick}
								imageUrl={formData && formData.image ? formData.image.url : null}
								onImageLoad={onImageLoad}
								squareDimension="13rem"
								clearImage={() => {
									document.getElementById('speaker-image').value = '';
									dispatch(updateFormData({ name: 'image', value: null }));
								}}
							/>
							<ErrorMessage display={errors.image} element="Image" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Name *
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="name"
								type="text"
								className="form-control"
								placeholder="Name of the speaker"
								onChange={onInputChange}
								value={formData.name ?? ''}
								onBlur={() => handleBlur('name')}
							/>
							<ErrorMessage display={errors.name} element="Name" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Designation *
						</Col>
						<Col sm={8} xs={12}>
							<textarea
								rows="4"
								className="global-form-control"
								name="designation"
								placeholder="Designation of speaker"
								onChange={onInputChange}
								value={formData.designation ?? ''}
								onBlur={() => handleBlur('designation')}
							/>
							<ErrorMessage display={errors.designation} element="Designation" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Order *
						</Col>
						{/* {hoc === 'techsparks_viewall_speakers' && (city === 'mumbai') */}
						{(hoc === 'mumbai_viewall_speakers' || city === 'mumbai')
							? <Col sm={8} xs={12}>
								<Input
									name="order"
									type="number"
									className="form-control"
									placeholder="Order of the speaker"
									onChange={onInputChange}
									value={formData?.order}
									onBlur={() => handleBlur('order')}
								/>
								<p className="error mt-2 ml-1">NOTE: Please enter order which is not being used.</p>
								<ErrorMessage display={errors.Order} element="Order" />
							</Col>
							: <Col sm={8} xs={12}>
								{/* <DropDown
									className="mt-0"
									name="order"
									value={formData && formData.order ? formData.order : 1}
									onChange={onInputChange}
									onBlur={() => handleBlur('order')}
								>
									<option value={1}>1</option>
									<option value={2}>2</option>
									<option value={3}>3</option>
									<option value={4}>4</option>
									<option value={5}>5</option>
									<option value={6}>6</option>
									<option value={7}>7</option>
									<option value={8}>8</option>
									<option value={9}>9</option>
									<option value={10}>10</option>
									<option value={11}>11</option>
									<option value={12}>12</option>
									<option value={13}>13</option>
								</DropDown>
								<p className="error mt-2 ml-1">NOTE: Please enter order which is not being used.</p>
								<ErrorMessage display={errors.Order} element="Order" /> */}
								<Input
									name="order"
									type="number"
									className="form-control"
									placeholder="Order of the speaker"
									onChange={onInputChange}
									value={formData?.order}
									onBlur={() => handleBlur('order')}
								/>
								<p className="error mt-2 ml-1">NOTE: Please enter order which is not being used.</p>
								<ErrorMessage display={errors.Order} element="Order" />
							</Col>}
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Linkedin url
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="linkedInUrl"
								type="url"
								className="form-control"
								placeholder="Linked in URL of the speaker"
								onChange={onInputChange}
								value={formData.linkedInUrl ?? ''}
							/>
						</Col>
					</Row>
					<div className="text-right">
						<Button
							success
							type="submit"
							disabled={isButtonDisabled}
						>
							SAVE
						</Button>
					</div>
				</Col>
				{renderFormMessage()}
			</Row>
		</form>
	);
};

SpeakerForm.propTypes = {
	speaker: T.object,
	unmountModal: T.func,
	getSpeakers: T.func,
	hoc: T.string,
	city: T.string,
	speakersLength: T.number,
};

export default SpeakerForm;
