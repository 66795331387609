/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import T from 'prop-types';
import Modal from 'react-modal';
import uuid from 'react-uuid';
import styled, { css } from 'styled-components';

import { Col, Row } from '../Responsive';
import {
	Button, Input, InputGroup, WrapperContainer,
} from '../Styles';
import AgendaForm from './AgendaForm';
import { CURATED_SET_CONFIG_URL } from '../../containers/TechSparksManager/constants';
import { LoadingIcon } from '../Basic';
import { extractedCityName } from '../../utils/common';

const Card = styled.div`
	border: 1px solid #ddd;
	height: 300px;
	width: 200px;
	padding: 5px;
	margin: 0.25rem;
	background-color: #fff;
	cursor: pointer;
	font-size: 0.8rem;
	word-wrap: break-word;
	cursor: pointer;
	display: block;
	position: relative;
	overflow: auto;
`;

const CardWrapper = styled(Row)`
	margin-top: 2rem;
	border: 1px solid #bbb;
	background-color: #eee;
	padding: 0.5rem;
	width: 100%;
	position: relative;
	overflow: auto;
	height: 75vh;
	${(props) => props.source === 'fullPage' && css`
			height: 75vh;
	`}
`;

const Agendas = (props) => {
	const { pageTitle, match } = props;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [agendas, setAgendas] = useState([]);
	const [currentAgenda, setCurrentAgenda] = useState({});
	const [loading, setLoading] = useState(false);
	const extractCityName = extractedCityName(match.params.city);

	useEffect(() => {
		pageTitle('agendas');
		getAgendas();
	}, []);

	async function getAgendas() {
		const url = `${CURATED_SET_CONFIG_URL}${match?.params?.city ? match.params.city === 'mumbai' ? `${match.params.city}_`
			: match.params.city && match.params.city.includes('techsparks_2023') ? `2023_${extractCityName}` : '' : ''}agendas?brand=2`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		setLoading(true);
		const response = await fetch(url, options);
		const agendas = await response.json();
		setAgendas(agendas);
		setLoading(false);
	}

	function unmountModal() {
		setIsModalOpen(false);
		setCurrentAgenda(null);
	}

	function renderFormModal() {
		return <AgendaForm
			agenda={currentAgenda}
			unmountModal={unmountModal}
			getAgendas={getAgendas}
			isOpen={isModalOpen}
			city={match.params.city}
			agendasLength={agendas?.length > 0 ? agendas?.length + 1 : 1}
		/>;
		// <Modal
		// 	isOpen={isModalOpen}
		// 	onRequestClose={() => unmountModal()}
		// 	overlayClassName="modal-overlay"
		// 	className="form-modal"
		// >
		// 	<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className="mb-2">
		// 		<h3>New Agenda</h3>
		// 		<button type="button" onClick={() => unmountModal()}>
		// 			<i className="fas fa-times light-text disablePointerEvents" />
		// 		</button>
		// 	</div>
		// </Modal>;
	}

	async function handleDelete(agenda) {
		const url = `${CURATED_SET_CONFIG_URL}${match?.params?.city ? match.params.city === 'mumbai' ? `${match.params.city}_`
			: match.params.city && match.params.city.includes('techsparks_2023') ? `2023_${extractCityName}` : '' : ''}agendas/delete?id=${agenda.id}`;
		const options = {
			method: 'PUT',
			credentials: 'include',
		};
		const response = await fetch(url, options);
		if (response && response.status === 200) {
			getAgendas();
		}
	}

	return (
		<WrapperContainer>
			<Row className="mt-4">
				<Col md={8} sm={8} xs={12}>
					<Col md={8} sm={10} className="pl-0">
						This is the agenda manager of <strong>{match?.params?.city?.toUpperCase()}</strong> where we will manage track
					</Col>
				</Col>
				<Col md={4} sm={4} xs={12}>
					<Col md={8} xs={8} className="pt-sm-4">
						<Button
							id="add-new-btn"
							primary
							btn_rounded
							w_100
							onClick={() => setIsModalOpen(true)}
						>
							ADD NEW
						</Button>
					</Col>
				</Col>
			</Row>
			<Row>
				{loading
					? <LoadingIcon />
					: <CardWrapper>
						{agendas && agendas.length > 0 && agendas.map((agenda) => (
							<Card key={uuid()}>
								<div className="text-right">
									<i className="fa fa-pencil-square fa-lg" style={{ cursor: 'pointer' }} onClick={() => { setCurrentAgenda(agenda); setIsModalOpen(true); }} />
									<i className="fa fa-trash fa-lg ml-3" style={{ cursor: 'pointer' }} onClick={() => handleDelete(agenda)} />
								</div>
								<h2>{agenda.title}</h2>
								<p>{agenda.description}</p>
								<p>Day: {agenda.day}</p>
								<p>Order: {agenda.order}</p>
								<p>Track: {agenda.track || 'N/A'}</p>
							</Card>
						))}
					</CardWrapper>}
			</Row>
			{renderFormModal()}
		</WrapperContainer>
	);
};

Agendas.propTypes = {
	pageTitle: T.func,
	match: T.object,
};

export default Agendas;
