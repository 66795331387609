import React from 'react';
import styled from 'styled-components';

const WifiIconTag = styled.i`
		color: #00ab00!important;
`;

const WifiIcon = () => <WifiIconTag className="fas fa-wifi" title="Online" />;

export default WifiIcon;
