export const RSS_FEED_LIST_BASE_URL = '/api/v2/rw/feedlist';

export const FEED_LOADING_MESSAGE = { intent: 'info', message: 'Loading...' };
export const CREATE_FEED_SUCCESS_MESSAGE = { intent: 'success', message: 'Feed Saved Successfully' };
export const CREATE_FEED_LOAD_MESSAGE = { intent: 'info', message: 'Saving...' };
export const UPDATE_FEED_SUCCESS_MESSAGE = { intent: 'success', message: 'Feed Updated Successfully' };
export const UPDATE_FEED_LOAD_MESSAGE = { intent: 'info', message: 'Updating...' };
export const DELETE_FEED_SUCCESS_MESSAGE = { intent: 'success', message: 'Feed Deleted Successfully' };
export const DELETE_FEED_LOAD_MESSAGE = { intent: 'info', message: 'Deleting...' };
export const FEED_ERROR_MESSAGE = { intent: 'danger', message: 'ERROR!!!' };
