import { defineMessages } from 'react-intl';

export default defineMessages({
	companyDeskCaption: {
		id: 'creatorstudio.components.TopicDeskHeader.companyTitle',
		defaultMessage: 'Review companies to update, publish or verify.',
	},
	influencerDeskCaption: {
		id: 'creatorstudio.components.TopicDeskHeader.influencerTitle',
		defaultMessage: 'Review influencers to update, publish or verify.',
	},
	inReview: {
		id: 'creatorstudio.components.TopicDeskHeader.inReview',
		defaultMessage: 'IN REVIEW',
	},
	updatesForReview: {
		id: 'creatorstudio.components.TopicDeskHeader.updatesForReview',
		defaultMessage: 'UPDATES FOR REVIEW',
	},
	published: {
		id: 'creatorstudio.components.TopicDeskHeader.published',
		defaultMessage: 'PUBLISHED',
	},
	verified: {
		id: 'creatorstudio.components.TopicDeskHeader.verified',
		defaultMessage: 'VERIFIED',
	},
	trashed: {
		id: 'creatorstudio.components.TopicDeskHeader.trashed',
		defaultMessage: 'TRASHED',
	},
	search: {
		id: 'creatorstudio.components.TopicDeskHeader.search',
		defaultMessage: 'SEARCH',
	},
});
