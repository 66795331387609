import React from 'react';
import T from 'prop-types';
import { Col } from 'react-styled-flexboxgrid';
import { ListHeaderWrapper } from '../../components/CompanyEditorComponents/styles';

const ListHeader = (props) => (
	<ListHeaderWrapper className="mt-4">
		<Col sm={3} xs={4} onClick={() => props.sortBy('name')} className="pointer">
			Name
			<i className={`ml-2 fas fa-chevron-${!props.sortedBy.name ? null : props.sortedBy.name === 'asc' ? 'up' : 'down'}`} />
		</Col>
		<Col sm={2} xs={4} onClick={() => props.sortBy('status')} className="pointer">
			Status
			<i className={`ml-2 fas fa-chevron-${!props.sortedBy.status ? null : props.sortedBy.status === 'asc' ? 'up' : 'down'}`} />
		</Col>
		<Col sm={2} xs={4} onClick={() => props.sortBy('createdBy')} className="pointer">
			Created By
			<i className={`ml-2 fas fa-chevron-${!props.sortedBy.createdBy ? null : props.sortedBy.createdBy === 'asc' ? 'up' : 'down'}`} />
		</Col>
		<Col sm={3} xs={2} onClick={() => props.sortBy('updatedAt')} className="pointer">
			Updated At
			<i className={`ml-2 fas fa-chevron-${!props.sortedBy.updatedAt ? null : props.sortedBy.updatedAt === 'asc' ? 'up' : 'down'}`} />
		</Col>
		<Col sm={2} xs={4}>
			Actions
		</Col>
	</ListHeaderWrapper>
);

ListHeader.propTypes = {
	sortBy: T.func,
	sortedBy: T.object,
};

export default ListHeader;
