import { call, put, takeLatest } from 'redux-saga/effects';
import { IMAGE_EDITOR_SAVE_IMAGE, FETCH_IMAGE_VERSIONS, FETCH_IMAGE_BY_ID } from './type';
import {
	imageEditorSaveImageFailure, imageEditorSaveImageSuccess, fetchImageVersionsFailure, fetchImageVersionsSuccess, fetchImageByIdFailure, fetchImageByIdSuccess, fetchImageVersions,
} from './actions';

const BASE_URL = '/api/v2/rw/assets';

function* createImageAsset(action) {
	const url = `${BASE_URL}/create`;
	const options = {
		method: 'POST',
		credentials: 'include',
		body: action.image,
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 200) {
			const parsedResponse = yield response.json();
			yield put(imageEditorSaveImageSuccess(parsedResponse));
		} else {
			throw new Error({ message: 'Failed to save image. Somethine went wrong' });
		}
	} catch (err) {
		yield put(imageEditorSaveImageFailure(err));
	}
}

function* getImageVersions(action) {
	const url = `${BASE_URL}/image/versions/${action.parentId}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		const versions = yield response.json();
		yield put(fetchImageVersionsSuccess(versions));
	} catch (err) {
		yield put(fetchImageVersionsFailure(err));
	}
}

function* getImageById(action) {
	const url = `${BASE_URL}/id/${action.imageData.id}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		const asset = yield response.json();
		yield put(fetchImageByIdSuccess(asset, action.imageData));
		if (asset.parentId) {
			yield put(fetchImageVersions(asset.parentId));
		}
	} catch (err) {
		yield put(fetchImageByIdFailure(err));
	}
}

export default function* editorImageHandler() {
	yield takeLatest(IMAGE_EDITOR_SAVE_IMAGE, createImageAsset);
	yield takeLatest(FETCH_IMAGE_VERSIONS, getImageVersions);
	yield takeLatest(FETCH_IMAGE_BY_ID, getImageById);
}
