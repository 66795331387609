export const ON_STORY_SELECT = 'ON_STORY_SELECT';
export const UPDATE_TAGGED_COMPANIES = 'UPDATE_TAGGED_COMPANIES';
export const UPDATE_TAGGED_INFLUENCERS = 'UPDATE_TAGGED_INFLUENCERS';

export const FETCH_STORY_ASSOCIATIONS = 'FETCH_STORY_ASSOCIATIONS';
export const FETCH_STORY_ASSOCIATIONS_SUCCESS = 'FETCH_STORY_ASSOCIATIONS_SUCCESS';
export const FETCH_STORY_ASSOCIATIONS_FAILURE = 'FETCH_STORY_ASSOCIATIONS_FAILURE';

export const SAVE_STORY_ASSOCIATIONS = 'SAVE_STORY_ASSOCIATIONS';
export const SAVE_STORY_ASSOCIATIONS_SUCCESS = 'SAVE_STORY_ASSOCIATIONS_SUCCESS';
export const SAVE_STORY_ASSOCIATIONS_FAILURE = 'SAVE_STORY_ASSOCIATIONS_FAILURE';

export const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
export const CLEAR_STATE = 'CLEAR_STATE';
