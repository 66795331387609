import React from 'react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import { Row, Col } from '../Responsive';
import deleteSvg from '../../images/deleteIcon.svg';
import {
	Card, Image, ImagePlaceHolder, Text, Hr, Option, DeleteIcon, MoveUpIcon, MoveDownIcon, Move, Content, PositionText, ImageSection, BrandText,
} from './styles';
import scrollArrowToDown from '../../images/scroll-arrow-to-down.svg';
import scrollArrowToUp from '../../images/scroll-arrow-to-up.svg';

class CmsPageSetCard extends React.Component {
	constructor(props) {
		super(props);
		this.formatText = this.formatText.bind(this);
	}

	formatText() {
		let { title } = this.props;
		if (title && (title.length > 140)) {
			title = `${title.substring(0, 140)}...`;
		}
		return title;
	}

	render() {
		return (<Card>
			<ImageSection>
				{this.props.image_url
					? <Image src={this.props.image_url} />
					: <ImagePlaceHolder>NO IMAGE</ImagePlaceHolder>}
				<PositionText>{this.props.image_text}</PositionText>
				<BrandText>{this.props.brand_text}</BrandText>
			</ImageSection>
			<Content>
				<Row className="m-0 p-0">
					<Col className="m-0 p-0" xs={10} sm={10} md={10} lg={10}>
						<Text>{this.formatText()}</Text>
						<Hr />
						<Option>
							<DeleteIcon onClick={this.props.deleteCard} src={deleteSvg} alt="Delete icon" />
						</Option>
					</Col>
					<Col className="m-0 p-0" xs={2} sm={2} md={2} lg={2}>
						<Move>
							<MoveUpIcon onClick={this.props.moveUp} src={scrollArrowToUp} alt="scrollArrowToUp" />
							<MoveDownIcon onClick={this.props.moveDown} src={scrollArrowToDown} alt="scrollArrowToDown" />
						</Move>
					</Col>
				</Row>
			</Content>
		</Card>);
	}
}

CmsPageSetCard.defaultProps = {
	image_url: null,
	title: 'NO TITLE',
	brand_text: '',
};

CmsPageSetCard.propTypes = {
	title: PropTypes.string,
	image_url: PropTypes.string,
	image_text: PropTypes.string,
	deleteCard: PropTypes.func,
	moveUp: PropTypes.func,
	moveDown: PropTypes.func,
	brand_text: PropTypes.string,
};

export default CmsPageSetCard;
