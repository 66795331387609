import {
	call, put, takeLatest,
} from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { redirectTo } from '../../utils/accessRedirection';
import {
	FETCH_ORG_CHART_DATA,
	CREATE_ORG_CHART,
	UPDATE_ORG_CHART,
	CREATE_PEOPLE_PROFILE,
	FETCH_PEOPLE_PROFILE_ORG_CHART_DATA,
} from './type';
import {
	createOrgChartSuccess,
	createOrgChartFailure,
	updateOrgChartSuccess,
	updateOrgChartFailure,
	fetchOrgChartDataSuccess,
	fetchOrgChartDataFailure,
	createPeopleProfileSuccess,
	createPeopleProfileFailure,
	fetchSubordinateOrgChartDataFailure,
	fetchSubordinateOrgChartDataSuccess,
} from './actions';
import {
	ORG_CHART_EDITOR_API_BASE_URL, PEOPLE_PROFILE_API_BASE_URL, ORG_CHART_API_BASE_URL, SUBORDINATE_ORG_CHART_API_BASE_URL,
} from './constants';

let CURRENT_LOCATION;

function* fetchOrgChart(action) {
	try {
		const url = `${ORG_CHART_API_BASE_URL}?id=${action.orgChartId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const responseMessage = yield response.json();
			yield put(fetchOrgChartDataSuccess(responseMessage));
		}
	} catch (err) {
		yield put(fetchOrgChartDataFailure(err));
	}
}

function* createNewOrgChart(action) {
	try {
		const url = ORG_CHART_EDITOR_API_BASE_URL;
		const options = {
			method: 'POST',
			credentials: 'include',
			body: action.orgChart,
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(createOrgChartSuccess(responseMessage.data));
				yield put(replace(`/org-chart/editor/${responseMessage.data.id}`));
			} else {
				yield put(createOrgChartFailure(responseMessage.error));
			}
		}
	} catch (err) {
		yield put(createOrgChartFailure(err));
	}
}

function* updateOrgChart(action) {
	try {
		const url = `${ORG_CHART_EDITOR_API_BASE_URL}/${action.id}`;
		const options = {
			method: 'PUT',
			credentials: 'include',
			body: action.orgChart,
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(updateOrgChartSuccess(responseMessage.data));
			} else {
				yield put(updateOrgChartFailure(responseMessage.error));
			}
		}
	} catch (err) {
		yield put(updateOrgChartFailure(err));
	}
}

function* createNewPeopleProfile(action) {
	try {
		const url = PEOPLE_PROFILE_API_BASE_URL;
		const { peopleProfile } = action;
		const options = {
			method: 'POST',
			credentials: 'include',
			body: peopleProfile,
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(createPeopleProfileSuccess(responseMessage.data));
			} else {
				yield put(createPeopleProfileFailure(responseMessage.error));
			}
		}
	} catch (err) {
		yield put(createOrgChartFailure(err));
	}
}

function* fetchSubordinateOrgChart(action) {
	try {
		const url = `${SUBORDINATE_ORG_CHART_API_BASE_URL}?id=${action.subordinateOrgChartId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const responseMessage = yield response.json();
			yield put(fetchSubordinateOrgChartDataSuccess(responseMessage));
		}
	} catch (err) {
		yield put(fetchSubordinateOrgChartDataFailure(err));
	}
}

export default function* jobEditorData() {
	yield takeLatest(FETCH_ORG_CHART_DATA, fetchOrgChart);
	yield takeLatest(CREATE_ORG_CHART, createNewOrgChart);
	yield takeLatest(UPDATE_ORG_CHART, updateOrgChart);
	yield takeLatest(CREATE_PEOPLE_PROFILE, createNewPeopleProfile);
	yield takeLatest(FETCH_PEOPLE_PROFILE_ORG_CHART_DATA, fetchSubordinateOrgChart);
}
