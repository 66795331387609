import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { VERTICAL_API_BASE_URL } from './constants';
import {
	createVerticalFailure, createVerticalSuccess, verticalSelect, updateVerticalSuccess, deleteVerticalSuccess, deleteVerticalFailure, updateVerticalFailure,
} from './actions';
import {
	CREATE_VERTICAL, DELETE_VERTICAL, UPDATE_VERTICAL, REPLACE_VERTICAL,
} from './type';

export function* createNewVertical(action) {
	const url = `${VERTICAL_API_BASE_URL}/`;
	const formData = action.vertical;
	const options = {
		method: 'POST',
		body: formData,
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(createVerticalSuccess(responseMessage));
				yield put(verticalSelect(null));
			} else {
				yield put(createVerticalFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(createVerticalFailure(err));
	}
}

export function* updateVertical(action) {
	const url = `${VERTICAL_API_BASE_URL}/id/${action.id}`;
	const formData = action.updatedData;
	const options = {
		method: 'PUT',
		body: formData,
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(updateVerticalSuccess(responseMessage));
				yield put(verticalSelect(null));
			} else {
				yield put(updateVerticalFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(updateVerticalFailure(err));
	}
}

export function* deleteVertical(action) {
	const url = `${VERTICAL_API_BASE_URL}/${action.id}`;
	const options = {
		method: 'DELETE',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(deleteVerticalSuccess(responseMessage));
				yield put(verticalSelect(null));
			} else {
				yield put(deleteVerticalFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(deleteVerticalFailure(err));
	}
}

export function* replaceVertical(action) {
	const url = `${VERTICAL_API_BASE_URL}/replace`;
	const body = {
		key: action.hoc,
		existing: action.existing.id,
		replacement: action.replacement.id,
	};
	const options = {
		method: 'PUT',
		credentials: 'include',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	};

	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(deleteVerticalSuccess(responseMessage));
				yield put(verticalSelect(null));
			} else {
				yield put(deleteVerticalFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(deleteVerticalFailure(err));
	}
}

export default function* verticalsData() {
	yield takeLatest(CREATE_VERTICAL, createNewVertical);
	yield takeLatest(UPDATE_VERTICAL, updateVertical);
	yield takeLatest(DELETE_VERTICAL, deleteVertical);
	yield takeLatest(REPLACE_VERTICAL, replaceVertical);
}
