import {
	call, put, takeLatest, select,
} from 'redux-saga/effects';
import { FETCH_PROFILES_BY_STATUS, UPDATE_DESK_PROFILE_STATUS, RUN_PROFILE_DESK_SEARCH } from './type';
import { COMPANY_DESK_BASE_API_URL } from './constants';
import {
	fetchDeskProfilesByStatusSuccess, fetchDeskProfilesByStatusFailure, fetchDeskProfilesByStatus, updateDeskProfileStatusFailure, runDeskSearchSuccess, runDeskSearchFailure,
} from './actions';
import { PEOPLE_API_BASE_URL } from '../PeopleManager/constants';
import { redirectTo } from '../../utils/accessRedirection';
import { makeSelectLocation } from '../App/selectors';

let CURRENT_LOCATION;

function* getProfilesByStatus(action) {
	const url = `${action.profileType === 'company' ? COMPANY_DESK_BASE_API_URL : PEOPLE_API_BASE_URL}/status/${action.status}/${action.page}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const allProfiles = yield response.json();
			yield put(fetchDeskProfilesByStatusSuccess(allProfiles));
		}
	} catch (err) {
		yield put(fetchDeskProfilesByStatusFailure(err));
	}
}

function* updateProfileStatus(action) {
	const { profile } = action;
	const url = `${action.profileType === 'company' ? COMPANY_DESK_BASE_API_URL : PEOPLE_API_BASE_URL}/updatestatus/${profile.id}`;
	const data = new FormData();
	data.append('status', action.newStatus);
	data.append('oldStatus', profile.status);
	if (action.alternateProfileId) {
		data.append('alternateProfileId', action.alternateProfileId);
	}
	const options = {
		method: 'PUT',
		credentials: 'include',
		body: data,
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				if (['PENDING-PUBLISHED', 'PENDING-VERIFIED', 'PENDING-REJECTED'].includes(action.newStatus)) {
					yield put(fetchDeskProfilesByStatus('UPDATESFORREVIEW', action.page, action.profileType));
				} else yield put(fetchDeskProfilesByStatus(action.profile.status, action.page, action.profileType));
			} else {
				yield put(updateDeskProfileStatusFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(updateDeskProfileStatusFailure(err));
	}
}

function* filterProfiles(action) {
	const url = `${action.profileType === 'company' ? COMPANY_DESK_BASE_API_URL : PEOPLE_API_BASE_URL}/search`;
	const data = new FormData();
	data.append('type', action.searchType);
	data.append('status', action.status);
	data.append('searchValue', action.searchValue);
	data.append('limit', action.limit);
	const options = {
		method: 'POST',
		credentials: 'include',
		body: data,
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const results = yield response.json();
			yield put(runDeskSearchSuccess(results));
		}
	} catch (err) {
		yield put(runDeskSearchFailure(err));
	}
}

export default function* deskData() {
	const location = yield select(makeSelectLocation());
	CURRENT_LOCATION = location.pathname;
	yield takeLatest(FETCH_PROFILES_BY_STATUS, getProfilesByStatus);
	yield takeLatest(UPDATE_DESK_PROFILE_STATUS, updateProfileStatus);
	yield takeLatest(RUN_PROFILE_DESK_SEARCH, filterProfiles);
}
