import { createGlobalStyle } from 'styled-components';

/* eslint no-unused-expressions: 0 */
const GlobalStyles = createGlobalStyle`
		.slider-disabled {
				background-color: #a6cdf1!important;
		}

		.switch {
				position: relative;
				display: inline-block;
				width: 44px;
				height: 24px;
		}

		.switch input {
				display:none;
		}

		.slider {
				position: absolute;
				cursor: pointer;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: #ccc;
				-webkit-transition: .4s;
				transition: .4s;
		}

		.slider:before {
				position: absolute;
				content: "";
				height: 20px;
				width: 20px;
				left: 3px;
				bottom: 2px;
				background-color: white;
				-webkit-transition: .4s;
				transition: .4s;
				border: 1px solid #efefef;
		}

		input:checked + .slider {
				background-color: #1790ff;
		}

		input:focus + .slider {
				box-shadow: 0 0 1px #1790ff;
		}

		input:checked + .slider:before {
				-webkit-transform: translateX(20px);
				-ms-transform: translateX(20px);
				transform: translateX(20px);
		}

		.slider.round {
				border-radius: 34px;
		}

		.slider.round:before {
				border-radius: 50%;
		}
		.scrollable{
				max-height: 430px;
				overflow-y: scroll;
		}

		.custom-scrollbar::-webkit-scrollbar {
				width: 4px;
		}

		.custom-scrollbar::-webkit-scrollbar-track {
				box-shadow: inset 0 0 5px white; 
				border-radius: 10px;
		}
				
		.custom-scrollbar::-webkit-scrollbar-thumb {
				background: #E5002D; 
				border-radius: 10px;
		}

		.selected-item-style{
				border: 1px solid #8080809c;
				background-color: #eee!important;
		}

		.thumbnail {
				border: 1px solid #ddd;
				border-radius: 4px;
				padding: 4px;
		}

		.thumbnail:hover {
				box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
		}

		.custom-scrollbar::-webkit-scrollbar-thumb:hover {
				background: #E5002D; 
		}

		@-ms-viewport { width: device-width; }
		article, aside, figcaption, figure, footer, header, hgroup, main, nav, section { display: block; }

		html { position: relative; height: 100%; }
		body.fontLoaded { font-family:"PT Sans", sans-serif; }
		body {
				margin: 0;
				font-weight:400;
				font-size:1rem;
				line-height: 1.5;
				//font-family:"PT Sans", sans-serif;
				color:#333;
				background-color: #fff; 
				margin-bottom: 100px;
				height: 100%
		}
		.disablePointerEvents {
				pointer-events: none;
		}
		@media (max-width:450px) {
				.logo { font-size: 1rem !important; margin-left:10px !important; }
				body { margin-bottom:70% !important; }
		}
		@media (max-width:768px) {

				body { margin-bottom:35%; }
				.logo { font-size: 1.3rem; }
				.xs-hide { display:none !important; }
		}

		@media (min-width:769px) and (max-width:1024px) {
				.sm-hide { display:none !important; }
		}


		.footer-float { background: #E0E0E0;position: absolute;left: 0;bottom: 0;width: 100%;overflow: hidden; }

		// Typo
		.announcements { 
				display: inline-flex;
				align-items: center;
				// & #HW_badge { 
				
				//	 top: -24px;
				//	 left: 25px;
				// }
				// & #HW_badge_cont {
				//	 display: inline-block;
				//	 vertical-align: middle;
				// }
		}

		.disabled-btn { background-color: #acb4b7; color: #e8e3e3; border-color: #867a7a}
		.profile-img { width: 50px; height: 50px; border-radius: 50%; }
		@media (max-width:450px) {
				.profile-img { width: 40px; height: 40px; border-radius: 50%; }
		}

		.logo { font-family: 'Oswald', sans-serif; }
		.brand-text { color:#e5002d !important; }
		.white-text { color:#fff !important; }
		.brand-bg { background-color:#e5002d !important; }
		.d-none,.hide { display:none !important; }
		.d-block,.show { display:block !important; }
		.tooltip { display:none; }
		.success-text { color:#28a745 !important; }
		.light-text { color:#a1a1a1 !important; }
		.grey-text { color:grey }
		.error-text { color:red !important; }
		.loading-text { color:#737171 !important; }
		.error { font-size:0.89rem; color:red; position:relative; margin-top: 0px; margin-bottom: 0px; }
		.xs-text,.md-text,.sm-text,lg-text{ color:#333; }
		.md-text { font-size:1rem !important; line-height: 1.5rem; }
		.xs-text { font-size: 0.5rem;line-height: 0.8rem; }
		.sm-text { font-size: 0.8rem; line-height: 1.3rem; }
		.lg-text{ font-size: 1.5rem; line-height: 2rem;}
		.tu { text-transform:uppercase; }
		.tc { text-transform:capitalize; }
		.tl { text-transform:lowercase; }
		.text-center { text-align:center !important; }
		.text-left { text-align:left !important; }
		.text-right { text-align:right !important; }
		.text-normal { font-style: normal !important; font-weight: normal !important; }
		.text-bold { font-weight: bolder}
		.pointer { cursor:pointer;	}
		.w-100 { width: 100%; }
		.transculent-bg { background-color: rgb(217, 224, 228, 0.5); }

		@media (max-width: 768px) { 
				.xs-text-center { text-align:center!important; }
		}
		@media (max-width: 1024px) { 
				.sm-text-center { text-align:center !important; }
		}
		@media (min-width: 1024px) { 
				.wrap-width { width: 70% }
		}

		.version {
				cursor: pointer;
				padding: 0.5rem !important;
		}

		.v-selected {
				background-color: #eee;
		}

		// Floats

		.pull-left { float:left; }
		.pull-right { float:right; }
		.pr { position:relative; }
		.pb { position:absolute; }

		// Image 
		.img-fluid { max-width: 100%; height: 200px; }

		.global-form-control {
				display: block;
				width: 100%;
				padding: 0.375rem 0.75rem;
				line-height: 1.5;
				color: #000;
				height:auto;
				background-color: #fff;
				background-clip: padding-box;
				border: 1px solid #ced4da;
				border-radius: 0.25rem;
		}
		.global-form-control:focus {
				outline:none;
		}
		.global-category {
				padding-top: 0.75rem;
				margin-bottom: 1rem;
				font-size: 0.75rem;
				color: #565656;
				text-transform: uppercase;
		}

		.global-highlight,.global-highlight-twice {
				height: 6px;
				border-radius: 0.3rem;
				background-color: #e5002d;
				width: 3rem;
				margin-top:0.5rem;
				margin-bottom:0.5rem;
		}

		.global-highlight-twice { width: 6rem; }

		.global-bg-heading {
				background-color: #e5002d;
				color: #fff;
				font-size: 1rem;
				margin-bottom: 0;
				padding: 0.8rem;
		}

		.global-card-title {
				position: relative;
				line-height: 1.5rem;
				max-height: 4.5rem;
				overflow: hidden;
				font-size: 1.25rem;
				font-weight: 700;
				color: #000;
				margin-bottom: 1rem;
		}

		.global-card-description {
				position: relative;
				line-height: 1.5rem;
				max-height: 4.5rem;
				overflow: hidden;
				font-size: 1.25rem;
				font-weight: 700;
				color: #000;
				margin-bottom: 1rem;
		}

		.global-author-name {
				font-size: 0.8125rem;
				color: #666;
				text-transform: capitalize;
		}

		.share-icon {
				float:right; 
				width: 1.3rem;
				height: 1.3rem;
		}

		.img-icon { position:relative; }

		.play-icon {
				position: absolute;
				color: white;
				left: 15px;
				bottom: 5px;
				height: 45px;
				font-size:1.8rem;
				padding:5px;
				background: rgba(0, 0, 0, 0.5);
				border-radius: 5px;
		}

		// Margin

		.m-0 { margin: 0 !important; }
		.m-auto { margin: auto !important; }
		.mt-auto { margin-top: auto !important; }
		.mr-auto { margin-right: auto !important; }
		.mb-auto { margin-bottom: auto !important; }
		.ml-auto { margin-left: auto !important; }
		.mt-0 { margin-top: 0 !important; }
		.mr-0 { margin-right: 0 !important;}
		.mb-0 { margin-bottom: 0 !important; }
		.ml-0 { margin-left: 0 !important; }
		.m-1 { margin: 0.25rem !important; }
		.mt-1 { margin-top: 0.25rem !important; }
		.mr-1 { margin-right: 0.25rem !important;	}
		.mb-1 { margin-bottom: 0.25rem !important;}
		.ml-1 { margin-left: 0.25rem !important; }
		.m-2 { margin: 0.5rem !important; }
		.mt-2 { margin-top: 0.5rem !important;}
		.mr-2 { margin-right: 0.5rem !important;}
		.mb-2 { margin-bottom: 0.5rem !important;}
		.ml-2 { margin-left: 0.5rem !important;}
		.m-3 { margin: 1rem !important; }
		.mt-3 { margin-top: 1rem !important; }
		.mr-3 { margin-right: 1rem !important; }
		.mb-3 { margin-bottom: 1rem !important; }
		.ml-3 { margin-left: 1rem !important; }
		.m-4 { margin: 1.5rem !important; }
		.mt-4 { margin-top: 1.5rem !important; }
		.mr-4 { margin-right: 1.5rem !important; }
		.mb-4 { margin-bottom: 1.5rem !important; }
		.ml-4 { margin-left: 1.5rem !important; }
		.m-5 { margin: 3rem !important; }
		.mt-5 { margin-top: 3rem !important; }
		.mr-5 { margin-right: 3rem !important; }
		.mb-5 { margin-bottom: 3rem !important; }
		.ml-5{ margin-left: 3rem !important; }

		// Padding

		.p-0 { padding: 0 !important; }
		.pt-0 { padding-top: 0 !important; }
		.pr-0 { padding-right: 0 !important; }
		.pb-0 { padding-bottom: 0 !important; }
		.pl-0 { padding-left: 0 !important; }
		.p-1 { padding: 0.25rem !important; }
		.pt-1 { padding-top: 0.25rem !important; }
		.pr-1 { padding-right: 0.25rem !important; }
		.pb-1 { padding-bottom: 0.25rem !important; }
		.pl-1 { padding-left: 0.25rem !important; }
		.p-2 { padding: 0.5rem !important; }
		.pt-2 { padding-top: 0.5rem !important; }
		.pr-2 { padding-right: 0.5rem !important; }
		.pb-2 { padding-bottom: 0.5rem !important; }
		.pl-2 { padding-left: 0.5rem !important; }
		.p-3 { padding: 1rem !important; }
		.pt-3 { padding-top: 1rem !important; }
		.pr-3 { padding-right: 1rem !important; }
		.pb-3 { padding-bottom: 1rem !important; }
		.pl-3 { padding-left: 1rem !important; }
		.p-4 { padding: 1.5rem !important; }
		.pt-4 { padding-top: 1.5rem !important; }
		.pr-4 { padding-right: 1.5rem !important; }
		.pb-4 { padding-bottom: 1.5rem !important; }
		.pl-4 { padding-left: 1.5rem !important; }
		.p-5 { padding: 3rem !important; }
		.pt-5 { padding-top: 3rem !important; }
		.pr-5 { padding-right: 3rem !important; }
		.pb-5 { padding-bottom: 3rem !important; }
		.pl-5 { padding-left: 3rem !important; }


		@media (max-width: 768px) {
				.m-sm-0 { margin: 0 !important; }
				.m-sm-auto { margin:0 auto !important; }
				.mt-sm-auto { margin-top: auto !important; }
				.mr-sm-auto { margin-right: auto !important; }
				.mb-sm-auto { margin-bottom: auto !important; }
				.ml-sm-auto { margin-left: auto !important; } 
				.mt-sm-0 { margin-top: 0 !important; }
				.mr-sm-0 { margin-right: 0 !important; }
				.mb-sm-0 { margin-bottom: 0 !important; }
				.ml-sm-0 { margin-left: 0 !important; }
				.m-sm-1 { margin: 0.25rem !important; }
				.mt-sm-1 { margin-top: 0.25rem !important; }
				.mr-sm-1 { margin-right: 0.25rem !important; }
				.mb-sm-1 { margin-bottom: 0.25rem !important; }
				.ml-sm-1 { margin-left: 0.25rem !important; }
				.m-sm-2 { margin: 0.5rem !important; }
				.mt-sm-2 { margin-top: 0.5rem !important; }
				.mr-sm-2 { margin-right: 0.5rem !important; }
				.mb-sm-2 { margin-bottom: 0.5rem !important; }
				.ml-sm-2 { margin-left: 0.5rem !important; }
				.m-sm-3 { margin: 1rem !important; }
				.mt-sm-3 { margin-top: 1rem !important; }
				.mr-sm-3 { margin-right: 1rem !important; }
				.mb-sm-3 { margin-bottom: 1rem !important; }
				.ml-sm-3 { margin-left: 1rem !important; }
				.m-sm-4 { margin: 1.5rem !important; }
				.mt-sm-4 { margin-top: 1.5rem !important; }
				.mr-sm-4 { margin-right: 1.5rem !important; }
				.mb-sm-4 { margin-bottom: 1.5rem !important; }
				.ml-sm-4 { margin-left: 1.5rem !important; }
				.m-sm-5 { margin: 3rem !important; }
				.mt-sm-5 { margin-top: 3rem !important; }
				.mr-sm-5 { margin-right: 3rem !important; }
				.mb-sm-5 { margin-bottom: 3rem !important; }
				.ml-sm-5 { margin-left: 3rem !important; }
				.p-sm-0 { padding: 0 !important; }
				.pt-sm-0 { padding-top: 0 !important; }
				.pr-sm-0 { padding-right: 0 !important; }
				.pb-sm-0 { padding-bottom: 0 !important; }
				.pl-sm-0 { padding-left: 0 !important; }
				.p-sm-1 { padding: 0.25rem !important; }
				.pt-sm-1 { padding-top: 0.25rem !important; }
				.pr-sm-1 { padding-right: 0.25rem !important; }
				.pb-sm-1 { padding-bottom: 0.25rem !important; }
				.pl-sm-1 { padding-left: 0.25rem !important; }
				.p-sm-2 { padding: 0.5rem !important; }
				.pt-sm-2 { padding-top: 0.5rem !important; }
				.pr-sm-2 { padding-right: 0.5rem !important; }
				.pb-sm-2 { padding-bottom: 0.5rem !important; }
				.pl-sm-2 { padding-left: 0.5rem !important; }
				.p-sm-3 { padding: 1rem !important; }
				.pt-sm-3 { padding-top: 1rem !important; }
				.pr-sm-3 { padding-right: 1rem !important; }
				.pb-sm-3 { padding-bottom: 1rem !important; }
				.pl-sm-3 { padding-left: 1rem !important; }
				.p-sm-4 { padding: 1.5rem !important; }
				.pt-sm-4 { padding-top: 1.5rem !important; }
				.pr-sm-4 { padding-right: 1.5rem !important; }
				.pb-sm-4 { padding-bottom: 1.5rem !important; }
				.pl-sm-4 { padding-left: 1.5rem !important; }
				.p-sm-5 { padding: 3rem !important; }
				.pt-sm-5 { padding-top: 3rem !important; }
				.pr-sm-5 { padding-right: 3rem !important; }
				.pb-sm-5 { padding-bottom: 3rem !important; }
				.pl-sm-5 { padding-left: 3rem !important; }

		}

		@media (min-width: 769px) {
				.m-md-0 { margin: 0 !important; }
				.mt-md-0 { margin-top: 0 !important; }
				.mr-md-0 { margin-right: 0 !important; }
				.mb-md-0 { margin-bottom: 0 !important; }
				.ml-md-0 { margin-left: 0 !important; }
				.m-md-1 { margin: 0.25rem !important; }
				.mt-md-1 { margin-top: 0.25rem !important; }
				.mr-md-1 { margin-right: 0.25rem !important; }
				.mb-md-1 { margin-bottom: 0.25rem !important; }
				.ml-md-1 { margin-left: 0.25rem !important; }
				.m-md-2 { margin: 0.5rem !important; }
				.mt-md-2 { margin-top: 0.5rem !important; }
				.mr-md-2 { margin-right: 0.5rem !important; }
				.mb-md-2 { margin-bottom: 0.5rem !important; }
				.ml-md-2 { margin-left: 0.5rem !important; }
				.m-md-3 { margin: 1rem !important; }
				.mt-md-3 { margin-top: 1rem !important; }
				.mr-md-3 { margin-right: 1rem !important; }
				.mb-md-3 { margin-bottom: 1rem !important; }
				.ml-md-3 { margin-left: 1rem !important; }
				.m-md-4 { margin: 1.5rem !important; }
				.mt-md-4 { margin-top: 1.5rem !important; }
				.mr-md-4 { margin-right: 1.5rem !important; }
				.mb-md-4 { margin-bottom: 1.5rem !important; }
				.ml-md-4 { margin-left: 1.5rem !important; }
				.m-md-5 { margin: 3rem !important; }
				.mt-md-5 { margin-top: 3rem !important; }
				.mr-md-5 { margin-right: 3rem !important; }
				.mb-md-5 { margin-bottom: 3rem !important; }
				.ml-md-5 { margin-left: 3rem !important; }
				.p-md-0 { padding: 0 !important; }
				.pt-md-0 { padding-top: 0!important; }
				.pr-md-0 { padding-right: 0 !important; }
				.pb-md-0 { padding-bottom: 0 !important; }
				.pl-md-0 { padding-left: 0 !important; }
				.p-md-1 { padding: 0.25rem !important; }
				.pt-md-1 { padding-top: 0.25rem !important; }
				.pr-md-1 { padding-right: 0.25rem !important; }
				.pb-md-1 { padding-bottom: 0.25rem !important; }
				.pl-md-1 { padding-left: 0.25rem !important; }
				.p-md-2 { padding: 0.5rem !important; }
				.pt-md-2 { padding-top: 0.5rem !important; }
				.pr-md-2 { padding-right: 0.5rem !important; }
				.pb-md-2 { padding-bottom: 0.5rem !important; }
				.pl-md-2 { padding-left: 0.5rem !important; }
				.p-md-3 { padding: 1rem !important; }
				.pt-md-3 { padding-top: 1rem !important; }
				.pr-md-3 { padding-right: 1rem !important; }
				.pb-md-3 { padding-bottom: 1rem !important; }
				.pl-md-3{ padding-left: 1rem !important; }
				.p-md-4 { padding: 1.5rem !important; }
				.pt-md-4 { padding-top: 1.5rem !important; }
				.pr-md-4 { padding-right: 1.5rem !important; }
				.pb-md-4 { padding-bottom: 1.5rem !important; }
				.pl-md-4 { padding-left: 1.5rem !important; }
				.p-md-5 { padding: 3rem !important; }
				.pt-md-5 { padding-top: 3rem !important; }
				.pr-md-5 { padding-right: 3rem !important; }
				.pb-md-5 { padding-bottom: 3rem !important; }
				.pl-md-5 { padding-left: 3rem !important; }
				.m-md-auto { margin: auto !important; }
				.mt-md-auto { margin-top: auto !important; }
				.mr-md-auto { margin-right: auto !important; }
				.mb-md-auto { margin-bottom: auto !important; }
				.ml-md-auto { margin-left: auto !important; } 

		}

		@media (min-width: 1024px) {
				.m-lg-0 { margin: 0 !important; }
				.mt-lg-0 { margin-top: 0 !important; }
				.mr-lg-0 { margin-right: 0 !important; }
				.mb-lg-0 { margin-bottom: 0 !important; }
				.ml-lg-0 { margin-left: 0 !important; }
				.m-lg-1 { margin: 0.25rem !important; }
				.mt-lg-1 { margin-top: 0.25rem !important; }
				.mr-lg-1 { margin-right: 0.25rem !important; }
				.mb-lg-1 { margin-bottom: 0.25rem !important; }
				.ml-lg-1 { margin-left: 0.25rem !important; }
				.m-lg-2 { margin: 0.5rem !important; }
				.mt-lg-2 { margin-top: 0.5rem !important; }
				.mr-lg-2 { margin-right: 0.5rem !important; }
				.mb-lg-2 { margin-bottom: 0.5rem !important; }
				.ml-lg-2 { margin-left: 0.5rem !important; }
				.m-lg-3 { margin: 1rem !important; }
				.mt-lg-3 { margin-top: 1rem !important; }
				.mr-lg-3 { margin-right: 1rem !important; }
				.mb-lg-3 { margin-bottom: 1rem !important; }
				.ml-lg-3 { margin-left: 1rem !important; }
				.m-lg-4 { margin: 1.5rem !important; }
				.mt-lg-4 { margin-top: 1.5rem !important; }
				.mr-lg-4 { margin-right: 1.5rem !important; }
				.mb-lg-4 { margin-bottom: 1.5rem !important; }
				.ml-lg-4 { margin-left: 1.5rem !important; }
				.m-lg-5 { margin: 3rem !important; }
				.mt-lg-5 { margin-top: 3rem !important; }
				.mr-lg-5 { margin-right: 3rem !important; }
				.mb-lg-5 { margin-bottom: 3rem !important; }
				.ml-lg-5 { margin-left: 3rem !important; }
				.p-lg-0 { padding: 0 !important; }
				.pt-lg-0 { padding-top: 0 !important; }
				.pr-lg-0 { padding-right: 0 !important; }
				.pb-lg-0 { padding-bottom: 0 !important; }
				.pl-lg-0 { padding-left: 0 !important; }
				.p-lg-1 { padding: 0.25rem !important; }
				.pt-lg-1 { padding-top: 0.25rem !important; }
				.pr-lg-1 { padding-right: 0.25rem !important; }
				.pb-lg-1 { padding-bottom: 0.25rem !important; }
				.pl-lg-1 { padding-left: 0.25rem !important; }
				.p-lg-2 { padding: 0.5rem !important; }
				.pt-lg-2 { padding-top: 0.5rem !important; }
				.pr-lg-2 { padding-right: 0.5rem !important; }
				.pb-lg-2 { padding-bottom: 0.5rem !important; }
				.pl-lg-2 { padding-left: 0.5rem !important; }
				.p-lg-3 { padding: 1rem !important; }
				.pt-lg-3 { padding-top: 1rem !important; }
				.pr-lg-3 { padding-right: 1rem !important; }
				.pb-lg-3 { padding-bottom: 1rem !important; }
				.pl-lg-3 { padding-left: 1rem !important; }
				.p-lg-4 { padding: 1.5rem !important; }
				.pt-lg-4 { padding-top: 1.5rem !important; }
				.pr-lg-4 { padding-right: 1.5rem !important; }
				.pb-lg-4 { padding-bottom: 1.5rem !important; }
				.pl-lg-4 { padding-left: 1.5rem !important; }
				.p-lg-5 { padding: 3rem !important; }
				.pt-lg-5 { padding-top: 3rem !important; }
				.pr-lg-5 { padding-right: 3rem !important; }
				.pb-lg-5 { padding-bottom: 3rem !important; }
				.pl-lg-5 { padding-left: 3rem !important; }
				.m-lg-auto { margin: auto !important; }
				.mt-lg-auto { margin-top: auto !important; }
				.mr-lg-auto { margin-right: auto !important; }
				.mb-lg-auto { margin-bottom: auto !important; }
				.ml-lg-auto { margin-left: auto !important; } 
		}

		@media (min-width: 1200px) {
				.m-xl-0 { margin: 0 !important; }
				.mt-xl-0 { margin-top: 0 !important; }
				.mr-xl-0 { margin-right: 0 !important; }
				.mb-xl-0 { margin-bottom: 0 !important; }
				.ml-xl-0 { margin-left: 0 !important; }
				.m-xl-1 { margin: 0.25rem !important; }
				.mt-xl-1 { margin-top: 0.25rem !important; }
				.mr-xl-1 { margin-right: 0.25rem !important; }
				.mb-xl-1 { margin-bottom: 0.25rem !important; }
				.ml-xl-1 { margin-left: 0.25rem !important; }
				.m-xl-2 { margin: 0.5rem !important; }
				.mt-xl-2 { margin-top: 0.5rem !important; }
				.mr-xl-2 { margin-right: 0.5rem !important; }
				.mb-xl-2 { margin-bottom: 0.5rem !important; }
				.ml-xl-2 { margin-left: 0.5rem !important; }
				.m-xl-3 { margin: 1rem !important; }
				.mt-xl-3 { margin-top: 1rem !important; }
				.mr-xl-3 { margin-right: 1rem !important; }
				.mb-xl-3 { margin-bottom: 1rem !important; }
				.ml-xl-3 { margin-left: 1rem !important; }
				.m-xl-4 { margin: 1.5rem !important; }
				.mt-xl-4 { margin-top: 1.5rem !important; }
				.mr-xl-4 { margin-right: 1.5rem !important; }
				.mb-xl-4 { margin-bottom: 1.5rem !important; }
				.ml-xl-4 { margin-left: 1.5rem !important; }
				.m-xl-5 { margin: 3rem !important; }
				.mt-xl-5 { margin-top: 3rem !important; }
				.mr-xl-5 { margin-right: 3rem !important; }
				.mb-xl-5 { margin-bottom: 3rem !important; }
				.ml-xl-5 { margin-left: 3rem !important; }
				.p-xl-0 { padding: 0 !important; }
				.pt-xl-0 { padding-top: 0 !important; }
				.pr-xl-0 { padding-right: 0 !important; }
				.pb-xl-0 { padding-bottom: 0 !important; }
				.pl-xl-0 { padding-left: 0 !important; }
				.p-xl-1 { padding: 0.25rem !important; }
				.pt-xl-1 { padding-top: 0.25rem !important; }
				.pr-xl-1 { padding-right: 0.25rem !important; }
				.pb-xl-1 { padding-bottom: 0.25rem !important; }
				.pl-xl-1 { padding-left: 0.25rem !important; }
				.p-xl-2 { padding: 0.5rem !important; }
				.pt-xl-2 { padding-top: 0.5rem !important; }
				.pr-xl-2 { padding-right: 0.5rem !important; }
				.pb-xl-2 { padding-bottom: 0.5rem !important; }
				.pl-xl-2 { padding-left: 0.5rem !important; }
				.p-xl-3 { padding: 1rem !important; }
				.pt-xl-3 { padding-top: 1rem !important; }
				.pr-xl-3 { padding-right: 1rem !important; }
				.pb-xl-3 { padding-bottom: 1rem !important; }
				.pl-xl-3 { padding-left: 1rem !important; }
				.p-xl-4 { padding: 1.5rem !important; }
				.pt-xl-4 { padding-top: 1.5rem !important; }
				.pr-xl-4 { padding-right: 1.5rem !important; }
				.pb-xl-4 { padding-bottom: 1.5rem !important; }
				.pl-xl-4 { padding-left: 1.5rem !important; }
				.p-xl-5 { padding: 3rem !important; }
				.pt-xl-5 { padding-top: 3rem !important; }
				.pr-xl-5 { padding-right: 3rem !important; }
				.pb-xl-5 { padding-bottom: 3rem !important; }
				.pl-xl-5 { padding-left: 3rem !important; }

		}

		// Border Radius

		.brr-0 { border-radius:0 !important; }
		.brt-0 { border-top-left-radius:0 !important; border-top-right-radius:0 !important; }
		.brb-0 { border-bottom-left-radius:0 !important;border-bottom-right-radius:0 !important; }
		.br { border-radius: 0.25rem; }
		.br-sm { border-radius: 0.2rem; }
		.br-lg { border-radius: 0.3rem; }
		.rounded-circle { border-radius: 50% !important; }
		.br-r { border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem; }

		// Shadows

		.shadow { box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }
		.shadow-sm { box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075); }
		.shadow-lg { box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }
		.shadow-none { box-shadow: none ; }

		// border 
		.border-color { border-color:#ddd !important; }
		.border-brand { border-color:#e5002d !important;}
		.border-white { border-color: #fff !important; }
		.border { border: 1px solid #ddd !important; }
		.b-top { border-top: 1px solid #ddd !important; }
		.b-right { border-right: 1px solid #ddd !important; }
		.b-bottom { border-bottom: 1px solid #ddd !important; }
		.b-left { border-left: 1px solid #ddd !important; }
		.b-0 { border: 0 !important; }
		.bt-0 { border-top: 0 !important; }
		.br-0 { border-right: 0 !important; }
		.bb-0 { border-bottom: 0 !important; }
		.bl-0 { border-left: 0 !important; }

		.nav-b { border-bottom: 5px solid #ddd; !important }
		.nav-b-a { border-bottom: 5px solid #e5002d; !important }
		.nav-text { font-size: 1.3rem; line-height: 2rem; font-weight: 500; text-align: center; cursor: pointer; }

		@media (min-width: 0) and (max-width: 991.98px) { 
				.nav-b { border-bottom: 3px solid #ddd; !important }
				.nav-b-a { border-bottom: 3px solid #e5002d; !important }
				.nav-text { font-size: 1rem; line-height: 2rem; font-weight: 500; text-align: center; cursor: pointer; }
		}


		.storylist-dropdown {
				display: none;
		}


		@media (min-width: 0) and (max-width: 767px) { 
				.storylist-tabs {
						display: none;
				}
				
				.storylist-dropdown {
						display: block;
				}
		}


		[tabindex="-1"]:focus { outline: 0; }
		hr { box-sizing: content-box;height: 0; overflow: visible; }
		h1, h2, h3, h4, h5, h6 { margin-top:0.5rem;margin-bottom:0.5rem;color: initial; }
		p { margin-top: 0;margin-bottom: 1rem; }
		abbr[title],
		abbr[data-original-title] { text-decoration: underline;text-decoration: underline dotted;cursor: help;border-bottom: 0; }
		address { margin-bottom: 1rem;font-style: normal;line-height: inherit; }
		ol, ul, dl { margin-top: 0;margin-bottom: 1rem; }
		dt { font-weight: 400; }
		dd { margin-bottom: .5rem;margin-left: 0; }
		blockquote { margin: 0 0 1rem; }
		dfn { font-style: italic; }
		b, strong { font-weight: bolder; }
		small { font-size: 80%; }
		sub,
		sup { position: relative;font-size: 75%;line-height: 0;vertical-align: baseline; }
		sub { bottom: -.25em; }
		sup { top: -.5em; }
		a { color:inherit;text-decoration: none;background-color: transparent;-webkit-text-decoration-skip: objects; }
		a:hover { text-decoration: none; }
		a:not([href]):not([tabindex]) { color: inherit;text-decoration: none; }
		a:not([href]):not([tabindex]):hover, 
		a:not([href]):not([tabindex]):focus { color: inherit;text-decoration: none; }
		a:not([href]):not([tabindex]):focus { outline: 0; }
		pre,
		code,
		kbd,
		samp { font-family: "PT Sans", sans-serif;font-size: 1em; }
		pre { margin-top: 0;margin-bottom: 1rem;overflow: auto;-ms-overflow-style: scrollbar; }
		figure { margin: 0 0 1rem; }
		img { vertical-align: middle;border-style: none; }
		svg:not(:root) { overflow: hidden; }
		table { border-collapse: collapse; }
		caption { padding-top: 0.75rem;padding-bottom: 0.75rem;color: #666;text-align: left;caption-side: bottom; }
		th { text-align: inherit; }
		label { display: inline-block;margin-bottom: 0.5rem;color: #000; }
		input,
		button,
		select,
		optgroup,
		textarea { margin: 0;font-family: inherit;font-size: inherit;line-height: inherit; }

		button,
		select { text-transform: none; cursor:pointer; background: inherit; color: inherit; border: none; }
		button:focus { outline:none; cursor:pointer; }
		button,
		html [type="button"],
		[type="reset"],
		[type="submit"] { -webkit-appearance: button; }

		button::-moz-focus-inner,
		[type="button"]::-moz-focus-inner,
		[type="reset"]::-moz-focus-inner,
		[type="submit"]::-moz-focus-inner { padding: 0; border-style: none; }

		input[type="radio"],
		input[type="checkbox"] { box-sizing: border-box;padding: 0; }

		input[type="date"],
		input[type="time"],
		input[type="datetime-local"],
		input[type="month"] { -webkit-appearance: listbox; }

		textarea { overflow: auto;resize: vertical; resize:none; }
		fieldset { min-width: 0;padding: 0;margin: 0;border: 0; }
		legend { display: block;width: 100%;max-width: 100%;padding: 0;margin-bottom: .5rem;font-size: 1.5rem;line-height: inherit;color: inherit;white-space: normal; }
		[type="number"]::-webkit-inner-spin-button,
		[type="number"]::-webkit-outer-spin-button { height: auto; }

		[type="search"] { outline-offset: -2px;-webkit-appearance: none; }

		[type="search"]::-webkit-search-cancel-button,
		[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

		::-webkit-file-upload-button { font: inherit; -webkit-appearance: button; }

		[hidden] { display: none; }

		// .tweet, .insta { display: flex; flex-direction: column; justify-content: center; align-items: center; }

		input[type=date]::-webkit-inner-spin-button,
		input[type=date]::-webkit-outer-spin-button {
				-webkit-appearance: none;
		}

		.story-preview-btn {
				font-weight: 900;
		}

		.story-preview-btn.active {
				background-color: #000;
				color: #fff;
		}
		.story-preview-btn.disabled {
				background-color: #dcdcdc;
		}

		.embed-iframe-outer-wrapper {
				position: relative;
				overflow: hidden;
				padding-bottom: 60.25%;
				@media (max-width:768px) {
						padding-bottom: 75.25%;
				}
		}

		.embed-iframe-inner-wrapper {
				position: absolute;
				top: 0;
				left: 0;
				border: 0;
				width: 100%;
				height: 100%;
		}

		.meta-datepicker {
				width: 200px;
				border: 1px solid #ddd !important;
				padding: 0.25rem;
		}
		
		.meta-datepicker-work {
				width: 150px;
				border: 1px solid #ddd !important;
				padding: 0.25rem;
		}

		.brand-datepicker {
			width: 100%;
			border: 1px solid #ddd !important;
			padding: 0.25rem;
		}

		.slide-pane__content {
				padding: 0 !important;
				overflow-y: scroll !important;
		}

		.slide-pane__header {
				background: #fff !important;
		}

		.slide-pane .slide-pane__title {
				font-size: 1.5rem !important;
				font-weight: bold !important;
		}

		.slide-pane-overlay {
				z-index: 5;
		}

		.ReactModal__Body--open {
				overflow: hidden;
		}

		.tooltip-width {
				width: 300px;
		}

		.modal-overlay {
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba(0,0,0,0.3);
				-webkit-transition: background-color 0.5s;
				transition: background-color 0.5s;
				z-index: 5;
		}
    .form-modal-offlineEvent{
			width: 50% !important;
			position: absolute;
			max-width: 400px;
			top: 50%;
			left: 50%;
			right: auto;
			bottom: auto;
			marginRight: -50%;
			transform: translate(-50%, -50%);
			background-color: #ffffff;
			padding: 1rem;
			@media(max-width: 768px) {
					width: 95% !important;
					height: 90% !important;
					overflow-x: scroll;
			}
			@media(max-width: 1024px) {
					width: 70% !important;
			}
	}
		}
		.form-modal {
				width: 50% !important;
				position: absolute;
				top: 50%;
				left: 50%;
				right: auto;
				bottom: auto;
				marginRight: -50%;
				transform: translate(-50%, -50%);
				background-color: #ffffff;
				padding: 1rem;
				@media(max-width: 768px) {
						width: 95% !important;
						height: 90% !important;
						overflow-x: scroll;
				}
				@media(max-width: 1024px) {
						width: 70% !important;
				}
		}
		.form-modal-profile-manager {
			width: 50% !important;
			position: absolute;
			top: 50%;
			left: 50%;
			right: auto;
			bottom: auto;
			marginRight: -50%;
			transform: translate(-50%, -50%);
			background-color: #ffffff;
			padding: 1rem;
			height: 90% !important;
			overflow-x: scroll;
			@media(max-width: 768px) {
					width: 95% !important;
			}
			@media(max-width: 1024px) {
					width: 70% !important;
			}
		}
		.form-modal-profile {
				width: 35% !important;
				position: absolute;
				top: 50%;
				left: 50%;
				right: auto;
				bottom: auto;
				marginRight: -50%;
				transform: translate(-50%, -50%);
				background-color: #ffffff;
				padding: 1rem;
				@media(max-width: 768px) {
						width: 95% !important;
						height: 90% !important;
						overflow-x: scroll;
				}
				@media(max-width: 1024px) {
						width: 70% !important;
				}
		}

		.form-modal-errors, .form-modal-liveFeed, .form-modal-keyTakeAwaysCompany {
				width: 30% !important;
				position: absolute;
				top: 50%;
				left: 50%;
				right: auto;
				bottom: auto;
				marginRight: -50%;
				transform: translate(-50%, -50%);
				background-color: #ffffff;
				padding: 1rem;
				@media(max-width: 768px) {
						width: 95% !important;
						height: 90% !important;
						overflow-x: scroll;
				}
				@media(max-width: 1024px) {
						width: 70% !important;
				}
		}

		.react-modal-content {
				position: absolute;
				top: 50%;
				left: 50%;
				right: auto;
				bottom: auto;
				border: 1px solid rgb(204, 204, 204);
				background: rgb(255, 255, 255);
				overflow: auto;
				border-radius: 4px;
				outline: none;
				padding: 20px;
				margin-right: -50%;
				transform: translate(-50%, -50%);
				z-index: 5;
				@media(max-width: 769px) {
						margin-right: -40%;
				}
		}

		.editor-sliding-pane {
				width: 700px;
				@media (max-width: 769px) {
						width: 100%;
				}
		}

		.flex {
				display: flex;
		}

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
		}
		input[type=number] {
				-moz-appearance: textfield;
		}
		.longText  {
			text-transform: lowercase; /* change to lowercase */
		}
		.longText::first-letter {
			text-transform: uppercase;
		}
		.keyTakeAwaysBlotContainer .ql-editor{
			min-height: 10vh !important;
		}
		.keyTakeAwaysBlotContainer .ql-editor .inline_link{
			color: #000000 !important;
			border: 1px solid #000000;
			padding: 2px 5px 0px !important;
			text-decoration: none !important;
		}
		.keyTakeAwaysBlotContainer .ql-editor u{
			border-bottom: 1px solid #000000;
			text-decoration: none !important;
		}
		.keyTakeAwaysBlotContainer .ql-container.ql-snow{
			border: 1px solid #ccc !important;
		}
		.keyTakeAwaysBlotContainer .ql-editor .company-widget {
			font-size: 1.12rem !important;
			color: #000000 !important;
			cursor: pointer !important;
			font-weight: 900 !important;
			font-family: 'Bunyan Pro' !important;
		}
		.keyTakeAwaysBlotContainer .ql-editor .company-widget::after {
			font-family: pt-sans !important;
			content: 'i';
			font-size: 0.7rem !important;
			border-radius: 20px !important;
			border: 1px solid #000000 !important;;
			color: #000000 !important;
			transform: translateY(-24%) !important;
			line-height: 0.8rem !important;
			display: inline-block !important;
			height: 1rem !important;
			width: 0.9rem !important;
			margin: 0 0.2rem !important;
			text-align: center !important;
		}
		.profileBioSaveBtn{
			margin-top: 15%;
			@media(max-width: 767px){
				margin-top: 18%;
			}
		}
		.profileBioEditor{
			margin-top: 7%;
			@media(max-width: 767px){
				margin-top: 20%;
			}
		}
		.topicManager a{
			color: #06c;
			text-decoration: underline;
			background-color: transparent;
		}
		.transparentAnchorTag a{
			text-decoration: unset !important;
			background-color: transparent !important;
			color: #000 !important;
		}
		.transparentAnchorTag:hover{
			color: #fff !important;
			background-color: #db2325 !important;
		}
		.transparentAnchorTag i{
			color: #000 !important;
		}
		.transparentAnchorTag i:hover{
			color: #fff !important;
		}
		.truncateTextToOneLine {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			display: inline-block;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			width: 100%;
		}
`;

export default GlobalStyles;
