import { Map } from 'immutable';

import {
	SELECT_CATEGORY, ADD_CATEGORY, CREATE_CATEGORY, CREATE_CATEGORY_SUCCESS, CREATE_CATEGORY_ERROR, UPDATE_CATEGORY, CATEGORY_REDIRECTION_ERROR, UPDATE_CATEGORY_SUCCESS, UPDATE_CATEGORY_ERROR, DELETE_CATEGORY, DELETE_CATEGORY_SUCCESS, DELETE_CATEGORY_ERROR, REPLACE_CATEGORY, REPLACE_CATEGORY_SUCCESS, REPLACE_CATEGORY_ERROR, CATEGORY_ELEMENT_ON_CHANGE, UNMOUNT_CATEGORY_FORM_MESSAGE, WRITE_CATEGORY_SLUG, SET_CATEGORY_SLUG_UNIQUE_VALUE, SHOW_CATEGORY_LOADING_MESSAGE,
} from './type';

import {
	CATEGORY_ERROR_MESSAGE, CATEGORY_CREATE_LOAD_MESSAGE, CATEGORY_CREATE_SUCCESS_MESSAGE, CATEGORY_UPDATE_LOAD_MESSAGE, CATEGORY_UPDATE_SUCCESS_MESSAGE, CATEGORY_DELETE_LOAD_MESSAGE, CATEGORY_DELETE_SUCCESS_MESSAGE, CATEGORY_REPLACE_LOAD_MESSAGE, CATEGORY_REPLACE_SUCCESS_MESSAGE, CATEGORY_LOADING_MESSAGE,
} from './constants';

const initialState = Map({
	selectedCategory: null,
	formData: {
		name: '',
		slug: '',
		description: '',
		image: '',
	},
	addCategory: false,
	editCategory: false,
	dropdownTitle: null,
	writeSlug: false,
	uniqueSlug: false,
	formMessage: null,
});

function categoryReducer(state = initialState, action) {
	switch (action.type) {
	case SELECT_CATEGORY: {
		const { selectedCategory } = action;
		if (!selectedCategory || Object.keys(selectedCategory).length < 1) {
			const form = {
				name: '',
				slug: '',
				description: '',
				image: '',
			};
			return state
				.set('addCategory', false)
				.set('editCategory', false)
				.set('selectedCategory', null)
				.set('writeSlug', false)
				.set('uniqueSlug', true)
				.set('formData', form)
				.set('dropdownTitle', null);
		}
		const form = {
			name: action.selectedCategory.name,
			slug: action.selectedCategory.slug,
			description: action.selectedCategory.description,
			image: action.selectedCategory.imagelocation,
		};
		return state
			.set('selectedCategory', action.selectedCategory)
			.set('formData', form)
			.set('editCategory', true)
			.set('addCategory', false)
			.set('writeSlug', false)
			.set('uniqueSlug', true)
			.set('formMessage', null)
			.set('dropdownTitle', action.selectedCategory);
	}
	case ADD_CATEGORY: {
		const form = {
			name: '',
			slug: '',
			description: '',
			image: '',
		};
		return state
			.set('addCategory', true)
			.set('editCategory', false)
			.set('selectedCategory', null)
			.set('dropdownTitle', null)
			.set('formData', form)
			.set('writeSlug', false)
			.set('uniqueSlug', false)
			.set('formMessage', null);
	}
	case DELETE_CATEGORY:
		return state
			.set('formMessage', CATEGORY_DELETE_LOAD_MESSAGE);
	case DELETE_CATEGORY_SUCCESS:
		return state
			.set('selectedCategory', null)
			.set('formMessage', CATEGORY_DELETE_SUCCESS_MESSAGE);
	case DELETE_CATEGORY_ERROR:
		return state
			.set('formMessage', CATEGORY_ERROR_MESSAGE);
	case REPLACE_CATEGORY:
		return state
			.set('formMessage', CATEGORY_REPLACE_LOAD_MESSAGE);
	case REPLACE_CATEGORY_SUCCESS:
		return state
			.set('selectedCategory', null)
			.set('formMessage', CATEGORY_REPLACE_SUCCESS_MESSAGE);
	case REPLACE_CATEGORY_ERROR:
		return state
			.set('formMessage', CATEGORY_ERROR_MESSAGE);
	case CATEGORY_REDIRECTION_ERROR:
		return state
			.set('formMessage', CATEGORY_REDIRECTION_ERROR);
	case CREATE_CATEGORY:
		return state
			.set('formMessage', CATEGORY_CREATE_LOAD_MESSAGE);
	case CREATE_CATEGORY_SUCCESS:
		return state
			.set('formMessage', CATEGORY_CREATE_SUCCESS_MESSAGE);
	case CREATE_CATEGORY_ERROR:
		return state
			.set('formMessage', CATEGORY_ERROR_MESSAGE);
	case UPDATE_CATEGORY:
		return state
			.set('formMessage', CATEGORY_UPDATE_LOAD_MESSAGE);
	case UPDATE_CATEGORY_SUCCESS:
		return state
			.set('selectedCategory', null)
			.set('formMessage', CATEGORY_UPDATE_SUCCESS_MESSAGE);
	case UPDATE_CATEGORY_ERROR:
		return state
			.set('formMessage', CATEGORY_ERROR_MESSAGE);
	case CATEGORY_ELEMENT_ON_CHANGE: {
		const newState = { ...state.get('formData') };

		newState[action.elementData.name] = action.elementData.value;
		if (action.elementData.name === 'name') {
			// eslint-disable-next-line
				newState.name = (action.elementData.value).replace(/[@#!$%^&*()_+|~=`{}[\]:";'<>?,.\/]/g, '');
			if (state.get('writeSlug') === false) {
				const slug = (action.elementData.value).replace(/ /g, '-').replace(/[^A-Za-z0-9-]/g, '').toLowerCase();
				newState.slug = slug;
			}
		}
		if (action.elementData.name === 'slug') {
			if (state.get('writeSlug') === true) {
				const slug = (action.elementData.value).replace(/ /g, '-').replace(/[^A-Za-z0-9-]/g, '');
				newState.slug = slug;
			}
		}
		return state
			.set('formData', newState);
	}
	case WRITE_CATEGORY_SLUG:
		return state
			.set('writeSlug', true);
	case UNMOUNT_CATEGORY_FORM_MESSAGE:
		return state
			.set('formMessage', null);
	case SET_CATEGORY_SLUG_UNIQUE_VALUE:
		return state
			.set('uniqueSlug', action.value);
	case SHOW_CATEGORY_LOADING_MESSAGE:
		return state
			.set('formMessage', CATEGORY_LOADING_MESSAGE);
	default:
		return state;
	}
}

export default categoryReducer;
