export const EDITOR_API_BASE_URL = '/api/v2/rw/stories';
export const AUTHOR_META_BASE_URL = '/api/v2/rw/authormeta';
export const STORY_METADATA_BASE_URL = '/api/v2/rw/storymetadata';
export const SEO_ANALYTICS_BASE_URL = '/api/v2/rw/seoanalysis';
export const MS_DOC_CONVERSION_API = '/api/v2/rw/stories/convert';
export const GOOGLE_DOC_CONVERSION_API = '/api/v2/rw/stories/convert/google';
export const EDITOR_VERSION = 142;

export const ACTION_OPTIONS = {
	INREVIEW: { label: 'SEND TO REVIEW', value: 'INREVIEW' },
	UNPUBLISH: { label: 'UNPUBLISH', value: 'INREVIEW' },
	REWORK: { label: 'SEND FOR REWORK', value: 'NEEDSEDIT' },
	REJECTED: { label: 'REJECT', value: 'REJECTED' },
	PUBLISHED: { label: 'PUBLISH', value: 'PUBLISHED' },
	SCHEDULED: { label: 'SCHEDULE', value: 'SCHEDULED' },
	UNSCHEDULE: { label: 'UNSCHEDULE', value: 'INREVIEW' },
	TRASHED: { label: 'TRASH', value: 'TRASHED' },
	REPUBLISH: { label: 'SEND TO REVIEW', value: 'REVIEW_FOR_PUBLISHED' },
	RESTORE: { label: 'RESTORE', value: 'DRAFTS' },
};
// For socket state
export const BLOCKED = 'BLOCKED';
export const ACCESS_NEEDED = 'ACCESS_NEEDED';
export const ACCESS_DENIED = 'ACCESS_DENIED';
export const ACCESS_GRANTED = 'ACCESS_GRANTED';
export const ACCESS_OVERRIDDEN = 'ACCESS_OVERRIDDEN';
export const BLOCKING_USER_DISCONNECTED = 'BLOCKING_USER_DISCONNECTED';
export const REQUESTING_USER_DISCONNECTED = 'REQUESTING_USER_DISCONNECTED';
export const INTERNET_DISCONNECTED = 'INTERNET_DISCONNECTED';
export const ERROR = 'ERROR';

export const EDITOR_VALIDATION_ERROR_MESSAGE = '<b>Validation Error: </b>Some fields are missing. Please ensure all fields have appropriate values';

export const STORY_UPDATING_MESSAGE = { intent: 'info', message: 'Updating story...' };
export const STORY_UPDATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Story updated successfully' };
export const STORY_UPDATE_FAILURE_MESSAGE = { intent: 'danger', message: 'Story update failed. Something went wrong. Try Again!' };

export const STORY_PUBLISH_SUCCESS_MESSAGE = { intent: 'success', message: 'Story published successfully' };
export const STORY_PUBLISH_FAILURE_MESSAGE = { intent: 'danger', message: 'Story publish failed. Something went wrong. Try Again!' };

export const WEBINAR_UPDATING_MESSAGE = { intent: 'info', message: 'Updating webinar...' };
export const WEBINAR_UPDATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Webinar updated successfully' };
export const WEBINAR_UPDATE_FAILURE_MESSAGE = { intent: 'danger', message: 'Webinar update failed. Something went wrong. Try Again!' };
export const WEBINAR_PUBLISH_SUCCESS_MESSAGE = { intent: 'success', message: 'Webinar published successfully' };
export const WEBINAR_PUBLISH_FAILURE_MESSAGE = { intent: 'danger', message: 'Webinar publish failed. Something went wrong. Try Again!' };

export const VIDEO_UPDATING_MESSAGE = { intent: 'info', message: 'Updating video story...' };
export const VIDEO_UPDATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Video story updated successfully' };
export const VIDEO_UPDATE_FAILURE_MESSAGE = { intent: 'danger', message: 'Video story update failed. Something went wrong. Try Again!' };
export const VIDEO_PUBLISH_SUCCESS_MESSAGE = { intent: 'success', message: 'Video story published successfully' };
export const VIDEO_PUBLISH_FAILURE_MESSAGE = { intent: 'danger', message: 'Video story publish failed. Something went wrong. Try Again!' };

export const COPY_SCAPE_FAILURE_MESSAGE = { intent: 'danger', message: 'Failed to run plagiarism check. Copyscape credit might be low!!!' };
// ENUMS LIST
export const STATUS = Object.freeze({
	ERROR: 'ERROR',
	SUCCESS: 'SUCCESS',
	FAIL: 'FAIL',
	UPLOADING: 'UPLOADING',
	PROCESSING: 'PROCESSING',
});

export const ARTICLE_TYPES = [
	{ value: 'Analysis', label: 'Analysis' },
	{ value: 'Announcement', label: 'Announcement' },
	{ value: 'Award', label: 'Award' },
	{ value: 'Expert Opinion', label: 'Expert Opinion' },
	{ value: 'Features', label: 'Features' },
	{ value: 'Funding News', label: 'Funding News' },
	{ value: 'In-Depth', label: 'In-Depth' },
	{ value: 'Inspiration', label: 'Inspiration' },
	{ value: 'Interview', label: 'Interview' },
	{ value: 'Jobs Roundup', label: 'Jobs Roundup' },
	{ value: 'Market Expansion', label: 'Market Expansion' },
	{ value: 'Merger and Acquisition', label: 'Merger and Acquisition' },
	{ value: 'News Reports', label: 'News Reports' },
	{ value: 'Obituary', label: 'Obituary' },
	{ value: 'Product Launch', label: 'Product Launch' },
	{ value: 'Product Review', label: 'Product Review' },
	{ value: 'Quotes', label: 'Quotes' },
	{ value: 'Research', label: 'Research' },
	{ value: 'Resources', label: 'Resources' },
	{ value: 'Startup', label: 'Startup' },
];

export const WEBINAR_API_BASE_URL = '/api/v2/rw/webinar';

export const UNIT_OPTIONS = [
	{ label: 'Thousand', value: 'thousand' },
	{ label: 'Million', value: 'million' },
	{ label: 'Billion', value: 'billion' },
	{ label: 'Trillion', value: 'trillion' },
];

export const CURRENCY_OPTIONS = [
	{ key: '&#36;', value: 'USD' },
	{ key: '&#8364;', value: 'EUR' },
	{ key: '&#163;', value: 'GBP' },
	{ key: '&#8377;', value: 'INR' },
	{ key: '&#165;', value: 'JPY' },
	{ key: '&#20803;', value: 'CNY' },
];

export const ARTICLE_WALL_TYPE_OPTIONS = [
	{ label: 'Login', value: 'login' },
	{ label: 'Subscription', value: 'subscription' },
	/* , { label: 'Micropayment', value: 'micropayment' } */
];

export const STORY_FORMAT_OPTIONS = [
	{ label: 'The Crux', value: 'the-crux' },
	{ label: 'Premium', value: 'premium' },
	{ label: 'Org Charts', value: 'org-charts' },
];

export const CATEGORY_SLUG_ARRAY = [
	'global-rd',
];

export const CONTENT_TYPES = [
	{ value: 'Editorial', label: 'Editorial' },
	{ value: 'Content', label: 'Content' },
	{ value: 'Others', label: 'Others' },
];

export const SCHEME_TYPES = [
	{ value: 'article story', label: 'Article Schema' },
	{ value: 'news story', label: 'News Schema' },
	{ value: 'how_to_story', label: 'How To Schema' },
	{ value: 'glossary_story', label: 'Glossary Schema' },
];
