export const FETCH_ALL_FEED_SOURCE = 'FETCH_ALL_FEED_SOURCE';
export const FETCH_ALL_FEED_SOURCE_SUCCESS = 'FETCH_ALL_FEED_SOURCE_SUCCESS';
export const FETCH_ALL_FEED_SOURCE_ERROR = 'FETCH_ALL_FEED_SOURCE_ERROR';

export const FETCH_FEED_CONTENTS_BY_PAGE = 'FETCH_FEED_CONTENTS_BY_PAGE';
export const FETCH_FEED_CONTENTS_BY_PAGE_SUCCESS = 'FETCH_FEED_CONTENTS_BY_PAGE_SUCCESS';
export const FETCH_FEED_CONTENTS_BY_PAGE_ERROR = 'FETCH_FEED_CONTENTS_BY_PAGE_ERROR';

export const FILTER_WATCHLIST_STORIES = 'FILTER_WATCHLIST_STORIES';
export const FILTER_WATCHLIST_STORIES_SUCCESS = 'FILTER_WATCHLIST_STORIES_SUCCESS';
export const FILTER_WATCHLIST_STORIES_ERROR = 'FILTER_WATCHLIST_STORIES_ERROR';

export const REMOVE_WATCHLIST_STORIES_FILTER = 'REMOVE_WATCHLIST_STORIES_FILTER';
export const SET_WATCHLIST_FILTER_TYPE = 'SET_WATCHLIST_FILTER_TYPE';
export const SET_WATCHLIST_FILTER_VALUE = 'SET_WATCHLIST_FILTER_VALUE';

export const SET_RSS_FEED_CONTENT_PAGE = 'SET_RSS_FEED_CONTENT_PAGE';
export const ON_FEED_SOURCE_SELECT = 'ON_FEED_SOURCE_SELECT';
