/*
 *
 * NotificationsManager reducer
 *
 */

import { Map } from 'immutable';

import { notification } from './constants';

import {
	SELECT_TOPIC, SEND_NOTIFICATION, SEND_NOTIFICATION_SUCCESS, SEND_NOTIFICATION_ERROR, CHANGE_NOTIFICATION_PAYLOAD, UPDATE_DISPLAY_MESSAGE,
} from './type';

const initialState = Map({
	loading: false,
	error: false,
	notification,
	displayMessage: null,
});

function notificationsReducer(state = initialState, action) {
	switch (action.type) {
	case SELECT_TOPIC:
		return state
			.setIn(['notification', 'topic'], action.topic)
			.setIn(['notification', 'icon'], action.icon);
	case SEND_NOTIFICATION:
		return state
			.set('loading', true);
	case CHANGE_NOTIFICATION_PAYLOAD:
		return state
			.setIn(['notification', action.itemName], action.itemValue);
	case SEND_NOTIFICATION_SUCCESS:
		return state
			.set('loading', false)
			.set('error', false)
			.set('notification', notification)
			.set('displayMessage', action.message);
	case SEND_NOTIFICATION_ERROR:
		return state
			.set('loading', false)
			.set('error', action.error)
			.set('displayMessage', action.message);
	case UPDATE_DISPLAY_MESSAGE:
		return state
			.set('displayMessage', action.message);
	default:
		return state;
	}
}

export default notificationsReducer;
