export const LOAD_ALL_BRANDS = 'LOAD_ALL_BRANDS';
export const LOAD_ALL_BRANDS_ERROR = 'LOAD_ALL_BRANDS_ERROR';
export const LOAD_ALL_BRANDS_SUCCESS = 'LOAD_ALL_BRANDS_SUCCESS';

export const SELECT_BRAND = 'SELECT_BRAND';
export const ADD_BRAND = 'ADD_BRAND';
export const WRITE_BRAND_SLUG = 'WRITE_BRAND_SLUG';
export const BRAND_ELEMENT_ON_CHANGE = 'BRAND_ELEMENT_ON_CHANGE';

export const CREATE_BRAND = 'CREATE_BRAND';
export const CREATE_BRAND_SUCCESS = 'CREATE_BRAND_SUCCESS';
export const CREATE_BRAND_ERROR = 'CREATE_BRAND_ERROR';

export const UPDATE_BRAND = 'UPDATE_BRAND';
export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_ERROR = 'UPDATE_BRAND_ERROR';

export const DELETE_BRAND = 'DELETE BRAND';
export const DELETE_BRAND_SUCCESS = 'DELETE_BRAND_SUCCESS';
export const DELETE_BRAND_ERROR = 'DELETE_BRAND_ERROR';

export const UNMOUNT_BRAND_FORM_MESSAGE = 'UNMOUNT_BRAND_FORM_MESSAGE';
