import {
	ADD_NEW_PERSON,
	PERSON_ELEMENT_ON_CHANGE,
	CREATE_PERSON,
	CREATE_PERSON_ERROR,
	CREATE_PERSON_SUCCESS,
	UPDATE_PERSON,
	UPDATE_PERSON_ERROR,
	UPDATE_PERSON_SUCCESS,
	DELETE_PERSON,
	DELETE_PERSON_ERROR,
	DELETE_PERSON_SUCCESS,
	SELECT_PERSON,
	UNMOUNT_PEOPLE_FORM_MESSAGE,
	ADD_PERSON_AWARD,
	REMOVE_PERSON_AWARD,
	CLEAR_INFLUENCER_MANAGER_DATA,
	FETCH_PERSON_BY_ID,
} from './type';
import history from '../../utils/history';

export function addNewPerson() {
	history.push('/manager/company/influencers/');
	return {
		type: ADD_NEW_PERSON,
	};
}

export function formElementOnChange(elementData, index) {
	return {
		type: PERSON_ELEMENT_ON_CHANGE,
		elementData,
		index,
	};
}

export function createPerson(person) {
	return {
		type: CREATE_PERSON,
		person,
	};
}
export function createPersonSuccess(successResponse) {
	return {
		type: CREATE_PERSON_SUCCESS,
		successResponse,
	};
}

export function createPersonFailure(failureResponse) {
	return {
		type: CREATE_PERSON_ERROR,
		failureResponse,
	};
}

export function updatePerson(id, updatedData) {
	return {
		type: UPDATE_PERSON,
		id,
		updatedData,
	};
}

export function updatePersonSuccess(successResponse) {
	return {
		type: UPDATE_PERSON_SUCCESS,
		successResponse,
	};
}

export function updatePersonFailure(failureResponse) {
	return {
		type: UPDATE_PERSON_ERROR,
		failureResponse,
	};
}

export function deletePerson(id) {
	return {
		type: DELETE_PERSON,
		id,
	};
}

export function deletePersonFailure(failureResponse) {
	return {
		type: DELETE_PERSON_ERROR,
		failureResponse,
	};
}

export function deletePersonSuccess(successResponse) {
	return {
		type: DELETE_PERSON_SUCCESS,
		successResponse,
	};
}

export function personSelect(selectedPerson) {
	if (selectedPerson && selectedPerson.id) history.push(`/manager/company/influencers/${selectedPerson.id}`);
	else history.push('/manager/company/influencers/');
	return {
		type: SELECT_PERSON,
		selectedPerson,
	};
}

export function unmountPeopleFormMessage() {
	return {
		type: UNMOUNT_PEOPLE_FORM_MESSAGE,
	};
}

export function addPersonAward() {
	return {
		type: ADD_PERSON_AWARD,
	};
}

export function removePersonAward(index) {
	return {
		type: REMOVE_PERSON_AWARD,
		index,
	};
}

export function setInitialState() {
	return {
		type: CLEAR_INFLUENCER_MANAGER_DATA,
	};
}

export function fetchPersonById(id) {
	return {
		type: FETCH_PERSON_BY_ID,
		id,
	};
}
