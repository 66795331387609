import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { GET_LATEST_ACTIVITY_STREAM, GET_ALL_ACTIVITY_STREAM, LOAD_ALL_BRANDS } from './type';
import {
	getLatestActivityStreamError, getLatestActivityStreamSuccess, getAllActivityStreamError, getAllActivityStreamSuccess, brandsLoadedSuccessfully, brandsLoadingError,
} from './actions';
import { ACTIVITY_STREAM_API_BASE_URL, BRAND_API_BASE_URL } from './constants';

export function* getLatestActivityStream() {
	const url = `${ACTIVITY_STREAM_API_BASE_URL}/`;
	const options = {
		method: 'GET',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
		},
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const result = yield response.json();
			yield put(getLatestActivityStreamSuccess(result));
		}
	} catch (err) {
		yield put(getLatestActivityStreamError(err));
	}
}

export function* getAllActivityStream(actionObject) {
	const {
		offset, brand, start, end, clearStream,
	} = actionObject;
	const url = `${ACTIVITY_STREAM_API_BASE_URL}/?offset=${offset}${brand ? `&brand=${brand}` : ''}${start ? `&start=${start}` : ''}${end ? `&end=${end}` : ''}`;
	const options = {
		method: 'GET',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
		},
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const result = yield response.json();
			yield put(getAllActivityStreamSuccess(result, clearStream));
		}
	} catch (err) {
		yield put(getAllActivityStreamError(err));
	}
}

export function* getAllBrands() {
	const url = `${BRAND_API_BASE_URL}/`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const allBrands = yield response.json();
			yield put(brandsLoadedSuccessfully(allBrands));
		}
	} catch (err) {
		yield put(brandsLoadingError(err));
	}
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* activityStreamData() {
	yield takeLatest(GET_ALL_ACTIVITY_STREAM, getAllActivityStream);
	yield takeLatest(GET_LATEST_ACTIVITY_STREAM, getLatestActivityStream);
	yield takeLatest(LOAD_ALL_BRANDS, getAllBrands);
}
