// SETTER
export const SET_LOADING_STATE = 'SET_LOADING_STATE';
export const SET_ERROR_STATE = 'SET_ERROR_STATE';
export const SET_TAB_STATE = 'SET_TAB_STATE';
export const SET_API_STATE = 'SET_API_STATE';
export const SET_SEARCH_STORIES = 'SET_SEARCH_STORIES';
export const SET_SAVE_STATE = 'SET_SAVE_STATE';
export const SET_FILE_STATE = 'SET_FILE_STATE';
export const SET_PAGE_MODE = 'SET_PAGE_MODE';
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';

// SETTER TAB DATA
export const SET_TAB_DATA_STATE = 'SET_TAB_DATA_STATE';

// GETTER
export const GET_API_STATE = 'GET_API_STATE';
export const GET_SEARCH_STORIES = 'GET_SEARCH_STORIES';
export const GET_SERIES_DATA_BY_SLUG = 'GET_SERIES_DATA_BY_SLUG';
