import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	FETCH_JOBS,
	FILTER_JOBS,
} from './type';
import { JOBS_BASE_URL } from './constants';
import {
	fetchJobsSuccess,
	fetchJobsFailure,
	filterJobsSuccess,
	filterJobsFailure,
} from './actions';

export function* loadJobs(action) {
	const url = `${JOBS_BASE_URL}/offset/${action.offset}/limit/${action.limit}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const jobs = yield response.json();
			yield put(fetchJobsSuccess(jobs));
		}
	} catch (error) {
		yield put(fetchJobsFailure(error));
	}
}

export function* loadFilteredJobs(action) {
	let { key } = action;
	const { status, page } = action;
	if (key) {
		key = key.trim();
		if (key.length < 1) {
			return;
		}
	}
	const searchKey = key || null;
	const url = `${JOBS_BASE_URL}/key/${searchKey}`;
	const formData = new FormData();
	formData.append('page', page);
	formData.append('status', status);
	const options = {
		method: 'POST',
		body: formData,
		credentials: 'include',
	};
	try {
		const response = yield fetch(url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const jobs = yield response.json();
			yield put(filterJobsSuccess(jobs));
		}
	} catch (err) {
		yield put(filterJobsFailure(err));
	}
}

export default function* jobsListSaga() {
	yield takeLatest(FETCH_JOBS, loadJobs);
	yield takeLatest(FILTER_JOBS, loadFilteredJobs);
}
