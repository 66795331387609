import styled from 'styled-components';
import { Row } from 'react-styled-flexboxgrid';

const HeaderCenteredDiv = styled.div`
		width: 100%;
		@media (min-width: 992px) {
				width: 65%;
				margin: 0 auto;
		}
`;

const PlagiarismValue = styled.span`
		border-radius: 25px;
		color: #fff;
		background-color: ${(props) => (props.color === 'green' ? 'green' : 'red')};
		padding: 0.20rem 0.5rem;
`;

const PlaceholderShadowedPanel = styled.div`
		padding: 1rem;
		margin: 1rem 0;
		box-shadow: 1px 2px 3px 0px #DDDDDD;
		display: flex;
		flex-direction: column;
		height: 70vh;
		align-items: center;
		justify-content: center;
		position: relative;
`;

const VideoInputWrapper = styled(Row)`
		width: 100%;
		background-image: linear-gradient(to left, #cccccc, #d2d2d2, #d8d8d8, #dfdfdf, #e5e5e5, #e9e9e9, #eeeeee, #f2f2f2, #f5f5f5, #f8f8f8, #fcfcfc, #ffffff);
		align-items: center;
		margin: 1rem 0;
		padding: 3rem 1rem;
		height: 300px;
		@media(max-width: 769px) {
				justify-content: center;
				#player-icon {
						display: none;
				}
		}
`;

const VideoSelectButton = styled.button`
		height: 50px;
		border: 2px solid #3c3c3c;
		background: #fff;
		border-radius: 0.25rem;
		:hover {
				background-color: #fff;
		}
		 @media(max-width: 769px) {
				height: auto;
				padding: 0.25rem 0.5rem;
		}
`;

const VideoContentWrapper = styled.div`
		display: flex;
		form {
				width: 50%;
		}
		.or {
				margin: 0.5rem
				font-size: 1.5rem;
				text-align: center;
				width: 60px;
		}
		
		@media(max-width: 769px) {
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-top: 1rem;
				form {
						width: 100%;
				}
				.or {
						font-size: 1rem;
						text-align: center;
				}
		}
`;

const YoutubeUrlInput = styled.input`
		padding: 0.25rem 0.5rem;
		background: #fff;
		font-size: 1.2rem;
		border: none;
		width: 80%;
		border: 2px solid #3c3c3c;
		border-right: none;
		border-radius: 0.25rem 0 0 0.25rem;
		&:focus {
				border: 2px solid #aaa !important;
				background: #fff;
		}
		@media(max-width: 769px) {
				margin: 1rem 0;
				width: 80%;
				font-size: 1rem;
		}
`;

const YoutubeAddButton = styled.button`
		font-size: 1.5rem;
		padding: 0.25rem 0.5rem;
		border: 2px solid #3c3c3c;
		background: #3c3c3c !important;
		color: #fff;
		border-radius: 0 0.25rem 0.25rem 0;
		@media(max-width: 769px) {
				margin: 1rem 0;
				font-size: 1rem;
		}
`;

const YoutubeIframeWrapper = styled.div`
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		max-width: 100%;

		iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
		}
`;

const CoverImageInputWrapper = styled.div`
		width: 100%;
		box-shadow: 1px 2px 3px 0px #DDDDDD;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #dcebf2;
		opacity: 0.7;
		:hover {
				opacity: 1;
		}
		button {
				width: 100%;
		}
		span {
				font-size: 1.5rem;
				color: #314e55;
		}
`;

const CoverImageWrapper = styled.div`
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		opacity: 0.8;
		background-size: cover;
		// background-image: url('${(props) => props.url || ''}');
		// background-attachment: ${(props) => (props.banner ? 'fixed' : '')};
		opacity: 1;
		cursor: pointer;
		:hover {
				opacity: 0.8;
		}
		.close-btn {
				position: absolute;
				right: 0;
				top: 0;
				margin: 10px;
				background: transparent;
		}
`;

export {
	HeaderCenteredDiv, PlagiarismValue, PlaceholderShadowedPanel, VideoInputWrapper, VideoSelectButton, YoutubeUrlInput, VideoContentWrapper, YoutubeAddButton, YoutubeIframeWrapper, CoverImageInputWrapper, CoverImageWrapper,
};
