import React from 'react';
import styled from 'styled-components';
import T from 'prop-types';
import { ImageHolder } from './index';

const Card = styled.div`
		position: relative;
		width: 400px;
		box-shadow: 1px 2px 3px 0px #DDDDDD;
		border-radius: ${(props) => (props.upperRounded ? '24px 24px 0px 0px' : '0px')}
		word-wrap: break-word;
`;

const HrLine = styled.hr`
		margin: 0;
		width: 100%;
		background-color: #efefef;
`;

const Bottom = styled.div`
		height: 1.8rem;
		width: 100%;
		margin: auto;
`;

const Link = styled.a`
		color: grey;
		font-size: 0.9rem;
`;

const RightButton = styled.button`
		color: grey;
		float: right;
		font-size: 0.9rem;
`;

const SocialPreviewCard = (props) => {
	const {
		upperRounded, showImageUploader, imageUrl, type, onEdit, storyUrl, handleBlur,
	} = props;

	function getSharePreviewLink() {
		switch (type.toLowerCase()) {
		case 'facebook':
			return `https://developers.facebook.com/tools/debug/sharing/?q=${storyUrl}`;
		case 'twitter':
			return 'https://cards-dev.twitter.com/validator';
		default:
			return `https://search.google.com/structured-data/testing-tool#url=${storyUrl}`;
		}
	}

	return <Card upperRounded={upperRounded} onBlur={handleBlur}>
		<ImageHolder
			showImageUploader={showImageUploader}
			upperRounded={upperRounded}
			imageUrl={imageUrl}
		/>
		{props.children}
		<HrLine />
		<Bottom>
			<Link
				className="p-2"
				href={getSharePreviewLink()}
				target="_blank"
				rel="noopener noreferrer"
			>
				{`Check on ${type}`}
			</Link>
			<RightButton onClick={onEdit}>Click to edit</RightButton>
		</Bottom>
	</Card>;
};

SocialPreviewCard.propTypes = {
	upperRounded: T.bool,
	showImageUploader: T.func,
	imageUrl: T.string,
	type: T.string,
	onEdit: T.func,
	storyUrl: T.string,
	handleBlur: T.func,
	children: T.object,
};
export default SocialPreviewCard;
