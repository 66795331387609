import styled from 'styled-components';

const SuccessMessageButton = styled.button`
		color: #4F8A10;
		background-color: #DFF2BF;
		padding: 8px;
		border-radius: 5px;
		width: 100%;
		margin-left: 5px;
		margin-right: 5px;
`;

const SuccessMessage = styled.span`
		color: #4F8A10;
		background-color: #DFF2BF;
		padding: 8px;
		border-radius: 5px;		
		margin-left: 5px;
		margin-right: 5px;
`;

const FailureMessage = styled.span`
		color: #D8000C;
		background-color: #FFD2D2;		
		padding: 8px;
		border-radius: 5px;
		margin-left: 5px;
		margin-right: 5px;
`;

const InfoMessage = styled.span`
		color: #00529B;
		background-color: #BDE5F8;		
		padding: 8px;
		border-radius: 5px;
		margin-left: 5px;
		margin-right: 5px;
`;

const WarningMessage = styled.span`
		color: #9F6000;
		background-color: #FEEFB3;		
		padding: 8px;
		border-radius: 5px;
		width: 100%;
		margin-left: 5px;
		margin-right: 5px;
`;

export {
	SuccessMessage, FailureMessage, SuccessMessageButton, InfoMessage, WarningMessage,
};
