import {
	ADD_NEW_VERTICAL, CREATE_VERTICAL, VERTICAL_ELEMENT_ON_CHANGE, CREATE_VERTICAL_ERROR, CREATE_VERTICAL_SUCCESS, UPDATE_VERTICAL, UPDATE_VERTICAL_ERROR, UPDATE_VERTICAL_SUCCESS, DELETE_VERTICAL, DELETE_VERTICAL_ERROR, DELETE_VERTICAL_SUCCESS, SELECT_VERTICAL, UNMOUNT_VERTICAL_FORM_MESSAGE, SET_VERTICAL_SLUG_UNIQUE_VALUE, CLEAR_VERTICAL_MANAGER_DATA, REPLACE_VERTICAL, REPLACE_VERTICAL_ERROR, REPLACE_VERTICAL_SUCCESS,
} from './type';

export function addNewVertical() {
	return {
		type: ADD_NEW_VERTICAL,
	};
}

export function onVerticalFormElementChange(elementData) {
	return {
		type: VERTICAL_ELEMENT_ON_CHANGE,
		elementData,
	};
}

export function createVertical(vertical) {
	return {
		type: CREATE_VERTICAL,
		vertical,
	};
}

export function createVerticalSuccess(successResponse) {
	return {
		type: CREATE_VERTICAL_SUCCESS,
		successResponse,
	};
}

export function createVerticalFailure(failureResponse) {
	return {
		type: CREATE_VERTICAL_ERROR,
		failureResponse,
	};
}

export function updateVertical(id, updatedData) {
	return {
		type: UPDATE_VERTICAL,
		id,
		updatedData,
	};
}

export function updateVerticalSuccess(successResponse) {
	return {
		type: UPDATE_VERTICAL_SUCCESS,
		successResponse,
	};
}

export function updateVerticalFailure(failureResponse) {
	return {
		type: UPDATE_VERTICAL_ERROR,
		failureResponse,
	};
}

export function deleteVertical(id) {
	return {
		type: DELETE_VERTICAL,
		id,
	};
}

export function deleteVerticalFailure(failureResponse) {
	return {
		type: DELETE_VERTICAL_ERROR,
		failureResponse,
	};
}

export function deleteVerticalSuccess(successResponse) {
	return {
		type: DELETE_VERTICAL_SUCCESS,
		successResponse,
	};
}

export function verticalSelect(selectedVertical) {
	return {
		type: SELECT_VERTICAL,
		selectedVertical,
	};
}

export function unmountVerticalFormMessage() {
	return {
		type: UNMOUNT_VERTICAL_FORM_MESSAGE,
	};
}

export function setVerticalSlugUniqueValue(value) {
	return {
		type: SET_VERTICAL_SLUG_UNIQUE_VALUE,
		value,
	};
}

export function setInitialState() {
	return {
		type: CLEAR_VERTICAL_MANAGER_DATA,
	};
}

export function replaceVertical(hoc, existing, replacement) {
	return {
		type: REPLACE_VERTICAL,
		hoc,
		existing,
		replacement,
	};
}

export function replaceVerticalSuccess() {
	return {
		type: REPLACE_VERTICAL_SUCCESS,
	};
}

export function replaceVerticalError() {
	return {
		type: REPLACE_VERTICAL_ERROR,
	};
}
