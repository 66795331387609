import React from 'react';
import T from 'prop-types';
import { Row } from '../Responsive';

const LoadingIcon = (props) => (
	<Row
		style={{
			width: '100%',
			minHeight: props.minHeight ? props.minHeight : '200px',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
		}}
	>
		<div>
			<i className="fa fa-spinner fa-spin" style={{ fontSize: '24px', color: '#e5002d' }} />
		</div>
	</Row>
);

LoadingIcon.propTypes = {
	minHeight: T.number,
};

export default LoadingIcon;
