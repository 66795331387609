export const LOAD_BRAND = 'LOAD_BRAND';
export const LOAD_BRAND_ERROR = 'LOAD_BRAND_ERROR';
export const LOAD_BRAND_SUCCESS = 'LOAD_BRAND_SUCCESS';

export const CREATE_ALIAS = 'CREATE_ALIAS';
export const CREATE_ALIAS_SUCCESS = 'CREATE_ALIAS_SUCCESS';
export const CREATE_ALIAS_ERROR = 'CREATE_ALIAS_ERROR';

export const UPDATE_FORM = 'UPDATE_FORM';
export const UPDATE_LOGO = 'UPDATE_LOGO';
export const DISCARD_FORM = 'DISCARD_FORM';
export const UNMOUNT_ALIAS_FORM_MESSAGE = 'UNMOUNT_ALIAS_FORM_MESSAGE';
