export const SELECT_CITY = 'SELECT_CITY';

export const ADD_CITY = 'ADD_CITY';
export const CITY_ELEMENT_ON_CHANGE = 'CITY_ELEMENT_ON_CHANGE';

export const ADD_NEW_CITY = 'ADD_NEW_CITY';

export const CREATE_CITY = 'CREATE_CITY';
export const CREATE_CITY_SUCCESS = 'CREATE_CITY_SUCCESS';
export const CREATE_CITY_ERROR = 'CREATE_CITY_ERROR';

export const UPDATE_CITY = 'UPDATE_CITY';
export const UPDATE_CITY_SUCCESS = 'UPDATE_CITY_SUCCESS';
export const UPDATE_CITY_ERROR = 'UPDATE_CITY_ERROR';

export const DELETE_CITY = 'DELETE_CITY';
export const DELETE_CITY_SUCCESS = 'DELETE_CITY_SUCCESS';
export const DELETE_CITY_ERROR = 'DELETE_CITY_ERROR';

export const UNMOUNT_CITY_FORM_MESSAGE = 'UNMOUNT_CITY_FORM_MESSAGE';
export const SET_CITY_SLUG_UNIQUE_VALUE = 'SET_CITY_SLUG_UNIQUE_VALUE';

export const CLEAR_CITY_MANAGER_DATA = 'CLEAR_CITY_MANAGER_DATA';
