export const APPROVED_ACTIVE_BASE_URL = '/api/v2/rw/talent';

export const FILTER_APPROVED_ACTIVE = 'FILTER_APPROVED_ACTIVE';
export const FETCH_APPROVED_ACTIVE_SUCCESS = 'FETCH_APPROVED_ACTIVE_SUCCESS';
export const FETCH_APPROVED_ACTIVE_FAILURE = 'FETCH_APPROVED_ACTIVE_FAILURE';
export const FETCH_APPROVED_ACTIVE = 'FETCH_APPROVED_ACTIVE';
export const FILTER_APPROVED_ACTIVE_SUCCESS = 'FILTER_APPROVED_ACTIVE_SUCCESS';
export const FILTER_APPROVED_ACTIVE_FAILURE = 'FILTER_APPROVED_ACTIVE_FAILURE';
export const SET_APPROVED_ACTIVE_PAGE = 'SET_APPROVED_ACTIVE_PAGE';
export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
export const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
export const UPDATE_ACTIONS_ERROR = 'UPDATE_ACTIONS_ERROR';
export const UPDATE_ACTIONS_SUCCESS = 'UPDATE_ACTIONS_SUCCESS';
export const UPDATE_ACTIONS_LOADER = 'UPDATE_ACTIONS_LOADER';
export const SET_ACTIONS_MESSAGE = 'SET_ACTIONS_MESSAGE';
