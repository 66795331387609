import { Map } from 'immutable';

import {
	SELECT_TAG, ADD_TAG, CREATE_TAG, CREATE_TAG_SUCCESS, CREATE_TAG_ERROR, UPDATE_TAG, UPDATE_TAG_SUCCESS, UPDATE_TAG_ERROR, DELETE_TAG, DELETE_TAG_SUCCESS, DELETE_TAG_ERROR, REPLACE_TAG, REPLACE_TAG_SUCCESS, REPLACE_TAG_ERROR, TAG_REDIRECTION_ERROR, TAG_ELEMENT_ON_CHANGE, WRITE_TAG_SLUG, UNMOUNT_TAG_FORM_MESSAGE, SET_TAG_SLUG_UNIQUE_VALUE, SHOW_TAG_LOADING_MESSAGE,
} from './type';

import {
	TAG_CREATE_LOAD_MESSAGE, TAG_CREATE_SUCCESS_MESSAGE, TAG_UPDATE_LOAD_MESSAGE, TAG_UPDATE_SUCCESS_MESSAGE, TAG_DELETE_SUCCESS_MESSAGE, TAG_DELETE_LOAD_MESSAGE, TAG_ERROR_MESSAGE, TAG_REPLACE_SUCCESS_MESSAGE, TAG_REPLACE_LOAD_MESSAGE, TAG_LOADING_MESSAGE,
} from './constants';

const initialState = Map({
	selectedTag: null,
	formData: {
		name: '',
		slug: '',
		description: '',
		image: '',
	},
	addTag: false,
	editTag: false,
	dropdownTitle: null,
	writeSlug: false,
	uniqueSlug: false,
	formMessage: null,
});

function tagReducer(state = initialState, action) {
	switch (action.type) {
	case SELECT_TAG: {
		const { selectedTag } = action;
		if (!selectedTag || Object.keys(selectedTag).length < 1) {
			const form = {
				name: '',
				slug: '',
				description: '',
				image: '',
			};
			return state
				.set('addTag', false)
				.set('editTag', false)
				.set('selectedTag', null)
				.set('writeSlug', false)
				.set('uniqueSlug', true)
				.set('formData', form)
				.set('dropdownTitle', null);
		}
		const form = {
			name: action.selectedTag.name,
			slug: action.selectedTag.slug,
			description: action.selectedTag.description,
			image: action.selectedTag.imagelocation,
		};
		return state
			.set('selectedTag', action.selectedTag)
			.set('formData', form)
			.set('editTag', true)
			.set('addTag', false)
			.set('writeSlug', false)
			.set('uniqueSlug', true)
			.set('formMessage', null)
			.set('dropdownTitle', action.selectedTag);
	}
	case ADD_TAG: {
		const form = {
			name: '',
			slug: '',
			description: '',
			image: '',
		};
		return state
			.set('addTag', true)
			.set('editTag', false)
			.set('selectedTag', null)
			.set('dropdownTitle', null)
			.set('formData', form)
			.set('writeSlug', false)
			.set('uniqueSlug', false)
			.set('formMessage', null);
	}
	case CREATE_TAG:
		return state
			.set('formMessage', TAG_CREATE_LOAD_MESSAGE);
	case CREATE_TAG_SUCCESS:
		return state
			.set('selectedTag', null)
			.set('formMessage', TAG_CREATE_SUCCESS_MESSAGE);
	case CREATE_TAG_ERROR:
		return state
			.set('formMessage', TAG_ERROR_MESSAGE);
	case UPDATE_TAG:
		return state
			.set('formMessage', TAG_UPDATE_LOAD_MESSAGE);
	case UPDATE_TAG_SUCCESS:
		return state
			.set('selectedTag', null)
			.set('formMessage', TAG_UPDATE_SUCCESS_MESSAGE);
	case UPDATE_TAG_ERROR:
		return state
			.set('formMessage', TAG_ERROR_MESSAGE);
	case DELETE_TAG:
		return state
			.set('formMessage', TAG_DELETE_LOAD_MESSAGE);
	case DELETE_TAG_SUCCESS:
		return state
			.set('selectedTag', null)
			.set('formMessage', TAG_DELETE_SUCCESS_MESSAGE);
	case DELETE_TAG_ERROR:
		return state
			.set('formMessage', TAG_ERROR_MESSAGE);
	case REPLACE_TAG:
		return state
			.set('formMessage', TAG_REPLACE_LOAD_MESSAGE);
	case REPLACE_TAG_SUCCESS:
		return state
			.set('selectedTag', null)
			.set('formMessage', TAG_REPLACE_SUCCESS_MESSAGE);
	case REPLACE_TAG_ERROR:
		return state
			.set('formMessage', TAG_ERROR_MESSAGE);
	case TAG_REDIRECTION_ERROR:
		return state
			.set('formMessage', TAG_REDIRECTION_ERROR);
	case TAG_ELEMENT_ON_CHANGE: {
		const newState = { ...state.get('formData') };
		newState[action.elementData.name] = action.elementData.value;
		if (action.elementData.name === 'name') {
			// eslint-disable-next-line
				newState.name = (action.elementData.value).replace(/[@!$%^&*()_+|~=`{}[\]:";'<>?,.\/]/g, '').replace(/#/g, (char, i) => !i++ ? char : '');
			if (state.get('writeSlug') === false) {
				const slug = (action.elementData.value).replace(/ /g, '-').replace(/[^A-Za-z0-9-]/g, '').toLowerCase();
				newState.slug = slug;
			}
		}
		if (action.elementData.name === 'slug') {
			if (state.get('writeSlug') === true) {
				const slug = (action.elementData.value).replace(/ /g, '-').replace(/[^A-Za-z0-9-]/g, '');
				newState.slug = slug;
			}
		}
		return state
			.set('formData', newState);
	}
	case WRITE_TAG_SLUG:
		return state
			.set('writeSlug', true);
	case UNMOUNT_TAG_FORM_MESSAGE:
		return state
			.set('formMessage', null);
	case SET_TAG_SLUG_UNIQUE_VALUE:
		return state
			.set('uniqueSlug', action.value);
	case SHOW_TAG_LOADING_MESSAGE:
		return state
			.set('formMessage', TAG_LOADING_MESSAGE);
	default:
		return state;
	}
}

export default tagReducer;
