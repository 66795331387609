import {
	ON_COMPANY_TAB_SELECT,
	UPDATE_COMPANY_FORM_DATA,
	UPDATE_COMPANY_METADATA_FORM_DATA,
	UPDATE_BASIC_INFO_INPUT_BLUR,
	UPDATE_BASIC_INFO_ERRORS,
	SET_ALL_BASIC_INFO_ELEMENTS_BLUR,
	CREATE_COMPANY,
	CREATE_COMPANY_SUCCESS,
	CREATE_COMPANY_FAILURE,
	UPDATE_BASIC_INFO,
	UPDATE_BASIC_INFO_SUCCESS,
	UPDATE_BASIC_INFO_FAILURE,
	UPDATE_FORM_MESSAGE,
	TRASH_COMPANY,
	TRASH_COMPANY_SUCCESS,
	TRASH_COMPANY_FAILURE,
	ON_BASIC_INFO_HEADER_TAB_SELECT,
	FETCH_COMPANY_EDITOR_DATA,
	FETCH_BASIC_INFO,
	FETCH_BASIC_INFO_SUCCESS,
	FETCH_BASIC_INFO_FAILURE,
	FETCH_ACQUISITIONS,
	FETCH_ACQUISITIONS_SUCCESS,
	FETCH_ACQUISITIONS_FAILURE,
	FETCH_COMPANY_FINANCE,
	FETCH_COMPANY_FINANCE_SUCCESS,
	FETCH_COMPANY_FINANCE_FAILURE,
	FETCH_COMPANY_PEOPLE,
	FETCH_COMPANY_PEOPLE_SUCCESS,
	FETCH_COMPANY_PEOPLE_FAILURE,
	FETCH_FUNDING,
	FETCH_FUNDING_SUCCESS,
	FETCH_FUNDING_FAILURE,
	FETCH_COMPANY_LOCATIONS,
	FETCH_COMPANY_LOCATIONS_SUCCESS,
	FETCH_COMPANY_LOCATIONS_FAILURE,
	FETCH_COMPANY_PRODUCTS,
	FETCH_COMPANY_PRODUCTS_SUCCESS,
	FETCH_COMPANY_PRODUCTS_FAILURE,
	CLEAR_COMPANY_EDITOR_DATA,
	ADD_COMPANY_AWARD,
	REMOVE_COMPANY_AWARD,
	FETCH_AUDIENCE,
	FETCH_AUDIENCE_SUCCESS,
	FETCH_AUDIENCE_FAILURE,
	FETCH_MILESTONES,
	FETCH_MILESTONES_SUCCESS,
	FETCH_MILESTONES_FAILURE,
	FETCH_CURATED_LIST_TYPES_SUCCESS,
	FETCH_CURATED_LIST_TYPES,
	FETCH_CURATED_LIST_TYPES_ERROR,
	FETCH_PROFILE_UPDATE_NOTES,
	FETCH_PROFILE_UPDATE_NOTES_SUCCESS,
	FETCH_PROFILE_UPDATE_NOTES_FAILURE,
	SET_TOP_PICKS,
	CLEAR_TOP_PICKS,
} from './type';

export function onTabSelect(tab) {
	return {
		type: ON_COMPANY_TAB_SELECT,
		tab,
	};
}

export function onBasicInfoHeaderTabSelect(tab) {
	return {
		type: ON_BASIC_INFO_HEADER_TAB_SELECT,
		tab,
	};
}

export function updateCompanyFormData(changedData) {
	return {
		type: UPDATE_COMPANY_FORM_DATA,
		changedData,
	};
}

export function updateCompanyMetadataFormData(changedData, index) {
	return {
		type: UPDATE_COMPANY_METADATA_FORM_DATA,
		changedData,
		index,
	};
}

export function updateBasicInfoInputBlur(element) {
	return {
		type: UPDATE_BASIC_INFO_INPUT_BLUR,
		element,
	};
}

export function updateBasicInfoErrors(errors) {
	return {
		type: UPDATE_BASIC_INFO_ERRORS,
		errors,
	};
}

export function setAllBasicInfoElementsBlur() {
	return {
		type: SET_ALL_BASIC_INFO_ELEMENTS_BLUR,
	};
}

export function createCompany(company) {
	return {
		type: CREATE_COMPANY,
		company,
	};
}

export function createCompanySuccess(successResponse) {
	return {
		type: CREATE_COMPANY_SUCCESS,
		successResponse,
	};
}
export function createCompanyFailure(error) {
	return {
		type: CREATE_COMPANY_FAILURE,
		error,
	};
}

export function updateBasicInfo(id, basicInfo, status) {
	return {
		type: UPDATE_BASIC_INFO,
		id,
		basicInfo,
		status,
	};
}

export function updateBasicInfoSuccess(successResponse) {
	return {
		type: UPDATE_BASIC_INFO_SUCCESS,
		successResponse,
	};
}

export function updateBasicInfoFailure(error) {
	return {
		type: UPDATE_BASIC_INFO_FAILURE,
		error,
	};
}

export function trashCompany(id) {
	return {
		type: TRASH_COMPANY,
		id,
	};
}

export function trashCompanySuccess(successResponse) {
	return {
		type: TRASH_COMPANY_SUCCESS,
		successResponse,
	};
}
export function trashCompanyFailure(error) {
	return {
		type: TRASH_COMPANY_FAILURE,
		error,
	};
}

export function updateFormMessage(message) {
	return {
		type: UPDATE_FORM_MESSAGE,
		message,
	};
}

export function fetchCompanyEditorData(companyId) {
	return {
		type: FETCH_COMPANY_EDITOR_DATA,
		companyId,
	};
}

export function fetchBasicInfo(companyId) {
	return {
		type: FETCH_BASIC_INFO,
		companyId,
	};
}

export function fetchBasicInfoSuccess(basicInfo) {
	return {
		type: FETCH_BASIC_INFO_SUCCESS,
		basicInfo,
	};
}

export function fetchBasicInfoFailure(error) {
	return {
		type: FETCH_BASIC_INFO_FAILURE,
		error,
	};
}

export function fetchAcquisitions(companyId) {
	return {
		type: FETCH_ACQUISITIONS,
		companyId,
	};
}

export function fetchAcquisitionsSuccess(acquisitions) {
	return {
		type: FETCH_ACQUISITIONS_SUCCESS,
		acquisitions,
	};
}

export function fetchAcquisitionsError(error) {
	return {
		type: FETCH_ACQUISITIONS_FAILURE,
		error,
	};
}

export function fetchCompanyFinance(companyId) {
	return {
		type: FETCH_COMPANY_FINANCE,
		companyId,
	};
}

export function fetchCompanyFinanceSuccess(companyFinance) {
	return {
		type: FETCH_COMPANY_FINANCE_SUCCESS,
		companyFinance,
	};
}

export function fetchCompanyFinanceError(error) {
	return {
		type: FETCH_COMPANY_FINANCE_FAILURE,
		error,
	};
}

export function fetchCompanyPeople(companyId) {
	return {
		type: FETCH_COMPANY_PEOPLE,
		companyId,
	};
}

export function fetchCompanyPeopleSuccess(companyPeople) {
	return {
		type: FETCH_COMPANY_PEOPLE_SUCCESS,
		companyPeople,
	};
}

export function fetchCompanyPeopleError(error) {
	return {
		type: FETCH_COMPANY_PEOPLE_FAILURE,
		error,
	};
}

export function fetchFunding(companyId) {
	return {
		type: FETCH_FUNDING,
		companyId,
	};
}

export function fetchFundingSuccess(funding) {
	return {
		type: FETCH_FUNDING_SUCCESS,
		funding,
	};
}

export function fetchFundingError(error) {
	return {
		type: FETCH_FUNDING_FAILURE,
		error,
	};
}

export function fetchCompanyLocations(companyId) {
	return {
		type: FETCH_COMPANY_LOCATIONS,
		companyId,
	};
}

export function fetchCompanyLocationsSuccess(companyLocations) {
	return {
		type: FETCH_COMPANY_LOCATIONS_SUCCESS,
		companyLocations,
	};
}

export function fetchCompanyLocationsError(error) {
	return {
		type: FETCH_COMPANY_LOCATIONS_FAILURE,
		error,
	};
}

export function fetchCompanyProducts(companyId) {
	return {
		type: FETCH_COMPANY_PRODUCTS,
		companyId,
	};
}

export function fetchCompanyProductsSuccess(companyProducts) {
	return {
		type: FETCH_COMPANY_PRODUCTS_SUCCESS,
		companyProducts,
	};
}

export function fetchCompanyProductsError(error) {
	return {
		type: FETCH_COMPANY_PRODUCTS_FAILURE,
		error,
	};
}

export function clearCompanyEditorData() {
	return {
		type: CLEAR_COMPANY_EDITOR_DATA,
	};
}

export function addCompanyAward() {
	return {
		type: ADD_COMPANY_AWARD,
	};
}

export function removeCompanyAward(index) {
	return {
		type: REMOVE_COMPANY_AWARD,
		index,
	};
}

export function fetchAudience(companyId) {
	return {
		type: FETCH_AUDIENCE,
		companyId,
	};
}

export function fetchAudienceSuccess(companyAudience) {
	return {
		type: FETCH_AUDIENCE_SUCCESS,
		companyAudience,
	};
}

export function fetchAudienceError(error) {
	return {
		type: FETCH_AUDIENCE_FAILURE,
		error,
	};
}

export function fetchMilestones(companyId) {
	return {
		type: FETCH_MILESTONES,
		companyId,
	};
}

export function fetchMilestonesSuccess(milestones) {
	return {
		type: FETCH_MILESTONES_SUCCESS,
		milestones,
	};
}

export function fetchMilestonesError(error) {
	return {
		type: FETCH_MILESTONES_FAILURE,
		error,
	};
}

export function fetchCuratedListTypes() {
	return {
		type: FETCH_CURATED_LIST_TYPES,
	};
}

export function fetchCuratedListTypesSuccess(list) {
	return {
		type: FETCH_CURATED_LIST_TYPES_SUCCESS,
		list,
	};
}

export function fetchCuratedListTypesError() {
	return {
		type: FETCH_CURATED_LIST_TYPES_ERROR,
	};
}

export function fetchProfileUpdateNotes(companyId) {
	return {
		type: FETCH_PROFILE_UPDATE_NOTES,
		companyId,
	};
}

export function fetchProfileUpdateNotesSuccess(updates) {
	return {
		type: FETCH_PROFILE_UPDATE_NOTES_SUCCESS,
		updates,
	};
}

export function fetchProfileUpdateNotesFailure(error) {
	return {
		type: FETCH_PROFILE_UPDATE_NOTES_FAILURE,
		error,
	};
}

export function setTopPicks(value) {
	return {
		type: SET_TOP_PICKS,
		value,
	};
}

export function clearTopPicksData() {
	return {
		type: CLEAR_TOP_PICKS,
	};
}
