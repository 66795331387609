import React, { useReducer, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import uuid from 'react-uuid';

import { Row, Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import { Input, Button } from '../Styles';
import { ImageHolder } from '../FormComponents';
import {
	CURATED_SET_CONFIG_URL, SAVING_FAILURE_MESSAGE, SAVING_MESSAGE, SAVING_SUCCESS_MESSAGE,
} from '../../containers/TechSparksManager/constants';
import ToastMessage from '../ToastMessage';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { extractedCityName } from '../../utils/common';

const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
const UPDATE_ERRORS = 'UPDATE_ERRORS';
const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
const SET_FORM_DATA = 'SET_FORM_DATA';

const Card = styled.div`
	border: 1px solid #ddd;
	height: 300px;
	width: 200px;
	padding: 5px;
	margin: 0.25rem;
	background-color: #fff;
	cursor: pointer;
	font-size: 0.8rem;
	word-wrap: break-word;
	cursor: pointer;
	display: block;
	position: relative;
	overflow: auto;
`;

const initialState = {
	formData: {
		id: null,
		name: null,
		designation: null,
		image: null,
		order: 1,
		linkedInUrl: '',
	},
	blurredElements: {
		name: false,
		designation: false,
		image: false,
		order: false,
	},
	errors: {
		name: false,
		designation: false,
		image: false,
		order: false,
	},
	formMessage: null,
};

function updateFormData(changedData) {
	return {
		type: UPDATE_FORM_DATA,
		changedData,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function setFormData(speaker) {
	return {
		type: SET_FORM_DATA,
		speaker,
	};
}

function setFormMessage(message) {
	return {
		type: SET_FORM_MESSAGE,
		message,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case SET_FORM_DATA:
		return { ...state, formData: action.speaker };

	case UPDATE_FORM_DATA: {
		const formData = { ...state.formData };
		const { changedData } = action;
		formData[changedData.name] = changedData.value;
		return { ...state, formData };
	}

	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };

	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			name: true,
			designation: true,
			image: true,
			order: true,
		};
		return { ...state, blurredElements };
	}

	case SET_FORM_MESSAGE:
		return { ...state, formMessage: action.message };

	default:
		return state;
	}
}

const CardWrapper = styled(Row)`
	margin-bottom: 2rem;
	border: 1px solid #bbb;
	background-color: #eee;
	padding: 0.5rem;
	width: 100%;
	position: relative;
	overflow: auto;
	height: 40vh;
	${(props) => props.source === 'fullPage' && css`
		height: 75vh;
	`}
`;

const SpeakerForm = (props) => {
	const {
		track, getTracks, unmountModal, city, speakersLength,
	} = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		formData, blurredElements, errors, formMessage,
	} = state;
	const [isButtonDisabled, disableButton] = useState(false);
	const [toUpdate, setToUpdate] = useState(false);
	const [speakers, setSpeakers] = useState([]);
	const extractCityName = extractedCityName(city);

	useEffect(() => {
		if (track && track?.speakers?.length > 0 && track?.speakers !== 'undefined') {
			setSpeakers(track.speakers);
		}
		const changedData = {
			name: 'order',
			value: speakersLength,
		};
		dispatch(updateFormData(changedData));
	}, []);

	useEffect(() => {
		getErrors();
	}, [formData, blurredElements]);

	function onInputChange(e) {
		const changedData = {
			name: e.target.name,
			value: e.target.value,
		};
		dispatch(updateFormData(changedData));
	}

	function validateForm(speaker) {
		return {
			name: !speaker.name || speaker.name.length < 1,
			designation: !speaker.designation || speaker.designation.length < 1,
			image: !speaker.image || speaker.image.length < 1,
			order: !speaker.order || speaker.order.length < 1,
		};
	}

	function getErrors() {
		const validationErrors = validateForm(formData);
		const errors = Object.keys(validationErrors).reduce((acc, curr) => {
			if (validationErrors[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	}

	function getSpeakerBody(speaker) {
		const formData = new FormData();
		if (toUpdate) {
			formData.append('id', speaker.id);
		} else {
			const id = uuid();
			formData.append('id', id);
		}
		formData.append('name', speaker.name);
		formData.append('designation', speaker.designation);
		formData.append('order', speaker.order);
		formData.append('image', speaker.image && speaker.image.file ? speaker.image.file : JSON.stringify(speaker.image));
		formData.append('linkedInUrl', speaker.linkedInUrl);
		return formData;
	}

	function onFormSubmit(e) {
		e.preventDefault();
		const validation = validateForm(formData);
		const validated = !Object.keys(validation).some((i) => validation[i]);
		if (validated) {
			saveSpeaker();
		} else {
			dispatch(setAllElementsBlurred());
		}
	}

	function handleBlur(element) {
		dispatch(updateBlurredElement(element));
	}

	async function saveSpeaker() {
		try {
			disableButton(true);
			dispatch(setFormMessage(SAVING_MESSAGE));
			const payload = getSpeakerBody(formData);
			const options = {
				method: 'PUT',
				credentials: 'include',
				body: payload,
			};
			const url = `${CURATED_SET_CONFIG_URL}${city ? city === 'mumbai' ? `${city}_`
				: city && city.includes('techsparks_2023') ? `2023_${extractCityName}` : '' : ''}tracks/${toUpdate ? 'update' : 'create'}?subTask=speakers&trackId=${track.id}`;
			const response = await fetch(url, options);
			disableButton(false);
			if (response.status === 200) {
				dispatch(setFormMessage(SAVING_SUCCESS_MESSAGE));
				getTracks();
				setToUpdate(false);
				unmountModal();
			} else {
				dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			}
		} catch (err) {
			dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			disableButton(false);
		}
	}

	function renderFormMessage() {
		if (formMessage) {
			return (
				<ToastMessage
					toastData={formMessage}
					unmount={() => dispatch(setFormMessage(null))}
				/>
			);
		}
		return null;
	}

	async function onUpload(e) {
		e.preventDefault();
		const file = e.target.files[0];
		if (file.size / 1024 / 1024 > 5) {
			return alert(`File size greater than 5 MB (${(file.size / 1024 / 1024).toFixed(2)} MB)!`);
		}
		const reader = new FileReader();
		reader.onloadend = () => {
			dispatch(updateFormData({
				name: 'image',
				value: {
					url: reader.result,
					file,
				},
			}));
		};
		reader.readAsDataURL(file);
	}

	function onUploadClick(e) {
		e.preventDefault();
		const logoInput = document.getElementById('speaker-image');
		logoInput.click();
	}

	function onImageLoad(e) {
		if (formData.image.file) {
			const data = { height: e.target.naturalHeight, width: e.target.naturalWidth };
			dispatch(updateFormData({
				name: 'image',
				value: {
					...formData.image,
					...data,
				},
			}));
		}
	}
	async function handleDelete(speaker) {
		const url = `${CURATED_SET_CONFIG_URL}${city ? city === 'mumbai' ? `${city}_`
			: city && city.includes('techsparks_2023') ? `2023_${extractCityName}` : '' : ''}tracks/delete?id=${speaker.id}&subTask=speakers&trackId=${track.id}`;
		const options = {
			method: 'PUT',
			credentials: 'include',
		};
		const response = await fetch(url, options);
		if (response && response.status === 200) {
			const newSpeakers = speakers.filter((currentSpeaker) => currentSpeaker.id !== speaker.id);
			setSpeakers(newSpeakers);
			getTracks();
		}
	}

	return (
		<form onSubmit={onFormSubmit}>
			<CardWrapper>
				{speakers && speakers.length > 0 && speakers.map((speaker) => (
					<Card key={uuid()}>
						<div className="text-right">
							<i className="fa fa-pencil-square fa-lg" style={{ cursor: 'pointer' }} onClick={() => { setToUpdate(true); dispatch(setFormData(speaker)); }} />
							<i className="fa fa-trash fa-lg ml-3" style={{ cursor: 'pointer' }} onClick={() => handleDelete(speaker)} />
						</div>
						<img src={speaker.image.url} alt="profile" style={{ width: '100px', height: '100px' }} />
						<h2>{speaker.name}</h2>
						<p>{speaker.designation}</p>
						<p>Order: {speaker.order}</p>
					</Card>
				))}
			</CardWrapper>
			<Row>
				<Col sm={5}>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Image *
						</Col>
						<Col xs={12} sm={8}>
							<Input
								id="speaker-image"
								type="file"
								style={{ display: 'none' }}
								name="image"
								onChange={onUpload}
								accept="image/png, image/jpeg"
								onBlur={() => handleBlur('image')}
							/>
							<ImageHolder
								showImageUploader={onUploadClick}
								imageUrl={formData && formData.image ? formData.image.url : null}
								onImageLoad={onImageLoad}
								squareDimension="13rem"
								clearImage={() => {
									document.getElementById('speaker-image').value = '';
									dispatch(updateFormData({ name: 'image', value: null }));
								}}
							/>
							<ErrorMessage display={errors.image} element="Image" />
						</Col>
					</Row>
				</Col>
				<Col sm={7}>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Name *
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="name"
								type="text"
								className="form-control"
								placeholder="Name of the speaker"
								onChange={onInputChange}
								value={formData.name ?? ''}
								onBlur={() => handleBlur('name')}
							/>
							<ErrorMessage display={errors.name} element="Name" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Designation *
						</Col>
						<Col sm={8} xs={12}>
							<textarea
								rows="4"
								className="global-form-control"
								name="designation"
								placeholder="Designation of speaker"
								onChange={onInputChange}
								value={formData.designation ?? ''}
								onBlur={() => handleBlur('designation')}
							/>
							<ErrorMessage display={errors.designation} element="Designation" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Order *
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="order"
								type="number"
								className="form-control"
								placeholder="Order of the speaker"
								onChange={onInputChange}
								value={formData?.order}
								onBlur={() => handleBlur('order')}
							/>
							{/* <DropDown
								className="mt-0"
								name="order"
								value={formData && formData.order ? formData.order : 1}
								onChange={onInputChange}
								onBlur={() => handleBlur('order')}
							>
								<option value={1}>1</option>
								<option value={2}>2</option>
								<option value={3}>3</option>
								<option value={4}>4</option>
								<option value={5}>5</option>
								<option value={6}>6</option>
								<option value={7}>7</option>
								<option value={8}>8</option>
							</DropDown> */}
							<p className="error mt-2 ml-1">NOTE: Please enter order which is not being used.</p>
							<ErrorMessage display={errors.order} element="Order" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Linkedin url
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="linkedInUrl"
								type="url"
								className="form-control"
								placeholder="Linked in URL of the speaker"
								onChange={onInputChange}
								value={formData.linkedInUrl ?? ''}
							/>
						</Col>
					</Row>
					<div className="text-right">
						{toUpdate
							? <Button
									secondary
									onClick={() => { dispatch(setFormData({})); setToUpdate(false); }}
									disabled={isButtonDisabled}
							>
								Clear
							</Button>
							: null}
						<Button
							success
							type="submit"
							disabled={isButtonDisabled}
						>
							SAVE
						</Button>
					</div>
				</Col>
				{renderFormMessage()}
			</Row>
		</form>
	);
};

SpeakerForm.propTypes = {
	track: T.object,
	unmountModal: T.func,
	getTracks: T.func,
	city: T.string,
	speakersLength: T.number,
};

export default SpeakerForm;
