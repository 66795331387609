import React, { useEffect, useReducer, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Row, Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';

import {
	WrapperContainer, Input, Button,
} from '../../components/Styles';
// import { ListItem, ListHeader } from '../../components/EventComponents/index'; Input, Button, DropDown,
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';
import { AsyncMultiSelect } from '../../components/FormComponents';
import { getDropdownOptions, isRoleAboveGivenValue } from '../../utils/common';
// import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import ToastMessage from '../../components/ToastMessage';
import { GET_USER_INFO_BASE_URL, POST_BASE_URL } from './constants';

// const { Quill } = ReactQuill;
// const Link = Quill.import('formats/link');

// class MyLink extends Link {
// 	static create(value) {
// 		const node = super.create(value);
// 		value = this.sanitize(value);
// 		node.setAttribute('href', value);
// 		node.setAttribute('rel', 'noopener nofollow');
// 		if (!value.startsWith('https://yourstory.com')) {
// 			node.setAttribute('target', '_blank');
// 		}
// 		return node;
// 	}

// 	format(value) {
// 		if (!value) {
// 			super.format(value);
// 		} else {
// 			this.domNode.setAttribute('href', this.constructor.sanitize(value));
// 		}
// 	}

// 	static formats(domNode) {
// 		return domNode.getAttribute('href');
// 	}

// 	static sanitize(url) {
// 		return sanitize(url, this.PROTOCOL_WHITELIST) ? url : this.SANITIZED_URL;
// 	}
// }

// MyLink.SANITIZED_URL = 'about:blank';
// MyLink.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel'];

// function sanitize(url, protocols) {
// 	const anchor = document.createElement('a');
// 	anchor.href = url;
// 	const protocol = anchor.href.slice(0, anchor.href.indexOf(':'));
// 	return protocols.indexOf(protocol) > -1;
// }

// Quill.register(MyLink);

const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
const UPDATE_ERRORS = 'UPDATE_ERRORS';
const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
const SET_FORM_DATA = 'SET_FORM_DATA';
const FETCH_PROFILE_BIO_INFO = 'FETCH_PROFILE_BIO_INFO';
const SAVING_MESSAGE = { intent: 'info', message: 'Saving data...' };
const SAVING_SUCCESS_MESSAGE = { intent: 'success', message: 'Data Saved Successfully' };
const SAVING_FAILURE_MESSAGE = { intent: 'danger', message: 'Data Save Failed. Something went wrong. Try Again!' };

const initialState = {
	formData: {
		id: null,
		location: null,
		designation: null,
		categories: null,
		bio: null,
		website_url: null,
		facebook_url: null,
		twitter_url: null,
		linkedin_url: null,
		google_url: null,

	},
	// blurredElements: {
	// 	location: false,
	// 	designation: false,
	// 	categories: false,
	// 	bio: false,
	// },
	errors: {
		location: false,
		designation: false,
		categories: false,
		bio: false,
		website_url: false,
		facebook_url: false,
		twitter_url: false,
		linkedin_url: false,
		google_url: false,
	},
	formMessage: null,
};

function fetchProfileBioInfo() {
	return {
		type: FETCH_PROFILE_BIO_INFO,
	};
}
function updateFormData(changedData) {
	return {
		type: UPDATE_FORM_DATA,
		changedData,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function setFormData(profileBioData) {
	return {
		type: SET_FORM_DATA,
		profileBioData,
	};
}

function setFormMessage(message) {
	return {
		type: SET_FORM_MESSAGE,
		message,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case FETCH_PROFILE_BIO_INFO:
		return { ...state, loading: true };

	case SET_FORM_DATA:
		return { ...state, loading: false, formData: action.profileBioData };

	case UPDATE_FORM_DATA: {
		const formData = { ...state.formData };
		const { changedData } = action;
		formData[changedData.name] = changedData.value;
		return { ...state, formData };
	}

	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };

	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			location: true,
			designation: true,
			categories: true,
			bio: true,
			website_url: true,
			facebook_url: true,
			twitter_url: true,
			linkedin_url: true,
			google_url: true,
		};
		return { ...state, blurredElements };
	}

	case SET_FORM_MESSAGE:
		return { ...state, formMessage: action.message };

	default:
		return state;
	}
}
const ProfileBioPage = (props) => {
	const { pageTitle, history } = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		formData, formMessage, loading, errors,
	} = state;
	const [isUserRoleAboveEditor, setIsUserRoleAboveEditor] = useState(false);

	const toolbarOptions = [
		[{ size: ['small', false, 'large', 'huge'] }],
		['bold', 'italic', 'underline', 'strike'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		[{ script: 'sub' }, { script: 'super' }],
		[{ indent: '-1' }, { indent: '+1' }],
		[{ direction: 'rtl' }],
		['clean'],
	];

	useEffect(() => {
		pageTitle('profileBio');
		getBioInfo();
	}, []);

	// useEffect(() => {
	// 	getErrors();
	// }, [formData, blurredElements]);

	const onChangeOfValue = (fieldName, fieldValue) => {
		const changedData = {
			name: fieldName,
			value: fieldValue,
		};
		dispatch(updateFormData(changedData));
	};

	function handleBlur(element) {
		dispatch(updateBlurredElement(element));
	}

	// validation for website, facebook, twitter, google profile and linked in urls
	function validUrlCheck(value) {
		if (!value) {
			return true;
		}

		const regexp = /^((ftp|http|https):\/\/)?(www.)+(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]*)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
		// /^(?:(?:https?):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
		if (regexp.test(value)) {
			return true;
		}
		return false;
	}
	// added url validation for social link
	function validateURLs(url, type) {
		let urlFormats;
		let result = false;
		switch (type) {
		case 'facebook':
			urlFormats = ['https://www.facebook.com', 'http://www.facebook.com', 'https://facebook.com', 'http://facebook.com'];
			break;
		case 'twitter':
			urlFormats = ['https://www.twitter.com', 'http://www.twitter.com', 'https://twitter.com', 'http://twitter.com'];
			break;
		case 'instagram':
			urlFormats = ['https://www.instagram.com', 'http://www.instagram.com', 'https://instagram.com', 'http://instagram.com'];
			break;
		case 'gmail':
			urlFormats = ['https://www.mail.google.com', 'http://www.mail.google.com', 'https://mail.google.com', 'http://mail.google.com'];
			break;
			// https://www.linkedin.com/in
		case 'linkedIn':
			urlFormats = ['https://www.linkedin.com', 'http://www.linkedin.com', 'https://linkedin.com', 'http://linkedin.com'];
			break;
		default:
			break;
		}
		// for (const format of urlFormats) {
		// 	if (url.startsWith(format)) {
		// 		return true;
		// 	}
		// }
		urlFormats.forEach((format) => {
			if (url && url?.length > 0 && url.startsWith(format)) {
				result = true;
			}
		});
		return result;
	}
	function validateForm(profileBioData) {
		return {
			// location: !profileBioData.location || profileBioData.location.length < 1,
			// designation: !profileBioData.designation || profileBioData.designation.length < 1,
			// categories: !profileBioData.categories || profileBioData.categories.length < 1,
			// bio: !profileBioData.bio || profileBioData.bio.length < 1,
			website_url: (profileBioData.website_url && profileBioData.website_url.length > 1 && !validUrlCheck(profileBioData.website_url)) || false,
			facebook_url: (profileBioData.facebook_url && profileBioData.facebook_url.length > 1 && !validateURLs(profileBioData.facebook_url, 'facebook')) || false,
			twitter_url: (profileBioData.twitter_url && profileBioData.twitter_url.length > 1 && !validateURLs(profileBioData.twitter_url, 'twitter')) || false,
			linkedin_url: (profileBioData.linkedin_url && profileBioData.linkedin_url.length > 1 && !validateURLs(profileBioData.linkedin_url, 'linkedIn')) || false,
			google_url: (profileBioData.google_url && profileBioData.google_url.length > 1 && !validUrlCheck(profileBioData.google_url)) || false,

		};
	}

	// function getErrors() {
	// 	const validationErrors = validateForm(formData);
	// 	const errors = Object.keys(validationErrors).reduce((acc, curr) => {
	// 		if (validationErrors[curr] && blurredElements[curr]) {
	// 			acc[curr] = true;
	// 		} else {
	// 			acc[curr] = false;
	// 		}
	// 		console.log('acce', acc, formData);
	// 		return acc;
	// 	}, {});
	// 	dispatch(updateErrors(errors));
	// }
	function onFormSubmit(e) {
		e.preventDefault();
		const validation = validateForm(formData);
		const validated = !Object.keys(validation).some((i) => validation[i]);
		if (validated) {
			saveBio();
			dispatch(updateErrors(validation));
		} else {
			dispatch(updateErrors(validation));
			dispatch(setAllElementsBlurred());
		}
	}

	const getBioInfo = async () => {
		dispatch(fetchProfileBioInfo());
		const url = GET_USER_INFO_BASE_URL;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			const res = await response.json();
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200 || response.status === 201) {
				const isEditorTrue = isRoleAboveGivenValue(res.user.roles.brand, 3);
				setIsUserRoleAboveEditor(isEditorTrue);
				dispatch(setFormData(res.user));
			} else {
				const formData = {
					id: null,
					location: null,
					designation: null,
					categories: null,
					bio: null,
					website_url: null,
					facebook_url: null,
					twitter_url: null,
					linkedin_url: null,
					google_url: null,
				};
				dispatch(setFormData(formData));
			}
		} catch (err) {
			dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			const formData = {
				id: null,
				location: null,
				designation: null,
				categories: null,
				bio: null,
				website_url: null,
				facebook_url: null,
				twitter_url: null,
				linkedin_url: null,
				google_url: null,
			};
			dispatch(setFormData(formData));
		}
		setTimeout(() => {
			dispatch(setFormMessage(null));
		}, 2000);
	};

	const saveBio = async () => {
		dispatch(setFormMessage(SAVING_MESSAGE));
		const url = POST_BASE_URL;
		const updateData = {
			bio: formData.bio,
			location: formData.location,
			designation: formData.designation,
			categories: formData.categories,
			website_url: formData.website_url,
			facebook_url: formData.facebook_url,
			twitter_url: formData.twitter_url,
			linkedin_url: formData.linkedin_url,
			google_url: formData.google_url,
		};
		const options = {
			method: 'POST',
			body: JSON.stringify(updateData),
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
			},
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200 || response.status === 201) {
				dispatch(setFormMessage(SAVING_SUCCESS_MESSAGE));
				getBioInfo();
			} else {
				dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			}
		} catch (err) {
			dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
		}
		setTimeout(() => {
			dispatch(setFormMessage(null));
		}, 2000);
	};

	function renderFormMessage() {
		if (formMessage) {
			return (
				<ToastMessage
					toastData={formMessage}
					unmount={() => dispatch(setFormMessage(null))}
				/>
			);
		}
		return null;
	}

	return (
		<WrapperContainer>
			{
				loading
					? <LoadingIcon />
					: <>
						<form onSubmit={onFormSubmit}>
							{isUserRoleAboveEditor ? <>
								<Row>
									<Col xs={12}>
										{renderFormMessage()}
									</Col>
								</Row>
								<Row className="mt-3">
									<Col xs={12} sm={5} md={5}>
										Location
									</Col>
									<Col xs={12} sm={5} md={5}>
										<Input
											type="text"
											name="location"
											value={formData.location ?? ''}
											onChange={(e) => onChangeOfValue('location', e.target.value)}
											className
											onBlur={() => handleBlur('location')}
											placeholder="Enter author location"
										/>
										{/* <ErrorMessage display={errors.location} element="Location" /> */}
									</Col>
								</Row>
								<Row className="mt-3">
									<Col xs={12} sm={5} md={5}>
										Designation
									</Col>
									<Col xs={12} sm={5} md={5}>
										<Input
											type="text"
											name="designation"
											value={formData.designation ?? ''}
											onChange={(e) => onChangeOfValue('designation', e.target.value)}
											className
											onBlur={() => handleBlur('designation')}
											placeholder="Enter author designation"
										/>
										{/* <ErrorMessage display={errors.designation} element="designation" /> */}
									</Col>
								</Row>
								<Row className="mt-3 mb-3">
									<Col xs={12} sm={5} md={5}>
										Categories
									</Col>
									<Col xs={12} sm={5} md={5}>
										<AsyncMultiSelect
											placeholder="Search categories by name"
											options={(value) => getDropdownOptions('profile-bio-the-captable', value, 'story-editor')}
											currentValues={formData.categories ?? []}
											handleChange={(categories) => onChangeOfValue('categories', categories)}
											onBlur={() => handleBlur('categories')}
										/>
										{/* <ErrorMessage display={errors.categories} element="Categories" /> */}
									</Col>
								</Row>
								{/*  website url */}
								<Row className="mt-3">
									<Col xs={12} sm={5} md={5}>
										Website URL
									</Col>
									<Col xs={12} sm={5} md={5}>
										<Input
											type="text"
											name="website_url"
											value={formData.website_url ?? ''}
											onChange={(e) => onChangeOfValue('website_url', e.target.value)}
											className
											onBlur={() => handleBlur('website_url')}
											placeholder="Enter author website_url"
										/>
										{errors.website_url && <p className="text-danger" style={{ color: 'red' }}>Enter Proper Website URL</p>}
										{/* <ErrorMessage display={errors.website_url} element="Website URL" /> */}
									</Col>
								</Row>
								{/*  */}
								{/*  facebook profile url */}
								<Row className="mt-3">
									<Col xs={12} sm={5} md={5}>
										Facebook Profile URL
									</Col>
									<Col xs={12} sm={5} md={5}>
										<Input
											type="text"
											name="facebook_url"
											value={formData.facebook_url ?? ''}
											onChange={(e) => onChangeOfValue('facebook_url', e.target.value)}
											className
											onBlur={() => handleBlur('facebook_url')}
											placeholder="Enter author Facebook Profile URL"
										/>
										{errors.facebook_url && <p className="text-danger" style={{ color: 'red' }}>Enter Proper Facebook URL</p>}
										{/* <ErrorMessage display={errors.facebook_url} element="Facebook URL" /> */}
									</Col>
								</Row>
								{/*  */}
								{/*  twitter profile url */}
								<Row className="mt-3">
									<Col xs={12} sm={5} md={5}>
										Twitter Profile URL
									</Col>
									<Col xs={12} sm={5} md={5}>
										<Input
											type="text"
											name="twitter_url"
											value={formData.twitter_url ?? ''}
											onChange={(e) => onChangeOfValue('twitter_url', e.target.value)}
											className
											onBlur={() => handleBlur('twitter_url')}
											placeholder="Enter author Twitter Profile URL"
										/>
										{/* <ErrorMessage display={errors.twitter_url} element="Twitter URL" /> */}
										{errors.twitter_url && <p className="text-danger" style={{ color: 'red' }}>Enter Proper Twitter URL</p>}
									</Col>
								</Row>
								{/*  */}
								{/*  linked in profile url */}
								<Row className="mt-3">
									<Col xs={12} sm={5} md={5}>
										Linked In Profile URL
									</Col>
									<Col xs={12} sm={5} md={5}>
										<Input
											type="text"
											name="linkedin_url"
											value={formData.linkedin_url ?? ''}
											onChange={(e) => onChangeOfValue('linkedin_url', e.target.value)}
											className
											onBlur={() => handleBlur('linkedin_url')}
											placeholder="Enter author LinkedIn Profile URL"
										/>
										{errors.linkedin_url && <p className="text-danger" style={{ color: 'red' }}>Enter Proper LinkedIn URL</p>}
										{/* <ErrorMessage display={errors.linkedin_url} element="LinkedIn URL" /> */}
									</Col>
								</Row>
								{/*  */}
								{/*  google profile url */}
								<Row className="mt-3 mb-3">
									<Col xs={12} sm={5} md={5}>
										Google Profile URL
									</Col>
									<Col xs={12} sm={5} md={5}>
										<Input
											type="text"
											name="google_url"
											value={formData.google_url ?? ''}
											onChange={(e) => onChangeOfValue('google_url', e.target.value)}
											className
											onBlur={() => handleBlur('google_url')}
											placeholder="Enter author Google Profile URL"
										/>
										{errors.google_url && <p className="text-danger" style={{ color: 'red' }}>Enter Proper Google Profile URL</p>}
										{/* <ErrorMessage display={errors.google_url} element="Google Profile URL" /> */}
									</Col>
								</Row>
								{/*  */}
							</>
								: null }
							<Row>
								<Col xs={12} sm={10} md={12}>
									<ReactQuill
										id="profileBio"
										name="profileBio"
										style={{ height: '15rem' }}
										placeholder="profileBio"
										defaultValue={formData && formData.bio ? formData.bio : ''}
										onChange={(content) => {
											onChangeOfValue('bio', content);
										}}
										modules={{ toolbar: toolbarOptions }}
										onBlur={() => handleBlur('bio')}
									/>
								</Col>
								{/* <Col xs={12}>
									<div className="profileBioEditor">
										<ErrorMessage display={errors.bio} element="Bio" />
									</div>
								</Col> */}
							</Row>
							<Row>
								<Col xs={12}>
									<div className="text-left profileBioSaveBtn">
										<Button
											primary
											type="submit"
										>
											SAVE
										</Button>
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									{renderFormMessage()}
								</Col>
							</Row>
						</form>
					</>
			}
		</WrapperContainer>
	);
};

ProfileBioPage.propTypes = {
	pageTitle: T.func,
	// user: T.object,
	history: T.object,
};
export default ProfileBioPage;
