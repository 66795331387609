/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect } from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import { WrapperContainer, Button, ActionCard } from '../../components/Styles';
import ProfileImagePlaceholder from '../../images/img-placeholder.png';

const WebStoriesManager = (props) => {
    const { pageTitle, user } = props;

    useEffect(
        () => {
            pageTitle('webstoriesManager');
        }, [],
    );
    console.log(user);

    const renderWebstoryCards = () => <ActionCard className="p-3 mb-3">
        <Col>
            <Row>
                <img
                    className="thumbnail-img"
                    src={ProfileImagePlaceholder}
                    alt="thumbnail"
                    style={{ width: '20%', boxShadow: '0px 1px 3px 2px #d6d6d6' }}
                />
                <Col><p><b>Title</b></p>
                    <div>Category</div> </Col>
            </Row>
        </Col>
    </ActionCard>;

    return (
        <div>
            <WrapperContainer className="mt-5">
                <Col>
                    <a href="/boni/write-web-story" target="_blank" rel="noopener noreferrer">
                        <Button
                            primary
                            btn_rounded
                            w_100
                        >
                            Write Web Story
                        </Button>
                    </a>
                    {renderWebstoryCards()}
                </Col>
            </WrapperContainer>
        </div>
    );
};

WebStoriesManager.propTypes = {
    pageTitle: T.func,
    user: T.object,
};

export default WebStoriesManager;
