import React, {
	useEffect, useState, useReducer, Fragment,
} from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import 'rc-pagination/assets/index.css';
import Pagination from 'rc-pagination';
import T from 'prop-types';
import Modal from 'react-modal';
import {
	Input, Button, WrapperContainer, DropDown,
} from '../../components/Styles';
// import ToastMessage from '../../components/ToastMessage';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { CommentsList, CommentsListHeader } from '../../components/LiveFeedCommentsList/index';
import locale from '../WatchlistedStories/en_US';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';
import {
	LIVE_FEED_COMMENTS_BASE_URL,
	LIVE_FEED_STATUS_BASE_URL,
	FETCH_LIVE_FEED_COMMENTS,
	FETCH_LIVE_FEED_COMMENTS_SUCCESS,
	FETCH_LIVE_FEED_COMMENTS_FAILURE,
	FILTER_LIVE_FEED_COMMENTS,
	FILTER_LIVE_FEED_COMMENTS_SUCCESS,
	FILTER_LIVE_FEED_COMMENTS_FAILURE,
	SET_LIVE_FEED_COMMENTS_PAGE,
	SET_ALL_ELEMENTS_BLURRED,
	UPDATE_ERRORS,
	UPDATE_BLURRED_ELEMENT,
	UPDATE_ACTIONS_ERROR,
	UPDATE_ACTIONS_SUCCESS,
	UPDATE_ACTIONS_LOADER,
	SET_ACTIONS_MESSAGE,
	SET_FORM_DATA,
	UPDATE_FORM_DATA,
	SAVING_MESSAGE,
	SAVING_SUCCESS_MESSAGE,
	SAVING_FAILURE_MESSAGE,
	SET_ELEMENTS_BLURRED,
} from './constants';
import ToastMessage from '../../components/ToastMessage';

const initialState = {
	liveFeedComments: [],
	commentsCount: null,
	loading: false,
	error: false,
	page: 1,
	actionLoading: false,
	actionError: false,
	actionMessage: '',
	formData: {
		name: null,
		designation: null,
		comment: null,
		status: null,
	},
	blurredElements: {
		name: false,
		designation: false,
		comment: false,
		status: false,
	},
	errors: {
		name: false,
		designation: false,
		comment: false,
		status: false,
	},
};

function setBlurredElements(blurredElements) {
	return {
		type: SET_ELEMENTS_BLURRED,
		blurredElements,
	};
}

function setActionsMessage(message) {
	return {
		type: SET_ACTIONS_MESSAGE,
		message,
	};
}

function updateActionsLoading() {
	return {
		type: UPDATE_ACTIONS_LOADER,
	};
}

function updateActionsFailure() {
	return {
		type: UPDATE_ACTIONS_ERROR,
	};
}

function updateActionsSuccess() {
	return {
		type: UPDATE_ACTIONS_SUCCESS,
	};
}
function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}
function fetchLiveFeedComments() {
	return {
		type: FETCH_LIVE_FEED_COMMENTS,
	};
}

function fetchLiveFeedCommentsSuccess(liveFeedComments) {
	return {
		type: FETCH_LIVE_FEED_COMMENTS_SUCCESS,
		liveFeedComments,
	};
}

function fetchLiveFeedCommentsFailure(error) {
	return {
		type: FETCH_LIVE_FEED_COMMENTS_FAILURE,
		error,
	};
}

function setPage(page) {
	return {
		type: SET_LIVE_FEED_COMMENTS_PAGE,
		page,
	};
}

function setFormData(comment) {
	return {
		type: SET_FORM_DATA,
		comment,
	};
}

function updateFormData(changedData) {
	return {
		type: UPDATE_FORM_DATA,
		changedData,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case SET_FORM_DATA:
		return { ...state, formData: action.comment };

	case UPDATE_FORM_DATA: {
		const formData = { ...state.formData };
		const { changedData } = action;
		formData[changedData.name] = changedData.value;
		return { ...state, formData };
	}

	case FETCH_LIVE_FEED_COMMENTS:
		return { ...state, loading: true };
	case FETCH_LIVE_FEED_COMMENTS_SUCCESS:
		return {
			...state, loading: false, liveFeedComments: [...action.liveFeedComments?.rows], commentsCount: action.liveFeedComments?.count,
		};
	case FETCH_LIVE_FEED_COMMENTS_FAILURE:
		return {
			...state, loading: false, error: true, liveFeedComments: [],
		};
	case FILTER_LIVE_FEED_COMMENTS:
		return { ...state, loading: true };
	case FILTER_LIVE_FEED_COMMENTS_SUCCESS:
		return {
			...state, loading: false, liveFeedComments: [...action.liveFeedComments?.rows], commentsCount: action.liveFeedComments?.count,
		};
	case FILTER_LIVE_FEED_COMMENTS_FAILURE:
		return {
			...state, loading: true, error: true, liveFeedComments: [],
		};
	case SET_LIVE_FEED_COMMENTS_PAGE:
		return { ...state, page: action.page };
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };
	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			name: true,
			designation: true,
			comment: true,
			status: true,
		};
		return { ...state, blurredElements };
	}
	case SET_ELEMENTS_BLURRED: {
		return { ...state, blurredElements: action.blurredElements };
	}
	case UPDATE_ACTIONS_ERROR:
		return {
			...state, actionLoading: false, actionError: true,
		};
	case UPDATE_ACTIONS_SUCCESS:
		return {
			...state, actionLoading: false, actionError: false,
		};
	case UPDATE_ACTIONS_LOADER:
		return {
			...state, actionLoading: true, actionError: false,
		};
	case SET_ACTIONS_MESSAGE:
		return {
			...state, actionMessage: action.message,
		};
	default:
		return state;
	}
}

const LiveFeedCommentsManager = (props) => {
	const {
		status, history, pageTitle, user,
	} = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		liveFeedComments, loading, commentsCount, page, errors, blurredElements, formData, actionMessage,
	} = state;
	const [disabledBtn, setDisabledBtn] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [editCommentForm, setAddOrEditCommentForm] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState('DRAFT');

	useEffect(() => {
		getLiveFeedComments((page - 1) * 10, 10, '', selectedStatus);
		pageTitle('liveFeedManager');
	}, []);

	useEffect(() => {
		handleErrors();
	}, [formData, blurredElements]);

	async function getLiveFeedComments(offset, limit, searchVal = searchValue, filterVal = selectedStatus) {
		dispatch(fetchLiveFeedComments());
		const url = `${LIVE_FEED_COMMENTS_BASE_URL}?offset=${offset}&limit=${limit}&search=${searchVal}&status=${filterVal}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				const liveFeedComments = await response.json();
				dispatch(fetchLiveFeedCommentsSuccess(liveFeedComments.data));
			} else {
				dispatch(fetchLiveFeedCommentsFailure());
			}
		} catch (err) {
			dispatch(fetchLiveFeedCommentsFailure(err));
		}
	}

	const handleActions = async ({
		id,
		method,
		selectStatus,
		callBack,
		callBackFromToolTipMessage,
	}) => {
		const options = {
			method,
			credentials: 'include',
		};
		const postParam = {
			DELETE: `${LIVE_FEED_COMMENTS_BASE_URL}/${id}`,
			PATCH: `${LIVE_FEED_STATUS_BASE_URL}${id}/${selectStatus}`,
		};
		if (callBackFromToolTipMessage) callBackFromToolTipMessage(1);
		try {
			const response = await fetch(`${postParam[method]}`, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
				if (callBack) callBack();
			} else if (response.status === 201 || response.status === 200) {
				if (callBackFromToolTipMessage) callBackFromToolTipMessage(2);
				setTimeout(() => {
					closeModal();
					getLiveFeedComments((page - 1) * 10, 10, '', selectedStatus);
					if (callBack) callBack();
				}, 2000);
			} else {
				if (callBackFromToolTipMessage) callBackFromToolTipMessage(3);
				setTimeout(() => {
					closeModal();
					if (callBack) callBack();
				}, 2000);
			}
		} catch (err) {
			setTimeout(() => {
				closeModal();
				if (callBackFromToolTipMessage) callBackFromToolTipMessage(3);
				if (callBack) callBack();
			}, 2000);
		}
	};

	const saveComment = async () => {
		dispatch(updateActionsLoading());
		dispatch(setActionsMessage(SAVING_MESSAGE));
		const patchAPiUrl = `${LIVE_FEED_COMMENTS_BASE_URL}/${formData.id}`;
		const url = `${formData.id ? patchAPiUrl : LIVE_FEED_COMMENTS_BASE_URL}`;
		const reqData = {
			name: formData.name,
			designation: formData.designation,
			comment: formData.comment,
			status: formData.status,
		};
		const options = {
			method: formData.id ? 'PATCH' : 'POST',
			credentials: 'include',
			body: JSON.stringify(reqData),
			headers: {
				'Content-Type': 'application/json',
			},
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response && response.status === 200) {
				dispatch(updateActionsSuccess());
				dispatch(setActionsMessage(SAVING_SUCCESS_MESSAGE));
				setTimeout(() => {
					closeModal();
					dispatch(setActionsMessage(null));
					getLiveFeedComments((page - 1) * 10, 10, '', selectedStatus);
				}, 2000);
			} else if (response && response.status !== 200) {
				dispatch(updateActionsFailure());
				dispatch(setActionsMessage(SAVING_FAILURE_MESSAGE));
				setTimeout(() => {
					dispatch(updateActionsSuccess());
					dispatch(setActionsMessage(null));
					closeModal();
				}, 2000);
			}
		} catch (err) {
			dispatch(updateActionsFailure());
			dispatch(setActionsMessage(SAVING_FAILURE_MESSAGE));
			setTimeout(() => {
				dispatch(updateActionsSuccess());
				closeModal();
				dispatch(setActionsMessage(null));
			}, 2000);
		}
	};

	function handleSearch(e) {
		e.preventDefault();
		const { value } = e.target;
		getLiveFeedComments(0, 10, value, selectedStatus);
		dispatch(setPage(1));
	}

	function onPageChange(page) {
		getLiveFeedComments((page - 1) * 10, 10, '', selectedStatus);
		dispatch(setPage(page));
	}

	function closeModal() {
		dispatch(setPage(1));
		dispatch(updateErrors({
			name: false,
			designation: false,
			comment: false,
			status: false,
		}));
		dispatch(setBlurredElements({
			name: false,
			designation: false,
			comment: false,
			status: false,
		}));
		dispatch(setAllElementsBlurred());
		setAddOrEditCommentForm(false);
		dispatch(setFormData({}));
		setDisabledBtn(false);
	}

	function validateForm() {
		return {
			// name: !(formData && formData.name && formData.name?.length > 0),
			// designation: !(formData && formData.designation && formData.designation?.length > 0),
			comment: !(formData && formData.comment && formData.comment?.length > 0),
			status: !(formData && formData.status && formData.status?.length > 0),
		};
	}
	const handleErrors = () => {
		const validation = validateForm();
		const errors = Object.keys(validation).reduce((acc, curr) => {
			if (validation[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(setAllElementsBlurred());
		const validation = validateForm();
		const validated = !Object.keys(validation).some((i) => validation[i]);
		handleErrors();
		if (validated) {
			setDisabledBtn(true);
			saveComment();
		} else {
			dispatch(setAllElementsBlurred());
		}
	};

	function onInputChange(e) {
		const changedData = {};
		changedData.name = e.target.name;
		changedData.value = e.target.value;
		dispatch(updateFormData(changedData));
	}

	function handleBlur(element) {
		dispatch(updateBlurredElement(element));
		handleErrors();
	}

	function renderFormMessage() {
		if (actionMessage) {
			return (
				<ToastMessage
					toastData={actionMessage}
					unmount={() => dispatch(setActionsMessage(null))}
				/>
			);
		}
		return null;
	}
	function onStatusChange(event) {
		const value = event;
		setSelectedStatus(value);
		getLiveFeedComments((page - 1) * 10, 10, searchValue, value);
		dispatch(setPage(1));
	}

	function getLiveFeedCommentsManager() {
		return liveFeedComments.map((comment, index) => <CommentsList
			key={comment.id}
			id={comment.id}
			history={history}
			userData={user}
			slNo={(page - 1) * 10 + index + 1}
			commentItem={comment}
			editComment={() => {
				setAddOrEditCommentForm(true);
				dispatch(setFormData(comment));
			}}
			handleActions={handleActions}
			index={index}
			selectedStatusOfComment={selectedStatus}
		/>);
	}

	const editCommentOfTheJob = () => <Modal
		isOpen={editCommentForm}
		shouldCloseOnOverlayClick={false}
		onRequestClose={closeModal}
		overlayClassName="modal-overlay"
		className="form-modal-liveFeed"
	>
		<Row>
			<Col md={10} xs={10}>
				<h2 className="w-50 text-left">Comment Of The Live Feed</h2>
			</Col>
			<Col md={2} xs={2}>
				<div className="w-50 text-right">
					<button type="button" onClick={closeModal}>
						<i className="fas fa-times light-text disablePointerEvents" />
					</button>
				</div>
			</Col>
		</Row>
		<div>
			<form onSubmit={handleSubmit}>
				{renderFormMessage()}
				<Row className="mb-3">
					<Col xs={12} className="mb-3">
						Name
					</Col>
					<Col xs={12}>
						<Input
							name="name"
							type="text"
							className="form-control"
							placeholder="Name"
							onChange={onInputChange}
							value={formData.name ?? ''}
							onBlur={() => handleBlur('name')}
						/>
						<ErrorMessage display={errors.name} element="Name" />
					</Col>
				</Row>
				<Row className="mb-3">
					<Col xs={12} className="mb-3">
						Designation
					</Col>
					<Col xs={12}>
						<Input
							name="designation"
							type="text"
							className="form-control"
							placeholder="Designation"
							onChange={onInputChange}
							value={formData.designation ?? ''}
							onBlur={() => handleBlur('designation')}
						/>
						<ErrorMessage display={errors.designation} element="Designation" />
					</Col>
				</Row>
				<Row className="mb-3">
					<Col xs={12} className="mb-3">
						Comment *
					</Col>
					<Col xs={12}>
						<textarea
							rows="6"
							maxLength={180 + (formData?.comment?.length > 0 ? formData?.comment?.split(' ').length - 1 : 0)}
							name="comment"
							className="global-form-control"
							placeholder="Comment of The Live Feed"
							value={formData.comment ?? ''}
							onChange={onInputChange}
							onBlur={() => handleBlur('comment')}
						/>
						<div style={{
							textAlign: 'right',
							color: formData.comment && formData.comment.replaceAll(' ', '').length > 180 ? '#ff0000' : '#aaa',
						}}
						>
							{formData && formData.comment ? formData.comment.replaceAll(' ', '').length : 0}/180
						</div>
						<ErrorMessage display={errors.comment} element="Comment" />
					</Col>
				</Row>
				<Row className="mb-3">
					<Col xs={12} className="mb-3">
						Status *
					</Col>
					<Col xs={12}>
						<DropDown
							className="mt-0"
							value={formData.status}
							onChange={(e) => {
								const changedData = {};
								changedData.name = 'status';
								changedData.value = e.target.value;
								dispatch(updateFormData(changedData));
							}}
						>
							<option>Select Staus</option>
							<option value="DRAFT">DRAFT</option>
							<option value="INREVIEW">INREVIEW</option>
							{user.roles.liveFeedCardManager === 2 && <option value="PUBLISHED">PUBLISHED</option>}
							{user.roles.liveFeedCardManager === 2 && <option value="UNPUBLISHED">UNPUBLISHED</option>}
						</DropDown>
						<ErrorMessage display={errors.status} element="status" />
					</Col>
					<Col xs={12}>
						<div className="text-right mt-4">
							<Button
								success
								type="submit"
								disabled={disabledBtn}
							>
								SAVE
							</Button>
						</div>
					</Col>
				</Row>
				{renderFormMessage()}
			</form>
		</div>
	</Modal>;

	function renderList() {
		if (liveFeedComments && liveFeedComments.length > 0) {
			return <div>
				<CommentsListHeader selectedStatus={status} />
				{getLiveFeedCommentsManager()}
			</div>;
		}

		return (
			<Row style={{
				width: '100%', minHeight: '400px', justifyContent: 'center', alignItems: 'center',
			}}
			>
				<div>
					<h1 style={{ color: '#bbb' }}>
						No Live Feed Comments To Display
					</h1>
				</div>
			</Row>
		);
	}

	return (
		<WrapperContainer>
			<Row className="">
				<Col sm={4} xs={12} className="mb-2">
					<h2>Live Feed Comments</h2>
				</Col>
				<Col sm={8} xs={12}>
					<Row style={{ justifyContent: 'flex-end' }} className="mr-1 mb-2">
						{/* <p className="text-bold mb-0 pt-1">Filter</p> */}
						{/* <Col sm={3} xs={12} className="mb-2">
							<DropDown className="mt-0" onChange={onStatusChange}>
								<option value="DRAFT">DRAFT</option>
								<option value="INREVIEW">INREVIEW</option>
								{user.roles.liveFeedCardManager === 2 && <option value="PUBLISHED">PUBLISHED</option>}
								{user.roles.liveFeedCardManager === 2 && <option value="UNPUBLISHED">UNPUBLISHED</option>}
							</DropDown>
						</Col> */}
						<Col sm={4} xs={12} className="mb-2">
							<Input
								id="search-box"
								type="text"
								className="form-control"
								placeholder="Search By Name"
								value={searchValue}
								onChange={(e) => {
									handleSearch(e);
									setSearchValue(e.target.value);
								}}
							/>
						</Col>
						<Col sm={2} xs={12} style={{ justifyContent: 'flex-end' }}>
							<Button
								primary
								no_radius
								className="mb-0 mr-2"
								onClick={() => {
									setAddOrEditCommentForm(true);
									dispatch(setFormData({}));
									dispatch(updateErrors({
										name: false,
										designation: false,
										comment: false,
										status: false,
									}));
									dispatch(setBlurredElements({
										name: false,
										designation: false,
										comment: false,
										status: false,
									}));
								}}
							>
								<b>ADD NEW COMMENT</b>
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
			{
				loading
					? <LoadingIcon />
					: <>
						<Row>
							<Col
								xs={1.7}
								className={`nav-text ${selectedStatus === 'DRAFT' ? 'nav-b-a' : 'nav-b'}`}
								onClick={() => onStatusChange('DRAFT')}
							>
								DRAFT
							</Col>
							<Col
								xs={1.7}
								className={`nav-text ${selectedStatus === 'INREVIEW' ? 'nav-b-a' : 'nav-b'}`}
								onClick={() => onStatusChange('INREVIEW')}
							>
								INREVIEW
							</Col>
							<Col
								xs={1.7}
								className={`nav-text ${selectedStatus === 'PUBLISHED' ? 'nav-b-a' : 'nav-b'}`}
								onClick={() => onStatusChange('PUBLISHED')}
							>
								PUBLISHED
							</Col>
							<Col
								xs={1.7}
								className={`nav-text ${selectedStatus === 'UNPUBLISHED' ? 'nav-b-a' : 'nav-b'}`}
								onClick={() => onStatusChange('UNPUBLISHED')}
							>
								UNPUBLISHED
							</Col>
						</Row>
						{renderList()}
						{liveFeedComments && liveFeedComments.length > 0
							? <div>
								<Row className="mt-3 pr-4" style={{ justifyContent: 'flex-end' }}>
									<Pagination onChange={onPageChange} current={page} total={commentsCount} locale={locale} />
								</Row>
							</div>
							: null}
					</>
			}
			{editCommentOfTheJob()}
		</WrapperContainer>
	);
};

LiveFeedCommentsManager.propTypes = {
	status: T.number,
	history: T.object,
	pageTitle: T.func,
	user: T.object,
};
export default LiveFeedCommentsManager;
