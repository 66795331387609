import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	CREATE_CATEGORY, UPDATE_CATEGORY, DELETE_CATEGORY, REPLACE_CATEGORY,
} from './type';
import { CATEGORY_API_BASE_URL, STORY_METADATA_BASE_URL, REDIRECTION_BASE_API_URL } from './constants';
import {
	createCategoryFailure, createCategorySuccess, updateCategorySuccess, deleteCategorySuccess, deleteCategoryFailure, replaceCategorySuccess, replaceCategoryFailure, categoryRedirectionFailure, updateCategoryFailure, categorySelect,
} from './actions';

export function* createCategory(action) {
	const url = `${CATEGORY_API_BASE_URL}/`;
	const formData = action.category;
	const options = {
		method: 'POST',
		credentials: 'include',
		body: formData,
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(createCategorySuccess(responseMessage));
				yield put(categorySelect(null));
			} else {
				yield put(createCategoryFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(createCategoryFailure(err));
	}
}

export function* updateCategory(action) {
	const url = `${CATEGORY_API_BASE_URL}/id/${action.id}`;
	const formData = action.updatedData;
	const options = {
		method: 'PUT',
		credentials: 'include',
		body: formData,
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(updateCategorySuccess(responseMessage));
				yield put(categorySelect(null));
			} else {
				yield put(updateCategoryFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(updateCategoryFailure(err));
	}
}

export function* deleteCategory(action) {
	const url = `${CATEGORY_API_BASE_URL}/${action.locale}/${action.id}`;
	const options = {
		method: 'DELETE',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(deleteCategorySuccess(responseMessage));
				yield put(categorySelect(null));
			} else {
				yield put(deleteCategoryFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(deleteCategoryFailure(err));
	}
}

export function* replaceCategory(action) {
	const url = `${STORY_METADATA_BASE_URL}/topic/replace`;
	const body = { key: action.key, locale: action.locale, target: { existing: action.existing, replacement: action.replacement } };
	const options = {
		method: 'PUT',
		credentials: 'include',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const redirectUrl = `${REDIRECTION_BASE_API_URL}/save`;
			const redirectOptions = {
				headers: { 'Content-Type': 'application/json' },
				method: 'POST',
				credentials: 'include',
				body: JSON.stringify(action.redirection),
			};
			const redirectResponse = yield call(fetch, redirectUrl, redirectOptions);
			const redirectResponseMessage = yield redirectResponse.json();
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				if (redirectResponseMessage.success) {
					yield put(replaceCategorySuccess(responseMessage));
					yield put(categorySelect(null));
				} else {
					yield put(categoryRedirectionFailure(redirectResponseMessage));
				}
			} else {
				yield put(replaceCategoryFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(deleteCategoryFailure(err));
	}
}

export default function* categoriesData() {
	yield takeLatest(CREATE_CATEGORY, createCategory);
	yield takeLatest(UPDATE_CATEGORY, updateCategory);
	yield takeLatest(DELETE_CATEGORY, deleteCategory);
	yield takeLatest(REPLACE_CATEGORY, replaceCategory);
}
