import React from 'react';
import Modal from 'react-modal';
import T from 'prop-types';
import { Button } from '../Styles';
import ToastMessage from '../ToastMessage';

const ConfirmationModal = (props) => {
	const {
		openDialog, closeDialog, message, confirm, showToolTipMessage, showToolTipMessageVal, closeToolTipMessage,
	} = props;
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
		},
	};

	return (
		<Modal
			isOpen={openDialog}
			onRequestClose={closeDialog}
			style={customStyles}
			contentLabel="Confirmation Dialog"
			overlayClassName="modal-overlay"
		>
			<>
				{!showToolTipMessage && <div>
					<p dangerouslySetInnerHTML={{ __html: message }} />
					<div className="text-right">
						<Button success className="mr-3" onClick={confirm}>YES</Button>
						<Button primary onClick={closeDialog}>NO</Button>
					</div>
				</div>}
				{showToolTipMessage && <ToastMessage
					toastData={showToolTipMessageVal}
					unmount={closeToolTipMessage}
				/>}
			</>
		</Modal>
	);
};

ConfirmationModal.propTypes = {
	openDialog: T.bool,
	closeDialog: T.func,
	message: T.string,
	confirm: T.func,
	showToolTipMessage: T.bool,
	showToolTipMessageVal: T.object,
	closeToolTipMessage: T.func,
};

export default ConfirmationModal;
