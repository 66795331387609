import { FETCH_STORY_URL, FETCH_STORY_URL_SUCCESS, FETCH_STORY_URL_FAILURE } from './type';

export function fetchStoryUrl(storyId) {
	return {
		type: FETCH_STORY_URL,
		storyId,
	};
}

export function fetchStoryUrlSuccess(storyUrl) {
	return {
		type: FETCH_STORY_URL_SUCCESS,
		storyUrl,
	};
}

export function fetchStoryUrlFailure(error) {
	return {
		type: FETCH_STORY_URL_FAILURE,
		error,
	};
}
