import React, { Fragment } from 'react';
import styled from 'styled-components';
import T from 'prop-types';
import { ImageHolder } from './index';

const YOURSTORY_URL = process.env.REACT_APP_SERVICE_PWA;

const TextHolder = styled.div`
		padding: 8px 15px;
`;

const SearchTitle = styled.div`
		color: #1a0dab;
		font-size: 1.2rem;
		max-height: 58px;
		min-height: 28px;
		overflow: hidden;
`;

const SearchLink = styled.div`
		color: #006621;
		font-size: 0.9rem;
`;

const Card = styled.div`
		width: 400px;
		box-shadow: 1px 2px 3px 0px #DDDDDD;
`;

const Content = styled.div`
		font-size: 0.9rem;
		color: grey;
		overflow: scroll;
		max-height: 60px;
`;

const SearchPreviewCard = (props) => <>
	<Card>
		<ImageHolder key="search" imageUrl={props.imageUrl} fileCallback={props.fileCallback} />
		<TextHolder>
			<SearchTitle>{props.searchTitle}</SearchTitle>
			{props.searchSlug ? <SearchLink>{`${YOURSTORY_URL} > series > ${props.searchSlug}`}</SearchLink> : null}
			<Content className="mt-2">{props.searchContent}</Content>
		</TextHolder>
	</Card>
</>;

// SearchPreviewCard.defaultProps = {
//		 searchTitle: 'This is a title',
//		 searchSlug: 'series-slug-can-also-be-bigger-than-this-one-here',
//		 searchContent: 'This is content can be bigger than the text as well and can go beyond things limits, This is content can be bigger than the text as well and can go beyond things limits',
//		 fileCallback: () => {
//				 console.log('File callback');
//		 }
// }

SearchPreviewCard.propTypes = {
	imageUrl: T.string,
	searchTitle: T.string,
	searchSlug: T.string,
	fileCallback: T.func,
	searchContent: T.string,
};

export default SearchPreviewCard;
