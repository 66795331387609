import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { ListHeaderWrapper } from '../../BoniManager/BoniProductCategory/style';

export const RedemptionDoneListHeader = () => (
	<ListHeaderWrapper>
		<Col xs={false} md={1} className="text-center">
			S.No
		</Col>
		<Col xs={6} md={2} className="text-center">
			User Name
		</Col>
		<Col xs={6} md={2} className="text-center">
			Claim status
		</Col>
		<Col xs={false} md={2} className="text-center">
			Claim Type
		</Col>
		{/* <Col xs={false} md={1} className="text-center">
			Redemption Allowed Count
		</Col> */}
		<Col xs={false} md={1} className="text-center">
			Created At
		</Col>
		<Col xs={false} md={1} className="text-center">
			End At
		</Col>
		{/* <Col xs={false} md={2} className="text-center">
			Action
		</Col> */}
	</ListHeaderWrapper>
);

export default RedemptionDoneListHeader;
