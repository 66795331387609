import React from 'react';
import PropTypes from 'prop-types';

const AddPlusButton = (props) => (<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 357 357" style={{ enableBackground: 'new 0 0 357 357' }} xml={{ space: 'preserve' }} width={props.width} height={props.height}>
	<g>
		<g>
			<g id="add">
				<path d="M357,204H204v153h-51V204H0v-51h153V0h51v153h153V204z" data-original="#000000" className="active-path" data-old_color={props.fill} fill={props.fill} />
			</g>
		</g>
	</g>
</svg>
);

AddPlusButton.defaultProps = {
	height: '24px',
	width: '24px',
	fill: '#e5002d!important',
};

AddPlusButton.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string,
	fill: PropTypes.string,
};

export default AddPlusButton;
