export const FETCH_DESK_STORIES_BY_BRAND_AND_STATUS = 'FETCH_DESK_STORIES_BY_BRAND_AND_STATUS';
export const FETCH_DESK_STORIES_BY_BRAND_AND_STATUS_SUCCESS = 'FETCH_DESK_STORIES_BY_BRAND_AND_STATUS_SUCCESS';
export const FETCH_DESK_STORIES_BY_BRAND_AND_STATUS_FAILURE = 'FETCH_DESK_STORIES_BY_BRAND_AND_STATUS_FAILURE';

export const SET_DESK_PAGE = 'SET_DESK_PAGE';
export const SET_DESK_STORIES_STATUS = 'SET_DESK_STORIES_STATUS';

export const FETCH_DESK_BRANDS = 'FETCH_DESK_BRANDS';
export const FETCH_DESK_BRANDS_SUCCESS = 'FETCH_DESK_BRANDS_SUCCESS';
export const FETCH_DESK_BRANDS_FAILURE = 'FETCH_DESK_BRANDS_FAILURE';

export const FETCH_PENDING_APPROVAL_COUNT = 'FETCH_PENDING_APPROVAL_COUNT';
export const FETCH_PENDING_APPROVAL_COUNT_SUCCESS = 'FETCH_PENDING_APPROVAL_COUNT_SUCCESS';
export const FETCH_PENDING_APPROVAL_COUNT_FAILURE = 'FETCH_PENDING_APPROVAL_COUNT_FAILURE';

export const UPDATE_DESK_STORY_STATUS = 'UPDATE_DESK_STORY_STATUS';
export const UPDATE_DESK_STORY_STATUS_SUCCESS = 'UPDATE_DESK_STORY_STATUS_SUCCESS';
export const UPDATE_DESK_STORY_STATUS_FAILURE = 'UPDATE_DESK_STORY_STATUS_FAILURE';

export const DISABLE_DESK_SEARCH_FLAG = 'DISABLE_DESK_SEARCH_FLAG';

export const RUN_DESK_SEARCH = 'RUN_DESK_SEARCH';
export const RUN_DESK_SEARCH_SUCCESS = 'RUN_DESK_SEARCH_SUCCESS';
export const RUN_DESK_SEARCH_FAILURE = 'RUN_DESK_SEARCH_FAILURE';

export const SEARCH_STORIES_BY_TITLE = 'SEARCH_STORIES_BY_TITLE';
export const SEARCH_STORIES_BY_TITLE_SUCCESS = 'SEARCH_STORIES_BY_TITLE_SUCCESS';
export const SEARCH_STORIES_BY_FAILURE = 'SEARCH_STORIES_BY_TITLE_FAILURE';

export const SEARCH_STORIES_BY_DATE = 'SEARCH_STORIES_BY_DATE';
export const SEARCH_STORIES_BY_DATE_SUCCESS = 'SEARCH_STORIES_BY_DATE_SUCCESS';
export const SEARCH_STORIES_BY_DATE_FAILURE = 'SEARCH_STORIES_BY_DATE_FAILURE';

export const SEARCH_STORIES_BY_AUTHOR = 'SEARCH_STORIES_BY_AUTHOR';
export const SEARCH_STORIES_BY_AUTHOR_SUCCESS = 'SEARCH_STORIES_BY_AUTHOR_SUCCESS';
export const SEARCH_STORIES_BY_AUTHOR_FAILURE = 'SEARCH_STORIES_BY_AUTHOR_FAILURE';

export const DISPLAY_DESK_COMMENT_BOX = 'DISPLAY_DESK_COMMENT_BOX';
export const HIDE_DESK_COMMENT_BOX = 'HIDE_DESK_COMMENT_BOX';
export const ADD_DESK_COMMENT = 'ADD_DESK_COMMENT';
export const SELECT_DESK_BRAND = 'SELECT_DESK_BRAND';

export const CHECK_PLAGIARISM = 'CHECK_PLAGIARISM';
