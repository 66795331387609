export const LIVE_FEED_COMMENTS_BASE_URL = '/api/v2/rw/liveFeedCard';
export const LIVE_FEED_STATUS_BASE_URL = '/api/v2/rw/updatestatus/';

export const FILTER_LIVE_FEED_COMMENTS = 'FILTER_LIVE_FEED_COMMENTS';
export const FETCH_LIVE_FEED_COMMENTS_SUCCESS = 'FETCH_LIVE_FEED_COMMENTS_SUCCESS';
export const FETCH_LIVE_FEED_COMMENTS_FAILURE = 'FETCH_LIVE_FEED_COMMENTS_FAILURE';
export const FETCH_LIVE_FEED_COMMENTS = 'FETCH_LIVE_FEED_COMMENTS';
export const FILTER_LIVE_FEED_COMMENTS_SUCCESS = 'FILTER_LIVE_FEED_COMMENTS_SUCCESS';
export const FILTER_LIVE_FEED_COMMENTS_FAILURE = 'FILTER_LIVE_FEED_COMMENTS_FAILURE';
export const SET_LIVE_FEED_COMMENTS_PAGE = 'SET_LIVE_FEED_COMMENTS_PAGE';
export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
export const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
export const UPDATE_ACTIONS_ERROR = 'UPDATE_ACTIONS_ERROR';
export const UPDATE_ACTIONS_SUCCESS = 'UPDATE_ACTIONS_SUCCESS';
export const UPDATE_ACTIONS_LOADER = 'UPDATE_ACTIONS_LOADER';
export const SET_ACTIONS_MESSAGE = 'SET_ACTIONS_MESSAGE';
export const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
export const SET_ELEMENTS_BLURRED = 'SET_ELEMENTS_BLURRED';
export const SAVING_MESSAGE = { intent: 'info', message: 'Saving data...' };
export const SAVING_SUCCESS_MESSAGE = { intent: 'success', message: 'Data Saved Successfully' };
export const SAVING_FAILURE_MESSAGE = { intent: 'danger', message: 'Data Save Failed. Something went wrong. Try Again!' };
