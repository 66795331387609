import { Map } from 'immutable';
import {
	SET_ERROR_STATE, SET_LOADING_STATE, SET_TAB_STATE, SET_API_STATE, SET_TAB_DATA_STATE, SET_SEARCH_STORIES, SET_SAVE_STATE, SET_FILE_STATE, SET_PAGE_MODE, SET_INITIAL_STATE,
} from './type';

const { formatDataForState } = require('../../utils/utility_function');

const initialState = {
	loading: {
		status: false,
		type: null,
	},
	save: {
		status: false,
		type: null,
		message: null,
		loading: false,
	},
	error: {
		status: false,
		type: null,
		message: null,
	},
	tab: null,
	API: null,
	tabData: {
		compose: null,
		finalize: null,
		preview: null,
		manageContent: null,
		analytics: null,
		mediaPicker: null,
	},
	searchModal: {
		open: false,
		loading: null,
		selected: null,
		list: null,
		searchList: null,
		title: '',
		offset: 0,
		listHash: {},
	},
	pageMode: null,
};

const initialStateImt = Map(initialState);

function SeriesCreateReducer(state = initialStateImt, action) {
	switch (action.type) {
	case SET_ERROR_STATE: return state.set('error', action.value);
	case SET_LOADING_STATE: return state.set('loading', action.value);
	case SET_TAB_STATE: return state.set('tab', action.value);
	case SET_API_STATE: return state.set('API', action.value);
	case SET_TAB_DATA_STATE: return state.set('tabData', formatDataForState(state.get('tabData'), action.value));
	case SET_SEARCH_STORIES: return state.set('searchModal', formatDataForState(state.get('searchModal'), action.value));
	case SET_SAVE_STATE: return state.set('save', formatDataForState(state.get('save'), action.value));
	case SET_FILE_STATE: {
		let tabData = state.get('tabData');
		const { key } = action.value;
		const { subKey } = action.value;
		const { file } = action.value;
		const { imagePreviewUrl } = action.value;
		if (!tabData) {
			tabData = {};
		}

		if (!tabData[key]) {
			tabData[key] = {};
		}

		if (!tabData[key][subKey]) {
			tabData[key][subKey] = {};
		}

		tabData[key][subKey].file = file;
		tabData[key][subKey].imagePreviewUrl = imagePreviewUrl;
		return state.set('tabData', tabData);
	}
	case SET_PAGE_MODE: return state.set('pageMode', action.value);
	case SET_INITIAL_STATE: return state
		.set('loading', initialState.loading)
		.set('save', initialState.save)
		.set('error', initialState.error)
		.set('tab', initialState.tab)
		.set('API', initialState.API)
		.set('tabData', initialState.tabData)
		.set('searchModal', initialState.searchModal)
		.set('pageMode', initialState.pageMode);
	default: return state;
	}
}

export default SeriesCreateReducer;
