import { defineMessages } from 'react-intl';

export default defineMessages({
	nameErr: {
		id: 'creatorstudio.components.FormInstance.nameErr',
		defaultMessage: '* Name cannot be empty',
	},
	slugEmptyErr: {
		id: 'creatorstudio.components.FormInstance.slugEmptyErr',
		defaultMessage: '* Slug cannot be empty',
	},
	slugCaseErr: {
		id: 'creatorstudio.components.FormInstance.slugCaseErr',
		defaultMessage: '* Slug cannot contain capital letters',
	},
	slugUniqueErr: {
		id: 'creatorstudio.components.FormInstance.slugUniqueErr',
		defaultMessage: '* This slug is already taken. Pick a unique slug',
	},
	slugSymbolErr: {
		id: 'creatorstudio.components.FormInstance.slugSymbolErr',
		defaultMessage: '* Slug cannot contain symbols',
	},
	stateErr: {
		id: 'creatorstudio.components.FormInstance.stateErr',
		defaultMessage: '* State cannot be empty',

	},
	countryErr: {
		id: 'creatorstudio.components.FormInstance.countryErr',
		defaultMessage: '* Country cannot be empty',

	},

});
