import React, { useEffect } from 'react';
import Props from 'prop-types';
import { Row, Col } from 'react-styled-flexboxgrid';
import { WrapperContainer } from '../../components/Styles';
import PendingApprovalJobsList from './PendingApprovalJobs';
import ApprovedActiveJobsList from './ApprovedAndActiveJobs';
// import HiredJobsList from './HiredJobs';
import RejectedJobsList from './RejectedJobs';

const AdminJobsPage = (props) => {
	const { pageTitle } = props;
	const [selectedStatus, setSelectedStatus] = React.useState('Pending Approval');
	useEffect(() => {
		pageTitle('adminJobs');
	});

	const onStatusChange = (event) => {
		setSelectedStatus(event);
	};

	return (
		<WrapperContainer>
			<Row>
				<Col xs={12} md={12}>
					<Row className="mt-3 storylist-tabs">
						<Col xs={1.7} className={`nav-text ${selectedStatus === 'Pending Approval' ? 'nav-b-a' : 'nav-b'}`} onClick={() => onStatusChange('Pending Approval')}>Pending Approval</Col>
						<Col xs={1.7} className={`nav-text ${selectedStatus === 'Approved & Active' ? 'nav-b-a' : 'nav-b'}`} onClick={() => onStatusChange('Approved & Active')}>Approved & Active </Col>
						<Col xs={1.7} className={`nav-text ${selectedStatus === 'Rejected' ? 'nav-b-a' : 'nav-b'}`} onClick={() => onStatusChange('Rejected')}>Rejected</Col>
						{/* <Col sm={1.7} className={`nav-text ${selectedStatus === 'Hired' ? 'nav-b-a' : 'nav-b'}`} onClick={() => onStatusChange('Hired')}>Hired </Col> */}
					</Row>
					<Row className="mt-4">
						<Col xs={12}>
							{selectedStatus === 'Pending Approval' && <PendingApprovalJobsList {...props} status={1} />}
							{selectedStatus === 'Approved & Active' && <ApprovedActiveJobsList {...props} status={2} />}
							{selectedStatus === 'Rejected' && <RejectedJobsList {...props} status={3} />}
							{/* {selectedStatus === 'Hired' && <HiredJobsList status={3} />} */}

							{/* {
                                renderStoryListItem()
                            } */}
							{/* {
                                this.state.loadMore
                                    ? <LoadingIcon />
                                    : null
                            } */}
						</Col>
					</Row>
				</Col>
			</Row>
		</WrapperContainer>
	);
};

AdminJobsPage.propTypes = {
	pageTitle: Props.func,
	// history: Props.object,
};
export default AdminJobsPage;
