import { Map } from 'immutable';
import {
	UPDATE_FROM_DATA,
	UPDATE_SPONSORS_LIST,
	ADD_SPONSOR,
	REMOVE_SPONSOR,
	FETCH_BRAND_SUCCESS,
	FETCH_USER_BRANDS,
	CLEAR_THUMBNAIL,
	CREATE_EVENT,
	CREATE_EVENT_SUCCESS,
	CREATE_EVENT_FAILURE,
	UPDATE_FORM_MESSAGE,
	FETCH_EVENT_FAILURE,
	FETCH_EVENT_DATA_SUCCESS,
	UPDATE_EVENT_SUCCESS,
	UPDATE_EVENT_BY_ID,
	DELETE_EVENT,
	DELETE_EVENT_FAILURE,
} from './type';

import {
	CREATE_MESSAGE,
	CREATE_SUCCESS_MESSAGE,
	CREATE_FAILURE_MESSAGE,
	FETCH_EVENT_FAILURE_MESSAGE,
	UPDATE_SUCCESS_MESSAGE,
	UPDATE_MESSAGE,
	EVENT_DELETE_FAILURE_MESSAGE,
	EVENT_DELETE_LOAD_MESSAGE,
} from './constants';

const dummySponsor = {
	name: '',
	website: '',
	logo: {},
	image: null,
};

const initialState = Map({
	userbrands: [],
	formData: {
		title: '',
		description: '',
		thumbnail: {
			url: '',
			image: null,
			imageKey: null,
		},
		heroImage: {
			url: '',
			image: null,
			imageKey: null,
		},
		keywords: '',
		additionalThumbnails: [],
		cta: {
			link: '',
			text: '',
		},
		speakers: [],
		brand: '',
		location: 'Online',
		video: {},
		featured: false,
		masterFeatured: false,
		partnership: false,
		techSparks: false,
		startTime: '',
		endTime: '',
		sponsors: [{ ...dummySponsor }],
		id: null,
		category: 'EVENT',
		rewindCta: '',
	},
	loading: false,
	formMessage: null,

});

function eventEditorReducer(state = initialState, action) {
	switch (action.type) {
	case UPDATE_FROM_DATA: {
		const formData = { ...state.get('formData') };
		const { changedData } = action;
		const key = changedData.name;
		if (key === 'thumbnail' || key === 'cta' || key === 'heroImage') {
			formData[key] = { ...formData[key], ...action.changedData.value };
		} else if (key === 'additionalThumbnails') {
			if (formData.additionalThumbnails) {
				formData.additionalThumbnails[changedData.index] = { ...formData.additionalThumbnails[changedData.index], ...action.changedData.value };
			} else {
				formData.additionalThumbnails = [];
				formData.additionalThumbnails[changedData.index] = { ...action.changedData.value };
			}
		} else if (key === 'category' && changedData.value === 'MEETUP') {
			formData.masterFeatured = false;
			formData[key] = changedData.value;
		} else { formData[key] = changedData.value; }
		return state
			.set('formData', formData);
	}

	case UPDATE_SPONSORS_LIST: {
		const newState = { ...state.get('formData') };
		newState.sponsors[action.index] = { ...newState.sponsors[action.index], ...action.changedData.value };

		return state
			.set('formData', newState);
	}

	case ADD_SPONSOR: {
		const newState = { ...state.get('formData') };
		if (!newState.sponsors) newState.sponsors = [];
		newState.sponsors.push(dummySponsor);
		return state
			.set('formData', newState);
	}

	case REMOVE_SPONSOR: {
		const newState = { ...state.get('formData') };
		newState.sponsors.splice(action.index, 1);
		return state
			.set('formData', newState);
	}

	case FETCH_BRAND_SUCCESS: {
		return state
			.set('userbrands', action.brands)
			.set('loading', false);
	}

	case FETCH_USER_BRANDS: {
		return state
			.set('loading', true);
	}

	case FETCH_EVENT_FAILURE: {
		return state
			.set('loading', false)
			.set('formMessage', FETCH_EVENT_FAILURE_MESSAGE);
	}

	case CLEAR_THUMBNAIL: {
		const newState = { ...state.get('formData') };
		const { hoc, index } = action;
		if (hoc === 'additionalThumbnails') {
			newState.additionalThumbnails[index] = { url: '', image: null };
		} else {
			newState[hoc] = { url: '', image: null };
		}
		return state
			.set('formData', newState);
	}

	case CREATE_EVENT: {
		return state
			.set('formMessage', CREATE_MESSAGE)
			.set('loading', true);
	}

	case CREATE_EVENT_SUCCESS: {
		return state
			.set('loading', false)
			.set('formMessage', CREATE_SUCCESS_MESSAGE)
			.set('formData', action.data.data);
	}

	case UPDATE_EVENT_BY_ID: {
		return state
			.set('formMessage', UPDATE_MESSAGE)
			.set('loading', true);
	}

	case UPDATE_EVENT_SUCCESS: {
		return state
			.set('loading', false)
			.set('formMessage', UPDATE_SUCCESS_MESSAGE);
	}

	case FETCH_EVENT_DATA_SUCCESS: {
		return state
			.set('loading', false)
			.set('formData', action.data.data);
	}

	case CREATE_EVENT_FAILURE:
		return state
			.set('formMessage', CREATE_FAILURE_MESSAGE)
			.set('loading', false);

	case UPDATE_FORM_MESSAGE:
		return state
			.set('formMessage', action.message);

	case DELETE_EVENT:
		return state
			.set('loading', true)
			.set('formMessage', EVENT_DELETE_LOAD_MESSAGE);

	case DELETE_EVENT_FAILURE:
		return state
			.set('loading', false)
			.set('formMessage', EVENT_DELETE_FAILURE_MESSAGE);

	default:
		return state;
	}
}

export default eventEditorReducer;
