import styled, { css } from 'styled-components';

const ToastWrapper = styled.div`
		width:100%;
		min-height: 60px;
		border: 1px solid;
		border-radius: 5px;
		margin: 1rem auto;
		margin-bottom: 30px;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		transition: all .1s ease-in-out;
		${(props) => props.intent === 'success' && css`
				background-color: #d8fdd8;
				border-color: #7abf7a;
				color: #366936;
		`}
		${(props) => props.intent === 'info' && css`
				background-color: #f7f7f7;
				border-color: #969696;
				color: #4e5a4e;
		`}
		${(props) => props.intent === 'danger' && css`
				background-color: #e5002d;
				border-color: #e5002d;
				color: #fff;
		`}
`;

const CloseButton = styled.button`
		background: transparent;
		right: 0;
		top: 0;
		position: absolute;
		margin-bottom: 2rem;
`;

const Message = styled.p`
		margin-top: 1rem;
		margin-bottom: 1rem;
`;

export {
	ToastWrapper,
	CloseButton,
	Message,
};
