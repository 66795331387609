import { FETCH_MARKETING_DATA, FETCH_MARKETING_DATA_SUCCESS, FETCH_MARKETING_DATA_FAILURE } from './type';

export function fetchMarketingData() {
	return {
		type: FETCH_MARKETING_DATA,
	};
}

export function fetchMarketingDataSuccess(data) {
	return {
		type: FETCH_MARKETING_DATA_SUCCESS,
		data,
	};
}

export function fetchMarketingDataFailure(error) {
	return {
		type: FETCH_MARKETING_DATA_FAILURE,
		error,
	};
}
