import React, { useState, useEffect, useReducer } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import Select from 'react-select';
import { Button, Input } from '../Styles';
import ToastMessage from '../ToastMessage';

import { validateImageFormat } from '../../utils/common';
import ImageHolder from './ImageHolder';

const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
const UPDATE_ERRORS = 'UPDATE_ERRORS';
const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
const SET_FORM_DATA = 'SET_FORM_DATA';
const CLEAR_DATA = 'CLEAR_DATA';
const TYPES = [{ label: 'TWEET', value: 'TWEET' }, { label: 'ARTICLE', value: 'ARTICLE' }];
const COMMUNITY_CONTENT_API = '/api/v2/rw/community-content';
const SAVING_MESSAGE = { intent: 'info', message: 'Saving...' };
const SAVING_FAILURE_MESSAGE = { intent: 'danger', message: 'Something went wrong!' };
const AUTO_FILL_FAIL = { intent: 'danger', message: 'Unable to fetch all data from the URL' };

const PreviewDiv = styled.div`
		max-height: 20rem;
		overflow: scroll;
		margin: 1rem;
		.twitter-embed{
				pointer-events: none;
		}
`;

const ImageWrapper = styled.div`
		padding-left: 2rem;
		@media (max-width: 768px) {					 
				padding-left: 0;
		}
`;

const initialState = {
	formData: {
		title: null,
		url: null,
		thumbnail: null,
		type: { label: 'TWEET', value: 'TWEET' },
		source: null,
		brand: null,
	},
	blurredElements: {
		title: null,
		url: null,
		thumbnail: null,
		type: null,
		source: null,
		brand: null,
	},
	errors: {
		title: null,
		url: null,
		thumbnail: null,
		type: null,
		source: null,
		brand: null,
	},
	formMessage: null,
};

function updateFormData(changedData) {
	return {
		type: UPDATE_FORM_DATA,
		changedData,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function setFormMessage(message) {
	return {
		type: SET_FORM_MESSAGE,
		message,
	};
}

function setFormData(data) {
	return {
		type: SET_FORM_DATA,
		data,
	};
}

function clearData() {
	return {
		type: CLEAR_DATA,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case SET_FORM_DATA:
		return { ...state, formData: { ...action.data } };
	case UPDATE_FORM_DATA: {
		const formData = { ...state.formData };
		const { changedData } = action;
		if (changedData[0].name === 'type') {
			const newState = {
				...initialState,
				formData: { ...initialState.formData, type: changedData[0].value },
			};
			return newState;
		}
		changedData.forEach((item) => {
			formData[item.name] = item.value;
		});

		return { ...state, formData };
	}

	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		if (element === 'twitterUrl') {
			blurredElements.url = true;
			blurredElements.twitterPreview = true;
		} else {
			blurredElements[element] = true;
		}
		return { ...state, blurredElements };
	}
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };

	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			title: true,
			url: true,
			thumbnail: true,
			type: true,
			source: true,
			brand: true,
			twitterPreview: true,
		};
		return { ...state, blurredElements };
	}

	case SET_FORM_MESSAGE:
		return { ...state, formMessage: action.message };

	case CLEAR_DATA: {
		return { ...initialState };
	}

	default:
		return state;
	}
}

const CommunityContentForm = (props) => {
	const { open, close, data } = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		formData, blurredElements, errors, formMessage,
	} = state;
	const [actionsDisabled, disableActions] = useState(false);
	const filtered = props.userBrands && props.userBrands.filter((item) => ['the-decrypting-story'].includes(item.slug));
	const BRANDS = filtered && filtered.length ? filtered.map((item) => ({ value: item.id, label: item.name })) : [];

	useEffect(() => {
		dispatch(setFormData(data));
	}, [data && data.id]);

	useEffect(() => {
		const { type, twitterPreview, id } = formData;
		if (type.value === 'TWEET' && !twitterPreview && id) {
			onTwitterBlur();
		}
	});

	useEffect(() => {
		getErrors();
	}, [formData, blurredElements]);

	function onInputChange(e) {
		const { name, value } = e.target;
		return dispatch(updateFormData([{ name, value }]));
	}

	function validateForm() {
		const {
			url, title, source, brand, type, twitterPreview, thumbnail,
		} = formData;
		const allChecks = {
			url: !url || url.length < 1,
			brand: !(brand && brand.value),
		};
		const articleChecks = {
			title: !title || title.length < 1,
			thumbnail: !(thumbnail && thumbnail.url),
			source: !source || source.length < 1,
		};
		const twitterChecks = {
			twitterPreview: !twitterPreview || twitterPreview.startsWith('Something'),
		};
		if (type && type.value === 'TWEET') {
			Object.assign(allChecks, twitterChecks);
		} else {
			Object.assign(allChecks, articleChecks);
		}
		return allChecks;
	}

	function handleBlur(element) {
		dispatch(updateBlurredElement(element));
	}

	function getErrors() {
		const validationErrors = validateForm(formData);
		const errors = Object.keys(validationErrors).reduce((acc, curr) => {
			acc[curr] = validationErrors[curr] && blurredElements[curr];
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	}

	async function onFormSubmit(e) {
		e.preventDefault();
		const validation = validateForm(formData);
		const validated = !Object.keys(validation).some((i) => validation[i]);
		if (validated) {
			saveContent();
		} else {
			dispatch(setAllElementsBlurred());
		}
	}

	async function saveContent() {
		try {
			const {
				url, title, source, brand, type, id, thumbnail,
			} = formData;
			disableActions(true);
			dispatch(setFormMessage(SAVING_MESSAGE));
			const payload = new FormData();
			payload.append('id', id);
			payload.append('title', title);
			payload.append('url', url);
			payload.append('type', type.value);
			payload.append('brand', brand.value);
			if (type.value === 'ARTICLE') {
				payload.append('source', source);
				payload.append('thumbnailFile', thumbnail.file);
			}

			const URL = `${COMMUNITY_CONTENT_API}/${id ? 'update' : 'create'}`;
			const options = {
				method: id ? 'PUT' : 'POST',
				credentials: 'include',
				body: payload,
			};
			const response = await fetch(URL, options);
			const parsed = await response.json();
			disableActions(false);
			if (parsed.success) {
				setTimeout(() => closeModal(false), 1000);
			} else {
				dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			}
		} catch (err) {
			console.log('Error saving', err);
			dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			disableActions(false);
		}
	}

	async function onURLblur() {
		const urlData = {
			title: null,
			thumbnail: null,
			file: null,
			url: null,
		};
		try {
			disableActions(true);

			dispatch(setFormMessage({ intent: 'info', message: 'Fetching data from URL...' }));

			const response = await fetch(`/api/v2/rw/community-content/scrape?url=${encodeURIComponent(formData.url)}`);
			const data = await response.json();
			if (data.error) {
				throw new Error(data.error);
			}

			urlData.title = data.title;
			urlData.imageBase64 = data.imageBase64;
			if (urlData.title && urlData.imageBase64) {
				const fileData = await fetch(data.imageBase64);
				const fileBlob = await fileData.blob();
				urlData.file = new File([fileBlob], `${data.title.substr(0, 10).replace(/[^A-Za-z0-9-]/, '-')}.png`, { type: 'image/png' });
				dispatch(setFormMessage(null));
			} else {
				dispatch(setFormMessage(AUTO_FILL_FAIL));
			}
			const changedData = [{ name: 'title', value: urlData.title }, { name: 'thumbnail', value: { url: urlData.imageBase64, file: urlData.file } }];

			dispatch(updateFormData(changedData));

			disableActions(false);
		} catch (err) {
			console.log('Error fetching data', err);
			dispatch(setFormMessage({ intent: 'danger', message: 'Error auto fetching data, please add details manually' }));
			disableActions(false);
		}
	}

	async function onUpload(e) {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		if (await validateImageFormat(file)) {
			reader.onloadend = () => {
				dispatch(updateFormData([{
					name: 'thumbnail',
					value: {
						url: reader.result,
						file,
					},
				}]));
			};
			reader.readAsDataURL(file);
		}
	}

	function onUploadClick(e) {
		e.preventDefault();
		const thumbnail = document.getElementById('thumbnail');
		thumbnail.click();
	}

	function onImageLoad() {
		if (formData.thumbnail.file) {
			dispatch(updateFormData([{
				name: 'thumbnail',
				value: {
					...formData.thumbnail,
				},
			}]));
		}
	}

	function closeModal(unchanged) {
		dispatch(clearData());
		close(unchanged);
	}

	function renderFormMessage() {
		if (formMessage) {
			return (
				<ToastMessage
					toastData={formMessage}
					unmount={() => dispatch(setFormMessage(null))}
				/>
			);
		}
	}

	const renderTweetFields = () => {
		const script = document.createElement('script');
		script.src = 'https://platform.twitter.com/widgets.js';
		if (document.getElementsByClassName('twitter-embed').length) document.getElementsByClassName('twitter-embed')[0].appendChild(script);
		return <>
			<Row className="mb-3">
				<Col xs={12} sm={3}>
					URL * {formData && formData.url && !errors.url && formData.twitterPreview && formData.twitterPreview.startsWith('Something')
					? <i
							className="fa fa-refresh fa-sm ml-2 brand-text pointer"
							title="Click to re-fetch preview"
							onClick={onTwitterBlur}
					/>
					: null}
				</Col>
				<Col xs={12} sm={12}>
					<Input
						name="url"
						type="url"
						className="form-control"
						placeholder="URL of the tweet"
						value={formData && formData.url ? formData.url : ''}
						onChange={onInputChange}
						onBlur={formData && formData.url ? onTwitterBlur : () => handleBlur('twitterUrl')}
						disabled={actionsDisabled}
					/>
					{
						// TBD use new component after merge
						errors.url
							? <p className="error error-text text-normal">
								* URL cannot be empty
							</p>
							: errors.twitterPreview
								? <p className="error error-text text-normal">
									* URL should be a valid Tweet URL
								</p>
								: null
					}
				</Col>
			</Row>
			{formData.twitterPreview
				? <Row className="mb-3">
					<Col xs={12} sm={3}>
						Preview
					</Col>

					<Col xs={12} sm={12}>
						<PreviewDiv>
							<div className="twitter-embed">
								<div
									dangerouslySetInnerHTML={{
										__html: formData.twitterPreview,
									}}
								/>
							</div>
						</PreviewDiv>
					</Col>
				</Row>
				: null}
		</>;
	};

	async function onTwitterBlur() {
		let html = 'Something doesn\'t look right. Please recheck the URL!';
		try {
			if (formData.url && formData.url.match(/http(?:s)?:\/\/(?:www)?twitter\.com\/([a-zA-Z0-9_]+)/)) {
				dispatch(updateFormData([{ name: 'twitterPreview', value: 'Loading...' }]));
				const reqObj = new FormData();
				reqObj.append('type', 'twitter');
				reqObj.append('link', formData.url);
				const response = await fetch('/api/v2/rw/stories/oembed', {
					method: 'POST',
					body: reqObj,
				});
				const oEmbed = await response.json();
				if (!oEmbed.error) {
					html = unescape(oEmbed.html);
				}
			}
			dispatch(updateFormData([{ name: 'twitterPreview', value: html }]));
		} catch (err) {
			console.log('Error fetching oembed data', err);
			dispatch(updateFormData([{ name: 'twitterPreview', value: 'Something went wrong' }]));
		}
	}

	const renderArticleFields = () => <><Row className="mb-3">
		<Col xs={12} sm={3}>
			URL * {formData && formData.url && !errors.url
			? <i
					className="fa fa-refresh fa-sm ml-2 brand-text pointer"
					title="Click to auto-fetch data from URL"
					onClick={onURLblur}
			/>
			: null}
		</Col>
		<Col xs={12} sm={12}>
			<Input
				name="url"
				type="url"
				className="form-control"
				placeholder="URL of the article"
				value={formData && formData.url ? formData.url : ''}
				onChange={onInputChange}
				onBlur={formData && formData.url ? onURLblur : () => handleBlur('url')}
				disabled={actionsDisabled}
			/>
			{
				errors.url
					? <p className="error error-text text-normal">
						* URL cannot be empty
					</p>
					: null
			}
		</Col>
	</Row>

		<Row className="mb-3">
			<Col xs={12} sm={3}>
				Title *
			</Col>
			<Col xs={12} sm={12}>
				<Input
					name="title"
					type="text"
					className="form-control"
					placeholder="Title to be displayed"
					value={formData && formData.title ? formData.title : ''}
					onChange={onInputChange}
					disabled={actionsDisabled}
					onBlur={() => handleBlur('title')}
					maxLength={150}
				/>
				<div className="w_100 text-right" style={{ color: '#aaa' }}>
					{`${formData.title ? formData.title.length : '0'}/150`}
				</div>
				{
					errors.title
						? <p className="error error-text text-normal">
							* Title cannot be empty
						</p>
						: null
				}
			</Col>
		</Row>

		<Row className="mb-3">
			<Col xs={12} sm={3}>
				Source *
			</Col>
			<Col xs={12} sm={12}>
				<Input
					name="source"
					type="text"
					className="form-control"
					placeholder="Source of the article"
					value={formData && formData.source ? formData.source : ''}
					onChange={onInputChange}
					disabled={actionsDisabled}
					onBlur={() => handleBlur('source')}
				/>
				{
					errors.source
						? <p className="error error-text text-normal">
							* Source cannot be empty
						</p>
						: null
				}
			</Col>
		</Row>

		<Row className="mb-3">
			<Col xs={12}>
				Thumbnail *
			</Col>
			<Col>
				<ImageWrapper className="mb-3">
					<Input
						id="thumbnail"
						type="file"
						style={{ display: 'none' }}
						name="thumbnail"
						onChange={onUpload}
						accept="image/png, image/jpeg"
						disabled={actionsDisabled}
					/>
					<ImageHolder
						showImageUploader={onUploadClick}
						imageUrl={formData && formData.thumbnail ? formData.thumbnail.url : null}
						onImageLoad={onImageLoad}
						squareDimension="13rem"
					/>
					{
						errors.thumbnail
							? <p className="error error-text text-normal">
								* Thumbnail cannot be empty
							</p>
							: null
					}
				</ImageWrapper>
			</Col>
		</Row>
	</>;
	return (
		<Modal
			style={{ content: { overflow: 'scroll', maxHeight: '95vh' } }}
			isOpen={open}
			onRequestClose={closeModal}
			contentLabel="Add content"
			overlayClassName="modal-overlay"
			className="form-modal"
			shouldCloseOnOverlayClick={false}
		>
			<div className="w_100 mb-4 text-right">
				<button type="button" onClick={() => closeModal(true)}>
					<i className="fas fa-times light-text disablePointerEvents" />
				</button>
			</div>
			<div>
				<h2>Add content</h2>
				{renderFormMessage()}
				<form onSubmit={onFormSubmit}>
					<Row>
						<Col xs={12} sm={12} md={12}>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Type *
								</Col>
								<Col xs={12} sm={12}>
									<Select
										placeholder="Type of content"
										value={formData && formData.type ? formData.type : ''}
										options={TYPES}
										onChange={(value) => dispatch(updateFormData([{ name: 'type', value }]))}
										isDisabled={actionsDisabled}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Brand *
								</Col>
								<Col xs={12} sm={12}>
									<Select
										placeholder="Type of content"
										value={formData && formData.brand ? formData.brand : ''}
										options={BRANDS}
										onChange={(value) => dispatch(updateFormData([{ name: 'brand', value }]))}
										isDisabled={actionsDisabled}
										onBlur={() => handleBlur('brand')}
									/>
									{
										errors.brand
											? <p className="error error-text text-normal">
												* Brand cannot be empty
											</p>
											: null
									}
								</Col>
							</Row>
							{formData.type && formData.type.value === 'ARTICLE' ? renderArticleFields() : renderTweetFields()}
						</Col>
					</Row>
					<Col xs={12} sm={12} className="text-center">
						<Button
							type="submit"
							success
							no_radius
							disabled={actionsDisabled}
							style={{ width: '100px' }}
						>
							<b>SAVE</b>
						</Button>
					</Col>
				</form>
			</div>
		</Modal>
	);
};

CommunityContentForm.propTypes = {
	open: T.bool,
	close: T.func,
	data: T.object,
	userBrands: T.array,
};

export default CommunityContentForm;
