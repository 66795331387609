import {
	FETCH_PROFILES,
	FETCH_PROFILES_SUCCESS,
	FETCH_PROFILES_FAILURE,
	FETCH_NEXT_PAGE,
	DISABLE_PROFILE_LIST_SEARCH,
	RUN_PROFILE_LIST_SEARCH,
	RUN_PROFILE_LIST_SEARCH_SUCCESS,
	RUN_PROFILE_LIST_SEARCH_FAILURE,
	RUN_PROFILE_LIST_SORT,
	DISABLE_PROFILE_LIST_SEARCH_FLAG,
	CLEAR_PROFILE_DESK_DATA,
} from './type';

export function fetchDeskProfiles(limit, offset, sortBy = 'updatedAt', orderBy = 'DESC', searchValue = null, profileType) {
	return {
		type: FETCH_PROFILES,
		limit,
		offset,
		sortBy,
		orderBy,
		searchValue,
		profileType,
	};
}

export function fetchDeskProfilesSuccess(profiles) {
	return {
		type: FETCH_PROFILES_SUCCESS,
		profiles,
	};
}

export function fetchDeskProfilesFailure(error) {
	return {
		type: FETCH_PROFILES_FAILURE,
		error,
	};
}

export function fetchNextPage(limit) {
	return {
		type: FETCH_NEXT_PAGE,
		limit,
	};
}

export function disableListSearchFlag() {
	return {
		type: DISABLE_PROFILE_LIST_SEARCH_FLAG,
	};
}

export function disableListSearch() {
	return {
		type: DISABLE_PROFILE_LIST_SEARCH,
	};
}

export function runProfileListSearch(searchType, searchValue, limit, offset, profileType) {
	return {
		type: RUN_PROFILE_LIST_SEARCH,
		searchType,
		searchValue,
		limit,
		offset,
		profileType,
	};
}

export function runProfileListSearchSuccess(searchResults) {
	return {
		type: RUN_PROFILE_LIST_SEARCH_SUCCESS,
		searchResults,
	};
}

export function runProfileListSearchFailure(error) {
	return {
		type: RUN_PROFILE_LIST_SEARCH_FAILURE,
		error,
	};
}

export function runProfileListSort(sortBy) {
	return {
		type: RUN_PROFILE_LIST_SORT,
		sortBy,
	};
}

export function setInitialState() {
	return {
		type: CLEAR_PROFILE_DESK_DATA,
	};
}
