import {
	ON_STORY_SELECT, UPDATE_TAGGED_COMPANIES, UPDATE_TAGGED_INFLUENCERS, FETCH_STORY_ASSOCIATIONS, FETCH_STORY_ASSOCIATIONS_SUCCESS, FETCH_STORY_ASSOCIATIONS_FAILURE, SAVE_STORY_ASSOCIATIONS, SAVE_STORY_ASSOCIATIONS_SUCCESS, SAVE_STORY_ASSOCIATIONS_FAILURE, SET_FORM_MESSAGE, CLEAR_STATE,
} from './type';

export function onStorySelect(story) {
	return {
		type: ON_STORY_SELECT,
		story,
	};
}

export function updateTaggedCompanies(data) {
	return {
		type: UPDATE_TAGGED_COMPANIES,
		data,
	};
}

export function updateTaggedInfluencers(data) {
	return {
		type: UPDATE_TAGGED_INFLUENCERS,
		data,
	};
}

export function fetchStoryAssociations(storyId) {
	return {
		type: FETCH_STORY_ASSOCIATIONS,
		storyId,
	};
}

export function fetchStoryAssociationsSuccess(associations) {
	return {
		type: FETCH_STORY_ASSOCIATIONS_SUCCESS,
		associations,
	};
}

export function fetchStoryAssociationsFailure(error) {
	return {
		type: FETCH_STORY_ASSOCIATIONS_FAILURE,
		error,
	};
}

export function saveStoryAssociations(storyId, associations) {
	return {
		type: SAVE_STORY_ASSOCIATIONS,
		associations,
		storyId,
	};
}

export function saveStoryAssociationsSuccess(successResponse) {
	return {
		type: SAVE_STORY_ASSOCIATIONS_SUCCESS,
		successResponse,
	};
}

export function saveStoryAssociationsFailure(error) {
	return {
		type: SAVE_STORY_ASSOCIATIONS_FAILURE,
		error,
	};
}

export function setFormMessage(message) {
	return {
		type: SET_FORM_MESSAGE,
		message,
	};
}

export function clearState() {
	return {
		type: CLEAR_STATE,
	};
}
