import { Map } from 'immutable';
import {
	FETCH_PROFILES,
	FETCH_PROFILES_FAILURE,
	FETCH_PROFILES_SUCCESS,
	FETCH_NEXT_PAGE,
	DISABLE_PROFILE_LIST_SEARCH,
	RUN_PROFILE_LIST_SEARCH,
	RUN_PROFILE_LIST_SEARCH_SUCCESS,
	RUN_PROFILE_LIST_SORT,
	DISABLE_PROFILE_LIST_SEARCH_FLAG,
	CLEAR_PROFILE_DESK_DATA,
} from './type';

const initialState = Map({
	profiles: [],
	loading: false,
	limit: 10,
	offset: 0,
	error: null,
	allProfiles: [],
	search: false,
	searchValue: null,
	searchType: null,
	sortValue: 'updatedAt',
});

function ProfileListReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_PROFILES: {
		if (state.get('offset') === 0) {
			return state
				.set('loading', true)
				.set('error', false)
				.set('allProfiles', [])
				.set('profiles', []);
		}
		return state
			.set('loading', true)
			.set('error', false);
	}
	case FETCH_PROFILES_SUCCESS: {
		let profiles = state.get('allProfiles');

		profiles = profiles.concat(action.profiles);

		return state
			.set('loading', false)
			.set('allProfiles', profiles)
			.set('profiles', action.profiles);
	}
	case FETCH_PROFILES_FAILURE:
		return state
			.set('loading', false)
			.set('error', action.error)
			.set('profiles', null);
	case FETCH_NEXT_PAGE: {
		const offset = Number(state.get('offset'));
		return state
			.set('limit', action.limit)
			.set('offset', offset + action.limit);
	}
	case DISABLE_PROFILE_LIST_SEARCH:
		return state
			.set('allProfiles', [])
			.set('profiles', [])
			.set('searchValue', null)
			.set('search', false)
			.set('offset', 0)
			.set('limit', 10)
			.set('searchType', null);
	case DISABLE_PROFILE_LIST_SEARCH_FLAG:
		return state
			.set('search', false);
	case RUN_PROFILE_LIST_SEARCH: {
		if (state.get('search') && action.searchValue === state.get('searchValue')) {
			return state
				.set('searchType', action.searchType)
				.set('loading', true);
		}
		return state
			.set('allProfiles', [])
			.set('profiles', [])
			.set('search', true)
			.set('searchValue', action.searchValue)
			.set('searchType', action.searchType)
			.set('loading', true)
			.set('offset', 0);
	}
	case RUN_PROFILE_LIST_SEARCH_SUCCESS: {
		let profiles = state.get('allProfiles');

		profiles = profiles.concat(action.searchResults);
		return state
			.set('profiles', action.searchResults)
			.set('allProfiles', profiles)
			.set('loading', false);
	}
	case RUN_PROFILE_LIST_SORT: {
		return state
			.set('allProfiles', [])
			.set('offset', 0)
			.set('profiles', [])
			.set('loading', true)
			.set('sortValue', action.sortBy);
	}
	case CLEAR_PROFILE_DESK_DATA: {
		return initialState;
	}
	default: return state;
	}
}

export default ProfileListReducer;
