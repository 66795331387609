import { replace } from 'connected-react-router';
import * as Sentry from '@sentry/react';
import { BASE_URL } from './constants';

const MY_ACCOUNT_BASE_URL = process.env.REACT_APP_SERVICE_PWA;

const redirectTo = (status, path) => {
	switch (status) {
	case 403:
		return window.location.assign(`${MY_ACCOUNT_BASE_URL}/login?redirect_url=${BASE_URL}${path}`);
		// showLoginModal=true&
	case 500:
		Sentry.captureException(`Error page encountered from [[$${BASE_URL}${path}]]`);
		return replace('/error');
	case 401:
		return replace('/unauthorized');
	case 404:
		return replace('/404');

	default:
		break;
	}
};

export { redirectTo };
