import React from 'react';
import { Row } from 'react-styled-flexboxgrid';
import TopicListings from '../../components/TopicsManagerComponents/TopicListings';

const TopicManager = (props) => (
	<>
		<Row>
			<TopicListings {...props} />
		</Row>
	</>
);
export default TopicManager;
