import React from 'react';
import T from 'prop-types';

const FacebookPreviewContent = (props) => {
	const { title, excerpt } = props;
	const facebookTitle = title ? `${title.substring(0, 80)}${title.length > 80 ? '...' : ''}` : 'Title';

	return (
		<div className="p-2">
			<p className="light-text mb-0">YOURSTORY.COM</p>
			<h3>{facebookTitle}</h3>
			<p style={{ fontSize: '0.9rem' }}>
				{excerpt || <span className="light-text">Description</span>}
			</p>
		</div>
	);
};

FacebookPreviewContent.propTypes = {
	title: T.string,
	excerpt: T.string,
};

export default FacebookPreviewContent;
