/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import Modal from 'react-modal';
import styled, { css } from 'styled-components';
import uuid from 'react-uuid';

import TrackForm from './TrackForm';
import { Col, Row } from '../Responsive';
import {
	Button, Input, InputGroup, WrapperContainer,
} from '../Styles';
import { CURATED_SET_CONFIG_URL } from '../../containers/TechSparksManager/constants';
import TrackSpeaker from './TrackSpeakers';
import TrackAgendas from './TrackAgendas';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import { extractedCityName } from '../../utils/common';
import Speakers from './Speakers';

const Card = styled.div`
	border: 1px solid #ddd;
	height: 250px;
	width: 200px;
	padding: 5px;
	margin: 0.25rem;
	background-color: #fff;
	cursor: pointer;
	font-size: 0.8rem;
	word-wrap: break-word;
	cursor: pointer;
	display: block;
	position: relative;
	overflow: auto;
`;

const CardWrapper = styled(Row)`
	margin-top: 2rem;
	border: 1px solid #bbb;
	background-color: #eee;
	padding: 0.5rem;
	width: 100%;
	position: relative;
	overflow: auto;
	height: 75vh;
	${(props) => props.source === 'fullPage' && css`
		height: 75vh;
	`}
`;

const Tracks = (props) => {
	const { pageTitle, match } = props;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isSpeakerModalOpen, setIsSpeakerModalOpen] = useState(false);
	const [isAgendaModalOpen, setIsAgendaModalOpen] = useState(false);
	const [tracks, setTracks] = useState(false);
	const [currentTrack, setCurrentTrack] = useState({});
	const [loading, setLoading] = useState(false);
	const extractCityName = extractedCityName(match.params.city);

	useEffect(() => {
		pageTitle('tracks');
		getTracks();
	}, []);

	async function getTracks() {
		const url = `${CURATED_SET_CONFIG_URL}${match?.params?.city ? match.params.city === 'mumbai' ? `${match.params.city}_`
			: match.params.city && match.params.city.includes('techsparks_2023') ? `2023_${extractCityName}` : '' : ''}tracks?brand=2`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		setLoading(true);
		const response = await fetch(url, options);
		const tracks = await response.json();
		setTracks(tracks);
		setLoading(false);
	}

	function unmountModal(modalType) {
		if (modalType === 'speakers') {
			setIsSpeakerModalOpen(false);
		} else if (modalType === 'agendas') {
			setIsAgendaModalOpen(false);
		} else {
			setIsModalOpen(false);
		}
		setCurrentTrack(null);
	}

	function renderFormModal() {
		return <Modal
			isOpen={isModalOpen}
			onRequestClose={() => unmountModal()}
			overlayClassName="modal-overlay"
			className="form-modal"
		>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className="mb-2">
				<h3>New Track</h3>
				<button type="button" onClick={() => unmountModal()}>
					<i className="fas fa-times light-text disablePointerEvents" />
				</button>
			</div>
			<TrackForm
				track={currentTrack}
				getTracks={getTracks}
				unmountModal={unmountModal}
				city={match.params.city}
			/>
		</Modal>;
	}

	function renderSpeakerFormModal() {
		return <Modal
			isOpen={isSpeakerModalOpen}
			onRequestClose={() => unmountModal('speakers')}
			overlayClassName="modal-overlay"
		>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className="mb-2">
				<h3>Speakers</h3>
				<button type="button" onClick={() => unmountModal('speakers')}>
					<i className="fas fa-times light-text disablePointerEvents" />
				</button>
			</div>
			<TrackSpeaker
				track={currentTrack}
				getTracks={getTracks}
				unmountModal={() => unmountModal('speakers')}
				city={match.params.city}
				speakersLength={currentTrack?.speakers?.length ? currentTrack?.speakers?.length + 1 : 1}
			/>
		</Modal>;
	}

	function renderAgendaFormModal() {
		return <Modal
			isOpen={isAgendaModalOpen}
			onRequestClose={() => unmountModal('agendas')}
			overlayClassName="modal-overlay"
		>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className="mb-2">
				<h3>Agendas</h3>
				<button type="button" onClick={() => unmountModal('agendas')}>
					<i className="fas fa-times light-text disablePointerEvents" />
				</button>
			</div>
			<TrackAgendas
				track={currentTrack}
				getTracks={getTracks}
				unmountModal={() => unmountModal('agendas')}
				city={match.params.city}
				agendasLength={currentTrack?.agendas?.length ? currentTrack?.agendas?.length + 1 : 1}
			/>
		</Modal>;
	}

	async function handleDelete(track) {
		const url = `${CURATED_SET_CONFIG_URL}${match?.params?.city ? match.params.city === 'mumbai' ? `${match.params.city}_`
			: match.params.city && match.params.city.includes('techsparks_2023') ? `2023_${extractCityName}` : '' : ''}tracks/delete?id=${track.id}`;
		const options = {
			method: 'PUT',
			credentials: 'include',
		};
		const response = await fetch(url, options);
		if (response && response.status === 200) {
			getTracks();
		}
	}

	return (
		<WrapperContainer>
			<Row className="mt-4">
				<Col md={8} sm={8} xs={12}>
					<Col md={8} sm={10} className="pl-0">
						This is the track manager of <strong>{match?.params?.city?.toUpperCase()}</strong> where we will manage track
					</Col>
				</Col>
				<Col md={4} sm={4} xs={12}>
					<Col md={8} xs={8} className="pt-sm-4">
						<Button
							id="add-new-btn"
							primary
							btn_rounded
							w_100
							onClick={() => setIsModalOpen(true)}
						>
							ADD NEW
						</Button>
					</Col>
				</Col>
			</Row>
			<Row>
				{loading
					? <LoadingIcon />
					: <CardWrapper>
						{tracks && tracks.map((track) => (
							<Card key={uuid()}>
								<div className="text-right">
									<i className="fa fa-pencil-square fa-lg" style={{ cursor: 'pointer' }} onClick={() => { setCurrentTrack(track); setIsModalOpen(true); }} />
									<i className="fa fa-trash fa-lg ml-3" style={{ cursor: 'pointer' }} onClick={() => handleDelete(track)} />
								</div>
								<h2>{track.title}</h2>
								<p>{track.description}</p>
								<Button secondary onClick={() => { setCurrentTrack(track); setIsSpeakerModalOpen(true); }}>Speakers</Button>
								<Button secondary onClick={() => { setCurrentTrack(track); setIsAgendaModalOpen(true); }}>Agendas</Button>
							</Card>
						))}
					</CardWrapper>}

			</Row>
			{renderFormModal()}
			{renderSpeakerFormModal()}
			{renderAgendaFormModal()}
		</WrapperContainer>
	);
};

Tracks.propTypes = {
	pageTitle: T.func,
	match: T.object,
};

export default Tracks;
