import React from 'react';
import PropTypes from 'prop-types';
import {
	Switch, Route, withRouter, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Modal from 'react-modal';

import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import NotFoundPage from '../NotFoundPage/Loadable';
import CategoryManager from '../CategoryManager/Loadable';
import TagManager from '../TagManager/Loadable';
import BrandManager from '../BrandManager/Loadable';
import ConfigManager from '../ConfigManager/Loadable';
import StoryDesk from '../StoryDesk/Loadable';
import StoryPreview from '../StoryPreview/Loadable';
import RedirectionEngine from '../RedirectionEngine/Loadable';
import UnauthorizedPage from '../UnauthorizedPage/Loadable';
import UploadPage from '../UploadPage/Loadable';
import BrandSpotlightManager from '../BrandSpotlightManager/Loadable';
import MessengerPromotionForm from '../MessengerPromotionForm/Loadable';
import MessengerPromotionsManager from '../MessengerPromotionsManager/Loadable';
import SubscriptionManager from '../SubscriptionManager/Loadable';
// import NotificationsManager from '../NotificationsManager/Loadable';
import {
	makeSelectAppUser, makeSelectAppLoading, makeSelectAppPageName, makeSelectOnlineStatus,
} from './selectors';
import {
	userLogout, setPageName, setOnlineStatus, fetchAppData,
} from './actions';
import RssFeedCreator from '../RssFeedCreator/Loadable';
import WatchlistedStories from '../WatchlistedStories/Loadable';
import DashboardHeader from '../../components/DashboardHeader';
import StoryList from '../StoryList/Loadable';
import Dashboard from '../Dashboard/Loadable';
import FeaturedSet from '../FeaturedSet/Loadable';
import StoryVersions from '../StoryVersions/Loadable';
import StopwordsPage from '../../components/StaticComponents/StopwordsList';
import ActivityStream from '../ActivityStream/Loadable';
import ErrorPage from '../ErrorPage/Loadable';
import SeriesManager from '../SeriesManager/Loadable';
import SeriesCreate from '../SeriesCreate/Loadable';
import MediaManagerGallery from '../MediaManagerGallery/Loadable';
import MediaManagerEditor from '../MediaManagerEditor/Loadable';
import EditorPage from '../EditorPage/Loadable';
import CompanyManager from '../CompanyManager/Loadable';
import CompanyEditor from '../CompanyEditor/Loadable';
import AwardManager from '../AwardManager/Loadable';
import PeopleManager from '../PeopleManager';
import CityManager from '../CityManager';
import ProfileDesk from '../ProfileDesk';
import TagStories from '../TagStories/Loadable';
import MultipleStoriesTagger from '../MultipleStoriesTagger/Loadable';
import ProfileClaimDesk from '../ProfileClaimDesk/Loadable';
import WebinarManager from '../WebinarManager/Loadable';
import CustomFields from '../CustomFields/Loadable';
import WebinarList from '../WebinarList/Loadable';
import UserManager from '../UserManager/Loadable';
import InviteUser from '../InviteUser/Loadable';
import CreateAlias from '../CreateAliasPage/Loadable';
import UpdateAlias from '../UpdateAliasPage/Loadable';
import DissociateAuthorProfile from '../DissociateAuthorProfile/Loadable';
import CompanyConnectDesk from '../CompanyConnectDesk/Loadable';
import JobEditor from '../JobEditor/Loadable';
import JobsList from '../JobsList/Loadable';
import EventEditor from '../EventEditor/Loadable';
import CommunityContentList from '../CommunityContentList/Loadable';
import EventsList from '../EventsList/Loadable';
import BroadcastManagerList from '../BroadcastManagerList/Loadable';
import AwardQuestions from '../AwardQuestions/Loadable';
import SubscriptionList from '../SubscriptionList/Loadable';
import SubscriptionEdit from '../SubscriptionEdit/Loadable';
import SubscriptionStatus from '../SubscriptionStatus/Loadable';
import FormBuilderStrapi from '../FormBuilderStrapi/Loadable';
import OrgChartsList from '../OrgChartList/Loadable';
import OrgChartEditor from '../OrgChartEditor/Loadable'; import BoniBrandManager from '../BoniBrandManager/Loadable';
import BoniBrandCategory from '../BoniBrandCategoryManager/Loadable';
import SpotlightMonthList from '../BoniSpotlightMonth/Loadable';
import BoniProductCategory from '../BoniProductCategory/Loadable';
import BoniProductManager from '../BoniProductManager/Loadable';
import BoniWebStory from '../BoniWebstoriesManager/Loadable';
import BoniExternalLink from '../BoniExternalLink/Loadable';
import BrandEditor from '../../components/BoniManager/BoniBrandManagerList/Loadable';
import BrandCategoryEditor from '../../components/BoniManager/BoniBrandCategory/Loadable';
import SpotlightEditor from '../../components/BoniManager/BoniSpotLightMonth/Loadable';
import ExternalLinkEditor from '../../components/BoniManager/BoniExternalLink/Loadable';
import WebstoryEditor from '../../components/BoniManager/BoniWebstoryManager/Loadable';
import ProductCategoryEditor from '../../components/BoniManager/BoniProductCategory/Loadable';
import ProductManagerEditor from '../../components/BoniManager/BoniProductManager/Loadable';
import AdRefreshManager from '../AdrefreshManager/Loadable';
import TechSparksManager from '../TechSparksManager/Loadable';

import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';
import ProfileList from '../ProfileList';
import ProfileFundingList from '../FundingList';
import { managerialRoles } from '../../utils/common';
import WebStoriesManager from '../WebStoriesManager';
import BrandOfTheDayManager from '../BoniBrandOfTheDayManager';
import BoniManager from '../BoniManager';
import WriteWebstory from '../WriteWebstory';
import CMSPageCurationSet from '../CMSPageCurationSet';
import AdminJobsPage from '../AdminJobs';
import EditAdminJobResumePage from '../EditAdminJobResume';
import EducationHistoryListPage from '../EditAdminJobResume/EducationHistorylist';
import WorkExperienceListPage from '../EditAdminJobResume/WorkExperienceList';
import {
	Tracks, Speakers, Agendas, ViewAllSpeakers, Partners,
} from '../../components/TechSparksComponents';
import LiveFeedCommentsManager from '../LiveFeedManager';
import ListingsManager from '../ListingsManager';
import ProfileListings from '../../components/ListingsComponent/ProfileListings';
import ProfileBioPage from '../ProfileBioManager';
import TopicManager from '../TopicManager';
import DashboardWebStoryManager from '../DashboardWebStoriesManager';
import DashboardWebStoryEditor from '../../components/DashboardWebStory';
import CaptableGiftCodesManager from '../CaptableGiftCodesManager';
import RedemptionDoneManager from '../CaptableGiftCodesManager/RedemptionDoneManager';

const PrivateRoute = (props) => (
	<Route {...props} component={!props.check ? UnauthorizedPage : undefined} />
);

PrivateRoute.propTypes = {
	check: PropTypes.bool,
};

const initializeSentry = () => {
	if (process.env.REACT_APP_ENV !== 'production') return;
	const sentryConfig = {
		dsn: 'https://d738d86ca72c6fdbb0d9afd74ec72fbb@o1082828.ingest.sentry.io/4506169980682240',
		environment: process.env.REACT_APP_ENV,
	};
	Sentry.init(sentryConfig);

	// if (process.env.REACT_APP_ENV !== 'development') {
	// 	Object.assign(sentryConfig, {
	// 		integrations: [new Integrations.BrowserTracing()],
	// 		tracesSampleRate: 1.0,
	// 		beforeSend(event) {
	// 			// Check if it is an exception, and if so, show the report dialog
	// 			if (event.exception) {
	// 				Sentry.showReportDialog({
	// 					eventId: event.event_id,
	// 					user: { name: userInfo.name, email: userInfo.email },
	// 				});
	// 			}
	// 			return event;
	// 		},
	// 	});
	// }
};

class App extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			editorAutoSaveMessage: null,
		};
	}

	componentDidMount() {
		// window._mfq.push(['root']);
		this.props.getAppData();
		Modal.setAppElement(this.el);
	}

	/* Commenting out code since Kayako is temporarily out
		 componentDidUpdate(prevProps) {
				if (!prevProps.userInfo && this.props.userInfo) {
						// window._mfq.push('setVariable', 'UserId', this.props.userInfo.id);
						// window._mfq.push(['setVariable', 'VISITOR NAME', this.props.userInfo.name]);
						if (this.props.userInfo.email) {
								const timestamp = parseInt(new Date().getTime() / 1000, 10);
								const data = `${this.props.userInfo.name}${this.props.userInfo.email}${'6QQD4TrulIz6vEOGPFHQSPt8FYTbk790t1gHy6ESEYybBR6HYERSXLZW9ruaFyQ6'
										}${timestamp}`;
								const signature = Crypto.createHmac('sha256', '6QQD4TrulIz6vEOGPFHQSPt8FYTbk790t1gHy6ESEYybBR6HYERSXLZW9ruaFyQ6')
										.update(data)
										.digest('hex');
								window.kayako.ready(() => {
										window.kayako.identify(
												nextProps.userInfo.name,
												nextProps.userInfo.email,
												signature,
												timestamp
										);
								});
						} else {
								window.kayako.ready(() => {
										window.kayako.forget();
								});
						}
				}
		} */

	render() {
		const {
			userInfo, logoutUser, loading, online, setPage,
		} = this.props;
		const managerialRolesCheck = userInfo && userInfo.roles ? managerialRoles(userInfo.roles) : {};

		// Sentry setup initialized here to prefil user info in crash report
		initializeSentry();

		return (
			<div ref={(ref) => this.el = ref}>
				{
					loading || !userInfo
						? <LoadingIcon />
						: (
							<div>
								<DashboardHeader
									{...this.props}
									logout={logoutUser}
									autoSaveMessage={this.state.editorAutoSaveMessage}
								/>
								<Switch>
									<Route
										exact
										path="/stories"
										render={(props) => <StoryList {...props} user={userInfo} pageTitle={setPage} />}
									/>
									<PrivateRoute
										exact
										path="/manager/category/:locale"
										render={(props) => <CategoryManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/manager/tag/:locale"
										render={(props) => <TagManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/manager/brand"
										render={(props) => <BrandManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/manager/dynamicconfig"
										render={(props) => <ConfigManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.config}
									/>
									<Route
										exact
										path="/editor/:storyType/:brand"
										render={(props) => <EditorPage
											{...props}
											user={userInfo}
											pageTitle={setPage}
											online={online}
											setOnlineStatus={this.props.setEditorOnlineStatus}
										/>}
									/>
									<Route
										exact
										path="/editor/:storyType/:brand/:storyId"
										render={(props) => <EditorPage
											{...props}
											user={userInfo}
											pageTitle={setPage}
											online={online}
											setOnlineStatus={this.props.setEditorOnlineStatus}
										/>}
									/>
									<PrivateRoute
										exact
										path="/desk"
										render={(props) => <StoryDesk {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/desk/:brand/:status"
										render={(props) => <StoryDesk {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/storypreview/:storyId"
										render={(props) => <StoryPreview {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/manager/brandspotlight"
										render={(props) => <BrandSpotlightManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.spotlight}
									/>
									<Route
										exact
										path="/unauthorized"
										render={(props) => <UnauthorizedPage {...props} pageTitle={setPage} />}
									/>
									<PrivateRoute
										exact
										path="/upload"
										render={(props) => <UploadPage {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/manager/redirections"
										render={(props) => (
											<RedirectionEngine {...props} user={userInfo} pageTitle={setPage} />
										)}
										check={userInfo.employee && managerialRolesCheck.redirection}
									/>
									<PrivateRoute
										exact
										path="/manager/messenger-promotions/create"
										render={(props) => <MessengerPromotionForm {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/manager/messenger-promotions"
										render={(props) => <MessengerPromotionsManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/rsscreator"
										render={(props) => <RssFeedCreator {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/watchlistedstories"
										render={(props) => <WatchlistedStories {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<Route
										exact
										path="/404"
										render={(props) => <NotFoundPage {...props} pageTitle={setPage} />}
									/>
									<Route
										exact
										path="/storyversions/:storyId"
										render={(props) => <StoryVersions {...props} user={userInfo} pageTitle={setPage} />}
									/>
									<PrivateRoute
										exact
										path="/featuredset"
										render={(props) => <FeaturedSet {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/series-manager"
										render={(props) => <SeriesManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.seriesManager}
									/>
									<PrivateRoute
										exact
										path="/series-manager/create"
										render={(props) => <SeriesCreate {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.seriesManager}
									/>
									<PrivateRoute
										exact
										path="/series-manager/update/:seriesSlug"
										render={(props) => <SeriesCreate {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.seriesManager}
									/>
									<Route
										exact
										path="/stopwords"
										component={StopwordsPage}
									/>
									<PrivateRoute
										exact
										path="/activity-stream"
										render={(props) => <ActivityStream {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/manager/media/gallery"
										render={(props) => <MediaManagerGallery {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/manager/media/editor"
										render={(props) => <MediaManagerEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/manager/media/editor/:id"
										render={(props) => <MediaManagerEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/manager/company"
										render={(props) => <CompanyManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.companyManager}
									/>
									<PrivateRoute
										exact
										path="/company/editor"
										render={(props) => <CompanyEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.companyManager}
									/>
									<PrivateRoute
										exact
										path="/company/editor/:companyId"
										render={(props) => <CompanyEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.companyManager}
									/>
									{/* <Route
											exact
											path="/manager/company/verticals"
											render={(props) => <VerticalManager {...props} user={userInfo} pageTitle={setPage} />}
									/> */}
									<PrivateRoute
										exact
										path="/manager/company/awards"
										render={(props) => <AwardManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.companyManager}
									/>
									<PrivateRoute
										exact
										path="/manager/company/influencers"
										render={(props) => <PeopleManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.companyManager}
									/>
									<PrivateRoute
										exact
										path="/manager/company/influencers/:id"
										render={(props) => <PeopleManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.companyManager}
									/>
									<PrivateRoute
										exact
										path="/manager/company/cities"
										render={(props) => <CityManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.companyManager}
									/>
									<PrivateRoute
										exact
										path="/manager/:profile/desk"
										render={(props) => <ProfileDesk {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/manager/:profile(company|influencer)/desk/:status"
										render={(props) => <ProfileDesk {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/manager/company/tagstories"
										render={(props) => <TagStories {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.companyManager}
									/>
									<PrivateRoute
										exact
										path="/manager/company/tagger/:tagType"
										render={(props) => <MultipleStoriesTagger {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.companyManager}
									/>
									<PrivateRoute
										exact
										path="/manager/:profile(company|influencer)/view"
										render={(props) => <ProfileList {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/manager/company/funding/view"
										render={(props) => <ProfileFundingList {...props} user={userInfo} pageTitle={setPage} module="funding" />}
										check={userInfo.employee && managerialRolesCheck.companyManager}
									/>
									<PrivateRoute
										exact
										path="/manager/company/merger/view"
										render={(props) => <ProfileFundingList {...props} user={userInfo} pageTitle={setPage} module="merger" />}
										check={userInfo.employee && managerialRolesCheck.companyManager}
									/>
									<PrivateRoute
										exact
										path="/manager/company/ipo/view"
										render={(props) => <ProfileFundingList {...props} user={userInfo} pageTitle={setPage} module="ipo" />}
										check={userInfo.employee && managerialRolesCheck.companyManager}
									/>
									<PrivateRoute
										exact
										path="/manager/company/acquisition/view"
										render={(props) => <ProfileFundingList {...props} user={userInfo} pageTitle={setPage} module="acquisition" />}
										check={userInfo.employee && managerialRolesCheck.companyManager}
									/>
									<PrivateRoute
										exact
										path="/profile-claim/desk"
										render={(props) => <ProfileClaimDesk {...props} user={userInfo} pageTitle={setPage} />}
										user={userInfo.employee && managerialRolesCheck.companyManager}
									/>
									<PrivateRoute
										exact
										path="/profile-claim/desk/:status(pending|verified|rejected)"
										render={(props) => <ProfileClaimDesk {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.companyManager}
									/>
									<PrivateRoute
										exact
										path="/company-connect/desk"
										render={(props) => <CompanyConnectDesk {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/company-connect/desk/:status(pending|verified|rejected)"
										render={(props) => <CompanyConnectDesk {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/manager/webinar"
										render={(props) => <WebinarManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.webinarManager}
									/>
									<PrivateRoute
										exact
										path="/webinar/custom-fields"
										render={(props) => <CustomFields {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.webinarManager}
									/>
									<PrivateRoute
										exact
										path="/webinar/list"
										render={(props) => <WebinarList {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.webinarManager}
									/>
									<PrivateRoute
										exact
										path="/manager/user"
										render={(props) => <UserManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/manager/user/invite"
										render={(props) => <InviteUser {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/manager/user/alias/create"
										render={(props) => <CreateAlias {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/manager/user/alias/update"
										render={(props) => <UpdateAlias {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/manager/user/dissociate"
										render={(props) => <DissociateAuthorProfile {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/job/editor"
										render={(props) => <JobEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.jobsManager}
									/>
									<PrivateRoute
										exact
										path="/job/editor/:jobId"
										render={(props) => <JobEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.jobsManager}
									/>
									<PrivateRoute
										exact
										path="/manager/jobs/list"
										render={(props) => <JobsList {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.jobsManager}
									/>
									<PrivateRoute
										exact
										path="/event/editor"
										render={(props) => <EventEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.eventManager}
									/>
									<PrivateRoute
										exact
										path="/event/editor/:brandId/:eventId"
										render={(props) => <EventEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.eventManager}
									/>
									<PrivateRoute
										path="/manager/community-content/list"
										render={(props) => <CommunityContentList {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.communityContentManager}
									/>
									<PrivateRoute
										exact
										path="/manager/events/list"
										render={(props) => <EventsList {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.eventManager}
									/>
									<PrivateRoute
										exact
										path="/manager/broadcast/list"
										render={(props) => <BroadcastManagerList {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.broadcastManager}
									/>
									<PrivateRoute
										exact
										path="/manager/award/questions"
										render={(props) => <AwardQuestions {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.companyManager}
									/>
									<PrivateRoute
										exact
										path="/manager/subscription"
										render={(props) => <SubscriptionManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.subscriptionManager}
									/>
									<PrivateRoute
										exact
										path="/manager/subscription/list"
										render={(props) => <SubscriptionList {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.subscriptionManager}
									/>
									<PrivateRoute
										exact
										path="/manager/subscription/update"
										render={(props) => <SubscriptionEdit {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.subscriptionManager}
									/>
									<PrivateRoute
										exact
										path="/manager/subscription/status"
										render={(props) => <SubscriptionStatus {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.subscriptionManager}
									/>
									<PrivateRoute
										exact
										path="/manager/form-builder/list"
										render={(props) => <FormBuilderStrapi {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.eventManager}
									/>
									<PrivateRoute
										exact
										path="/manager/org-chart/list"
										render={(props) => <OrgChartsList {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.orgChartManager}
									/>
									<PrivateRoute
										exact
										path="/org-chart/editor/:orgChartId"
										render={(props) => <OrgChartEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.orgChartManager}
									/>
									<PrivateRoute
										exact
										path="/org-chart/editor/"
										render={(props) => <OrgChartEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.orgChartManager}
									/>
									<PrivateRoute
										exact
										path="/sponsored-story"
										render={(props) => <CMSPageCurationSet {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/admin-jobs"
										render={(props) => <AdminJobsPage {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.ysJobsManager}
									/>
									<PrivateRoute
										exact
										path="/admin-job/editor/:profileId"
										render={(props) => <EditAdminJobResumePage {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.ysJobsManager}
									/>
									<PrivateRoute
										exact
										path="/admin-job/education-history/editor/:profileId"
										render={(props) => <EducationHistoryListPage {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.ysJobsManager}
									/>
									<PrivateRoute
										exact
										path="/admin-job/work-experience/editor/:profileId"
										render={(props) => <WorkExperienceListPage {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.ysJobsManager}
									/>
									<PrivateRoute
										exact
										path="/manager/boni"
										render={(props) => <BoniManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>
									<PrivateRoute
										exact
										path="/boni-brand/web-stories/manager"
										render={(props) => <WebStoriesManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>
									<PrivateRoute
										exact
										path="/boni/write-web-story"
										render={(props) => <WriteWebstory {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>

									<PrivateRoute
										exact
										path="/manager/boni/brand-manager"
										render={(props) => <BoniBrandManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>
									<PrivateRoute
										exact
										path="/manager/boni/brand-editor"
										render={(props) => <BrandEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>
									<PrivateRoute
										exact
										path="/manager/boni/brand-editor/:id"
										render={(props) => <BrandEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>

									{/* <PrivateRoute
										exact
										path="/manager/boni/edit-brands"
										render={(props) => <EditBrand {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/> */}

									<PrivateRoute
										exact
										path="/manager/boni/brand-category"
										render={(props) => <BoniBrandCategory {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>

									<PrivateRoute
										exact
										path="/manager/boni/brand-category-editor"
										render={(props) => <BrandCategoryEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>
									<PrivateRoute
										exact
										path="/manager/boni/brand-category-editor/:id"
										render={(props) => <BrandCategoryEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>
									<PrivateRoute
										exact
										path="/manager/boni/product-category"
										render={(props) => <BoniProductCategory {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>

									<PrivateRoute
										exact
										path="/manager/boni/product-category-editor"
										render={(props) => <ProductCategoryEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>
									<PrivateRoute
										exact
										path="/manager/boni/product-category-editor/:id"
										render={(props) => <ProductCategoryEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>

									<PrivateRoute
										exact
										path="/manager/boni/spotlight-of-month"
										render={(props) => <SpotlightMonthList {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>
									<PrivateRoute
										exact
										path="/manager/boni/spotlight-of-month-editor"
										render={(props) => <SpotlightEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>
									<PrivateRoute
										exact
										path="/manager/boni/spotlight-of-month-editor:id"
										render={(props) => <SpotlightEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>

									{/* <PrivateRoute
										exact
										path="/manager/boni/edit-spotlight-of-month"
										render={(props) => <EditSpotlight {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/> */}
									<PrivateRoute
										exact
										path="/manager/boni/product-manager"
										render={(props) => <BoniProductManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>
									<PrivateRoute
										exact
										path="/manager/boni/product-editor"
										render={(props) => <ProductManagerEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>

									<PrivateRoute
										exact
										path="/manager/boni/product-editor/:id"
										render={(props) => <ProductManagerEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>
									<PrivateRoute
										exact
										path="/manager/boni/webstory-manager"
										render={(props) => <BoniWebStory {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>
									<PrivateRoute
										exact
										path="/manager/boni/webstory-editor"
										render={(props) => <WebstoryEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>
									<PrivateRoute
										exact
										path="/manager/boni/webstory-editor:id"
										render={(props) => <WebstoryEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>
									<PrivateRoute
										exact
										path="/manager/boni/external-link"
										render={(props) => <BoniExternalLink {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>
									<PrivateRoute
										exact
										path="/manager/boni/external-link-editor"
										render={(props) => <ExternalLinkEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>

									<PrivateRoute
										exact
										path="/manager/boni/external-link-editor:id"
										render={(props) => <ExternalLinkEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>

									<PrivateRoute
										exact
										path="/boni-brand/brand-of-the-day/manager"
										render={(props) => <BrandOfTheDayManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.brandsOfNewIndiaManager}
									/>

									<PrivateRoute
										exact
										path="/manager/ad-refresh"
										render={(props) => <AdRefreshManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.adRefreshManager}
									/>

									<PrivateRoute
										exact
										path="/manager/offlineEvent"
										render={(props) => <TechSparksManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.techSparksManager}
									/>
									<PrivateRoute
										exact
										path="/manager/offlineEvent/:city"
										render={(props) => <TechSparksManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.techSparksManager}
									/>
									<PrivateRoute
										exact
										path="/techsparks/tracks/:city"
										render={(props) => <Tracks {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.techSparksManager}
									/>

									<PrivateRoute
										exact
										path="/techsparks/tracks"
										render={(props) => <Tracks {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.techSparksManager}
									/>
									<PrivateRoute
										exact
										path="/techsparks/speakers"
										render={(props) => <Speakers {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.techSparksManager}
									/>
									<PrivateRoute
										exact
										path="/techsparks/speakers/:city"
										render={(props) => <Speakers {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.techSparksManager}
									/>
									<PrivateRoute
										exact
										path="/techsparks/view-all/speakers"
										render={(props) => <ViewAllSpeakers {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.techSparksManager}
									/>
									<PrivateRoute
										exact
										path="/techsparks/view-all/speakers/:city"
										render={(props) => <ViewAllSpeakers {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.techSparksManager}
									/>
									<PrivateRoute
										exact
										path="/techsparks/agendas"
										render={(props) => <Agendas {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.techSparksManager}
									/>
									<PrivateRoute
										exact
										path="/techsparks/agendas/:city"
										render={(props) => <Agendas {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.techSparksManager}
									/>
									<PrivateRoute
										exact
										path="/techsparks/partners"
										render={(props) => <Partners {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.techSparksManager}
									/>
									<PrivateRoute
										exact
										path="/techsparks/partners/:city"
										render={(props) => <Partners {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.techSparksManager}
									/>
									<PrivateRoute
										exact
										path="/manager/live-feed"
										render={(props) => <LiveFeedCommentsManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.liveFeedCardManager}
									/>
									<PrivateRoute
										exact
										path="/manager/category-listings"
										render={(props) => <ListingsManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.listingsCardManager}
									/>
									<PrivateRoute
										exact
										path="/manager/profile-listings/:categoryId"
										render={(props) => <ProfileListings {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.listingsCardManager}
									/>
									<PrivateRoute
										exact
										path="/manager/topics"
										render={(props) => <TopicManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.topicManager}
									/>
									<PrivateRoute
										exact
										path="/dashboard/web-stories"
										render={(props) => <DashboardWebStoryManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.webStoryManager}
									/>
									<PrivateRoute
										exact
										path="/manager/dashboard/webstory-editor"
										render={(props) => <DashboardWebStoryEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.webStoryManager}
									/>
									<PrivateRoute
										exact
										path="/manager/dashboard/webstory-editor:id"
										render={(props) => <DashboardWebStoryEditor {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee && managerialRolesCheck.webStoryManager}
									/>
									<PrivateRoute
										exact
										path="/manager/captable/gift-codes"
										render={(props) => <CaptableGiftCodesManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/manager/captable/giftCode/Claims/:giftCodeId"
										render={(props) => <RedemptionDoneManager {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee}
									/>
									<PrivateRoute
										exact
										path="/profile/bio"
										render={(props) => <ProfileBioPage {...props} user={userInfo} pageTitle={setPage} />}
										check={userInfo.employee || !userInfo.employee}
									/>
									<Route
										exact
										path="/"
										render={(props) => (userInfo && userInfo.employee ? <Dashboard {...props} user={userInfo} pageTitle={setPage} /> : <Redirect to="/stories" />)}
									/>
									<Route
										exact
										path="/error"
										render={(props) => <ErrorPage {...props} pageTitle={setPage} />}
									/>

									<Route render={(props) => <NotFoundPage {...props} pageTitle={setPage} />} />
								</Switch>
							</div>
						)
				}
			</div>
		);
	}
}

App.propTypes = {
	userInfo: PropTypes.object,
	logoutUser: PropTypes.func,
	loading: PropTypes.bool,
	online: PropTypes.bool,
	pageName: PropTypes.string,
	setPage: PropTypes.func,
	setEditorOnlineStatus: PropTypes.func,
	getAppData: PropTypes.func,
};

export function mapDispatchToProps(dispatch) {
	return {
		logoutUser: () => dispatch(userLogout()),
		setPage: (page) => dispatch(setPageName(page)),
		setEditorOnlineStatus: (value) => dispatch(setOnlineStatus(value)),
		getAppData: () => dispatch(fetchAppData()),
	};
}

const mapStateToProps = createStructuredSelector({
	userInfo: makeSelectAppUser(),
	loading: makeSelectAppLoading(),
	pageName: makeSelectAppPageName(),
	online: makeSelectOnlineStatus(),
});

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default withRouter(withConnect(App));
