import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-styled-flexboxgrid';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';
import { Input, Button, WrapperContainer } from '../../components/Styles';

import TopicManagerHeader from '../../components/TopicManagerHeader/TopicManagerHeader';
import {
	onCityFormElementChange, addNewCity, createCity, unmountCityFormMessage, setCitySlugUniqueValue, citySelect, updateCity, deleteCity, setInitialState,
} from './actions';
import {
	makeSelectFormData, makeSelectFormMessage, makeSelectGetOperation, makeSelectUniqueSlug, makeSelectSelectedCity, makeSelectLoading, makeSelectDropdownTitle,
} from './selectors';
import { isSlugUnique } from '../../utils/common';

import messages from './messages';
import { countries } from './countryList';
import ToastMessage from '../../components/ToastMessage';

class CityManager extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			touched: {
				name: false,
				slug: false,
				state: false,
			},
		};
	}

	componentDidMount() {
		const { pageTitle } = this.props;
		pageTitle('cityManager');
	}

	componentDidUpdate(prevProps) {
		if (this.props.formData.slug && (prevProps.formData.slug !== this.props.formData.slug)) {
			isSlugUnique(this.props.formData.slug, 'city', this.props.selectedCity, this.props.setSlugUniqueValue);
		}
	}

	getSnapshotBeforeUpdate(nextProps) {
		if (nextProps.uniqueSlug !== this.props.uniqueSlug) {
			this.handleBlur('slug');
		}
		return null;
	}

	componentWillUnmount() {
		this.props.setInitialState();
	}

	renderFormMessage() {
		const { formMessage, formMessageUnmount } = this.props;
		if (formMessage) {
			return <ToastMessage toastData={formMessage} unmount={formMessageUnmount} />;
		}
		return null;
	}

	onElementChange = (e) => {
		const form = e.target;
		const data = {
			name: form.name,
			value: form.value,
		};
		this.props.triggerFormElementChange(data);
	}

	handleBlur(field) {
		this.setState({
			touched: { ...this.state.touched, [field]: true },
		});
	}

	hasUpperCase(str) {
		return /[A-Z]/.test(str);
	}

	hasSymbols(str) {
		return /[^A-Za-z0-9-]/.test(str);
	}

	displayErrors(field) {
		const errors = this.validateForm();
		const hasError = errors[field];
		let shouldShow;
		switch (field) {
		case 'slugLength':
		case 'slugUnique':
		case 'slugUpperCase':
			shouldShow = this.state.touched.slug;
			break;
		case 'slugSymbol':
			shouldShow = this.state.touched.slug;
			break;
		default:
			shouldShow = this.state.touched[field];
			break;
		}
		return hasError ? shouldShow : false;
	}

	validateForm = () => {
		const { formData, uniqueSlug } = this.props;
		return {
			name: formData.name.length < 1,
			slugLength: formData.slug.length < 1,
			slugUpperCase: this.hasUpperCase(formData.slug),
			slugSymbol: this.hasSymbols(formData.slug),
			slugUnique: !uniqueSlug,
			country: !formData.country,
			state: !formData.state,
		};
	}

	onSave = (e) => {
		e.preventDefault();
		const form = e.target;
		const errors = this.validateForm();
		const save = !Object.keys(errors).some((i) => errors[i]);
		const {
			operation, triggerCreateCity, triggerUpdateCity, selectedCity,
		} = this.props;
		if (save) {
			const data = new FormData();
			data.append('name', form.name.value);
			data.append('slug', form.slug.value);
			data.append('state', form.state.value);
			data.append('country', form.country.value);

			if (operation === 'add') triggerCreateCity(data);

			else if (operation === 'edit') triggerUpdateCity(selectedCity.id, data);
		} else {
			this.setState({
				touched: {
					name: this.state.touched.name || true,
					slug: this.state.touched.slug || true,
					state: this.state.touched.state || true,
					country: this.state.touched.country || true,
				},
			});
		}
	}

	onDelete = (e) => {
		const { triggerDeleteCity, selectedCity } = this.props;
		e.preventDefault();
		triggerDeleteCity(selectedCity.id);
	}

	renderForm() {
		const {
			operation, triggerFormElementChange, addCity, formData,
		} = this.props;
		if (['add', 'edit'].includes(operation)) {
			return <form onSubmit={this.onSave}>
				<Row className="mt-5 mb-5">
					<Col xs={8}>
						<Row className="mb-3">
							<Col sm={2} xs={12}>
								Name *
							</Col>
							<Col sm={9} xs={12}>
								<Input
									type="text"
									name="name"
									placeholder="Name of the city"
									value={formData.name ? formData.name : ''}
									onChange={this.onElementChange}
									required
									onBlur={() => this.handleBlur('name')}
								/>
								{
									this.displayErrors('name')
										? <p className="error" id="name-err"><FormattedMessage {...messages.nameErr} /></p>
										: null
								}
							</Col>
						</Row>
						<Row className="mb-3">
							<Col sm={2} xs={12}>
								Slug *
							</Col>
							<Col sm={9} xs={12}>
								<Input
									type="text"
									name="slug"
									placeholder="Slug for the city"
									value={formData.slug ? formData.slug : ''}
									onChange={this.onElementChange}
									required
									onBlur={() => this.handleBlur('slug')}

								/>
								{
									this.displayErrors('slugLength')
										? <p className="error" id="slug-length-err"><FormattedMessage {...messages.slugEmptyErr} /></p>
										: null
								}
								{
									!this.displayErrors('slugLength') && this.displayErrors('slugUpperCase')
										? <p className="error" id="slug-case-err"><FormattedMessage {...messages.slugCaseErr} /></p>
										: null
								}
								{
									!this.displayErrors('slugLength') && this.displayErrors('slugUnique')
										? <p className="error" id="slug-unique-err"><FormattedMessage {...messages.slugUniqueErr} /></p>
										: null
								}
								{
									!this.displayErrors('slugLength') && this.displayErrors('slugSymbol')
										? <p className="error" id="slug-symbol-err"><FormattedMessage {...messages.slugSymbolErr} /></p>
										: null
								}
							</Col>
						</Row>
						<Row className="mb-3">
							<Col sm={2} xs={12}>
								State *
							</Col>
							<Col sm={9} xs={12}>
								<Input
									type="text"
									name="state"
									placeholder="State"
									value={formData.state ? formData.state : ''}
									onChange={this.onElementChange}
									required
									onBlur={() => this.handleBlur('state')}
								/>
								{
									this.displayErrors('state')
										? <p className="error" id="name-err"><FormattedMessage {...messages.stateErr} /></p>
										: null
								}
							</Col>
						</Row>
						<Row className="mb-3">
							<Col sm={2} xs={12}>
								Country *
							</Col>
							<Col sm={9} xs={12}>
								<Select
									placeholder="Select country"
									name="country"
									value={formData.country ? { label: formData.country, value: formData.country } : ''}
									options={countries}
									className="basic-multi-select"
									classNamePrefix="select"
									onChange={(value) => {
										triggerFormElementChange({
											name: 'country',
											value: value.value,
										});
									}}
									onBlur={() => this.handleBlur('country')}
								/>
								{
									this.displayErrors('country')
										? <p className="error" id="name-err"><FormattedMessage {...messages.countryErr} /></p>
										: null
								}
							</Col>
						</Row>
						<Row className="mb-3">
							<Col sm={2} xs={12} />
							<Col sm={9} xs={12}>
								<Button
									type="submit"
									success
									disabled={this.props.loading}
								>
									{this.props.operation === 'edit' ? 'UPDATE' : 'SAVE'}
								</Button>
								<Button
									light
									disabled={this.props.loading}
									onClick={(e) => {
										e.preventDefault();
										addCity();
									}}
									className="ml-3"
								>
									Discard
								</Button>
								{/* {operation === 'edit' ?
									<Button
										type="button"
										primary
										id="deleteBtn"
										onClick={this.onDelete}
										disabled={this.props.loading}
										className="ml-3"
									>
										Delete
													</Button>
									: null} */}
							</Col>
						</Row>
					</Col>
				</Row>
			</form>;
		}
		return null;
	}

	render() {
		const {
			addCity, onSelectCity, selectedCity, dropdownTitle,
		} = this.props;
		return (
			<div>
				<WrapperContainer>
					<TopicManagerHeader
						hoc="city"
						addNew={addCity}
						selectedItem={onSelectCity}
						itemSelected={selectedCity !== null}
						title={dropdownTitle}
					/>
					<Row>
						<Col md={8} sm={7} xs={12}>
							{this.renderFormMessage()}
						</Col>
					</Row>
					<div className="mt-5 mb-5">
						{this.renderForm()}
					</div>
				</WrapperContainer>
			</div>
		);
	}
}

export function mapDispatchToProps(dispatch) {
	return {
		addCity: () => dispatch(addNewCity()),
		triggerCreateCity: (data) => dispatch(createCity(data)),
		triggerFormElementChange: (changedElement) => dispatch(onCityFormElementChange(changedElement)),
		triggerUpdateCity: (id, updatedCity) => dispatch(updateCity(id, updatedCity)),
		triggerDeleteCity: (id) => dispatch(deleteCity(id)),
		formMessageUnmount: () => dispatch(unmountCityFormMessage()),
		setSlugUniqueValue: (value) => dispatch(setCitySlugUniqueValue(value)),
		onSelectCity: (selectedCity) => dispatch(citySelect(selectedCity)),
		setInitialState: () => dispatch(setInitialState()),
	};
}

const mapStateToProps = createStructuredSelector({
	selectedCity: makeSelectSelectedCity(),
	formData: makeSelectFormData(),
	formMessage: makeSelectFormMessage(),
	operation: makeSelectGetOperation(),
	uniqueSlug: makeSelectUniqueSlug(),
	loading: makeSelectLoading(),
	dropdownTitle: makeSelectDropdownTitle(),
});

CityManager.propTypes = {
	pageTitle: PropTypes.func,
	operation: PropTypes.string,
	onSelectCity: PropTypes.func,
	selectedCity: PropTypes.object,
	addCity: PropTypes.func,
	triggerFormElementChange: PropTypes.func,
	formData: PropTypes.object,
	triggerCreateCity: PropTypes.func,
	triggerUpdateCity: PropTypes.func,
	triggerDeleteCity: PropTypes.func,
	uniqueSlug: PropTypes.bool,
	formMessage: PropTypes.string,
	formMessageUnmount: PropTypes.func,
	setSlugUniqueValue: PropTypes.func,
	setInitialState: PropTypes.func,
	loading: PropTypes.bool,
	dropdownTitle: PropTypes.string,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withConnect(CityManager);
