import {
	SELECT_NAMESPACE, ON_CONFIG_CHANGED, UPDATE_CONFIG, DISCARD_CONFIG_CHANGES, UPDATE_CONFIG_SUCCESS, UPDATE_CONFIG_ERROR, UNMOUNT_CONFIG_EDITOR_MESSAGE, LOAD_ALL_NAMESPACE, LOAD_ALL_NAMESPACE_SUCCESS, LOAD_ALL_NAMESPACE_ERROR, LOAD_CONFIGS_BY_NAMESPACE, LOAD_CONFIGS_BY_NAMESPACE_SUCCESS, LOAD_CONFIGS_BY_NAMESPACE_ERROR, LOAD_CONFIG_LATEST_UPDATE_TIMESTAMP, LOAD_CONFIG_LATEST_UPDATE_TIMESTAMP_SUCCESS, LOAD_CONFIG_LATEST_UPDATE_TIMESTAMP_ERROR, SET_CONFIG_USER, LOAD_BRANDS, LOAD_ALL_BRANDS_SUCCESS, LOAD_ALL_BRANDS_ERROR, ON_BRAND_CHANGE, UPDATE_BRANDS, CLEAR_CONFIG_DATA,
} from './type';

export function loadAllNamespace() {
	return {
		type: LOAD_ALL_NAMESPACE,
	};
}

export function loadAllNamespaceSuccess(namespaces) {
	return {
		type: LOAD_ALL_NAMESPACE_SUCCESS,
		namespaces,
	};
}

export function loadAllNamespaceFailure(error) {
	return {
		type: LOAD_ALL_NAMESPACE_ERROR,
		error,
	};
}

export function loadConfigsByNamespace(namespace) {
	return {
		type: LOAD_CONFIGS_BY_NAMESPACE,
		namespace,
	};
}

export function loadConfigsByNamespaceSuccess(allConfigs) {
	return {
		type: LOAD_CONFIGS_BY_NAMESPACE_SUCCESS,
		allConfigs,
	};
}

export function loadConfigsByNamespaceError(error) {
	return {
		type: LOAD_CONFIGS_BY_NAMESPACE_ERROR,
		error,
	};
}

export function selectNamespace(selectedNamespace) {
	return {
		type: SELECT_NAMESPACE,
		selectedNamespace,
	};
}

export function onConfigChanged(changedConfig, changedConfigValue) {
	return {
		type: ON_CONFIG_CHANGED,
		changedConfig,
		changedConfigValue,
	};
}

export function onUpdateConfig(updatedConfigs) {
	return {
		type: UPDATE_CONFIG,
		updatedConfigs,
	};
}

export function onUpdateConfigSuccess(successResponse) {
	return {
		type: UPDATE_CONFIG_SUCCESS,
		successResponse,
	};
}

export function onUpdateConfigFailure(failureMessage) {
	return {
		type: UPDATE_CONFIG_ERROR,
		failureMessage,
	};
}

export function onDiscardConfig() {
	return {
		type: DISCARD_CONFIG_CHANGES,
	};
}

export function unmountEditorMessage() {
	return {
		type: UNMOUNT_CONFIG_EDITOR_MESSAGE,
	};
}

export function loadConfigLastUpdatedAt() {
	return {
		type: LOAD_CONFIG_LATEST_UPDATE_TIMESTAMP,
	};
}

export function loadConfigLastUpdatedAtSuccess(timestamp) {
	return {
		type: LOAD_CONFIG_LATEST_UPDATE_TIMESTAMP_SUCCESS,
		timestamp,
	};
}

export function loadConfigLastUpdatedAtError(error) {
	return {
		type: LOAD_CONFIG_LATEST_UPDATE_TIMESTAMP_ERROR,
		error,
	};
}

export function setConfigUser(user) {
	return {
		type: SET_CONFIG_USER,
		user,
	};
}

export function loadAllBrands() {
	return {
		type: LOAD_BRANDS,
	};
}

export function brandsLoadedSuccessfully(brands) {
	return {
		type: LOAD_ALL_BRANDS_SUCCESS,
		brands,
	};
}

export function brandsLoadingError(error) {
	return {
		type: LOAD_ALL_BRANDS_ERROR,
		error,
	};
}

export function onBrandChange(brand, value, brandIndex) {
	return {
		type: ON_BRAND_CHANGE,
		brand,
		value,
		brandIndex,
	};
}

export function onUpdateBrand(updatedBrands) {
	return {
		type: UPDATE_BRANDS,
		updatedBrands,
	};
}

export function setInitialState() {
	return {
		type: CLEAR_CONFIG_DATA,
	};
}
