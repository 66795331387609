import styled from 'styled-components';
import { Button } from '../Styles';
import { PRIMARYCOLOR } from '../../utils/constants';

const Switch = styled.span`
		width: 100px;
		height: 40px;
		padding: 10px;
		border-radius: 2px;
		display: flex;
		align-items: center;
		position: relative;
		cursor: pointer;
		margin: 0;
		font-size:13px;
		font-weight:600;
`;

const SwitchLabel = styled.span`
		width: 50px;
		text-align: center;
		color: white;
		backgroundColor: ${(props) => props.backgroundColor};
`;

const Slider = styled.span`
		height: 40px;
		width: 50px;
		position: absolute;
		background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'white')};
		border: 1px solid #bbb;
		border-radius: 2px;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		transition: .4s;
		transform: translateX(0px);
`;

const PrimaryOutlineButton = styled(Button)`
		width: 100px;
		background: white;
		color: ${PRIMARYCOLOR};
		border: 1px solid ${PRIMARYCOLOR};
		margin-right: 20px;
`;

const PrimaryButton = styled(PrimaryOutlineButton)`
		background: ${PRIMARYCOLOR};
		color: white;
		&:hover {
						background: ${PRIMARYCOLOR};
						color: white;
				}
`;

const ButtonGroup = styled.div`
		display: inline-block;
		margin-top: 20px;
		margin-bottom: 20px;
`;

const TextAreaWrapper = styled.div`
		padding-top: 2.5rem;
		.text-area-border {
				border: 1px solid #eee;
		}
`;

export {
	Switch, SwitchLabel, Slider, PrimaryOutlineButton, PrimaryButton, ButtonGroup, TextAreaWrapper,
};
