export const AWARD_API_BASE_URL = '/api/v2/rw/awards';

export const AWARD_LOADING_MESSAGE = { intent: 'info', message: 'Loading...' };
export const AWARD_CREATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Award Saved Successfully' };
export const AWARD_CREATE_LOAD_MESSAGE = { intent: 'info', message: 'Saving...' };
export const AWARD_UPDATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Award Updated Successfully' };
export const AWARD_UPDATE_LOAD_MESSAGE = { intent: 'info', message: 'Updating...' };
export const AWARD_DELETE_SUCCESS_MESSAGE = { intent: 'success', message: 'Award Deleted Successfully' };
export const AWARD_DELETE_LOAD_MESSAGE = { intent: 'info', message: 'Deleting...' };
export const AWARD_ERROR_MESSAGE = { intent: 'danger', message: 'ERROR!!!' };
