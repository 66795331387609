import React from 'react';
import T from 'prop-types';

const TwitterPreviewContent = (props) => {
	const { title, excerpt } = props;
	const twitterTitle = title ? `${title.substring(0, 80)}${title.length > 80 ? '...' : ''}` : 'Title';

	return (
		<div className="p-2">
			<h3>{twitterTitle}</h3>
			<p style={{ fontSize: '0.9rem' }}>
				{excerpt || <span className="light-text">Description</span>}
			</p>
			<p className="light-text mb-0">yourstory.com</p>
		</div>
	);
};

TwitterPreviewContent.propTypes = {
	title: T.string,
	excerpt: T.string,
};

export default TwitterPreviewContent;
