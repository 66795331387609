import React from 'react';
import PropTypes from 'prop-types';

import { Modal, Button } from '../Styles';

const ModalSelector = (props) => (<Modal>
	<div className="modal-float">
		<div className="modal-wrapper">
			<div className="modal-content" style={{ maxWidth: props.width }}>
				<div className="text-right">
					{props.close ? <Button light light_outline className="b-0 p-0 sm-text light-text" onClick={props.close}>
						<i className="fa fa-times" />
					</Button> : null}
				</div>
				{props.children}
			</div>
		</div>
	</div>
</Modal>);

ModalSelector.defaultProps = {
	width: '300px',
};

ModalSelector.propTypes = {
	children: PropTypes.node.isRequired,
	close: PropTypes.func,
	width: PropTypes.string,
};

export default ModalSelector;
