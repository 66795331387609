import { Map } from 'immutable';
import {
	ADD_NEW_PERSON,
	PERSON_ELEMENT_ON_CHANGE,
	CREATE_PERSON,
	CREATE_PERSON_SUCCESS,
	CREATE_PERSON_ERROR,
	SELECT_PERSON,
	UNMOUNT_PEOPLE_FORM_MESSAGE,
	UPDATE_PERSON,
	UPDATE_PERSON_ERROR,
	UPDATE_PERSON_SUCCESS,
	DELETE_PERSON,
	DELETE_PERSON_ERROR,
	DELETE_PERSON_SUCCESS,
	ADD_PERSON_AWARD,
	REMOVE_PERSON_AWARD,
	CLEAR_INFLUENCER_MANAGER_DATA,
} from './type';

import {
	PERSON_CREATE_LOAD_MESSAGE,
	PERSON_CREATE_SUCCESS_MESSAGE,
	PERSON_ERROR_MESSAGE,
	PERSON_DELETE_LOAD_MESSAGE,
	PERSON_DELETE_SUCCESS_MESSAGE,
	PERSON_UPDATE_SUCCESS_MESSAGE,
	PERSON_UPDATE_LOAD_MESSAGE,
} from './constants';

const awardObj = {
	id: '',
	name: '',
	label: '',
	value: '',
	rank: '',
	description: '',
};

const initial_state = Map({
	formData: {
		name: '',
		email: '',
		phone: '',
		subtitle: '',
		bio: '',
		facebook: '',
		linkedin: '',
		twitter: '',
		wikipedia: '',
		website: '',
		awards: [awardObj],
		image: null,
		banner: null,
		imageFile: null,
		bannerImageFile: null,
		status: 'INREVIEW',
	},
	unchangedFormData: {
		name: '',
		email: '',
		phone: '',
		subtitle: '',
		bio: '',
		facebook: '',
		linkedin: '',
		twitter: '',
		wikipedia: '',
		website: '',
		awards: [awardObj],
		image: null,
		banner: null,
		imageFile: null,
		bannerImageFile: null,
		status: 'INREVIEW',
	},
	isInfoUpdated: false,
	operation: null,
	selectedPerson: null,
	formMessage: null,
	loading: false,
});

function peopleReducer(state = initial_state, action) {
	switch (action.type) {
	case ADD_NEW_PERSON: {
		const form = {
			name: '',
			email: '',
			phone: '',
			subtitle: '',
			bio: '',
			facebook: '',
			linkedin: '',
			twitter: '',
			wikipedia: '',
			website: '',
			awards: [{ rank: '', description: '' }],
			image: { url: null },
			banner: { url: null },
			status: 'INREVIEW',
		};
		return state
			.set('operation', 'add')
			.set('formData', form)
			.set('formMessage', null)
			.set('selectedPerson', null)
			.set('dropdownTitle', null)
			.set('unchangedFormData', form)
			.set('isInfoUpdated', false);
	}
	case PERSON_ELEMENT_ON_CHANGE: {
		const newState = { ...state.get('formData') };
		if (action.elementData.name === 'awards') {
			let value = { ...action.elementData.value };
			if (action.elementData.value === null) {
				value = {
					id: '',
					name: '',
					label: '',
					value: '',
				};
			}
			newState.awards[action.index] = { ...newState.awards[action.index], ...value };
		} else {
			newState[action.elementData.name] = action.elementData.value;
			if (action.elementData.name === 'name') {
				const slug = (action.elementData.value).replace(/ /g, '-').replace(/[^A-Za-z0-9-]/g, '').toLowerCase();
				newState.slug = slug;
			}
		}
		return state
			.set('formData', newState);
	}

	case SELECT_PERSON: {
		const { selectedPerson } = action;
		if (!selectedPerson || Object.keys(selectedPerson).length < 1) {
			const form = {
				name: '',
				email: '',
				phone: '',
				subtitle: '',
				bio: '',
				awards: [],
				facebook: '',
				linkedin: '',
				twitter: '',
				wikipedia: '',
				website: '',
				image: { url: null },
				banner: { url: null },
				status: 'INREVIEW',
			};

			return state
				.set('operation', null)
				.set('selectedPerson', null)
				.set('formData', form)
				.set('dropdownTitle', null)
				.set('unchangedFormData', form)
				.set('isInfoUpdated', false);
		}
		let updates;
		const form = {
			name: action.selectedPerson.name,
			email: action.selectedPerson.email,
			phone: action.selectedPerson.phoneNumber,
			bio: action.selectedPerson.bio,
			subtitle: action.selectedPerson.subtitle,
			website: action.selectedPerson.website,
			image: action.selectedPerson.profilePicture ? action.selectedPerson.profilePicture : null,
			banner: action.selectedPerson.banner ? action.selectedPerson.banner : null,
			awards: action.selectedPerson.awards ? action.selectedPerson.awards : [awardObj],
			status: action.selectedPerson.status ? action.selectedPerson.status : 'INREVIEW',
		};

		if (action.selectedPerson.socialLinks) {
			form.facebook = action.selectedPerson.socialLinks.facebook ? action.selectedPerson.socialLinks.facebook : '';
			form.linkedin = action.selectedPerson.socialLinks.linkedin ? action.selectedPerson.socialLinks.linkedin : '';
			form.twitter = action.selectedPerson.socialLinks.twitter ? action.selectedPerson.socialLinks.twitter : '';
			form.wikipedia = action.selectedPerson.socialLinks.wikipedia ? action.selectedPerson.socialLinks.wikipedia : '';
		}

		if (action.selectedPerson.updates && action.selectedPerson.updates.value) {
			const updatedData = action.selectedPerson.updates.value;
			updates = {
				name: updatedData.name,
				email: updatedData.email,
				phone: updatedData.phoneNumber,
				bio: updatedData.bio,
				subtitle: updatedData.subtitle,
				website: updatedData.website,
				image: updatedData.profilePicture ? updatedData.profilePicture : action.selectedPerson.profilePicture ? action.selectedPerson.profilePicture : null,
				banner: updatedData.banner ? updatedData.banner : null,
				awards: updatedData.awards ? JSON.parse(updatedData.awards) : [awardObj],
				status: updatedData.status ? updatedData.status : 'INREVIEW',
			};

			if (updatedData.socialLinks) {
				updates.facebook = updatedData.socialLinks.facebook ? updatedData.socialLinks.facebook : '';
				updates.linkedin = updatedData.socialLinks.linkedin ? updatedData.socialLinks.linkedin : '';
				updates.twitter = updatedData.socialLinks.twitter ? updatedData.socialLinks.twitter : '';
				updates.wikipedia = updatedData.socialLinks.wikipedia ? updatedData.socialLinks.wikipedia : '';
			}
		}
		return state
			.set('isInfoUpdated', !!(updates && action.selectedPerson.status !== 'INREVIEW'))
			.set('selectedPerson', action.selectedPerson)
			.set('formData', updates || form)
			.set('unchangedFormData', form)
			.set('operation', 'edit')
			.set('uniqueSlug', true)
			.set('formMessage', null)
			.set('dropdownTitle', action.selectedPerson);
	}
	case CREATE_PERSON:
		return state
			.set('formMessage', PERSON_CREATE_LOAD_MESSAGE)
			.set('loading', true);
	case CREATE_PERSON_SUCCESS:
		return state
			.set('selectedAward', null)
			.set('formMessage', PERSON_CREATE_SUCCESS_MESSAGE)
			.set('loading', false);
	case CREATE_PERSON_ERROR:
		return state
			.set('formMessage', PERSON_ERROR_MESSAGE)
			.set('loading', false);
	case UNMOUNT_PEOPLE_FORM_MESSAGE:
		return state
			.set('formMessage', null);
	case UPDATE_PERSON:
		return state
			.set('formMessage', PERSON_UPDATE_LOAD_MESSAGE)
			.set('loading', true);
	case UPDATE_PERSON_SUCCESS: {
		const curData = state.get('unchangedFormData');
		return state
			.set('isInfoUpdated', curData.status !== 'INREVIEW')
			.set('selectedAward', null)
			.set('formMessage', PERSON_UPDATE_SUCCESS_MESSAGE)
			.set('loading', false);
	}
	case UPDATE_PERSON_ERROR:
		return state
			.set('formMessage', PERSON_ERROR_MESSAGE)
			.set('loading', false);
	case DELETE_PERSON:
		return state
			.set('formMessage', PERSON_DELETE_LOAD_MESSAGE)
			.set('loading', true);
	case DELETE_PERSON_SUCCESS:
		return state
			.set('selectedAward', null)
			.set('formMessage', PERSON_DELETE_SUCCESS_MESSAGE)
			.set('loading', false);
	case DELETE_PERSON_ERROR:
		return state
			.set('formMessage', PERSON_ERROR_MESSAGE)
			.set('loading', false);
	case ADD_PERSON_AWARD: {
		const newState = { ...state.get('formData') };
		newState.awards.push(awardObj);
		return state
			.set('formData', newState);
	}
	case REMOVE_PERSON_AWARD: {
		const newState = { ...state.get('formData') };
		newState.awards.splice(action.index, 1);
		return state
			.set('formData', newState);
	}
	case CLEAR_INFLUENCER_MANAGER_DATA:
		return initial_state;
	default:
		return state;
	}
}

export default peopleReducer;
