import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col } from 'react-styled-flexboxgrid';
import moment from 'moment-timezone';
import { ListItemWrapper, Button } from '../BoniManager/BoniBrandManagerList/styles';
import 'rc-pagination/assets/index.css';
import ConfirmationModal from '../FormComponents/ConfirmationModal';

const WebstoryList = (props) => {
	const history = useHistory();
	const [isTrue, setIsTrue] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState('');
	const [activateStatus, setActivateStatus] = useState('Deactivate');
	const [activateTitle, setActivateTitle] = useState(false);
	const [reqId, setReqId] = useState(id);

	const {
		id,
		snNo,
		title,
		brandCategory,
		brand,
		url,
		updatedAt,
		status,
		handleActions,
		lastIndex,
	} = props;

	const handleActivateModal = () => {
		setActivateTitle(true);
		setIsTrue(!isTrue);
		setActivateStatus(!activateStatus);
	};

	const handleModal = () => {
		setIsTrue(!isTrue);
		setActivateTitle(false);
	};

	const handleConfirm = ({ method, id, position }) => {
		const callBack = () => {
			setIsTrue(!isTrue);
		};
		handleActions({
			id: id || reqId,
			method: method || (activateTitle ? 'PATCH' : 'DELETE'),
			selectedStatus: selectedStatus.status,
			position,
			callBack,
		});
	};

	const handleCancel = () => {
		setIsTrue(!isTrue);
	};

	return (
		<>
			<ListItemWrapper id={snNo} style={{ padding: '15px 0' }} className="py-2">
				<Col xs={false} md={1} className="text-center">
					<span>{snNo}.</span>
				</Col>
				<Col xs={6} md={3} className="text-center">
					<span>{title}</span>
				</Col>
				<Col xs={false} md={3} className="text-center">
					<span>{moment(updatedAt).format('DD-MM-YYYY') || 'Not Found'}</span>
				</Col>
				<Col xs={false} md={3} className="text-center">
					<a style={{ color: 'blue', textDecoration: 'underline' }} href={url} target="_blank" rel="noreferrer">{url || '-'}</a>
				</Col>
				<Col xs={false} md={3} className="text-center d-none">
					<span>{brandCategory || 'Not Found'}</span>
				</Col>

				<Col xs={false} md={1} className="text-center d-none">
					<span>{brand || 'Not Found'}</span>
				</Col>

				<Col xs={false} md={1} className="text-center d-none">
					<span>{status}</span>
				</Col>
				<Col xs={false} md={2} className="text-center">
					{
						// action.map((data, index) =>
						<div className="action">
							<Button onClick={() => handleConfirm({ method: 'PATCH', id, position: 'up' })} title="move up">
								{snNo === 1 ? <></> : <i className="fa fa-arrow-circle-up fa-lg" />}
							</Button>
							<Button onClick={() => handleConfirm({ method: 'PATCH', id, position: 'down' })} title="move down" style={snNo === lastIndex ? { display: 'none' } : { }}>
								{
									// snNo === lastIndex ? <></>
									<i className="fas fa-arrow-circle-down fa-lg" />
                                }
							</Button>
							<Button
								className="d-none"
								title={`${status === 'ACTIVE' ? 'Deactivate' : 'Activate'}`}
								onClick={() => {
									handleActivateModal();
									setReqId(id);
									setSelectedStatus({ status: status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' });
								}}
							>
								{
									status === 'ACTIVE'
										? <i className="fa fa-times-circle fa-lg" />
										: <i className="fas fa-check-circle  fa-lg" />
								}
							</Button>
							<Button
								onClick={() => {
									handleConfirm({ method: 'GET', id });
									history.push(`/manager/dashboard/webstory-editor/?id=${id}`);
								}}
							>
								<i className="fa fa-pencil-square fa-lg" />
							</Button>
							<Button
								onClick={() => {
									handleModal();
									setReqId(id);
								}}
							>
								<i className="fas fa-trash fa-lg" />
							</Button>
						</div>
					}

				</Col>
			</ListItemWrapper>

			<ConfirmationModal
				openDialog={isTrue}
				handleCancel={handleCancel}
				closeDialog={handleCancel}
				confirm={handleConfirm}
				message={
					activateTitle
						? `Are you sure you would like to ${status === 'ACTIVE'
							? 'Deactivate'
							: 'Activate'} the web story ? `
						: 'Are you sure you would like to delete the web story ? This action is irreversible.'
				}
			/>
		</>
	);
};

export default WebstoryList;
WebstoryList.propTypes = {
	id: PropTypes.string,
	snNo: PropTypes.number,
	title: PropTypes.string,
	brandCategory: PropTypes.string,
	url: PropTypes.string,
	brand: PropTypes.string,
	updatedAt: PropTypes.any,
	status: PropTypes.string,
	handleActions: PropTypes.any,
	lastIndex: PropTypes.number,
};
