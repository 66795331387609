import React, {
	useEffect, useState, useReducer, Fragment,
} from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import 'rc-pagination/assets/index.css';
import T from 'prop-types';
import {
	Input, Button,
} from '../../../components/Styles';
// import ToastMessage from '../../../components/ToastMessage';
import { AsyncMultiSelect, AsyncSingleSelectWithDefault, TitleWithTooltip } from '../../../components/FormComponents';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import LoadingIcon from '../../../components/LoadingIcon/LoadingIcon';
import {
	GET_PROFILE_DATA,
	FETCH_PROFILE_DATA,
	FETCH_PROFILE_DATA_SUCCESS,
	FETCH_PROFILE_DATA_FAILURE,
	UPDATE_TALENT_BASE_URL,
	SET_ALL_ELEMENTS_BLURRED,
	UPDATE_ERRORS,
	UPDATE_BLURRED_ELEMENT,
	UPDATE_ACTIONS_ERROR,
	UPDATE_ACTIONS_SUCCESS,
	UPDATE_ACTIONS_LOADER,
	SET_ACTIONS_MESSAGE,
} from '../constants';
import {
	returnAsyncFilterValue, returnOnlyValueIncludedArray,
	removeDuplicatesFromAsyncMultiSelect, filterProfileData,
	jobProfileContactInfoValidation,
	getDropdownOptions,
	filterAsyncSelectOptionsTrue,
} from '../../../utils/common';
import { Slider, Switch, SwitchLabel } from '../../../components/DynamicConfigEditor/styles';
import WorkExperienceListPage from '../WorkExperienceList';

const initialState = {
	profileJobData: {},
	loading: false,
	error: false,
	actionLoading: false,
	actionError: false,
	actionMessage: '',
	blurredElements: {
		latestCompanyName: false,
		latestDesignation: false,
		primaryJobFunction: false,
		primaryJobExpertise: false,
		// secondaryJobFunction: false,
		// secondaryJobExpertise: false,
		industry: false,
		lastDrawnAnnualSalary: false,
		esopsInLastJob: false,
		totalWorkExperience: false,
		noticePeriod: false,
	},
	errors: {
		latestCompanyName: false,
		latestDesignation: false,
		primaryJobFunction: false,
		primaryJobExpertise: false,
		// secondaryJobFunction: false,
		// secondaryJobExpertise: false,
		industry: false,
		lastDrawnAnnualSalary: false,
		esopsInLastJob: false,
		totalWorkExperience: false,
		noticePeriod: false,
	},
};

function setActionsMessage(message) {
	return {
		type: SET_ACTIONS_MESSAGE,
		message,
	};
}
function updateActionsLoading() {
	return {
		type: UPDATE_ACTIONS_LOADER,
	};
}

function updateActionsFailure() {
	return {
		type: UPDATE_ACTIONS_ERROR,
	};
}

function updateActionsSuccess() {
	return {
		type: UPDATE_ACTIONS_SUCCESS,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function fetchProfileJobData() {
	return {
		type: FETCH_PROFILE_DATA,
	};
}
function fetchProfileJobDataSuccess(profileJobData) {
	return {
		type: FETCH_PROFILE_DATA_SUCCESS,
		profileJobData,
	};
}
function fetchProfileJobDataFailure() {
	return {
		type: FETCH_PROFILE_DATA_FAILURE,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case FETCH_PROFILE_DATA:
		return { ...state, loading: true };
	case FETCH_PROFILE_DATA_SUCCESS:
		return {
			...state, loading: false, profileJobData: { ...action.profileJobData },
		};
	case FETCH_PROFILE_DATA_FAILURE:
		return {
			...state, loading: false, error: true, profileJobData: {},
		};
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };
	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			latestCompanyName: true,
			latestDesignation: true,
			primaryJobFunction: true,
			primaryJobExpertise: true,
			// secondaryJobFunction: false,
			// secondaryJobExpertise: false,
			industry: true,
			lastDrawnAnnualSalary: true,
			esopsInLastJob: true,
			totalWorkExperience: true,
			noticePeriod: true,
		};
		return { ...state, blurredElements };
	}
	case UPDATE_ACTIONS_ERROR:
		return {
			...state, actionLoading: false, actionError: true,
		};
	case UPDATE_ACTIONS_SUCCESS:
		return {
			...state, actionLoading: false, actionError: false,
		};
	case UPDATE_ACTIONS_LOADER:
		return {
			...state, actionLoading: true, actionError: false,
		};
	case SET_ACTIONS_MESSAGE:
		return {
			...state, actionMessage: action.message,
		};
	default:
		return state;
	}
}

const WorkExperienceProfilePage = (props) => {
	const { match, history } = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		loading, errors, blurredElements, actionLoading, actionError, actionMessage, profileJobData,
	} = state;
	const [profileData, setProfileData] = useState({
		latestCompanyName: '',
		latestDesignation: '',
		primaryJobFunction: {},
		primaryJobExpertise: [],
		secondaryJobFunction: {},
		secondaryJobExpertise: [],
		industry: [],
		lastDrawnAnnualSalary: '',
		esopsInLastJob: '',
		totalWorkExperience: '',
		noticePeriod: {},
		primaryJobExpOptionsList: [],
		secondaryJobExpOptionsList: [],
	});

	useEffect(() => {
		dispatch(updateErrors({
			latestCompanyName: false,
			latestDesignation: false,
			primaryJobFunction: false,
			primaryJobExpertise: false,
			// secondaryJobFunction: false,
			// secondaryJobExpertise: false,
			industry: false,
			lastDrawnAnnualSalary: false,
			esopsInLastJob: false,
			totalWorkExperience: false,
			noticePeriod: false,
		}));
		dispatch(updateBlurredElement({
			latestCompanyName: false,
			latestDesignation: false,
			primaryJobFunction: false,
			primaryJobExpertise: false,
			// secondaryJobFunction: false,
			// secondaryJobExpertise: false,
			industry: false,
			lastDrawnAnnualSalary: false,
			esopsInLastJob: false,
			totalWorkExperience: false,
			noticePeriod: false,
		}));
		dispatch(setAllElementsBlurred());
		if (match?.params?.profileId) {
			getProfileData(match.params.profileId);
		}
	}, []);

	async function getProfileData(profileId) {
		dispatch(fetchProfileJobData());
		const url = `${GET_PROFILE_DATA}${profileId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				const profileDetails = await response.json();
				if (profileDetails && profileDetails?.id?.length > 0) {
					setProfileData({
						...filterProfileData(profileDetails),
						latestCompanyName: profileDetails.latestCompanyName,
						latestDesignation: profileDetails.latestDesignation,
						primaryJobFunction: {
							value: profileDetails.primaryJobFunction,
							label: profileDetails.primaryJobFunction,
						},
						primaryJobExpertise: returnAsyncFilterValue(profileDetails.primaryJobExpertise),
						secondaryJobFunction: {
							value: profileDetails.secondaryJobFunction,
							label: profileDetails.secondaryJobFunction,
						},
						secondaryJobExpertise: returnAsyncFilterValue(profileDetails.secondaryJobExpertise),
						industry: returnAsyncFilterValue(profileDetails.industry),
						lastDrawnAnnualSalary: profileDetails.lastDrawnAnnualSalary,
						esopsInLastJob: profileDetails.esopsInLastJob.toLowerCase() === 'yes',
						totalWorkExperience: profileDetails.totalWorkExperience,
						noticePeriod: {
							value: profileDetails.noticePeriod,
							label: profileDetails.noticePeriod,
						},
					});
				}
				dispatch(fetchProfileJobDataSuccess(profileDetails));
			} else {
				dispatch(fetchProfileJobDataFailure());
			}
		} catch (err) {
			dispatch(fetchProfileJobDataFailure());
		}
	}
	const saveProfileDataForUpdate = async () => {
		dispatch(setActionsMessage(''));
		dispatch(updateActionsLoading());
		const url = UPDATE_TALENT_BASE_URL;
		const formData = {
			...profileData,
			profileId: match.params.profileId,
			latestCompanyName: profileData.latestCompanyName,
			latestDesignation: profileData.latestDesignation,
			primaryJobFunction: profileData.primaryJobFunction.label,
			primaryJobExpertise: returnOnlyValueIncludedArray(profileData.primaryJobExpertise),
			secondaryJobFunction: profileData.secondaryJobFunction.label,
			secondaryJobExpertise: returnOnlyValueIncludedArray(profileData.secondaryJobExpertise),
			industry: returnOnlyValueIncludedArray(profileData.industry),
			lastDrawnAnnualSalary: profileData.lastDrawnAnnualSalary,
			esopsInLastJob: profileData.esopsInLastJob.toString().toLowerCase() === 'true' ? 'Yes' : 'No',
			totalWorkExperience: profileData.totalWorkExperience,
			noticePeriod: profileData.noticePeriod.label,
			switchFunctionIndustry: 'Yes',
			prefIndustry: profileData?.prefIndustry ? profileData?.prefIndustry : '',
			prefCity: profileData?.prefCity ? profileData?.prefCity : [],
			// workHistory: profileData.workHistory,
		};
		const options = {
			method: 'PATCH',
			credentials: 'include',
			body: JSON.stringify(formData),
			headers: {
				'Content-Type': 'application/json',
			},
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				dispatch(updateActionsSuccess());
				dispatch(setActionsMessage('Work Experiences Details are updated successfully'));
				setTimeout(() => {
					dispatch(setActionsMessage(''));
					dispatch(fetchProfileJobDataSuccess({
						...profileJobData,
						profileId: match.params.profileId,
						latestCompanyName: profileData.latestCompanyName,
						latestDesignation: profileData.latestDesignation,
						primaryJobFunction: profileData.primaryJobFunction.label,
						primaryJobExpertise: returnOnlyValueIncludedArray(profileData.primaryJobExpertise),
						secondaryJobFunction: profileData.secondaryJobFunction.label,
						secondaryJobExpertise: returnOnlyValueIncludedArray(profileData.secondaryJobExpertise),
						industry: returnOnlyValueIncludedArray(profileData.industry),
						lastDrawnAnnualSalary: profileData.lastDrawnAnnualSalary,
						esopsInLastJob: profileData.esopsInLastJob.toString().toLowerCase() === 'true' ? 'Yes' : 'No',
						totalWorkExperience: profileData.totalWorkExperience,
						noticePeriod: profileData.noticePeriod.label,
						switchFunctionIndustry: 'Yes',
						prefIndustry: profileData?.prefIndustry ? profileData?.prefIndustry : '',
						prefCity: profileData?.prefCity ? profileData?.prefCity : [],
						workHistory: profileData.workHistory,
					}));
				}, 2000);
			} else {
				dispatch(updateActionsFailure());
				dispatch(setActionsMessage('Not Able To Update The Job profile'));
				setTimeout(() => {
					dispatch(updateActionsSuccess());
				}, 2000);
			}
		} catch (err) {
			dispatch(updateActionsFailure());
			dispatch(setActionsMessage('Not Able To Update The Job profile'));
			setTimeout(() => {
				dispatch(setActionsMessage(''));
				dispatch(updateActionsSuccess());
			}, 2000);
		}
	};
	function validateForm() {
		return {
			latestCompanyName: !(profileData.latestCompanyName && profileData.latestCompanyName?.length > 0),
			latestDesignation: !(profileData.latestDesignation && profileData.latestDesignation?.length > 0),
			primaryJobFunction: !(profileData.primaryJobFunction && profileData.primaryJobFunction?.value?.length > 0),
			primaryJobExpertise: !(profileData.primaryJobExpertise && profileData.primaryJobExpertise?.length > 0),
			industry: !(profileData.industry && profileData.industry?.length > 0),
			lastDrawnAnnualSalary: !(profileData.lastDrawnAnnualSalary && profileData.lastDrawnAnnualSalary?.toString().length > 0),
			esopsInLastJob: !(profileData.esopsInLastJob?.toString()?.length > 0),
			totalWorkExperience: !(profileData.totalWorkExperience && profileData.totalWorkExperience?.toString().length > 0),
			noticePeriod: !(profileData.noticePeriod && profileData.noticePeriod?.value?.length > 0),
		};
	}
	const handleErrors = () => {
		const validation = validateForm();
		const errors = Object.keys(validation).reduce((acc, curr) => {
			if (validation[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		const validation = validateForm();
		const validated = !Object.keys(validation).some((i) => validation[i]);
		handleErrors();
		if (validated) {
			saveProfileDataForUpdate();
		} else {
			dispatch(setAllElementsBlurred());
		}
	};
	const validateSalaryValue = (event) => {
		if (event.target.value.length === 1 && event.which === 48) {
			return event.preventDefault();
		}
		const validSalary = jobProfileContactInfoValidation(event.target.value, 5);
		/* eslint-disable no-useless-escape */
		return validSalary ? event.target.value.toString().replace(/,/g, '') : '';
	};
	const validateWorkExperienceYearValue = (event) => {
		const validWorkExpYear = jobProfileContactInfoValidation(event.target.value, 6);
		return validWorkExpYear ? event.target.value : '';
	};

	const getJobExpertiseOptionList = async (caseVal, value) => {
		let result = [];
		result = await getDropdownOptions('secondary-job-function', 'secondary-job-function', value.label.toLowerCase());
		if (result && result?.length > 0) {
			if (caseVal === 1) {
				setProfileData({
					...profileData,
					primaryJobFunction: value,
					primaryJobExpertise: [],
					primaryJobExpOptionsList: result,
				});
			} else if (caseVal === 2) {
				setProfileData({
					...profileData,
					secondaryJobFunction: value,
					secondaryJobExpertise: [],
					secondaryJobExpOptionsList: result,
				});
			}
		}
	};
	const updateWorkHistoryDetails = (data) => {
		setProfileData(({
			...profileData,
			workHistory: [...data],
		}));
	};
	return (
		<div>
			{
				loading
					? <LoadingIcon />
					: <>
						<div>
							{/* <Row>
								<Col xs={8} sm={8}>
									<h3 className="px-3">
										Edit Work Experience Details
									</h3>
								</Col>
								<Col xs={4} sm={4} className="text-right">
									<Button
										primary
										no_radius
										className="mb-0 mr-2"
										onClick={() => window.open(`/admin-job/work-experience/editor/${match.params.profileId}`)}
									>
										<b>VIEW WORK EXPERIENCE HISTORY</b>
									</Button>
								</Col>
							</Row> */}
							<Row>
								<Col xs={12}>
									{profileJobData && <WorkExperienceListPage {...props} updatedProfileJobData={profileJobData} updateWorkHistoryDetails={updateWorkHistoryDetails} />}
								</Col>
							</Row>
							<form onSubmit={handleSubmit}>
								<Col xs={12} sm={12}>
									<h3 className="pt-3 pb-2">
										Edit Work Experience Details
									</h3>
								</Col>
								<Row className="m-2">
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Current/Last Company Name *"
												hint="Current/Last Company Name*"
											/>
											<Input
												type="text"
												className="form-control"
												value={profileData.latestCompanyName}
												name="candidateLatestCompanyName"
												autoComplete="off"
												maxLength="1000"
												placeholder="eg: StartBucks"
												onChange={(e) => {
													setProfileData(({
														...profileData,
														latestCompanyName: e.target.value,
													}));
													handleErrors();
												}}
											/>
											<ErrorMessage display={errors.latestCompanyName} element="Current/Last Company Name" />
										</div>
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Designation *"
												hint="Designation*"
											/>
											<Input
												type="text"
												className="form-control"
												value={profileData.latestDesignation}
												name="latestDesignation"
												autoComplete="off"
												maxLength="1000"
												placeholder="eg: Director Of Product"
												onChange={(e) => {
													setProfileData(({
														...profileData,
														latestDesignation: e.target.value,
													}));
													handleErrors();
												}}
											/>
											<ErrorMessage display={errors.latestCompanyName} element="Designation Name" />
										</div>
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Primary Job Function *"
												hint="Primary Job Function"
											/>
											<AsyncSingleSelectWithDefault
												name="primaryJobFunction"
												placeholder="Select From List"
												defaultOptionsList
												options={(value) => filterAsyncSelectOptionsTrue(value, 'primary-job-function', '', null)}
												value={profileData.primaryJobFunction}
												handleChange={(value) => {
													setProfileData({
														...profileData,
														primaryJobFunction: value,
														primaryJobExpertise: [],
													});
													getJobExpertiseOptionList(1, value);
												}}
											/>
											<ErrorMessage display={errors.primaryJobFunction} element="Primary Job Function" />
										</div>
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Primary Job Expertise*"
												hint="Primary Job Expertise"
											/>
											<AsyncMultiSelect
												placeholder="Select From List"
												defaultOptionsList={(profileData.primaryJobExpOptionsList && profileData.primaryJobExpOptionsList?.length > 0) ? profileData.primaryJobExpOptionsList
													: true}
												options={(value) => filterAsyncSelectOptionsTrue(value, 'primary-job-function', '', profileData.primaryJobFunction.label)}
												currentValues={profileData.primaryJobExpertise}
												handleChange={(value) => {
													setProfileData({
														...profileData,
														primaryJobExpertise: removeDuplicatesFromAsyncMultiSelect(value),
													});
												}}
												profile="primaryJobExpertise"
											/>
											<ErrorMessage display={errors.primaryJobExpertise} element="Primary Job Expertise" />
										</div>
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Secondary Job Function"
												hint="Secondary Job Function"
											/>
											<AsyncSingleSelectWithDefault
												name="secondaryJobFunction"
												placeholder="Select From List"
												defaultOptionsList
												options={(value) => filterAsyncSelectOptionsTrue(value, 'primary-job-function', '', null)}
												value={profileData.secondaryJobFunction}
												handleChange={(value) => {
													setProfileData({
														...profileData,
														secondaryJobFunction: value,
														secondaryJobExpertise: [],
													});
													getJobExpertiseOptionList(2, value);
												}}
											/>
											<ErrorMessage display={errors.secondaryJobFunction} element="Secondary Job Function" />
										</div>
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Secondary Job Expertise*"
												hint="Secondary Job Expertise"
											/>
											<AsyncMultiSelect
												placeholder="Select From List"
												defaultOptionsList={(profileData.secondaryJobExpOptionsList && profileData.secondaryJobExpOptionsList?.length > 0) ? profileData.secondaryJobExpOptionsList
													: true}
												options={(value) => filterAsyncSelectOptionsTrue(value, 'secondary-job-function', '', profileData.secondaryJobFunction.label)}
												currentValues={profileData.secondaryJobExpertise}
												handleChange={(value) => {
													setProfileData({
														...profileData,
														secondaryJobExpertise: removeDuplicatesFromAsyncMultiSelect(value),
													});
												}}
												profile="secondaryJobExpertise"
											/>
											<ErrorMessage display={errors.secondaryJobExpertise} element="Secondary Job Expertise" />
										</div>
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Industry Job*"
												hint="Industry Job"
											/>
											<AsyncMultiSelect
												placeholder="Select From List"
												defaultOptionsList
												options={(value) => filterAsyncSelectOptionsTrue(value, 'job-industry', '', null)}
												currentValues={profileData.industry}
												handleChange={(value) => {
													setProfileData({
														...profileData,
														industry: removeDuplicatesFromAsyncMultiSelect(value),
													});
												}}
												profile="industryJob"
											/>
											<ErrorMessage display={errors.industry} element="Industry Job" />
										</div>
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Last drawn annual salary in INR *"
												hint="Last drawn annual salary in INR *"
											/>
											{/* eslint-disable no-useless-escape */}
											<Input
												type="text"
												className="form-control"
												value={profileData.lastDrawnAnnualSalary.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}
												name="lastDrawnAnnualSalary"
												autoComplete="off"
												maxLength="1000"
												placeholder="eg: 3,60,000"
												onChange={(e) => {
													setProfileData(({
														...profileData,
														lastDrawnAnnualSalary: validateSalaryValue(e),
													}));
													handleErrors();
												}}
											/>
											<ErrorMessage display={errors.lastDrawnAnnualSalary} element="Drawn Annual Salary" />
										</div>
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Total work experience in years *"
												hint="Total work experience in years*"
											/>
											<Input
												type="text"
												className="form-control"
												value={profileData.totalWorkExperience}
												name="totalWorkExperience"
												autoComplete="off"
												maxLength="1000"
												placeholder="eg: 1 years"
												onChange={(e) => {
													setProfileData(({
														...profileData,
														totalWorkExperience: validateWorkExperienceYearValue(e),
													}));
													handleErrors();
												}}
											/>
											<ErrorMessage display={errors.totalWorkExperience} element="Total Work Experience" />
										</div>
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Esops in your last job *"
												hint="Esops in your last job"
											/>
											<Switch
												id="toggle-btn"
												style={{ backgroundColor: !profileData.esopsInLastJob ? '#e5002d' : '#28a745' }}
												onClick={() => setProfileData(({
													...profileData,
													esopsInLastJob: !profileData.esopsInLastJob,
												}))}
											>
												<SwitchLabel backgroundColor="#28a745">Yes</SwitchLabel>
												<SwitchLabel backgroundColor="#e5002d">No</SwitchLabel>
												{profileData.esopsInLastJob
													? <Slider id="slider" style={{ transform: 'translateX(50px)' }} />
													: <Slider id="slider" />}
											</Switch>
											<ErrorMessage display={errors.esopsInLastJob} element="Esops in your last job" />
										</div>
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Notice Period *"
												hint="Notice Period"
											/>
											<AsyncSingleSelectWithDefault
												name="noticePeriod"
												placeholder="Select From List"
												defaultOptionsList
												options={(value) => filterAsyncSelectOptionsTrue(value, 'notice-period', '', null)}
												value={profileData.noticePeriod}
												handleChange={(value) => {
													setProfileData({ ...profileData, noticePeriod: value });
												}}
											/>
											<ErrorMessage display={errors.noticePeriod} element="Notice Period" />
										</div>
									</Col>
									<Col xs={12} sm={12} className="mt-2 text-center">
										<Button
											success
											no_radius
											type="submit"
											style={{ width: '150px' }}
										>
											<b>Save Changes</b>
										</Button>
									</Col>
									<Col xs={12} sm={12}>
										{actionLoading && <LoadingIcon />}
									</Col>
									<Col xs={12} sm={12}>
										{(actionError && actionMessage) && <p className="error error-text text-normal text-center mb-2">
											{actionMessage}
										</p>}
										{(!actionError && actionMessage) && <p className="success-text text-normal text-center mb-2">
											{actionMessage}
										</p>}
									</Col>
								</Row>
							</form>
						</div>
					</>
			}
		</div>
	);
};

WorkExperienceProfilePage.propTypes = {
	// status: T.number,
	history: T.object,
	match: T.object,
};
export default WorkExperienceProfilePage;
