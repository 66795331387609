import React from 'react';
import { Row, Col } from '../Responsive';
import { WrapperContainer } from '../Styles';

const STOP_WORDS = ['a', 'about', 'above', 'after', 'again', 'against', 'all', 'am', 'an', 'and', 'any', 'are', 'as', 'at', 'be', 'because', 'been', 'before', 'being', 'below', 'between', 'both', 'but', 'by', 'could', 'did', 'do', 'does', 'doing', 'down', 'during', 'each', 'few', 'for', 'from', 'further', 'had', 'has', 'have', 'having', 'he', "he'd", "he'll", "he's", 'her', 'here', "here's", 'hers', 'herself', 'him', 'himself', 'his', 'how', "how's", 'i', "i'd", "i'll", "i'm", "i've", 'if', 'in', 'into', 'is', 'it', "it's", 'its', 'itself', "let's", 'me', 'more', 'most', 'my', 'myself', 'nor', 'of', 'on', 'once', 'only', 'or', 'other', 'ought', 'our', 'ours', 'ourselves', 'out', 'over', 'own', 'same', 'she', "she'd", "she'll", "she's", 'should', 'so', 'some', 'such', 'than', 'that', "that's", 'the', 'their', 'theirs', 'them', 'themselves', 'then', 'there', "there's", 'these', 'they', "they'd", "they'll", "they're", "they've", 'this', 'those', 'through', 'to', 'too', 'under', 'until', 'up', 'very', 'was', 'we', "we'd", "we'll", "we're", "we've", 'were', 'what', "what's", 'when', "when's", 'where', "where's", 'which', 'while', 'who', "who's", 'whom', 'why', "why's", 'with', 'would', 'you', "you'd", "you'll", "you're", "you've", 'your', 'yours', 'yourself', 'yourselves'];

const StopwordsList = () => {
	function renderStopwordsItems() {
		const Items = STOP_WORDS.map((item, index) => (
			// eslint-disable-next-line react/no-array-index-key
			<Col key={index} md={2} xs={4}>
				<p style={{ fontSize: '1.3rem' }}>{item}</p>
			</Col>
		));
		return Items;
	}

	return (
		<div>
			<WrapperContainer>
				<h1>List of Stop Words</h1>
				<div className="global-highlight" />
				<Row className="mt-5">
					{renderStopwordsItems()}
				</Row>
			</WrapperContainer>
		</div>
	);
};

export default StopwordsList;
