import { Map } from 'immutable';
import {
	FETCH_PROFILES_BY_STATUS, FETCH_PROFILES_BY_STATUS_SUCCESS, FETCH_PROFILES_BY_STATUS_FAILURE, SET_DESK_PAGE, SET_DESK_PROFILES_STATUS, DISABLE_DESK_SEARCH_FLAG, RUN_PROFILE_DESK_SEARCH, RUN_PROFILE_DESK_SEARCH_SUCCESS, SET_ALTERNATE_PROFILE_ID,
} from './type';

const initialState = Map({
	loading: false,
	error: false,
	allProfiles: null,
	profiles: null,
	page: 1,
	status: 'inReview',
	search: false,
	searchValue: null,
	searchType: null,
	alternateProfileId: null,
});

function profileDeskReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_PROFILES_BY_STATUS:
		return state
			.set('loading', true)
			.set('error', false);
	case FETCH_PROFILES_BY_STATUS_SUCCESS:
		return state
			.set('loading', false)
			.set('allProfiles', action.profiles)
			.set('profiles', action.profiles);
	case FETCH_PROFILES_BY_STATUS_FAILURE:
		return state
			.set('loading', false)
			.set('error', action.error)
			.set('profiles', null);
	case SET_DESK_PAGE:
		return state
			.set('page', action.page);
	case SET_DESK_PROFILES_STATUS: {
		return state
			.set('status', action.status);
	}
	case DISABLE_DESK_SEARCH_FLAG:
		return state
			.set('profiles', state.get('allProfiles'))
			.set('searchValue', null)
			.set('search', false)
			.set('searchType', null);
	case RUN_PROFILE_DESK_SEARCH: {
		return state
			.set('search', true)
			.set('searchValue', action.searchValue)
			.set('searchType', action.searchType)
			.set('loading', true);
	}
	case RUN_PROFILE_DESK_SEARCH_SUCCESS:
		return state
			.set('profiles', action.searchResults)
			.set('loading', false);
	case SET_ALTERNATE_PROFILE_ID:
		return state
			.set('alternateProfileId', action.id);
	default:
		return state;
	}
}
export default profileDeskReducer;
