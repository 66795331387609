import {
	call, put, takeLatest, select,
} from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { redirectTo } from '../../utils/accessRedirection';
import {
	createCompanySuccess,
	createCompanyFailure,
	fetchBasicInfoSuccess,
	fetchBasicInfoFailure,
	updateBasicInfoSuccess,
	updateBasicInfoFailure,
	fetchAcquisitionsError,
	fetchAcquisitionsSuccess,
	fetchCompanyFinanceSuccess,
	fetchCompanyFinanceError,
	fetchCompanyPeopleError,
	fetchCompanyPeopleSuccess,
	fetchFundingSuccess,
	fetchFundingError,
	fetchCompanyProductsSuccess,
	fetchCompanyProductsError,
	fetchCompanyLocationsSuccess,
	fetchCompanyLocationsError,
	fetchAudienceSuccess,
	fetchAudienceError,
	fetchMilestonesSuccess,
	fetchMilestonesError,
	fetchCuratedListTypesSuccess,
	fetchProfileUpdateNotesSuccess,
} from './actions';
import { makeSelectLocation } from '../App/selectors';
import {
	CREATE_COMPANY,
	FETCH_BASIC_INFO,
	UPDATE_BASIC_INFO,
	FETCH_ACQUISITIONS,
	FETCH_COMPANY_FINANCE,
	FETCH_COMPANY_PEOPLE,
	FETCH_FUNDING,
	FETCH_COMPANY_PRODUCTS,
	FETCH_COMPANY_LOCATIONS,
	FETCH_AUDIENCE,
	FETCH_MILESTONES,
	FETCH_CURATED_LIST_TYPES,
	FETCH_PROFILE_UPDATE_NOTES,
} from './type';
import {
	COMPANY_API_BASE_URL,
	ACQUISITIONS_API_BASE_URL,
	COMPANY_FINANCE_API_BASE_URL,
	COMPANY_PEOPLE_API_BASE_URL,
	FUNDING_API_BASE_URL,
	COMPANY_PRODUCTS_API_BASE_URL,
	COMPANY_LOCATIONS_API_BASE_URL,
	COMPANY_MILESTONES_API_BASE_URL,
	COMPANY_AUDIENCE_API_BASE_URL,
	CURATED_LIST_API_BASE_URL,
} from './constants';

let CURRENT_LOCATION;
function* createCompany(action) {
	try {
		const url = `${COMPANY_API_BASE_URL}/create`;
		const options = {
			method: 'POST',
			credentials: 'include',
			body: action.company,
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(createCompanySuccess(responseMessage.data.Company));
				yield put(replace(`/company/editor/${responseMessage.data.Company.id}`));
				yield getBasicInfo({ companyId: responseMessage.data.Company.id });
			} else {
				yield put(createCompanyFailure(responseMessage.error));
			}
		}
	} catch (err) {
		yield put(createCompanyFailure(err));
	}
}

function* getBasicInfo(action) {
	try {
		const url = `${COMPANY_API_BASE_URL}/basic-info/${action.companyId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const basicInfo = yield response.json();
			yield put(fetchBasicInfoSuccess(basicInfo));
		}
	} catch (err) {
		yield put(fetchBasicInfoFailure(err));
	}
}

function* getAcquisitions(action) {
	try {
		const url = `${ACQUISITIONS_API_BASE_URL}/editor/companyid/${action.companyId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const acquisitions = yield response.json();
			yield put(fetchAcquisitionsSuccess(acquisitions));
		}
	} catch (err) {
		yield put(fetchAcquisitionsError(err));
	}
}

function* getCompanyFinance(action) {
	try {
		const url = `${COMPANY_FINANCE_API_BASE_URL}/editor/companyid/${action.companyId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const companyFinance = yield response.json();
			yield put(fetchCompanyFinanceSuccess(companyFinance));
		}
	} catch (err) {
		yield put(fetchCompanyFinanceError(err));
	}
}

function* getCompanyProducts(action) {
	try {
		const url = `${COMPANY_PRODUCTS_API_BASE_URL}/editor/companyid/${action.companyId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const companyFinance = yield response.json();
			yield put(fetchCompanyProductsSuccess(companyFinance));
		}
	} catch (err) {
		yield put(fetchCompanyProductsError(err));
	}
}

function* updateBasicInfo(action) {
	try {
		const url = `${COMPANY_API_BASE_URL}/basic-info/update/${action.id}`;
		const options = {
			method: 'PUT',
			credentials: 'include',
			body: action.basicInfo,
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(updateBasicInfoSuccess(responseMessage));
				yield getBasicInfo({ companyId: action.id });
			} else {
				yield put(updateBasicInfoFailure(responseMessage.error));
			}
		}
	} catch (err) {
		yield put(createCompanyFailure(err));
	}
}

function* getCompanyPeople(action) {
	try {
		const url = `${COMPANY_PEOPLE_API_BASE_URL}/editor/companyid/${action.companyId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const companyPeople = yield response.json();
			yield put(fetchCompanyPeopleSuccess(companyPeople));
		}
	} catch (err) {
		yield put(fetchCompanyPeopleError(err));
	}
}

function* getFunding(action) {
	try {
		const url = `${FUNDING_API_BASE_URL}/editor/companyid/${action.companyId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const companyPeople = yield response.json();
			yield put(fetchFundingSuccess(companyPeople));
		}
	} catch (err) {
		yield put(fetchFundingError(err));
	}
}

function* getCompanyLocations(action) {
	try {
		const url = `${COMPANY_LOCATIONS_API_BASE_URL}/editor/companyid/${action.companyId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const companyPeople = yield response.json();
			yield put(fetchCompanyLocationsSuccess(companyPeople));
		}
	} catch (err) {
		yield put(fetchCompanyLocationsError(err));
	}
}

function* getCompanyAudience(action) {
	try {
		const url = `${COMPANY_AUDIENCE_API_BASE_URL}/editor/companyid/${action.companyId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const companyPeople = yield response.json();
			yield put(fetchAudienceSuccess(companyPeople));
		}
	} catch (err) {
		yield put(fetchAudienceError(err));
	}
}

function* getMilestones(action) {
	try {
		const url = `${COMPANY_MILESTONES_API_BASE_URL}/editor/companyid/${action.companyId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const companyPeople = yield response.json();
			yield put(fetchMilestonesSuccess(companyPeople));
		}
	} catch (err) {
		yield put(fetchMilestonesError(err));
	}
}

function* fetchCuratedListTypes() {
	try {
		const url = `${CURATED_LIST_API_BASE_URL}/editor`;
		const options = {
			method: 'POST',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const list = yield response.json();
			yield put(fetchCuratedListTypesSuccess(list));
		}
	} catch (err) {
		// yield put(fetchCuratedListTypeError(err));
		console.log('Error fetching curated list', err);
	}
}

function* fetchProfileUpdateNotes(action) {
	const url = `/api/v2/rw/company-updates/profile/${action.companyId}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const updates = yield response.json();
			yield put(fetchProfileUpdateNotesSuccess(updates));
		}
	} catch (err) {
		console.log('Error fetching updates', err);
	}
}

export default function* companyEditorData() {
	const location = yield select(makeSelectLocation());
	CURRENT_LOCATION = location.pathname;
	yield takeLatest(CREATE_COMPANY, createCompany);
	yield takeLatest(UPDATE_BASIC_INFO, updateBasicInfo);
	yield takeLatest(FETCH_BASIC_INFO, getBasicInfo);
	yield takeLatest(FETCH_ACQUISITIONS, getAcquisitions);
	yield takeLatest(FETCH_COMPANY_FINANCE, getCompanyFinance);
	yield takeLatest(FETCH_COMPANY_PEOPLE, getCompanyPeople);
	yield takeLatest(FETCH_FUNDING, getFunding);
	yield takeLatest(FETCH_COMPANY_PRODUCTS, getCompanyProducts);
	yield takeLatest(FETCH_COMPANY_LOCATIONS, getCompanyLocations);
	yield takeLatest(FETCH_AUDIENCE, getCompanyAudience);
	yield takeLatest(FETCH_MILESTONES, getMilestones);
	yield takeLatest(FETCH_CURATED_LIST_TYPES, fetchCuratedListTypes);
	yield takeLatest(FETCH_PROFILE_UPDATE_NOTES, fetchProfileUpdateNotes);
}
