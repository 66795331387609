import {
	call, put, takeLatest, select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	FETCH_DESK_STORIES_BY_BRAND_AND_STATUS, FETCH_DESK_BRANDS, FETCH_PENDING_APPROVAL_COUNT, UPDATE_DESK_STORY_STATUS, RUN_DESK_SEARCH, CHECK_PLAGIARISM,
} from './type';
import { DESK_BASE_API_URL } from './constants';
import {
	fetchDeskStoriesByBrandAndStatusSuccess, fetchDeskStoriesByBrandAndStatusFailure, fetchDeskBrandsSuccess, fetchDeskBrandsFailure, fetchPendingApprovalCountSuccess, fetchPendingApprovalCountFailure, fetchDeskStoriesByBrandAndStatus, fetchPendingApprovalCount, updateDeskStoryStatusFailure, updateDeskStoryStatusSuccess, runDeskSearchSuccess, runDeskSearchFailure,
} from './actions';
import { BRAND_API_BASE_URL } from '../BrandManager/constants';
import { makeSelectDeskSelectedBrand, makeSelectDeskStoriesStatus, makeSelectDeskPage } from './selectors';

export function* getDeskBrand(action) {
	const url = `${BRAND_API_BASE_URL}/slugs`;
	const formData = new FormData();
	formData.append('slugs', JSON.stringify(action.slugs));
	const options = {
		method: 'POST',
		credentials: 'include',
		body: formData,
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const brands = yield response.json();
			yield put(fetchDeskBrandsSuccess(brands));
		}
	} catch (err) {
		yield put(fetchDeskBrandsFailure(err));
	}
}

export function* getDeskStoriesByBrandAndStatus(action) {
	const storyTypeUrl = action?.storyType !== 'All' ? `?type=${action?.storyType}` : '';
	const url = `${DESK_BASE_API_URL}/status/${action.brand}/${action.status}/${action.page}${storyTypeUrl}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const allStories = yield response.json();
			yield put(fetchDeskStoriesByBrandAndStatusSuccess(allStories));
		}
	} catch (err) {
		yield put(fetchDeskStoriesByBrandAndStatusFailure(err));
	}
}

export function* getPendingApprovalCount(action) {
	const url = `${DESK_BASE_API_URL}/pendingapproval/${action.brand}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const count = yield response.json();
			yield put(fetchPendingApprovalCountSuccess(count));
		}
	} catch (err) {
		yield put(fetchPendingApprovalCountFailure(err));
	}
}

export function* updateStoryStatus(action) {
	const { story } = action;
	const url = `${DESK_BASE_API_URL}/updatestatus/${story.brand}/${story.storyId}`;
	const data = new FormData();
	data.append('status', action.newStatus);
	data.append('story', JSON.stringify(story));
	data.append('comments', action.comment);
	const options = {
		method: 'PUT',
		credentials: 'include',
		body: data,
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(updateDeskStoryStatusSuccess(responseMessage));
				yield put(fetchDeskStoriesByBrandAndStatus(action.brand, action.story.status, action.page));
				yield put(fetchPendingApprovalCount(action.brand));
			} else {
				yield put(updateDeskStoryStatusFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(updateDeskStoryStatusFailure(err));
	}
}

export function* filterStories(action) {
	const url = `${DESK_BASE_API_URL}/search`;
	const data = new FormData();
	data.append('type', action.searchType);
	data.append('brand', action.brand);
	data.append('status', action.status);
	data.append('searchValue', action.searchValue);
	data.append('limit', action.limit);
	// data.append('storyType', action.storyType);
	action?.storyType !== 'All' ? data.append('storyType', action.storyType) : '';
	const options = {
		method: 'POST',
		credentials: 'include',
		body: data,
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 403 || response.status === 401) {
			yield put(push('/unauthorized'));
		} else {
			const results = yield response.json();
			yield put(runDeskSearchSuccess(results));
		}
	} catch (err) {
		yield put(runDeskSearchFailure(err));
	}
}

export function* checkPlagiarsim(action) {
	const url = `${DESK_BASE_API_URL}/plagiarismcheck/${action.storyId}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	yield call(fetch, url, options);
	yield call(delay);
	const brand = yield select(makeSelectDeskSelectedBrand());
	const status = yield select(makeSelectDeskStoriesStatus());
	const page = yield select(makeSelectDeskPage());
	yield put(fetchDeskStoriesByBrandAndStatus(brand.slug, status, page));
}

function delay() {
	return new Promise((resolve) => {
		setTimeout(() => resolve(), 8000);
	});
}

export default function* deskData() {
	yield takeLatest(FETCH_DESK_BRANDS, getDeskBrand);
	yield takeLatest(FETCH_DESK_STORIES_BY_BRAND_AND_STATUS, getDeskStoriesByBrandAndStatus);
	yield takeLatest(FETCH_PENDING_APPROVAL_COUNT, getPendingApprovalCount);
	yield takeLatest(UPDATE_DESK_STORY_STATUS, updateStoryStatus);
	yield takeLatest(RUN_DESK_SEARCH, filterStories);
	yield takeLatest(CHECK_PLAGIARISM, checkPlagiarsim);
}
