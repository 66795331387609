import styled from 'styled-components';
import { Row } from '../Responsive';

const Header = styled.div`
	position: fixed;
	top: 0;
	width: 100%;
	box-shadow: 0px 5px 5px -2px #C8CECA;
	z-index: 5;
	background-color: #fff;
`;
const HeaderContainer = styled(Row)`
		height: 60px;
		align-items: center;
`;

const LogoIcon = styled.img`
			height: 50px;
			width: 50px;
			@media (max-width: 768px) {
				height: 30px;
				width: 30px;		 
			}
`;
const NavWrapper = styled.div`
			display: table;
			.active {
				padding-bottom: 0;
				border-bottom: 5px solid #e5002d;
			}
`;
const NavItem = styled.div`
			display: table-cell;
			vertical-align: middle;
			height:60px;
			width: 100px;
			text-align: center;
			font-weight: bold;
			padding: 0 10px;
			padding-bottom: 5px;
			cursor: pointer;
			&:hover {
				background-color: #ddd;
			}
			@media (max-width: 768px) {
				font-size: 0.8rem;
				width: 70px;
			}
`;

const UserMenuItem = styled.li`
		padding: 10px;
		&:hover {
				background-color :#eee;
			}
`;

const UserMenu = styled.ul`
		list-style: none;
		position: absolute;
		min-width: 120px;
		overflow: auto;
		box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
		z-index: 4;
		background-color: #fff;
		right: 10px;
		top: 61px;
		text-align: left;
		padding: 0;
`;

export {
	Header,
	HeaderContainer,
	LogoIcon,
	NavItem,
	NavWrapper,
	UserMenuItem,
	UserMenu,
};
