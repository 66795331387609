import React from 'react';
import Tooltip from 'react-tooltip';
import styled from 'styled-components';
import T from 'prop-types';
import InfoIcon from '../../images/info.png';

const Title = styled.div`
		font-size: 1rem;
		font-weight: 500;
		margin-bottom: 10px;
`;

const Image = styled.img`
		height: 15px;
		width: 15px;
`;

const TitleWithToolTip = (props) => <div>
	<Tooltip effect="solid" />
	<Title>
		{props.title ? props.title : null}
		&ensp;
		<Image src={InfoIcon} data-tip={props.hint} />
	</Title>
</div>;

export default TitleWithToolTip;

TitleWithToolTip.propTypes = {
	title: T.string,
	hint: T.string,
};
