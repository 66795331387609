import styled, { css } from 'styled-components';
import { Row } from 'react-styled-flexboxgrid';

const ImageWrapper = styled.div`
		display: flex;
		justify-content: center;
		.width {
				width: 50%;
				@media (max-width: 768px) {
						width: 100%; 
				}
		}
`;

const ListHeaderWrapper = styled(Row)`
		color: #db2325;
		min-height: 50px;
		align-items: center;
		margin-left: 0 !important;
		margin-right: 0 !important;
`;

const ListItemWrapper = styled(Row)`
		background-color: #fffff;
		min-height: 40px;
		align-items: center;
		margin-left: 0 !important;
		margin-right: 0 !important;
		${(props) => props.slNo % 2 !== 0 && css`
				background-color: #f9f9f9;
		`}
`;

const Button = styled.button`
		:hover {
				background-color: #db2325;
				color: #fff;
				border-radius: 40px;
				text-decoration: underline;
				cursor: pointer;
		}
`;

const Span = styled.span`
		:hover{
				cursor: pointer;
				color: #db2325;
				text-decoration: underline;
		}
`;

export {
	ImageWrapper, ListItemWrapper, ListHeaderWrapper, Button, Span,
};
