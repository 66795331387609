import { Map } from 'immutable';
import {
	FETCH_DESK_STORIES_BY_BRAND_AND_STATUS, FETCH_DESK_STORIES_BY_BRAND_AND_STATUS_SUCCESS, FETCH_DESK_STORIES_BY_BRAND_AND_STATUS_FAILURE, SET_DESK_PAGE, SET_DESK_STORIES_STATUS, FETCH_DESK_BRANDS_SUCCESS, FETCH_PENDING_APPROVAL_COUNT_SUCCESS, DISABLE_DESK_SEARCH_FLAG, RUN_DESK_SEARCH, DISPLAY_DESK_COMMENT_BOX, HIDE_DESK_COMMENT_BOX, SELECT_DESK_BRAND, RUN_DESK_SEARCH_SUCCESS,
} from './type';

const initialState = Map({
	loading: false,
	error: false,
	allStories: null,
	stories: null,
	page: 1,
	status: 'pending',
	brands: null,
	pendingApproval: null,
	search: false,
	searchValue: null,
	searchType: null,
	displayCommentBox: false,
	commentText: null,
	feedback: {
		newStatus: null,
		story: {},
	},
	selectedBrand: null,
});

function deskReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_DESK_STORIES_BY_BRAND_AND_STATUS:
		return state
			.set('loading', true)
			.set('error', false);
	case FETCH_DESK_STORIES_BY_BRAND_AND_STATUS_SUCCESS:
		return state
			.set('loading', false)
			.set('allStories', action.stories)
			.set('stories', action.stories);
	case FETCH_DESK_STORIES_BY_BRAND_AND_STATUS_FAILURE:
		return state
			.set('loading', false)
			.set('error', action.error)
			.set('stories', null);
	case SET_DESK_PAGE:
		return state
			.set('page', action.page);
	case SET_DESK_STORIES_STATUS: {
		return state
			.set('status', action.status);
	}
	case FETCH_DESK_BRANDS_SUCCESS: {
		const { brands } = action;
		if (brands.length === 1) {
			return state
				.set('brands', action.brands)
				.set('selectedBrand', brands[0]);
		}
		return state
			.set('brands', action.brands);
	}
	case FETCH_PENDING_APPROVAL_COUNT_SUCCESS:
		return state
			.set('pendingApproval', Number(action.count));
	case DISABLE_DESK_SEARCH_FLAG:
		return state
			.set('stories', state.get('allStories'))
			.set('searchValue', null)
			.set('search', false)
			.set('searchType', null);
	case RUN_DESK_SEARCH: {
		return state
			.set('search', true)
			.set('searchValue', action.searchValue)
			.set('searchType', action.searchType)
			.set('loading', true);
	}
	case RUN_DESK_SEARCH_SUCCESS:
		return state
			.set('stories', action.searchResults)
			.set('loading', false);
	case DISPLAY_DESK_COMMENT_BOX: {
		const obj = {
			newStatus: action.newStatus,
			story: action.story,
		};
		return state
			.set('displayCommentBox', true)
			.set('feedback', { ...obj });
	}
	case HIDE_DESK_COMMENT_BOX:
		return state
			.set('feedback', null)
			.set('displayCommentBox', false);
	case SELECT_DESK_BRAND:
		return state
			.set('selectedBrand', action.selectedBrand);
	default:
		return state;
	}
}
export default deskReducer;
