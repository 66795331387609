export const BRAND_OF_THE_DAY_BASE_URL = '/api/v2/rw/brand-of-the-day';

export const FETCH_BRANDS_OF_THE_DAY = 'FETCH_BRANDS_OF_THE_DAY';
export const FETCH_BRANDS_OF_THE_DAY_SUCCESS = 'FETCH_BRANDS_OF_THE_DAY_SUCCESS';
export const FETCH_BRANDS_OF_THE_DAY_FAILURE = 'FETCH_BRANDS_OF_THE_DAY_FAILURE';

export const FILTER_BRANDS_OF_THE_DAY = 'FILTER_BRANDS_OF_THE_DAY';
export const FILTER_BRANDS_OF_THE_DAY_SUCCESS = 'FILTER_BRANDS_OF_THE_DAY_SUCCESS';
export const FILTER_BRANDS_OF_THE_DAY_FAILURE = 'FILTER_BRANDS_OF_THE_DAY_FAILURE';

export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
export const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';

export const SET_BRANDS_OF_THE_DAY_PAGE = 'SET_BRANDS_OF_THE_DAY_PAGE';
export const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';

export const SAVING_MESSAGE = { intent: 'info', message: 'Saving data...' };
export const SAVING_SUCCESS_MESSAGE = { intent: 'success', message: 'Data Saved Successfully' };
export const SAVING_FAILURE_MESSAGE = { intent: 'danger', message: 'Data Save Failed. Something went wrong. Try Again!' };
export const ACTIVE_BRANDS_OF_THE_DAY_FAILURE_MESSAGE = { intent: 'danger', message: 'There is an ACTIVE brand of the day with this date' };
