import {
	FETCH_CONNECT_REQUESTS_BY_STATUS,
	FETCH_CONNECT_REQUESTS_BY_STATUS_SUCCESS,
	FETCH_CONNECT_REQUESTS_BY_STATUS_FAILURE,
	ON_TAB_CHANGE,
	SET_PAGE,
	UPDATE_CONNECT_REQUEST_STATUS,
	UPDATE_CONNECT_REQUEST_STATUS_SUCCESS,
	UPDATE_CONNECT_REQUEST_STATUS_FAILURE,
	FILTER_CONNECT_REQUESTS_BY_COMPANY,
	FILTER_CONNECT_REQUESTS_BY_COMPANY_SUCCESS,
	FILTER_CONNECT_REQUESTS_BY_COMPANY_FAILURE,
} from './type';

export function fetchConnectRequestsByStatus(status, page) {
	return {
		type: FETCH_CONNECT_REQUESTS_BY_STATUS,
		status,
		page,
	};
}

export function fetchConnectRequestsByStatusSuccess(connectRequests) {
	return {
		type: FETCH_CONNECT_REQUESTS_BY_STATUS_SUCCESS,
		connectRequests,
	};
}

export function fetchConnectRequestsByStatusFailure(error) {
	return {
		type: FETCH_CONNECT_REQUESTS_BY_STATUS_FAILURE,
		error,
	};
}

export function onTabChange(tab) {
	return {
		type: ON_TAB_CHANGE,
		tab,
	};
}

export function setPage(page) {
	return {
		type: SET_PAGE,
		page,
	};
}

export function updateConnectRequestStatus(requestId, status, currentTab, page, comment) {
	return {
		type: UPDATE_CONNECT_REQUEST_STATUS,
		requestId,
		status,
		currentTab,
		page,
		comment,
	};
}

export function updateConnectRequestStatusSuccess(response) {
	return {
		type: UPDATE_CONNECT_REQUEST_STATUS_SUCCESS,
		response,
	};
}

export function updateConnectRequestStatusFailure(error) {
	return {
		type: UPDATE_CONNECT_REQUEST_STATUS_FAILURE,
		error,
	};
}

export function filterConnectRequestsByCompany(company, status, page) {
	return {
		type: FILTER_CONNECT_REQUESTS_BY_COMPANY,
		company,
		status,
		page,
	};
}

export function filterConnectRequestsByCompanySuccess(connectRequests) {
	return {
		type: FILTER_CONNECT_REQUESTS_BY_COMPANY_SUCCESS,
		connectRequests,
	};
}

export function filterConnectRequestsByCompanyFailure(error) {
	return {
		type: FILTER_CONNECT_REQUESTS_BY_COMPANY_FAILURE,
		error,
	};
}
