import {
	LOAD_PROFILES, LOAD_PROFILES_SUCCESS, LOAD_PROFILES_ERROR, LOAD_MORE_PROFILES, LOAD_MORE_PROFILES_ERROR, LOAD_MORE_PROFILES_SUCCESS, LOAD_FILTERED_PROFILES, LOAD_FILTERED_PROFILES_ERROR, LOAD_FILTERED_PROFILES_SUCCESS, SAVE_PROFILE, SAVE_PROFILE_ERROR, SAVE_PROFILE_SUCCESS, SELECT_BRAND, SELECT_PROFILE, UPDATE_CHANGES, DISCARD_PROFILE, CLEAR_USER_MANAGER_DATA,
} from './type';

export function loadProfiles(page) {
	return {
		type: LOAD_PROFILES,
		page,
	};
}

export function profilesLoadedSuccessfully(profiles) {
	return {
		type: LOAD_PROFILES_SUCCESS,
		profiles,
	};
}

export function profilesLoadingError(error) {
	return {
		type: LOAD_PROFILES_ERROR,
		error,
	};
}

export function loadMoreProfiles(onPage, queryString) {
	return {
		type: LOAD_MORE_PROFILES,
		onPage,
		queryString,
	};
}

export function loadMoreProfilesSuccessfully(profiles) {
	return {
		type: LOAD_MORE_PROFILES_SUCCESS,
		profiles,
	};
}

export function loadMoreProfilesError(error) {
	return {
		type: LOAD_MORE_PROFILES_ERROR,
		error,
	};
}

export function loadFilteredProfiles(page, filterCriteria, filterByValue) {
	return {
		type: LOAD_FILTERED_PROFILES,
		page,
		filterCriteria,
		filterByValue,
	};
}

export function loadFilteredProfilesSuccessfully(profiles) {
	return {
		type: LOAD_FILTERED_PROFILES_SUCCESS,
		profiles,
	};
}

export function loadFilteredProfilesError(error) {
	return {
		type: LOAD_FILTERED_PROFILES_ERROR,
		error,
	};
}

export function saveProfile(data) {
	return {
		type: SAVE_PROFILE,
		data,
	};
}

export function saveProfileSuccessfully(response) {
	return {
		type: SAVE_PROFILE_SUCCESS,
		response,
	};
}

export function saveProfileError(error) {
	return {
		type: SAVE_PROFILE_ERROR,
		error,
	};
}

export function brandSelect(selectedBrand) {
	return {
		type: SELECT_BRAND,
		selectedBrand,
	};
}

export function profileSelect(data) {
	return {
		type: SELECT_PROFILE,
		data,
	};
}

export function updateChange(name, value) {
	return {
		type: UPDATE_CHANGES,
		name,
		value,
	};
}

export function discardProfile(data) {
	return {
		type: DISCARD_PROFILE,
		data,
	};
}

export function setInitialState() {
	return {
		type: CLEAR_USER_MANAGER_DATA,
	};
}
