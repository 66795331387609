import { Map } from 'immutable';
import {
	SELECT_BRAND_SPOTLIGHT, ADD_BRAND_SPOTLIGHT, CREATE_BRAND_SPOTLIGHT, CREATE_BRAND_SPOTLIGHT_SUCCESS, CREATE_BRAND_SPOTLIGHT_ERROR, UPDATE_BRAND_SPOTLIGHT, UPDATE_BRAND_SPOTLIGHT_SUCCESS, UPDATE_BRAND_SPOTLIGHT_ERROR, BRAND_SPOTLIGHT_ELEMENT_ON_CHANGE, UNMOUNT_BRAND_SPOTLIGHT_FORM_MESSAGE, WRITE_BRAND_SPOTLIGHT_SLUG, SET_BRAND_SPOTLIGHT_SLUG_UNIQUE_VALUE, CLEAR_BRAND_SPOTLIGHT_DATA,
} from './type';
import {
	BRAND_SPOTLIGHT_CREATE_LOAD_MESSAGE, BRAND_SPOTLIGHT_CREATE_SUCCESS_MESSAGE, BRAND_SPOTLIGHT_ERROR_MESSAGE, BRAND_SPOTLIGHT_UPDATE_LOAD_MESSAGE, BRAND_SPOTLIGHT_UPDATE_SUCCESS_MESSAGE,
} from './constants';

const initialState = Map({
	loading: false,
	error: false,
	allBrandSpotlights: null,
	selectedBrandSpotlight: null,
	formData: {
		name: '',
		slug: '',
		website: '',
		description: '',
		image: '',
	},
	addSpotlight: false,
	editSpotlight: false,
	dropdownTitle: null,
	formMessage: null,
	writeSlug: false,
	uniqueSlug: false,
});

function brandSpotlightReducer(state = initialState, action) {
	switch (action.type) {
	case SELECT_BRAND_SPOTLIGHT: {
		const selected = action.selectedBrandSpotlight ? action.selectedBrandSpotlight.name : null;
		if (!selected || Object.keys(selected).length < 1) {
			const form = {
				name: '',
				slug: '',
				website: '',
				description: '',
				image: '',
			};
			return state
				.set('loading', false)
				.set('error', false)
				.set('addSpotlight', false)
				.set('editSpotlight', false)
				.set('writeSlug', false)
				.set('uniqueSlug', true)
				.set('selectedBrandSpotlight', null)
				.set('dropdownTitle', null)
				.set('formData', form);
		}
		const form = {
			name: action.selectedBrandSpotlight.name,
			slug: action.selectedBrandSpotlight.slug,
			website: action.selectedBrandSpotlight.website,
			description: action.selectedBrandSpotlight.description,
			image: action.selectedBrandSpotlight.imagelocation,
		};
		return state
			.set('selectedBrandSpotlight', action.selectedBrandSpotlight)
			.set('formData', form)
			.set('editSpotlight', true)
			.set('addSpotlight', false)
			.set('writeSlug', false)
			.set('uniqueSlug', true)
			.set('dropdownTitle', action.selectedBrandSpotlight)
			.set('formMessage', null);
	}
	case ADD_BRAND_SPOTLIGHT: {
		const form = {
			name: '',
			slug: '',
			website: '',
			description: '',
			image: '',
		};
		return state
			.set('addSpotlight', true)
			.set('editSpotlight', false)
			.set('selectedBrandSpotlight', null)
			.set('dropdownTitle', null)
			.set('formData', form)
			.set('writeSlug', false)
			.set('uniqueSlug', false)
			.set('formMessage', null);
	}
	case CREATE_BRAND_SPOTLIGHT:
		return state
			.set('formMessage', BRAND_SPOTLIGHT_CREATE_LOAD_MESSAGE);
	case CREATE_BRAND_SPOTLIGHT_SUCCESS:
		return state
			.set('addSpotlight', false)
			.set('editSpotlight', false)
			.set('formMessage', BRAND_SPOTLIGHT_CREATE_SUCCESS_MESSAGE);
	case CREATE_BRAND_SPOTLIGHT_ERROR:
		return state
			.set('formMessage', BRAND_SPOTLIGHT_ERROR_MESSAGE);
	case UPDATE_BRAND_SPOTLIGHT:
		return state
			.set('formMessage', BRAND_SPOTLIGHT_UPDATE_LOAD_MESSAGE);
	case UPDATE_BRAND_SPOTLIGHT_SUCCESS:
		return state
			.set('addSpotlight', false)
			.set('editSpotlight', false)
			.set('dropdownTitle', null)
			.set('formMessage', BRAND_SPOTLIGHT_UPDATE_SUCCESS_MESSAGE);
	case UPDATE_BRAND_SPOTLIGHT_ERROR:
		return state
			.set('formMessage', BRAND_SPOTLIGHT_ERROR_MESSAGE);
	case BRAND_SPOTLIGHT_ELEMENT_ON_CHANGE: {
		const newState = { ...state.get('formData') };
		newState[action.elementData.name] = action.elementData.value;
		if (action.elementData.name === 'name') {
			// eslint-disable-next-line
				newState.name = (action.elementData.value)/*.replace(/[@#!$%^&*()_+|~=`{}[\]:";'<>?,.\/]/g, '')*/;
			if (state.get('writeSlug') === false && /[A-Za-z0-9-]/g.test(action.elementData.value)) {
				const slug = (action.elementData.value).replace(/ /g, '-').replace(/[^A-Za-z0-9-]/g, '').toLowerCase();
				newState.slug = slug;
			}
		}
		if (action.elementData.name === 'slug') {
			if (state.get('writeSlug') === true) {
				const slug = (action.elementData.value).replace(/ /g, '-').replace(/[^A-Za-z0-9-]/g, '');
				newState.slug = slug;
			}
		}
		return state
			.set('formData', newState);
	}
	case WRITE_BRAND_SPOTLIGHT_SLUG:
		return state
			.set('writeSlug', true);
	case UNMOUNT_BRAND_SPOTLIGHT_FORM_MESSAGE:
		return state
			.set('formMessage', null);
	case SET_BRAND_SPOTLIGHT_SLUG_UNIQUE_VALUE:
		return state
			.set('uniqueSlug', action.value);
	case CLEAR_BRAND_SPOTLIGHT_DATA:
		return initialState;
	default:
		return state;
	}
}

export default brandSpotlightReducer;
