import styled, { css } from 'styled-components';
import { Row } from 'react-styled-flexboxgrid';

export const USER_BASE_API_URL = '/api/v2/rw/user-manager';

export const rolesType = {
	0: 'NONE',
	1: 'AUTHOR',
	2: 'MARKETER',
	3: 'CAMPAIGN PUBLISHER',
	4: 'EDITOR',
	5: 'CHIEF EDITOR',
	6: 'ADMIN',
};

export const managerRoleValues = {
	0: 'NO ROLE',
	1: 'ADMIN',
};

// Constants used as initial state -->
export const managerialRoles = {
	videoManager: {
		value: 'Video Manager',
		selected: false,
	},
	configManager: {
		value: 'Configuration Manager',
		selected: false,
	},
	redirectionManager: {
		value: 'Redirection Manager',
		selected: false,
	},
	brandSpotlightManager: {
		value: 'BrandSpotlight Manager',
		selected: false,
	},
	seriesManager: {
		value: 'Series Manager',
		selected: false,
	},
	webinarManager: {
		value: 'Webinar Manager',
		selected: false,
	},
	jobsManager: {
		value: 'Jobs Manager',
		selected: false,
	},
	communityContentManager: {
		value: 'Community Content Manager',
		selected: false,
	},
	eventManager: {
		value: 'Event Manager',
		selected: false,
	},
	broadcastManager: {
		value: 'Broadcast Manager',
		selected: false,
	},
	subscriptionManager: {
		value: 'Subscription Manager',
		selected: false,
	},
	brandsOfNewIndiaManager: {
		value: 'Brands Of New India Manager',
		selected: false,
	},
	orgChartManager: {
		value: 'Org Chart Manager',
		selected: false,
	},
	ysJobsManager: {
		value: 'Admin Jobs Manager',
		selected: false,
	},
	adRefreshManager: {
		value: 'Ad Refresh Manager',
		selected: false,
	},
	techSparksManager: {
		// value: 'TechSparks Manger',
		value: 'Offline Event Manager',
		selected: false,
	},
	listingsCardManager: {
		// value: 'TechSparks Manger',
		value: 'Listings Manager',
		selected: false,
	},
	topicManager: {
		// value: 'TechSparks Manger',
		value: 'Topics Manager',
		selected: false,
	},
	webStoryManager: {
		value: 'Web Stories Manager',
		selected: false,
	},
	liveFeedCardManager: {
		// value: 'TechSparks Manger',
		value: 'Live Feed Manager',
		selected: false,
		accessLevel: 0,
	},
	// Left at bottom for aesthetic purposes
	companyManager: {
		value: 'Company Manager',
		selected: false,
		accessLevel: 0,
	},
};

export const teamRoles = {
	PR: {
		value: 'PR',
		selected: false,
	},
};

export const truncateLongerValue = (value, maxLength) => {
	if (!value) {
		return value;
	}

	if (value.length >= maxLength) {
		value = `${value.substring(0, maxLength - 3)}...`;
	}
	return value;
};

export const isRoleAboveGivenValue = (roles, value) => {
	const keyArray = Object.keys(roles);
	let isRoleAbove = false;
	keyArray.forEach((ele) => {
		if (roles[ele] > value) isRoleAbove = true;
	});
	return isRoleAbove;
};

export const isRoleAbovePublisher = (roles) => {
	const keyArray = Object.keys(roles);
	let isRoleAbove = false;
	keyArray.forEach((ele) => {
		if (roles[ele] > 3) isRoleAbove = true;
	});
	return isRoleAbove;
};

export const IMAGES_BASE_URL = 'https://images.yourstory.com';

export const GalleryWrapper = styled(Row)`
		padding: 0.5rem;
		width: 100%;
		position: relative;
		overflow: auto;
		max-height: 75vh;
`;

export const Card = styled.div`
		border:1px solid #ddd;
		box-shadow : 0 0.1rem 0.3rem rgba(0, 0, 0, 0.15);
		border-radius: 0.25rem;
		padding: 0.5rem;
		margin-bottom: 1rem;
		word-wrap: break-word;
		cursor: pointer;
		display: flex;
		width: '100%';
	 ${(props) => props.hover && css`
		:hover{
				background-color: ${props.hover.bgColor};
		}
	`}
`;

export const BrandList = styled.div`
		font-size: 12px;
		:hover{
				background-color: #eee;
		}
`;
