import {
	SET_TAG_VALUE, UPDATE_TAGGED_STORIES, SEARCH_STORIES_BY_KEYWORDS, SEARCH_STORIES_BY_KEYWORDS_SUCCESS, SEARCH_STORIES_BY_KEYWORDS_FAILURE, SET_LIST_PAGE, TAG_STORIES, TAG_STORIES_SUCCESS, TAG_STORIES_FAILURE, SET_FORM_MESSAGE, SET_TAG_TYPE, CLEAR_STATE,
} from './type';

export function setTagValue(value) {
	return {
		type: SET_TAG_VALUE,
		value,
	};
}

export function updateTaggedStories(index) {
	return {
		type: UPDATE_TAGGED_STORIES,
		index,
	};
}

export function searchStoriesByKeywords(tagType, searchValueId, searchValue, page) {
	return {
		type: SEARCH_STORIES_BY_KEYWORDS,
		tagType,
		searchValueId,
		searchValue,
		page,
	};
}

export function searchStoriesByKeywordsSuccess(stories) {
	return {
		type: SEARCH_STORIES_BY_KEYWORDS_SUCCESS,
		stories,
	};
}

export function searchStoriesByKeywordsFailure(error) {
	return {
		type: SEARCH_STORIES_BY_KEYWORDS_FAILURE,
		error,
	};
}

export function setListPage(page) {
	return {
		type: SET_LIST_PAGE,
		page,
	};
}

export function tagStories(payload) {
	return {
		type: TAG_STORIES,
		payload,
	};
}

export function tagStoriesSuccess(successResponse) {
	return {
		type: TAG_STORIES_SUCCESS,
		successResponse,
	};
}

export function tagStoriesFailure(error) {
	return {
		type: TAG_STORIES_FAILURE,
		error,
	};
}

export function setFormMessage(message) {
	return {
		type: SET_FORM_MESSAGE,
		message,
	};
}

export function setTagType(value) {
	return {
		type: SET_TAG_TYPE,
		value,
	};
}

export function clearState() {
	return {
		type: CLEAR_STATE,
	};
}
