import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const FlexDiv = styled.div`
		display: flex;
		flex-direction: row;
`;

const Toggler = (props) => {
	Toggler.propTypes = {
		onToggleChange: PropTypes.func,
		checked: PropTypes.bool,
		disabled: PropTypes.bool,
	};

	return <FlexDiv>
		<div>
			<label className="switch">
				<input
					type="checkbox"
					checked={props.checked || false}
					onChange={(e) => {
						props.onToggleChange(e.target.checked);
					}}
					disabled={props.disabled}
				/>
				<span className={`${props.disabled ? 'slider-disabled' : ''} slider round`} />
			</label>
		</div>
	</FlexDiv>;
};

export default Toggler;
