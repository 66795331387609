import {
	FETCH_CLAIMS_BY_STATUS, FETCH_CLAIMS_BY_STATUS_SUCCESS, FETCH_CLAIMS_BY_STATUS_FAILURE, ON_TAB_CHANGE, SET_PAGE, UPDATE_CLAIM_STATUS, UPDATE_CLAIM_STATUS_SUCCESS, UPDATE_CLAIM_STATUS_FAILURE, FILTER_CLAIMS_BY_COMPANY, FILTER_CLAIMS_BY_COMPANY_SUCCESS, FILTER_CLAIMS_BY_COMPANY_FAILURE,
} from './type';

export function fetchClaimsByStatus(status, page) {
	return {
		type: FETCH_CLAIMS_BY_STATUS,
		status,
		page,
	};
}

export function fetchClaimsByStatusSuccess(profileClaims) {
	return {
		type: FETCH_CLAIMS_BY_STATUS_SUCCESS,
		profileClaims,
	};
}

export function fetchClaimsByStatusFailure(error) {
	return {
		type: FETCH_CLAIMS_BY_STATUS_FAILURE,
		error,
	};
}

export function onTabChange(tab) {
	return {
		type: ON_TAB_CHANGE,
		tab,
	};
}

export function setPage(page) {
	return {
		type: SET_PAGE,
		page,
	};
}

export function updateClaimStatus(claimId, status, currentTab, page, comment) {
	return {
		type: UPDATE_CLAIM_STATUS,
		claimId,
		status,
		currentTab,
		page,
		comment,
	};
}

export function updateClaimStatusSuccess(response) {
	return {
		type: UPDATE_CLAIM_STATUS_SUCCESS,
		response,
	};
}

export function updateClaimStatusFailure(error) {
	return {
		type: UPDATE_CLAIM_STATUS_FAILURE,
		error,
	};
}

export function filterClaimsByCompany(company, status, page) {
	return {
		type: FILTER_CLAIMS_BY_COMPANY,
		company,
		status,
		page,
	};
}

export function filterClaimsByCompanySuccess(profileClaims) {
	return {
		type: FILTER_CLAIMS_BY_COMPANY_SUCCESS,
		profileClaims,
	};
}

export function filterClaimsByCompanyFailure(error) {
	return {
		type: FILTER_CLAIMS_BY_COMPANY_FAILURE,
		error,
	};
}
