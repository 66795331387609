import React from 'react';
import AsyncSelect from 'react-select/async';
import { Row, Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import { TitleWithTooltip, TopicCard } from './index';
import { BRAND_SPOTLIGHT_API_BASE_URL } from '../../containers/BrandSpotlightManager/constants';

const BrandSpotlightCard = (props) => {
	const { brandSpotlight, onBrandSpotlightChange, brandSpotlightSponsors } = props;

	async function getBrandSpotlightOptions(inputValue, callback) {
		if (/[/\\]/.test(inputValue)) return [];
		try {
			const url = `${BRAND_SPOTLIGHT_API_BASE_URL}/key/${inputValue}`;
			const options = {
				method: 'GET',
				credentials: 'include',
			};

			const apiResponse = await fetch(url, options);
			const selectOptions = await apiResponse.json();
			callback(selectOptions);
		} catch (err) {
			console.log('Brand Spotlight options error: ', err);
		}
	}

	function renderTopicCards() {
		if (brandSpotlight && !props.isMulti) {
			return <div className="mt-4">
				<TopicCard
					thumbnail={brandSpotlight ? brandSpotlight.imagelocation : null}
					name={brandSpotlight ? brandSpotlight.name : null}
				/>
			</div>;
		} if (brandSpotlightSponsors && props.isMulti) {
			return (<Row className="mt-4">
				{brandSpotlightSponsors.map((sponsor) => <Col xs={12} sm={6} className="mb-4">
					<TopicCard
						thumbnail={sponsor ? sponsor.imagelocation : null}
						name={sponsor ? sponsor.name : null}
					/>
				</Col>)}
			</Row>);
		}
	}

	return (
		<div
			style={{ width: '100%', padding: '0 0.5rem' }}
		>
			<TitleWithTooltip
				title="Brand Spotlight"
				hint="BrandSpotlight are our partners for whom we write sponsored content. The badge which is shown at the top of the story on a sponsored content"
			/>
			<AsyncSelect
				style={{ width: '100%' }}
				className="basic-single"
				classNamePrefix="select"
				isMulti={props.isMulti}
				isClearable
				isSearchable
				loadOptions={getBrandSpotlightOptions}
				placeholder="Search brand spotlight by name..."
				onChange={(value) => {
					onBrandSpotlightChange(value);
				}}
				value={props.isMulti ? brandSpotlightSponsors : brandSpotlight}
			/>
			{renderTopicCards()}
		</div>
	);
};

BrandSpotlightCard.propTypes = {
	isMulti: T.bool,
	brandSpotlight: T.object,
	onBrandSpotlightChange: T.func,
	brandSpotlightSponsors: T.array,
};
export default BrandSpotlightCard;
