import React, {
	useEffect, useState, useReducer, Fragment,
} from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import 'rc-pagination/assets/index.css';
import T from 'prop-types';
import {
	Input, Button,
} from '../../../components/Styles';
// import ToastMessage from '../../../components/ToastMessage';
import { AsyncMultiSelect, TitleWithTooltip } from '../../../components/FormComponents';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import LoadingIcon from '../../../components/LoadingIcon/LoadingIcon';
import {
	GET_PROFILE_DATA,
	FETCH_PROFILE_DATA,
	FETCH_PROFILE_DATA_SUCCESS,
	FETCH_PROFILE_DATA_FAILURE,
	UPDATE_TALENT_BASE_URL,
	SET_ALL_ELEMENTS_BLURRED,
	UPDATE_ERRORS,
	UPDATE_BLURRED_ELEMENT,
	UPDATE_ACTIONS_ERROR,
	UPDATE_ACTIONS_SUCCESS,
	UPDATE_ACTIONS_LOADER,
	SET_ACTIONS_MESSAGE,
} from '../constants';
import {
	GET_JOB_STRENGTH_TYPE,
} from './constants';
import {
	filterAsyncSelectOptions,
	returnAsyncFilterValue, returnOnlyValueIncludedArray,
	removeDuplicatesFromAsyncMultiSelect, filterProfileData,
	jobProfileContactInfoValidation,
} from '../../../utils/common';

const initialState = {
	cityList: [],
	profileJobData: {},
	loading: false,
	error: false,
	actionLoading: false,
	actionError: false,
	actionMessage: '',
	blurredElements: {
		strengths: false,
		linkedInProfile: false,
	},
	errors: {
		strengths: false,
		linkedInProfile: false,
	},
};

function setActionsMessage(message) {
	return {
		type: SET_ACTIONS_MESSAGE,
		message,
	};
}
function updateActionsLoading() {
	return {
		type: UPDATE_ACTIONS_LOADER,
	};
}

function updateActionsFailure() {
	return {
		type: UPDATE_ACTIONS_ERROR,
	};
}

function updateActionsSuccess() {
	return {
		type: UPDATE_ACTIONS_SUCCESS,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function fetchProfileJobData() {
	return {
		type: FETCH_PROFILE_DATA,
	};
}
function fetchProfileJobDataSuccess(profileJobData) {
	return {
		type: FETCH_PROFILE_DATA_SUCCESS,
		profileJobData,
	};
}
function fetchProfileJobDataFailure() {
	return {
		type: FETCH_PROFILE_DATA_FAILURE,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case FETCH_PROFILE_DATA:
		return { ...state, loading: true };
	case FETCH_PROFILE_DATA_SUCCESS:
		return {
			...state, loading: false, profileJobData: { ...action.profileJobData },
		};
	case FETCH_PROFILE_DATA_FAILURE:
		return {
			...state, loading: false, error: true, profileJobData: {},
		};
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };
	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			strengths: true,
			linkedInProfile: true,
		};
		return { ...state, blurredElements };
	}
	case UPDATE_ACTIONS_ERROR:
		return {
			...state, actionLoading: false, actionError: true,
		};
	case UPDATE_ACTIONS_SUCCESS:
		return {
			...state, actionLoading: false, actionError: false,
		};
	case UPDATE_ACTIONS_LOADER:
		return {
			...state, actionLoading: true, actionError: false,
		};
	case SET_ACTIONS_MESSAGE:
		return {
			...state, actionMessage: action.message,
		};
	default:
		return state;
	}
}

const MoreAboutYouProfilePage = (props) => {
	const { match, history } = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		loading, errors, blurredElements, actionLoading, actionError, actionMessage,
	} = state;
	const [jobStrengthTypeList, setJobStrengthTypeList] = useState([]);
	const [validLinkedInUrl, setValidLinkedInUrl] = useState(true);
	const [validOtherProfileUrl, setValidOtherProfileUrl] = useState({
		profileUrl1: true,
		profileUrl2: true,
		profileUrl3: true,
	});
	const [profileData, setProfileData] = useState({
		strengths: [],
		linkedInProfile: '',
		otherProfile: [],
	});

	useEffect(() => {
		dispatch(updateErrors({
			strengths: false,
			linkedInProfile: false,
		}));
		dispatch(updateBlurredElement({
			strengths: false,
			linkedInProfile: false,
		}));
		dispatch(setAllElementsBlurred());
		if (match?.params?.profileId) {
			getJobStrengthTypeList();
			getProfileData(match.params.profileId);
		}
	}, []);

	async function getJobStrengthTypeList() {
		const url = `${GET_JOB_STRENGTH_TYPE}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
				return [];
			} if (response.status === 200) {
				const finalResult = await response.json();
				if (finalResult && finalResult?.length > 0) {
					setJobStrengthTypeList([...finalResult]);
					return finalResult;
				}
			} else {
				setJobStrengthTypeList([]);
			}
		} catch (err) {
			setJobStrengthTypeList([]);
		}
	}
	async function getProfileData(profileId) {
		dispatch(fetchProfileJobData());
		const url = `${GET_PROFILE_DATA}${profileId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				const profileDetails = await response.json();
				if (profileDetails && profileDetails?.id?.length > 0) {
					setProfileData({
						...filterProfileData(profileDetails),
						strengths: returnAsyncFilterValue(profileDetails.strengths),
						linkedInProfile: profileDetails.linkedInProfile,
						otherProfile: {
							profileLinkUrl1: profileDetails.otherProfile?.[0],
							profileLinkUrl2: profileDetails.otherProfile?.[1],
							profileLinkUrl3: profileDetails.otherProfile?.[2],
						},
					});
				}
				dispatch(fetchProfileJobDataSuccess(profileDetails));
			} else {
				dispatch(fetchProfileJobDataFailure());
			}
		} catch (err) {
			dispatch(fetchProfileJobDataFailure());
		}
	}
	const saveProfileDataForUpdate = async () => {
		dispatch(setActionsMessage(''));
		dispatch(updateActionsLoading());
		const url = UPDATE_TALENT_BASE_URL;
		const formData = {
			...profileData,
			profileId: match.params.profileId,
			strengths: returnOnlyValueIncludedArray(profileData.strengths),
			linkedInProfile: profileData.linkedInProfile,
			otherProfile: [
				profileData.otherProfile?.profileLinkUrl1 ?? '',
				profileData.otherProfile?.profileLinkUrl2 ?? '',
				profileData.otherProfile?.profileLinkUrl3 ?? '',
			],
			switchFunctionIndustry: 'Yes',
			prefIndustry: profileData?.prefIndustry ? profileData?.prefIndustry : '',
			prefCity: profileData?.prefCity ? profileData?.prefCity : [],
		};
		const options = {
			method: 'PATCH',
			credentials: 'include',
			body: JSON.stringify(formData),
			headers: {
				'Content-Type': 'application/json',
			},
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				dispatch(updateActionsSuccess());
				dispatch(setActionsMessage('More About You Details are updated successfully'));
				setTimeout(() => {
					dispatch(setActionsMessage(''));
				}, 2000);
			} else {
				dispatch(updateActionsFailure());
				dispatch(setActionsMessage('Not Able To Update The Job profile'));
				setTimeout(() => {
					dispatch(updateActionsSuccess());
				}, 2000);
			}
		} catch (err) {
			dispatch(updateActionsFailure());
			dispatch(setActionsMessage('Not Able To Update The Job profile'));
			setTimeout(() => {
				dispatch(setActionsMessage(''));
				dispatch(updateActionsSuccess());
			}, 2000);
		}
	};
	function validateForm() {
		return {
			strengths: !(profileData.strengths && profileData.strengths?.length > 0),
			linkedInProfile: !(profileData.linkedInProfile && profileData.linkedInProfile?.length > 0),
		};
	}
	const handleErrors = () => {
		const validation = validateForm();
		const errors = Object.keys(validation).reduce((acc, curr) => {
			if (validation[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		const validation = validateForm();
		const validated = !Object.keys(validation).some((i) => validation[i]);
		const validatedOtherProfile = [
			validOtherProfileUrl.profileUrl1,
			validOtherProfileUrl.profileUrl2,
			validOtherProfileUrl.profileUrl3,
		];
		const otherProfileValidation = validatedOtherProfile.every((item) => item === true);
		handleErrors();
		if (validated && otherProfileValidation) {
			saveProfileDataForUpdate();
		} else {
			dispatch(setAllElementsBlurred());
		}
	};

	return (
		<div>
			{
				loading
					? <LoadingIcon />
					: <>
						<div>
							<h3 className="px-3">
								Edit More about You
							</h3>
							<form onSubmit={handleSubmit}>
								<Row className="m-2">
									<Col xs={12} sm={12}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Your Job Strengths *"
												hint="Your Job Strengths"
											/>
											<AsyncMultiSelect
												placeholder="Select From List"
												defaultOptionsList={jobStrengthTypeList}
												options={(value) => filterAsyncSelectOptions(jobStrengthTypeList, value)}
												currentValues={profileData.strengths}
												handleChange={(value) => {
													setProfileData({ ...profileData, strengths: removeDuplicatesFromAsyncMultiSelect(value) });
												}}
												profile="yourJobStrengths"
											/>
											<ErrorMessage display={errors.strengths} element="Job Strengths" />
										</div>
									</Col>
									<Col xs={12} sm={12}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Candidate LinkedIn Profile *"
												hint="Candidate LinkedIn Profile"
											/>
											<Input
												type="text"
												className="form-control"
												value={profileData.linkedInProfile}
												name="candidateLinkedInProfile"
												autoComplete="off"
												maxLength="1000"
												placeholder="eg: https://linkedin.com/yourid"
												onChange={(e) => {
													setProfileData(({
														...profileData,
														linkedInProfile: e.target.value,
													}));
													if (e.target.value?.length > 0) {
														setValidLinkedInUrl(jobProfileContactInfoValidation(e.target.value, 4));
													} else {
														setValidLinkedInUrl(true);
													}
													handleErrors();
												}}
											/>
											{!validLinkedInUrl && (
												<p className="error error-text text-normal text-center mb-2">
													LinkedId link is Invalid!!!
												</p>
											)}
											<ErrorMessage display={errors.linkedInProfile} element="LinkedIn Profile Link" />
										</div>
									</Col>
									<Col xs={12} sm={12}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Candidate Other Profile Links"
												hint="Candidate Other Profile Links"
											/>
											<div className="w-100 pb-2 pt-2">
												<Input
													type="text"
													className="form-control"
													value={profileData.otherProfile?.profileLinkUrl1}
													name="candidateOtherProfileLink-1"
													autoComplete="off"
													maxLength="1000"
													placeholder="eg: website link"
													onChange={(e) => {
														setProfileData(({
															...profileData,
															otherProfile: {
																...profileData.otherProfile,
																profileLinkUrl1: e.target.value,
															},
														}));
														if (e.target.value?.length > 0) {
															setValidOtherProfileUrl({
																...validOtherProfileUrl,
																profileUrl1: jobProfileContactInfoValidation(e.target.value, 3),
															});
														} else {
															setValidOtherProfileUrl({
																...validOtherProfileUrl,
																profileUrl1: true,
															});
														}
													}}
												/>
												{!validOtherProfileUrl?.profileUrl1 && (
													<p className="error error-text text-normal text-center mb-2">
														Profile Link is Invalid!!!
													</p>
												)}
											</div>
											<div className="w-100 pb-2">
												<Input
													type="text"
													className="form-control"
													value={profileData.otherProfile?.profileLinkUrl2}
													name="candidateOtherProfileLink-2"
													autoComplete="off"
													maxLength="1000"
													placeholder="eg: github link"
													onChange={(e) => {
														setProfileData(({
															...profileData,
															otherProfile: {
																...profileData.otherProfile,
																profileLinkUrl2: e.target.value,
															},
														}));
														if (e.target.value?.length > 0) {
															setValidOtherProfileUrl({
																...validOtherProfileUrl,
																profileUrl2: jobProfileContactInfoValidation(e.target.value, 3),
															});
														} else {
															setValidOtherProfileUrl({
																...validOtherProfileUrl,
																profileUrl2: true,
															});
														}
													}}
												/>
												{!validOtherProfileUrl?.profileUrl2 && (
													<p className="error error-text text-normal text-center mb-2">
														Profile Link is Invalid!!!
													</p>
												)}
											</div>
											<div className="w-100 pb-2">
												<Input
													type="text"
													className="form-control"
													value={profileData.otherProfile?.profileLinkUrl3}
													name="candidateOtherProfileLink-3"
													autoComplete="off"
													maxLength="1000"
													placeholder="eg: any other link"
													onChange={(e) => {
														setProfileData(({
															...profileData,
															otherProfile: {
																...profileData.otherProfile,
																profileLinkUrl3: e.target.value,
															},
														}));
														if (e.target.value?.length > 0) {
															setValidOtherProfileUrl({
																...validOtherProfileUrl,
																profileUrl3: jobProfileContactInfoValidation(e.target.value, 3),
															});
														} else {
															setValidOtherProfileUrl({
																...validOtherProfileUrl,
																profileUrl3: true,
															});
														}
													}}
												/>
												{!validOtherProfileUrl?.profileUrl3 && (
													<p className="error error-text text-normal text-center mb-2">
														Profile Link is Invalid!!!
													</p>
												)}
											</div>
										</div>
									</Col>
									<Col xs={12} sm={12} className="mt-2 text-center">
										<Button
											success
											no_radius
											type="submit"
											style={{ width: '150px' }}
										>
											<b>Save Changes</b>
										</Button>
									</Col>
									<Col xs={12} sm={12}>
										{actionLoading && <LoadingIcon />}
									</Col>
									<Col xs={12} sm={12}>
										{(actionError && actionMessage) && <p className="error error-text text-normal text-center mb-2">
											{actionMessage}
										</p>}
										{(!actionError && actionMessage) && <p className="success-text text-normal text-center mb-2">
											{actionMessage}
										</p>}
									</Col>
								</Row>
							</form>
						</div>
					</>
			}
		</div>
	);
};

MoreAboutYouProfilePage.propTypes = {
	// status: T.number,
	history: T.object,
	match: T.object,
};
export default MoreAboutYouProfilePage;
