import { createSelector } from 'reselect';

const selectProfileDesk = (state) => state.get('profileDesk');

const makeSelectDeskProfiles = () => createSelector(
	selectProfileDesk,
	(profileDeskState) => profileDeskState.get('profiles'),
);

const makeSelectProfileDeskPage = () => createSelector(
	selectProfileDesk,
	(profileDeskState) => profileDeskState.get('page'),
);

const makeSelectProfileDeskStatus = () => createSelector(
	selectProfileDesk,
	(profileDeskState) => profileDeskState.get('status'),
);

const makeSelectProfileDeskSearchType = () => createSelector(
	selectProfileDesk,
	(profileDeskState) => profileDeskState.get('searchType'),
);

const makeSelectProfileDeskSearch = () => createSelector(
	selectProfileDesk,
	(profileDeskState) => profileDeskState.get('search'),
);

const makeSelectProfileDeskSearchValue = () => createSelector(
	selectProfileDesk,
	(profileDeskState) => profileDeskState.get('searchValue'),
);

const makeSelectProfileDeskLoading = () => createSelector(
	selectProfileDesk,
	(profileDeskState) => profileDeskState.get('loading'),
);

const makeSelectAlternateProfileId = () => createSelector(
	selectProfileDesk,
	(profileDeskState) => profileDeskState.get('alternateProfileId'),
);

export {
	makeSelectDeskProfiles,
	makeSelectProfileDeskPage,
	makeSelectProfileDeskStatus,
	makeSelectProfileDeskSearchType,
	makeSelectProfileDeskSearch,
	makeSelectProfileDeskSearchValue,
	makeSelectProfileDeskLoading,
	makeSelectAlternateProfileId,
};
