export const JOB_EDITOR_API_BASE_URL = '/api/v2/rw/job';

export const STATUS = Object.freeze({
	ERROR: 'ERROR',
	SUCCESS: 'SUCCESS',
	FAIL: 'FAIL',
	UPLOADING: 'UPLOADING',
	PROCESSING: 'PROCESSING',
});

export const MS_DOC_CONVERSION_API = '/api/v2/rw/job/convert';
export const GOOGLE_DOC_CONVERSION_API = '/api/v2/rw/job/convert/google';

export const UPDATE_MESSAGE = { intent: 'info', message: 'Updating job...' };
export const UPDATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Job updated Successfully' };
export const UPDATE_FAILURE_MESSAGE = { intent: 'danger', message: 'Job update Failed. Something went wrong. Try Again!' };

export const CREATE_MESSAGE = { intent: 'info', message: 'Creating job...' };
export const CREATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Job created Successfully' };
export const CREATE_FAILURE_MESSAGE = { intent: 'danger', message: 'Job creation Failed. Something went wrong. Try Again!' };
