export const HANDLE_BLUR = 'HANDLE_BLUR';
export const UPDATE_FROM_DATA = 'UPDATE_FROM_DATA';
export const UPDATE_SPONSORS_LIST = 'UPDATE_SPONSORS_LIST';
export const ADD_SPONSOR = 'ADD_SPONSOR';
export const REMOVE_SPONSOR = 'REMOVE_SPONSOR';

export const FETCH_USER_BRANDS = 'FETCH_USER_BRANDS';
export const FETCH_BRAND_SUCCESS = 'FETCH_BRAND_SUCCESS';
export const CLEAR_THUMBNAIL = 'CLEAR_THUMBNAIL';
export const CREATE_EVENT = 'CREATE_EVENT';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';

export const UPDATE_FORM_MESSAGE = 'UPDATE_FORM_MESSAGE';
export const FETCH_EVENT_DATA = 'FETCH_EVENT_DATA';
export const FETCH_EVENT_DATA_SUCCESS = 'FETCH_EVENT_DATA_SUCCESS';
export const FETCH_EVENT_FAILURE = 'FETCH_EVENT_FAILURE';
export const UPDATE_EVENT_BY_ID = 'UPDATE_EVENT_BY_ID';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAILURE = 'UPDATE_EVENT_FAILURE';

export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE';
