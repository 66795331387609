import {
	HANDLE_BLUR,
	UPDATE_FROM_DATA,
	UPDATE_SPONSORS_LIST,
	ADD_SPONSOR, REMOVE_SPONSOR,
	FETCH_BRAND_SUCCESS,
	FETCH_USER_BRANDS,
	CLEAR_THUMBNAIL,
	CREATE_EVENT,
	CREATE_EVENT_SUCCESS,
	CREATE_EVENT_FAILURE,
	UPDATE_FORM_MESSAGE,
	FETCH_EVENT_DATA,
	FETCH_EVENT_DATA_SUCCESS,
	FETCH_EVENT_FAILURE,
	UPDATE_EVENT_BY_ID,
	UPDATE_EVENT_SUCCESS,
	UPDATE_EVENT_FAILURE,
	DELETE_EVENT,
	DELETE_EVENT_FAILURE,
} from './type';

export function handleBlur(element) {
	return {
		type: HANDLE_BLUR,
		element,
	};
}

export function onFormDataChange(changedData) {
	return {
		type: UPDATE_FROM_DATA,
		changedData,
	};
}

export function updateSponsorsList(changedData, index) {
	return {
		type: UPDATE_SPONSORS_LIST,
		changedData,
		index,
	};
}

export function addSponsor() {
	return {
		type: ADD_SPONSOR,
	};
}

export function removeSponsor(index) {
	return {
		type: REMOVE_SPONSOR,
		index,
	};
}

export function fetchBrandSuccess(brands) {
	return {
		type: FETCH_BRAND_SUCCESS,
		brands,
	};
}

export function fetchBrands() {
	return {
		type: FETCH_USER_BRANDS,
	};
}

export function clearThumbnail(hoc, index) {
	return {
		type: CLEAR_THUMBNAIL,
		hoc,
		index,
	};
}

export function createEvent(data) {
	return {
		type: CREATE_EVENT,
		data,
	};
}

export function createEventSuccess(data) {
	return {
		type: CREATE_EVENT_SUCCESS,
		data,
	};
}

export function createEventFailure(data) {
	return {
		type: CREATE_EVENT_FAILURE,
		data,
	};
}

export function updateFormMessage(message) {
	return {
		type: UPDATE_FORM_MESSAGE,
		message,
	};
}

export function fetchEventData(eventId) {
	return {
		type: FETCH_EVENT_DATA,
		eventId,
	};
}

export function fetchEventSuccess(data) {
	return {
		type: FETCH_EVENT_DATA_SUCCESS,
		data,
	};
}

export function fetchEventFailure(err) {
	return {
		type: FETCH_EVENT_FAILURE,
		err,
	};
}

export function updateEventById(data, id) {
	return {
		type: UPDATE_EVENT_BY_ID,
		data,
		id,
	};
}

export function updateEventSuccess() {
	return {
		type: UPDATE_EVENT_SUCCESS,
	};
}

export function updateEventFailure(data) {
	return {
		type: UPDATE_EVENT_FAILURE,
		data,
	};
}

export function deleteEventById(id) {
	return {
		type: DELETE_EVENT,
		id,
	};
}

export function deleteEventFailure(data) {
	return {
		type: DELETE_EVENT_FAILURE,
		data,
	};
}
