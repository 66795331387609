export const PENDING_APPROVAL_BASE_URL = '/api/v2/rw/talent';

export const FILTER_PENDING_APPROVAL = 'FILTER_PENDING_APPROVAL';
export const FETCH_PENDING_APPROVAL_SUCCESS = 'FETCH_PENDING_APPROVAL_SUCCESS';
export const FETCH_PENDING_APPROVAL_FAILURE = 'FETCH_PENDING_APPROVAL_FAILURE';
export const FETCH_PENDING_APPROVAL = 'FETCH_PENDING_APPROVAL';
export const FILTER_PENDING_APPROVAL_SUCCESS = 'FILTER_PENDING_APPROVAL_SUCCESS';
export const FILTER_PENDING_APPROVAL_FAILURE = 'FILTER_PENDING_APPROVAL_FAILURE';
export const SET_PENDING_APPROVAL_PAGE = 'SET_PENDING_APPROVAL_PAGE';
export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
export const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
export const UPDATE_ACTIONS_ERROR = 'UPDATE_ACTIONS_ERROR';
export const UPDATE_ACTIONS_SUCCESS = 'UPDATE_ACTIONS_SUCCESS';
export const UPDATE_ACTIONS_LOADER = 'UPDATE_ACTIONS_LOADER';
export const SET_ACTIONS_MESSAGE = 'SET_ACTIONS_MESSAGE';
