import {
	SELECT_TOPIC, SEND_NOTIFICATION, SEND_NOTIFICATION_SUCCESS, SEND_NOTIFICATION_ERROR, CHANGE_NOTIFICATION_PAYLOAD, UPDATE_DISPLAY_MESSAGE, CREATE_NOTIFICATION, CREATE_NOTIFICATION_SUCCESS, CREATE_NOTIFICATION_ERROR,
} from './type';

export function selectTopic(topic, icon) {
	return {
		type: SELECT_TOPIC,
		topic,
		icon,
	};
}

export function changeNotificationPayload(itemName, itemValue) {
	return {
		type: CHANGE_NOTIFICATION_PAYLOAD,
		itemName,
		itemValue,
	};
}

export function sendNotification(payload) {
	return {
		type: SEND_NOTIFICATION,
		payload,
	};
}

export function sendNotificationSuccess(message) {
	return {
		type: SEND_NOTIFICATION_SUCCESS,
		message,
	};
}

export function sendNotificationError(error) {
	return {
		type: SEND_NOTIFICATION_ERROR,
		error,
	};
}

export function updateDisplayMessage(message) {
	return {
		type: UPDATE_DISPLAY_MESSAGE,
		message,
	};
}

export function createNotification(notification) {
	return {
		type: CREATE_NOTIFICATION,
		notification,
	};
}

export function createNotificationSuccess(successResponse) {
	return {
		type: CREATE_NOTIFICATION_SUCCESS,
		successResponse,
	};
}

export function createNotificationError(error) {
	return {
		type: CREATE_NOTIFICATION_ERROR,
		error,
	};
}
