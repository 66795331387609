import {
	call, put, takeLatest, select,
} from 'redux-saga/effects';
import { FETCH_CLAIMS_BY_STATUS, UPDATE_CLAIM_STATUS, FILTER_CLAIMS_BY_COMPANY } from './type';
import { PROFILE_CLAIMS_BASE_API_URL } from './constants';
import { redirectTo } from '../../utils/accessRedirection';
import { makeSelectLocation } from '../App/selectors';
import {
	fetchClaimsByStatusSuccess, fetchClaimsByStatusFailure, updateClaimStatusSuccess, updateClaimStatusFailure, fetchClaimsByStatus, filterClaimsByCompanySuccess, filterClaimsByCompanyFailure,
} from './actions';

let CURRENT_LOCATION;

function* getClaimsByStatus(action) {
	const url = `${PROFILE_CLAIMS_BASE_API_URL}/status/${action.status.toLowerCase()}/page/${action.page}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const profileClaims = yield response.json();
			yield put(fetchClaimsByStatusSuccess(profileClaims));
		}
	} catch (err) {
		yield put(fetchClaimsByStatusFailure(err));
	}
}

function* updateClaimStatus(action) {
	const url = `${PROFILE_CLAIMS_BASE_API_URL}/status/${action.claimId}`;
	const payload = new FormData();
	payload.append('status', action.status);
	payload.append('comment', action.comment);
	const options = {
		method: 'PUT',
		credentials: 'include',
		body: payload,
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const profileClaims = yield response.json();
			yield put(updateClaimStatusSuccess(profileClaims));
			yield put(fetchClaimsByStatus(action.currentTab, action.page));
		}
	} catch (err) {
		yield put(updateClaimStatusFailure(err));
	}
}

function* filterClaims(action) {
	const url = `${PROFILE_CLAIMS_BASE_API_URL}/filter/status/${action.status}/page/${action.page}`;
	const options = {
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'POST',
		credentials: 'include',
		body: JSON.stringify({ company: action.company }),
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const profileClaims = yield response.json();
			yield put(filterClaimsByCompanySuccess(profileClaims));
		}
	} catch (err) {
		yield put(filterClaimsByCompanyFailure(err));
	}
}

export default function* profileClaimDeskSaga() {
	const location = yield select(makeSelectLocation());
	CURRENT_LOCATION = location.pathname;
	yield takeLatest(FETCH_CLAIMS_BY_STATUS, getClaimsByStatus);
	yield takeLatest(UPDATE_CLAIM_STATUS, updateClaimStatus);
	yield takeLatest(FILTER_CLAIMS_BY_COMPANY, filterClaims);
}
