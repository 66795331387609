import {
	call, put, takeLatest, select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { UPDATE_STORYLIST_STORYITEM_STATUS, FETCH_STORYLIST_BRAND, FETCH_STORY_LIST } from './type';
import {
	changeStoryListStoryItemStatusError, fetchStoryListBrandSuccess, fetchStoryListBrandFailure, fetchStoryListSuccess, fetchStoryListError, fetchStoryList, changeStoryListStoryItemStatusSuccess,
} from './actions';
import { DESK_BASE_API_URL } from '../StoryDesk/constants';
import { BRAND_API_BASE_URL } from '../BrandManager/constants';
import { makeSelectStoryListSelectedStatus, makeSelectStoryListSelectedBrand, makeSelectStoryListPage } from './selectors';

export function* getStoryList(action) {
	const storyTypeUrl = action?.storyType !== 'All' ? `?type=${action?.storyType}` : '';
	const url = `${DESK_BASE_API_URL}/userstories/${action.brand}/${action.status}/${action.page}${storyTypeUrl}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const stories = yield response.json();
			yield put(fetchStoryListSuccess(stories));
		}
	} catch (err) {
		yield put(fetchStoryListError(err));
	}
}

export function* updateStoryListStoryItemStatus(action) {
	const url = `${DESK_BASE_API_URL}/updatestatus/${action.story.brand}/${action.story.storyId}`;
	const data = new FormData();
	data.append('status', action.newStatus);
	data.append('story', JSON.stringify(action.story));
	const options = {
		method: 'PUT',
		credentials: 'include',
		body: data,
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			const selectedStatus = yield select(makeSelectStoryListSelectedStatus());
			const selectedBrand = yield select(makeSelectStoryListSelectedBrand());
			const page = yield select(makeSelectStoryListPage());
			if (responseMessage.success) {
				yield put(changeStoryListStoryItemStatusSuccess(responseMessage));
				yield put(fetchStoryList(selectedBrand.slug, selectedStatus.toLowerCase(), page));
			} else {
				yield put(changeStoryListStoryItemStatusError(responseMessage));
			}
		}
	} catch (err) {
		yield put(changeStoryListStoryItemStatusError(err));
	}
}

export function* getStoryListBrands() {
	const url = `${BRAND_API_BASE_URL}/userbrands`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const brands = yield response.json();
			yield put(fetchStoryListBrandSuccess(brands));
		}
	} catch (err) {
		yield put(fetchStoryListBrandFailure(err));
	}
}

export default function* storyListData() {
	yield takeLatest(FETCH_STORY_LIST, getStoryList);
	yield takeLatest(UPDATE_STORYLIST_STORYITEM_STATUS, updateStoryListStoryItemStatus);
	yield takeLatest(FETCH_STORYLIST_BRAND, getStoryListBrands);
}
