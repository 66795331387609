export const CITY_API_BASE_URL = '/api/v2/rw/cities';

export const CITY_LOADING_MESSAGE = { intent: 'info', message: 'Loading...' };
export const CITY_CREATE_SUCCESS_MESSAGE = { intent: 'success', message: 'City Saved Successfully' };
export const CITY_CREATE_LOAD_MESSAGE = { intent: 'info', message: 'Saving...' };
export const CITY_UPDATE_SUCCESS_MESSAGE = { intent: 'success', message: 'City Updated Successfully' };
export const CITY_UPDATE_LOAD_MESSAGE = { intent: 'info', message: 'Updating...' };
export const CITY_DELETE_SUCCESS_MESSAGE = { intent: 'success', message: 'City Deleted Successfully' };
export const CITY_DELETE_LOAD_MESSAGE = { intent: 'info', message: 'Deleting...' };
export const CITY_ERROR_MESSAGE = { intent: 'danger', message: 'ERROR!!!' };
