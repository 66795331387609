import React, {
	useEffect, useState, useReducer, Fragment,
} from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import 'rc-pagination/assets/index.css';
import Pagination from 'rc-pagination';
import T from 'prop-types';
import Modal from 'react-modal';
import {
	Input, Button,
} from '../../../components/Styles';
// import ToastMessage from '../../../components/ToastMessage';
import { TitleWithTooltip } from '../../../components/FormComponents';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import { ListItem, ListHeader } from '../../../components/AdminJobListComponents/index';
import locale from '../../WatchlistedStories/en_US';
import LoadingIcon from '../../../components/LoadingIcon/LoadingIcon';
import {
	APPROVED_ACTIVE_BASE_URL,
	FETCH_APPROVED_ACTIVE,
	FETCH_APPROVED_ACTIVE_SUCCESS,
	FETCH_APPROVED_ACTIVE_FAILURE,
	FILTER_APPROVED_ACTIVE,
	FILTER_APPROVED_ACTIVE_SUCCESS,
	FILTER_APPROVED_ACTIVE_FAILURE,
	SET_APPROVED_ACTIVE_PAGE,
	SET_ALL_ELEMENTS_BLURRED,
	UPDATE_ERRORS,
	UPDATE_BLURRED_ELEMENT,
	UPDATE_ACTIONS_ERROR,
	UPDATE_ACTIONS_SUCCESS,
	UPDATE_ACTIONS_LOADER,
	SET_ACTIONS_MESSAGE,
} from './constants';

const initialState = {
	approvedActiveJobs: [],
	jobsCount: null,
	loading: false,
	error: false,
	page: 1,
	actionLoading: false,
	actionError: false,
	actionMessage: '',
	blurredElements: {
		rejectReason: false,
	},
	errors: {
		rejectReason: false,
	},
};

function setActionsMessage(message) {
	return {
		type: SET_ACTIONS_MESSAGE,
		message,
	};
}

function updateActionsLoading() {
	return {
		type: UPDATE_ACTIONS_LOADER,
	};
}

function updateActionsFailure() {
	return {
		type: UPDATE_ACTIONS_ERROR,
	};
}

function updateActionsSuccess() {
	return {
		type: UPDATE_ACTIONS_SUCCESS,
	};
}
function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}
function fetchApprovedActiveJobs() {
	return {
		type: FETCH_APPROVED_ACTIVE,
	};
}

function fetchApprovedActiveJobsSuccess(approvedActiveJobs) {
	return {
		type: FETCH_APPROVED_ACTIVE_SUCCESS,
		approvedActiveJobs,
	};
}

function fetchApprovedActiveJobsFailure(error) {
	return {
		type: FETCH_APPROVED_ACTIVE_FAILURE,
		error,
	};
}

function setPage(page) {
	return {
		type: SET_APPROVED_ACTIVE_PAGE,
		page,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case FETCH_APPROVED_ACTIVE:
		return { ...state, loading: true };
	case FETCH_APPROVED_ACTIVE_SUCCESS:
		return {
			...state, loading: false, approvedActiveJobs: [...action.approvedActiveJobs?.rows], jobsCount: action.approvedActiveJobs?.count,
		};
	case FETCH_APPROVED_ACTIVE_FAILURE:
		return {
			...state, loading: false, error: true, approvedActiveJobs: [],
		};
	case FILTER_APPROVED_ACTIVE:
		return { ...state, loading: true };
	case FILTER_APPROVED_ACTIVE_SUCCESS:
		return {
			...state, loading: false, approvedActiveJobs: [...action.approvedActiveJobs?.rows], jobsCount: action.approvedActiveJobs?.count,
		};
	case FILTER_APPROVED_ACTIVE_FAILURE:
		return {
			...state, loading: true, error: true, approvedActiveJobs: [],
		};
	case SET_APPROVED_ACTIVE_PAGE:
		return { ...state, page: action.page };
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };
	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			introduction: true,
			detailedBio: true,
			existingBio: true,
			rejectReason: true,
		};
		return { ...state, blurredElements };
	}
	case UPDATE_ACTIONS_ERROR:
		return {
			...state, actionLoading: false, actionError: true,
		};
	case UPDATE_ACTIONS_SUCCESS:
		return {
			...state, actionLoading: false, actionError: false,
		};
	case UPDATE_ACTIONS_LOADER:
		return {
			...state, actionLoading: true, actionError: false,
		};
	case SET_ACTIONS_MESSAGE:
		return {
			...state, actionMessage: action.message,
		};
	default:
		return state;
	}
}

const ApprovedActiveJobsList = (props) => {
	const { status, history } = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		approvedActiveJobs, loading, jobsCount, page, errors, blurredElements, actionLoading, actionError, actionMessage,
	} = state;
	const [searchValue, setSearchValue] = useState('');
	const [viewBioForm, setViewBioForm] = useState(false);
	const [showRejectionReasonForm, setShowRejectionReasonForm] = useState(false);
	const [selectedJob, setSelectedJob] = useState({});
	const [rejectJobData, setRejectJobData] = useState({
		rejectReason: '',
	});
	// const [filterBy, setFilterBy] = useState('select');
	// const [filterValue, setFilterValue] = useState(null);

	useEffect(() => {
		dispatch(updateErrors({
			rejectReason: false,
		}));
		dispatch(updateBlurredElement({
			rejectReason: false,
		}));
		dispatch(setAllElementsBlurred());
		// pageTitle('approvedActiveJobs');
		getApprovedActiveJobs((page - 1) * 10, 10);
	}, []);

	async function getApprovedActiveJobs(offset, limit, searchVal = searchValue) {
		dispatch(fetchApprovedActiveJobs());
		const url = `${APPROVED_ACTIVE_BASE_URL}?status=${status}&offset=${offset}&limit=${limit}&search=${searchVal}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				const approvedActiveJobs = await response.json();
				dispatch(fetchApprovedActiveJobsSuccess(approvedActiveJobs));
			} else {
				dispatch(fetchApprovedActiveJobsFailure());
			}
		} catch (err) {
			dispatch(fetchApprovedActiveJobsFailure(err));
		}
	}

	function handleSearch(e) {
		e.preventDefault();
		const { value } = e.target;
		getApprovedActiveJobs(0, 10, value);
		dispatch(setPage(1));
	}

	// function handleFilterValueChange(e) {
	// 	const { value } = e.target;
	// 	setFilterValue(value);
	// 	if (value && filterBy !== 'select') {
	// 		filterapprovedActiveJobs(searchValue, filterBy, value, 1);
	// 	} else {
	// 		filterapprovedActiveJobs(searchValue, 'select', null, 1);
	// 	}
	// 	dispatch(setPage(1));
	// }

	// function handleFilterByChange(e) {
	// 	const { value } = e.target;
	// 	setFilterBy(value);
	// 	if (value === 'featured' || value === 'partnership') {
	// 		const newFilterValue = ![false, 'false'].includes(filterValue);
	// 		setFilterValue(newFilterValue);
	// 		filterapprovedActiveJobs(searchValue, value, newFilterValue, 1);
	// 	} else if (value === 'startTime' || value === 'endTime') {
	// 		const newFilterValue = filterValue || 'ASC';
	// 		setFilterValue(newFilterValue);
	// 		filterapprovedActiveJobs(searchValue, value, newFilterValue, 1);
	// 	} else {
	// 		getapprovedActiveJobs(1, 10);
	// 	}
	// 	dispatch(setPage(1));
	// }

	function onPageChange(page) {
		getApprovedActiveJobs((page - 1) * 10, 10);
		dispatch(setPage(page));
	}

	function closeModal() {
		setRejectJobData({});
		setSelectedJob({});
		dispatch(setPage(1));
		dispatch(updateErrors({
			introduction: false,
			detailedBio: false,
			existingBio: false,
		}));
		dispatch(updateBlurredElement({
			introduction: false,
			detailedBio: false,
			existingBio: false,
		}));
		dispatch(setAllElementsBlurred());
		setViewBioForm(false);
		setShowRejectionReasonForm(false);
	}
	const saveRejectJobApproval = async () => {
		dispatch(updateActionsLoading());
		const url = `${APPROVED_ACTIVE_BASE_URL}/status`;
		const formData = {
			profileId: selectedJob.id,
			status: 3,
			detailedBio: '',
			headLine: '',
			rejectReason: rejectJobData.rejectReason,
		};
		const options = {
			method: 'PATCH',
			credentials: 'include',
			body: JSON.stringify(formData),
			headers: {
				'Content-Type': 'application/json',
			},
		};
		try {
			// if ((rejectJobData.rejectReason.length > 250)) {
			// 	dispatch(updateActionsSuccess());
			// 	dispatch(setActionsMessage(''));
			// 	return alert('The respective fields cannot have a length of more than 250 characters.');
			// }
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response && response.status === 200) {
				getApprovedActiveJobs((page - 1) * 10, 10);
				dispatch(updateActionsSuccess());
				if (showRejectionReasonForm) {
					dispatch(setActionsMessage('The Rejection reason for the job is updated successfully.'));
				}
				setTimeout(() => {
					closeModal();
					dispatch(setActionsMessage(''));
				}, 2000);
			} else if (response && response.status !== 200) {
				dispatch(updateActionsFailure());
				if (showRejectionReasonForm) {
					dispatch(setActionsMessage('Not Able To Update The Reject Reason'));
				}
				setTimeout(() => {
					dispatch(updateActionsSuccess());
					dispatch(setActionsMessage(''));
					closeModal();
				}, 2000);
			}
		} catch (err) {
			dispatch(updateActionsFailure());
			if (showRejectionReasonForm) {
				dispatch(setActionsMessage('Not Able To Update The Reject Reason'));
			}
			setTimeout(() => {
				dispatch(updateActionsSuccess());
				closeModal();
			}, 2000);
		}
	};
	function validateForm() {
		if (showRejectionReasonForm) {
			return {
				rejectReason: !(rejectJobData && rejectJobData.rejectReason && rejectJobData.rejectReason?.length > 0),
			};
		}
	}
	const handleErrors = () => {
		const validation = validateForm();
		const errors = Object.keys(validation).reduce((acc, curr) => {
			if (validation[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		const validation = validateForm();
		const validated = !Object.keys(validation).some((i) => validation[i]);
		handleErrors();
		if (validated) {
			saveRejectJobApproval();
		} else {
			dispatch(setAllElementsBlurred());
		}
	};

	function handleBlur(element) {
		handleErrors();
		dispatch(updateBlurredElement(element));
	}

	function getApprovedActiveJobsList() {
		return approvedActiveJobs.map((job, index) => <ListItem
			key={job.id}
			history={history}
			slNo={(page - 1) * 10 + index + 1}
			job={job}
			rejectJob={() => {
				setShowRejectionReasonForm(true);
				setSelectedJob(job);
				setRejectJobData(({
					rejectReason: '',
				}));
			}}
			viewBio={() => {
				setViewBioForm(true);
				setSelectedJob(job);
				setRejectJobData(({
					rejectReason: '',
				}));
			}}
			index={index}
			selectedStatus={status}
		/>);
	}

	const rejectTheJob = () => <Modal
		isOpen={showRejectionReasonForm}
		shouldCloseOnOverlayClick={false}
		onRequestClose={closeModal}
		overlayClassName="modal-overlay"
		className="form-modal"
	>
		<Row>
			<Col md={10} xs={10}>
				<h2 className="w-50 text-left">Reject / Make In-Active The Job</h2>
			</Col>
			<Col md={2} xs={2}>
				<div className="w-50 text-right">
					<button type="button" onClick={closeModal}>
						<i className="fas fa-times light-text disablePointerEvents" />
					</button>
				</div>
			</Col>
		</Row>
		<div>
			<form onSubmit={handleSubmit}>
				<Row className="m-2">
					<Col xs={12} sm={12}>
						<div className="pb-3 pt-3">
							<TitleWithTooltip
								title="Reject Reason *"
								hint="This is the rejection reason of the job."
							/>
							<textarea
								rows="3"
								className="global-form-control"
								name="rejectReason"
								placeholder="Reject Reason Of The Job"
								value={rejectJobData.rejectReason}
								maxLength={250}
								onChange={(e) => {
									setRejectJobData(({
										...rejectJobData,
										rejectReason: e.target.value,
									}));
									handleErrors();
								}}
								onBlur={() => handleBlur('rejectReason')}
							/>
							<div style={{ textAlign: 'right', color: rejectJobData.rejectReason && rejectJobData.rejectReason.length > 250 ? '#ff0000' : '#aaa' }}>
								{rejectJobData && rejectJobData.rejectReason ? rejectJobData.rejectReason.length : 0}/250
							</div>
							<ErrorMessage display={errors.rejectReason} element="Reject Reason" />
						</div>
					</Col>
					<Col xs={12} sm={12} className="mt-2 text-center">
						<Button
							success
							no_radius
							type="submit"
							style={{ width: '100px' }}
						>
							<b>REJECT</b>
						</Button>
					</Col>
					<Col xs={12} sm={12}>
						{actionLoading && <LoadingIcon />}
					</Col>
					<Col xs={12} sm={12}>
						<Col xs={12} sm={12}>
							{(actionError && actionMessage) && <p className="error error-text text-normal text-center mb-2">
								{actionMessage}
							</p>}
							{(!actionError && actionMessage) && <p className="success-text text-normal text-center mb-2">
								{actionMessage}
							</p>}
						</Col>
					</Col>
				</Row>
			</form>
		</div>
	</Modal>;
	const viewBioOfTheJob = () => <Modal
		isOpen={viewBioForm}
		shouldCloseOnOverlayClick={false}
		onRequestClose={closeModal}
		overlayClassName="modal-overlay"
		className="form-modal"
	>
		<Row>
			<Col md={10} xs={10}>
				<h2 className="w-50 text-left">Bio Of The Job</h2>
			</Col>
			<Col md={2} xs={2}>
				<div className="w-50 text-right">
					<button type="button" onClick={closeModal}>
						<i className="fas fa-times light-text disablePointerEvents" />
					</button>
				</div>
			</Col>
		</Row>
		<div>
			<form>
				<Row className="m-2">
					<Col xs={12} sm={12}>
						<div className="pb-3">
							<TitleWithTooltip
								title="Existing Bio *"
								hint="Existing Bio"
							/>
							{/* <Input
								type="text"
								readOnly
								className="form-control"
								value={selectedJob.shortBio}
								name="bioName"
								autoComplete="off"
								maxLength="100"
							/> */}
							<textarea
								rows="3"
								readOnly
								className="global-form-control"
								name="bioName"
								placeholder="Existing Bio Of The Job"
								value={selectedJob.shortBio}
							/>
							<div style={{ textAlign: 'right', color: selectedJob.shortBio && selectedJob.shortBio.length > 250 ? '#aaa' : '#aaa' }}>
								{selectedJob && selectedJob.shortBio ? selectedJob.shortBio.length : 0}
							</div>
						</div>
					</Col>
					<Col xs={12} sm={12}>
						<div className="pb-3 pt-3">
							<TitleWithTooltip
								title="Introduction *"
								hint="This is the introduction of the job."
							/>
							<textarea
								rows="3"
								readOnly
								className="global-form-control"
								name="introduction"
								placeholder="Description/Summary Of The Job"
								value={selectedJob.headLine}
								maxLength={50}
							/>
							<div style={{ textAlign: 'right', color: selectedJob.headLine && selectedJob.headLine.length > 50 ? '#aaa' : '#aaa' }}>
								{selectedJob && selectedJob.headLine ? selectedJob.headLine.length : 0}/50
							</div>
						</div>
					</Col>
					<Col xs={12} sm={12}>
						<div className="pb-3 pt-3">
							<TitleWithTooltip
								title="Detailed Bio *"
								hint="This is the Detailed Bio of the job."
							/>
							<textarea
								rows="3"
								readOnly
								className="global-form-control"
								name="detailedBio"
								placeholder="Detailed Bio of the job"
								value={selectedJob.detailedBio}
							/>
							<div style={{ textAlign: 'right', color: selectedJob.detailedBio && selectedJob.detailedBio.length > 250 ? '#aaa' : '#aaa' }}>
								{selectedJob && selectedJob.detailedBio ? selectedJob.detailedBio.length : 0}
							</div>
						</div>
					</Col>
				</Row>
			</form>
		</div>
	</Modal>;

	function renderList() {
		if (approvedActiveJobs && approvedActiveJobs.length > 0) {
			return <div>
				<ListHeader selectedStatus={status} />
				{getApprovedActiveJobsList()}
			</div>;
		}

		return (
			<Row style={{
				width: '100%', minHeight: '400px', justifyContent: 'center', alignItems: 'center',
			}}
			>
				<div>
					<h1 style={{ color: '#bbb' }}>
						No Approved Active Jobs To Display
					</h1>
				</div>
			</Row>
		);
	}

	return (
		<div>
			<Row className="">
				<Col sm={4} xs={12} className="mb-2">
					<p>Approved Active Jobs to be displayed on Admin Job pages</p>
				</Col>
				<Col sm={8} xs={12}>
					<Row style={{ justifyContent: 'flex-end' }} className="mr-1 mb-2">
						{/* <p className="text-bold mb-0 pt-1">Filter/Sort By</p>
						<Col sm={3} xs={12} className="mb-2">
							<DropDown className="mt-0" onChange={handleFilterByChange}>
								<option value="select">Select</option>
								<option value="startTime">Start Time</option>
								<option value="endTime">End Time</option>
								<option value="featured">Featured</option>
								<option value="partnership">Partnership</option>
							</DropDown>
						</Col>
						{
							filterBy !== 'select'
								? <Col sm={3} xs={12} className="mb-2">
									<DropDown className="mt-0" onChange={handleFilterValueChange}>
										{filterBy === 'startTime' || filterBy === 'endTime'
											? <>
												<option value="ASC">ASCENDING</option>
												<option value="DESC">DESCENDING</option>
											</>
											: null}
										{filterBy === 'featured' || filterBy === 'partnership'
											? <>
												<option value>TRUE</option>
												<option value={false}>FALSE</option>
											</>
											: null}
									</DropDown>
								</Col>
								: null
						} */}
						<Col sm={4} xs={12} className="mb-2">
							<Input
								id="search-box"
								type="text"
								className="form-control"
								placeholder="Search By Email/Name/PhoneNumber"
								value={searchValue}
								onChange={(e) => {
									handleSearch(e);
									setSearchValue(e.target.value);
								}}
							/>
						</Col>
					</Row>
					{/* <Row style={{ justifyContent: 'flex-end' }}>
						<Col>
							<Button
								primary
								no_radius
								className="mb-0 mr-2"
								onClick={() => window.open('/event/editor/')}
							>
								<b>ADD NEW EVENT</b>
							</Button>
						</Col>
					</Row> */}
				</Col>
			</Row>
			{
				loading
					? <LoadingIcon />
					: <>
						{renderList()}
						{approvedActiveJobs && approvedActiveJobs.length > 0
							? <div>
								<Row className="mt-3 pr-4" style={{ justifyContent: 'flex-end' }}>
									<Pagination onChange={onPageChange} current={page} total={jobsCount} locale={locale} />
								</Row>
							</div>
							: null}
					</>
			}
			{rejectTheJob()}
			{viewBioOfTheJob()}
		</div>
	);
};

ApprovedActiveJobsList.propTypes = {
	status: T.number,
	history: T.object,
};
export default ApprovedActiveJobsList;
