import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	LOAD_ALL_BRANDS, CREATE_BRAND, UPDATE_BRAND, DELETE_BRAND,
} from './type';
import {
	brandsLoadedSuccessfully, brandsLoadingError, createBrandSuccess, createBrandFailure, updateBrandSuccess, updateBrandFailure, deleteBrandSuccess, deleteBrandFailure, loadAllBrands,
} from './actions';
import { BRAND_API_BASE_URL } from './constants';

export function* getAllBrands() {
	const url = `${BRAND_API_BASE_URL}/`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const allBrands = yield response.json();
			yield put(brandsLoadedSuccessfully(allBrands));
		}
	} catch (err) {
		yield put(brandsLoadingError(err));
	}
}

export function* createBrand(action) {
	const url = `${BRAND_API_BASE_URL}/`;
	const formData = action.brand;
	const options = {
		method: 'POST',
		body: formData,
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(createBrandSuccess(responseMessage));
				yield put(loadAllBrands());
			} else {
				yield put(createBrandFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(createBrandFailure(err));
	}
}

export function* updateBrand(action) {
	const url = `${BRAND_API_BASE_URL}/id/${action.id}`;
	const formData = action.updatedData;
	const options = {
		method: 'PUT',
		body: formData,
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(updateBrandSuccess(responseMessage));
				yield put(loadAllBrands());
			} else {
				yield put(updateBrandFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(updateBrandFailure(err));
	}
}

export function* deleteBrand(action) {
	const url = `${BRAND_API_BASE_URL}/id/${action.id}`;
	const options = {
		method: 'DELETE',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(deleteBrandSuccess(responseMessage));
				yield put(loadAllBrands());
			} else {
				yield put(deleteBrandFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(deleteBrandFailure(err));
	}
}

export default function* brandData() {
	yield takeLatest(LOAD_ALL_BRANDS, getAllBrands);
	yield takeLatest(CREATE_BRAND, createBrand);
	yield takeLatest(UPDATE_BRAND, updateBrand);
	yield takeLatest(DELETE_BRAND, deleteBrand);
}
