import {
	call, put, takeLatest, select,
} from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import { redirectTo } from '../../utils/accessRedirection';
import {
	makeSelectLocation, makeSelectUserBrands, makeSelectCurrentBrand, makeSelectAppUser,
} from '../App/selectors';
import { setCurrentBrand } from '../App/actions';
import {
	FETCH_EDITOR_DATA,
	CREATE_STORY,
	EDITOR_RUN_SEO_ANALYSIS,
	UPDATE_STORY,
	EDITOR_RUN_PLAGIARISM_CHECK,
	SET_EDITOR_DOC_CONVERSION_DATA,
	FETCH_EDITOR_STORY_REVISIONS,
	ADD_YOUTUBE_VIDEO,
	AUTO_SAVE_STORY,
	FETCH_WEBINAR_QUESTIONS,
	CHECK_FOR_NEW_QUESTIONS,
	GENERATE_WEBINAR_REPORT,
	DOWNLOAD_WEBINAR_REPORT,
	FETCH_LIVE_STREAM_TYPES,
} from './type';
import {
	fetchEditorDataSuccess,
	createStorySuccess,
	createStoryFailure,
	fetchEditorDataFailure,
	editorRunSeoAnalysisSuccess,
	editorRunSeoAnalysisError,
	updateStorySuccess,
	updateStoryFailure,
	runEditorPlagiarismFailure,
	runEditorPlagiarismSuccess,
	updateEditorDocConversion,
	updateEditorDocConvertedDelta,
	fetchEditorStoryRevisionsFailure,
	fetchEditorStoryRevisionsSuccess,
	fetchEditorStoryRevisions,
	addYoutubeVideoFailure,
	addYoutubeVideoSuccess,
	autoSaveStorySuccess,
	autoSaveStoryFailure,
	clearAutoSaveMessage,
	editorSetUserRole,
	fetchQuestionsSuccess,
	checkForQuestionsSuccess,
	downloadWebinarReportComplete,
	generateWebinarReportComplete,
	getLiveStreamTypesSuccess,
	getLiveStreamTypesFailure,
} from './actions';
import {
	EDITOR_API_BASE_URL,
	AUTHOR_META_BASE_URL,
	STORY_METADATA_BASE_URL,
	SEO_ANALYTICS_BASE_URL,
	MS_DOC_CONVERSION_API,
	GOOGLE_DOC_CONVERSION_API,
	STATUS,
	WEBINAR_API_BASE_URL,
} from './constants';
import { brandIdToBrand } from '../../utils/brandUtils';
import { generateStoryUrlPrefix } from '../../utils/storyUrlGeneration';
import { getEditorFormData, checkIsUserEditor, checkIsUserStoryAuthor } from './functions';
import {
	XMLHttpsUpload, checkNested, checkType, convertDocDeltaToBlotDelta, startUrlDownload,
} from '../../utils/utility_function';
import { selectEditorDocConversion, selectEditorFormData } from './selectors';
import { CURATED_LIST_API_BASE_URL } from '../CompanyEditor/constants';

let CURRENT_LOCATION;

function delay() {
	return new Promise((resolve) => {
		setTimeout(() => resolve(), 3000);
	});
}

// create new story
function* createStory(action) {
	const url = `${EDITOR_API_BASE_URL}/create/${action.brand}/${action.storyType}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(createStorySuccess(responseMessage.data.StoryVersion));
				yield put(replace(`/editor/${action.storyType}/${action.brand}/${responseMessage.data.StoryVersion.storyId}`));
			} else {
				yield put(createStoryFailure(responseMessage.error));
			}
		}
	} catch (err) {
		yield put(createStoryFailure(err));
	}
}

function* updateStory(action) {
	const url = `${EDITOR_API_BASE_URL}/update/${action.storyId}`;
	const options = {
		method: 'POST',
		body: action.story,
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				const params = {
					brand: action.story.get('brand'),
					storyId: action.storyId,
					storyType: action.story.get('type').toLowerCase(),
				};
				yield getEditorData(params);
				yield put(updateStorySuccess(responseMessage, action.status));
			} else {
				yield put(updateStoryFailure(responseMessage, action.status));
			}
		}
	} catch (err) {
		yield put(updateStoryFailure(err, action.status));
	}
}

function* autosaveStory(action) {
	const url = `${EDITOR_API_BASE_URL}/update/${action.storyId}`;
	const options = {
		method: 'POST',
		body: action.story,
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				const params = {
					brand: action.story.get('brand'),
					storyId: action.storyId,
					storyType: action.story.get('type').toLowerCase(),
				};
				yield getEditorData(params);
				yield put(autoSaveStorySuccess(responseMessage));
			} else {
				yield put(autoSaveStoryFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(autoSaveStoryFailure(err));
	}
	yield call(delay);
	yield put(clearAutoSaveMessage());
}

function* checkPlagiarsim(action) {
	const url = `/api/v2/rw/plagiarismcheck/${action.storyId}`;
	const options = {
		method: 'POST',
		credentials: 'include',
		body: action.storyContent,
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else if (response.status === 505) {
			const copyScapeError = yield response.json();
			yield put(runEditorPlagiarismFailure(copyScapeError));
		} else {
			const plagiarismData = yield response.json();
			yield put(runEditorPlagiarismSuccess(plagiarismData));
		}
	} catch (err) {
		yield put(runEditorPlagiarismFailure(err));
	}
}

function* getPlagiarismRecord(storyId) {
	try {
		const options = {
			credentials: 'include',
		};
		const response = yield call(fetch, `/api/v2/rw/plagiarismcheck/${storyId}`, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const responseJson = yield response.json();
			return responseJson;
		}
	} catch (err) {
		throw new Error(err);
	}
}

// get latest story version as story by story id
function* getStoryById(action) {
	const url = `${EDITOR_API_BASE_URL}/id/${action.brand}/${action.storyId}/${action.storyType}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500, 404].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const story = yield response.json();
			return story;
		}
	} catch (err) {
		throw new Error(err);
	}
}

// get story metadata by story id
function* getStoryMetadata(action) {
	const url = `${STORY_METADATA_BASE_URL}/storyid/${action.storyId}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const metadata = yield response.json();
			return metadata;
		}
	} catch (err) {
		throw new Error(err);
	}
}

function* runSeoAnalysis(action) {
	const url = `${SEO_ANALYTICS_BASE_URL}/analyse/${action.storyId}`;
	const options = {
		method: 'POST',
		credentials: 'include',
		body: action.content,
	};

	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const seoAnalysisData = yield response.json();
			yield put(editorRunSeoAnalysisSuccess(seoAnalysisData));
		}
	} catch (err) {
		yield put(editorRunSeoAnalysisError(err));
	}
}

function* getEditorData(action) {
	try {
		// fetch story
		const story = yield getStoryById(action);
		// fetch story metadata
		const metadata = yield getStoryMetadata(action);
		// fetch plagiarism record
		let plagiarismData = yield getPlagiarismRecord(action.storyId);
		if (plagiarismData && plagiarismData.storyData) {
			plagiarismData = plagiarismData.storyData;
		}
		const curatedLists = yield fetchCuratedLists(story.type);
		const userBrands = yield select(makeSelectUserBrands());
		const currentBrand = brandIdToBrand(story.brand, userBrands);
		const paywallSubtypes = yield fetchPaywallSubtypes(story.brand);
		// webinar-related
		const snippets = yield fetchStorySnippets({ storyId: action.storyId });
		let webinar; let registrationForm; let
			liveStreamTypes;
		if (story.type === 'WEBINAR') {
			webinar = yield getWebinarBasicInfo({ webinarId: story.webinar && story.webinar.id ? story.webinar.id : null });
			registrationForm = yield getRegistrationFormElements({ webinarId: story.webinar && story.webinar.id });
			if (story.webinar && story.webinar.id && !(new Date(webinar.endTime) <= new Date())) {
				yield fetchStreamOptions({ id: story.webinar.id, startTime: webinar.startTime, endTime: webinar.endTime });
			}
		}
		yield put(setCurrentBrand(currentBrand));
		const user = yield select(makeSelectAppUser());

		// if there is no author
		if (!metadata.author) {
			const authorMeta = yield getAuthorMetaByUserId(user.id);
			metadata.author = authorMeta;
		}

		yield put(editorSetUserRole(checkIsUserEditor(user, currentBrand), checkIsUserStoryAuthor(user, story.createdBy)));

		const editorFormData = getEditorFormData(story, metadata, currentBrand);

		// if there is no story url prefix in metadata, generate one
		if (!metadata.storyUrlPrefix) {
			metadata.storyUrlPrefix = generateStoryUrlPrefix(currentBrand);
		}

		// if there is no story url slug in metadata, set one
		if (!metadata.storyUrlSlug) {
			if (story.slug && story.slug.length > 0) {
				metadata.storyUrlSlug = story.slug;
			} else {
				metadata.storyUrlSlug = story.storyId;
			}
		}

		if (!metadata.storyUrl && metadata.storyUrlSlug && metadata.storyUrlPrefix) {
			metadata.storyUrl = metadata.storyUrlPrefix + metadata.storyUrlSlug;
		}

		yield put(fetchEditorDataSuccess({
			story, editorFormData, metadata, plagiarismData, curatedLists, snippets, webinar, registrationForm, liveStreamTypes, paywallSubtypes, currentBrand,
		}));

		// fetch revisions only if story is of type RICH
		if (story.type === 'RICH' || story.type === 'GLOSSARY') {
			yield put(fetchEditorStoryRevisions(action.storyId));
		}
	} catch (err) {
		console.log('Error fetching editor data', err);
		yield put(fetchEditorDataFailure(err));
	}
}

function* getAuthorMetaByUserId(userId) {
	try {
		const url = `${AUTHOR_META_BASE_URL}/userid/${userId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const author = yield response.json();
			const authorMeta = {
				label: author.name,
				value: author.id,
				slug: author.username,
				image_url: author.image_url,
				employee: author.employee,
				username: author.username,
				primary_email: author.primary_email,
			};
			return [authorMeta];
		}
	} catch (err) {
		throw new Error(err);
	}
}

function* uploadDocData(action) {
	try {
		const newState = action.value;
		yield put(updateEditorDocConversion(newState));
		const docConversion = yield select(selectEditorDocConversion());
		const selectedBrand = yield select(makeSelectCurrentBrand());
		const editorFormData = yield select(selectEditorFormData());
		if (newState) {
			if (('microsoft' in newState) && newState.microsoft.ready) {
				const { file } = newState.microsoft;

				if (checkNested(file, 'name') && checkType(file.name, 'string')) {
					if (!(file.name.endsWith('docx') || file.name.endsWith('doc'))) {
						throw new Error('Invalid file type, expected MS/Google document');
					}
				}

				const data = {
					docFile: file,
					storyId: editorFormData.storyId,
					brandId: selectedBrand.id,
				};
				yield put(updateEditorDocConversion({ status: { type: STATUS.UPLOADING, value: 0 } }));

				const response = yield XMLHttpsUpload(MS_DOC_CONVERSION_API, data);

				if (response.type === STATUS.SUCCESS) {
					const { message } = response;
					if (checkNested(response, 'data', 'data', 'delta')) {
						const { delta } = response.data.data;
						const html = convertDocDeltaToBlotDelta(delta);
						yield put(updateEditorDocConvertedDelta({ html, delta }));
					}
					yield put(updateEditorDocConversion({ status: { type: STATUS.SUCCESS, value: message } }));
				} else if (response.type === STATUS.FAIL) {
					yield put(updateEditorDocConversion({ status: { type: STATUS.FAIL, value: response.message } }));
				} else if (response.type === STATUS.ERROR) {
					yield put(updateEditorDocConversion({ status: { type: STATUS.ERROR, value: response.message } }));
				}
			} else if (('google' in newState) && newState.google.ready) {
				const { authToken } = docConversion.google;
				const { mimeType } = newState.google;
				const { fileId } = newState.google;
				const options = {
					method: 'POST',
					credentials: 'include',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						authToken,
						mimeType,
						fileId,
						storyId: editorFormData.storyId,
						brandId: selectedBrand.id,
					}),
				};

				yield put(updateEditorDocConversion({ status: { type: STATUS.UPLOADING, value: 0 } }));

				const response = yield call(fetch, GOOGLE_DOC_CONVERSION_API, options);
				if (response.status === 200) {
					const responseJson = yield response.json();
					const { message } = responseJson;
					if (checkNested(responseJson, 'data', 'delta')) {
						const { delta } = responseJson.data;
						const html = convertDocDeltaToBlotDelta(delta);
						yield put(updateEditorDocConvertedDelta({ html, delta }));
					}
					yield put(updateEditorDocConversion({ status: { type: STATUS.SUCCESS, value: message } }));
				} else {
					const responseJson = yield response.json();
					const { message } = responseJson.error;
					yield put(updateEditorDocConversion({ status: { type: STATUS.FAIL, value: message } }));
				}
			}
		}
	} catch (err) {
		yield put(updateEditorDocConversion({ status: { type: STATUS.ERROR, value: err.message } }));
	}
}

function* getStoryRevisions(action) {
	try {
		const url = `/api/v2/rw/storyversions/revisions/${action.storyId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
			yield put(fetchEditorStoryRevisionsFailure('Failed to fetch story revisions'));
		} else {
			const revisions = yield response.json();
			yield put(fetchEditorStoryRevisionsSuccess(revisions));
		}
	} catch (err) {
		yield put(fetchEditorStoryRevisionsFailure(err));
	}
}

function* addYoutubeVideo(action) {
	try {
		const url = '/api/v2/rw/assets/create/youtube';
		const options = {
			method: 'POST',
			credentials: 'include',
			body: action.youtubeData,
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
			yield put(addYoutubeVideoFailure('Failed to add youtube video'));
		} else {
			const asset = yield response.json();
			yield put(addYoutubeVideoSuccess(asset));
		}
	} catch (err) {
		yield put(addYoutubeVideoFailure(err));
	}
}

function* fetchCuratedLists(type) {
	let customList = ['SECTOR'];

	if (type !== 'RICH') {
		customList = customList.concat(['ARTICLE-TOPIC', 'YSTV-CATEGORY']);
		if (type === 'WEBINAR') {
			customList = customList.concat(['WEBINAR-TAG-TYPE-A', 'WEBINAR-TAG-TYPE-B']);
		}
	} else {
		customList = customList.concat(['RICH-ARTICLE-TOPIC']);
	}

	const url = `${CURATED_LIST_API_BASE_URL}/editor`;
	const options = {
		headers: { 'Content-Type': 'application/json' },
		method: 'POST',
		credentials: 'include',
		body: JSON.stringify({ customList }),
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const list = yield response.json();
			return list;
		}
	} catch (err) {
		yield put(createStoryFailure(err));
	}
}

export function* fetchStorySnippets(action) {
	try {
		const url = `/api/v2/rw/video-snippets/editor/id/${action.storyId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const data = yield response.json();
			return data;
		}
	} catch (err) {
		console.log('Error', err);
		yield put(fetchEditorDataFailure(err));
	}
}

export function* getWebinarBasicInfo(action) {
	if (!action.webinarId) {
		return null;
	}
	try {
		const url = `${WEBINAR_API_BASE_URL}/basic-info/${action.webinarId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const basicInfo = yield response.json();
			return basicInfo;
		}
	} catch (err) {
		console.log('Error fetching webinar data', err);
		yield put(fetchEditorDataFailure(err));
	}
}

export function* getRegistrationFormElements(action) {
	try {
		const url = `/api/v2/rw/registration-form-elements/editor?${action.webinarId ? `id=${action.webinarId}` : ''}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const data = yield response.json();
			return data.elements;
		}
	} catch (err) {
		console.log('Error fetching registration form elements', err);
		yield put(fetchEditorDataFailure(err));
	}
}

export function* getWebinarQuestions(action) {
	try {
		const url = `/api/v2/rw/webinar-questions/editor/all-questions?webinarId=${action.webinarId}&limit=${action.limit}&offset=${action.offset}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const data = yield response.json();
			yield put(fetchQuestionsSuccess({
				questions: data.questions, offset: action.offset, reset: action.reset, questionsCount: data.questionsCount,
			}));
		}
	} catch (err) {
		console.log('Error fetching webinar data', err);
		yield put(fetchEditorDataFailure(err));
	}
}

export function* checkForQuestions(action) {
	try {
		const url = '/api/v2/rw/webinar-questions/editor/check';
		const options = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			credentials: 'include',
			body: JSON.stringify({ webinarId: action.webinarId, latestDate: action.latestDate }),
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const available = yield response.json();
			if (available && available.present) yield put(checkForQuestionsSuccess(available.count));
		}
	} catch (err) {
		console.log('Error fetching webinar data', err);
		yield put(fetchEditorDataFailure(err));
	}
}

function* fetchStreamOptions(action) {
	const url = `/api/v2/rw/curatedset/editor?brand=2&key=jwplayer_live_channels&id=${action.id}&startTime=${action.startTime}&endTime=${action.endTime}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 200) {
			const streamTypes = yield response.json();
			yield put(getLiveStreamTypesSuccess(streamTypes));
		} else {
			yield put(getLiveStreamTypesFailure(response));
		}
	} catch (err) {
		yield put(getLiveStreamTypesFailure(err));
	}
}

// generate report
function* generateWebinarReport(action) {
	const url = '/api/v2/rw/webinar/report/generate';
	const options = {
		method: 'POST',
		credentials: 'include',
		body: JSON.stringify({ storyId: action.storyId, webinarId: action.webinarId }),
		headers: { 'Content-Type': 'application/json' },
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const res = yield response.json();
			if (res.success) {
				if (res.noEnrollments) {
					yield put(generateWebinarReportComplete('No users enrolled yet!', null, false));
				} else if (!res.report) {
					yield put(generateWebinarReportComplete('Something went wrong, try again later', null, true));
				} else {
					startUrlDownload(res.report.url, true);
					yield put(generateWebinarReportComplete(res.message, res.report, false));
				}
			} else {
				yield put(generateWebinarReportComplete(res.message, null, true));
			}
		}
	} catch (err) {
		yield put(generateWebinarReportComplete('Something went wrong, try again later!', null, true));
		throw new Error(err);
	}
}

// download report
function* downloadWebinarReport(action) {
	const url = '/api/v2/rw/webinar/report/download';
	const options = {
		method: 'POST',
		credentials: 'include',
		body: JSON.stringify({ fileName: action.fileName, webinarId: action.webinarId }),
		headers: { 'Content-Type': 'application/json' },
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const res = yield response.json();
			if (res.success && res.report) {
				startUrlDownload(res.report.url, true);
				yield put(downloadWebinarReportComplete(res.message, res.report, false));
			} else {
				yield put(downloadWebinarReportComplete(res.message, null, true));
			}
		}
	} catch (err) {
		yield put(downloadWebinarReportComplete('Something went wrong, try again later', null, true));
		throw new Error(err);
	}
}

function* fetchPaywallSubtypes(brand) {
	const url = `/api/v2/rw/subscription-packages${brand ? `?brand=${brand}` : ''}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const list = yield response.json();
			return list;
		}
	} catch (err) {
		console.log('Errror fetching subtypes', err);
		return [];
	}
}

export default function* editorData() {
	const location = yield select(makeSelectLocation());
	CURRENT_LOCATION = location.pathname;
	yield takeLatest(UPDATE_STORY, updateStory);
	yield takeLatest(EDITOR_RUN_SEO_ANALYSIS, runSeoAnalysis);
	yield takeLatest(CREATE_STORY, createStory);
	yield takeLatest(FETCH_EDITOR_DATA, getEditorData);
	yield takeLatest(EDITOR_RUN_PLAGIARISM_CHECK, checkPlagiarsim);
	yield takeLatest(SET_EDITOR_DOC_CONVERSION_DATA, uploadDocData);
	yield takeLatest(FETCH_EDITOR_STORY_REVISIONS, getStoryRevisions);
	yield takeLatest(ADD_YOUTUBE_VIDEO, addYoutubeVideo);
	yield takeLatest(AUTO_SAVE_STORY, autosaveStory);
	yield takeLatest(FETCH_WEBINAR_QUESTIONS, getWebinarQuestions);
	yield takeLatest(CHECK_FOR_NEW_QUESTIONS, checkForQuestions);
	yield takeLatest(GENERATE_WEBINAR_REPORT, generateWebinarReport);
	yield takeLatest(DOWNLOAD_WEBINAR_REPORT, downloadWebinarReport);
	yield takeLatest(FETCH_LIVE_STREAM_TYPES, fetchStreamOptions);
}
