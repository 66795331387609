import React from 'react';
import loadable from '@loadable/component';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';
import { WrapperContainer } from '../../components/Styles';

export default loadable(
	() => import(/* webpackChunkName: "brand-spotlight-manager" */ './index'),
	{
		fallback: (() => <WrapperContainer>
			<LoadingIcon />
		</WrapperContainer>)(),
	},
);
