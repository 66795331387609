import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Selected = styled.div`
		font-weight: 600;
		padding: 10px 20px;
		box-shadow: inset 0px -4px 0px 0px #e5002d;
		display: inline-block;
		cursor: pointer;
		text-align: center;
`;

const UnSelected = styled.div`
		color: black;
		padding: 10px 20px;
		display: inline-block;
		cursor: pointer;
		text-align: center;
`;

const Bar = styled.div`
		width: 100%;
		box-shadow: ${(props) => (props.noShadow ? 'none' : '1px 2px 3px 0px #DDDDDD')};
`;

const CircularWrapper = styled.span`
		border-radius: 25px;
		color: #fff;
		background-color: green;
		padding: 0.20rem 0.5rem;
		font-size: 0.9rem;
		margin-left: 0.15rem;
`;

const StatusBlock = styled.div`
		display: inline-block;
		float: right;
		margin-top: 10px;
		padding-right: 20px;
`;

const WrapBar = (elements, children, noShadow = false) => <Bar noShadow={noShadow}>
	{elements}
	{children ? <StatusBlock>{children}</StatusBlock> : null}
</Bar>;

const Tabs = (props) => {
	const {
		tabs, value, onSelect, updatesCount, children, noShadow, disabled,
	} = props;

	function renderUpdateCount() {
		if (updatesCount > 0) {
			return (
				<CircularWrapper>
					{updatesCount}
				</CircularWrapper>
			);
		}
		return null;
	}

	return WrapBar(tabs.map((ele) => {
		if (ele && value && ele.value.toLowerCase() === value.toLowerCase()) {
			return <Selected
				key={ele.value}
				onClick={() => { if (!disabled) { onSelect(ele.value); } }}
			>
				{ele.name} {ele.value === 'updates' ? renderUpdateCount() : null}
			</Selected>;
		}
		return <UnSelected
			key={ele.value}
			onClick={() => { if (!disabled) { onSelect(ele.value); } }}
		>
			{ele.name} {ele.value === 'updates' ? renderUpdateCount() : null}
		</UnSelected>;
	}), children, noShadow);
};

Tabs.propTypes = {
	tabs: PropTypes.array,
	value: PropTypes.string,
	onSelect: PropTypes.func,
	updatesCount: PropTypes.number,
	children: PropTypes.object,
	noShadow: PropTypes.bool,
};

Tabs.defaultProps = {
	noShadow: false,
};

export default Tabs;
