export const UPDATE_DISPLAY_MESSAGE = 'UPDATE_DISPLAY_MESSAGE';

export const LOAD_PROMOTIONS_START = 'LOAD_PROMOTIONS_START';
export const LOAD_PROMOTIONS_SUCCESS = 'LOAD_PROMOTIONS_SUCCESS';
export const LOAD_PROMOTIONS_ERROR = 'LOAD_PROMOTIONS_ERROR';

export const LOAD_PROMOTIONS_UPDATE_SUCCESS = 'LOAD_PROMOTIONS_UPDATE_SUCCESS';

export const LOAD_CHATBOTS = 'LOAD_CHATBOTS';
export const LOAD_CHATBOTS_ERROR = 'LOAD_CHATBOTS_ERROR';
export const LOAD_CHATBOTS_SUCCESS = 'LOAD_CHATBOTS_SUCCESS';
