import { Map } from 'immutable';
import {
	FETCH_CONNECT_REQUESTS_BY_STATUS, FETCH_CONNECT_REQUESTS_BY_STATUS_SUCCESS, FETCH_CONNECT_REQUESTS_BY_STATUS_FAILURE, ON_TAB_CHANGE, SET_PAGE, FILTER_CONNECT_REQUESTS_BY_COMPANY, FILTER_CONNECT_REQUESTS_BY_COMPANY_SUCCESS,
} from './type';

const initialState = Map({
	loading: false,
	connectRequests: [],
	error: null,
	selectedTab: 'PENDING',
	page: 1,
});

function companyConnectReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_CONNECT_REQUESTS_BY_STATUS:
		return state
			.set('loading', true);
	case FETCH_CONNECT_REQUESTS_BY_STATUS_SUCCESS: {
		return state
			.set('loading', false)
			.set('connectRequests', action.connectRequests);
	}
	case FETCH_CONNECT_REQUESTS_BY_STATUS_FAILURE:
		return state
			.set('loading', false)
			.set('error', action.error);
	case ON_TAB_CHANGE:
		return state
			.set('selectedTab', action.tab);
	case SET_PAGE:
		return state
			.set('page', action.page);
	case FILTER_CONNECT_REQUESTS_BY_COMPANY:
		return state
			.set('loading', true)
			.set('page', 1);
	case FILTER_CONNECT_REQUESTS_BY_COMPANY_SUCCESS:
		return state
			.set('loading', false)
			.set('connectRequests', action.connectRequests);
	default:
		return state;
	}
}

export default companyConnectReducer;
