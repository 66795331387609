import React from 'react';
import AsyncSelect from 'react-select/async';
import T from 'prop-types';
import { TitleWithTooltip, TopicCard } from './index';

const SeriesCard = (props) => {
	const { series, onSeriesChange } = props;

	async function getSeriesOptions(inputValue, callback) {
		try {
			const url = `/api/v2/rw/series/title/${inputValue}`;
			const options = {
				method: 'GET',
				credentials: 'include',
			};
			const apiResponse = await fetch(url, options);
			const selectOptions = await apiResponse.json();
			callback(selectOptions);
		} catch (err) {
			console.log('Series options error: ', err);
		}
	}

	return (
		<div
			style={{ width: '100%', padding: '0 0.5rem' }}
		>
			<TitleWithTooltip
				title="Series"
				hint="This story will be part of the series that you select"
			/>
			<AsyncSelect
				style={{ width: '100%' }}
				className="basic-single"
				classNamePrefix="select"
				isClearable
				isSearchable
				loadOptions={getSeriesOptions}
				placeholder="Search series by name..."
				onChange={(value) => {
					onSeriesChange(value);
				}}
				value={series}
			/>
			{
				series
					? <div className="mt-4">
						<TopicCard
							thumbnail={series ? series.thumbnail : null}
							name={series ? series.title : null}
						/>
					</div>
					: null
			}
		</div>
	);
};

SeriesCard.propTypes = {
	series: T.object,
	onSeriesChange: T.func,
};

export default SeriesCard;
