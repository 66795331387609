import {
	FETCH_FEED_CONTENTS_BY_PAGE, FETCH_FEED_CONTENTS_BY_PAGE_SUCCESS, FETCH_FEED_CONTENTS_BY_PAGE_ERROR, SET_RSS_FEED_CONTENT_PAGE, FILTER_WATCHLIST_STORIES, FILTER_WATCHLIST_STORIES_SUCCESS, FILTER_WATCHLIST_STORIES_ERROR, REMOVE_WATCHLIST_STORIES_FILTER, SET_WATCHLIST_FILTER_TYPE, SET_WATCHLIST_FILTER_VALUE, FETCH_ALL_FEED_SOURCE, FETCH_ALL_FEED_SOURCE_SUCCESS, FETCH_ALL_FEED_SOURCE_ERROR, ON_FEED_SOURCE_SELECT,
} from './type';

export function fetchAllFeedSource() {
	return {
		type: FETCH_ALL_FEED_SOURCE,
	};
}

export function fetchAllFeedSourceSuccess(feedSource) {
	return {
		type: FETCH_ALL_FEED_SOURCE_SUCCESS,
		feedSource,
	};
}

export function fetchAllFeedSourceError(error) {
	return {
		type: FETCH_ALL_FEED_SOURCE_ERROR,
		error,
	};
}

export function fetchFeedContentsByPage(feedId, page) {
	return {
		type: FETCH_FEED_CONTENTS_BY_PAGE,
		feedId,
		page,
	};
}

export function fetchFeedContentsByPageSuccess(feedContents) {
	return {
		type: FETCH_FEED_CONTENTS_BY_PAGE_SUCCESS,
		feedContents,
	};
}

export function fetchFeedContentsByPageError(error) {
	return {
		type: FETCH_FEED_CONTENTS_BY_PAGE_ERROR,
		error,
	};
}

export function setRssFeedContentPage(page) {
	return {
		type: SET_RSS_FEED_CONTENT_PAGE,
		page,
	};
}

export function filterWatchlistStories(searchType, searchValue, feedId, page) {
	return {
		type: FILTER_WATCHLIST_STORIES,
		searchType,
		searchValue,
		feedId,
		page,
	};
}

export function filterWatchlistStoriesSuccess(filteredStories) {
	return {
		type: FILTER_WATCHLIST_STORIES_SUCCESS,
		filteredStories,
	};
}

export function filterWatchlistStoriesError(error) {
	return {
		type: FILTER_WATCHLIST_STORIES_ERROR,
		error,
	};
}

export function removeWatchlistStoriesFilter() {
	return {
		type: REMOVE_WATCHLIST_STORIES_FILTER,
	};
}

export function setWatchlistFilterType(filterType) {
	return {
		type: SET_WATCHLIST_FILTER_TYPE,
		filterType,
	};
}

export function setWatchlistFilterValue(filterValue) {
	return {
		type: SET_WATCHLIST_FILTER_VALUE,
		filterValue,
	};
}

export function onFeedSourceSelect(selectedFeed) {
	return {
		type: ON_FEED_SOURCE_SELECT,
		selectedFeed,
	};
}
