import { Map } from 'immutable';
import {
	FETCH_CLAIMS_BY_STATUS, FETCH_CLAIMS_BY_STATUS_SUCCESS, FETCH_CLAIMS_BY_STATUS_FAILURE, ON_TAB_CHANGE, SET_PAGE, FILTER_CLAIMS_BY_COMPANY, FILTER_CLAIMS_BY_COMPANY_SUCCESS,
} from './type';

const initialState = Map({
	loading: false,
	profileClaims: [],
	error: null,
	selectedTab: 'PENDING',
	page: 1,
});

function profileClaimDeskReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_CLAIMS_BY_STATUS:
		return state
			.set('loading', true);
	case FETCH_CLAIMS_BY_STATUS_SUCCESS: {
		return state
			.set('loading', false)
			.set('profileClaims', action.profileClaims);
	}
	case FETCH_CLAIMS_BY_STATUS_FAILURE:
		return state
			.set('loading', false)
			.set('error', action.error);
	case ON_TAB_CHANGE:
		return state
			.set('selectedTab', action.tab);
	case SET_PAGE:
		return state
			.set('page', action.page);
	case FILTER_CLAIMS_BY_COMPANY:
		return state
			.set('loading', true)
			.set('page', 1);
	case FILTER_CLAIMS_BY_COMPANY_SUCCESS:
		return state
			.set('loading', false)
			.set('profileClaims', action.profileClaims);
	default:
		return state;
	}
}

export default profileClaimDeskReducer;
