Object.defineProperty(exports, '__esModule', {
	value: true,
});
exports.default = {
	// Options.jsx
	items_per_page: '/ page',
	jump_to: 'Goto',
	jump_to_confirm: 'confirm',
	page: '',

	// Pagination.jsx
	prev_page: 'Previous Page',
	next_page: 'Next Page',
	prev_5: 'Previous 5 Pages',
	next_5: 'Next 5 Pages',
	prev_3: 'Previous 3 Pages',
	next_3: 'Next 3 Pages',
};
module.exports = exports.default;
