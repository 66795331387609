/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, {
    useEffect, useReducer,
} from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import styled from 'styled-components';
import {
    WrapperContainer, Input, Button,
} from '../../components/Styles';
import {
    AsyncMultiSelect, AsyncSingleSelect, ImageHolder, TitleWithTooltip,
} from '../../components/FormComponents';
import ToastMessage from '../../components/ToastMessage';
import { getDropdownOptions, validateImageFormat } from '../../utils/common';
import {
    WEBSTORY_API_BASE_URL, WEBSTORY_CREATE_LOAD_MESSAGE, WEBSTORY_CREATE_SUCCESS_MESSAGE, WEBSTORY_ERROR_MESSAGE,
} from './constants';

const ThumbnailCol = styled(Col)`
		padding: 0px;
		background-color: ${(props) => (props.selected ? 'white' : 'none')};
		border: ${(props) => (props.border ? '0.5px solid #e5002d' : '')};
		border-bottom: 0;
		border-radius:${(props) => (props.selected ? '10px 10px 0 0' : '0')}
`;

const ImageWrapper = styled.div`
		padding-left: 2rem;
`;

const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const UPDATE_ERRORS = 'UPDATE_ERRORS';
const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
const CLEAR_DATA = 'CLEAR_DATA';

const initialState = {
    formData: {
        webstoryURL: null,
        title: null,
        category: null,
        thumbnail: {
            portrait: null,
            landscape: null,
        },
        slug: null,
        status: null,
        brand: null,
    },
};

function updateFormData(changedData) {
    return {
        type: UPDATE_FORM_DATA,
        changedData,
    };
}

function updateErrors(errors) {
    return {
        type: UPDATE_ERRORS,
        errors,
    };
}

function setFormMessage(message) {
    return {
        type: SET_FORM_MESSAGE,
        message,
    };
}

// function clearData() {
//     return {
//         type: CLEAR_DATA,
//     };
// }

function reducer(state, action) {
    switch (action.type) {
        case UPDATE_FORM_DATA: {
            const formData = { ...state.formData };
            const { changedData } = action;
            if (['portrait', 'landscape'].includes(changedData.name)) {
                if (!formData.thumbnail) formData.thumbnail = {};
                formData.thumbnail[changedData.name] = changedData.value;
            } else {
                formData[changedData.name] = changedData.value;
            }
            return { ...state, formData };
        }

        case UPDATE_ERRORS:
            return { ...state, errors: action.errors };

        case SET_FORM_MESSAGE:
            return { ...state, formMessage: action.message };

        case CLEAR_DATA: {
            const newState = {
                ...initialState,
                formData: {
                    ...initialState.formData,
                    thumbnail: {
                        portrait: null,
                        landscape: null,
                    },
                },
            };
            return newState;
        }

        default:
            return state;
    }
}

const WriteWebstory = (props) => {
    const { pageTitle, user } = props;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        formData, formMessage,
    } = state;

    useEffect(
        () => {
            pageTitle('writeWebstory');
        }, [],
    );

    useEffect(() => {
        getErrors();
    }, [formData]);

    // useEffect(() => {
    //     createWebstory();
    // }, []);

    // function onInputChange(e) {
    //     const changedData = {
    //         name: e.target.name,
    //         value: e.target.value,
    //     };
    //     return dispatch(updateFormData(changedData));
    // }

    function validateForm(data) {
        return {
            webstoryURL: !data.webstoryURL || data.webstoryURL,
            title: !data.title || data.title,
            slug: !data.slug || data.slug,
        };
    }

    function getErrors() {
        const validationErrors = validateForm(formData);
        const errors = Object.keys(validationErrors).reduce((acc, curr) => {
            if (validationErrors[curr]) {
                acc[curr] = true;
            } else {
                acc[curr] = false;
            }
            return acc;
        }, {});
        dispatch(updateErrors(errors));
    }

    async function onFormSubmit(e) {
        e.preventDefault();
        const validation = validateForm(formData);
        const validated = !Object.keys(validation).some((i) => validation[i]);
        if (validated) {
            // disableButton(true);
        } else {
            // dispatch(setAllElementsBlurred());
        }
        createWebstory();
    }

    async function createWebstory() {
        try {
            dispatch(setFormMessage(WEBSTORY_CREATE_LOAD_MESSAGE));
            const payload = getRequestBody();
            const URL = WEBSTORY_API_BASE_URL;
            const options = {
                method: 'PUT',
                credentials: 'include',
                body: payload,
            };
            const response = await fetch(URL, options);
            const parsed = await response.json();
            console.log('parsed', parsed);
            if (parsed.success) {
                // const webstory = {
                // 	id: parsed.data.id,
                // 	value: parsed.data.id,
                // 	label: parsed.data.name,
                // 	bio: parsed.data.bio,
                // 	profilePicture: parsed.data.profilePicture,
                // 	username: parsed.data.username,
                // };
                dispatch(setFormMessage(WEBSTORY_CREATE_SUCCESS_MESSAGE));
            } else {
                dispatch(setFormMessage(WEBSTORY_ERROR_MESSAGE));
            }
        } catch (err) {
            console.log('err', err);
            dispatch(setFormMessage(WEBSTORY_ERROR_MESSAGE));
        }
    }

    function getRequestBody() {
        const payload = new FormData();
        payload.append('webstoryURL', formData.webstoryURL);
        payload.append('title', formData.title);
        payload.append('category', formData.category.value);
        payload.append('brand', formData.brand);
        payload.append('status', 'ACTIVE');
        if (formData.thumbnail.landscape && formData.thumbnail.landscape.file) {
            payload.append('thumbnail_landscape', formData.thumbnail.landscape.file);
            payload.append('thumbnail_landscape_meta', JSON.stringify({
                width: formData.thumbnail.landscape.width,
                height: formData.thumbnail.landscape.height,
            }));
        }
        if (formData.thumbnail.portrait && formData.thumbnail.portrait.file) {
            payload.append('thumbnail_portrait', formData.thumbnail.portrait.file);
            payload.append('thumbnail_portrait_meta', JSON.stringify({
                width: formData.thumbnail.portrait.width,
                height: formData.thumbnail.portrait.height,
            }));
        }
        return payload;
    }

    async function onUploadLandscape(e) {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        if (await validateImageFormat(file)) {
            reader.onloadend = () => {
                dispatch(updateFormData({
                    name: 'landscape',
                    value: {
                        url: reader.result,
                        file,
                    },
                }));
            };
            reader.readAsDataURL(file);
        }
    }

    function onUploadClickLandscape(e) {
        e.preventDefault();
        const thumbnailLandscape = document.getElementById('thumbnail_landscape');
        thumbnailLandscape.click();
    }

    function onImageLoadLandscape(e) {
        if (formData.thumbnail.landscape.file) {
            const data = { height: e.target.naturalHeight, width: e.target.naturalWidth };
            // if (!true) {
            //     document.getElementById('thumbnail_landscape').value = '';
            //     dispatch(updateFormData({
            //         name: 'landscape',
            //         value: {
            //             url: null,
            //             file: null,
            //         },
            //     }));
            // } else {
            dispatch(updateFormData({
                name: 'landscape',
                value: {
                    ...formData.thumbnail.landscape,
                    ...data,
                },
            }));
            // }
        }
    }

    async function onUploadPortrait(e) {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        if (await validateImageFormat(file)) {
            reader.onloadend = () => {
                dispatch(updateFormData({
                    name: 'portrait',
                    value: {
                        url: reader.result,
                        file,
                    },
                }));
            };
            reader.readAsDataURL(file);
        }
    }

    function onUploadClickPortrait(e) {
        e.preventDefault();
        const thumbnailLandscape = document.getElementById('thumbnail_portrait');
        thumbnailLandscape.click();
    }

    function onImageLoadPortrait(e) {
        if (formData.thumbnail.portrait.file) {
            const data = { height: e.target.naturalHeight, width: e.target.naturalWidth };
            // if (false) {
            //     document.getElementById('thumbnail_portrait').value = '';
            //     dispatch(updateFormData({
            //         name: 'portrait',
            //         value: {
            //             url: null,
            //             file: null,
            //         },
            //     }));
            // } else {
            dispatch(updateFormData({
                name: 'portrait',
                value: {
                    ...formData.thumbnail.portrait,
                    ...data,
                },
            }));
            // }
        }
    }

    function renderFormMessage() {
        if (formMessage) {
            return (
                <ToastMessage
                    toastData={formMessage}
                    unmount={() => dispatch(setFormMessage(null))}
                />
            );
        }
        return null;
    }
    console.log(user);

    return (
        <div>
            <WrapperContainer className="mt-5">
                <form onSubmit={onFormSubmit}>
                    {renderFormMessage()}
                    <Col>
                        <TitleWithTooltip
                            title="Title *"
                            hint="This is the Title of the webstory."
                        />
                        <Input
                            type="text"
                            className="form-control"
                            onChange={(e) => dispatch(updateFormData({
                                name: 'title',
                                value: e.target.value,
                            }))}
                            name="title"
                            style={{ minWidth: '60%' }}
                            autoComplete="off"
                            maxLength="200"
                        />
                        <div style={{ height: '20px' }} />
                        <TitleWithTooltip
                            title="Category *"
                            hint="Category"
                        />
                        <AsyncSingleSelect
                            placeholder="Search individual brand by name"
                            options={(value) => getDropdownOptions('brand-categories', value)}
                            currentValues={formData.category}
                            handleChange={(value) => dispatch(updateFormData({
                                name: 'category',
                                value,
                            }))}
                        />
                        <div style={{ height: '20px' }} />
                        <TitleWithTooltip
                            title="Webstory URL *"
                            hint="This is the URL where the webstory is published"
                        />
                        <Input
                            type="text"
                            className="form-control"
                            onChange={(e) => dispatch(updateFormData({
                                name: 'webstoryURL',
                                value: e.target.value,
                            }))}
                            name="webstoryURL"
                            style={{ minWidth: '60%' }}
                            autoComplete="off"
                            maxLength="200"
                        />
                        <div style={{ height: '20px' }} />
                        <TitleWithTooltip
                            title="Webstory Thumbnail *"
                            hint="Image for the webstory thumbnail"
                        />
                        <Row>
                            <Col sm={6}>
                                <ThumbnailCol
                                    sm={12}
                                    className="p-0 text-center"
                                >
                                    Landscape *
                                </ThumbnailCol>
                                <ImageWrapper>
                                    { }
                                    <Input
                                        id="thumbnail_landscape"
                                        type="file"
                                        style={{ display: 'none' }}
                                        name="landscape"
                                        onChange={onUploadLandscape}
                                        accept="image/png, image/jpeg"
                                    />
                                    <ImageHolder
                                        showImageUploader={onUploadClickLandscape}
                                        imageUrl={formData && formData.thumbnail.landscape ? formData.thumbnail.landscape.url : null}
                                        onImageLoad={onImageLoadLandscape}
                                    />
                                </ImageWrapper>
                            </Col>
                            <Col sm>
                                <ThumbnailCol
                                    sm={12}
                                    className="p-0 text-center"
                                >
                                    Portrait *
                                </ThumbnailCol>
                                <ImageWrapper>
                                    { }
                                    <Input
                                        id="thumbnail_portrait"
                                        type="file"
                                        style={{ display: 'none' }}
                                        name="portrait"
                                        onChange={onUploadPortrait}
                                        accept="image/png, image/jpeg"
                                    />
                                    <ImageHolder
                                        showImageUploader={onUploadClickPortrait}
                                        imageUrl={formData && formData.thumbnail.portrait ? formData.thumbnail.portrait.url : null}
                                        onImageLoad={onImageLoadPortrait}
                                        portrait
                                    />
                                </ImageWrapper>
                            </Col>
                        </Row>
                        <div style={{ height: '20px' }} />
                        <TitleWithTooltip
                            title="Property Slug *"
                            hint="This is the Slug of the webstory."
                        />
                        <Input
                            type="text"
                            className="form-control"
                            value="brands-of-new-india"
                            disabled
                            name="webstoryPropertySlug"
                            style={{ minWidth: '60%' }}
                            autoComplete="off"
                            maxLength="200"
                        />
                        <div style={{ height: '20px' }} />
                        {/* <TitleWithTooltip
                            title="Status *"
                            hint="Status"
                        />
                        <DropDown
                            className="mt-0"
                            onChange={onInputChange}
                            onBlur={() => { }}
                            value={JSON.stringify(category)}
                        >
                            {renderBrandDropdownItems()}
                        </DropDown>
                        <div style={{ height: '20px' }} /> */}
                        <TitleWithTooltip
                            title="Brand"
                            hint="Brand"
                        />
                        <AsyncMultiSelect
                            placeholder="Search brand by name"
                            options={(value) => getDropdownOptions('company-brand', value)}
                            currentValues={formData.brand}
                            handleChange={(value) => dispatch(updateFormData({
                                name: 'brand',
                                value,
                            }))}
                            name="brand"
                        />
                        <div style={{ height: '20px' }} />
                        <Button
                            type="submit"
                            primary
                            btn_rounded
                        >
                            Create Web Story
                        </Button>
                    </Col>
                </form>
                {renderFormMessage()}
            </WrapperContainer>
        </div>
    );
};

WriteWebstory.propTypes = {
    pageTitle: T.func,
    user: T.object,
};

export default WriteWebstory;
