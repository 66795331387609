/* eslint-disable no-nested-ternary */
export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const PRIMARYCOLOR = '#e5002d';

console.log('APP ENV', process.env.NODE_ENV);

export const BASE_URL = process.env.REACT_APP_SERVICE_CREATORSTUDIO;
