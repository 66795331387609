export const SELECT_TOPIC = 'SELECT_TOPIC';

export const CHANGE_NOTIFICATION_PAYLOAD = 'CHANGE_NOTIFICATION_PAYLOAD';

export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_ERROR = 'SEND_NOTIFICATION_ERROR';

export const UPDATE_DISPLAY_MESSAGE = 'UPDATE_DISPLAY_MESSAGE';

export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
export const CREATE_NOTIFICATION_ERROR = 'CREATE_NOTIFICATION_ERROR';
