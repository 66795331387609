import { Map } from 'immutable';
import {
	SET_TAG_VALUE, SEARCH_STORIES_BY_KEYWORDS, SEARCH_STORIES_BY_KEYWORDS_SUCCESS, SEARCH_STORIES_BY_KEYWORDS_FAILURE, UPDATE_TAGGED_STORIES, SET_LIST_PAGE, TAG_STORIES, TAG_STORIES_SUCCESS, TAG_STORIES_FAILURE, SET_FORM_MESSAGE, SET_TAG_TYPE, CLEAR_STATE,
} from './type';
import { SAVING_MESSAGE, SAVING_SUCCESS_MESSAGE, SAVING_FAILURE_MESSAGE } from './constants';

const initialState = Map({
	tagValue: null,
	taggedStories: {},
	loading: false,
	page: 1,
	listedStories: [],
	formMessage: null,
	tagType: null,
});

function multipleStoriesTagger(state = initialState, action) {
	switch (action.type) {
	case SET_TAG_VALUE:
		return state
			.set('tagValue', action.value)
			.set('listedStories', [])
			.set('taggedStories', {})
			.set('formMessage', null)
			.set('page', 1);
	case SEARCH_STORIES_BY_KEYWORDS:
		return state
			.set('loading', true);
	case SEARCH_STORIES_BY_KEYWORDS_SUCCESS: {
		let listedStories = Object.assign([], state.get('listedStories'));
		listedStories = listedStories.concat(action.stories);
		return state
			.set('loading', false)
			.set('listedStories', listedStories);
	}

	case SEARCH_STORIES_BY_KEYWORDS_FAILURE:
		return state
			.set('loading', false);

	case UPDATE_TAGGED_STORIES: {
		const listedStories = Object.assign([], state.get('listedStories'));
		listedStories[action.index].tagged = !listedStories[action.index].tagged;
		const taggedStories = { ...state.get('taggedStories') };
		if (listedStories[action.index].tagged) {
			taggedStories[listedStories[action.index].storyId] = listedStories[action.index];
		} else {
			delete taggedStories[listedStories[action.index].storyId];
		}
		return state
			.set('listedStories', listedStories)
			.set('taggedStories', taggedStories);
	}

	case SET_LIST_PAGE:
		return state
			.set('page', action.page);

	case TAG_STORIES:
		return state
			.set('formMessage', SAVING_MESSAGE);
	case TAG_STORIES_SUCCESS:
		return state
			.set('formMessage', SAVING_SUCCESS_MESSAGE);
	case TAG_STORIES_FAILURE:
		return state
			.set('formMessage', SAVING_FAILURE_MESSAGE);

	case SET_FORM_MESSAGE:
		return state
			.set('formMessage', action.message);
	case SET_TAG_TYPE:
		return state
			.set('tagType', action.value);

	case CLEAR_STATE:
		return initialState;
	default:
		return state;
	}
}

export default multipleStoriesTagger;
