export const FETCH_PROFILES = 'FETCH_PROFILES';
export const FETCH_PROFILES_SUCCESS = 'FETCH_PROFILES_SUCCESS';
export const FETCH_PROFILES_FAILURE = 'FETCH_PROFILES_FAILURE';

export const FETCH_NEXT_PAGE = 'FETCH_NEXT_PAGE';

export const RUN_PROFILE_LIST_SEARCH = 'RUN_PROFILE_LIST_SEARCH';
export const RUN_PROFILE_LIST_SEARCH_SUCCESS = 'RUN_PROFILE_LIST_SEARCH_SUCCESS';
export const RUN_PROFILE_LIST_SEARCH_FAILURE = 'RUN_PROFILE_LIST_SEARCH_FAILURE';

export const RUN_PROFILE_LIST_SORT = 'RUN_PROFILE_LIST_SORT';

export const DISABLE_PROFILE_LIST_SEARCH = 'DISABLE_PROFILE_LIST_SEARCH';
export const DISABLE_PROFILE_LIST_SEARCH_FLAG = 'DISABLE_PROFILE_LIST_SEARCH_FLAG';

export const CLEAR_PROFILE_DESK_DATA = 'CLEAR_PROFILE_DESK_DATA';
