import {
	SHOW_COPY_ELEMENT, ADD_TO_LIST_ON_UPLOAD, LOADING_LIST, LOAD_ADHOC_URL, LOAD_MORE_ADHOC_URL, FETCH_ADHOC_URL_LIST, LOADING_STATE, CLEAR_STATE,
} from './type';

export function loadingList() {
	return {
		type: LOADING_LIST,
	};
}

export function changeCopyElementValue(value) {
	return {
		type: SHOW_COPY_ELEMENT,
		value,
	};
}

export function loadAdhocUrl(list, pageNo, dateArray, end) {
	return {
		type: LOAD_ADHOC_URL,
		list,
		pageNo,
		dateArray,
		end,
	};
}

export function addToListOnUpload(item) {
	return {
		type: ADD_TO_LIST_ON_UPLOAD,
		item,
	};
}

export function loadingState(status) {
	return {
		type: LOADING_STATE,
		status,
	};
}

export function loadMoreAdhocUrl(list, pageNo) {
	return {
		type: LOAD_MORE_ADHOC_URL,
		list,
		pageNo,
	};
}

export function fetchAdhocUrlList() {
	return {
		type: FETCH_ADHOC_URL_LIST,
	};
}

export function clearState() {
	return {
		type: CLEAR_STATE,
	};
}
