import styled from 'styled-components';

const Card = styled.div`
		height: 285px!important;
		width: 300px!important;
		background-color: #fafafa!important;
		display: flex;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		align-items: center;
		cursor: pointer;
`;

export { Card };
