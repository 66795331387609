import React from 'react';
import PropTypes from 'prop-types';
import { DropDown } from '../Styles';
import { generateObjectArray } from '../../utils/utility_function';

const SELECT_AN_OPTION = 'SELECT AN OPTION';

const DropDownBar = (props) => {
	const { placeholder } = props;
	const value = props.value ? props.value : props.placeholder;
	const list = (props.list && ((typeof props.list[0]) === 'object')) ? props.list : generateObjectArray(props.list);
	return <DropDown value={value} onChange={(e) => props.onChange(e.target.value)} className="m-0 rounded-input pl-1" placeholder={placeholder} spellCheck="false">
		<option value={placeholder} disabled hidden>{placeholder}</option>
		{list.map((obj) => <option key={`${obj.key}-${obj.value}`} value={obj.value}>{obj.label}</option>)}
	</DropDown>;
};

DropDownBar.defaultProps = {
	placeholder: SELECT_AN_OPTION,
	list: [],
};

DropDownBar.propTypes = {
	onChange: PropTypes.func.isRequired,
	list: PropTypes.array,
	value: PropTypes.any,
	placeholder: PropTypes.any,
};

export default DropDownBar;
