export const REJECTED_BASE_URL = '/api/v2/rw/talent';

export const FILTER_REJECTED = 'FILTER_REJECTED';
export const FETCH_REJECTED_SUCCESS = 'FETCH_REJECTED_SUCCESS';
export const FETCH_REJECTED_FAILURE = 'FETCH_REJECTED_FAILURE';
export const FETCH_REJECTED = 'FETCH_REJECTED';
export const FILTER_REJECTED_SUCCESS = 'FILTER_REJECTED_SUCCESS';
export const FILTER_REJECTED_FAILURE = 'FILTER_REJECTED_FAILURE';
export const SET_REJECTED_PAGE = 'SET_REJECTED_PAGE';
export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
export const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
export const UPDATE_ACTIONS_ERROR = 'UPDATE_ACTIONS_ERROR';
export const UPDATE_ACTIONS_SUCCESS = 'UPDATE_ACTIONS_SUCCESS';
export const UPDATE_ACTIONS_LOADER = 'UPDATE_ACTIONS_LOADER';
export const SET_ACTIONS_MESSAGE = 'SET_ACTIONS_MESSAGE';
