import styled, { css } from 'styled-components';

const Spacer = styled.div`
	height: ${(props) => (props.height ? props.height : '1')}rem;
`;

const Wrapper = styled.div`
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-right: 15px;
	padding-left: 15px;
	font-family:"PT Sans", sans-serif;
	box-shadow: 0px 5px 5px -3px #C8CECA;
	padding-top: 1rem;
		@media (min-width: 576px) 
		{
			padding-top: 0;
			max-width: 540px;	
		}
		@media (min-width: 768px) {
			padding-top: 0;
			max-width: 720px; 
		}
		@media (min-width: 992px) {
			padding-top: 0;
			max-width: 960px; 
		}
		@media (min-width: 1200px) {
			max-width: 1280px;
		}
`;

const WrapperContainer = styled.div`
	margin-top: 3em;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 5em;
	padding-top: 3em;
	font-family: "PT Sans", sans-serif;
		@media (min-width: 576px) 
		{
			max-width: 540px;	
		}
		@media (min-width: 768px) {
			max-width: 720px; 
		}
		@media (min-width: 992px) {
			max-width: 960px; 
		}
		@media (min-width: 1200px) {
			max-width: 1280px;
		}
`;

const HeaderWrapper = styled.div`
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
	font-family: "PT Sans", sans-serif;
		@media (min-width: 576px) 
		{
			max-width: 540px;	
		}
		@media (min-width: 768px) {
			max-width: 720px; 
		}
		@media (min-width: 992px) {
			max-width: 960px; 
		}
		@media (min-width: 1200px) {
			max-width: 1280px;
		}
`;

const SectionHeading = styled.div`
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-top: 1px solid #333;
	border-bottom: 1px solid #333;
	margin-bottom: 1rem;

	& .heading-text {
		font-size: 1.25rem;
		font-weight: 300;
		color: #3c3c3c;
		text-transform: uppercase;
	}
	& .link-text {
		text-align:right;
		text-transform: capitalize;
		color: #3c3c3c;
		font-size: 1.25rem;
		font-weight: 300;
	}
`;

const PageHeading = styled.div`
	padding-top: 1.2rem;
	padding-bottom: 1.2rem;
	margin-bottom: 1rem;
	background-color: #fafafa;

	& .heading-text {
		font-size: 2rem;
		font-weight: 400;
		line-height: 1;
		color: #3c3c3c;
		margin-bottom: 8px;
		text-transform: capitalize;
	}
`;

const Button = styled.button`
	display: inline-block;
	font-weight: 400;
	font-family:"PT Sans", sans-serif;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;
	color: #fff;
	font-size:0.9rem;
	background: transparent;
	border: 1px solid #e5002d;
	padding: 0.375rem 0.75rem;
	line-height: 1.5;
	cursor:pointer;
	border-radius: 0.25rem;
	margin-right:0.25rem;
	margin-bottom:0.5rem;


	&:focus {
		outline:none;
	}

 
	${(props) => props.btn_rounded && css`
		border-radius:5rem;
	`}
	
	${(props) => props.primary && css`
		background-color: #e5002d;
		color: #fff;
		border-color: #e5002d;
		&:hover {
			color: #fff;
			background-color: #bf0025;
			border-color: #b20023;
		}
	`}
	${(props) => props.no_radius && css`
		border-radius:0;
	`}
	 ${(props) => props.primary_outline && css`
		color:#e5002d;
		background-color:transparent;
		border-color: #e5002d;
	`}
	${(props) => props.secondary && css`
		 background-color: #6c757d;
			border-color: #6c757d;
			&:hover {
				color: #fff;
				background-color: #5a6268;
				border-color: #545b62;
		}
	`}
	${(props) => props.secondary_outline && css`
		color:#545b62;
		background-color:transparent;
		border-color: #545b62;
	`}
	${(props) => props.success && css`
		 background-color: #28a745;
			border-color: #28a745; 
			&:hover {
				color: #fff;
				background-color: #218838;
				border-color: #1e7e34;
			}
			&:disabled {
				color: #fff;
				background-color: #28a745;
				border-color: #28a745;
				cursor: not-allowed;
			}
	`}
	${(props) => props.w_100 && css`
	 width:100%;
 `}
	${(props) => props.success_outline && css`
		color:#28a745;
		background-color:transparent;
		border-color: #28a745;
	`}
	${(props) => props.light && css`
		color: #000;
		background-color: #f8f9fa;
		border-color: #ddd;
		&:hover {
			color: #000;
			background-color: #e2e6ea;
			border-color: #ddd;
		}
	`}
	 ${(props) => props.light_outline && css`
		color:#333;
		background-color:transparent;
		border-color: #ddd;
	`}

	${(props) => props.btn_xs && css`
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		line-height: 1;
		border-radius: 0.2rem;
	`}
	${(props) => props.btn_sm && css`
		padding: 0.5rem 0.5rem;
		font-size: 1rem;
		line-height: 1;
		border-radius: 0.2rem;
	`}
	${(props) => props.btn_md && css`
		padding: 0.8rem 0.8rem;
		font-size: 1rem;
		line-height: 1;
		border-radius: 0.2rem;
	`}
	${(props) => props.btn_lg && css`
		padding: 1rem 1rem;
		font-size: 1rem;
		line-height: 1;
		border-radius: 0.2rem;
	`}
	${(props) => props.flex_btn && css`
		display: inline-flex;
		align-items: center;
		margin-bottom: 0;
		justify-content: center;
	`}
	${(props) => props.fb_msg && css`
		color: #FFF;
		background-color: #0078FF;
		border-color: #ddd;
		&:hover {
			color: #FFF;
			background-color: #00C6FF;
			border-color: #ddd;
		}
	`}

`;

const InlineFlex = styled.div`
	display: inline-flex;
	height: 100%;
	width: 100%;
	${(props) => props.vt_ct && css`
		align-items: center;
	`}
	${(props) => props.hr_ct && css`
		justify-content: center;
	`}
`;

const Input = styled.input`
	padding: 0px 5px;
	font-size: 1rem;
	display: block;
	width: 100%;
	// padding: 0.375rem 0.75rem;
	line-height: 1.5;
	min-height:35px;
	color: #000;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	// font-size: xx-large;
	 &:focus {
			outline:none;
		}
	${(props) => props.file_upload && css`
		position: relative;
		z-index: 2;
		width: 100%;
		height: calc(2.25rem + 2px);
		margin: 0;
		opacity: 0; 
		&:after {
			border-color: none; 
		}

	`}
	${(props) => props.image_upload && css`
		border: none;
		line-height: 1;
		font-size: 1rem;
		margin: 0px;
		padding: 0px;
	`}
	${(props) => props.radio && css`
		width: 0.8rem;
		display: -webkit-inline-box;
		margin: 10px;
	`}
	${(props) => props.disabled && css`
		background-color: #e0dddd;
		cursor: not-allowed;
	`}
`;

const InputGroup = styled.div`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%;
	&:focus {
		outline:none;
	}
	> .date-picker {
		position:relative;
		> .element {
			width: auto;
			display:inline-block !important; 
			@media (max-width:992px){
				width: unset;
				display: block !important;
			}
		}
	}
	> .prepend-group {
			display:block;
			margin-right: -1px;
		> .prepend-group-text {
			display: flex;
			align-items: center;
			height:100%;
			padding: 0.375rem 0.75rem;
			margin-bottom: 0;
			font-weight: 400;
			line-height: 1.5;
			color: #333;
			text-align: center;
			white-space: nowrap;
			background-color: #e9ecef;
			border: 1px solid #ced4da;
			border-radius: 0.25rem;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
			
	}

	> .form-control {
		position: relative;
		flex: 1 1 auto;
		width: 1%;
		margin-bottom: 0;
	
		&:focus {
			outline:none;
		}

		&:not(:first-child){
		 border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		&:not(:last-child){
		 border-top-right-radius: 0;
		 border-bottom-right-radius: 0;
		}
	}

	> .append-group {
			display:block;
			position:relative;
			margin-left: -1px;
		
		> .float-icon {
			position:absolute;
			position: absolute;
			left: -14px;
			top: 8px;
			cursor: pointer;
		}
		

		> .append-group-text {
			display: flex;
			align-items: center;
			padding: 0.375rem 0.75rem;
			margin-bottom: 0;
			height:100%;
			font-weight: 400;
			line-height: 1.5;
			color: #333;
			height:100%;
			text-align: center;
			white-space: nowrap;
			background-color: #e9ecef;
			border: 1px solid #ced4da;
			border-radius: 0.25rem;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		> .append-icon {
			position:absolute;
			position: absolute;
			left: -25px;
			top: 8px;
		}
	}
`;

const DropDown = styled.select`
	margin-top: 20px;
	background: transparent;
	border: 1px solid #ddd;
	width:100%;
	height:35px;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	&:hover {
		background: white;
		color: black;
		border-color: #333;
	}
	&:focus {
		outline:none;
	}
	${(props) => props.eq_mg && css`
		margin-top: 10px;
		margin-bottom: 10px
	`}
`;

const Card = styled.div`
	 border:1px solid #ddd;
	 box-shadow : 0 0.1rem 0.3rem rgba(0, 0, 0, 0.15);
	 border-radius: 0.25rem;
	 padding:0.5rem;
	 margin-bottom:1rem;
	 word-wrap: break-word;
	 > .description {
			font: 400 0.9rem "PT Sans", sans-serif;
			color:#666;
	 }
	 > .info {
		min-height:70px;
		font-weight:400;
		font-family: "PT Sans", sans-serif;
	 }
	 .separator { border-color:#f9f9f9 !important; }
	 > .brand-name {
		 color:#ccc;
		 font-size:1.2rem;
	 }
	}
`;

const Breadcrumb = styled.div`
	background-color:#e5002d;
	color:#fff;
	padding:0.5rem 0;
	li {
		display:inline-block;
		list-style:none;
		margin-right:0.8rem;
		text-transform:uppercase;
	}
	.separator {
		color:#ccc;
	}
`;

const ActionCard = styled.div`
	box-shadow: 0 0rem 0.2rem rgba(0, 0, 0, 0.3);
	border-radius: 0.25rem;
	min-width: 100%;

	& .dropdown {
		border:2px solid #e5002d;
		border-radius:0; 
		color: #e5002d;
	}
	& .percentage {
		color:#fff;
		text-align:center;
		border-radius:8rem;
		padding:0.1rem;
		font-size: 15px;
		width:80%;
	}
	& .separator:before {
		content: '';
		display: inline-block;
		width: 10px;
		height: 10px;
		margin-right:15px;
		margin-left:5px;
		-moz-border-radius: 7.5px;
		-webkit-border-radius: 7.5px;
		border-radius: 7.5px;
		background-color: #ccc;
	}
	& .plagiarism-btn {
		font-weight: bolder;
		font-size: x-large;
	}
`;

const Modal = styled.div`	
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 0;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 5;
	> .modal-float {
		display: table;
		height: 100%;
		width: 100%;
		> .modal-wrapper {
			display: table-cell;
			vertical-align: middle;
		}
		& .modal-title {
			position: realtive;
			text-align: left;
			font-size: 1rem;
			font-weight: bold;
		}
		& .modal-content {
			background-color: #fff;
			border-radius: 0;
			color: #333;
			margin: 0 auto;
			max-width: 435px;
			padding: 10px 10px;
			position: relative;
			text-align: center;
			border-radius : 5px;
			@media (max-width: 576px) 
			{
				max-width: 320px; 
				overflow-y: scroll; 
			}
		}
		& .manager-modal-content {
			background-color: #fff;
			border-radius: 0;
			color: #333;
			margin: 0 auto;
			max-width: 900px;
			height: 550px;
			padding: 10px 10px;
			position: relative;
			border-radius : 5px;
			width: 100%
			@media (max-width: 576px) 
			{
				max-width: 320px;	
			}
		}
		& .modal-close {
			font-size: 30px;
			position: absolute;
			right: 15px;
			top: -8px;
			cursor: pointer;
			text-decoration: none;
		}
	}
`;

const ModalContent = styled.div`
		background-color: #fff;
		border-radius: 0;
		color: #333;
		margin: 0 auto;
		max-width: ${(props) => (props.maxWidth ? props.maxWidth : '60%')};
		min-width: ${(props) => (props.minWidth ? props.minWidth : '60%')};
		min-height: ${(props) => (props.minHeight ? props.minHeight : '92%')};
		padding: 10px 10px;
		position: relative;
		border-radius : 0px;
		padding: 25px; 
		@media (max-width: 576px) {
			max-width: 320px;	
		}
		margin-bottom: 10px
`;

const Dot = styled.span`
	height: ${(props) => (props.height ? props.height : '15')}px;
	width: ${(props) => (props.width ? props.width : '15')}px;
	border-radius: 50%;
	display: inline-block;
	background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'grey')};
`;

const CustomTab = styled.div`
	width:100% !important;
	@media(max-width:768px){
		text-align:center;
	}
	.search-box {
		justify-content: flex-end;
		@media(max-width:768px){
			justify-content: center;
			margin-bottom:15px;
		}
	}
	.tab-list { 
		padding-left:0; 
		> li {
			display:inline-block;
			list-style: none;
			padding: 5px 10px;
			border: 1px solid #3399cc;
			color: #3399cc;
			border-right:0;
			cursor:pointer;
			font-weight: 500;
			&:hover {
				background-color: #3399cc;
				color:#fff;
				border-left-color:#fff;
			}
			&:last-child {
				border-right :1px solid;
			}
			@media(max-width:500px){
				width: 125px;
				border: 1px solid #3399cc; 
				margin-right: 5px;
				margin-bottom: 5px;
			}
			@media(min-width:769px) and (max-width:992px){
				width: 100px;
				padding: 5px;
				font-size:12px;
				text-align:center;
			}
		}
	& .active { 
			background-color: #3399cc;
			color:#fff;
		}
	} 
}
`;

const AlertMessage = styled.div`
	z-index: 100;
	margin: auto;
	padding: 0.5rem;
	text-align: center;
	.success {
		background-color: green;
		color: white;
	}
	.error {
		background-color: red;
		color: white;
	}
`;

const ModalCloseButton = styled.button`
	background-color: white;
	color: #767676;
	padding: 0.5rem;
	display: block;
	float: right;
`;

const Textarea = styled.textarea`
	border: 1px solid gray;
	width: 100%;
	height: 300px;
	padding: 1rem;
`;

const ShadowedPanel = styled.div`
		padding: 1rem;
		margin: 1rem 0;
		box-shadow: 1px 2px 3px 0px #DDDDDD;
		position: relative;

		${(props) => props.noPadding && css`
		padding: 0;
	`}


		.header-input {
				border: 1px solid #ced4da;
				width: 100%;
				height: 100px;
				padding: 0.5rem;
				border-radius: 0.25rem;
		}

		.header-input:focus {
				border: 3px solid #ced4da;
				outline: none;
		}
`;

const EditorContainer = styled.div`
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
		margin-top: 2em;
		margin-bottom: 5em;
		padding-top: 1em;
		font-family: "PT Sans", sans-serif;
		@media (min-width: 576px) 
		{
				max-width: 540px;	
		}
		@media (min-width: 768px) {
				max-width: 720px; 
		}
		@media (min-width: 992px) {
				max-width: 960px; 
		}
		@media (min-width: 1200px) {
				max-width: 1280px;
		}
`;

const TabWrapper = styled.div`
		width: 100%;
		margin: 1rem auto;		
		padding: 1rem 15px;
		background-color: #fff;
		font-family: "PT Sans", sans-serif;
		position: sticky;
		top: 60px;
		z-index: 2;
		@media (min-width: 576px) 
		{
				max-width: 540px;	
		}
		@media (min-width: 768px) {
				max-width: 720px; 
		}
		@media (min-width: 992px) {
				max-width: 960px; 
		}
		@media (min-width: 1200px) {
				max-width: 1280px;
		}	
`;

const DashBoardFooter = styled.div`
	text-align: center;
	padding: 1rem;
	border-top: 1px solid #ddd;
	color: red;
`;
const NewButton = Button;
const DropDownStyleComponent = DropDown;
export {
	Spacer, Wrapper, Textarea, WrapperContainer, Button, InlineFlex, ModalCloseButton, ModalContent, Dot, Input, InputGroup, SectionHeading, PageHeading, Card, DropDown, Breadcrumb, ActionCard, CustomTab, Modal, AlertMessage, HeaderWrapper, ShadowedPanel, TabWrapper, EditorContainer,
	NewButton, DropDownStyleComponent, DashBoardFooter,
};
