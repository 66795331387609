const CURATED_SET_CONFIG_URL = '/api/v2/rw/curated/config/100_women_leader';

const SAVING_MESSAGE = { intent: 'info', message: 'Saving data...' };
const SAVING_SUCCESS_MESSAGE = { intent: 'success', message: 'Data Saved Successfully' };
const SAVING_FAILURE_MESSAGE = { intent: 'danger', message: 'Data Save Failed. Something went wrong. Try Again!' };
const OPTIONSLIST = [
	{
		name: 'Mumbai-2023',
		label: 'Mumbai-2023',
		key: 'Mumbai-2023',
		value: 'mumbai',
	}, {
		name: 'Bangalore-2022',
		label: 'Bangalore-2022',
		key: 'Bangalore-2022',
		value: 'bangalore',
	},
];

const toolbarOptions = [
	['bold', 'italic', 'underline'],
	// [{ list: 'ordered' }, { list: 'bullet' }],
	// [{ script: 'sub' }, { script: 'super' }],
	// [{ indent: '-1' }, { indent: '+1' }],
	// [{ direction: 'rtl' }],
	// ['clean'],
];

const regex = /^((?!(0))[0-9]*)$/;
const regex1 = /^0+$/;
/* eslint-disable no-useless-escape */
const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export {
	SAVING_MESSAGE,
	SAVING_SUCCESS_MESSAGE,
	SAVING_FAILURE_MESSAGE,
	CURATED_SET_CONFIG_URL,
	OPTIONSLIST,
	toolbarOptions,
	regex,
	regex1,
	emailRegex,
};
