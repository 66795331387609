import React, { useEffect } from 'react';
import { Row } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import { Button, WrapperContainer } from '../../components/Styles';
import {
	BoniBrandManager, BrandCategoryManager, BrandOfTheDayManager, BrandProductManager, SpotlightOfTheMonthManager, BrandWebStoriesManager, BrandProductCategoriesManager, BrandExternalLinksManager,
} from './constants';

const BoniManager = (props) => {
	const { pageTitle, user } = props;

	useEffect(
		() => {
			pageTitle('boniManager');
		}, [],
	);

	return (
		<WrapperContainer>
			<Row>
				<BrandCategoryManager />
				{user.roles.brandsOfNewIndiaManager ? <BoniBrandManager /> : null}
				<BrandProductCategoriesManager />
				<BrandProductManager />
				<BrandOfTheDayManager />
				<BrandWebStoriesManager />
				<SpotlightOfTheMonthManager />
				<BrandExternalLinksManager />
			</Row>
			<Row>
				<Button
					primary
					className="mr-4 mb-0"
					onClick={() => window.open('https://docs.google.com/document/d/1Og2Q3lpYaTB_pes_jYi9jVPFJwUyaaBacT5NdHU8sNY/edit')}
				>
					CMS Editor's Steps To Follow
				</Button>
			</Row>
		</WrapperContainer>
	);
};

BoniManager.propTypes = {
	pageTitle: T.func,
	user: T.object,
};

export default BoniManager;
