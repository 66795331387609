const STORY_LIST_BASE_API_URL = '/api/v2/rw/stories';

const MODAL_STATE = Object.freeze({ OPEN: true, CLOSE: false });

export {
	STORY_LIST_BASE_API_URL,
	MODAL_STATE,
};

export const StoryTypeFilterOptions = [
	{
		id: Math.random(1, 1000), name: 'All', value: 'All', label: 'All',
	},
	{
		id: Math.random(1, 1000), name: 'Rich', value: 'RICH', label: 'Rich',
	},
	{
		id: Math.random(1, 1000), name: 'Video', value: 'VIDEO', label: 'Video',
	},
	{
		id: Math.random(1, 1000), name: 'Glossary', value: 'GLOSSARY', label: 'Glossary',
	},
	{
		id: Math.random(1, 1000), name: 'Web Story', value: 'WEB_STORY', label: 'Web Story',
	},
];
