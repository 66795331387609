import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { USER_BASE_API_URL } from './constants';
import { LOAD_BRAND, CREATE_ALIAS } from './type';
import {
	brandsLoadedSuccessfully,
	brandsLoadingError,
	createAliasSuccess,
	createAliasFailure,
} from './actions';

export function* loadBrandList() {
	const url = `${USER_BASE_API_URL}/brand/list`;
	const options = {
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.status) {
				yield put(brandsLoadedSuccessfully(responseMessage));
			} else {
				yield put(brandsLoadingError(responseMessage));
			}
		}
	} catch (error) {
		yield put(brandsLoadingError(error));
	}
}

export function* createAlias(action) {
	const url = `${USER_BASE_API_URL}/alias/create`;
	const formData = action.alias;
	const options = {
		method: 'POST',
		credentials: 'include',
		body: formData,
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.status === 'success') {
				yield put(createAliasSuccess(responseMessage.message));
			} else {
				yield put(createAliasFailure(responseMessage.error));
			}
		}
	} catch (err) {
		yield put(createAliasFailure(err));
	}
}

export default function* createAliasSaga() {
	yield takeLatest(LOAD_BRAND, loadBrandList);
	yield takeLatest(CREATE_ALIAS, createAlias);
}
