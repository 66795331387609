import {
	USER_LOGOUT, USER_LOGOUT_SUCCESS, USER_LOGOUT_ERROR, SET_PAGE_NAME, SET_ONLINE_STATUS, FETCH_APP_DATA, FETCH_APP_DATA_SUCCESS, FETCH_APP_DATA_FAILURE, SET_CURRENT_BRAND,
} from './type';

export function fetchAppData() {
	return {
		type: FETCH_APP_DATA,
	};
}

export function fetchAppDataSuccess(user, userBrands) {
	return {
		type: FETCH_APP_DATA_SUCCESS,
		user,
		userBrands,
	};
}

export function fetchAppDataFailure() {
	return {
		type: FETCH_APP_DATA_FAILURE,
	};
}

export function userLogout() {
	return {
		type: USER_LOGOUT,
	};
}

export function userLogoutSuccess() {
	return {
		type: USER_LOGOUT_SUCCESS,
	};
}

export function userLogoutError(error) {
	return {
		type: USER_LOGOUT_ERROR,
		error,
	};
}

export function setPageName(page) {
	return {
		type: SET_PAGE_NAME,
		page,
	};
}

export function setOnlineStatus(value) {
	return {
		type: SET_ONLINE_STATUS,
		value,
	};
}

export function setCurrentBrand(brand) {
	return {
		type: SET_CURRENT_BRAND,
		brand,
	};
}
