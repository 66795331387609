import {
	ON_MEDIA_FORM_DATA_CHANGE, ON_MEDIA_FILE_SELECTED, CREATE_NEW_ASSET, CREATE_NEW_ASSET_FAILURE, CREATE_NEW_ASSET_SUCCESS, UNMOUNT_MEDIA_FORM_MESSAGE, ON_MEDIA_ASSET_SELECTED, FETCH_STORIES_BY_MEDIA_ID, FETCH_STORIES_BY_MEDIA_ID_SUCCESS, FETCH_STORIES_BY_MEDIA_ID_FAILURE, CLEAR_MEDIA_EDITOR_DATA, UPDATE_MEDIA, UPDATE_MEDIA_SUCCESS, UPDATE_MEDIA_FAILURE, START_UPLOADING_MEDIA, DISPLAY_MEDIA_PREVIEW, FETCH_MEDIA_ASSET_BY_ID, FETCH_MEDIA_ASSET_BY_ID_SUCCESS, FETCH_MEDIA_ASSET_BY_ID_FAILURE,
} from './type';

export function onMediaFormDataChange(name, value) {
	return {
		type: ON_MEDIA_FORM_DATA_CHANGE,
		name,
		value,
	};
}

export function onMediaFileSelected(fileInfo) {
	return {
		type: ON_MEDIA_FILE_SELECTED,
		fileInfo,
	};
}

export function createNewAsset(asset) {
	return {
		type: CREATE_NEW_ASSET,
		asset,
	};
}

export function createNewAssetSuccess(assetResponse) {
	return {
		type: CREATE_NEW_ASSET_SUCCESS,
		assetResponse,
	};
}

export function createNewAssetFailure(error) {
	return {
		type: CREATE_NEW_ASSET_FAILURE,
		error,
	};
}

export function unmountMediaFormMessage() {
	return {
		type: UNMOUNT_MEDIA_FORM_MESSAGE,
	};
}

export function onMediaAssetSelected(media) {
	return {
		type: ON_MEDIA_ASSET_SELECTED,
		media,
	};
}

export function fetchStoriesByMediaId(mediaId) {
	return {
		type: FETCH_STORIES_BY_MEDIA_ID,
		mediaId,
	};
}

export function fetchStoriesByMediaIdSuccess(stories) {
	return {
		type: FETCH_STORIES_BY_MEDIA_ID_SUCCESS,
		stories,
	};
}
export function fetchStoriesByMediaIdFailure(error) {
	return {
		type: FETCH_STORIES_BY_MEDIA_ID_FAILURE,
		error,
	};
}

export function clearMediaEditorData() {
	return {
		type: CLEAR_MEDIA_EDITOR_DATA,
	};
}

export function updateMedia(mediaId, media) {
	return {
		type: UPDATE_MEDIA,
		mediaId,
		media,
	};
}

export function updateMediaSuccess(updatedMedia) {
	return {
		type: UPDATE_MEDIA_SUCCESS,
		updatedMedia,
	};
}

export function updateMediaFailure(error) {
	return {
		type: UPDATE_MEDIA_FAILURE,
		error,
	};
}

export function savingMedia() {
	return {
		type: START_UPLOADING_MEDIA,
	};
}

export function displayMediaPreview(value) {
	return {
		type: DISPLAY_MEDIA_PREVIEW,
		value,
	};
}

export function fetchMediaAssetById(id) {
	return {
		type: FETCH_MEDIA_ASSET_BY_ID,
		id,
	};
}

export function fetchMediaAssetByIdSuccess(media) {
	return {
		type: FETCH_MEDIA_ASSET_BY_ID_SUCCESS,
		media,
	};
}

export function fetchMediaAssetByIdFailure(error) {
	return {
		type: FETCH_MEDIA_ASSET_BY_ID_FAILURE,
		error,
	};
}
