import {
	FETCH_STORY_VERSIONS_BY_STORY_ID, FETCH_STORY_VERSIONS_BY_STORY_ID_SUCCESS, FETCH_STORY_VERSIONS_BY_STORY_ID_FAILURE, SET_SELECTED_STORY_VERSION,
} from './type';

export function fetchStoryVersionsByStoryId(storyId) {
	return {
		type: FETCH_STORY_VERSIONS_BY_STORY_ID,
		storyId,
	};
}

export function fetchStoryVersionsByStoryIdSuccess(storyVersions) {
	return {
		type: FETCH_STORY_VERSIONS_BY_STORY_ID_SUCCESS,
		storyVersions,
	};
}

export function fetchStoryVersionsByStoryIdFailure(error) {
	return {
		type: FETCH_STORY_VERSIONS_BY_STORY_ID_FAILURE,
		error,
	};
}

export function setSelectedStoryVersion(selectedVersion) {
	return {
		type: SET_SELECTED_STORY_VERSION,
		selectedVersion,
	};
}
