import { createSelector } from 'reselect';

const selectDesk = (state) => state.get('desk');

const makeSelectDeskStories = () => createSelector(
	selectDesk,
	(deskState) => deskState.get('stories'),
);

const makeSelectDeskPage = () => createSelector(
	selectDesk,
	(deskState) => deskState.get('page'),
);

const makeSelectDeskStoriesStatus = () => createSelector(
	selectDesk,
	(deskState) => deskState.get('status'),
);

const makeSelectDeskBrands = () => createSelector(
	selectDesk,
	(deskState) => deskState.get('brands'),
);

const makeSelectPendingApproval = () => createSelector(
	selectDesk,
	(deskState) => deskState.get('pendingApproval'),
);

const makeSelectDeskSearchType = () => createSelector(
	selectDesk,
	(deskState) => deskState.get('searchType'),
);

const makeSelectDeskSearch = () => createSelector(
	selectDesk,
	(deskState) => deskState.get('search'),
);

const makeSelectDeskSearchValue = () => createSelector(
	selectDesk,
	(deskState) => deskState.get('searchValue'),
);

const makeSelectDeskDisplayCommentBox = () => createSelector(
	selectDesk,
	(deskState) => deskState.get('displayCommentBox'),
);

const makeSelectDeskCommentText = () => createSelector(
	selectDesk,
	(deskState) => deskState.get('commentText'),
);

const makeSelectDeskFeedback = () => createSelector(
	selectDesk,
	(deskState) => deskState.get('feedback'),
);

const makeSelectDeskSelectedBrand = () => createSelector(
	selectDesk,
	(deskState) => deskState.get('selectedBrand'),
);

const makeSelectDeskLoading = () => createSelector(
	selectDesk,
	(deskState) => deskState.get('loading'),
);

export {
	makeSelectDeskStories,
	makeSelectDeskPage,
	makeSelectDeskStoriesStatus,
	makeSelectDeskBrands,
	makeSelectPendingApproval,
	makeSelectDeskSearchType,
	makeSelectDeskSearch,
	makeSelectDeskSearchValue,
	makeSelectDeskDisplayCommentBox,
	makeSelectDeskCommentText,
	makeSelectDeskFeedback,
	makeSelectDeskSelectedBrand,
	makeSelectDeskLoading,
};
