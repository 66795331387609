export const FUNDING_LIST_BASE_URL = '/api/v2/rw/cp-automation/funding';
export const FUNDING_LIST_UPDATE_STATUS_BASE_URL = '/api/v2/rw/cp-automation/funding/updatestatus';
export const FUNDING_LIST_SEARCH_URL = '/api/v2/rw/cp-automation/funding/search';

export const IPO_LIST_BASE_URL = '/api/v2/rw/cp-automation/ipo';
export const IPO_LIST_UPDATE_STATUS_BASE_URL = '/api/v2/rw/cp-automation/ipo/updatestatus';
export const IPO_LIST_SEARCH_URL = '/api/v2/rw/cp-automation/ipo/search';

export const ACQUISITION_LIST_BASE_URL = '/api/v2/rw/cp-automation/acquisition';
export const ACQUISITION_LIST_BASE_URL_LIST_UPDATE_STATUS_BASE_URL = '/api/v2/rw/cp-automation/acquisition/updatestatus';
export const ACQUISITION_LIST_BASE_URL_LIST_SEARCH_URL = '/api/v2/rw/cp-automation/acquisition/search';

export const MERGER_LIST_BASE_URL = '/api/v2/rw/cp-automation/merger';
export const MERGER_LIST_BASE_URL_LIST_UPDATE_STATUS_BASE_URL = '/api/v2/rw/cp-automation/merger/updatestatus';
export const MERGER_LIST_BASE_URL_LIST_SEARCH_URL = '/api/v2/rw/cp-automation/merger/search';
