import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { FETCH_CP_ITEMS, RUN_CP_LIST_SEARCH, UPDATE_CP_ITEM_STATUS } from './type';
import {
	ACQUISITION_LIST_BASE_URL, ACQUISITION_LIST_BASE_URL_LIST_UPDATE_STATUS_BASE_URL, FUNDING_LIST_BASE_URL, FUNDING_LIST_SEARCH_URL, FUNDING_LIST_UPDATE_STATUS_BASE_URL, MERGER_LIST_BASE_URL, MERGER_LIST_BASE_URL_LIST_UPDATE_STATUS_BASE_URL, ACQUISITION_LIST_BASE_URL_LIST_SEARCH_URL, MERGER_LIST_BASE_URL_LIST_SEARCH_URL,
	IPO_LIST_BASE_URL,
	IPO_LIST_UPDATE_STATUS_BASE_URL,
	IPO_LIST_SEARCH_URL,
} from './constants';
import {
	fetchCPItemsSuccess, fetchCPItemsFailure, runCPListSearchSuccess, runCPListSearchFailure, updateCPItemStatusSuccess, updateCPItemStatusFailure,
} from './actions';

export function* fetchCPItemsByLimitAndOffset(action) {
	let baseUrl = '';
	switch (action.logType) {
	case 'funding':
		baseUrl = FUNDING_LIST_BASE_URL;
		break;
	case 'acquisition':
		baseUrl = ACQUISITION_LIST_BASE_URL;
		break;
	case 'merger':
		baseUrl = MERGER_LIST_BASE_URL;
		break;
	default:
		baseUrl = IPO_LIST_BASE_URL;
		break;
	}
	const url = `${baseUrl}?limit=${action.limit}&offset=${action.offset}&sortBy=${action.sortBy}&orderBy=${action.orderBy}`;
	const data = new FormData();
	if (action.searchValue) data.append('searchValue', action.searchValue);
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const allCPItems = yield response.json();
			yield put(fetchCPItemsSuccess(allCPItems));
		}
	} catch (err) {
		yield put(fetchCPItemsFailure(err));
	}
}
export function* updateCPItemStatus(action) {
	const { newStatus, CPItem, logType } = action;
	let baseUrl = '';
	switch (logType) {
	case 'funding':
		baseUrl = FUNDING_LIST_UPDATE_STATUS_BASE_URL;
		break;
	case 'acquisition':
		baseUrl = ACQUISITION_LIST_BASE_URL_LIST_UPDATE_STATUS_BASE_URL;
		break;
	case 'merger':
		baseUrl = MERGER_LIST_BASE_URL_LIST_UPDATE_STATUS_BASE_URL;
		break;
	default:
		baseUrl = IPO_LIST_UPDATE_STATUS_BASE_URL;
	}
	const url = `${baseUrl}/${CPItem.id}`;
	const data = new FormData();
	data.append('status', newStatus);
	const options = {
		method: 'PUT',
		credentials: 'include',
		body: data,
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(updateCPItemStatusFailure(response.status));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(updateCPItemStatusSuccess(responseMessage.success, CPItem.id, newStatus));
			} else {
				yield put(updateCPItemStatusFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(updateCPItemStatusFailure(err));
	}
}

export function* filterCPList(action) {
	let url = '';
	const {
		logType, filterYear, filterMonth, searchValue, limit, offset,
	} = action;
	switch (logType) {
	case 'funding':
		url = FUNDING_LIST_SEARCH_URL;
		break;
	case 'acquisition':
		url = ACQUISITION_LIST_BASE_URL_LIST_SEARCH_URL;
		break;
	case 'merger':
		url = MERGER_LIST_BASE_URL_LIST_SEARCH_URL;
		break;
	default:
		url = IPO_LIST_SEARCH_URL;
	}

	const data = new FormData();
	if (searchValue) {
		data.append('searchValue', searchValue);
	}
	if (filterYear) {
		data.append('year', filterYear);
	}
	if (filterMonth) {
		data.append('month', filterMonth);
	}
	data.append('limit', limit);
	data.append('offset', offset);

	const options = {
		method: 'POST',
		credentials: 'include',
		body: data,
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 403 || response.status === 401) {
			yield put(push('/unauthorized'));
		} else {
			const results = yield response.json();
			yield put(runCPListSearchSuccess(results));
		}
	} catch (err) {
		yield put(runCPListSearchFailure(err));
	}
}

export default function* deskData() {
	yield takeLatest(FETCH_CP_ITEMS, fetchCPItemsByLimitAndOffset);
	yield takeLatest(RUN_CP_LIST_SEARCH, filterCPList);
	yield takeLatest(UPDATE_CP_ITEM_STATUS, updateCPItemStatus);
}
