export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const WRITE_CATEGORY_SLUG = 'WRITE_CATEGORY_SLUG';
export const CATEGORY_ELEMENT_ON_CHANGE = 'CATEGORY_ELEMENT_ON_CHANGE';

export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_ERROR = 'CREATE_CATEGORY_ERROR';

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR';

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';

export const REPLACE_CATEGORY = 'REPLACE_CATEGORY';
export const REPLACE_CATEGORY_SUCCESS = 'REPLACE_CATEGORY_SUCCESS';
export const REPLACE_CATEGORY_ERROR = 'REPLACE_CATEGORY_ERROR';
export const CATEGORY_REDIRECTION_ERROR = 'CATEGORY_REDIRECTION_ERROR';

export const UNMOUNT_CATEGORY_FORM_MESSAGE = 'UNMOUNT_CATEGORY_FORM_MESSAGE';
export const SET_CATEGORY_SLUG_UNIQUE_VALUE = 'SET_CATEGORY_SLUG_UNIQUE_VALUE';

export const SHOW_CATEGORY_LOADING_MESSAGE = 'SHOW_CATEGORY_LOADING_MESSAGE';
