import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from '../Responsive';
import { ActionCard, Button } from '../Styles';
import ProfileImagePlaceholder from '../../images/img-placeholder.png';

class ProfileDeskActionCard extends React.PureComponent {
	constructor(props) {
		super(props);
		this.onActionSelect = this.onActionSelect.bind(this);
		this.onViewClick = this.onViewClick.bind(this);
	}

	async onActionSelect(e) {
		const { profile, hideOption } = this.props;
		try {
			let newStatus = e.target.value.toString().toUpperCase();
			if (newStatus.toLowerCase() !== 'select an option') {
				if (hideOption === 'pending-') {
					if (newStatus === 'VERIFY') newStatus = 'PENDING-VERIFIED';
					else newStatus = `PENDING-${newStatus}ED`;
				} else if (newStatus === 'VERIFY') newStatus = 'VERIFIED';
				else if (['PUBLISH', 'TRASH'].includes(newStatus)) newStatus = `${newStatus}ED`;
				else if (newStatus === 'UNVERIFY') newStatus = 'PUBLISHED';
				else newStatus = 'INREVIEW';
				this.props.updateStatus(profile, newStatus);
			}
		} catch (err) {
			console.log('Update status error:', err);
		}
	}

	onViewClick() {
		const { profile, profileType } = this.props;
		if (profileType === 'company') return (`/company/editor/${profile.id}`);
		return (`/manager/company/influencers/${profile.id}`);
	}

	returnSrc() {
		const { profile, profileType } = this.props;
		const imgSrc = profileType === 'company' ? profile.logo : profile.profilePicture;
		return imgSrc && imgSrc.url ? imgSrc.url : ProfileImagePlaceholder;
	}

	render() {
		const { profile } = this.props;
		let { hideOption } = this.props;
		let user;
		if (profile.createdByUser && profile.createdByUser.name) user = 'createdByUser';
		if (profile.verifiedByUser && profile.verifiedByUser.name) user = 'verifiedByUser';
		if (profile.updatedByUser && profile.updatedByUser.name) user = 'updatedByUser';
		if (hideOption === 'pending-') {
			hideOption = `pending-${profile.status.toLowerCase()}`;
		}
		return (
			<ActionCard className="p-3 mb-3">
				<Row className="mb-2">
					<Col xs={8} sm={10} md={3} className="mb-sm-2" style={{ fontSize: '1.1rem', fontWeight: 'bold', alignSelf: 'center' }}>
						<Row className="mb-sm-2">
							<Col xs={4} sm={2} md={3}>
								<img
									width={60}
									height={60}
									src={this.returnSrc()}
									alt="user"
								/>
							</Col>
							<Col xs={12} sm={4} md={9} className="mb-sm-2" style={{ fontSize: '1.1rem', fontWeight: 'bold', alignSelf: 'center' }}>
								{profile.name}
							</Col>
						</Row>
					</Col>

					<Col xs={12} sm={12} md={9} className="xs-text-centers">
						<Row className="mb-sm-2">
							<Col xs={12} sm={4} md={2} className="mb-sm-2">
								<p><b>Current Status</b></p>
								{profile.status}

							</Col>
							<Col xs={12} sm={4} md={3} className="mb-sm-2">
								{user
									? <>
										<b>{user === 'createdByUser' ? 'Created By' : profile.status === 'PUBLISHED' ? 'Published by' : user === 'updatedByUser' ? 'Updated By' : 'Verified By'}</b>

										<Row middle="xs">
											<Col xs={8} sm={3} md={8}>
												<div>{profile[user].name ? profile[user].name : 'N/A'}</div>
												<div className="sm-text light-text">
													{profile[user].primary_email ? profile[user].primary_email : 'N/A'}
												</div>
											</Col>

										</Row>
									</> : null}
							</Col>

							<Col xs={12} sm={4} md={3} className="mb-sm-2">
								<p><b>Last Updated</b></p>
								{profile.updatedAt ? profile.updatedAt : null}
							</Col>
							<Col xs={12} sm={4} md={3}>
								<select className="global-form-control" onChange={(e) => this.onActionSelect(e)}>
									<option value="select an option">Select An Option</option>
									{['inreview', 'pending-published'].includes(hideOption) ? <option value="publish">PUBLISH</option> : null}
									{['inreview', 'pending-published', 'pending-verified', 'publish'].includes(hideOption) ? <option value="verify">VERIFY</option> : null}
									{hideOption === 'trash' ? <option value="restore">RESTORE</option> : null}
									{hideOption === 'inreview' ? <option value="trash">TRASH</option> : null}
									{['publish', 'verify'].includes(hideOption) ? <option value="unpublish">UNPUBLISH</option> : null}
									{hideOption === 'verify' ? <option value="unverify">UNVERIFY</option> : null}
									{['pending-published', 'pending-verified'].includes(hideOption) ? <option value="reject">REJECT</option> : null}
								</select>
								<a href={this.onViewClick()} target="_blank" rel="noopener noreferrer">
									<Button primary primary_outline btn_rounded w_100 className="mt-4"><b>VIEW/EDIT</b></Button>
								</a>
							</Col>

						</Row>
					</Col>
				</Row>

			</ActionCard>
		);
	}
}

ProfileDeskActionCard.propTypes = {
	profile: PropTypes.object,
	updateStatus: PropTypes.func,
	hideOption: PropTypes.string,
	// history: PropTypes.object,
	profileType: PropTypes.string,
};

export default ProfileDeskActionCard;
