import {
	FETCH_MEDIA_BY_BRAND_AND_TYPE, FETCH_MEDIA_BY_BRAND_AND_TYPE_SUCCESS, FETCH_MEDIA_BY_BRAND_AND_TYPE_FAILURE, SET_MEDIA_GALLERY_PAGE_NO, SORT_MEDIA_BY, FILTER_MEDIA, FILTER_MEDIA_SUCCESS, FILTER_MEDIA_FAILURE, SET_MEDIA_SEARCH_VALUE, SET_GALLERY_UPDATED_TIME,
} from './type';

export function fetchMediaByBrandAndType(brand, mediaType, page, sortBy, concat) {
	return {
		type: FETCH_MEDIA_BY_BRAND_AND_TYPE,
		brand,
		mediaType,
		page,
		sortBy,
		concat,
	};
}

export function fetchMediaByBrandAndTypeSuccess(media, concat) {
	return {
		type: FETCH_MEDIA_BY_BRAND_AND_TYPE_SUCCESS,
		media,
		concat,
	};
}

export function fetchMediaByBrandAndTypeFailure(error) {
	return {
		type: FETCH_MEDIA_BY_BRAND_AND_TYPE_FAILURE,
		error,
	};
}

export function setMediaGalleryPageNo(page) {
	return {
		type: SET_MEDIA_GALLERY_PAGE_NO,
		page,
	};
}

export function sortMediaBy(sortBy) {
	return {
		type: SORT_MEDIA_BY,
		sortBy,
	};
}

export function filterMedia(value, concat) {
	return {
		type: FILTER_MEDIA,
		value,
		concat,
	};
}

export function filterMediaSuccess(media, concat) {
	return {
		type: FILTER_MEDIA_SUCCESS,
		media,
		concat,
	};
}

export function filterMediaFailure(error) {
	return {
		type: FILTER_MEDIA_FAILURE,
		error,
	};
}

export function setMediaSearchValue(value) {
	return {
		type: SET_MEDIA_SEARCH_VALUE,
		value,
	};
}

export function setGalleryUpdatedTime(time) {
	return {
		type: SET_GALLERY_UPDATED_TIME,
		time,
	};
}
