import {
	LOADED_STATE, LOADING_STATE, SET_ERROR_STATE, SET_RESPONSE_ERROR_STATE, SET_CURATED_SPONSORED_DATA, GET_CURATED_SPONSORED_DATA, SET_SELECTED_BRAND_VALUE, SET_CURATED_SET_LIST, OPEN_MODAL, CLOSE_MODAL, SET_STORY_SEARCH_TITLE, SET_STORY_SEARCH_RESULTS, SET_MODAL_LOADING, SET_MODAL_LOADED, DISCARD_CHANGES, SET_STORY_SEARCH_OFFSET, SET_END_OF_STORY_SEARCH_LIST, SET_STORY_SEARCH_SELECT, SET_SAVING, SET_SAVING_RESPONSE, CLEAR_STORY_SEARCH_DATA, RESET_PAGE,
} from './type';

export function setLoadingState() {
	return {
		type: LOADING_STATE,
	};
}

export function setLoadedState() {
	return {
		type: LOADED_STATE,
	};
}

export function setErrorState(message) {
	return {
		type: SET_ERROR_STATE,
		message,
	};
}

export function setResponseErrorState(message) {
	return {
		type: SET_RESPONSE_ERROR_STATE,
		message,
	};
}

export function setResponseData(curatedSetList) {
	return {
		type: SET_CURATED_SPONSORED_DATA,
		curatedSetList,
	};
}

export function getCuratedData(brand = null) {
	return {
		type: GET_CURATED_SPONSORED_DATA,
		brand,
	};
}

export function setSelectedBrandValue(selectedBrandValue, brandList) {
	return {
		type: SET_SELECTED_BRAND_VALUE,
		selectedBrandValue,
		brandList,
	};
}

export function setCuratedSetList(curatedSetList) {
	return {
		type: SET_CURATED_SET_LIST,
		curatedSetList,
	};
}

export function setOpenModal(openModalFor) {
	return {
		type: OPEN_MODAL,
		openModalFor,
	};
}

export function setCloseModal() {
	return {
		type: CLOSE_MODAL,
	};
}

export function setStorySearchTitle(title) {
	return {
		type: SET_STORY_SEARCH_TITLE,
		title,
	};
}

export function setStorySearchResults(storySearchResults) {
	return {
		type: SET_STORY_SEARCH_RESULTS,
		storySearchResults,
	};
}

export function setModalLoading() {
	return {
		type: SET_MODAL_LOADING,
	};
}

export function setModalLoaded() {
	return {
		type: SET_MODAL_LOADED,
	};
}

export function discardChanges() {
	return {
		type: DISCARD_CHANGES,
	};
}

export function setStorySearchOffset(offset) {
	return {
		type: SET_STORY_SEARCH_OFFSET,
		offset,
	};
}

export function setEndOfStorySearchList(value) {
	return {
		type: SET_END_OF_STORY_SEARCH_LIST,
		value,
	};
}

export function setStorySearchSelect(position) {
	return {
		type: SET_STORY_SEARCH_SELECT,
		position,
	};
}

export function setSaving(status) {
	return {
		type: SET_SAVING,
		status,
	};
}

export function setSavingResponseMessage(messageType, message) {
	return {
		type: SET_SAVING_RESPONSE,
		messageType,
		message,
	};
}

export function clearStorySearchData() {
	return {
		type: CLEAR_STORY_SEARCH_DATA,
	};
}

export function resetPage() {
	return {
		type: RESET_PAGE,
	};
}
