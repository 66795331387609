export const CHANGE_PROMOTION_PAYLOAD = 'CHANGE_PROMOTION_PAYLOAD';

export const SEND_PROMOTION = 'SEND_PROMOTION';
export const SEND_PROMOTION_SUCCESS = 'SEND_PROMOTION_SUCCESS';
export const SEND_PROMOTION_ERROR = 'SEND_PROMOTION_ERROR';

export const UPDATE_DISPLAY_MESSAGE = 'UPDATE_DISPLAY_MESSAGE';

export const CREATE_PROMOTION = 'CREATE_PROMOTION';
export const CREATE_PROMOTION_SUCCESS = 'CREATE_PROMOTION_SUCCESS';
export const CREATE_PROMOTION_ERROR = 'CREATE_PROMOTION_ERROR';

export const LOAD_CHATBOTS = 'LOAD_CHATBOTS';
export const LOAD_CHATBOTS_SUCCESS = 'LOAD_CHATBOTS_SUCCESS';
export const LOAD_CHATBOTS_ERROR = 'LOAD_CHATBOTS_ERROR';

export const SET_STORIES_LOADING = 'SET_STORIES_LOADING';
export const SET_STORIES_LOADED = 'SET_STORIES_LOADED';
export const CLEAR_STORY_SEARCH_DATA = 'CLEAR_STORY_SEARCH_DATA';
export const SET_STORY_SEARCH_TITLE = 'SET_STORY_SEARCH_TITLE';
export const SET_STORY_SEARCH_RESULTS = 'SET_STORY_SEARCH_RESULTS';
export const SET_STORY_SEARCH_OFFSET = 'SET_STORY_SEARCH_OFFSET';
export const SET_STORY_SEARCH_LIST_END = 'SET_STORY_SEARCH_LIST_END';
export const SET_STORY_SEARCH_SELECT = 'SET_STORY_SEARCH_SELECT';
