import { Map } from 'immutable';
import {
	ON_COMPANY_TAB_SELECT,
	UPDATE_COMPANY_FORM_DATA,
	UPDATE_COMPANY_METADATA_FORM_DATA,
	UPDATE_BASIC_INFO_INPUT_BLUR,
	UPDATE_BASIC_INFO_ERRORS,
	SET_ALL_BASIC_INFO_ELEMENTS_BLUR,
	CREATE_COMPANY,
	CREATE_COMPANY_SUCCESS,
	CREATE_COMPANY_FAILURE,
	UPDATE_BASIC_INFO,
	UPDATE_BASIC_INFO_SUCCESS,
	UPDATE_BASIC_INFO_FAILURE,
	TRASH_COMPANY,
	TRASH_COMPANY_SUCCESS,
	TRASH_COMPANY_FAILURE,
	UPDATE_FORM_MESSAGE,
	ON_BASIC_INFO_HEADER_TAB_SELECT,
	FETCH_BASIC_INFO,
	FETCH_BASIC_INFO_SUCCESS,
	FETCH_BASIC_INFO_FAILURE,
	FETCH_ACQUISITIONS,
	FETCH_ACQUISITIONS_SUCCESS,
	FETCH_ACQUISITIONS_FAILURE,
	FETCH_COMPANY_FINANCE,
	FETCH_COMPANY_FINANCE_SUCCESS,
	FETCH_COMPANY_FINANCE_FAILURE,
	FETCH_COMPANY_PEOPLE,
	FETCH_COMPANY_PEOPLE_SUCCESS,
	FETCH_COMPANY_PEOPLE_FAILURE,
	FETCH_FUNDING,
	FETCH_FUNDING_SUCCESS,
	FETCH_FUNDING_FAILURE,
	FETCH_COMPANY_LOCATIONS,
	FETCH_COMPANY_LOCATIONS_SUCCESS,
	FETCH_COMPANY_LOCATIONS_FAILURE,
	FETCH_COMPANY_PRODUCTS,
	FETCH_COMPANY_PRODUCTS_SUCCESS,
	FETCH_COMPANY_PRODUCTS_FAILURE,
	CLEAR_COMPANY_EDITOR_DATA,
	ADD_COMPANY_AWARD,
	REMOVE_COMPANY_AWARD,
	FETCH_AUDIENCE_FAILURE,
	FETCH_MILESTONES_FAILURE,
	FETCH_AUDIENCE,
	FETCH_MILESTONES,
	FETCH_AUDIENCE_SUCCESS,
	FETCH_MILESTONES_SUCCESS,
	FETCH_CURATED_LIST_TYPES_SUCCESS,
	FETCH_PROFILE_UPDATE_NOTES_SUCCESS,
	SET_TOP_PICKS,
	CLEAR_TOP_PICKS,
} from './type';

import {
	SAVING_MESSAGE,
	SAVING_SUCCESS_MESSAGE,
	SAVING_FAILURE_MESSAGE,
} from './constants';

const awardObj = {
	id: '',
	name: '',
	label: '',
	value: '',
	rank: '',
	description: '',
};

const initialState = Map({
	selectedTab: 'basic',
	basicInfoHeaderSelectedTab: 'current',
	isBasicInfoUpdated: false,
	basicInfo: null,
	companyId: null,
	companyFormData: {
		name: null,
		legalName: null,
		slug: null,
		subtitle: null,
		description: null,
		vision: null,
		website: null,
		employeeCount: null,
		foundingDate: null,
		foundingDateFormat: 'YEAR',
		closingDateFormat: 'YEAR',
		closingDate: null,
		headquarter: false,
		logo: {
			url: null,
			file: null,
		},
		socialLinks: {
			facebook: null,
			twitter: null,
			linkedin: null,
			wikipedia: null,
		},
		fundingStatus: null,
	},
	status: 'INREVIEW',
	companyMetadataFormData: {
		sectors: null,
		awards: [{ ...awardObj }],
		subsidiaries: null,
		revenueStreams: null,
		businessModels: null,
		ios: null,
		android: null,
		individualInvestors: null,
		organisationInvestors: null,
		disallowConnect: false,
		editorsPickTitle: null,
		editorsPickSubtitle: null,
		editorsPickStartTime: null,
		editorsPickEndTime: null,
	},
	acquisitions: [],
	funding: [],
	companyFinance: [],
	companyLocations: [],
	companyProducts: [],
	companyPeople: [],

	updatedCompanyFinance: [],
	updatedCompanyPeople: [],
	updatedCompanyLocations: [],
	updatedCompanyProducts: [],
	updatedAcquisitions: [],
	updatedFunding: [],

	isAcquisitionUpdated: false,
	isCompanyPeopleUpdated: false,
	isFinanceUpdated: false,
	isCompanyLocationsUpdated: false,
	isCompanyProductsUpdated: false,
	isFundingUpdated: false,
	profileUpdateNotes: null,
	profileUpdateCount: 0,
	basicInfoBlurredElements: {
		name: false,
		slug: false,
		description: false,
		subtitle: false,
		editorsPickTitle: false,
		editorsPickSubtitle: false,
		editorsPickStartTime: false,
		editorsPickEndTime: false,
	},
	basicInfoErrors: {
		name: false,
		slug: false,
		description: false,
		subtitle: false,
		editorsPickTitle: false,
		editorsPickSubtitle: false,
		editorsPickStartTime: false,
		editorsPickEndTime: false,
	},
	formMessage: null,
	disableActions: false,
	addedToTopPicks: false,
});

function companyEditorReducer(state = initialState, action) {
	switch (action.type) {
	case ON_COMPANY_TAB_SELECT:
		return state
			.set('selectedTab', action.tab);

	case ON_BASIC_INFO_HEADER_TAB_SELECT:
		return state
			.set('basicInfoHeaderSelectedTab', action.tab);

	case UPDATE_COMPANY_FORM_DATA: {
		const companyFormData = JSON.parse(JSON.stringify(state.get('companyFormData')));
		const { changedData } = action;
		if (['facebook', 'twitter', 'linkedin', 'wikipedia'].includes(changedData.name)) {
			if (!companyFormData.socialLinks) companyFormData.socialLinks = {};
			companyFormData.socialLinks[changedData.name] = changedData.value;
		} else {
			companyFormData[changedData.name] = changedData.value;
		}
		return state
			.set('companyFormData', companyFormData);
	}

	case UPDATE_COMPANY_METADATA_FORM_DATA: {
		const companyMetadataFormData = JSON.parse(JSON.stringify(state.get('companyMetadataFormData')));
		const { changedData } = action;
		if (changedData.name === 'awards') {
			companyMetadataFormData.awards[action.index] = { ...companyMetadataFormData.awards[action.index], ...changedData.value };
		} else {
			companyMetadataFormData[changedData.name] = changedData.value;
		}
		return state
			.set('companyMetadataFormData', companyMetadataFormData);
	}

	case UPDATE_BASIC_INFO_INPUT_BLUR: {
		const blurredElements = { ...state.get('basicInfoBlurredElements') };
		blurredElements[action.element] = true;
		return state
			.set('basicInfoBlurredElements', blurredElements);
	}

	case UPDATE_BASIC_INFO_ERRORS:
		return state
			.set('basicInfoErrors', action.errors);

	case SET_ALL_BASIC_INFO_ELEMENTS_BLUR: {
		const blurredElements = {
			name: true,
			slug: true,
			description: true,
			subtitle: true,
		};
		return state
			.set('basicInfoBlurredElements', blurredElements);
	}
	case CREATE_COMPANY:
	case UPDATE_BASIC_INFO:
	case TRASH_COMPANY:
		return state
			.set('formMessage', SAVING_MESSAGE);
	case CREATE_COMPANY_SUCCESS:
	case UPDATE_BASIC_INFO_SUCCESS:
	case TRASH_COMPANY_SUCCESS:
		return state
			.set('formMessage', SAVING_SUCCESS_MESSAGE);
	case CREATE_COMPANY_FAILURE:
	case UPDATE_BASIC_INFO_FAILURE:
	case TRASH_COMPANY_FAILURE:
		return state
			.set('formMessage', SAVING_FAILURE_MESSAGE);
	case UPDATE_FORM_MESSAGE:
		return state
			.set('formMessage', action.message);

	case FETCH_BASIC_INFO:
		return state
			.set('loading', true);
	case FETCH_BASIC_INFO_SUCCESS: {
		const data = action.basicInfo;
		let companyId;
		let status;
		let companyFormData;
		let companyMetadataFormData;
		let basicInfo;
		let isBasicInfoUpdated = false;
		if (data.isUpdated) {
			companyId = data.current.company.id;
			({ status } = data.updated.company);
			companyFormData = data.updated.company;
			companyFormData.headquarter = data.updated.company.headquarter;
			companyMetadataFormData = data.updated.metadata;
			basicInfo = data.current;
			isBasicInfoUpdated = true;
		} else {
			companyId = data.company.id;
			({ status } = data.company);
			companyFormData = data.company;
			companyMetadataFormData = data.metadata;
			basicInfo = data;
		}

		return state
			.set('loading', false)
			.set('companyFormData', companyFormData)
			.set('basicInfo', basicInfo)
			.set('companyMetadataFormData', companyMetadataFormData)
			.set('status', status)
			.set('companyId', companyId)
			.set('isBasicInfoUpdated', isBasicInfoUpdated);
	}

	case FETCH_BASIC_INFO_FAILURE:
		return state
			.set('loading', false);

	case FETCH_ACQUISITIONS:
		return state
			.set('loading', true);

	case FETCH_ACQUISITIONS_SUCCESS: {
		const data = action.acquisitions;
		let acquisitions;
		let updatedAcquisitions;
		let isAcquisitionUpdated = false;
		if (data.isUpdated) {
			updatedAcquisitions = data.updated;
			acquisitions = data.current;
			isAcquisitionUpdated = true;
		} else {
			updatedAcquisitions = data;
			acquisitions = data;
		}
		return state
			.set('loading', false)
			.set('updatedAcquisitions', updatedAcquisitions)
			.set('acquisitions', acquisitions)
			.set('isAcquisitionUpdated', isAcquisitionUpdated);
	}

	case FETCH_ACQUISITIONS_FAILURE:
		return state
			.set('loading', false);

	case FETCH_COMPANY_FINANCE:
		return state
			.set('loading', true);

	case FETCH_COMPANY_FINANCE_SUCCESS: {
		const data = action.companyFinance;
		let companyFinance;
		let updatedCompanyFinance;
		let isFinanceUpdated = false;
		if (data.isUpdated) {
			updatedCompanyFinance = data.updated;
			companyFinance = data.current;
			isFinanceUpdated = true;
		} else {
			updatedCompanyFinance = data;
			companyFinance = data;
		}
		return state
			.set('loading', false)
			.set('updatedCompanyFinance', updatedCompanyFinance)
			.set('companyFinance', companyFinance)
			.set('isFinanceUpdated', isFinanceUpdated);
	}

	case FETCH_COMPANY_FINANCE_FAILURE:
		return state
			.set('loading', false);

	case FETCH_COMPANY_PEOPLE:
		return state
			.set('loading', true);

	case FETCH_COMPANY_PEOPLE_SUCCESS: {
		const data = action.companyPeople;
		let companyPeople;
		let updatedCompanyPeople;
		let isCompanyPeopleUpdated = false;
		if (data.isUpdated) {
			updatedCompanyPeople = data.updated;
			companyPeople = data.current;
			isCompanyPeopleUpdated = true;
		} else {
			updatedCompanyPeople = data;
			companyPeople = data;
		}
		return state
			.set('loading', false)
			.set('updatedCompanyPeople', updatedCompanyPeople)
			.set('companyPeople', companyPeople)
			.set('isCompanyPeopleUpdated', isCompanyPeopleUpdated);
	}

	case FETCH_COMPANY_PEOPLE_FAILURE:
		return state
			.set('loading', false);

	case FETCH_FUNDING:
		return state
			.set('loading', true);

	case FETCH_FUNDING_SUCCESS: {
		const data = action.funding;
		let funding;
		let updatedFunding;
		let isFundingUpdated = false;
		if (data.isUpdated) {
			updatedFunding = data.updated;
			funding = data.current;
			isFundingUpdated = true;
		} else {
			updatedFunding = data;
			funding = data;
		}
		return state
			.set('loading', false)
			.set('updatedFunding', updatedFunding)
			.set('funding', funding)
			.set('isFundingUpdated', isFundingUpdated);
	}

	case FETCH_FUNDING_FAILURE:
		return state
			.set('loading', false);

	case FETCH_COMPANY_LOCATIONS:
		return state
			.set('loading', true);

	case FETCH_COMPANY_LOCATIONS_SUCCESS: {
		const data = action.companyLocations;
		let companyLocations;
		let updatedCompanyLocations;
		let isCompanyLocationsUpdated = false;
		if (data.isUpdated) {
			updatedCompanyLocations = data.updated;
			companyLocations = data.current;
			isCompanyLocationsUpdated = true;
		} else {
			updatedCompanyLocations = data;
			companyLocations = data;
		}
		return state
			.set('loading', false)
			.set('updatedCompanyLocations', updatedCompanyLocations)
			.set('companyLocations', companyLocations)
			.set('isCompanyLocationsUpdated', isCompanyLocationsUpdated);
	}

	case FETCH_COMPANY_LOCATIONS_FAILURE:
		return state
			.set('loading', false);

	case FETCH_AUDIENCE:
	case FETCH_MILESTONES:
	case FETCH_COMPANY_PRODUCTS:
		return state
			.set('loading', true);

	case FETCH_COMPANY_PRODUCTS_SUCCESS: {
		const data = action.companyProducts;
		let companyProducts;
		let updatedCompanyProducts;
		let isCompanyProductsUpdated = false;
		if (data.isUpdated) {
			updatedCompanyProducts = data.updated;
			companyProducts = data.current;
			isCompanyProductsUpdated = true;
		} else {
			updatedCompanyProducts = data;
			companyProducts = data;
		}
		return state
			.set('loading', false)
			.set('updatedCompanyProducts', updatedCompanyProducts)
			.set('companyProducts', companyProducts)
			.set('isCompanyProductsUpdated', isCompanyProductsUpdated);
	}
	case FETCH_AUDIENCE_FAILURE:
	case FETCH_MILESTONES_FAILURE:
	case FETCH_COMPANY_PRODUCTS_FAILURE:
		return state
			.set('loading', false);
	case CLEAR_COMPANY_EDITOR_DATA:
		return initialState;
	case ADD_COMPANY_AWARD: {
		const newState = { ...state.get('companyMetadataFormData') };
		newState.awards.push(awardObj);
		return state
			.set('companyMetadataFormData', newState);
	}
	case REMOVE_COMPANY_AWARD: {
		const newState = { ...state.get('companyMetadataFormData') };
		newState.awards.splice(action.index, 1);
		return state
			.set('companyMetadataFormData', newState);
	}
	case FETCH_AUDIENCE_SUCCESS: {
		const data = action.companyAudience;
		let companyAudience;
		let updatedCompanyAudience;
		let isCompanyAudienceUpdated = false;
		if (data.isUpdated) {
			updatedCompanyAudience = data.updated;
			companyAudience = data.current;
			isCompanyAudienceUpdated = true;
		} else {
			updatedCompanyAudience = data;
			companyAudience = data;
		}
		return state
			.set('loading', false)
			.set('updatedCompanyAudience', updatedCompanyAudience)
			.set('companyAudience', companyAudience)
			.set('isCompanyAudienceUpdated', isCompanyAudienceUpdated);
	}
	case FETCH_MILESTONES_SUCCESS: {
		const data = action.milestones;
		let milestones;
		let updatedMilestones;
		let isMilestoneUpdated = false;
		if (data.isUpdated) {
			updatedMilestones = data.updated;
			milestones = data.current;
			isMilestoneUpdated = true;
		} else {
			updatedMilestones = data;
			milestones = data;
		}
		return state
			.set('loading', false)
			.set('updatedMilestones', updatedMilestones)
			.set('milestones', milestones)
			.set('isMilestoneUpdated', isMilestoneUpdated);
	}
	case FETCH_CURATED_LIST_TYPES_SUCCESS: {
		return state
			.set('revenueStreamTypes', action.list.revenueStreamTypes)
			.set('geolocationTypes', action.list.geolocationTypes)
			.set('employeeSizeTypes', action.list.employeeSizeTypes)
			.set('milestoneTypes', action.list.milestoneTypes)
			.set('incomeTypes', action.list.incomeTypes)
			.set('businessSizeTypes', action.list.businessSizeTypes)
			.set('sectorTypes', action.list.sectorTypes)
			.set('productSectorTypes', action.list.productSectorTypes)
			.set('ageRangeTypes', action.list.ageRangeTypes)
			.set('businessModelTypes', action.list.businessModelTypes);
	}
	case FETCH_PROFILE_UPDATE_NOTES_SUCCESS: {
		let data = state.get('profileUpdateNotes') ? Object.assign('profileUpdateNotes') : null;
		let count = state.get('profileUpdateCount');
		if (action.updates && action.updates.data) {
			({ data } = action.updates);
		}
		if (action.updates && action.updates.count) {
			({ count } = action.updates);
		}
		return state
			.set('profileUpdateNotes', data)
			.set('profileUpdateCount', count);
	}
	case CLEAR_TOP_PICKS: {
		const companyMetadataFormData = JSON.parse(JSON.stringify(state.get('companyMetadataFormData')));
		companyMetadataFormData.editorsPickTitle = null;
		companyMetadataFormData.editorsPickSubtitle = null;
		companyMetadataFormData.editorsPickStartTime = null;
		companyMetadataFormData.editorsPickEndTime = null;
		return state
			.set('companyMetadataFormData', companyMetadataFormData);
	}
	case SET_TOP_PICKS: {
		return state
			.set('addedToTopPicks', action.value);
	}

	default:
		return state;
	}
}
export default companyEditorReducer;
