import { createStore, applyMiddleware, compose } from 'redux';
import { Map } from 'immutable';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import * as Sentry from '@sentry/react';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const middlewares = [
	sagaMiddleware,
	routerMiddleware(history),
];

const enhancers = [
	applyMiddleware(...middlewares),
	sentryReduxEnhancer,
];

export default function configureStore() {
	const store = createStore(
		rootReducer(history),
		Map({}),
		composeEnhancers(...enhancers),
	);

	sagaMiddleware.run(rootSaga);
	return store;
}

// If Redux DevTools Extension is installed use it, otherwise use Redux compose
/* eslint-disable no-underscore-dangle */
const composeEnhancers = process.env.NODE_ENV !== 'production'
	&& typeof window === 'object'
	&& window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
	? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
		shouldHotReload: false,
	})
	: compose;
/* eslint-enable */
