export const GET_PROFILE_DATA = '/api/v2/rw/talent/';
export const GET_CITY_LIST = '/api/v2/cities/key';
export const FETCH_PROFILE_DATA = 'FETCH_PROFILE_DATA';
export const FETCH_PROFILE_DATA_SUCCESS = 'FETCH_PROFILE_DATA_SUCCESS';
export const FETCH_PROFILE_DATA_FAILURE = 'FETCH_PROFILE_DATA_FAILURE';
export const UPDATE_TALENT_BASE_URL = '/api/v2/rw/talent';
export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
export const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
export const UPDATE_ACTIONS_ERROR = 'UPDATE_ACTIONS_ERROR';
export const UPDATE_ACTIONS_SUCCESS = 'UPDATE_ACTIONS_SUCCESS';
export const UPDATE_ACTIONS_LOADER = 'UPDATE_ACTIONS_LOADER';
export const SET_ACTIONS_MESSAGE = 'SET_ACTIONS_MESSAGE';
