/* eslint-disable no-useless-escape */
const generateCompanySlug = (name) => {
	const slug = name.toLowerCase().replace(/[^a-zA-Z0-9 -]/g, '').replace(/ /g, '-');
	return slug;
};

const validateCompanyFormData = (company, metadata, addedToTopPicks) => {
	try {
		return {
			name: !company.name || company.name.length < 1,
			slug: !company.slug || company.slug.length < 1 || company.slug.match(/[A-Z!@#$%^&*(),.?":{}|/<>_';`~\[\]\\+=\ ]/g),
			editorsPickTitle: addedToTopPicks && (!metadata.editorsPickTitle || metadata.editorsPickTitle.length < 1),
			editorsPickSubtitle: addedToTopPicks && (!metadata.editorsPickSubtitle || metadata.editorsPickSubtitle.length < 1),
			editorsPickStartTime: addedToTopPicks && (!metadata.editorsPickStartTime || metadata.editorsPickStartTime.length < 1),
			editorsPickEndTime: addedToTopPicks && (!metadata.editorsPickEndTime || metadata.editorsPickEndTime.length < 1),
			// description: !company.description || company.description.length < 100
			// || company.description.length > 2000,
			// subtitle: !company.subtitle || company.subtitle.length < 30 || company.subtitle.length > 250,
		};
	} catch (err) {
		console.log('Company validation function error: ', err);
	}
};

const getBasicInfoTabErrors = (companyData, elementsTouched, metadata, addedToTopPicks) => {
	try {
		const validationErrors = validateCompanyFormData(companyData, metadata, addedToTopPicks);
		const errors = Object.keys(validationErrors).reduce((acc, curr) => {
			if (validationErrors[curr] && elementsTouched[curr]) {
				acc[curr] = true;
			} else if (['editorsPickTitle', 'editorsPickSubtitle', 'editorsPickStartTime', 'editorsPickEndTime'].includes(curr)) {
				acc[curr] = validationErrors[curr];
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		return errors;
	} catch (err) {
		console.log('Basic information tab error: ', err);
	}
};

const getCompanyRequestBody = (company, metadata, status) => {
	const formData = new FormData();
	formData.append('name', company.name);
	formData.append('legalName', company.legalName);
	formData.append('slug', company.slug);
	formData.append('subtitle', company.subtitle);
	formData.append('description', company.description);
	formData.append('vision', company.vision);
	formData.append('website', company.website);
	formData.append('employeeCount', company.employeeCount);
	formData.append('employeeSize', JSON.stringify(company.employeeSize));
	formData.append('foundingDate', company.foundingDate);
	formData.append('foundingDateFormat', company.foundingDateFormat);
	formData.append('closingDate', company.closingDate);
	formData.append('closingDateFormat', company.closingDateFormat);
	formData.append('fundingStatus', company.fundingStatus);
	if (company.logo && company.logo.file) {
		formData.append('imageFile', company.logo.file);
		formData.append('logo', JSON.stringify({ width: company.logo.width, height: company.logo.height }));
	} else {
		formData.append('logo', JSON.stringify(company.logo));
	}
	formData.append('socialLinks', JSON.stringify(company.socialLinks));
	formData.append('status', status);
	const filteredAwards = metadata.awards && metadata.awards.length > 0 ? metadata.awards.filter((award) => award.id) : [];
	metadata.awards = filteredAwards;
	formData.append('metadata', JSON.stringify(metadata));
	return formData;
};

const isCompanyPublishedOrVerified = (status) => {
	if (['PUBLISHED', 'VERIFIED'].includes(status)) {
		return true;
	}
	return false;
};

function getCurrencyUnitValue(amount) {
	const str = amount.toString();
	const { length } = str;
	let value;
	let unit;
	if (length <= 6) {
		// thousand
		value = amount / 1000;
		unit = 'THOUSAND';
	} else if (length >= 7 && length <= 9) {
		// million
		value = amount / 1000000;
		unit = 'MILLION';
	} else if (length >= 10 && length <= 12) {
		// billion
		value = amount / 1000000000;
		unit = 'BILLION';
	} else {
		// trillion
		value = amount / 1000000000000;
		unit = 'TRILLION';
	}
	return {
		value,
		unit,
	};
}

export {
	generateCompanySlug,
	validateCompanyFormData,
	getBasicInfoTabErrors,
	getCompanyRequestBody,
	isCompanyPublishedOrVerified,
	getCurrencyUnitValue,
};
