import React from 'react';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css';
import { checkType } from '../../utils/utility_function';

const toolbarOptions = ['bold', 'italic', 'underline', 'link'];

class MinimalQuill extends React.PureComponent {
	componentDidMount() {
		this.attachQuillRefs();
	}

	componentDidUpdate() {
		this.attachQuillRefs();
	}

	attachQuillRefs = () => {
		if (typeof this.reactQuillRef.getEditor !== 'function') return;
		this.quillRef = this.reactQuillRef.getEditor();
		document.quill = this.reactQuillRef.getEditor();
	};

	render() {
		return (<ReactQuill
			ref={(el) => {
				this.reactQuillRef = el;
			}}
			value={this.props.value ? this.props.value : ''}
			onChange={(value) => {
				if (checkType(this.quillRef, 'object') && ('getText' in this.quillRef)) {
					this.props.callback(value, this.quillRef.getText());
				} else {
					this.props.callback(value, '');
				}
			}}
			modules={{
				toolbar: toolbarOptions,
			}}
			style={{
				height: this.props.height ? this.props.height : '100px',
				width: this.props.width ? this.props.width : '100%',
			}}
			placeholder={this.props.placeholder}
		/>);
	}
}

MinimalQuill.propTypes = {
	value: PropTypes.string,
	callback: PropTypes.func,
	height: PropTypes.string,
	placeholder: PropTypes.string,
	width: PropTypes.number,
};

export default MinimalQuill;
