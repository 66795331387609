import {
	SELECT_CATEGORY, ADD_CATEGORY, CATEGORY_ELEMENT_ON_CHANGE, WRITE_CATEGORY_SLUG, CREATE_CATEGORY, CREATE_CATEGORY_SUCCESS, CREATE_CATEGORY_ERROR, UPDATE_CATEGORY, UPDATE_CATEGORY_SUCCESS, UPDATE_CATEGORY_ERROR, DELETE_CATEGORY, DELETE_CATEGORY_SUCCESS, DELETE_CATEGORY_ERROR, REPLACE_CATEGORY, REPLACE_CATEGORY_SUCCESS, REPLACE_CATEGORY_ERROR, CATEGORY_REDIRECTION_ERROR, UNMOUNT_CATEGORY_FORM_MESSAGE, SET_CATEGORY_SLUG_UNIQUE_VALUE, SHOW_CATEGORY_LOADING_MESSAGE,
} from './type';

export function categorySelect(selectedCategory) {
	return {
		type: SELECT_CATEGORY,
		selectedCategory,
	};
}

export function initiateAddCategory() {
	return {
		type: ADD_CATEGORY,
	};
}

export function onCategoryFormElementChange(elementData) {
	return {
		type: CATEGORY_ELEMENT_ON_CHANGE,
		elementData,
	};
}

export function writeCategorySlug() {
	return {
		type: WRITE_CATEGORY_SLUG,
	};
}

export function createCategory(category) {
	return {
		type: CREATE_CATEGORY,
		category,
	};
}

export function createCategorySuccess(successResponse) {
	return {
		type: CREATE_CATEGORY_SUCCESS,
		successResponse,
	};
}

export function createCategoryFailure(failureResponse) {
	return {
		type: CREATE_CATEGORY_ERROR,
		failureResponse,
	};
}

export function updateCategory(id, updatedData) {
	return {
		type: UPDATE_CATEGORY,
		id,
		updatedData,
	};
}

export function updateCategorySuccess(successResponse) {
	return {
		type: UPDATE_CATEGORY_SUCCESS,
		successResponse,
	};
}

export function updateCategoryFailure(failureResponse) {
	return {
		type: UPDATE_CATEGORY_ERROR,
		failureResponse,
	};
}

export function deleteCategory(id, locale) {
	return {
		type: DELETE_CATEGORY,
		id,
		locale,
	};
}

export function deleteCategorySuccess(successResponse) {
	return {
		type: DELETE_CATEGORY_SUCCESS,
		successResponse,
	};
}

export function deleteCategoryFailure(failureResponse) {
	return {
		type: DELETE_CATEGORY_ERROR,
		failureResponse,
	};
}

export function replaceCategory(key, existing, replacement, locale, redirection) {
	return {
		type: REPLACE_CATEGORY,
		existing,
		replacement,
		locale,
		key,
		redirection,
	};
}

export function replaceCategorySuccess(successResponse) {
	return {
		type: REPLACE_CATEGORY_SUCCESS,
		successResponse,
	};
}

export function replaceCategoryFailure(failureResponse) {
	return {
		type: REPLACE_CATEGORY_ERROR,
		failureResponse,
	};
}

export function categoryRedirectionFailure(failureResponse) {
	return {
		type: CATEGORY_REDIRECTION_ERROR,
		failureResponse,
	};
}

export function unmountCategoryFormMessage() {
	return {
		type: UNMOUNT_CATEGORY_FORM_MESSAGE,
	};
}

export function setCategorySlugUniqueValue(value) {
	return {
		type: SET_CATEGORY_SLUG_UNIQUE_VALUE,
		value,
	};
}

export function showLoading() {
	return {
		type: SHOW_CATEGORY_LOADING_MESSAGE,
	};
}
