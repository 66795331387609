import { defineMessages } from 'react-intl';

export default defineMessages({
	categoriesCaption: {
		id: 'creatorstudio.components.HeadSection.categoryCaption',
		defaultMessage: 'Categories are limited to brands and do not move across brands. This also means two brands can have same category. Ideally they should not but they can.',
	},
	tagsCaption: {
		id: 'creatorstudio.components.HeadSection.tagCaption',
		defaultMessage: 'Tags are limited to brands and do not move across brands. This also means two brands can have same tag. Ideally they should not but they can.',
	},
	brandsCaption: {
		id: 'creatorstudio.components.HeadSection.brandCaption',
		defaultMessage: 'Brands are the top level containers for all individual properties. For eg: HerStory, SocialStory, MyStory, etc',
	},
	configCaption: {
		id: 'creatorstudio.components.HeadSection.configCaption',
		defaultMessage: 'This section allows you to configure some parts of the website. Be advised these changes will directly affect the main website.',
	},
	brandSpotlightCaption: {
		id: 'creatorstudio.components.HeadSection.brandSpotlightCaption',
		defaultMessage: 'BrandSpotlight are our partners for whom we write sponsored content. The badge which is shown at the top of the articles on a sponsored content is actually shown according to the data which is added here',
	},
	tagSelectPlaceholder: {
		id: 'creatorstudio.components.HeadSection.tagSelectPlaceholder',
		defaultMessage: 'Search tags by name ...',
	},
	categorySelectPlaceholder: {
		id: 'creatorstudio.components.HeadSection.categorySelectPlaceholder',
		defaultMessage: 'Search categories by name ...',
	},
	brandSpotlightSelectPlaceholder: {
		id: 'creatorstudio.components.HeadSection.brandSpotlightSelectPlaceholder',
		defaultMessage: 'Search brand spotlights by name ...',
	},
	rssFeedCreatorCaption: {
		id: 'creatorstudio.components.HeadSection.rssFeedCreatorCaption',
		defaultMessage: 'This section allows you to add Rss Feed source to watch and monitor their content',
	},
	verticalCaption: {
		id: 'creatorstudio.components.HeadSection.verticalCaption',
		defaultMessage: 'A vertical is a segment within an industry that is made up from similar businesses and customers. Manage all verticals from here.',
	},
	verticalSelectPlaceholder: {
		id: 'creatorstudio.components.HeadSection.verticalSelectPlaceholder',
		defaultMessage: 'Search verticals by name ...',
	},
	awardCaption: {
		id: 'creatorstudio.components.HeadSection.awardCaption',
		defaultMessage: 'A award is a prize or other mark of recognition given in honour of an achievement. Manage all awards from here.',
	},
	awardSelectPlaceholder: {
		id: 'creatorstudio.components.HeadSection.awardSelectPlaceholder',
		defaultMessage: 'Search awards by name ...',
	},
	peopleCaption: {
		id: 'creatorstudio.components.HeadSection.peopleCaption',
		defaultMessage: 'Manage all influencers from here.',
	},
	peopleSelectPlaceholder: {
		id: 'creatorstudio.components.HeadSection.peopleSelectPlaceholder',
		defaultMessage: 'Search person by name ...',
	},
	cityCaption: {
		id: 'creatorstudio.components.HeadSection.citiesCaption',
		defaultMessage: 'Manage all cities from here',
	},
	citySelectPlaceholder: {
		id: 'creatorstudio.components.HeadSection.citiesSelectPlaceholder',
		defaultMessage: 'Search city by name ...',
	},

});
