import { Map } from 'immutable';
import {
	SET_REDIRECTION_FORM_MESSAGE, REDIRECTION_ELEMENT_ON_CHANGE, CLEAR_REDIRECTION_FORM, FETCH_ALL_REDIRECTIONS_SUCCESS, SET_REDIRECTION_TABLE_PAGE, FILTER_REDIRECTION_TABLE, SELECT_REDIRECTION, FETCH_ALL_REDIRECTIONS, FILTER_REDIRECTION_TABLE_SUCCESS,
} from './type';

const initialState = Map({
	loading: false,
	error: false,
	allRedirections: null,
	formData: {
		sourceUrl: '',
		destinationUrl: '',
		reason: '',
	},
	selectedRedirection: null,
	searchValue: null,
	editRedirection: false,
	formMessage: null,
	tablePage: 1,
});

function redirectionsReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_ALL_REDIRECTIONS:
		return state
			.set('loading', true);
	case FETCH_ALL_REDIRECTIONS_SUCCESS: {
		const obj = {
			sourceUrl: '',
			destinationUrl: '',
			reason: '',
		};

		return state
			.set('error', false)
			.set('loading', false)
			.set('selectedRedirection', null)
			.set('editRedirection', false)
			.set('redirections', action.allRedirections)
			.set('formData', obj);
	}
	case SELECT_REDIRECTION: {
		const obj = {
			sourceUrl: action.redirection.fromUrl,
			destinationUrl: action.redirection.toUrl,
			reason: action.redirection.reason,
		};
		return state
			.set('formData', { ...obj })
			.set('selectedRedirection', action.redirection)
			.set('editRedirection', true);
	}

	case SET_REDIRECTION_FORM_MESSAGE:
		return state
			.set('formMessage', action.message);
	case REDIRECTION_ELEMENT_ON_CHANGE: {
		const newState = { ...state.get('formData') };
		newState[action.element.name] = action.element.value;
		return state
			.set('formData', newState);
	}
	case CLEAR_REDIRECTION_FORM: {
		const obj = {
			sourceUrl: '',
			destinationUrl: '',
			reason: '',
		};
		return state
			.set('selectedRedirection', null)
			.set('editRedirection', false)
			.set('formData', obj);
	}
	case SET_REDIRECTION_TABLE_PAGE:
		return state
			.set('tablePage', action.page);
	case FILTER_REDIRECTION_TABLE: {
		return state
			.set('searchValue', action.filter)
			.set('loading', true);
	}
	case FILTER_REDIRECTION_TABLE_SUCCESS:
		return state
			.set('redirections', action.redirections)
			.set('loading', false);

	default:
		return state;
	}
}

export default redirectionsReducer;
