import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import T from 'prop-types';

import { Row, Col } from '../../components/Responsive';
import { WrapperContainer, Button } from '../../components/Styles';
import {
	makeSelectListProfiles, makeSelectProfileListLoading, makeSelectProfileListLimit, makeSelectProfileListOffset, makeSelectFetchedProfiles, makeSelectProfileListSearch, makeSelectProfileListSearchType, makeSelectProfileListSearchValue, makeSelectProfileListSortValue,
} from './selectors';
import {
	fetchDeskProfiles, fetchNextPage, runProfileListSearch, disableListSearchFlag, runProfileListSort, disableListSearch, setInitialState,
} from './actions';
import ListHeader from './ListHeader';
import ListItem from './ListItem';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';
import ProfileDeskHeader from '../../components/ProfileDeskHeader/ProfileDeskHeader';

class ProfileList extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			sortedBy: {
				name: null,
				status: null,
				createdBy: null,
				updatedAt: 'desc',
			},
		};
	}

	componentDidMount() {
		const {
			pageTitle, getProfiles, limit, offset, match,
		} = this.props;
		getProfiles(limit, offset, 'updatedAt', 'DESC', null, match.params.profile);
		pageTitle(`${match.params.profile}List`);
	}

	componentWillUnmount() {
		this.props.setInitialState();
	}

	renderProfiles = () => {
		const { profiles, match } = this.props;
		if (profiles && profiles.length > 0) {
			return profiles.map((profile) => <ListItem
				key={profile.id}
				profile={profile}
				profileType={match.params.profile}
			/>);
		}

		return (
			<div className="w_100 text-center">
				<h2 className="light-text">No profiles to display</h2>
			</div>
		);
	}

	sortBy = (field) => {
		const {
			disableSearchFlag, initiateSort, getProfiles, searchValue, match,
		} = this.props;
		disableSearchFlag();

		const sortedBy = {
			name: null, status: null, createdBy: null, updatedAt: null,
		};
		if (this.state.sortedBy[field]) {
			if (this.state.sortedBy[field] === 'asc') sortedBy[field] = 'desc';
			else sortedBy[field] = 'asc';
		} else sortedBy[field] = 'desc';
		this.setState({ sortedBy });
		initiateSort(field);
		getProfiles(10, 0, field, sortedBy[field], searchValue, match.params.profile);
	}

	onShowMoreProfiles() {
		const {
			limit, offset, getProfiles, fetchNextPage, searchFlag, initiateListSearch, searchType, searchValue, sortValue, match,
		} = this.props;
		fetchNextPage(limit);
		if (searchFlag) {
			initiateListSearch(searchType, searchValue, limit, offset + 10, match.params.profile);
		} else {
			getProfiles(limit, offset + 10, sortValue, this.state.sortedBy[sortValue], searchValue, match.params.profile);
		}
	}

	runFilter = (searchType, searchValue, profileType = this.props.match.params.value) => {
		const { initiateListSearch, limit, offset } = this.props;
		if (this.props.searchValue) {
			if (this.props.searchValue !== searchValue) initiateListSearch(searchType, searchValue, limit, 0, profileType);
		} else initiateListSearch(searchType, searchValue, 10, offset, profileType);
	}

	onSearchClear = () => {
		const { getProfiles, cancelSearch, match } = this.props;
		cancelSearch();
		getProfiles(10, 0, 'updatedAt', 'DESC', null, match.params.profile);
	}

	render() {
		const {
			profiles, loading, limit, offset, fetchedProfiles,
		} = this.props;
		return (
			<div>
				<WrapperContainer className="mt-5">
					<ProfileDeskHeader
						listProfiles
						search={this.props.searchFlag}
						cancelSearch={this.onSearchClear}
						filterProfiles={this.runFilter}
						profile={this.props.match.params.profile}
					/>
					<ListHeader
						sortBy={this.sortBy}
						sortedBy={this.state.sortedBy}
					/>
					{this.renderProfiles()}
					<Row>
						{
							loading && limit > 10
								? <LoadingIcon />
								: null
						}
					</Row>
					{
						loading || fetchedProfiles.length < 1 || (profiles.length - offset < limit)
							? null
							: <Row center="xs">
								<Col xs={6} md={2}>
									<Button
										className="mt-4"
										primary
										btn_rounded
										w_100
										id="more-profiles-btn"
										onClick={() => this.onShowMoreProfiles()}
									>
										Show More Profiles
									</Button>
								</Col>
							</Row>
					}
				</WrapperContainer>

			</div>
		);
	}
}

export function mapDispatchToProps(dispatch) {
	return {
		getProfiles: (limit, offset, sortBy, orderBy, searchValue, profile) => dispatch(fetchDeskProfiles(limit, offset, sortBy, orderBy, searchValue, profile)),
		fetchNextPage: (pageNo) => dispatch(fetchNextPage(pageNo)),
		initiateListSearch: (searchType, searchValue, limit, offset, profile) => dispatch(runProfileListSearch(searchType, searchValue, limit, offset, profile)),
		cancelSearch: () => dispatch(disableListSearch()),
		initiateSort: (sortBy) => dispatch(runProfileListSort(sortBy)),
		disableSearchFlag: () => dispatch(disableListSearchFlag()),
		setInitialState: () => dispatch(setInitialState()),
	};
}

const mapStateToProps = createStructuredSelector({
	profiles: makeSelectListProfiles(),
	loading: makeSelectProfileListLoading(),
	limit: makeSelectProfileListLimit(),
	offset: makeSelectProfileListOffset(),
	fetchedProfiles: makeSelectFetchedProfiles(),
	searchFlag: makeSelectProfileListSearch(),
	searchType: makeSelectProfileListSearchType(),
	searchValue: makeSelectProfileListSearchValue(),
	sortValue: makeSelectProfileListSortValue(),
});

ProfileList.propTypes = {
	profiles: T.array,
	getProfiles: T.func,
	fetchNextPage: T.func,
	initiateListSearch: T.func,
	cancelSearch: T.func,
	initiateSort: T.func,
	disableSearchFlag: T.func,
	setInitialState: T.func,
	searchValue: T.string,
	match: T.object,
	pageTitle: T.func,
	limit: T.number,
	offset: T.number,
	searchFlag: T.bool,
	searchType: T.string,
	sortValue: T.string,
	loading: T.bool,
	fetchedProfiles: T.array,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(ProfileList);
