import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { Modal, Button } from '../Styles';
import { Col } from '../Responsive';
import { getDropdownOptions } from '../../utils/common';
import AlertModal from '../AlertModal/AlertModal';

const ReplacementModal = (props) => {
	const [onConfirm, setConfirm] = useState(false);
	const [profile, setProfile] = useState(null);

	function handleOnChange(profile) {
		setProfile(profile);
		if (profile) {
			props.onReplacementSelect(profile.id);
		} else {
			props.onReplacementSelect(null);
		}
	}

	return (
		<>
			{!onConfirm
				? <Modal>
					<div className="modal-float">
						<div className="modal-wrapper">
							<div className="modal-content">
								<p className="mb-2">{props.comment}</p>
								<Col className="ml-2 p-2 mb-2" sm={8} md={8}>
									<AsyncSelect
										isClearable
										placeholder="Select alternate company"
										loadOptions={(inputValue) => getDropdownOptions('company', inputValue)}
										onChange={handleOnChange}
									/>
								</Col>
								<p className="error-text mb-0"><small>NOTE: Leaving it blank will trash the company without a replacement.</small></p>
								<div className="text-right">
									<Button
										primary
										className="m-1"
										onClick={() => setConfirm(true)}
									>
										Confirm
									</Button>
									<Button
										light
										className="m-1"
										onClick={() => { setProfile(null); props.confirm(false); }}
									>
										Cancel
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Modal>
				: <AlertModal
						comment={`This is an irreversible action and will ${profile && profile.id ? 'replace' : 'remove'}	all the article associations for this company. Are you sure?`}
						confirm={(value) => props.confirm(value)}
				/>}
		</>
	);
};

ReplacementModal.propTypes = {
	comment: PropTypes.string,
	confirm: PropTypes.func,
	onReplacementSelect: PropTypes.func,
};

export default ReplacementModal;
