import { createSelector } from 'reselect';

const editor = (state) => state.get('editor');

const selectEditorLoading = () => createSelector(
	editor,
	(editorState) => editorState.get('loading'),
);

const selectEditorIsUserEditor = () => createSelector(
	editor,
	(editorState) => editorState.get('isUserEditor'),
);

const selectEditorIsUserStoryAuthor = () => createSelector(
	editor,
	(editorState) => editorState.get('isUserStoryAuthor'),
);

const selectEditorSelectedTab = () => createSelector(
	editor,
	(editorState) => editorState.get('selectedTab'),
);

const selectEditorFormData = () => createSelector(
	editor,
	(editorState) => editorState.get('editorFormData'),
);

const selectEditorFormMetadata = () => createSelector(
	editor,
	(editorState) => editorState.get('formMetadata'),
);

const selectEditorHasErrors = () => createSelector(
	editor,
	(editorState) => editorState.get('hasErrors'),
);

const selectEditorStoryId = () => createSelector(
	editor,
	(editorState) => editorState.get('storyId'),
);

const selectEditorStoryStatus = () => createSelector(
	editor,
	(editorState) => editorState.get('status'),
);

const selectEditorEditStory = () => createSelector(
	editor,
	(editorState) => editorState.get('editStory'),
);

const selectEditorIsContentDuplicate = () => createSelector(
	editor,
	(editorState) => editorState.get('isContentDuplicate'),
);

const selectEditorFocusKeyphrase = () => createSelector(
	editor,
	(editorState) => editorState.get('focusKeyphrase'),
);

const selectEditorSeoLoading = () => createSelector(
	editor,
	(editorState) => editorState.get('seoLoading'),
);

const selectEditorSeoAnalysis = () => createSelector(
	editor,
	(editorState) => editorState.get('seoAnalysis'),
);

const selectEditorFormTouched = () => createSelector(
	editor,
	(editorState) => editorState.get('formTouched'),
);

const selectEditorSelectedAction = () => createSelector(
	editor,
	(editorState) => editorState.get('selectedAction'),
);

const selectEditorUpdating = () => createSelector(
	editor,
	(editorState) => editorState.get('updating'),
);

const selectEditorFormMessage = () => createSelector(
	editor,
	(editorState) => editorState.get('formMessage'),
);

const selectEditorLoadingPlagiarism = () => createSelector(
	editor,
	(editorState) => editorState.get('loadingPlagiarism'),
);

const selectEditorPlagiarismData = () => createSelector(
	editor,
	(editorState) => editorState.get('plagiarismData'),
);

const selectEditorSocketData = () => createSelector(
	editor,
	(editorState) => editorState.get('socketData'),
);

const selectEditorDocConversion = () => createSelector(
	editor,
	(editorState) => editorState.get('docConversion'),
);

const selectEditorRevisionLoading = () => createSelector(
	editor,
	(editorState) => editorState.get('revisionLoading'),
);

const selectEditorStoryRevisions = () => createSelector(
	editor,
	(editorState) => editorState.get('storyRevisions'),
);

const selectEditorVideoLoading = () => createSelector(
	editor,
	(editorState) => editorState.get('videoLoading'),
);

const selectEditorAutoSaving = () => createSelector(
	editor,
	(editorState) => editorState.get('autosaving'),
);

const selectAllSectors = () => createSelector(
	editor,
	(editorState) => editorState.get('allSectors'),
);

const selectStorySnippets = () => createSelector(
	editor,
	(editorState) => editorState.get('snippetsData'),
);

const selectYstvCategories = () => createSelector(
	editor,
	(editorState) => editorState.get('ystvCategories'),
);

// webinar related
const selectWebinarBasicInfo = () => createSelector(
	editor,
	(editorState) => editorState.get('webinar'),
);

const selectRegistrationForm = () => createSelector(
	editor,
	(editorState) => editorState.get('registrationForm'),
);

const selectTag1Types = () => createSelector(
	editor,
	(editorState) => editorState.get('tag1Types'),
);

const selectTag2Types = () => createSelector(
	editor,
	(editorState) => editorState.get('tag2Types'),
);

const selectQuestions = () => createSelector(
	editor,
	(editorState) => editorState.get('questions'),
);

const selectQuestionOffset = () => createSelector(
	editor,
	(editorState) => editorState.get('questionOffset'),
);

const selectQuestionsAvailable = () => createSelector(
	editor,
	(editorState) => editorState.get('questionsAvailable'),
);

const selectQuestionsCount = () => createSelector(
	editor,
	(editorState) => editorState.get('questionsCount'),
);

const selectQuestionsLoading = () => createSelector(
	editor,
	(editorState) => editorState.get('questionsLoading'),
);

const selectLiveStreamTypes = () => createSelector(
	editor,
	(editorState) => editorState.get('liveStreamTypes'),
);

const selectReportGenerateLoading = () => createSelector(
	editor,
	(editorState) => editorState.get('reportGenerateLoading'),
);

const selectReportMessage = () => createSelector(
	editor,
	(editorState) => editorState.get('reportMessage'),
);

const selectReportDownloadLoading = () => createSelector(
	editor,
	(editorState) => editorState.get('reportDownloadLoading'),
);

const selectAddedToTopPicks = () => createSelector(
	editor,
	(editorState) => editorState.get('addedToTopPicks'),
);
// webinar related

const selectUniqueSlug = () => createSelector(
	editor,
	(editorState) => editorState.get('uniqueSlug'),
);
// Article Topic Selector

const selectAllArticleTopic = () => createSelector(
	editor,
	(editorState) => editorState.get('allArticleTopics'),
);

const selectAllRichArticleTopic = () => createSelector(
	editor,
	(editorState) => editorState.get('allRichArticleTopics'),
);

const selectPaywallSubtypeOptions = () => createSelector(
	editor,
	(editorState) => editorState.get('paywallSubtypeOptions'),
);

const selectBoniComposer = () => createSelector(
	editor,
	(editorState) => editorState.get('boniComposer'),
);

const selectEditorTabChangeCount = () => createSelector(
	editor,
	(editorState) => editorState.get('tabChangeCount'),
);

export {
	selectEditorLoading,
	selectEditorIsUserEditor,
	selectEditorIsUserStoryAuthor,
	selectEditorSelectedTab,
	selectEditorFormData,
	selectEditorFormMetadata,
	selectEditorHasErrors,
	selectEditorStoryId,
	selectEditorStoryStatus,
	selectEditorEditStory,
	selectEditorIsContentDuplicate,
	selectEditorFocusKeyphrase,
	selectEditorSeoLoading,
	selectEditorSeoAnalysis,
	selectEditorFormTouched,
	selectEditorSelectedAction,
	selectEditorUpdating,
	selectEditorFormMessage,
	selectEditorLoadingPlagiarism,
	selectEditorPlagiarismData,
	selectEditorSocketData,
	selectEditorDocConversion,
	selectEditorRevisionLoading,
	selectEditorStoryRevisions,
	selectEditorVideoLoading,
	selectEditorAutoSaving,
	selectAllSectors,
	selectStorySnippets,
	selectYstvCategories,
	selectWebinarBasicInfo,
	selectRegistrationForm,
	selectTag1Types,
	selectTag2Types,
	selectQuestions,
	selectQuestionOffset,
	selectQuestionsAvailable,
	selectLiveStreamTypes,
	selectReportGenerateLoading,
	selectReportMessage,
	selectReportDownloadLoading,
	selectQuestionsCount,
	selectQuestionsLoading,
	selectUniqueSlug,
	selectAllArticleTopic,
	selectAddedToTopPicks,
	selectAllRichArticleTopic,
	selectPaywallSubtypeOptions,
	selectBoniComposer,
	selectEditorTabChangeCount,
};
