import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';

import ContainerApp from './containers/App/index';
import LanguageProvider from './containers/LanguageProvider';
import configureStore, { history } from './utils/configureStore';
import { translationMessages } from './i18n';
import ScrollToTop from './components/ScrollToTop';

const store = configureStore();

const App = () => <Provider store={store}>
	<LanguageProvider messages={translationMessages}>
		<ConnectedRouter history={history}>
			<ScrollToTop>
				<ContainerApp />
			</ScrollToTop>
		</ConnectedRouter>
	</LanguageProvider>
</Provider>;

export default App;
