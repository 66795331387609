/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';

import { DEFAULT_LOCALE } from './containers/App/constants'; // eslint-disable-line
import enTranslationMessages from './translations/en.json';

export const appLocales = [
	'en',
];

export const formatTranslationMessages = (locale, messages) => {
	const defaultFormattedMessages = locale !== DEFAULT_LOCALE
		? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
		: {};
	return Object.keys(messages).reduce((formattedMessages, key) => {
		let message = messages[key];
		if (!message && locale !== DEFAULT_LOCALE) {
			message = defaultFormattedMessages[key];
		}
		return Object.assign(formattedMessages, { [key]: message });
	}, {});
};

export const translationMessages = {
	en: formatTranslationMessages('en', enTranslationMessages),
};
