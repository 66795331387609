export const FETCH_ALL_REDIRECTIONS = 'FETCH-ALL_REDIRECTIONS';
export const FETCH_ALL_REDIRECTIONS_SUCCESS = 'FETCH-ALL_REDIRECTIONS_SUCCESS';
export const FETCH_ALL_REDIRECTIONS_FAILURE = 'FETCH-ALL_REDIRECTIONS_FAILURE';

export const SELECT_REDIRECTION = 'SELECT_REDIRECTION';
export const CLEAR_REDIRECTION_FORM = 'CLEAR_REDIRECTION_FORM';
export const SET_REDIRECTION_TABLE_PAGE = 'SET_REDIRECTION_TABLE_PAGE';

export const CREATE_REDIRECTION = 'CREATE_REDIRECTION';
export const CREATE_REDIRECTION_SUCCESS = 'CREATE_REDIRECTION_SUCCESS';
export const CREATE_REDIRECTION_FAILURE = 'CREATE_REDIRECTION_FAILURE';

export const SET_REDIRECTION_FORM_MESSAGE = 'SET_REDIRECTION_FORM_MESSAGE';
export const REDIRECTION_ELEMENT_ON_CHANGE = 'REDIRECTION_ELEMENT_ON_CHANGE';

export const FILTER_REDIRECTION_TABLE = 'FILTER_REDIRECTION_TABLE';
export const FILTER_REDIRECTION_TABLE_SUCCESS = 'FILTER_REDIRECTION_TABLE_SUCCESS';
export const FILTER_REDIRECTION_TABLE_FAILURE = 'FILTER_REDIRECTION_TABLE_FAILURE';
