import {
	SELECT_BRAND_SPOTLIGHT, ADD_BRAND_SPOTLIGHT, BRAND_SPOTLIGHT_ELEMENT_ON_CHANGE, CREATE_BRAND_SPOTLIGHT, CREATE_BRAND_SPOTLIGHT_SUCCESS, CREATE_BRAND_SPOTLIGHT_ERROR, UPDATE_BRAND_SPOTLIGHT, UPDATE_BRAND_SPOTLIGHT_SUCCESS, UPDATE_BRAND_SPOTLIGHT_ERROR, UNMOUNT_BRAND_SPOTLIGHT_FORM_MESSAGE, WRITE_BRAND_SPOTLIGHT_SLUG, SET_BRAND_SPOTLIGHT_SLUG_UNIQUE_VALUE, CLEAR_BRAND_SPOTLIGHT_DATA,
} from './type';

export function selectBrandSpotlight(selectedBrandSpotlight) {
	return {
		type: SELECT_BRAND_SPOTLIGHT,
		selectedBrandSpotlight,
	};
}

export function addBrandSpotlight() {
	return {
		type: ADD_BRAND_SPOTLIGHT,
	};
}

export function brandSpotlightElementOnChange(elementData) {
	return {
		type: BRAND_SPOTLIGHT_ELEMENT_ON_CHANGE,
		elementData,
	};
}

export function createBrandSpotlight(brandSpotlight) {
	return {
		type: CREATE_BRAND_SPOTLIGHT,
		brandSpotlight,
	};
}

export function createBrandSpotlightSuccess(responseMessage) {
	return {
		type: CREATE_BRAND_SPOTLIGHT_SUCCESS,
		responseMessage,
	};
}

export function createBrandSpotlightError(error) {
	return {
		type: CREATE_BRAND_SPOTLIGHT_ERROR,
		error,
	};
}

export function updateBrandSpotlight(brandSpotlightId, brandSpotlight) {
	return {
		type: UPDATE_BRAND_SPOTLIGHT,
		brandSpotlightId,
		brandSpotlight,
	};
}

export function updateBrandSpotlightSuccess(responseMessage) {
	return {
		type: UPDATE_BRAND_SPOTLIGHT_SUCCESS,
		responseMessage,
	};
}

export function updateBrandSpotlightError(error) {
	return {
		type: UPDATE_BRAND_SPOTLIGHT_ERROR,
		error,
	};
}

export function unmountBrandSpotlightFormMessage() {
	return {
		type: UNMOUNT_BRAND_SPOTLIGHT_FORM_MESSAGE,
	};
}

export function writeBrandSpotlightSlug() {
	return {
		type: WRITE_BRAND_SPOTLIGHT_SLUG,
	};
}

export function setBrandSpotlightSlugUniqueValue(value) {
	return {
		type: SET_BRAND_SPOTLIGHT_SLUG_UNIQUE_VALUE,
		value,
	};
}

export function setInitialState() {
	return {
		type: CLEAR_BRAND_SPOTLIGHT_DATA,
	};
}
