import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { FETCH_STORY_VERSIONS_BY_STORY_ID } from './type';
import { STORY_VERSION_API_URL } from './constants';
import { fetchStoryVersionsByStoryIdSuccess, fetchStoryVersionsByStoryIdFailure } from './actions';

export function* getStoryVersionsByStoryId(action) {
	const url = `${STORY_VERSION_API_URL}/versions/${action.storyId}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};

	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const storyVersions = yield response.json();
			yield put(fetchStoryVersionsByStoryIdSuccess(storyVersions));
		}
	} catch (err) {
		yield put(fetchStoryVersionsByStoryIdFailure(err));
	}
}

export default function* storyVersionsData() {
	yield takeLatest(FETCH_STORY_VERSIONS_BY_STORY_ID, getStoryVersionsByStoryId);
}
