import { Map } from 'immutable';
import _ from 'lodash';
import {
	LOAD_ALL_NAMESPACE, LOAD_ALL_NAMESPACE_SUCCESS, LOAD_ALL_NAMESPACE_ERROR, SELECT_NAMESPACE, ON_CONFIG_CHANGED, DISCARD_CONFIG_CHANGES, UPDATE_CONFIG, UPDATE_CONFIG_SUCCESS, UPDATE_CONFIG_ERROR, UNMOUNT_CONFIG_EDITOR_MESSAGE, LOAD_CONFIGS_BY_NAMESPACE_SUCCESS, LOAD_CONFIGS_BY_NAMESPACE, LOAD_CONFIG_LATEST_UPDATE_TIMESTAMP_SUCCESS, SET_CONFIG_USER, LOAD_ALL_BRANDS_SUCCESS, ON_BRAND_CHANGE, CLEAR_CONFIG_DATA, UPDATE_BRANDS,
} from './type';
import { CONFIG_SAVE_LOAD_MESSAGE, CONFIG_SAVE_SUCCESS_MESSAGE } from './constants';

const initialState = Map({
	loading: false,
	error: false,
	dropdownTitle: 'SELECT NAMESPACE',
	namespaces: null,
	configs: null,
	selectedNamespaceConfigs: null,
	editorMessage: null,
	selectedNamespace: null,
	saveEnabled: false,
	lastUpdatedAt: null,
	user: null,
	brands: [],
	saving: false,
});

function dynamicConfigReducer(state = initialState, action) {
	switch (action.type) {
	case SET_CONFIG_USER:
		return state
			.set('user', action.user);
	case LOAD_ALL_NAMESPACE:
		return state
			.set('loading', true)
			.set('error', false)
			.set('dropdownTitle', 'SELECT NAMESPACE')
			.set('namespaces', null)
			.set('configs', null)
			.set('selectedNamespaceConfigs', null)
			.set('selectedNamespace', null)
			.set('saveEnabled', false)
			.set('lastUpdatedAt', null);
	case LOAD_ALL_NAMESPACE_SUCCESS: {
		return state
			.set('loading', false)
			.set('error', false)
			.set('dropdownTitle', 'SELECT NAMESPACE')
			.set('namespaces', action.namespaces)
			.set('saveEnabled', false);
	}
	case LOAD_ALL_NAMESPACE_ERROR:
		return state
			.set('loading', false)
			.set('error', action.error)
			.set('saveEnabled', false)
			.set('dropdownTitle', 'SELECT NAMESPACE');
	case SELECT_NAMESPACE: {
		const selected = action.selectedNamespace.name;
		if (selected === state.get('dropdownTitle')) {
			return state
				.set('selectedNamespace', null)
				.set('selectedNamespaceConfigs', null)
				.set('saveEnabled', false);
		}
		return state
			.set('selectedNamespace', action.selectedNamespace)
			.set('editorMessage', null)
			.set('saveEnabled', false);
	}
	case LOAD_CONFIGS_BY_NAMESPACE:
		return state
			.set('editorMessage', null);
	case LOAD_CONFIGS_BY_NAMESPACE_SUCCESS: {
		return state
			.set('configs', JSON.parse(JSON.stringify(action.allConfigs)))
			.set('selectedNamespaceConfigs', JSON.parse(JSON.stringify(action.allConfigs)));
	}
	case ON_CONFIG_CHANGED:
		return state
			.set('saveEnabled', true)
			.set('selectedNamespaceConfigs', changeConfig(state.get('selectedNamespaceConfigs'), action.changedConfig, action.changedConfigValue, state.get('user')));
	case DISCARD_CONFIG_CHANGES: {
		return state
			.set('saveEnabled', false)
			.set('duplicateBrands', JSON.parse(JSON.stringify(state.get('brands'))))
			.set('selectedNamespaceConfigs', JSON.parse(JSON.stringify(state.get('configs'))));
	}
	case UPDATE_CONFIG:
		return state
			.set('saving', true)
			.set('editorMessage', CONFIG_SAVE_LOAD_MESSAGE);
	case UPDATE_CONFIG_SUCCESS:
		return state
			.set('saving', false)
			.set('brands', JSON.parse(JSON.stringify(state.get('duplicateBrands'))))
			.set('editorMessage', CONFIG_SAVE_SUCCESS_MESSAGE);
	case UPDATE_CONFIG_ERROR:
		return state
			.set('saving', false)
			.set('editorMessage', action.failureMessage);
	case UNMOUNT_CONFIG_EDITOR_MESSAGE:
		return state
			.set('editorMessage', null);
	case LOAD_CONFIG_LATEST_UPDATE_TIMESTAMP_SUCCESS: {
		return state
			.set('lastUpdatedAt', action.timestamp.updatedAt);
	}
	case LOAD_ALL_BRANDS_SUCCESS:
		return state
			.set('brands', action.brands)
			.set('duplicateBrands', action.brands);
	case ON_BRAND_CHANGE: {
		let duplicateBrands = _.cloneDeep(state.get('duplicateBrands'));
		if (action.brand === 'ALL') {
			duplicateBrands = duplicateBrands.map((brand) => {
				brand[action.value] = !action.brandIndex;
				return brand;
			});
		} else {
			duplicateBrands[action.brandIndex][action.value] = !duplicateBrands[action.brandIndex][action.value];
		}
		return state
			.set('saveEnabled', true)
			.set('duplicateBrands', duplicateBrands);
	}
	case UPDATE_BRANDS:
		return state
			.set('saving', true);
	case CLEAR_CONFIG_DATA:
		return initialState;
	default:
		return state;
	}
}

export default dynamicConfigReducer;

const changeConfig = (editorConfig, changedConfig, changedConfigValue, user) => {
	const newConfig = editorConfig.reduce((acc, curr) => {
		const config = curr;
		if (config.id === changedConfig) {
			if (config.inputType === 'radio') {
				config.value = (!JSON.parse(config.value)).toString();
				config.lastUpdatedBy = user.id;
			} else if (config.inputType === 'text') {
				config.value = changedConfigValue;
				config.lastUpdatedBy = user.id;
			}
		}
		acc.push(config);
		return acc;
	}, []);
	return newConfig;
};
