export const ADD_NEW_PERSON = 'ADD_NEW_PERSON';
export const PERSON_ELEMENT_ON_CHANGE = 'PERSON_ELEMENT_ON_CHANGE';
export const SELECT_PERSON = 'SELECT_PERSON';

export const CREATE_PERSON = 'CREATE_PERSON';
export const CREATE_PERSON_SUCCESS = 'CREATE_PERSON_SUCCESS';
export const CREATE_PERSON_ERROR = 'CREATE_PERSON_ERROR';

export const UPDATE_PERSON = 'UPDATE_PERSON';
export const UPDATE_PERSON_SUCCESS = 'UPDATE_PERSON_SUCCESS';
export const UPDATE_PERSON_ERROR = 'UPDATE_PERSON_ERROR';

export const DELETE_PERSON = 'DELETE_PERSON';
export const DELETE_PERSON_SUCCESS = 'DELETE_PERSON_SUCCESS';
export const DELETE_PERSON_ERROR = 'DELETE_PERSON_ERROR';

export const UNMOUNT_PEOPLE_FORM_MESSAGE = 'UNMOUNT_PEOPLE_FORM_MESSAGE';

export const ADD_PERSON_AWARD = 'ADD_PERSON_AWARD';
export const REMOVE_PERSON_AWARD = 'REMOVE_PERSON_AWARD';

export const CLEAR_INFLUENCER_MANAGER_DATA = 'CLEAR_INFLUENCER_MANAGER_DATA';

export const FETCH_PERSON_BY_ID = 'FETCH_PERSON_BY_ID';
