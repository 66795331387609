import { Map } from 'immutable';
import {
	LOAD_USER_ERROR, LOAD_USER_SUCCESS, INVITE_USER_ERROR, INVITE_USER_SUCCESS, UPDATE_DATA, CLEAR_INVITE_USER_DATA,
} from './type';

const initialState = Map({
	name: null,
	email: null,
	loggedInUser: null,
	loaded: false,
	error: {},
	formData: {},
	openModal: false,
	rolesAssigned: null,
	roleTypeDropDownValue: null,
	brandDropDownValue: undefined,
	managerTypeDropDownValue: undefined,
	roleDropDownValue: undefined,
	message: null,
	responseType: null,
});

function inviteUserReducer(state = initialState, action) {
	switch (action.type) {
	case LOAD_USER_SUCCESS:
		return state
			.set('loggedInUser', action.user)
			.set('loaded', true);

	case LOAD_USER_ERROR:
		return state
			.set('loggedInUser', null)
			.set('responseType', 'error')
			.set('message', 'Failed to authenticate')
			.set('loaded', false);

	case INVITE_USER_ERROR:
		return state
			.set('message', action.error.message)
			.set('responseType', 'error');

	case INVITE_USER_SUCCESS: {
		return state
			.set('message', 'Successfully invited')
			.set('responseType', 'success');
	}

	case UPDATE_DATA: {
		return state
			.set(action.name, action.value);
	}

	case CLEAR_INVITE_USER_DATA:
		return initialState;

	default:
		return state;
	}
}

export default inviteUserReducer;
