import { Map } from 'immutable';
import _ from 'lodash';

import {
	EDITOR_SET_USER_ROLE,
	EDITOR_ON_TAB_CHANGE,
	FETCH_EDITOR_DATA,
	FETCH_EDITOR_DATA_SUCCESS,
	EDITOR_ON_FORM_DATA_CHANGE,
	EDITOR_ON_FORM_METADATA_CHANGE,
	EDITOR_HANDLE_BLUR,
	CREATE_STORY,
	SET_DEFAULT_SOCIAL_EXCERPT,
	SET_DEFAULT_SOCIAL_TITLE,
	SET_DEFAULT_SOCIAL_IMAGE,
	EDITOR_ON_FOCUS_KEYPHRASE_CHANGE,
	EDITOR_RUN_SEO_ANALYSIS,
	EDITOR_RUN_SEO_ANALYSIS_SUCCESS,
	EDITOR_HAS_ERRORS,
	SET_EDITOR_ACTION,
	UPDATE_STORY,
	UPDATE_STORY_FAILURE,
	UPDATE_STORY_SUCCESS,
	SET_EDITOR_FORM_MESSAGE,
	EDITOR_SET_ALL_ELEMENTS_BLUR,
	EDITOR_RUN_PLAGIARISM_CHECK,
	EDITOR_RUN_PLAGIARISM_CHECK_SUCCESS,
	EDITOR_RUN_PLAGIARISM_CHECK_FAILURE,
	SET_EDITOR_SOCKET_DATA,
	UPDATE_EDITOR_DOC_CONVERSION_DATA,
	UPDATE_EDITOR_DOC_CONVERTED_DELTA,
	FETCH_EDITOR_STORY_REVISIONS,
	FETCH_EDITOR_STORY_REVISIONS_SUCCESS,
	FETCH_EDITOR_STORY_REVISIONS_FAILURE,
	ADD_YOUTUBE_VIDEO,
	ADD_YOUTUBE_VIDEO_SUCCESS,
	ADD_YOUTUBE_VIDEO_FAILURE,
	REPLACE_VIDEO,
	ADD_VIDEO_FROM_GALLERY,
	CLEAR_EDITOR_DATA,
	AUTO_SAVE_STORY,
	AUTO_SAVE_STORY_SUCCESS,
	AUTO_SAVE_STORY_FAILURE,
	CLEAR_AUTO_SAVE_MESSAGE,
	SET_CONTENT_DUPLICATE,
	UPDATE_SNIPPETS,
	FORM_ELEMENT_ORDER_CHANGE,
	UPDATE_WEBINAR_REGISTRATION_FORM_DATA,
	UPDATE_WEBINAR_BASIC_INFO,
	FETCH_WEBINAR_QUESTIONS,
	FETCH_WEBINAR_QUESTIONS_SUCCESS,
	CHECK_FOR_NEW_QUESTIONS_SUCCESS,
	GENERATE_WEBINAR_REPORT,
	DOWNLOAD_WEBINAR_REPORT,
	REPORT_DOWNLOAD_COMPLETE,
	REPORT_GENERATE_COMPLETE,
	FETCH_LIVE_STREAM_TYPES,
	FETCH_LIVE_STREAM_TYPES_SUCCESS,
	FETCH_LIVE_STREAM_TYPES_FAILURE,
	SET_UNIQUE_SLUG,
	CLEAR_TOP_PICKS_DATA,
	SET_TOP_PICK,
	BONI_COMPOSER_CHANGE,
	BONI_COMPOSER_BRNAD_SECTION_CHANGE,
	EDITOR_ON_BRAND_CHANGE,
} from './type';
import { getTimeToRead, formatDataForState } from './functions';
import {
	ACTION_OPTIONS,
	STORY_UPDATING_MESSAGE,
	STORY_UPDATE_SUCCESS_MESSAGE,
	STORY_UPDATE_FAILURE_MESSAGE,
	STORY_PUBLISH_SUCCESS_MESSAGE,
	STORY_PUBLISH_FAILURE_MESSAGE,
	WEBINAR_UPDATE_SUCCESS_MESSAGE,
	VIDEO_UPDATE_FAILURE_MESSAGE,
	WEBINAR_UPDATE_FAILURE_MESSAGE,
	VIDEO_PUBLISH_FAILURE_MESSAGE,
	WEBINAR_PUBLISH_FAILURE_MESSAGE,
	VIDEO_UPDATE_SUCCESS_MESSAGE,
	VIDEO_PUBLISH_SUCCESS_MESSAGE,
	WEBINAR_PUBLISH_SUCCESS_MESSAGE,
	VIDEO_UPDATING_MESSAGE,
	WEBINAR_UPDATING_MESSAGE,
	COPY_SCAPE_FAILURE_MESSAGE,
} from './constants';

const initialState = Map({
	loading: true,
	isUserEditor: false,
	isUserStoryAuthor: true,
	selectedTab: 'Featured',
	tabChangeCount: 0,
	editStory: null,
	editorFormData: {
		storyId: null,
		title: '',
		subtitle: '',
		slug: '',
		postJson: {},
		postContent: '',
		imported: false,
		publishedAt: null,
		series: null,
		mediaId: null,
		videoPlatform: 'jwplayer',
		storyType: null,
		editorComment: null,
		plagiarismCheck: null,
		videoTitle: null,
		articleType: null,
		protected: false,
		totalParagraphCount: null,
	},
	isContentDuplicate: false,
	status: 'DRAFTS',
	seoLoading: false,
	focusKeyphrase: null,
	seoAnalysis: null,
	selectedAction: ACTION_OPTIONS.INREVIEW,
	updating: false,
	storyId: null,
	formTouched: {
		title: false,
		brand: false,
		thumbnail: false,
		thumbnailSquare: false,
		thumbnailDouble: false,
		thumbnailVideo: false,
		storyUrlSlug: false,
		featuredVideo: false,
		author: false,
		category: false,
		canonicalUrl: false,
		facebook: false,
		twitter: false,
		search: false,
		plagiarismCheck: false,
		marketingComments: false,
		marketingPost: false,
		portraitThumbnail: false,
		moderators: false,
		speakers: false,
		curatedCategory: false,
		startTime: false,
		endTime: false,
		protected: false,
		editorsPickTitle: false,
		editorsPickSubtitle: false,
		editorsPickParentUrl: false,
		editorsPickStartTime: false,
		editorsPickEndTime: false,
		companyBrand: false,
		insideLookTitle: false,
		insideLookDesc: false,
		offerHeading: false,
		offerSubHeading: false,
		offerCta: false,
		productTitle: false,
		productQuestion: false,
		productAnswer: false,
		products: false,
		brandSection: false,
		prelude: false,
		contentType: false,
		translatedFromUrl: false,
		storySchemaType: false,
		AIGeneratedStory: false,
		storyShortHeading: false,
		'schemaData.HowTo.StepsText': false,
		webStoryUrl: false,
		parentGlossary: false,
		NewsRoundUp: false,
	},
	formMetadata: {
		tags: [],
		category: null,
		author: null,
		facebookTitle: '',
		facebookExcerpt: '',
		facebookFeaturedImage: null,
		twitterTitle: '',
		twitterExcerpt: '',
		twitterFeaturedImage: null,
		searchTitle: '',
		searchExcerpt: '',
		searchFeaturedImage: null,
		brandSpotlight: null,
		removeAds: false,
		dontShowOnHomepage: false,
		sponsoredStory: false,
		marketingPost: '',
		marketingComments: '',
		thumbnail: null,
		thumbnailSquare: null,
		thumbnailDouble: null,
		thumbnailVideo: null,
		canonicalUrl: '',
		timeToRead: null,
		plainText: null,
		hasTwitterEmbed: false,
		hasInstagramEmbed: false,
		hasSoundCloudEmbed: false,
		schedule: null,
		noSyndication: false,
		focusKeyphrase: '',
		storyUrlSlug: null,
		storyUrlPrefix: null,
		storyUrl: null,
		disableComments: false,
		addToSeries: false,
		videoDuration: null,
		videoThumbnail: null,
		companies: [],
		influencers: [],
		sectors: [],
		city: null,
		editors: [],
		tag1: null,
		tag2: null,
		moderators: [],
		speakers: [],
		paywallType: null,
		articleTopics: [],
		editorsPickTitle: null,
		editorsPickSubtitle: null,
		editorsPickStartTime: null,
		editorsPickEndTime: null,
		richArticleTopic: null,
		paywallSubtype: null,
		storyFormat: null,
		orgCharts: [],
		selectedOrgChart: null,
		contentType: [],
		translatedStory: null,
		translatedFromUrl: null,
		storySchemaType: 'article story',
		AIGeneratedStory: false,
		storyShortHeading: null,
		'schemaData.HowTo.StepsText': '',
		webStoryUrl: '',
		parentGlossary: '',
		NewsRoundUp: false,
	},
	hasErrors: {
		title: false,
		brand: false,
		thumbnail: false,
		thumbnailSquare: false,
		thumbnailDouble: false,
		thumbnailVideo: false,
		storyUrlSlug: false,
		featuredVideo: false,
		author: false,
		category: false,
		facebookTitle: false,
		facebookExcerpt: false,
		facebookFeaturedImage: false,
		twitterTitle: false,
		twitterExcerpt: false,
		twitterFeaturedImage: false,
		searchTitle: false,
		searchExcerpt: false,
		searchFeaturedImage: false,
		canonicalUrl: false,
		plagiarismCheck: false,
		sectors: false,
		articleType: false,
		marketingComments: false,
		marketingPost: false,
		moderators: false,
		speakers: false,
		curatedCategory: false,
		liveStreams: false,
		articleTopics: false,
		protected: false,
		richArticleTopic: false,
		paywallSubtype: false,
		storyFormat: false,
		subtitle: false,
		tags: false,
		companyBrand: false,
		insideLookTitle: false,
		insideLookDesc: false,
		offerHeading: false,
		offerSubHeading: false,
		offerCta: false,
		productTitle: false,
		productQuestion: false,
		productAnswer: false,
		products: false,
		brandSection: false,
		prelude: false,
		contentType: false,
		translatedFromUrl: false,
		storySchemaType: false,
		AIGeneratedStory: false,
		storyShortHeading: false,
		'schemaData.HowTo.StepsText': false,
		webStoryUrl: false,
		parentGlossary: false,
		NewsRoundUp: false,
	},
	plagiarismData: null,
	loadingPlagiarism: false,
	socketData: null,
	revisionLoading: false,
	storyRevisions: null,
	videoLoading: false,
	online: true,
	autosaving: {
		status: false,
		message: null,
	},
	allSectors: [],
	snippetsData: {
		snippets: [],
		trailer: [],
	},
	webinar: {
		startTime: '',
		endTime: '',
		disclaimer: null,
		liveStreamConfig: null,
		report: null,
		status: null,
		loadingStreams: false,
	},
	registrationForm: [],
	registrationFormOrig: {},
	tag1Types: [],
	tag2Types: [],
	questions: [],
	questionsLoading: false,
	questionOffset: 0,
	questionsAvailable: 0,
	liveStreamTypes: [],
	uniqueSlug: true,
	allArticleTopics: [],
	iterationNumber: 0,
	originStorySlug: null,
	addedToTopPicks: false,
	allRichArticleTopics: [],
	paywallSubtypeOptions: [],
	boniComposer: {
		brandSection: [
			{
				title: '',
				question: '',
				answer: '',
				banner: '',
				index: 0,
			}, {
				title: '',
				question: '',
				answer: '',
				banner: '',
				index: 1,
			},
		],
		productTitle: null,
		productQuestion: null,
		productAnswer: null,
		products: [],
		offerHeading: null,
		offerSubHeading: null,
		offerCta: null,
		insideLookTitle: null,
		insideLookDesc: null,
		addProduct: false,
		addOffer: false,
		addInsideLook: false,
		prelude: null,
	},
});

function editorReducer(state = initialState, action) {
	switch (action.type) {
	case EDITOR_SET_USER_ROLE:
		return state
			.set('isUserEditor', action.editor)
			.set('isUserStoryAuthor', action.author);
		// .set('selectedAction', action.editor ? ACTION_OPTIONS.PUBLISHED : ACTION_OPTIONS.INREVIEW);
	case EDITOR_ON_TAB_CHANGE: {
		const formTouched = { ...state.get('formTouched') };
		const tabChangeCount = state.get('tabChangeCount');
		if (action.tab === 'Plagiarism') {
			formTouched.plagiarismCheck = true;
		}
		return state
			.set('selectedTab', action.tab)
			.set('formTouched', formTouched)
			.set('tabChangeCount', tabChangeCount >= 0 && tabChangeCount < 2 ? tabChangeCount + 1 : tabChangeCount);
	}
	case EDITOR_ON_BRAND_CHANGE: {
		return state
			.set('tabChangeCount', 0);
	}
	case CREATE_STORY:
		return state
			.set('loading', true);
	case FETCH_EDITOR_DATA:
		return state
			.set('loading', true);
	case FETCH_EDITOR_DATA_SUCCESS: {
		let formHash; let
			registrationForm;
		let iterationNumber = state.get('iterationNumber');
		const originStorySlug = action.metadata.storyUrlSlug;
		let boniComposer = {};
		console.log('action', action);
		if (action.editorFormData && action.editorFormData.storyType && action.editorFormData.storyType === 'RICH') {
			if (Number(action.metadata.storyUrlSlug)) { action.metadata.storyUrlSlug = action.metadata.storyUrlSlug.toString(); }
			const slugArray = action.metadata.storyUrlSlug.split('-');
			const isLastElementInt = parseInt(slugArray[slugArray.length - 1]);
			if (isLastElementInt) {
				iterationNumber = isLastElementInt + 1;
			} else {
				iterationNumber = 1;
			}
		}
		if (action.editorFormData && action.editorFormData.storyType && action.editorFormData.storyType === 'WEBINAR') {
			({ registrationForm } = action);
			if (registrationForm && registrationForm.length) {
				registrationForm = registrationForm.map((item) => {
					if (item.order === 99999) {
						item.selected = false;
						item.isRequired = false;
						item.order = null;
					}
					item.changed = false;
					return item;
				});
			}
			formHash = registrationForm.reduce((obj, item) => {
				obj[item.id] = { ...item };
				return obj;
			}, {});

			if (!(action.metadata && action.metadata.curatedCategory)) {
				action.metadata.curatedCategory = action.curatedLists && action.curatedLists.ystvCategoryTypes
					? action.curatedLists.ystvCategoryTypes.filter((item) => item.label === 'Webinars')[0] : null;
			}
			if (action.webinar && action.webinar.startTime && action.webinar.endTime) {
				action.webinar.status = getWebinarStatus(action.webinar.startTime, action.webinar.endTime);
				action.webinar.origStartTime = action.webinar.startTime;
				action.webinar.origEndTime = action.webinar.endTime;
				action.webinar.origLiveStreamConfig = action.webinar.liveStreamConfig;
			}
		}
		if (action.editorFormData && action.editorFormData.postContent && action.currentBrand && action.currentBrand.slug === 'brands-of-new-india') {
			boniComposer = action.editorFormData.postContent;
		}
		if (action.editorFormData && action.editorFormData.storyType && action.editorFormData.storyType === 'GLOSSARY' && action.metadata.parentGlossary) {
			action.metadata.parentGlossary = {
				value: action.metadata.parentGlossary?.id,
				name: action.metadata.parentGlossary?.title,
				slug: action.metadata.parentGlossary?.title,
				label: action.metadata.parentGlossary?.title,
				...action.metadata.parentGlossary,
			};
		} else if (action.editorFormData && !action?.editorFormData?.postContent && action.currentBrand && action.currentBrand.slug === 'brands-of-new-india') {
			boniComposer.brandSection = [{
				title: '',
				question: '',
				answer: '',
				banner: '',
				index: 0,
			}, {
				title: '',
				question: '',
				answer: '',
				banner: '',
				index: 1,
			}];
		}
		return state
			.set('loading', false)
			.set('error', false)
			.set('editStory', action.story)
			.set('status', action.story.status)
			.set('editorFormData', action.editorFormData)
			.set('storyMetadata', action.metadata)
			.set('formMetadata', Object.keys(action.metadata).length > 0 ? { ...state.get('formMetadata'), ...action.metadata } : ({ ...state.get('formMetadata') }))
			.set('storyId', action.story.storyId)
			.set('isContentDuplicate', action.metadata.canonicalUrl && action.metadata.canonicalUrl.length > 0)
			.set('focusKeyphrase', action.metadata.focusKeyphrase || '')
			.set('plagiarismData', action.plagiarismData ? action.plagiarismData.allViewLink : null)
			.set('selectedAction', getSelectedAction(action.story.status, state.get('isUserEditor'), action.editorFormData.storyType))
			.set('allSectors', action.curatedLists && action.curatedLists.sectorTypes)
			.set('ystvCategories', action.curatedLists && action.curatedLists.ystvCategoryTypes)
			.set('snippetsData', action.snippets)
			.set('registrationForm', registrationForm)
			.set('registrationFormOrig', formHash)
			.set('webinar', action.webinar ? action.webinar : {
				startTime: '',
				endTime: '',
				disclaimer: null,
				liveStreamConfig: null,
			})
			.set('tag1Types', action.curatedLists && action.curatedLists.webinarTagATypes)
			.set('tag2Types', action.curatedLists && action.curatedLists.webinarTagBTypes)
			.set('allArticleTopics', action.curatedLists && action.curatedLists.articleTopicTypes)
			.set('iterationNumber', iterationNumber)
			.set('originStorySlug', originStorySlug)
			.set('addedToTopPicks', !!(action.metadata && action.metadata.editorsPickTitle))
			.set('allRichArticleTopics', action.curatedLists && action.curatedLists.richArticleTopicTypes)
			.set('paywallSubtypeOptions', action.paywallSubtypes)
			.set('boniComposer', boniComposer);
	}
	case EDITOR_ON_FORM_DATA_CHANGE: {
		const { changedData } = action;
		const editorFormData = { ...state.get('editorFormData') };
		const formMetadata = { ...state.get('formMetadata') };
		// removed due to CP-424 urls getting changed on edit
		// const iterationNumber = state.get('iterationNumber');
		// const originStorySlug = state.get('originStorySlug');

		if (changedData.name === 'editor') {
			editorFormData.postContent = changedData.postContent;
			editorFormData.postJson = changedData.postJson;
			formMetadata.plainText = changedData.plainText;
			formMetadata.timeToRead = getTimeToRead(changedData.plainText);
		} else {
			editorFormData[changedData.name] = changedData.value;
			if (changedData.name === 'slug') {
				// removed due to CP-424 urls getting changed on edit
				// if (changedData.modifySlug && changedData.value) {
				// 	const slugArray = originStorySlug.split('-');
				// 	if (parseInt(slugArray[slugArray.length - 1])) {
				// 		const slug = originStorySlug.substring(0, originStorySlug.length - iterationNumber.toString().length - 1);
				// 		changedData.value = `${slug}-${iterationNumber}`;
				// 	} else {
				// 		changedData.value = `${originStorySlug}-${iterationNumber}`;
				// 	}
				// }
				formMetadata.storyUrlSlug = changedData.value;
				formMetadata.storyUrl = formMetadata.storyUrlPrefix + changedData.value;
			} else if (changedData.name === 'series') {
				if (editorFormData.storyType === 'WEBINAR' && changedData.value && changedData.value.isRegistrationRequired) formMetadata.isRegistrationRequired = true;
			}
		}
		return state
			.set('editorFormData', editorFormData)
			.set('formMetadata', formMetadata);
	}
	case EDITOR_ON_FORM_METADATA_CHANGE: {
		const { changedData } = action;
		const formMetadata = { ...state.get('formMetadata') };
		const editorFormData = { ...state.get('editorFormData') };
		if (changedData.name === 'featuredVideo') {
			editorFormData.mediaId = changedData.mediaId;
			formMetadata.videoDuration = changedData.duration;
			formMetadata.videoThumbnail = changedData.thumbnail;
		} else if (changedData.name === 'socialPreview') {
			formMetadata[changedData.titleKey] = changedData.title;
			formMetadata[changedData.excerptKey] = changedData.excerpt;
		} else if (changedData.name === 'sponsoredStory') {
			formMetadata[changedData.name] = changedData.value;
			if (!changedData.value) {
				formMetadata.brandSpotlight = null;
				formMetadata.brandSpotlightSponsors = null;
				formMetadata.removeAds = false;
			} else {
				formMetadata.removeAds = true;
			}
		} else if (changedData.name === 'addToSeries') {
			formMetadata[changedData.name] = changedData.value;
			if (!changedData.value) {
				editorFormData.series = null;
			}
		} else if (changedData.name === 'banner') {
			formMetadata[changedData.name] = changedData.value;
			formMetadata.parallaxBanner = changedData.parallax;
		} else if (changedData.name === 'storyUrlPrefix') {
			const currSlug = formMetadata.storyUrlSlug;
			if (!/\/webinar\/|\/session\//.test(formMetadata.storyUrl)) {
				formMetadata[changedData.name] = changedData.value;
			} else {
				formMetadata.storyUrlPrefix = formMetadata.storyUrlPrefix.replace(/\/webinar\/|\/session\//, changedData.value);
			}
			formMetadata.storyUrl = formMetadata.storyUrlPrefix + currSlug;
		} else if (changedData.name === 'paywallType') {
			formMetadata[changedData.name] = changedData.value;
			formMetadata.paywallSubtype = null;
		} else if (changedData.name === 'storySchemaType') {
			formMetadata[changedData.name] = changedData.value?.value;
		} else {
			formMetadata[changedData.name] = changedData.value;
		}
		return state
			.set('editorFormData', editorFormData)
			.set('formMetadata', formMetadata);
	}
	case EDITOR_HANDLE_BLUR: {
		const touched = { ...state.get('formTouched') };
		touched[action.element] = true;
		return state
			.set('formTouched', touched);
	}
	case EDITOR_HAS_ERRORS:
		return state
			.set('hasErrors', action.errors);

	case SET_DEFAULT_SOCIAL_EXCERPT: {
		const formMetadata = { ...state.get('formMetadata') };
		if (action.excerpt && action.excerpt.length > 0) {
			if (!formMetadata.facebookExcerpt || formMetadata.facebookExcerpt.length < 1) {
				formMetadata.facebookExcerpt = action.excerpt.substring(0, 299);
			}
			if (!formMetadata.twitterExcerpt || formMetadata.twitterExcerpt.length < 1) {
				formMetadata.twitterExcerpt = action.excerpt.substring(0, 299);
			}
			if (!formMetadata.searchExcerpt || formMetadata.searchExcerpt.length < 1) {
				formMetadata.searchExcerpt = action.excerpt.substring(0, 299);
			}
		}
		return state
			.set('formMetadata', formMetadata);
	}
	case SET_DEFAULT_SOCIAL_TITLE: {
		const formMetadata = { ...state.get('formMetadata') };
		if (!formMetadata.facebookTitle || formMetadata.facebookTitle.length < 1) {
			formMetadata.facebookTitle = action.title;
		}
		if (!formMetadata.twitterTitle || formMetadata.twitterTitle.length < 1) {
			formMetadata.twitterTitle = action.title;
		}
		if (!formMetadata.searchTitle || formMetadata.searchTitle.length < 1) {
			formMetadata.searchTitle = action.title;
		}
		return state
			.set('formMetadata', formMetadata);
	}
	case SET_DEFAULT_SOCIAL_IMAGE: {
		const formMetadata = { ...state.get('formMetadata') };

		if (!formMetadata.facebookFeaturedImage || formMetadata.facebookFeaturedImage.length < 1) {
			formMetadata.facebookFeaturedImage = action.image;
		}
		if (!formMetadata.twitterFeaturedImage || formMetadata.twitterFeaturedImage.length < 1) {
			formMetadata.twitterFeaturedImage = action.image;
		}
		if (!formMetadata.searchFeaturedImage || formMetadata.searchFeaturedImage.length < 1) {
			formMetadata.searchFeaturedImage = action.image;
		}
		return state
			.set('formMetadata', formMetadata);
	}
	case EDITOR_ON_FOCUS_KEYPHRASE_CHANGE:
		return state
			.set('focusKeyphrase', action.keyphrase)
			.set('formMetadata', { ...state.get('formMetadata'), focusKeyphrase: action.keyphrase });
	case EDITOR_RUN_SEO_ANALYSIS:
		return state
			.set('seoLoading', true);
	case EDITOR_RUN_SEO_ANALYSIS_SUCCESS:
		return state
			.set('seoLoading', false)
			.set('seoAnalysis', action.analysis);
	case SET_EDITOR_ACTION: {
		const editorFormData = { ...state.get('editorFormData') };
		const selectedAction = action.value;
		if (!['SCHEDULED', 'PUBLISHED'].includes(selectedAction.value)) {
			editorFormData.publishedAt = null;
		}
		return state
			.set('selectedAction', selectedAction)
			.set('editorFormData', editorFormData);
	}
	case UPDATE_STORY: {
		let formMessage = STORY_UPDATING_MESSAGE;
		const editorFormData = { ...state.get('editorFormData') };
		if (editorFormData.storyType === 'VIDEO') {
			formMessage = VIDEO_UPDATING_MESSAGE;
		} else if (editorFormData.storyType === 'WEBINAR') {
			formMessage = WEBINAR_UPDATING_MESSAGE;
		}
		return state
			.set('updating', true)
			.set('formMessage', formMessage);
	}
	case UPDATE_STORY_SUCCESS: {
		let formMessage = STORY_UPDATE_SUCCESS_MESSAGE;
		const editorFormData = { ...state.get('editorFormData') };
		if (editorFormData && editorFormData.postJson && editorFormData.postJson.ops) {
			const totalParagraphCount = editorFormData.postJson.ops.reduce((acc, index) => {
				if (index.insert.length > 10) {
					const count = index.insert.replace(/\n$/gm, '').split(/\n/).length;
					acc += count;
				}
				return acc;
			}, 0);
			editorFormData.totalParagraphCount = totalParagraphCount;
		} else {
			editorFormData.totalParagraphCount = null;
		}
		if (editorFormData.storyType === 'VIDEO') {
			formMessage = VIDEO_UPDATE_SUCCESS_MESSAGE;
		} else if (editorFormData.storyType === 'WEBINAR') {
			formMessage = WEBINAR_UPDATE_SUCCESS_MESSAGE;
		}
		if (action.status === 'PUBLISHED') {
			formMessage = STORY_PUBLISH_SUCCESS_MESSAGE;
			if (editorFormData.storyType === 'VIDEO') {
				formMessage = VIDEO_PUBLISH_SUCCESS_MESSAGE;
			} else if (editorFormData.storyType === 'WEBINAR') {
				formMessage = WEBINAR_PUBLISH_SUCCESS_MESSAGE;
			}
		}
		return state
			.set('updating', false)
			.set('editorFormData', editorFormData)
			.set('formMessage', formMessage);
	}
	case UPDATE_STORY_FAILURE: {
		let formMessage = STORY_UPDATE_FAILURE_MESSAGE;
		const editorFormData = { ...state.get('editorFormData') };
		if (editorFormData && editorFormData.postJson && editorFormData.postJson.ops) {
			const totalParagraphCount = editorFormData.postJson.ops.reduce((acc, index) => {
				if (index.insert.length > 10) {
					const count = index.insert.replace(/\n$/gm, '').split(/\n/).length;
					acc += count;
				}
				return acc;
			}, 0);
			editorFormData.totalParagraphCount = totalParagraphCount;
		} else {
			editorFormData.totalParagraphCount = null;
		}
		if (editorFormData.storyType === 'VIDEO') {
			formMessage = VIDEO_UPDATE_FAILURE_MESSAGE;
		} else if (editorFormData.storyType === 'WEBINAR') {
			formMessage = WEBINAR_UPDATE_FAILURE_MESSAGE;
		}
		if (action.status === 'PUBLISHED') {
			formMessage = STORY_PUBLISH_FAILURE_MESSAGE;
			if (editorFormData.storyType === 'VIDEO') {
				formMessage = VIDEO_PUBLISH_FAILURE_MESSAGE;
			} else if (editorFormData.storyType === 'WEBINAR') {
				formMessage = WEBINAR_PUBLISH_FAILURE_MESSAGE;
			}
		}
		return state
			.set('updating', false)
			.set('editorFormData', editorFormData)
			.set('formMessage', formMessage);
	}
	case SET_EDITOR_FORM_MESSAGE:
		return state
			.set('formMessage', action.message);
	case EDITOR_SET_ALL_ELEMENTS_BLUR: {
		const touched = {
			title: true,
			brand: true,
			thumbnail: true,
			thumbnailSquare: true,
			thumbnailDouble: true,
			thumbnailVideo: true,
			storyUrlSlug: true,
			featuredVideo: true,
			canonicalUrl: true,
			author: true,
			category: true,
			facebook: true,
			twitter: true,
			search: true,
			sectors: true,
			articleType: true,
			marketingComments: true,
			marketingPost: true,
			startTime: true,
			endTime: true,
			portraitThumbnail: true,
			speakers: true,
			moderators: true,
			curatedCategory: true,
			articleTopics: true,
			protected: true,
			richArticleTopic: true,
			paywallSubtype: true,
			storyFormat: true,
			editorsPickTitle: true,
			editorsPickSubtitle: true,
			editorsPickParentUrl: true,
			editorsPickStartTime: true,
			editorsPickEndTime: true,
			subtitle: true,
			tags: true,
			companyBrand: true,
			insideLookTitle: true,
			insideLookDesc: true,
			offerHeading: true,
			offerSubHeading: true,
			offerCta: true,
			productTitle: true,
			productQuestion: true,
			productAnswer: true,
			products: true,
			brandSection: true,
			prelude: true,
			contentType: true,
			translatedFromUrl: true,
			storySchemaType: true,
			AIGeneratedStory: true,
			storyShortHeading: true,
			'schemaData.HowTo.StepsText': true,
			webStoryUrl: true,
			parentGlossary: true,
			NewsRoundUp: true,
		};
		return state
			.set('formTouched', touched);
	}
	case EDITOR_RUN_PLAGIARISM_CHECK:
		return state
			.set('loadingPlagiarism', true);
	case EDITOR_RUN_PLAGIARISM_CHECK_SUCCESS: {
		const editorFormData = { ...state.get('editorFormData') };
		editorFormData.plagiarismCheck = action.plagiarismData.percent;
		return state
			.set('loadingPlagiarism', false)
			.set('plagiarismData', action.plagiarismData.allViewLink)
			.set('editorFormData', editorFormData);
	}
	case EDITOR_RUN_PLAGIARISM_CHECK_FAILURE: {
		return state
			.set('formMessage', COPY_SCAPE_FAILURE_MESSAGE)
			.set('loadingPlagiarism', false);
	}
	case SET_EDITOR_SOCKET_DATA:
		return state
			.set('socketData', formatSocketData(state, action, 'socketData'));
	case UPDATE_EDITOR_DOC_CONVERSION_DATA: {
		return state
			.set('docConversion', formatDataForState(state.get('docConversion'), action.value));
	}
	case UPDATE_EDITOR_DOC_CONVERTED_DELTA: {
		const editorFormData = state.get('editorFormData');
		editorFormData.postJson = action.value.delta;
		editorFormData.postContent = action.value.html;
		return state
			.set('editorFormData', editorFormData);
	}
	case FETCH_EDITOR_STORY_REVISIONS:
		return state
			.set('revisionLoading', true);
	case FETCH_EDITOR_STORY_REVISIONS_SUCCESS:
		return state
			.set('revisionLoading', false)
			.set('storyRevisions', action.revisions);
	case FETCH_EDITOR_STORY_REVISIONS_FAILURE:
		return state
			.set('revisionLoading', false);
	case ADD_YOUTUBE_VIDEO:
		return state
			.set('videoLoading', true);
	case ADD_YOUTUBE_VIDEO_SUCCESS: {
		const editorFormData = { ...state.get('editorFormData') };
		const formMetadata = { ...state.get('formMetadata') };
		const { asset } = action;
		editorFormData.videoPlatform = 'youtube';
		editorFormData.mediaId = asset.mediaId;
		editorFormData.videoTitle = asset.title;
		formMetadata.videoDuration = asset.duration;
		formMetadata.videoThumbnail = asset.thumbnail;
		return state
			.set('editorFormData', editorFormData)
			.set('formMetadata', formMetadata)
			.set('videoLoading', false);
	}
	case ADD_YOUTUBE_VIDEO_FAILURE:
		return state
			.set('videoLoading', false);
	case REPLACE_VIDEO: {
		const editorFormData = { ...state.get('editorFormData') };
		const formMetadata = { ...state.get('formMetadata') };
		editorFormData.videoPlatform = null;
		editorFormData.mediaId = null;
		editorFormData.videoTitle = null;
		formMetadata.videoDuration = null;
		formMetadata.videoThumbnail = null;
		return state
			.set('editorFormData', editorFormData)
			.set('formMetadata', formMetadata);
	}
	case ADD_VIDEO_FROM_GALLERY: {
		const editorFormData = state.get('editorFormData');
		const formMetadata = state.get('formMetadata');
		const { videoData } = action;
		editorFormData.mediaId = videoData.mediaId;
		editorFormData.videoPlatform = videoData.videoPlatform;
		editorFormData.videoTitle = videoData.videoTitle;
		formMetadata.videoDuration = videoData.duration;
		formMetadata.videoThumbnail = videoData.thumbnail;
		return state
			.set('editorFormData', editorFormData)
			.set('formMetadata', formMetadata);
	}
	case UPDATE_SNIPPETS: {
		let snippets = { ...state.get('snippetsData') };
		if (!snippets) snippets = {};
		snippets[action.snippetType][action.index] = action.changedData;
		return state
			.set('snippetsData', snippets);
	}
	case SET_CONTENT_DUPLICATE: {
		const formMetadata = { ...state.get('formMetadata') };
		if (!action.value) {
			formMetadata.canonicalUrl = '';
		}
		return state
			.set('formMetadata', formMetadata)
			.set('isContentDuplicate', action.value);
	}
	case AUTO_SAVE_STORY:
		return state
			.set('autosaving', { status: true, message: 'Saving...' });
	case AUTO_SAVE_STORY_SUCCESS:
		return state
			.set('autosaving', { status: true, message: 'Saved' });
	case AUTO_SAVE_STORY_FAILURE:
		return state
			.set('autosaving', { status: true, message: 'Error' });
	case CLEAR_AUTO_SAVE_MESSAGE:
		return state
			.set('autosaving', { status: false, message: null });
	case CLEAR_EDITOR_DATA:
		return initialState;
	case UPDATE_WEBINAR_BASIC_INFO: {
		const webinar = { ...state.get('webinar') };
		const { changedData } = action;
		if (changedData.name !== 'resetTime') {
			webinar[changedData.name] = changedData.value;
			if (['endTime', 'startTime'].includes(changedData.name)) {
				webinar.status = getWebinarStatus(webinar.startTime, webinar.endTime);
			}
		} else {
			webinar.startTime = webinar.origStartTime;
			webinar.endTime = webinar.origEndTime;
			webinar.liveStreamConfig = webinar.origLiveStreamConfig;
			webinar.status = getWebinarStatus(webinar.startTime, webinar.endTime);
		}
		return state
			.set('webinar', webinar);
	}
	case UPDATE_WEBINAR_REGISTRATION_FORM_DATA: {
		let registrationForm = Object.assign([], state.get('registrationForm'));
		const registrationFormOrig = Object.assign([], state.get('registrationFormOrig'));
		const { changedData } = action;
		if (action.added) {
			registrationForm.push(changedData.value);
			registrationFormOrig[changedData.value.id] = changedData.value;
			return state
				.set('registrationForm', _.cloneDeep(registrationForm))
				.set('registrationFormOrig', _.cloneDeep(registrationFormOrig));
		}
		let maxOrder = 0;

		if (changedData.value.selected && registrationForm[changedData.index].order === null) {
			const maxObj = _.maxBy(registrationForm, 'order');
			if (maxObj) maxOrder = maxObj.order + 1;
			changedData.value.order = maxOrder;
		}

		registrationForm[changedData.index] = Object.assign(registrationForm[changedData.index], changedData.value);
		registrationForm[changedData.index].changed = false;

		if (!_.isEqual(registrationForm[changedData.index], registrationFormOrig[registrationForm[changedData.index].id])) {
			registrationForm[changedData.index].changed = true;
		}

		// sort if newly selected
		if (maxOrder !== 0) {
			const temp = registrationForm.splice(changedData.index, 1);
			registrationForm.splice(maxOrder, 0, temp[0]);
		}

		// sort if removed
		if (changedData.value.order === null) {
			const maxObj = _.maxBy(registrationForm, 'order');
			if (maxObj) {
				maxOrder = maxObj.order - 1;
				registrationForm.sort((a, b) => {
					if (a.order === b.order) {
						return 0;
					}
					if (a.order === null) {
						return 1;
					}
					if (b.order === null) {
						return -1;
					}

					return a < b ? -1 : 1;
				});
				registrationForm = registrationForm.map((item, index) => {
					if (index <= maxOrder && index >= changedData.index) {
						item.order = index;
					}
					return item;
				});
			}
		}
		if (maxOrder === 0) {
			registrationForm.sort((a, b) => {
				if (a.order === b.order) {
					return 0;
				}
				if (a.order === null) {
					return 1;
				}
				if (b.order === null) {
					return -1;
				}

				return a < b ? -1 : 1;
			});
		}
		return state
			.set('registrationForm', _.cloneDeep(registrationForm));
	}
	case FORM_ELEMENT_ORDER_CHANGE: {
		const currIndex = action.curr;
		const finalIndex = action.final;
		let registrationForm = _.cloneDeep(state.get('registrationForm'));

		if (currIndex !== finalIndex) {
			const temp = registrationForm.splice(currIndex, 1);
			registrationForm.splice(finalIndex, 0, temp[0]);

			let highLimit = finalIndex; let
				lowLimit = currIndex;
			if (currIndex > finalIndex) {
				highLimit = currIndex;
				lowLimit = finalIndex;
			}
			registrationForm = registrationForm.map((item, index) => {
				if (index <= highLimit && index >= lowLimit) {
					return { ...item, changed: true, order: index };
				}
				return item;
			});
		}
		return state
			.set('registrationForm', registrationForm);
	}
	case FETCH_WEBINAR_QUESTIONS:
		return state
			.set('questionsLoading', true);
	case FETCH_WEBINAR_QUESTIONS_SUCCESS: {
		let questionsAvailable = state.get('questionsAvailable');
		if (action.reset) {
			questionsAvailable = 0;
		}
		return state
			.set('questions', action.questions)
			.set('questionsCount', action.questionsCount)
			.set('questionOffset', action.offset + 10)
			.set('questionsLoading', false)
			.set('questionsAvailable', questionsAvailable);
	}
	case CHECK_FOR_NEW_QUESTIONS_SUCCESS:
		return state
			.set('questionsAvailable', action.count);
	case GENERATE_WEBINAR_REPORT: {
		return state
			.set('reportGenerateLoading', true)
			.set('reportMessage', null)
			.set('webin');
	}
	case REPORT_GENERATE_COMPLETE: {
		const webinar = { ...state.get('webinar') };
		if (!action.error && action.report) {
			webinar.report = { ...action.report };
		}
		return state
			.set('reportGenerateLoading', false)
			.set('reportMessage', action.message)
			.set('webinar', webinar);
	}
	case DOWNLOAD_WEBINAR_REPORT: {
		return state
			.set('reportDownloadLoading', true)
			.set('reportMessage', null);
	}
	case REPORT_DOWNLOAD_COMPLETE: {
		const webinar = { ...state.get('webinar') };
		if (!action.error && action.report) {
			webinar.report = { ...action.report };
		}
		return state
			.set('reportDownloadLoading', false)
			.set('reportMessage', action.message)
			.set('webinar', webinar);
	}
	case FETCH_LIVE_STREAM_TYPES: {
		const webinar = { ...state.get('webinar') };
		webinar.loadingStreams = true;
		return state
			.set('webinar', webinar);
	}

	case FETCH_LIVE_STREAM_TYPES_FAILURE: {
		const hasErrors = { ...state.get('hasErrors') };
		hasErrors.liveStreams = true;
		return state
			.set('hasErrors', hasErrors);
	}

	case FETCH_LIVE_STREAM_TYPES_SUCCESS: {
		const hasErrors = { ...state.get('hasErrors') };
		hasErrors.liveStreams = false;
		const webinar = { ...state.get('webinar') };
		webinar.loadingStreams = false;
		if (webinar.liveStreamConfig && !(action.streamTypes.find((stream) => stream.channelId === webinar.liveStreamConfig.channelId))) {
			webinar.liveStreamConfig = null;
		}
		return state
			.set('liveStreamTypes', action.streamTypes)
			.set('webinar', webinar);
	}

	case SET_UNIQUE_SLUG: {
		return state.set('uniqueSlug', action.value);
	}

	case SET_TOP_PICK: {
		return state.set('addedToTopPicks', action.value);
	}

	case CLEAR_TOP_PICKS_DATA: {
		const formMetadata = { ...state.get('formMetadata') };
		formMetadata.editorsPickTitle = null;
		formMetadata.editorsPickSubtitle = null;
		formMetadata.editorsPickStartTime = null;
		formMetadata.editorsPickEndTime = null;
		formMetadata.editorsPickParentUrl = null;
		return state
			.set('formMetadata', formMetadata);
	}

	case BONI_COMPOSER_CHANGE: {
		const { changedData } = action;
		const boniComposer = { ...state.get('boniComposer') };
		if (changedData.section && changedData.section === 'brand') {
			if (boniComposer.brandSection) {
				const previousData = boniComposer.brandSection[changedData.index];
				boniComposer.brandSection[changedData.index] = { ...previousData, [changedData.name]: changedData.value };
			} else {
				boniComposer.brandSection = [];
				boniComposer.brandSection[changedData.index] = { [changedData.name]: changedData.value };
			}
		} else {
			boniComposer[changedData.name] = changedData.value;
		}
		return state
			.set('boniComposer', boniComposer);
	}
	case BONI_COMPOSER_BRNAD_SECTION_CHANGE: {
		const { changedData } = action;
		const boniComposer = { ...state.get('boniComposer') };
		if (changedData?.section && changedData?.section === 'brand') {
			if (boniComposer.brandSection && boniComposer.brandSection.length > 0
				&& boniComposer.brandSection.length >= 2 && boniComposer.brandSection.length <= 8) {
				const previousData = boniComposer.brandSection;
				changedData.type === 1 ? previousData.push({
					title: '',
					question: '',
					answer: '',
					banner: '',
					index: changedData.index,
				}) : previousData.splice(changedData.index, 1);
				boniComposer.brandSection = previousData;
			}
		}
		return state
			.set('boniComposer', boniComposer);
	}
	default:
		return state;
	}
}

const formatSocketData = (state, action, variableName) => {
	let data = _.clone(state.get(variableName, true));
	if (!data) {
		data = {};
	}
	if (action.data) {
		Object.keys(action.data).forEach((key) => {
			data[key] = action.data[key];
		});
		return data;
	}
	return data;
};

const getSelectedAction = (status, editor, type) => {
	let selectedAction;
	switch (status) {
	case 'PUBLISHED':
		if (editor) {
			selectedAction = ACTION_OPTIONS.PUBLISHED;
		} else {
			selectedAction = ACTION_OPTIONS.REPUBLISH;
		}
		break;
	case 'SCHEDULED':
		if (editor) {
			selectedAction = ACTION_OPTIONS.SCHEDULED;
		} else {
			selectedAction = ACTION_OPTIONS.INREVIEW;
		}
		break;
	case 'INREVIEW':
		if (editor) {
			selectedAction = ACTION_OPTIONS.PUBLISHED;
		} else {
			selectedAction = ACTION_OPTIONS.INREVIEW;
		}
		break;
	default:
		selectedAction = type === 'WEBINAR' ? ACTION_OPTIONS.PUBLISHED : ACTION_OPTIONS.INREVIEW;
		break;
	}
	if (type === 'WEBINAR') {
		if (status === 'TRASHED') {
			selectedAction = ACTION_OPTIONS.RESTORE;
		}
	}
	return selectedAction;
};

const getWebinarStatus = (startTime, endTime) => {
	const end = new Date(endTime);
	const start = new Date(startTime);
	const today = new Date();
	if (end < today) {
		return 'ENDED';
	} if (end > today && start > today) {
		return 'UPCOMING';
	} if (end > today && start <= today) {
		return 'LIVE';
	}
	return undefined;
};

export default editorReducer;
