import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import { ListItemWrapper, Button } from '../../WebinarComponents/styles';

const ListItem = (props) => {
	const {
		job, slNo, selectedStatus, acceptJob, rejectJob, viewBio, history,
	} = props;
	const {
		name, emailId, phoneNo, createdAt, status, updatedAt, rejectReason, resumeLink, id,
	} = job;

	return (
		<ListItemWrapper slNo={slNo}>
			<ReactTooltip />
			<Col xs={2} sm={1} md={1}>
				<span>{slNo}</span>
			</Col>
			<Col xs={6} sm={2} md={2}>
				<p className="m-0" type="title">{name.length > 60 ? `${name.substring(0, 60)}... ` : name}</p>
			</Col>
			<Col xs={6} sm={2} md={2}>
				<p className="m-0" type="title">{emailId.length > 60 ? `${emailId.substring(0, 60)}... ` : emailId}</p>
			</Col>
			<Col xs={6} sm={2} md={2}>
				<p className="m-0" type="title">{phoneNo.length > 60 ? `${phoneNo.substring(0, 60)}... ` : phoneNo}</p>
			</Col>
			<Col xs={false} sm={2} md={1}>
				<span>{createdAt ? moment(createdAt).format('DD MMM YYYY hh:mm A') : 'Not Available'}</span>
			</Col>
			{selectedStatus === 1 && (
				<Col xs={2} sm={1} md={1}>
					<p className="m-0" type="title">{status === 1 ? 'Parsed' : 'Not Parsed'}</p>
				</Col>
			)}
			{selectedStatus === 2 && (
				<Col xs={2} sm={2} md={1}>
					<span>{updatedAt ? moment(updatedAt).format('DD MMM YYYY hh:mm A') : 'Not Available'}</span>
				</Col>
			)}
			{selectedStatus === 3 && (
				<Col xs={2} sm={1} md={1}>
					<span>{updatedAt ? moment(updatedAt).format('DD MMM YYYY hh:mm A') : 'Not Available'}</span>
				</Col>
			)}
			{selectedStatus === 3 && (
				<Col xs={2} sm={1} md={1}>
					<p className="m-0" type="title">{rejectReason.length > 60 ? `${rejectReason.substring(0, 60)}... ` : rejectReason}</p>
				</Col>
			)}
			{(selectedStatus === 1) && (
				<Col xs={2} sm={2} md={2}>
					<Button view title="edit The Resume" onClick={() => history.push(`/admin-job/editor/${id}`)}>
						<i className="fa fa-pencil-square fa-lg" />
					</Button>
					<Button view title="View The Resume" onClick={() => window.open(resumeLink)}>
						{/* <i className="fa fa-pencil-square fa-lg" /> */}
						<i className="fa fa-file fa-lg" />
					</Button>
					<Button view title="Accept The Job" onClick={acceptJob}>
						<i className="fa fa-check-square fa-lg" />
					</Button>
					<Button view title="Reject The Job" onClick={rejectJob}>
						<i className="fa fa-times-circle fa-lg" />
					</Button>
				</Col>
			)}
			{(selectedStatus === 2) && (
				<Col xs={2} sm={2} md={2}>
					<Button view title="edit The Resume" onClick={() => history.push(`/admin-job/editor/${id}`)}>
						<i className="fa fa-pencil-square fa-lg" />
					</Button>
					<Button view title="View The Resume" onClick={() => window.open(resumeLink)}>
						{/* <i className="fa fa-pencil-square fa-lg" /> */}
						<i className="fa fa-file fa-lg" />
					</Button>
					<Button view title="View The Bio" onClick={viewBio}>
						<i className="fa fa-file-alt fa-lg" />
					</Button>
					<Button view title="Reject The Job" onClick={rejectJob}>
						<i className="fa fa-times-circle fa-lg" />
					</Button>
				</Col>
			)}
			{(selectedStatus === 3) && (
				<Col xs={2} sm={2} md={2}>
					<Button view title="edit The Resume" onClick={() => history.push(`/admin-job/editor/${id}`)}>
						<i className="fa fa-pencil-square fa-lg" />
					</Button>
					<Button view title="View The Resume" onClick={() => window.open(resumeLink)}>
						<i className="fa fa-file fa-lg" />
					</Button>
					<Button view title="View The Bio" onClick={viewBio}>
						<i className="fa fa-file-alt fa-lg" />
					</Button>
					<Button view title="Accept The Job" onClick={acceptJob}>
						<i className="fa fa-check-square fa-lg" />
					</Button>
				</Col>
			)}
		</ListItemWrapper>
	);
};

ListItem.propTypes = {
	slNo: T.number,
	job: T.object,
	selectedStatus: T.number,
	acceptJob: T.func,
	rejectJob: T.func,
	viewBio: T.func,
	history: T.object,
};

export default ListItem;
