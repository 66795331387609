export const ASSET_BASE_API_URL = '/api/v2/rw/assets';
export const VIDEO_BASE_API_URL = '/api/v2/rw/video';

export const MEDIA_CREATE_LOAD_MESSAGE = 'Saving...';
export const MEDIA_CREATE_SUCCESS_MESSAGE = 'Media Saved Successfully. Your media will be available for use in 20 minutes';
export const MEDIA_UPDATE_SUCCESS_MESSAGE = 'Media Updated Successfully. Your media will be available for use in 20 minutes';
export const MEDIA_UPDATE_LOAD_MESSAGE = 'Updating...';
export const MEDIA_DELETE_SUCCESS_MESSAGE = 'Media Deleted Successfully';
export const MEDIA_DELETE_LOADING_MESSAGE = 'Deleting...';
export const MEDIA_ERROR_MESSAGE = 'Something went wrong. Try Again!';

export const ALL_BRAND_OBJECT = {
	id: 0,
	name: 'All',
	slug: 'all',
	internal: true,
};
