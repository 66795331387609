import styled from 'styled-components';

const ImagePlaceHolder = styled.div`
		width: 5rem;
		height: 5rem;
		background-color: #eee;
		text-align: center;
		vertical-align: middle;
		font-weight: bold;
		color: grey;
		display: table-cell;
		cursor: pointer;
`;

const DropdownOptionContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const CenteredText = styled.div`
	display: flex;
	text-align: center;
	justif-content: center;
	align-items: center;
`;

export {
	ImagePlaceHolder,
	DropdownOptionContainer,
	CenteredText,
};
