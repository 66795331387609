import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import T from 'prop-types';

class ScrollToTop extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0);
		}
	}

	render() {
		return this.props.children;
	}
}

ScrollToTop.propTypes = {
	location: T.object,
	children: T.any,
};

export default withRouter(ScrollToTop);
