import React, { useState, useEffect } from 'react';
import T from 'prop-types';
import Modal from 'react-modal';
import uuid from 'react-uuid';
import styled, { css } from 'styled-components';

import { Col, Row } from '../Responsive';
import {
	Button, WrapperContainer,
} from '../Styles';
import { CURATED_SET_CONFIG_URL } from '../../containers/TechSparksManager/constants';
import SpeakerForm from './SpeakerForm';
import { LoadingIcon } from '../Basic';
import { extractedCityName } from '../../utils/common';

const Card = styled.div`
	border: 1px solid #ddd;
	height: 300px;
	width: 200px;
	padding: 5px;
	margin: 0.25rem;
	background-color: #fff;
	cursor: pointer;
	font-size: 0.8rem;
	word-wrap: break-word;
	cursor: pointer;
	display: block;
	position: relative;
	overflow: auto;
`;

const CardWrapper = styled(Row)`
	border: 1px solid #bbb;
	background-color: #eee;
	padding: 0.5rem;
	width: 100%;
	position: relative;
	overflow: auto;
	height: 75vh;
	${(props) => props.source === 'fullPage' && css`
		height: 75vh;
	`}
`;

const VIewAllSpeakers = (props) => {
	const { pageTitle, match } = props;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [speakers, setSpeakers] = useState([]);
	const [currentSpeaker, setCurrentSpeaker] = useState({});
	const [loading, setLoading] = useState(false);
	const extractCityName = extractedCityName(match.params.city);

	useEffect(() => {
		pageTitle('viewAllSpeakers');
		getSpeakers();
	}, []);

	async function getSpeakers() {
		const url = `${CURATED_SET_CONFIG_URL}${match?.params?.city ? match.params.city === 'mumbai' ? `${match.params.city}_`
			: match.params.city && match.params.city.includes('techsparks_2023') ? `2023_${extractCityName}` : '' : ''}viewall_speakers?brand=2`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		setLoading(true);
		const response = await fetch(url, options);
		const data = await response.json();
		setSpeakers(data);
		setLoading(false);
	}

	function unmountModal() {
		setIsModalOpen(false);
		setCurrentSpeaker(null);
	}

	function renderFormModal() {
		return <Modal
			isOpen={isModalOpen}
			onRequestClose={() => unmountModal()}
			overlayClassName="modal-overlay"
			className="form-modal"
		>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className="mb-2">
				<h3>New Speaker</h3>
				<button type="button" onClick={() => unmountModal()}>
					<i className="fas fa-times light-text disablePointerEvents" />
				</button>
			</div>
			<SpeakerForm
				speaker={currentSpeaker}
				getSpeakers={getSpeakers}
				unmountModal={unmountModal}
				city={match.params.city}
				speakersLength={speakers?.length > 0 ? (speakers?.length + 1) : 1}
				hoc={`${match?.params?.city ? match.params.city === 'mumbai' ? `${match.params.city}_`
					: match.params.city && match.params.city.includes('techsparks_2023') ? `2023_${extractCityName}` : '' : ''}viewall_speakers`}
			/>
		</Modal>;
	}

	async function handleDelete(speaker) {
		const url = `${CURATED_SET_CONFIG_URL}${match?.params?.city ? match.params.city === 'mumbai' ? `${match.params.city}_`
			: match.params.city && match.params.city.includes('techsparks_2023') ? `2023_${extractCityName}` : '' : ''}viewall_speakers/delete?id=${speaker.id}`;
		const options = {
			method: 'PUT',
			credentials: 'include',
		};
		const response = await fetch(url, options);
		if (response && response.status === 200) {
			getSpeakers();
		}
	}

	return (
		<WrapperContainer>
			<Row className="mt-4">
				<Col md={8} sm={8} xs={12}>
					<Col md={8} sm={10} className="pl-0">
						This is the speakers manager of <strong>{match?.params?.city?.toUpperCase()}</strong> for view all speakers
					</Col>
				</Col>
				<Col md={4} sm={4} xs={12}>
					<Col md={8} xs={8} className="pt-sm-4">
						<Button
							id="add-new-btn"
							primary
							btn_rounded
							w_100
							onClick={() => setIsModalOpen(true)}
						>
							ADD NEW
						</Button>
					</Col>
				</Col>
			</Row>
			<Row>
				{loading
					? <LoadingIcon />
					: <CardWrapper>
						{speakers && speakers.map((speaker) => (
							<Card key={uuid()}>
								<div className="text-right">
									<i className="fa fa-pencil-square fa-lg" style={{ cursor: 'pointer' }} onClick={() => { setCurrentSpeaker(speaker); setIsModalOpen(true); }} />
									<i className="fa fa-trash fa-lg ml-3" style={{ cursor: 'pointer' }} onClick={() => handleDelete(speaker)} />
								</div>
								<img src={speaker.image.url} alt="profile" style={{ width: '100px', height: '100px' }} />
								<h2>{speaker.name}</h2>
								<p>{speaker.designation}</p>
								<p>Order: {speaker.order}</p>
							</Card>
						))}
					</CardWrapper>}

			</Row>
			{renderFormModal()}
		</WrapperContainer>
	);
};

VIewAllSpeakers.propTypes = {
	pageTitle: T.func,
	match: T.object,
};

export default VIewAllSpeakers;
