export const ON_IMAGE_EDITOR_FORM_DATA_CHANGE = 'ON_IMAGE_EDITOR_FORM_DATA_CHANGE';
export const CLEAR_IMAGE_EDITOR_FORM_DATA = 'CLEAR_IMAGE_EDITOR_FORM_DATA';

export const IMAGE_EDITOR_SAVE_IMAGE = 'IMAGE_EDITOR_SAVE_IMAGE';
export const IMAGE_EDITOR_SAVE_IMAGE_SUCCESS = 'IMAGE_EDITOR_SAVE_IMAGE_SUCCESS';
export const IMAGE_EDITOR_SAVE_IMAGE_FAILURE = 'IMAGE_EDITOR_SAVE_IMAGE_FAILURE';

export const SET_IMAGE_EDITOR_FORM_MESSAGE = 'SET_IMAGE_EDITOR_FORM_MESSAGE';

export const FETCH_IMAGE_VERSIONS = 'FETCH_IMAGE_VERSIONS';
export const FETCH_IMAGE_VERSIONS_SUCCESS = 'FETCH_IMAGE_VERSIONS_SUCCESS';
export const FETCH_IMAGE_VERSIONS_FAILURE = 'FETCH_IMAGE_VERSIONS_FAILURE';

export const FETCH_IMAGE_BY_ID = 'FETCH_IMAGE_BY_ID';
export const FETCH_IMAGE_BY_ID_SUCCESS = 'FETCH_IMAGE_BY_ID_SUCCESS';
export const FETCH_IMAGE_BY_ID_FAILURE = 'FETCH_IMAGE_BY_ID_FAILURE';

export const CHANGE_VIEW = 'CHANGE_VIEW';
