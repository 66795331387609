export const FETCH_PROFILES_BY_STATUS = 'FETCH_PROFILES_BY_STATUS';
export const FETCH_PROFILES_BY_STATUS_SUCCESS = 'FETCH_PROFILES_BY_STATUS_SUCCESS';
export const FETCH_PROFILES_BY_STATUS_FAILURE = 'FETCH_PROFILES_BY_STATUS_FAILURE';

export const SET_DESK_PAGE = 'SET_DESK_PAGE';
export const SET_ALTERNATE_PROFILE_ID = 'SET_ALTERNATE_PROFILE_ID';
export const SET_DESK_PROFILES_STATUS = 'SET_DESK_PROFILES_STATUS';

export const UPDATE_DESK_PROFILE_STATUS = 'UPDATE_DESK_PROFILE_STATUS';
export const UPDATE_DESK_PROFILE_STATUS_SUCCESS = 'UPDATE_DESK_PROFILE_STATUS_SUCCESS';
export const UPDATE_DESK_PROFILE_STATUS_FAILURE = 'UPDATE_DESK_PROFILE_STATUS_FAILURE';

export const DISABLE_DESK_SEARCH_FLAG = 'DISABLE_DESK_SEARCH_FLAG';

export const RUN_PROFILE_DESK_SEARCH = 'RUN_PROFILE_DESK_SEARCH';
export const RUN_PROFILE_DESK_SEARCH_SUCCESS = 'RUN_PROFILE_DESK_SEARCH_SUCCESS';
export const RUN_PROFILE_DESK_SEARCH_FAILURE = 'RUN_PROFILE_DESK_SEARCH_FAILURE';
