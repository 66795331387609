import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-styled-flexboxgrid';
import { ListHeaderWrapper } from '../BoniManager/BoniProductCategory/style';

export const CaptabelGiftCodesListHeader = (props) => {
	const { selectedStatus } = props;
	return (
		<ListHeaderWrapper>
			<Col xs={false} md={1} className="text-center">
				S.No
			</Col>
			<Col xs={6} md={2} className="text-center">
				User Name
			</Col>
			<Col xs={6} md={1} className="text-center">
				Story Id
			</Col>
			<Col xs={6} md={2} className="text-center">
				Gift Code
			</Col>
			<Col xs={false} md={1} className="text-center">
				Redemption Count
			</Col>
			<Col xs={false} md={1} className="text-center">
				Redemption Allowed Count
			</Col>
			<Col xs={false} md={1} className="text-center">
				Created At
			</Col>
			<Col xs={false} md={1} className="text-center">
				Expire By
			</Col>
			{selectedStatus === 'ACTIVE' && <Col xs={false} md={2} className="text-center">
				Action
			</Col>}
		</ListHeaderWrapper>
	);
};

CaptabelGiftCodesListHeader.propTypes = {
	selectedStatus: PropTypes.string,
};
export default CaptabelGiftCodesListHeader;
