import React, {
	useEffect, useState, useReducer, Fragment,
} from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import 'rc-pagination/assets/index.css';
import Pagination from 'rc-pagination';
import T from 'prop-types';
import Modal from 'react-modal';
import {
	Input, Button, WrapperContainer,
} from '../Styles';
// import ToastMessage from '../../components/ToastMessage';
import TopicForm from './TopicForm';
import { TopicListHeader, TopicsListData } from './index';
import locale from '../../containers/WatchlistedStories/en_US';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import {
	TOPICS_BASE_URL,
	FETCH_TOPICS,
	FETCH_TOPICS_SUCCESS,
	FETCH_TOPICS_FAILURE,
	FILTER_TOPICS,
	FILTER_TOPICS_SUCCESS,
	FILTER_TOPICS_FAILURE,
	SET_TOPICS_PAGE,
	SET_ALL_ELEMENTS_BLURRED,
	UPDATE_ERRORS,
	UPDATE_ACTIONS_ERROR,
	UPDATE_ACTIONS_SUCCESS,
	UPDATE_ACTIONS_LOADER,
	SET_FORM_DATA,
	SET_ELEMENTS_BLURRED,
} from '../../containers/TopicManager/constants';

const initialState = {
	topicsList: [],
	topicsCount: null,
	loading: false,
	error: false,
	page: 1,
	actionLoading: false,
	actionError: false,
	actionMessage: '',
	formData: {
		name: null,
		designation: null,
		topic: null,
		status: null,
	},
	blurredElements: {
		name: false,
		designation: false,
		topic: false,
		status: false,
	},
	errors: {
		name: false,
		designation: false,
		topic: false,
		status: false,
	},
};

function setBlurredElements(blurredElements) {
	return {
		type: SET_ELEMENTS_BLURRED,
		blurredElements,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}
function fetchTopicsList() {
	return {
		type: FETCH_TOPICS,
	};
}

function fetchTopicsListSuccess(topicsList) {
	return {
		type: FETCH_TOPICS_SUCCESS,
		topicsList,
	};
}

function fetchTopicsListFailure(error) {
	return {
		type: FETCH_TOPICS_FAILURE,
		error,
	};
}

function setPage(page) {
	return {
		type: SET_TOPICS_PAGE,
		page,
	};
}

function setFormData(topic) {
	return {
		type: SET_FORM_DATA,
		topic,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case FETCH_TOPICS:
		return { ...state, loading: true };
	case FETCH_TOPICS_SUCCESS:
		return {
			...state, loading: false, topicsList: [...action.topicsList?.rows], topicsCount: action.topicsList?.count,
		};
	case FETCH_TOPICS_FAILURE:
		return {
			...state, loading: false, error: true, topicsList: [],
		};
	case FILTER_TOPICS:
		return { ...state, loading: true };
	case FILTER_TOPICS_SUCCESS:
		return {
			...state, loading: false, topicsList: [...action.topicsList?.rows], topicsCount: action.topicsList?.count,
		};
	case FILTER_TOPICS_FAILURE:
		return {
			...state, loading: true, error: true, topicsList: [],
		};
	case SET_TOPICS_PAGE:
		return { ...state, page: action.page };
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };
	case UPDATE_ACTIONS_ERROR:
		return {
			...state, actionLoading: false, actionError: true,
		};
	case UPDATE_ACTIONS_SUCCESS:
		return {
			...state, actionLoading: false, actionError: false,
		};
	case UPDATE_ACTIONS_LOADER:
		return {
			...state, actionLoading: true, actionError: false,
		};
	default:
		return state;
	}
}

const TopicListings = (props) => {
	const {
		status, history, pageTitle, user,
	} = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		topicsList, loading, topicsCount, page,
	} = state;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentTopic, setCurrentTopic] = useState({});
	const [currentTopicIndex, setCurrentTopicIndex] = useState(-1);
	const [searchValue, setSearchValue] = useState('');
	useEffect(() => {
		getTopicsList((page - 1) * 10, 10, searchValue);
		pageTitle('topicManager');
	}, []);

	async function getTopicsList(offset, limit, searchVal = searchValue) {
		dispatch(fetchTopicsList());
		const url = `${TOPICS_BASE_URL}?offset=${offset}&limit=${limit}&search=${searchVal}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				const topicsList = await response.json();
				dispatch(fetchTopicsListSuccess(topicsList.data));
			} else {
				dispatch(fetchTopicsListFailure());
			}
		} catch (err) {
			dispatch(fetchTopicsListFailure(err));
		}
	}

	const handleActions = async ({
		id,
		method,
		callBack,
		callBackFromToolTipMessage,
	}) => {
		const options = {
			method,
			credentials: 'include',
		};
		const postParam = {
			DELETE: `${TOPICS_BASE_URL}/${id}`,
		};
		if (callBackFromToolTipMessage) callBackFromToolTipMessage(1);
		try {
			const response = await fetch(`${postParam[method]}`, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
				if (callBack) callBack();
			} else if (response.status === 201 || response.status === 200) {
				if (callBackFromToolTipMessage) callBackFromToolTipMessage(2);
				setTimeout(() => {
					closeModal();
					getTopicsList((page - 1) * 10, 10, searchValue);
					if (callBack) callBack();
				}, 2000);
			} else {
				if (callBackFromToolTipMessage) callBackFromToolTipMessage(3);
				setTimeout(() => {
					closeModal();
					if (callBack) callBack();
				}, 2000);
			}
		} catch (err) {
			setTimeout(() => {
				closeModal();
				if (callBackFromToolTipMessage) callBackFromToolTipMessage(3);
				if (callBack) callBack();
			}, 2000);
		}
	};

	// const saveTopic = async () => {
	// 	dispatch(updateActionsLoading());
	// 	dispatch(setActionsMessage(SAVING_MESSAGE));
	// 	const patchAPiUrl = `${TOPICS_BASE_URL}/${formData.id}`;
	// 	const url = `${formData.id ? patchAPiUrl : TOPICS_BASE_URL}`;
	// 	const reqData = {
	// 		name: formData.name,
	// 		designation: formData.designation,
	// 		topic: formData.topic,
	// 		status: formData.status,
	// 	};
	// 	const options = {
	// 		method: formData.id ? 'PATCH' : 'POST',
	// 		credentials: 'include',
	// 		body: JSON.stringify(reqData),
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 		},
	// 	};
	// 	try {
	// 		const response = await fetch(url, options);
	// 		if (response.status === 401 || response.status === 403) {
	// 			history.push('/unauthorized');
	// 		} else if (response && response.status === 200) {
	// 			dispatch(updateActionsSuccess());
	// 			dispatch(setActionsMessage(SAVING_SUCCESS_MESSAGE));
	// 			setTimeout(() => {
	// 				closeModal();
	// 				dispatch(setActionsMessage(null));
	// 				getTopicsList((page - 1) * 10, 10, searchValue);
	// 			}, 2000);
	// 		} else if (response && response.status !== 200) {
	// 			dispatch(updateActionsFailure());
	// 			dispatch(setActionsMessage(SAVING_FAILURE_MESSAGE));
	// 			setTimeout(() => {
	// 				dispatch(updateActionsSuccess());
	// 				dispatch(setActionsMessage(null));
	// 				closeModal();
	// 			}, 2000);
	// 		}
	// 	} catch (err) {
	// 		dispatch(updateActionsFailure());
	// 		dispatch(setActionsMessage(SAVING_FAILURE_MESSAGE));
	// 		setTimeout(() => {
	// 			dispatch(updateActionsSuccess());
	// 			closeModal();
	// 			dispatch(setActionsMessage(null));
	// 		}, 2000);
	// 	}
	// };

	function handleSearch(e) {
		e.preventDefault();
		const { value } = e.target;
		getTopicsList(0, 10, value);
		dispatch(setPage(1));
	}

	function onPageChange(page) {
		getTopicsList((page - 1) * 10, 10, searchValue);
		dispatch(setPage(page));
	}

	function closeModal() {
		dispatch(setPage(1));
		dispatch(updateErrors({
			name: false,
			designation: false,
			topic: false,
			status: false,
		}));
		dispatch(setBlurredElements({
			name: false,
			designation: false,
			topic: false,
			status: false,
		}));
		dispatch(setAllElementsBlurred());
		dispatch(setFormData({}));
	}

	function returnAsyncFilterValue(responseArray) {
		const resultantArray = [];
		responseArray.forEach((element) => {
			resultantArray.push({
				label: element?.name,
				value: element?.id,
				...element,
			});
		});
		return resultantArray;
	}

	function getTopicListings() {
		return topicsList.map((topic, index) => <TopicsListData
			key={topic.id}
			id={topic.id}
			history={history}
			userData={user}
			slNo={(page - 1) * 10 + index + 1}
			topicItem={topic}
			editTopic={() => {
				dispatch(setFormData(topic));
				setIsModalOpen(true);
				const topicData = {
					...topic,
					description: topic?.description?.replaceAll('&nbsp;', ' ').replaceAll('\\', ''),
					companies: topic?.companies?.length > 0 ? returnAsyncFilterValue(topic?.companies) : [],
				};
				setCurrentTopic(topicData);
			}}
			handleActions={handleActions}
			index={index}
		/>);
	}

	function unmountModal() {
		setIsModalOpen(false);
		setCurrentTopic(null);
		dispatch(setFormData({}));
	}

	function getLatestTopicsList() {
		getTopicsList((page - 1) * 10, 10, searchValue);
	}

	function renderFormModal() {
		return <Modal
			isOpen={isModalOpen}
			onRequestClose={() => unmountModal()}
			overlayClassName="modal-overlay"
			className="form-modal-profile-manager"
			shouldCloseOnOverlayClick={false}
			shouldCloseOnEsc={false}
		>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className="mb-2">
				<h3>{currentTopic?.id ? 'Edit Topic' : 'Create Topic'}</h3>
				<button type="button" onClick={() => unmountModal()}>
					<i className="fas fa-times light-text disablePointerEvents" />
				</button>
			</div>
			<TopicForm
				topic={currentTopic}
				getTopicListings={getLatestTopicsList}
				unmountModal={unmountModal}
				topicIndex={currentTopicIndex}
			/>
		</Modal>;
	}

	function renderList() {
		if (topicsList && topicsList.length > 0) {
			return <div>
				<TopicListHeader selectedStatus={status} />
				{getTopicListings()}
			</div>;
		}

		return (
			<Row style={{
				width: '100%', minHeight: '400px', justifyContent: 'center', alignItems: 'center',
			}}
			>
				<div>
					<h1 style={{ color: '#bbb' }}>
						No Topics To Display
					</h1>
				</div>
			</Row>
		);
	}

	return (
		<WrapperContainer>
			<Row className="">
				<Col xs={12} className="text-right">
					<Row style={{ justifyContent: 'flex-end' }} className="mr-1 mb-2">
						<Col sm={4} xs={12} className="mb-2">
							<Input
								id="search-box"
								type="text"
								className="form-control"
								placeholder="Search By Name"
								value={searchValue}
								onChange={(e) => {
									handleSearch(e);
									setSearchValue(e.target.value);
								}}
							/>
						</Col>
						<Col sm={2} xs={12} style={{ justifyContent: 'flex-end' }}>
							<Button
								primary
								no_radius
								className="mb-0 mr-2"
								onClick={() => {
									setIsModalOpen(true);
									setCurrentTopicIndex(-1);
								}}
							>
								<b>CREATE TOPIC</b>
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
			{
				loading
					? <LoadingIcon />
					: <>
						{renderList()}
						{topicsList && topicsList.length > 0
							? <div>
								<Row className="mt-3 pr-4" style={{ justifyContent: 'flex-end' }}>
									<Pagination onChange={onPageChange} current={page} total={topicsCount} locale={locale} />
								</Row>
							</div>
							: null}
					</>
			}
			{renderFormModal()}
		</WrapperContainer>
	);
};

TopicListings.propTypes = {
	status: T.number,
	history: T.object,
	pageTitle: T.func,
	user: T.object,
};
export default TopicListings;
