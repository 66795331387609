import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { FETCH_DASHBOARD_USER_BRANDS } from './type';
import { BRAND_API_BASE_URL } from '../BrandManager/constants';
import { fetchDashboardUserBrandsSuccess, fetchDashboardUserBrandsError } from './actions';

export function* getDashboardBrands() {
	const url = `${BRAND_API_BASE_URL}/userbrands`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const brands = yield response.json();
			yield put(fetchDashboardUserBrandsSuccess(brands));
		}
	} catch (err) {
		yield put(fetchDashboardUserBrandsError(err));
	}
}

export default function* dashboardata() {
	yield takeLatest(FETCH_DASHBOARD_USER_BRANDS, getDashboardBrands);
}
