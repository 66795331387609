import React, { useState, useEffect } from 'react';
import T from 'prop-types';
import Modal from 'react-modal';
import uuid from 'react-uuid';
import styled, { css } from 'styled-components';

import { Col, Row } from '../Responsive';
import {
	Button, Wrapper,
} from '../Styles';
import { CURATED_SET_CONFIG_URL } from '../../containers/ListingsManager/constants';
import CategoryForm from './CategoryForm';
import { LoadingIcon } from '../Basic';

const Card = styled.div`
	border: 1px solid #ddd;
	height: 300px;
	width: 200px;
	padding: 5px;
	margin: 0.25rem;
	background-color: #fff;
	cursor: pointer;
	font-size: 0.8rem;
	word-wrap: break-word;
	cursor: pointer;
	display: block;
	position: relative;
	overflow: auto;
`;

const CardWrapper = styled(Row)`
	border: 1px solid #bbb;
	background-color: #eee;
	padding: 0.5rem;
	width: 100%;
	position: relative;
	overflow: auto;
	height: 75vh;
	${(props) => props.source === 'fullPage' && css`
		height: 75vh;
	`}
`;

const CategoryListings = (props) => {
	const { pageTitle, history } = props;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [categories, setCategories] = useState([]);
	const [currentCategory, setCurrentCategory] = useState({});
	const [currentCategoryIndex, setCurrentCategoryIndex] = useState(-1);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		pageTitle('viewAllCategories');
		getCategories();
	}, []);

	async function getCategories() {
		const url = `${CURATED_SET_CONFIG_URL}?brand=4`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		setLoading(true);
		const response = await fetch(url, options);
		const data = await response.json();
		data.category.forEach((item) => {
			item?.newOrder ? item.newOrder = '' : '';
		});
		setCategories(data.category);
		setLoading(false);
	}

	function unmountModal() {
		setIsModalOpen(false);
		setCurrentCategory(null);
	}

	function renderFormModal() {
		return <Modal
			isOpen={isModalOpen}
			onRequestClose={() => unmountModal()}
			overlayClassName="modal-overlay"
			className="form-modal"
			shouldCloseOnOverlayClick={false}
			shouldCloseOnEsc={false}
		>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className="mb-2">
				<h3>New Category</h3>
				<button type="button" onClick={() => unmountModal()}>
					<i className="fas fa-times light-text disablePointerEvents" />
				</button>
			</div>
			<CategoryForm
				category={currentCategory}
				getCategories={getCategories}
				unmountModal={unmountModal}
				categoriesList={categories}
				categoryIndex={currentCategoryIndex}
			/>
		</Modal>;
	}

	async function handleDelete(index) {
		const url = `${CURATED_SET_CONFIG_URL}/create`;
		const reqCategoryData = [...categories];
		let finalCategories = [];
		if (categories && categories?.length > 0) {
			reqCategoryData.splice(index, 1);
			finalCategories = [...reqCategoryData];
			finalCategories.forEach((item, index) => {
				item.order = index + 1;
				item.previousOrder = index + 1;
				item.newOrder = '';
			});
		}
		const payload = {
			category: categories && categories?.length > 0 ? finalCategories : [],
		};
		const options = {
			method: 'PUT',
			credentials: 'include',
			body: JSON.stringify(payload),
			headers: {
				'Content-Type': 'application/json',
			},
		};
		const response = await fetch(url, options);
		if (response && response.status === 200) {
			getCategories();
		}
	}

	return (
		<Wrapper>
			<Row className="mt-4">
				<Col md={8} sm={8} xs={12}>
					<Col md={8} sm={10} className="pl-0">
						This is the categories manager. View all categories
					</Col>
				</Col>
				<Col md={4} sm={4} xs={12}>
					<Col md={8} xs={8} className="pt-sm-4">
						<Button
							id="add-new-btn"
							primary
							btn_rounded
							w_100
							onClick={() => { setIsModalOpen(true); setCurrentCategoryIndex(-1); }}
						>
							ADD NEW
						</Button>
					</Col>
				</Col>
			</Row>
			<Row>
				{loading
					? <LoadingIcon />
					: <CardWrapper>
						{categories && categories.map((category, index) => (
							<Card key={uuid()}>
								<div className="text-right">
									<i title="Edit The Category" className="fa fa-pencil-square fa-lg" style={{ cursor: 'pointer' }} onClick={() => { setCurrentCategory(category); setIsModalOpen(true); setCurrentCategoryIndex(index); }} />
									<i title="View All Profiles" className="fa fa-user fa-lg ml-3" style={{ cursor: 'pointer' }} onClick={() => history.push(`/manager/profile-listings/${category.id}`)} />
									<i title="Delete The Category" className="fa fa-trash fa-lg ml-3" style={{ cursor: 'pointer' }} onClick={() => { handleDelete(index); setCurrentCategoryIndex(index); }} />
								</div>
								<h2><b>Name : {category.name}</b></h2>
								<p><b>Order :</b> {category.order}</p>
								<p><b>Description :</b> {category.description}</p>
							</Card>
						))}
					</CardWrapper>}

			</Row>
			{renderFormModal()}
		</Wrapper>
	);
};

CategoryListings.propTypes = {
	pageTitle: T.func,
	history: T.object,
};

export default CategoryListings;
