import {
	call, put, takeLatest, select,
} from 'redux-saga/effects';
import { FETCH_STORY_URL } from './type';
import { fetchStoryUrlFailure, fetchStoryUrlSuccess } from './actions';
import { redirectTo } from '../../utils/accessRedirection';
import { makeSelectLocation } from '../App/selectors';

let CURRENT_LOCATION;

function* getStoryUrl(action) {
	const url = `/api/v2/rw/storymetadata/key/${action.storyId}/storyurl`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
			yield put(fetchStoryUrlFailure('Failed to fetch story revisions'));
		} else {
			const storyUrl = yield response.json();
			yield put(fetchStoryUrlSuccess(storyUrl[0].value));
		}
	} catch (err) {
		yield put(fetchStoryUrlFailure(err));
	}
}

export default function* storyPreviewData() {
	const location = yield select(makeSelectLocation());
	CURRENT_LOCATION = location.pathname;
	yield takeLatest(FETCH_STORY_URL, getStoryUrl);
}
