import { createSelector } from 'reselect';

const selectCmsPageCurations = (state) => state.get('cmsPageCurations');

const selectLoading = () => createSelector(
	selectCmsPageCurations,
	(state) => state.get('loading'),
);

const selectError = () => createSelector(
	selectCmsPageCurations,
	(state) => state.get('error'),
);

const selectErrorResponse = () => createSelector(
	selectCmsPageCurations,
	(state) => state.get('errorResponse'),
);

const selectMessage = () => createSelector(
	selectCmsPageCurations,
	(state) => state.get('message'),
);

const selectCuratedSetList = () => createSelector(
	selectCmsPageCurations,
	(state) => state.get('curatedSetList'),
);

const selectBrandValue = () => createSelector(
	selectCmsPageCurations,
	(state) => state.get('selectedBrandValue'),
);

const selectOpenModal = () => createSelector(
	selectCmsPageCurations,
	(state) => state.get('openModalFor'),
);

const selectStorySearchTitle = () => createSelector(
	selectCmsPageCurations,
	(state) => state.get('storySearchTitle'),
);

const selectStorySearchResults = () => createSelector(
	selectCmsPageCurations,
	(state) => state.get('storySearchResults'),
);

const selectModalLoading = () => createSelector(
	selectCmsPageCurations,
	(state) => state.get('modalLoading'),
);

const selectStorySearchOffset = () => createSelector(
	selectCmsPageCurations,
	(state) => state.get('storySearchOffset'),
);

const selectEndOfStorySearchList = () => createSelector(
	selectCmsPageCurations,
	(state) => state.get('endOfStorySearchList'),
);

const selectStorySearchSelect = () => createSelector(
	selectCmsPageCurations,
	(state) => state.get('storySearchSelect'),
);

const selectSaving = () => createSelector(
	selectCmsPageCurations,
	(state) => state.get('saving'),
);

const selectSavingResponseType = () => createSelector(
	selectCmsPageCurations,
	(state) => state.get('savingResponseType'),
);

const selectSavingMessage = () => createSelector(
	selectCmsPageCurations,
	(state) => state.get('savingMessage'),
);

export {
	selectLoading,
	selectError,
	selectErrorResponse,
	selectMessage,
	selectBrandValue,
	selectCuratedSetList,
	selectOpenModal,
	selectStorySearchTitle,
	selectStorySearchResults,
	selectModalLoading,
	selectStorySearchOffset,
	selectEndOfStorySearchList,
	selectStorySearchSelect,
	selectSaving,
	selectSavingResponseType,
	selectSavingMessage,
};
