export const BRAND_API_BASE_URL = '/api/v2/rw/webstories';

export const FETCH_BRAND = 'FETCH_BRAND';
export const SET_BRAND_ACTION = 'SET_BRAND_ACTION';
export const FETCH_BRAND_SUCCESS = 'FETCH_BRAND_SUCCESS';
export const FETCH_BRAND_FAILURE = 'FETCH_BRAND_FAILURE';

export const TRASH_BRAND = 'TRASH_BRAND';
export const TRASH_BRAND_SUCCESS = 'TRASH_BRAND_SUCCESS';
export const TRASH_BRAND_FAILURE = 'TRASH_BRAND_FAILURE';

export const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
export const SAVING_MESSAGE = { intent: 'info', message: 'Saving data...' };
export const SAVING_SUCCESS_MESSAGE = { intent: 'success', message: 'Data Saved Successfully' };
export const SAVING_FAILURE_MESSAGE = { intent: 'danger', message: 'Data Save Failed. Something went wrong. Try Again!' };

export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
export const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';

export const SET_WHOLE_FORM_DATA = 'SET_WHOLE_FORM_DATA';
export const SET_FORM_DATA = 'SET_FORM_DATA';
