import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from '../Responsive';
import { Modal, Button } from '../Styles';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import {
	StoryResultCard, Image, ModalContent, Title, AuthorName, Published, Detail, Result, ModalTitle, Input, ImagePlaceHolder, BrandName, ButtonSection, WarningText,
} from './styles';
import { urlBelongsTo } from '../../utils/utility_function';

class AsyncInputModal extends React.Component {
	constructor(props) {
		super(props);
		this.renderResults = this.renderResults.bind(this);
	}

	renderResults() {
		if (this.props.loading === null) {
			return <h5 className="text-center">Please type in the search box</h5>;
		} if (this.props.loading === true) {
			return <LoadingIcon />;
		} if (this.props.loading === false) {
			return (<>
				<Result className="custom-scrollbar scrollable">
					{(('length' in this.props.resultValue) && (this.props.resultValue.length > 0)) ? this.props.resultValue.map((storyObject, idx) => {
						const storyTitle = storyObject.title ? storyObject.title : 'NO TITLE FOUND';
						let authorName = 'NO AUTHOR';
						let publishedAtDate = 'NO PUBLISHED DATE';
						let imageUrl = null;
						let brandName = 'NO BRAND';
						let selectedStory = false;
						let featuredImage = false;

						if (this.props.selectedItemPosition === idx) {
							selectedStory = true;
						}

						if (((typeof storyObject.metadata) === 'object') && ('authors' in storyObject.metadata) && ('length' in storyObject.metadata.authors) && (storyObject.metadata.authors.length > 0)) {
							if (storyObject.metadata.authors[0].name) {
								authorName = storyObject.metadata.authors[0].name;
							}
						}

						if (storyObject.publishedAt) {
							publishedAtDate = new Date(storyObject.publishedAt).toDateString();
						}

						if (((typeof storyObject.metadata) === 'object') && ('media' in storyObject.metadata) && storyObject.metadata.media) {
							imageUrl = storyObject.metadata.media;
						}

						if (((typeof storyObject.brand) === 'object') && (storyObject.brand.name)) {
							brandName = storyObject.brand.name;
						}

						if (((typeof storyObject.metadata) === 'object') && ('featuredImage' in storyObject.metadata) && storyObject.metadata.featuredImage) {
							featuredImage = true;
						}

						return (<StoryResultCard
							key={`search-story-card-${idx + 1}`}
							onClick={() => {
								const indexOfStory = idx;
								if (!featuredImage && storyObject.brand.slug === 'ys-life') return;
								this.props.selectedItem(indexOfStory);
							}}
							className={selectedStory ? 'selected-item-style' : ''}
						>
							<Row className="m-0">
								<Col xs={9} sm={9} md={9} lg={9}>
									<Title>{storyTitle}</Title>
									<Detail>
										<BrandName>{brandName}</BrandName>
										<AuthorName>{authorName}</AuthorName>
										<Published>{publishedAtDate}</Published>
									</Detail>
									{!featuredImage && storyObject.brand.slug === 'ys-life' ? <WarningText>'This story does not contain featured Image'</WarningText> : null}
								</Col>
								<Col xs={3} sm={3} md={3} lg={3} className="p-0 m-0">
									{imageUrl
										? <Image src={urlBelongsTo(imageUrl, 'https://images.yourstory.com') ? `${imageUrl}?h=200&w=200` : imageUrl} alt="Story search Image" />
										: <ImagePlaceHolder>NO IMAGE</ImagePlaceHolder>}
								</Col>
							</Row>
						</StoryResultCard>);
					}) : <h5 className="text-center">No results found</h5>}
				</Result>
				{(('length' in this.props.resultValue) && (this.props.resultValue.length > 0))
					? <ButtonSection>
						<Button primary className="m-1 mr-3 pull-right" onClick={() => this.props.onConfirm()}>Confirm</Button>
						{this.props.endOfLoadMoreList ? null
							: <Button primary className="m-1 mr-3 pull-right" onClick={() => this.props.onLoadMore()}>Load more</Button>}
					</ButtonSection>
					: null}
			</>);
		}
		return null;
	}

	render() {
		return (<Modal>
			<div className="modal-float">
				<div className="modal-wrapper">
					<ModalContent>
						<div>
							<Row>
								<Col xs={10} sm={10} md={10} lg={10}>
									<ModalTitle>{this.props.title}</ModalTitle>
								</Col>
								<Col xs={2} sm={2} md={2} lg={2} className="text-right">
									<Button className="b-0 p-0 md-text" onClick={this.props.closeModal}><i className="fa fa-times" /></Button>
								</Col>
							</Row>
						</div>
						<Input placeholder={this.props.placeHolder} onChange={this.props.onChangeOfValue} value={this.props.inputValue} />
						{this.renderResults()}
					</ModalContent>
				</div>
			</div>
		</Modal>);
	}
}

AsyncInputModal.defaultProps = {
	inputValue: '',
	placeHolder: 'Type in to search',
	title: 'Add a story',
	resultValue: [],
};

AsyncInputModal.propTypes = {
	loading: PropTypes.bool,

	inputValue: PropTypes.string,
	placeHolder: PropTypes.string,
	title: PropTypes.string,
	resultValue: PropTypes.array,
	endOfLoadMoreList: PropTypes.bool,
	selectedItemPosition: PropTypes.number,

	selectedItem: PropTypes.func,
	onConfirm: PropTypes.func,
	closeModal: PropTypes.func,
	onChangeOfValue: PropTypes.func,
	onLoadMore: PropTypes.func,
};

export default AsyncInputModal;
