import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-styled-flexboxgrid';
import moment from 'moment-timezone';
import { ListItemWrapper, Button } from '../../BoniManager/BoniBrandManagerList/styles';
import 'rc-pagination/assets/index.css';
import ConfirmationModal from '../../FormComponents/ConfirmationModal';

const RedemptionDoneList = (props) => {
	const [isTrue, setIsTrue] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState({});
	const [activateStatus, setActivateStatus] = useState('DELETED');
	const [activateTitle, setActivateTitle] = useState(false);
	const [showToolTipMessage, setShowToolTipMessage] = useState(false);
	const [showToolTipMessageVal, setShowToolTipMessageVal] = useState(null);
	const [reqId, setReqId] = useState(giftCodeData?.code);
	const SAVING_MESSAGE = { intent: 'info', message: 'Saving data...' };
	const SAVING_SUCCESS_MESSAGE = { intent: 'success', message: 'Data Saved Successfully' };
	const SAVING_FAILURE_MESSAGE = { intent: 'danger', message: 'Data Save Failed. Something went wrong. Try Again!' };

	const {
		// id,
		slNo,
		giftCodeData,
		// editComment,
		handleActions,
		// userData,
		selectedStatusOfGiftCode,
	} = props;

	const handleActivateModal = () => {
		setActivateTitle(true);
		setIsTrue(!isTrue);
		setActivateStatus(!activateStatus);
	};

	const handleModal = () => {
		setIsTrue(!isTrue);
		setActivateTitle(false);
	};

	const handleConfirm = ({ method, id }) => {
		setShowToolTipMessage(true);
		const callBack = () => {
			setIsTrue(!isTrue);
			setShowToolTipMessage(false);
			setShowToolTipMessageVal(null);
		};
		const callBackFromToolTipMessage = (value) => {
			if (value === 1) {
				setShowToolTipMessageVal(SAVING_MESSAGE);
			} else if (value === 2) {
				setShowToolTipMessageVal(SAVING_SUCCESS_MESSAGE);
			} else if (value === 3) {
				setShowToolTipMessageVal(SAVING_FAILURE_MESSAGE);
			}
		};
		handleActions({
			id: id || reqId,
			method: method || (activateTitle ? 'PATCH' : 'DELETE'),
			selectStatus: selectedStatus.status,
			callBack,
			callBackFromToolTipMessage,
		});
	};

	const handleCancel = () => {
		setIsTrue(!isTrue);
	};

	return (
		<>
			<ListItemWrapper id={slNo} style={{ padding: '15px 0' }} className="py-2">
				<Col xs={false} md={1} className="text-center">
					<span>{slNo}.</span>
				</Col>
				<Col xs={6} md={2} className="text-center">
					<span>{giftCodeData?.user?.name || 'N/A'}</span>
				</Col>
				<Col xs={false} md={2} className="text-center">
					<span>{giftCodeData?.status || 'N/A'}</span>
				</Col>
				<Col xs={false} md={2} className="text-center">
					<span>{giftCodeData?.type || 'Not Found'}</span>
				</Col>
				<Col xs={false} md={1} className="text-center">
					<span>{giftCodeData?.createdAt ? moment(giftCodeData?.createdAt).format('DD MMM YYYY hh:mm A') : 'N/A'}</span>
				</Col>
				<Col xs={false} md={1} className="text-center">
					<span>{giftCodeData?.endAt ? moment(giftCodeData?.endAt).format('DD MMM YYYY hh:mm A') : 'N/A'}</span>
				</Col>
				<Col xs={false} md={2} className="text-center d-none">
					{
						// action.map((data, index) =>
						<div className="action">
							{(selectedStatusOfGiftCode !== 'ACTIVE') && <Button
								className
								title={`${giftCodeData.status === 'DELETED' ? 'EXPIRE' : 'ACTIVE'}`}
								onClick={() => {
									handleActivateModal();
									setReqId(giftCodeData?.id);
									setSelectedStatus({ status: giftCodeData.status === 'DELETED' ? 'EXPIRED' : 'ACTIVE' });
								}}
							>
								{
									giftCodeData.status === 'DELETED'
										? <i className="fa fa-times-circle fa-lg" />
										: <i className="fas fa-check-circle  fa-lg" />
								}
							</Button>}
							{/* <Button
								onClick={() => {
									editComment();
								}}
							>
								<i className="fa fa-pencil-square fa-lg" />
							</Button> */}
							{(selectedStatusOfGiftCode === 'ACTIVE') && <Button
									onClick={() => {
										handleModal();
										setReqId(giftCodeData?.id);
										setSelectedStatus({ status: 'DELETED' });
									}}
							>
								<i className="fas fa-trash fa-lg" />
							</Button>}
						</div>
					}

				</Col>
			</ListItemWrapper>

			<ConfirmationModal
				openDialog={isTrue}
				handleCancel={handleCancel}
				closeDialog={handleCancel}
				confirm={() => handleConfirm({ method: 'PATCH', id: reqId })}
				closeToolTipMessage={() => {
					setShowToolTipMessage(false);
					setShowToolTipMessageVal(null);
				}}
				showToolTipMessage={showToolTipMessage}
				showToolTipMessageVal={showToolTipMessageVal}
				message={
					activateTitle
						? `Are you sure you would like to ${selectedStatusOfGiftCode === 'ACTIVE'
							? 'DELETE'
							: selectedStatusOfGiftCode === 'EXPIRED' ? 'ACTIVE' : 'EXPIRE'} the selected gift code ? `
						: 'Are you sure you would like to delete the selected gift code ?'
				}
			/>
		</>
	);
};
RedemptionDoneList.propTypes = {
	// id: PropTypes.string,
	slNo: PropTypes.number,
	giftCodeData: PropTypes.object,
	handleActions: PropTypes.any,
	// editComment: PropTypes.func,
	// userData: PropTypes.object,
	selectedStatusOfGiftCode: PropTypes.string,
};

export default RedemptionDoneList;
