export const ON_SUCCESS = 'ON_SUCCESS';
export const ON_FAIL = 'ON_FAIL';
export const FETCH_ADHOC_URL_LIST = 'FETCH_ADHOC_URL_LIST';
export const LOAD_ADHOC_URL = 'LOAD_ADHOC_URL';
export const LOAD_MORE_ADHOC_URL = 'LOAD_MORE_ADHOC_URL';
export const LOADING_STATE = 'LOADING_STATE';
export const LOADING_LIST = 'LOADING_LIST';
export const ADD_TO_LIST_ON_UPLOAD = 'ADD_TO_LIST_ON_UPLOAD';
export const SHOW_COPY_ELEMENT = 'SHOW_COPY_ELEMENT';
export const CLEAR_STATE = 'CLEAR_STATE';
