export const FETCH_ORG_CHART_DATA = 'FETCH_ORG_CHART_DATA';
export const FETCH_ORG_CHART_DATA_SUCCESS = 'FETCH_ORG_CHART_DATA_SUCCESS';
export const FETCH_ORG_CHART_DATA_FAILURE = 'FETCH_ORG_CHART_DATA_FAILURE';

export const CREATE_ORG_CHART = 'CREATE_ORG_CHART';
export const CREATE_ORG_CHART_SUCCESS = 'CREATE_ORG_CHART_SUCCESS';
export const CREATE_ORG_CHART_FAILURE = 'CREATE_ORG_CHART_FAILURE';

export const UPDATE_ORG_CHART = 'UPDATE_ORG_CHART';
export const UPDATE_ORG_CHART_SUCCESS = 'UPDATE_ORG_CHART_SUCCESS';
export const UPDATE_ORG_CHART_FAILURE = 'UPDATE_ORG_CHART_FAILURE';

export const UPDATE_ORG_CHART_FROM_DATA = 'UPDATE_ORG_CHART_FROM_DATA';
export const UPDATE_FORM_MESSAGE = 'UPDATE_FORM_MESSAGE';
export const SHOW_CONFIRMATION_MODAL = 'SHOW_CONFIRMATION_MODAL';

export const HANDLE_BLUR = 'HANDLE_BLUR';
export const SET_ALL_ELEMENTS_BLUR = 'SET_ALL_ELEMENTS_BLUR';
export const ORG_CHART_EDITOR_HAS_ERRORS = 'ORG_CHART_EDITOR_HAS_ERRORS';

export const HANDLE_PROFILE_BLUR = 'HANDLE_PROFILE_BLUR';
export const SET_ALL_PROFILE_ELEMENTS_BLUR = 'SET_ALL_PROFILE_ELEMENTS_BLUR';
export const PROFILE_HAS_ERRORS = 'PROFILE_HAS_ERRORS';

export const UPDATE_PEOPLE_PROFILE_DATA = 'UPDATE_PEOPLE_PROFILE_DATA';

export const CREATE_PEOPLE_PROFILE = 'CREATE_PEOPLE_PROFILE';
export const CREATE_PEOPLE_PROFILE_SUCCESS = 'CREATE_PEOPLE_PROFILE_SUCCESS';
export const CREATE_PEOPLE_PROFILE_FAILURE = 'CREATE_PEOPLE_PROFILE_FAILURE';

export const UPDATE_PEOPLE_FORM_MESSAGE = 'UPDATE_PEOPLE_FORM_MESSAGE';
export const FETCH_PEOPLE_PROFILE_ORG_CHART_DATA_SUCCESS = 'FETCH_PEOPLE_PROFILE_ORG_CHART_DATA_SUCCESS';
export const FETCH_PEOPLE_PROFILE_ORG_CHART_DATA_FAILURE = 'FETCH_PEOPLE_PROFILE_ORG_CHART_DATA_FAILURE';
export const FETCH_PEOPLE_PROFILE_ORG_CHART_DATA = 'FETCH_PEOPLE_PROFILE_ORG_CHART_DATA';
