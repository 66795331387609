import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Outline = styled.div`
		height: ${({ height }) => (height || '100%')};
		width: ${({ width }) => (width || '100%')};
		padding: ${({ padding }) => (padding || '10px')};
		box-shadow: ${({ boxShadow }) => (boxShadow || 'rgba(0, 0, 0, 0.15) 0px 0.1rem 0.3rem')};
`;

const CardOutline = (props) => <Outline {...props}>
	{props.children}
</Outline>;

CardOutline.propTypes = {
	children: PropTypes.node,
};

export default CardOutline;
