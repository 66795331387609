import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { SEND_PROMOTION, CREATE_PROMOTION, LOAD_CHATBOTS } from './type';
import {
	sendPromotionSuccess, sendPromotionError, createPromotionSuccess, createPromotionError, createPromotion, loadChatbotsError, loadChatbotsSuccess,
} from './actions';
import { PROMOTIONS_API_BASE_URL, CHATBOTS_API_BASE_URL } from './constants';

export function* sendPromotion(action) {
	const url = `${PROMOTIONS_API_BASE_URL}/send`;
	const payload = {
		message: {
			title: action.payload.title,
			subtitle: action.payload.subtitle,
			url: action.payload.cta,
			media: action.payload.media,
		},
		brand: action.payload.brand,
	};
	const options = {
		method: 'POST',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(payload),
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const result = yield response.json();
			if (result.success) {
				yield put(sendPromotionSuccess(result));
				yield put(createPromotion(action.payload));
			} else {
				yield put(sendPromotionError(result));
			}
		}
	} catch (err) {
		yield put(sendPromotionError(err));
	}
}

export function* createPromotionRow(action) {
	const url = `${PROMOTIONS_API_BASE_URL}/save`;
	const timestamp = new Date().toISOString();
	const payload = {
		title: action.promotionForm.title,
		subtitle: action.promotionForm.subtitle,
		cta: action.promotionForm.cta,
		media: action.promotionForm.media,
		brand: action.promotionForm.brand,
		createdAt: timestamp,
		updatedAt: timestamp,
	};
	const options = {
		method: 'POST',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(payload),
	};

	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response === 403) {
			yield put(push('/unauthorized'));
		} else {
			const result = yield response.json();
			if (result.success) {
				yield put(createPromotionSuccess(result));
			} else {
				yield put(createPromotionError(result));
			}
		}
	} catch (err) {
		yield put(createPromotionError(err));
	}
}

export function* getChatbots() {
	const url = `${CHATBOTS_API_BASE_URL}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const chatbots = yield response.json();
			yield put(loadChatbotsSuccess(chatbots));
		}
	} catch (err) {
		yield put(loadChatbotsError(err));
	}
}

export default function* messengerPromotionFormData() {
	yield takeLatest(SEND_PROMOTION, sendPromotion);
	yield takeLatest(CREATE_PROMOTION, createPromotionRow);
	yield takeLatest(LOAD_CHATBOTS, getChatbots);
}
