import { push } from 'connected-react-router';

import { call, put, takeLatest } from 'redux-saga/effects';
import { FETCH_ADHOC_URL_LIST, LOAD_MORE_ADHOC_URL } from './type';
import { loadingList, loadAdhocUrl } from './actions';
import { getFullDate } from '../../utils/date_maker';

const USER_ADHOC_API_URL_LIST_URL = '/api/v2/rw/adhoc/url/list';

function* fetchAdhocUrl(action) {
	const option = {
		credentials: 'include',
	};

	yield put(loadingList());

	const pageNo = action.pageNo ? action.pageNo : 1;
	const list = action.list ? action.list : [];

	try {
		const response = yield call(fetch, `${USER_ADHOC_API_URL_LIST_URL}/${pageNo}`, option);
		if (response.status === 200) {
			const responseJson = yield response.json();
			let end = null;

			if (responseJson.status === 'success') {
				const newListArray = list.concat(responseJson.list);
				let dateArray = [];
				newListArray.forEach((adhocRecord) => {
					const date = getFullDate(adhocRecord.createdAt);
					if (!dateArray.includes(date)) {
						dateArray = [...dateArray, date];
					}
				});

				if (responseJson.list && (responseJson.list.length === 0)) {
					end = true;
				}

				yield put(loadAdhocUrl(newListArray, pageNo, dateArray, end));
			}
		} else if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		}
	} catch (err) {
		console.log('Error in fetch adhoc url saga', err);
	}
}

export default function* handleFile() {
	yield takeLatest(FETCH_ADHOC_URL_LIST, fetchAdhocUrl);
	yield takeLatest(LOAD_MORE_ADHOC_URL, fetchAdhocUrl);
}
