export const EDITOR_SET_USER_ROLE = 'EDITOR_SET_USER_ROLE';
export const EDITOR_ON_TAB_CHANGE = 'EDITOR_ON_TAB_CHANGE';
export const EDITOR_ON_BRAND_CHANGE = 'EDITOR_ON_BRAND_CHANGE';

export const FETCH_EDITOR_DATA = 'FETCH_EDITOR_DATA';
export const FETCH_EDITOR_DATA_SUCCESS = 'FETCH_EDITOR_DATA_SUCCESS';
export const FETCH_EDITOR_DATA_FAILURE = 'FETCH_EDITOR_DATA_FAILURE';

export const CREATE_STORY = 'CREATE_STORY';
export const CREATE_STORY_SUCCESS = 'CREATE_STORY_SUCCESS';
export const CREATE_STORY_FAILURE = 'CREATE_STORY_FAILURE';

export const UPDATE_STORY = 'UPDATE_STORY';
export const UPDATE_STORY_SUCCESS = 'UPDATE_STORY_SUCCESS';
export const UPDATE_STORY_FAILURE = 'UPDATE_STORY_FAILURE';

export const EDITOR_ON_FORM_METADATA_CHANGE = 'EDITOR_ON_FORM_METADATA_CHANGE';
export const EDITOR_ON_FORM_DATA_CHANGE = 'EDITOR_ON_FORM_DATA_CHANGE';
export const EDITOR_HANDLE_BLUR = 'EDITOR_HANDLE_BLUR';

export const SET_DEFAULT_SOCIAL_TITLE = 'SET_DEFAULT_SOCIAL_TITLE';
export const SET_DEFAULT_SOCIAL_EXCERPT = 'SET_DEFAULT_SOCIAL_EXCERPT';
export const SET_DEFAULT_SOCIAL_IMAGE = 'SET_DEFAULT_SOCIAL_IMAGE';
export const SET_CONTENT_DUPLICATE = 'SET_CONTENT_DUPLICATE';

export const EDITOR_SET_ALL_ELEMENTS_BLUR = 'EDITOR_SET_ALL_ELEMENTS_BLUR';
export const EDITOR_HAS_ERRORS = 'EDITOR_HAS_ERRORS';
export const SET_EDITOR_FORM_MESSAGE = 'SET_EDITOR_FORM_MESSAGE';
export const CLEAR_EDITOR_DATA = 'CLEAR_EDITOR_DATA';

export const EDITOR_RUN_SEO_ANALYSIS = 'EDITOR_RUN_SEO_ANALYSIS';
export const EDITOR_RUN_SEO_ANALYSIS_SUCCESS = 'EDITOR_RUN_SEO_ANALYSIS_SUCCESS';
export const EDITOR_RUN_SEO_ANALYSIS_ERROR = 'EDITOR_RUN_SEO_ANALYSIS_ERROR';
export const EDITOR_ON_FOCUS_KEYPHRASE_CHANGE = 'EDITOR_ON_FOCUS_KEYPHRASE_CHANGE';
export const SET_EDITOR_ACTION = 'SET_EDITOR_ACTION';

export const EDITOR_RUN_PLAGIARISM_CHECK = 'EDITOR_RUN_PLAGIARISM_CHECK';
export const EDITOR_RUN_PLAGIARISM_CHECK_SUCCESS = 'EDITOR_RUN_PLAGIARISM_CHECK_SUCCESS';
export const EDITOR_RUN_PLAGIARISM_CHECK_FAILURE = 'EDITOR_RUN_PLAGIARISM_CHECK_FAILURE';

export const SET_EDITOR_SOCKET_DATA = 'SET_EDITOR_SOCKET_DATA';

export const SET_EDITOR_DOC_CONVERSION_DATA = 'SET_EDITOR_DOC_CONVERSION_DATA';
export const UPDATE_EDITOR_DOC_CONVERSION_DATA = 'UPDATE_EDITOR_DOC_CONVERSION_DATA';
export const UPDATE_EDITOR_DOC_CONVERTED_DELTA = 'UPDATE_EDITOR_DOC_CONVERTED_DELTA';

export const FETCH_EDITOR_STORY_REVISIONS = 'FETCH_EDITOR_STORY_REVISIONS';
export const FETCH_EDITOR_STORY_REVISIONS_SUCCESS = 'FETCH_EDITOR_STORY_REVISIONS_SUCCESS';
export const FETCH_EDITOR_STORY_REVISIONS_FAILURE = 'FETCH_EDITOR_STORY_REVISIONS_FAILURE';

export const ADD_YOUTUBE_VIDEO = 'ADD_YOUTUBE_VIDEO';
export const ADD_YOUTUBE_VIDEO_SUCCESS = 'ADD_YOUTUBE_VIDEO_SUCCESS';
export const ADD_YOUTUBE_VIDEO_FAILURE = 'ADD_YOUTUBE_VIDEO_FAILURE';

export const ADD_VIDEO_FROM_GALLERY = 'ADD_VIDEO_FROM_GALLERY';

export const REPLACE_VIDEO = 'REPLACE_VIDEO';

export const AUTO_SAVE_STORY = 'AUTO_SAVE_STORY';
export const AUTO_SAVE_STORY_SUCCESS = 'AUTO_SAVE_STORY_SUCCESS';
export const AUTO_SAVE_STORY_FAILURE = 'AUTO_SAVE_STORY_FAILURE';
export const CLEAR_AUTO_SAVE_MESSAGE = 'CLEAR_AUTO_SAVE_MESSAGE';

export const FETCH_SECTORS = 'FETCH_SECTORS';
export const FETCH_SECTORS_SUCCESS = 'FETCH_SECTORS_SUCCESS';

export const UPDATE_SNIPPETS = 'UPDATE_SNIPPETS';
export const SET_UNIQUE_SLUG = 'SET_UNIQUE_SLUG';
export const SET_TOP_PICK = 'SET_TOP_PICK';
export const CLEAR_TOP_PICKS_DATA = 'CLEAR_TOP_PICKS_DATA';

// webinar related
export const UPDATE_WEBINAR_BASIC_INFO = 'UPDATE_WEBINAR_BASIC_INFO';
export const UPDATE_WEBINAR_REGISTRATION_FORM_DATA = 'UPDATE_WEBINAR_REGISTRATION_FORM_DATA';
export const FORM_ELEMENT_ORDER_CHANGE = 'FORM_ELEMENT_ORDER_CHANGE';
export const FETCH_WEBINAR_QUESTIONS = 'FETCH_WEBINAR_QUESTIONS';
export const FETCH_WEBINAR_QUESTIONS_SUCCESS = 'FETCH_WEBINAR_QUESTIONS_SUCCESS';
export const CHECK_FOR_NEW_QUESTIONS = 'CHECK_FOR_NEW_QUESTIONS';
export const CHECK_FOR_NEW_QUESTIONS_SUCCESS = 'CHECK_FOR_NEW_QUESTIONS_SUCCESS';
export const GENERATE_WEBINAR_REPORT = 'GENERATE_WEBINAR_REPORT';
export const REPORT_GENERATE_COMPLETE = 'REPORT_GENERATE_COMPLETE';
export const DOWNLOAD_WEBINAR_REPORT = 'DOWNLOAD_WEBINAR_REPORT';
export const REPORT_DOWNLOAD_COMPLETE = 'REPORT_DOWNLOAD_COMPLETE';
export const FETCH_LIVE_STREAM_TYPES = 'FETCH_LIVE_STREAM_TYPES';
export const FETCH_LIVE_STREAM_TYPES_SUCCESS = 'FETCH_LIVE_STREAM_TYPES_SUCCESS';
export const FETCH_LIVE_STREAM_TYPES_FAILURE = 'FETCH_LIVE_STREAM_TYPES_FAILURE';

// boni composer related
export const BONI_COMPOSER_CHANGE = 'BONI_COMPOSER_CHANGE';
export const BONI_COMPOSER_BRNAD_SECTION_CHANGE = 'BONI_COMPOSER_BRNAD_SECTION_CHANGE';
