import { Map } from 'immutable';
import {
	FETCH_STORY_LIST_SUCCESS, FETCH_STORY_LIST_ERROR, SET_STORY_LIST_STORY_STATUS, UPDATE_STORYLIST_STORYITEM_STATUS_ERROR, FETCH_STORYLIST_BRAND_SUCCESS, FETCH_STORY_LIST, UPDATE_STORYLIST_STORYITEM_STATUS, UPDATE_STORYLIST_STORYITEM_STATUS_SUCCESS, SET_STORY_LIST_SELECTED_BRAND, SET_STORY_LIST_PAGE,
} from './type';

const BRAND_ITEM_ALL = {
	id: 0,
	name: 'All',
	slug: 'all',
};

const initialState = Map({
	loading: false,
	error: false,
	stories: null,
	selectedStatus: 'Drafts',
	selectedBrand: null,
	brands: null,
	page: 1,
});

function storyListReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_STORY_LIST:
		return state
			.set('loading', true)
			.set('error', false);
	case FETCH_STORY_LIST_SUCCESS:
		return state
			.set('loading', false)
			.set('error', false)
			.set('stories', action.stories);
	case FETCH_STORY_LIST_ERROR:
		return state
			.set('error', true)
			.set('loading', false)
			.set('stories', null);
	case SET_STORY_LIST_STORY_STATUS:
		return state
			.set('selectedStatus', action.status);
	case UPDATE_STORYLIST_STORYITEM_STATUS:
		return state
			.set('loading', true);
	case UPDATE_STORYLIST_STORYITEM_STATUS_SUCCESS:
		return state
			.set('loading', false)
			.set('error', false);
	case UPDATE_STORYLIST_STORYITEM_STATUS_ERROR:
		return state
			.set('error', true)
			.set('loading', false);
	case FETCH_STORYLIST_BRAND_SUCCESS: {
		const temp = [];
		temp.push(BRAND_ITEM_ALL);
		const incoming = action.brands;
		incoming.forEach((item) => { temp.push(item); });
		return state
			.set('brands', temp);
	}
	case SET_STORY_LIST_SELECTED_BRAND:
		return state
			.set('selectedBrand', action.brand);
	case SET_STORY_LIST_PAGE:
		return state
			.set('page', action.page);
	default:
		return state;
	}
}

export default storyListReducer;
