import {
	ON_IMAGE_EDITOR_FORM_DATA_CHANGE, CLEAR_IMAGE_EDITOR_FORM_DATA, IMAGE_EDITOR_SAVE_IMAGE, IMAGE_EDITOR_SAVE_IMAGE_SUCCESS, IMAGE_EDITOR_SAVE_IMAGE_FAILURE, SET_IMAGE_EDITOR_FORM_MESSAGE, FETCH_IMAGE_VERSIONS, FETCH_IMAGE_VERSIONS_SUCCESS, FETCH_IMAGE_VERSIONS_FAILURE, FETCH_IMAGE_BY_ID, FETCH_IMAGE_BY_ID_SUCCESS, FETCH_IMAGE_BY_ID_FAILURE, CHANGE_VIEW,
} from './type';

export function onImageEditorFormDataChange(changedData) {
	return {
		type: ON_IMAGE_EDITOR_FORM_DATA_CHANGE,
		changedData,
	};
}

export function clearImageEditorFormData() {
	return {
		type: CLEAR_IMAGE_EDITOR_FORM_DATA,
	};
}

export function imageEditorSaveImage(image) {
	return {
		type: IMAGE_EDITOR_SAVE_IMAGE,
		image,
	};
}

export function imageEditorSaveImageSuccess(successResponse) {
	return {
		type: IMAGE_EDITOR_SAVE_IMAGE_SUCCESS,
		successResponse,
	};
}

export function imageEditorSaveImageFailure(error) {
	return {
		type: IMAGE_EDITOR_SAVE_IMAGE_FAILURE,
		error,
	};
}

export function setImageEditorFormMessage(message) {
	return {
		type: SET_IMAGE_EDITOR_FORM_MESSAGE,
		message,
	};
}

export function fetchImageVersions(parentId) {
	return {
		type: FETCH_IMAGE_VERSIONS,
		parentId,
	};
}

export function fetchImageVersionsSuccess(imageVersions) {
	return {
		type: FETCH_IMAGE_VERSIONS_SUCCESS,
		imageVersions,
	};
}

export function fetchImageVersionsFailure(error) {
	return {
		type: FETCH_IMAGE_VERSIONS_FAILURE,
		error,
	};
}

export function fetchImageById(imageData) {
	return {
		type: FETCH_IMAGE_BY_ID,
		imageData,
	};
}

export function fetchImageByIdSuccess(asset, selectedImage) {
	return {
		type: FETCH_IMAGE_BY_ID_SUCCESS,
		asset,
		selectedImage,
	};
}

export function fetchImageByIdFailure(error) {
	return {
		type: FETCH_IMAGE_BY_ID_FAILURE,
		error,
	};
}

export function changeView(view) {
	return {
		type: CHANGE_VIEW,
		view,
	};
}
