import {
	call, put, takeLatest, select,
} from 'redux-saga/effects';
import { SAVE_STORY_ASSOCIATIONS, FETCH_STORY_ASSOCIATIONS } from './type';
import { STORY_ASSOCIATION_API_BASE_URL } from './constants';
import {
	saveStoryAssociationsFailure, saveStoryAssociationsSuccess, fetchStoryAssociationsSuccess, fetchStoryAssociationsFailure,
} from './actions';
import { redirectTo } from '../../utils/accessRedirection';
import { makeSelectLocation } from '../App/selectors';

let CURRENT_LOCATION;

function* saveStoryAssociations(action) {
	try {
		const URL = `${STORY_ASSOCIATION_API_BASE_URL}/story-associations/save/${action.storyId}`;
		const options = {
			method: 'POST',
			credentials: 'include',
			body: action.associations,
		};
		const response = yield call(fetch, URL, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const parsed = yield response.json();
			if (parsed.success) {
				yield put(saveStoryAssociationsSuccess(parsed));
			} else {
				yield put(saveStoryAssociationsFailure('Saving Failed! Something went wrong'));
			}
		}
	} catch (err) {
		yield put(saveStoryAssociationsFailure(err));
	}
}

function* getStoryAssociations(action) {
	try {
		const URL = `${STORY_ASSOCIATION_API_BASE_URL}/story-associations/${action.storyId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, URL, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const parsed = yield response.json();
			yield put(fetchStoryAssociationsSuccess(parsed));
		}
	} catch (err) {
		yield put(fetchStoryAssociationsFailure(err));
	}
}

export default function* tagStoriesData() {
	const location = yield select(makeSelectLocation());
	CURRENT_LOCATION = location.pathname;
	yield takeLatest(SAVE_STORY_ASSOCIATIONS, saveStoryAssociations);
	yield takeLatest(FETCH_STORY_ASSOCIATIONS, getStoryAssociations);
}
