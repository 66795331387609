import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { WrapperContainer, Button } from '../../components/Styles';
import { Row, Col } from '../../components/Responsive';

import {
	selectError, selectMessage, selectErrorResponse, selectLoading, selectCuratedSetList, selectOpenModal, selectStorySearchTitle, selectStorySearchResults, selectModalLoading, selectStorySearchOffset, selectEndOfStorySearchList, selectStorySearchSelect, selectSaving, selectSavingResponseType, selectSavingMessage,
} from './selector';
import {
	setOpenModal, getCuratedData, setCuratedSetList, setCloseModal, setStorySearchTitle, setStorySearchResults, setModalLoading, setModalLoaded, discardChanges, setStorySearchOffset, setEndOfStorySearchList, setStorySearchSelect, setSaving, setSavingResponseMessage, setResponseData, clearStorySearchData, resetPage,
} from './actions';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';
import CmsPageSetCard from '../../components/CMSPageSetCard';
import PlusIconCard from '../../components/PlusIconCard';
import AsyncInputModal from '../../components/AsyncInputModal';
import ResponseMessage from '../../components/ResponseMessage';
import { Container, SecondSection } from './styles';
import { checkType } from '../../utils/utility_function';
import { makeSelectUserBrands } from '../App/selectors';

const CURATED_SET_LIST_SIZE = 4;
const CURATED_SET_LIST_SIZE_FOR_BLOCKCHAIN_STORY = 4;

const CMSPageCurationSet = (props) => {
	const {
		pageTitle,
		// user,
	} = props;
	const dispatch = useDispatch();
	const userBrands = useSelector(makeSelectUserBrands());
	const loading = useSelector(selectLoading());
	const saving = useSelector(selectSaving());
	const error = useSelector(selectError());
	const errorResponse = useSelector(selectErrorResponse());
	const message = useSelector(selectMessage());
	const savingResponseType = useSelector(selectSavingResponseType());
	const savingMessage = useSelector(selectSavingMessage());
	const selectedBrandValue = {
		id: 2,
		imagelocation: 'https://images.yourstory.com/cs/images/brands/BCCI-1641381341276.png',
		internal: false,
		locale: 'en_GB',
		name: 'YourStory',
		slug: 'yourstory',
	};
	const curatedSetList = useSelector(selectCuratedSetList());
	const openModalFor = useSelector(selectOpenModal());
	const modalLoading = useSelector(selectModalLoading());
	const storySearchTitle = useSelector(selectStorySearchTitle());
	const storySearchResults = useSelector(selectStorySearchResults());
	const storySearchOffset = useSelector(selectStorySearchOffset());
	const storySearchSelect = useSelector(selectStorySearchSelect());
	const endOfStorySearchList = useSelector(selectEndOfStorySearchList());
	const [sectionOneElements, setSectionOneElements] = useState([]);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [sectionTwoArray, setSectionTwoArray] = useState([]);
	let cardPositionNumber = 0;

	useEffect(() => {
		pageTitle('cmsPageCurationSet');
		dispatch(resetPage());
		handleDropdownChange('event');
	}, []);

	useEffect(() => {
		if (curatedSetList?.length > 0) {
			filterFeaturedCuratedSetBrandWise();
		}
	}, [curatedSetList]);

	const onStorySelectionConfirm = () => {
		if ((typeof storySearchSelect) === 'number') {
			const newStoryObject = _.cloneDeep(storySearchResults[storySearchSelect], true);
			const isOfSectionOne = openModalFor.includes('first-section');
			const positionToInsertAt = parseInt(openModalFor.split('-')[openModalFor.split('-').length - 1], 10);
			const brandId = selectedBrandValue.id;
			let modified = false;
			const newCuratedSetList = curatedSetList.map((brandCuratedSet) => {
				if (brandCuratedSet.brandId === brandId) {
					const newBrandCuratedSet = _.cloneDeep(brandCuratedSet, true);
					if (isOfSectionOne) {
						const brandObj = userBrands.find((brandObject) => brandId === brandObject.id);
						newStoryObject.fromBrand = newStoryObject.brand;
						newStoryObject.brand = {
							name: brandObj.key,
							slug: brandObj.value,
							id: brandObj.id,
						};
						newStoryObject.position = positionToInsertAt + 1;
						newBrandCuratedSet.value[positionToInsertAt] = newStoryObject;
					} else {
						const brandObj = userBrands.find((brandObject) => brandId === brandObject.id);
						newStoryObject.fromBrand = newStoryObject.brand;
						newStoryObject.brand = {
							name: brandObj.key,
							slug: brandObj.value,
							id: brandObj.id,
						};
						newStoryObject.position = positionToInsertAt + 1;
						newBrandCuratedSet.scheduledValue[positionToInsertAt] = newStoryObject;
					}
					modified = true;
					return newBrandCuratedSet;
				}
				return brandCuratedSet;
			});

			if (modified) {
				modified = false;
				dispatch(setCuratedSetList(newCuratedSetList));
				closeModalOnClick();
			}
		}
		if (curatedSetList?.length > 0) {
			cardPositionNumber = 0;
			filterFeaturedCuratedSetBrandWise();
		}
	};
	async function onClickOnSave() {
		try {
			dispatch(setSavingResponseMessage('', ''));
			setShowConfirmationModal(false);

			if ((!saving) && isBrandSelected()) {
				const curatedSetItem = curatedSetList.find((curatedObj) => curatedObj.brandId === selectedBrandValue.id);
				curatedSetItem.brandSlug = 'yourstory';
				if (curatedSetItem.value && curatedSetItem.value?.length > 0) {
					const newCuratedSetStoryArray = new Array(7).fill(0).map((value, idx) => {
						const foundObject = curatedSetItem.value.find((storyObject) => (storyObject !== null && Object.keys(storyObject).length > 0 && (idx + 1) === storyObject?.position));
						if (foundObject) {
							return foundObject;
						}
						return {};
					});
					curatedSetItem.value = newCuratedSetStoryArray || [];
					// return newCuratedSetStoryArray;
				}
				curatedSetItem.scheduledValue = curatedSetItem.value;
				// 	.map((valueObject, idx) => {
				// 	if (Object.keys(curatedSetItem.scheduledValue[idx]).length > 1) {
				// 		curatedSetItem.scheduledValue[idx].expiresBy = valueObject.expiresBy;
				// 	}
				// 	return curatedSetItem.scheduledValue[idx];
				// });
				setSaving(true);
				return await fetch('/api/v2/rw/curatedset/save?key=SPONSORED_STORY', {
					credentials: 'include',
					method: 'POST',
					headers: new Headers({ 'Content-Type': 'application/json' }),
					body: JSON.stringify({
						brandSlug: selectedBrandValue.slug,
						curatedSetItem,
					}),
				}).then((response) => response.json())
					.then((responseJson) => {
						if (responseJson.status === 'success') {
							dispatch(setResponseData(userBrands, curatedSetList));
							dispatch(setSavingResponseMessage('success', 'Successfully saved'));
						} else if (responseJson.status === 'fail') {
							dispatch(setSavingResponseMessage('fail', `Failed, ${responseJson.error.message}`));
						}
						setShowConfirmationModal(true);
						setSaving(false);
						setTimeout(() => setShowConfirmationModal(false), 10 * 1000);
					});
			}
			return null;
		} catch (err) {
			setSaving(false);
			dispatch(setSavingResponseMessage('fail', 'Failed, Error occurred while saving data, unable to fetch'));
			setShowConfirmationModal(true);
			setTimeout(() => setShowConfirmationModal(false), 10 * 1000);
			return null;
		}
	}

	const onClickOnDiscard = () => {
		if (isBrandSelected()) {
			dispatch(discardChanges());
			dispatch(setSavingResponseMessage('', ''));
		}
	};

	async function onChangeOfTitle(event, type) {
		let searchValue = '';
		let offset = 0;
		try {
			if (type === 'loadMore') {
				searchValue = storySearchTitle;
				const earlierOffset = (typeof storySearchOffset === 'number') ? storySearchOffset : 0;
				offset = earlierOffset + 10;
				dispatch(setStorySearchOffset(offset));
			} else {
				searchValue = event.target.value;
				dispatch(setStorySearchTitle(searchValue));
				dispatch(setEndOfStorySearchList(false));
				dispatch(setStorySearchOffset(0));
				dispatch(setModalLoading());
				dispatch(setStorySearchSelect(null));
			}

			let isOfSectionTwo = true;
			let scheduledDate = null;

			if (checkType(openModalFor, 'string')) {
				isOfSectionTwo = !openModalFor.includes('first-section');
				if (isOfSectionTwo) {
					const [, , selectedIndex] = openModalFor.split('-');
					const firstSectionStory = getBrandSelectedStories()[selectedIndex];
					scheduledDate = firstSectionStory ? firstSectionStory.expiresBy : null;
				}
			}

			if (searchValue) {
				await fetch('/api/v2/rw/stories/search/sponsored', {
					credentials: 'include',
					method: 'POST',
					headers: new Headers({ 'Content-Type': 'application/json' }),
					body: JSON.stringify({
						searchValue,
						scheduled: {
							include: isOfSectionTwo,
							changeDate: scheduledDate,
						},
						brand: selectedBrandValue.slug,
						limit: 10,
						offset,
					}),
				}).then((response) => response.json())
					.then((responseJson) => {
						if (responseJson.status === 'success') {
							if (responseJson.stories && ('length' in (responseJson.stories)) && (responseJson.stories.length > 0)) {
								if (offset === 0) {
									dispatch(setStorySearchResults(responseJson.stories));
									if ((responseJson.stories.length % 10) !== 0) {
										dispatch(setEndOfStorySearchList(true));
									}
								} else {
									let newArray = [];
									if (storySearchResults && ('length' in storySearchResults) && (storySearchResults.length > 0)) {
										newArray = storySearchResults;
									}
									newArray = [...newArray, ...responseJson.stories];
									dispatch(setStorySearchResults(newArray));
									if ((responseJson.stories.length % 10) !== 0) {
										dispatch(setEndOfStorySearchList(true));
									}
								}
							} else if (offset === 0) {
								dispatch(setStorySearchResults([]));
								dispatch(setEndOfStorySearchList(true));
							} else {
								dispatch(setEndOfStorySearchList(true));
							}
						} else if (responseJson.status === 'fail') {
							dispatch(setStorySearchResults([]));
						}
					});
				dispatch(setModalLoaded());
			} else {
				dispatch(clearStorySearchData());
			}
		} catch (err) {
			dispatch(setModalLoaded());
			dispatch(setSavingResponseMessage('fail', 'Failed, Error occurred while saving data, unable to fetch'));
		}
	}
	const getBrandSelectedStories = () => {
		let stories = [];
		if (isBrandSelected()) {
			const brandId = selectedBrandValue.id;
			stories = curatedSetList.find((brandCuratedSet) => brandCuratedSet.brandId === brandId);
		}
		return stories.value;
	};

	const isBrandSelected = () => {
		if (selectedBrandValue.slug) {
			return true;
		}
		return false;
	};

	const changeModalSelectedStory = (positionOfStory) => {
		dispatch(setStorySearchSelect(positionOfStory));
	};

	const openModalOnClick = (elementName) => {
		dispatch(clearStorySearchData());
		dispatch(setStorySearchSelect(null));
		dispatch(setOpenModal(elementName));
	};
	const closeModalOnClick = () => {
		dispatch(setCloseModal());
	};

	const changeOfExpiryDate = (newExpiryDate, position) => {
		const brandId = selectedBrandValue.id;
		let modified = false;
		const newCuratedSetList = curatedSetList.map((brandCuratedSet) => {
			if (brandCuratedSet.brandId === brandId) {
				const newBrandCuratedSet = _.cloneDeep(brandCuratedSet, true);
				const positionInArray = position - 1;
				newBrandCuratedSet.value[positionInArray].expiresBy = newExpiryDate;
				modified = true;
				return newBrandCuratedSet;
			}
			return brandCuratedSet;
		});

		if (modified) {
			modified = false;
			dispatch(setCuratedSetList(newCuratedSetList));
		}
	};

	const changePositionOfStoriesToUp = (brandCuratedSetValueArray, positionInArray, position) => {
		const newBrandCuratedSetValueArray = brandCuratedSetValueArray;
		if ((typeof newBrandCuratedSetValueArray[positionInArray]?.position) === 'number') {
			newBrandCuratedSetValueArray[positionInArray].position = position - 1;
		}

		if ((typeof newBrandCuratedSetValueArray[positionInArray - 1]?.position) === 'number') {
			newBrandCuratedSetValueArray[positionInArray - 1].position = position;
		}

		const temp = newBrandCuratedSetValueArray[positionInArray];
		newBrandCuratedSetValueArray[positionInArray] = newBrandCuratedSetValueArray[positionInArray - 1];
		newBrandCuratedSetValueArray[positionInArray - 1] = temp;
		return newBrandCuratedSetValueArray;
	};

	const changePositionOfStoriesToDown = (brandCuratedSetValueArray, positionInArray, position) => {
		const newBrandCuratedSetValueArray = brandCuratedSetValueArray;
		if ((typeof newBrandCuratedSetValueArray[positionInArray]?.position) === 'number') {
			newBrandCuratedSetValueArray[positionInArray].position = position + 1;
		}

		if ((typeof newBrandCuratedSetValueArray[positionInArray + 1]?.position) === 'number') {
			newBrandCuratedSetValueArray[positionInArray + 1].position = position;
		}

		const temp = newBrandCuratedSetValueArray[positionInArray];
		newBrandCuratedSetValueArray[positionInArray] = newBrandCuratedSetValueArray[positionInArray + 1];
		newBrandCuratedSetValueArray[positionInArray + 1] = temp;
		return newBrandCuratedSetValueArray;
	};

	const swapCard = (moveType, position) => {
		const { id } = selectedBrandValue;
		let modified = false;
		const newCuratedSetList = curatedSetList.map((brandCuratedSet) => {
			if (brandCuratedSet.brandId === id) {
				const newBrandCuratedSet = _.cloneDeep(brandCuratedSet, true);
				const positionInArray = position - 1;
				if ((positionInArray !== 0) && (moveType === 'up')) {
					// change position value
					newBrandCuratedSet.value = changePositionOfStoriesToUp(newBrandCuratedSet.value.slice(0, 4), positionInArray, position);
					newBrandCuratedSet.scheduledValue = changePositionOfStoriesToUp(newBrandCuratedSet.scheduledValue.slice(0, 4), positionInArray, position);
					modified = true;
				}

				if ((positionInArray !== CURATED_SET_LIST_SIZE - 1) && (moveType === 'down')) {
					// change position value
					newBrandCuratedSet.value = changePositionOfStoriesToDown(newBrandCuratedSet.value.slice(0, 4), positionInArray, position);
					newBrandCuratedSet.scheduledValue = changePositionOfStoriesToDown(newBrandCuratedSet.scheduledValue.slice(0, 4), positionInArray, position);
					modified = true;
				}
				return newBrandCuratedSet;
			}
			return brandCuratedSet;
		});

		if (modified) {
			dispatch(setCuratedSetList(newCuratedSetList));
		}
	};

	const deleteCard = (position, type) => {
		const brandId = selectedBrandValue.id;
		let modified = false;
		let storiesPresent = 0;

		const newCuratedSetList = curatedSetList.map((brandCuratedSet) => {
			if (brandCuratedSet.brandId === brandId) {
				const newBrandCuratedSet = _.cloneDeep(brandCuratedSet, true);

				// Count of stories present
				newBrandCuratedSet.value.slice(0, 4).forEach((storyObject) => {
					if (Object.keys(storyObject).length > 1) {
						storiesPresent += 1;
					}
				});

				if ((storiesPresent > 1 || type !== 'normal')) {
					if (type === 'normal') {
						newBrandCuratedSet.value.splice((position - 1), 1, {});
					}
					newBrandCuratedSet.scheduledValue.slice(0, 4).splice((position - 1), 1, {});
					modified = true;
				}
				return newBrandCuratedSet;
			}
			return brandCuratedSet;
		});

		if (modified) {
			return dispatch(setCuratedSetList(newCuratedSetList));
		} if (storiesPresent === 1) {
			return dispatch(setSavingResponseMessage('fail', 'Minimum of one story is required in a brand to save'));
		}
		return null;
	};

	const filterFeaturedCuratedSetBrandWise = () => {
		if (selectedBrandValue && selectedBrandValue.id) {
			const brandSelectedCuratedSet = curatedSetList.find((curatedObject) => selectedBrandValue.id === curatedObject.brandId);

			if (brandSelectedCuratedSet && brandSelectedCuratedSet.value) {
				const sectionOneElements = brandSelectedCuratedSet.value.slice(0, 4);
				const sectionTwoElements = brandSelectedCuratedSet.scheduledValue;
				setSectionOneElements([...sectionOneElements]);
				const sectionTwoArray = sectionOneElements.map((storyObj, idx) => {
					if (Object.keys(storyObj).length > 1) {
						if (storyObj.expiresBy) {
							if (Object.keys(sectionTwoElements[idx]).length !== 0) {
								return (<CmsPageSetCard
									deleteCard={() => {
										const position = idx + 1;
										const type = 'scheduled';
										deleteCard(position, type);
									}}
									moveUp={() => {
										const position = idx + 1;
										swapCard('up', position);
									}}
									moveDown={() => {
										const position = idx + 1;
										swapCard('down', position);
									}}
									changeDate={(newExpiryDate) => {
										const position = idx + 1;
										changeOfExpiryDate(newExpiryDate, position);
									}}
									title={sectionTwoElements[idx].title}
									image_url={(function () {
										if (((typeof sectionTwoElements[idx].metadata) === 'object') && ('media' in sectionTwoElements[idx].metadata) && sectionTwoElements[idx].metadata.media) {
											return sectionTwoElements[idx].metadata.media;
										}
										return null;
									}())}
									image_text=""
									brand_text={(function () {
										if (((typeof sectionTwoElements[idx].fromBrand) === 'object') && ('name' in sectionTwoElements[idx].fromBrand) && sectionTwoElements[idx].fromBrand.name) {
											return sectionTwoElements[idx].fromBrand.name;
										}
										return '';
									}())}
									expiresBy={null}
								/>);
							}
						} else {
							return null;
						}
					}
					return (<PlusIconCard
						onClick={() => {
							const elementName = `second-section-${idx}`;
							openModalOnClick(elementName);
						}}
					/>);
				});

				setSectionTwoArray(sectionTwoArray);
			}
		}
	};

	const handleDropdownChange = () => {
		dispatch(getCuratedData({ id: 2 }));
	};

	const renderModal = () => {
		if (openModalFor) {
			return (<AsyncInputModal
				title="Add a New Feature"
				onChangeOfValue={onChangeOfTitle}
				placeHolder="Search By Title or URL"
				inputValue={storySearchTitle}
				resultType="storyCard"
				resultValue={storySearchResults || []}
				closeModal={closeModalOnClick}
				loading={modalLoading}
				selectedItem={changeModalSelectedStory}
				onConfirm={onStorySelectionConfirm}
				onLoadMore={() => {
					onChangeOfTitle({}, 'loadMore');
				}}
				selectedItemPosition={storySearchSelect}
				endOfLoadMoreList={endOfStorySearchList}
			/>);
		}
		return null;
	};

	const renderButtons = () => {
		if (isBrandSelected()) {
			return (<div className="m-4">
				<Button primary className="m-1 mr-3" onClick={() => { if (!saving) { onClickOnSave(); } }} style={{ minWidth: '140px' }}>
					{saving
						? <i className="fa fa-spinner fa-spin" style={{ fontSize: '20px', color: 'white' }} /> : 'SAVE'}
				</Button>
				<Button light className="m-1 mr-3" onClick={onClickOnDiscard} style={{ minWidth: '120px' }}>DISCARD</Button>
			</div>);
		}
		return null;
	};

	return (
		<Container>
			<WrapperContainer className="pt-4">
				<Row className="p-3 m-0" key="header-text">
					<Col xs={11} sm={11} md={7} lg={7}>
						<div>{`These are the stories which are shown in the top of the brand page. A maximum of ${selectedBrandValue.slug === 'yourstory' ? CURATED_SET_LIST_SIZE_FOR_BLOCKCHAIN_STORY : CURATED_SET_LIST_SIZE} stories are allowed at any point of time`}</div>
					</Col>
				</Row>
				{(loading === true) && <LoadingIcon />}
				{(!loading && curatedSetList?.length > 0) && <>
					{
						sectionOneElements.map((storyObj, idx) => {
							if (Object.keys(storyObj).length > 1) {
								cardPositionNumber += 1;
								return (<Row className="p-3 m-0" key={`sponsored-card-set-${idx + 1}`}>
									<Col xs={5} sm={5} md={4} lg={4}>
										<CmsPageSetCard
											deleteCard={() => {
												const position = idx + 1;
												const type = 'normal';
												deleteCard(position, type);
											}}
											moveUp={() => {
												const position = idx + 1;
												swapCard('up', position);
											}}
											moveDown={() => {
												const position = idx + 1;
												swapCard('down', position);
											}}
											changeDate={(newExpiryDate) => {
												const position = idx + 1;
												changeOfExpiryDate(newExpiryDate, position);
											}}
											title={storyObj.title}
											image_url={(function () {
												if (((typeof storyObj.metadata) === 'object') && ('media' in storyObj.metadata) && storyObj.metadata.media) {
													return storyObj.metadata.media;
												}
												return null;
											}())}
											image_text={`Shown at position ${cardPositionNumber}`}
											brand_text={(function () {
												if (((typeof storyObj.fromBrand) === 'object') && ('name' in storyObj.fromBrand) && storyObj.fromBrand.name) {
													return storyObj.fromBrand.name;
												}
												return '';
											}())}
											expiresBy={storyObj.expiresBy ? new Date(storyObj.expiresBy) : null}
										/>
									</Col>
									<Col>
										<SecondSection>
											{sectionTwoArray[idx]}
										</SecondSection>
									</Col>
								</Row>);
							}
							return (<Row className={`p-3 m-0 ml-2 plus-card-${idx + 1}}`} key={`plus-card-${idx + 1}`}>
								<Col xs={5} sm={5} md={4} lg={4}>
									<PlusIconCard
										onClick={() => {
											const elementName = `first-section-${idx}`;
											openModalOnClick(elementName);
										}}
									/>
								</Col>
							</Row>);

							// return returnVal;
						})
					}
				</>}
				{renderModal()}
				<Row className="p-3 m-0">
					<Col xs={11} sm={11} md={7} lg={7}>
						<div className="m-4">{
							(showConfirmationModal && savingResponseType && savingMessage) && (<>
								{savingResponseType === 'success' && <ResponseMessage embedElement={<i className="fas fa-check-circle" />} type="success" message={savingMessage} />}
								{savingResponseType === 'fail' && <ResponseMessage embedElement={<i className="fas fa-exclamation-circle" />} type="fail" message={savingMessage} />}
							</>)
						}
						</div>
					</Col>
				</Row>
				{!loading && (error || errorResponse) && (!curatedSetList || curatedSetList?.length === 0) && <Row style={{
					width: '100%', minHeight: '400px', justifyContent: 'center', alignItems: 'center',
				}}
				>
					<div>
						<h1 style={{ color: '#bbb' }}>
							{message}
						</h1>
					</div>
				</Row>}
				{renderButtons()}
			</WrapperContainer>
		</Container>
	);
};

CMSPageCurationSet.propTypes = {
	pageTitle: PropTypes.func,
};

export default CMSPageCurationSet;
