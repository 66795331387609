import {
	CREATE_ORG_CHART,
	CREATE_ORG_CHART_SUCCESS,
	CREATE_ORG_CHART_FAILURE,
	UPDATE_ORG_CHART,
	UPDATE_ORG_CHART_SUCCESS,
	UPDATE_ORG_CHART_FAILURE,
	FETCH_ORG_CHART_DATA,
	FETCH_ORG_CHART_DATA_SUCCESS,
	FETCH_ORG_CHART_DATA_FAILURE,
	UPDATE_ORG_CHART_FROM_DATA,
	UPDATE_FORM_MESSAGE,
	SHOW_CONFIRMATION_MODAL,
	HANDLE_BLUR,
	SET_ALL_ELEMENTS_BLUR,
	ORG_CHART_EDITOR_HAS_ERRORS,
	UPDATE_PEOPLE_PROFILE_DATA,
	CREATE_PEOPLE_PROFILE,
	CREATE_PEOPLE_PROFILE_SUCCESS,
	CREATE_PEOPLE_PROFILE_FAILURE,
	SET_ALL_PROFILE_ELEMENTS_BLUR,
	PROFILE_HAS_ERRORS,
	UPDATE_PEOPLE_FORM_MESSAGE,
	FETCH_PEOPLE_PROFILE_ORG_CHART_DATA_SUCCESS,
	FETCH_PEOPLE_PROFILE_ORG_CHART_DATA,
	FETCH_PEOPLE_PROFILE_ORG_CHART_DATA_FAILURE,
} from './type';

export function onOrgChartFormDataChange(changedData) {
	return {
		type: UPDATE_ORG_CHART_FROM_DATA,
		changedData,
	};
}

export function fetchOrgChartData(orgChartId) {
	return {
		type: FETCH_ORG_CHART_DATA,
		orgChartId,
	};
}

export function fetchOrgChartDataSuccess(orgChart) {
	return {
		type: FETCH_ORG_CHART_DATA_SUCCESS,
		orgChart,
	};
}

export function fetchOrgChartDataFailure(error) {
	return {
		type: FETCH_ORG_CHART_DATA_FAILURE,
		error,
	};
}

export function createOrgChart(orgChart) {
	return {
		type: CREATE_ORG_CHART,
		orgChart,
	};
}

export function createOrgChartSuccess(successResponse) {
	return {
		type: CREATE_ORG_CHART_SUCCESS,
		successResponse,
	};
}

export function createOrgChartFailure(error) {
	return {
		type: CREATE_ORG_CHART_FAILURE,
		error,
	};
}

export function updateOrgChart(id, orgChart) {
	return {
		type: UPDATE_ORG_CHART,
		id,
		orgChart,
	};
}

export function updateOrgChartSuccess(successResponse) {
	return {
		type: UPDATE_ORG_CHART_SUCCESS,
		successResponse,
	};
}

export function updateOrgChartFailure(error) {
	return {
		type: UPDATE_ORG_CHART_FAILURE,
		error,
	};
}

export function updateFormMessage(message) {
	return {
		type: UPDATE_FORM_MESSAGE,
		message,
	};
}

export function setConfirmationModal(value) {
	return {
		type: SHOW_CONFIRMATION_MODAL,
		value,
	};
}

export function handleBlur(element) {
	return {
		type: HANDLE_BLUR,
		element,
	};
}

export function setAllElementsBlur() {
	return {
		type: SET_ALL_ELEMENTS_BLUR,
	};
}

export function orgChartEditorHasErrors(errors) {
	return {
		type: ORG_CHART_EDITOR_HAS_ERRORS,
		errors,
	};
}

export function onPeopleProfileDataChange(changedData) {
	return {
		type: UPDATE_PEOPLE_PROFILE_DATA,
		changedData,
	};
}

export function createPeopleProfile(peopleProfile) {
	return {
		type: CREATE_PEOPLE_PROFILE,
		peopleProfile,
	};
}

export function createPeopleProfileSuccess(successResponse) {
	return {
		type: CREATE_PEOPLE_PROFILE_SUCCESS,
		successResponse,
	};
}

export function createPeopleProfileFailure(error) {
	return {
		type: CREATE_PEOPLE_PROFILE_FAILURE,
		error,
	};
}

export function setAllPeopleElementsBlur() {
	return {
		type: SET_ALL_PROFILE_ELEMENTS_BLUR,
	};
}

export function profileHasErrors(errors) {
	return {
		type: PROFILE_HAS_ERRORS,
		errors,
	};
}

export function setPeopleFormMessage(message) {
	return {
		type: UPDATE_PEOPLE_FORM_MESSAGE,
		message,
	};
}

export function fetchSubordinateOrgChartData(subordinateOrgChartId) {
	return {
		type: FETCH_PEOPLE_PROFILE_ORG_CHART_DATA,
		subordinateOrgChartId,
	};
}

export function fetchSubordinateOrgChartDataSuccess(subordinateOrgData) {
	return {
		type: FETCH_PEOPLE_PROFILE_ORG_CHART_DATA_SUCCESS,
		subordinateOrgData,
	};
}

export function fetchSubordinateOrgChartDataFailure(error) {
	return {
		type: FETCH_PEOPLE_PROFILE_ORG_CHART_DATA_FAILURE,
		error,
	};
}
