import React from 'react';
import T from 'prop-types';
import { CoverImageInputWrapper, CoverImageWrapper } from '../EditorComponents/styles';
import closeSvg from '../../images/close.svg';
import { validateImageFormat } from '../../utils/common';

const BannerImageUploader = (props) => {
	const { formData, formElementChange } = props;

	async function handleImageChange(e) {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		if (await validateImageFormat(file)) {
			reader.onloadend = () => {
				formElementChange({
					name: 'banner',
					value: { url: reader.result },
				});
			};
			reader.readAsDataURL(file);
			formElementChange({ name: 'bannerImageFile', value: file });
		}
	}

	function onImageLoad(e) {
		const data = { height: e.target.naturalHeight, width: e.target.naturalWidth };
		formElementChange({ name: 'banner', value: { ...formData.banner, ...data } });
	}

	if (formData && formData.banner && formData.banner.url) {
		return (

			<CoverImageWrapper
				onClick={() => document.getElementById('banner-upload').click()}

			>
				<input
					type="file"
					id="banner-upload"
					style={{ display: 'none' }}
					onChange={handleImageChange}
				/>
				<img
					src={formData.banner.url}
					alt="banner"
					style={{
						width: '100%',
						height: 'auto',
						maxHeight: '600px',
					}}
					onLoad={onImageLoad}
				/>
				<button
					type="button"
					className="close-btn"
					title="Remove Cover"
					onClick={(e) => {
						e.stopPropagation();
						const changedData = {
							url: null,
							height: null,
							width: null,
						};
						formElementChange({ name: 'banner', value: changedData });
						formElementChange({ name: 'bannerImageFile', value: null });
					}}
				>
					<img
						src={closeSvg}
						alt="close"
						width="30"
						height="30"
					/>
				</button>
				<div
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				>
					<p className="lg-text light-text">Click to replace</p>
				</div>
			</CoverImageWrapper>
		);
	}
	return (
		<CoverImageInputWrapper>
			<input
				type="file"
				id="banner-upload"
				style={{ display: 'none' }}
				onChange={handleImageChange}
			/>
			<button
				type="button"
				onClick={() => document.getElementById('banner-upload').click()}
			>
				<span>
					Add a banner image
					<i className="far fa-images ml-3" />
				</span>
			</button>
		</CoverImageInputWrapper>
	);
};

BannerImageUploader.propTypes = {
	formData: T.object,
	formElementChange: T.func,
};

export default BannerImageUploader;
