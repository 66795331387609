import React, {
	useEffect, useReducer, Fragment,
} from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import 'rc-pagination/assets/index.css';
import Pagination from 'rc-pagination';
import T from 'prop-types';
import { WrapperContainer } from '../../../components/Styles';
// import ToastMessage from '../../components/ToastMessage';
// import { RedemptionDoneListHeader, RedemptionDoneList } from '../../components/CaptableGiftCodesComponent/index';
import locale from '../../WatchlistedStories/en_US';
import LoadingIcon from '../../../components/LoadingIcon/LoadingIcon';
import {
	REDEMPTION_DONE_LIST_BASE_URL,
	REDEMPTION_DONE_LIST_STATUS_BASE_URL,
	FETCH_REDEMPTION_DONE_LIST,
	FETCH_REDEMPTION_DONE_LIST_SUCCESS,
	FETCH_REDEMPTION_DONE_LIST_FAILURE,
	FILTER_REDEMPTION_DONE_LIST,
	FILTER_REDEMPTION_DONE_LIST_SUCCESS,
	FILTER_REDEMPTION_DONE_LIST_FAILURE,
	SET_REDEMPTION_DONE_LIST_PAGE,
	SET_ALL_ELEMENTS_BLURRED,
	UPDATE_ERRORS,
	UPDATE_BLURRED_ELEMENT,
	UPDATE_ACTIONS_ERROR,
	UPDATE_ACTIONS_SUCCESS,
	UPDATE_ACTIONS_LOADER,
	SET_ACTIONS_MESSAGE,
	SET_FORM_DATA,
	UPDATE_FORM_DATA,
	SET_ELEMENTS_BLURRED,
} from './constants';
import { RedemptionDoneList, RedemptionDoneListHeader } from '../../../components/CaptableGiftCodesComponent';

const initialState = {
	redemptionDoneList: [],
	redemptionDoneCount: null,
	loading: false,
	error: false,
	page: 1,
	actionLoading: false,
	actionError: false,
	actionMessage: '',
	formData: {
		name: null,
		designation: null,
		giftCode: null,
		status: null,
	},
	blurredElements: {
		name: false,
		designation: false,
		giftCode: false,
		status: false,
	},
	errors: {
		name: false,
		designation: false,
		giftCode: false,
		status: false,
	},
};

function setBlurredElements(blurredElements) {
	return {
		type: SET_ELEMENTS_BLURRED,
		blurredElements,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}
function fetchRedemptionDoneList() {
	return {
		type: FETCH_REDEMPTION_DONE_LIST,
	};
}

function fetchRedemptionDoneListSuccess(redemptionDoneList) {
	return {
		type: FETCH_REDEMPTION_DONE_LIST_SUCCESS,
		redemptionDoneList,
	};
}

function fetchRedemptionDoneListFailure(error) {
	return {
		type: FETCH_REDEMPTION_DONE_LIST_FAILURE,
		error,
	};
}

function setPage(page) {
	return {
		type: SET_REDEMPTION_DONE_LIST_PAGE,
		page,
	};
}

function setFormData(giftCode) {
	return {
		type: SET_FORM_DATA,
		giftCode,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case SET_FORM_DATA:
		return { ...state, formData: action.giftCode };

	case UPDATE_FORM_DATA: {
		const formData = { ...state.formData };
		const { changedData } = action;
		formData[changedData.name] = changedData.value;
		return { ...state, formData };
	}

	case FETCH_REDEMPTION_DONE_LIST:
		return { ...state, loading: true };
	case FETCH_REDEMPTION_DONE_LIST_SUCCESS:
		return {
			...state, loading: false, redemptionDoneList: [...action.redemptionDoneList?.rows], redemptionDoneCount: action.redemptionDoneList?.count,
		};
	case FETCH_REDEMPTION_DONE_LIST_FAILURE:
		return {
			...state, loading: false, error: true, redemptionDoneList: [],
		};
	case FILTER_REDEMPTION_DONE_LIST:
		return { ...state, loading: true };
	case FILTER_REDEMPTION_DONE_LIST_SUCCESS:
		return {
			...state, loading: false, redemptionDoneList: [...action.redemptionDoneList?.rows], redemptionDoneCount: action.redemptionDoneList?.count,
		};
	case FILTER_REDEMPTION_DONE_LIST_FAILURE:
		return {
			...state, loading: true, error: true, redemptionDoneList: [],
		};
	case SET_REDEMPTION_DONE_LIST_PAGE:
		return { ...state, page: action.page };
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };
	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			name: true,
			designation: true,
			giftCode: true,
			status: true,
		};
		return { ...state, blurredElements };
	}
	case SET_ELEMENTS_BLURRED: {
		return { ...state, blurredElements: action.blurredElements };
	}
	case UPDATE_ACTIONS_ERROR:
		return {
			...state, actionLoading: false, actionError: true,
		};
	case UPDATE_ACTIONS_SUCCESS:
		return {
			...state, actionLoading: false, actionError: false,
		};
	case UPDATE_ACTIONS_LOADER:
		return {
			...state, actionLoading: true, actionError: false,
		};
	case SET_ACTIONS_MESSAGE:
		return {
			...state, actionMessage: action.message,
		};
	default:
		return state;
	}
}

const RedemptionDoneManager = (props) => {
	const {
		history, pageTitle, match, location,
	} = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		redemptionDoneList, loading, redemptionDoneCount, page, blurredElements, formData,
	} = state;

	useEffect(() => {
		if (match?.params?.giftCodeId) { getRedemptionDoneList((page - 1) * 10, 10); }
		pageTitle('redemptionGiftCodeManager');
	}, []);

	useEffect(() => {
		handleErrors();
	}, [formData, blurredElements]);

	async function getRedemptionDoneList(offset, limit) {
		dispatch(fetchRedemptionDoneList());
		const url = `${REDEMPTION_DONE_LIST_BASE_URL}?offset=${offset}&limit=${limit}&giftCodeId=${match?.params?.giftCodeId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				const redemptionDoneList = await response.json();
				dispatch(fetchRedemptionDoneListSuccess(redemptionDoneList));
			} else {
				dispatch(fetchRedemptionDoneListFailure());
			}
		} catch (err) {
			dispatch(fetchRedemptionDoneListFailure(err));
		}
	}

	const handleActions = async ({
		id,
		method,
		selectStatus,
		callBack,
		callBackFromToolTipMessage,
	}) => {
		const options = {
			method,
			credentials: 'include',
			body: JSON.stringify({
				giftCodeId: id,
				status: selectStatus,
			}),
			headers: { 'Content-Type': 'application/json' },
		};
		const postParam = {
			DELETE: `${REDEMPTION_DONE_LIST_BASE_URL}/${id}`,
			PATCH: `${REDEMPTION_DONE_LIST_STATUS_BASE_URL}`,
		};
		if (callBackFromToolTipMessage) callBackFromToolTipMessage(1);
		try {
			const response = await fetch(`${postParam[method]}`, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
				if (callBack) callBack();
			} else if (response.status === 201 || response.status === 200) {
				if (callBackFromToolTipMessage) callBackFromToolTipMessage(2);
				setTimeout(() => {
					closeModal();
					getRedemptionDoneList((page - 1) * 10, 10);
					if (callBack) callBack();
				}, 2000);
			} else {
				if (callBackFromToolTipMessage) callBackFromToolTipMessage(3);
				setTimeout(() => {
					closeModal();
					if (callBack) callBack();
				}, 2000);
			}
		} catch (err) {
			setTimeout(() => {
				closeModal();
				if (callBackFromToolTipMessage) callBackFromToolTipMessage(3);
				if (callBack) callBack();
			}, 2000);
		}
	};

	// const saveGiftCode = async () => {
	// 	dispatch(updateActionsLoading());
	// 	dispatch(setActionsMessage(SAVING_MESSAGE));
	// 	const patchAPiUrl = `${REDEMPTION_DONE_LIST_BASE_URL}/${formData.id}`;
	// 	const url = `${formData.id ? patchAPiUrl : REDEMPTION_DONE_LIST_BASE_URL}`;
	// 	const reqData = {
	// 		name: formData.name,
	// 		designation: formData.designation,
	// 		giftCode: formData.giftCode,
	// 		status: formData.status,
	// 	};
	// 	const options = {
	// 		method: formData.id ? 'PATCH' : 'POST',
	// 		credentials: 'include',
	// 		body: JSON.stringify(reqData),
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 		},
	// 	};
	// 	try {
	// 		const response = await fetch(url, options);
	// 		if (response.status === 401 || response.status === 403) {
	// 			history.push('/unauthorized');
	// 		} else if (response && response.status === 200) {
	// 			dispatch(updateActionsSuccess());
	// 			dispatch(setActionsMessage(SAVING_SUCCESS_MESSAGE));
	// 			setTimeout(() => {
	// 				closeModal();
	// 				dispatch(setActionsMessage(null));
	// 				getredemptionDoneList((page - 1) * 10, 10, '', selectedStatus);
	// 			}, 2000);
	// 		} else if (response && response.status !== 200) {
	// 			dispatch(updateActionsFailure());
	// 			dispatch(setActionsMessage(SAVING_FAILURE_MESSAGE));
	// 			setTimeout(() => {
	// 				dispatch(updateActionsSuccess());
	// 				dispatch(setActionsMessage(null));
	// 				closeModal();
	// 			}, 2000);
	// 		}
	// 	} catch (err) {
	// 		dispatch(updateActionsFailure());
	// 		dispatch(setActionsMessage(SAVING_FAILURE_MESSAGE));
	// 		setTimeout(() => {
	// 			dispatch(updateActionsSuccess());
	// 			closeModal();
	// 			dispatch(setActionsMessage(null));
	// 		}, 2000);
	// 	}
	// };

	function onPageChange(page) {
		getRedemptionDoneList((page - 1) * 10, 10);
		dispatch(setPage(page));
	}

	function closeModal() {
		dispatch(setPage(1));
		dispatch(updateErrors({
			name: false,
			designation: false,
			giftCode: false,
			status: false,
		}));
		dispatch(setBlurredElements({
			name: false,
			designation: false,
			giftCode: false,
			status: false,
		}));
		dispatch(setAllElementsBlurred());
		dispatch(setFormData({}));
	}

	function validateForm() {
		return {
			// name: !(formData && formData.name && formData.name?.length > 0),
			// designation: !(formData && formData.designation && formData.designation?.length > 0),
			giftCode: !(formData && formData.giftCode && formData.giftCode?.length > 0),
			status: !(formData && formData.status && formData.status?.length > 0),
		};
	}
	const handleErrors = () => {
		const validation = validateForm();
		const errors = Object.keys(validation).reduce((acc, curr) => {
			if (validation[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	};
	// const handleSubmit = (e) => {
	// 	e.preventDefault();
	// 	dispatch(setAllElementsBlurred());
	// 	const validation = validateForm();
	// 	const validated = !Object.keys(validation).some((i) => validation[i]);
	// 	handleErrors();
	// 	if (validated) {
	// 		setDisabledBtn(true);
	// 		// saveGiftCode();
	// 	} else {
	// 		dispatch(setAllElementsBlurred());
	// 	}
	// };

	// function onInputChange(e) {
	// 	const changedData = {};
	// 	changedData.name = e.target.name;
	// 	changedData.value = e.target.value;
	// 	dispatch(updateFormData(changedData));
	// }

	// function handleBlur(element) {
	// 	dispatch(updateBlurredElement(element));
	// 	handleErrors();
	// }

	// function renderFormMessage() {
	// 	if (actionMessage) {
	// 		return (
	// 			<ToastMessage
	// 				toastData={actionMessage}
	// 				unmount={() => dispatch(setActionsMessage(null))}
	// 			/>
	// 		);
	// 	}
	// 	return null;
	// }
	// function onStatusChange(event) {
	// 	const value = event;
	// 	setSelectedStatus(value);
	// 	getRedemptionDoneList((page - 1) * 10, 10, searchValue, value);
	// 	dispatch(setPage(1));
	// }

	function getRedemptionDoneManager() {
		return redemptionDoneList.map((giftCode, index) => <RedemptionDoneList
			key={giftCode.id}
			id={giftCode.id}
			history={history}
			slNo={(page - 1) * 10 + index + 1}
			giftCodeData={giftCode}
			handleActions={handleActions}
			index={index}
		/>);
	}

	function renderList() {
		if (redemptionDoneList && redemptionDoneList.length > 0) {
			return <div>
				<RedemptionDoneListHeader />
				{getRedemptionDoneManager()}
			</div>;
		}

		return (
			<Row style={{
				width: '100%', minHeight: '400px', justifyContent: 'center', alignItems: 'center',
			}}
			>
				<div>
					<h1 style={{ color: '#bbb' }}>
						No Redemption List To Display
					</h1>
				</div>
			</Row>
		);
	}

	return (
		<WrapperContainer>
			<Row className="">
				<Col sm={4} xs={12} className="mb-2">
					<h2>Redemption List Codes {location.search?.split('=')[1] ? `Of ${location.search?.split('=')[1]}` : ''} </h2>
				</Col>
			</Row>
			{
				loading
					? <LoadingIcon />
					: <>
						{renderList()}
						{redemptionDoneList && redemptionDoneList.length > 0
							? <div>
								<Row className="mt-3 pr-4" style={{ justifyContent: 'flex-end' }}>
									<Pagination onChange={onPageChange} current={page} total={redemptionDoneCount} locale={locale} />
								</Row>
							</div>
							: null}
					</>
			}
			{/* {editGiftCodeOfTheJob()} */}
		</WrapperContainer>
	);
};

RedemptionDoneManager.propTypes = {
	pageTitle: T.func,
	match: T.object,
	history: T.object,
	location: T.object,
};
export default RedemptionDoneManager;
