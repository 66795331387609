import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import T from 'prop-types';
import InfoIcon from '../../images/info.png';

const Image = styled.img`
		height: 15px;
		width: 15px;
`;

const Tooltip = (props) => (
	<div>
		<ReactTooltip
			effect="solid"
			data-multiline
			place="top"
			className="tooltip-width"
		/>
		<Image src={InfoIcon} data-tip={props.hint} />
	</div>
);

Tooltip.propTypes = {
	hint: T.string,
};

export default Tooltip;
