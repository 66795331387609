export const ORG_CHART_EDITOR_API_BASE_URL = '/api/v2/rw/org-chart';
export const ORG_CHART_API_BASE_URL = '/api/v3/org-chart';
export const SUBORDINATE_ORG_CHART_API_BASE_URL = '/api/v3/people-profile';
export const PEOPLE_PROFILE_API_BASE_URL = '/api/v2/rw/people-profile';

export const UPDATE_MESSAGE = { intent: 'info', message: 'Updating org chart...' };
export const UPDATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Org chart updated Successfully' };
export const UPDATE_FAILURE_MESSAGE = { intent: 'danger', message: 'Org chart update Failed. Something went wrong. Try Again!' };

export const CREATE_MESSAGE = { intent: 'info', message: 'Creating org chart...' };
export const CREATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Org chart created Successfully' };
export const CREATE_FAILURE_MESSAGE = { intent: 'danger', message: 'Org chart creation Failed. Something went wrong. Try Again!' };

export const CREATE_PROFILE_MESSAGE = { intent: 'info', message: 'Creating people profile...' };
export const CREATE_PROFILE_SUCCESS_MESSAGE = { intent: 'success', message: 'People profile created Successfully' };
export const CREATE_PROFILE_FAILURE_MESSAGE = { intent: 'danger', message: 'People profile creation Failed. Something went wrong. Try Again!' };

export const UPDATE_PROFILE_MESSAGE = { intent: 'info', message: 'Updating people profile...' };
export const UPDATE_PROFILE_SUCCESS_MESSAGE = { intent: 'success', message: 'People profile updated Successfully' };
export const UPDATE_PROFILE_FAILURE_MESSAGE = { intent: 'danger', message: 'People profile creation Failed. Something went wrong. Try Again!' };
