import {
	SELECT_TAG, ADD_TAG, TAG_ELEMENT_ON_CHANGE, CREATE_TAG, DELETE_TAG, REPLACE_TAG, UPDATE_TAG, CREATE_TAG_SUCCESS, CREATE_TAG_ERROR, UPDATE_TAG_SUCCESS, UPDATE_TAG_ERROR, DELETE_TAG_SUCCESS, DELETE_TAG_ERROR, REPLACE_TAG_ERROR, REPLACE_TAG_SUCCESS, TAG_REDIRECTION_ERROR, WRITE_TAG_SLUG, UNMOUNT_TAG_FORM_MESSAGE, SET_TAG_SLUG_UNIQUE_VALUE, SHOW_TAG_LOADING_MESSAGE,
} from './type';

export function tagSelect(selectedTag) {
	return {
		type: SELECT_TAG,
		selectedTag,
	};
}

export function initiateAddTag() {
	return {
		type: ADD_TAG,
	};
}

export function onTagFormElementChange(elementData) {
	return {
		type: TAG_ELEMENT_ON_CHANGE,
		elementData,
	};
}

export function writeTagSlug() {
	return {
		type: WRITE_TAG_SLUG,
	};
}

export function createTag(tag) {
	return {
		type: CREATE_TAG,
		tag,
	};
}

export function createTagSuccess(successResponse) {
	return {
		type: CREATE_TAG_SUCCESS,
		successResponse,
	};
}

export function createTagFailure(failureResponse) {
	return {
		type: CREATE_TAG_ERROR,
		failureResponse,
	};
}

export function updateTag(id, updatedData) {
	return {
		type: UPDATE_TAG,
		id,
		updatedData,
	};
}

export function updateTagSuccess(successResponse) {
	return {
		type: UPDATE_TAG_SUCCESS,
		successResponse,
	};
}

export function updateTagFailure(failureResponse) {
	return {
		type: UPDATE_TAG_ERROR,
		failureResponse,
	};
}

export function deleteTag(id, locale) {
	return {
		type: DELETE_TAG,
		id,
		locale,
	};
}

export function replaceTag(key, existing, replacement, locale, redirection) {
	return {
		type: REPLACE_TAG,
		existing,
		replacement,
		locale,
		key,
		redirection,
	};
}

export function replaceTagSuccess(successResponse) {
	return {
		type: REPLACE_TAG_SUCCESS,
		successResponse,
	};
}

export function replaceTagFailure(failureResponse) {
	return {
		type: REPLACE_TAG_ERROR,
		failureResponse,
	};
}

export function tagRedirectionFailure(failureResponse) {
	return {
		type: TAG_REDIRECTION_ERROR,
		failureResponse,
	};
}

export function deleteTagSuccess(successResponse) {
	return {
		type: DELETE_TAG_SUCCESS,
		successResponse,
	};
}

export function deleteTagFailure(failureResponse) {
	return {
		type: DELETE_TAG_ERROR,
		failureResponse,
	};
}

export function unmountTagFormMessage() {
	return {
		type: UNMOUNT_TAG_FORM_MESSAGE,
	};
}

export function setTagSlugUniqueValue(value) {
	return {
		type: SET_TAG_SLUG_UNIQUE_VALUE,
		value,
	};
}

export function showLoading() {
	return {
		type: SHOW_TAG_LOADING_MESSAGE,
	};
}
