import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Col } from 'react-styled-flexboxgrid';

const BoniBrandManager = () => (
	<Col md={3} sm={6} xs={12}>
		<Link to="boni/brand-manager" className="d-block">
			<Card className="b-0 p-3">
				<div className="lg-text pb-2 mb-2 b-bottom separator">
					<span className="mr-2">
						<i name="desk-icon" className="fa fa-book brand-text" />
					</span>
					<span>Brand Manager</span>
				</div>
				<div className="mb-3 info">Add, update and unpublish Brands</div>
				<div className="tu sm-text brand-text mb-2"><b>manage</b></div>
			</Card>
		</Link>
	</Col>
);

const BrandCategoryManager = () => (
	<Col md={3} sm={6} xs={12}>
		<Link to="/manager/boni/brand-category" className="d-block">
			<Card className="b-0 p-3">
				<div className="lg-text pb-2 mb-2 b-bottom separator">
					<span className="mr-2">
						<i name="desk-icon" className="fa fa-book brand-text" />
					</span>
					<span>Brand Category Manager</span>
				</div>
				<div className="mb-3 info">Add, update and unpublish Brand Categories</div>
				<div className="tu sm-text brand-text mb-2"><b>manage</b></div>
			</Card>
		</Link>
	</Col>
);

const BrandProductManager = () => (
	<Col md={3} sm={6} xs={12}>
		<Link to="/manager/boni/product-manager" className="d-block">
			<Card className="b-0 p-3">
				<div className="lg-text pb-2 mb-2 b-bottom separator">
					<span className="mr-2">
						<i name="desk-icon" className="fa fa-book brand-text" />
					</span>
					<span>Brand Product Manager</span>
				</div>
				<div className="mb-3 info">Add, update and unpublish Products</div>
				<div className="tu sm-text brand-text mb-2"><b>manage</b></div>
			</Card>
		</Link>
	</Col>
);

const BrandOfTheDayManager = () => (
	<Col md={3} sm={6} xs={12}>
		<Link to="/boni-brand/brand-of-the-day/manager" className="d-block">
			<Card className="b-0 p-3">
				<div className="lg-text pb-2 mb-2 b-bottom separator">
					<span className="mr-2">
						<i name="desk-icon" className="fa fa-book brand-text" />
					</span>
					<span>Brand Of The Day Manager</span>
				</div>
				<div className="mb-3 info">Update Brand of the day</div>
				<div className="tu sm-text brand-text mb-2"><b>manage</b></div>
			</Card>
		</Link>
	</Col>
);

const SpotlightOfTheMonthManager = () => (
	<Col md={3} sm={6} xs={12}>
		<Link to="boni/spotlight-of-month" className="d-block">
			<Card className="b-0 p-3">
				<div className="lg-text pb-2 mb-2 b-bottom separator">
					<span className="mr-2">
						<i name="desk-icon" className="fa fa-book brand-text" />
					</span>
					<span>Spotlight Of The Month Manager</span>
				</div>
				<div className="mb-3 info">Update Spotlight of the month</div>
				<div className="tu sm-text brand-text mb-2"><b>manage</b></div>
			</Card>
		</Link>
	</Col>
);

const BrandWebStoriesManager = () => (
	<Col md={3} sm={6} xs={12}>
		<Link to="/manager/boni/webstory-manager" className="d-block">
			<Card className="b-0 p-3">
				<div className="lg-text pb-2 mb-2 b-bottom separator">
					<span className="mr-2">
						<i name="desk-icon" className="fa fa-book brand-text" />
					</span>
					<span>Web Stories Manager</span>
				</div>
				<div className="mb-3 info">Add, Update and Unpublish Web Stories</div>
				<div className="tu sm-text brand-text mb-2"><b>manage</b></div>
			</Card>
		</Link>
	</Col>
);

const BrandProductCategoriesManager = () => (
	<Col md={3} sm={6} xs={12}>
		<Link to="/manager/boni/product-category" className="d-block">
			<Card className="b-0 p-3">
				<div className="lg-text pb-2 mb-2 b-bottom separator">
					<span className="mr-2">
						<i name="desk-icon" className="fa fa-book brand-text" />
					</span>
					<span>Product Categories Manager</span>
				</div>
				<div className="mb-3 info">Add, Update and Delete Product Categories</div>
				<div className="tu sm-text brand-text mb-2"><b>manage</b></div>
			</Card>
		</Link>
	</Col>
);

const BrandExternalLinksManager = () => (
	<Col md={3} sm={6} xs={12}>
		<Link to="/manager/boni/external-link" className="d-block">
			<Card className="b-0 p-3">
				<div className="lg-text pb-2 mb-2 b-bottom separator">
					<span className="mr-2">
						<i name="desk-icon" className="fa fa-book brand-text" />
					</span>
					<span>External Links Manager</span>
				</div>
				<div className="mb-3 info">Add, Update and Delete External Links</div>
				<div className="tu sm-text brand-text mb-2"><b>manage</b></div>
			</Card>
		</Link>
	</Col>
);

const Card = styled.div`
		border:1px solid #ddd;
		box-shadow : 0 0.1rem 0.3rem rgba(0, 0, 0, 0.15);
		border-radius: 0.25rem;
		padding:0.5rem;
		margin-bottom:1rem;
		word-wrap: break-word;
		min-height: 225px;
		> .description {
				font: 400 0.9rem "PT Sans", sans-serif;
				color:#666;
		}
		> .info {
				font-weight:400;
				font-family: "PT Sans", sans-serif;
		}
		.separator { border-color:#f9f9f9 !important; }
		> .brand-name {
				color:#ccc;
				font-size:1.2rem;
		}
	`;
export {
	BoniBrandManager,
	BrandCategoryManager,
	BrandProductManager,
	BrandOfTheDayManager,
	SpotlightOfTheMonthManager,
	BrandWebStoriesManager,
	BrandProductCategoriesManager,
	BrandExternalLinksManager,
};
