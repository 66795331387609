export const FETCH_CP_ITEMS = 'FETCH_CP_ITEMS';
export const FETCH_CP_ITEMS_SUCCESS = 'FETCH_CP_ITEMS_SUCCESS';
export const FETCH_CP_ITEMS_FAILURE = 'FETCH_CP_ITEMS_FAILURE';

export const UPDATE_CP_ITEM_STATUS = 'UPDATE_CP_ITEM_STATUS';
export const UPDATE_CP_ITEM_STATUS_SUCCESS = 'UPDATE_CP_ITEM_STATUS_SUCCESS';
export const UPDATE_CP_ITEM_STATUS_FAILURE = 'UPDATE_CP_ITEM_STATUS_FAILURE';

export const FETCH_NEXT_PAGE = 'FETCH_NEXT_PAGE';

export const RUN_CP_LIST_SEARCH = 'RUN_CP_LIST_SEARCH';
export const RUN_CP_LIST_SEARCH_SUCCESS = 'RUN_CP_LIST_SEARCH_SUCCESS';
export const RUN_CP_LIST_SEARCH_FAILURE = 'RUN_CP_LIST_SEARCH_FAILURE';

export const RUN_CP_LIST_SORT = 'RUN_CP_LIST_SORT';

export const DISABLE_PROFILE_LIST_SEARCH = 'DISABLE_PROFILE_LIST_SEARCH';
export const DISABLE_PROFILE_LIST_SEARCH_FLAG = 'DISABLE_PROFILE_LIST_SEARCH_FLAG';

export const CLEAR_PROFILE_DESK_DATA = 'CLEAR_PROFILE_DESK_DATA';
