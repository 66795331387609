import React, {
	useEffect, useState, useReducer, Fragment,
} from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import 'rc-pagination/assets/index.css';
import T from 'prop-types';
import {
	Input, Button,
} from '../../../components/Styles';
// import ToastMessage from '../../../components/ToastMessage';
import { AsyncMultiSelect, AsyncSingleSelectWithDefault, TitleWithTooltip } from '../../../components/FormComponents';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import LoadingIcon from '../../../components/LoadingIcon/LoadingIcon';
import {
	GET_PROFILE_DATA,
	FETCH_PROFILE_DATA,
	FETCH_PROFILE_DATA_SUCCESS,
	FETCH_PROFILE_DATA_FAILURE,
	UPDATE_TALENT_BASE_URL,
	SET_ALL_ELEMENTS_BLURRED,
	UPDATE_ERRORS,
	UPDATE_BLURRED_ELEMENT,
	UPDATE_ACTIONS_ERROR,
	UPDATE_ACTIONS_SUCCESS,
	UPDATE_ACTIONS_LOADER,
	SET_ACTIONS_MESSAGE,
} from '../constants';
import {
	returnAsyncFilterValue, returnOnlyValueIncludedArray,
	removeDuplicatesFromAsyncMultiSelect, filterProfileData,
	jobProfileContactInfoValidation,
	getDropdownOptions,
	filterAsyncSelectOptionsTrue,
	filterPrefCitiesOptions,
	filterAsyncSelectOptions,
} from '../../../utils/common';

const initialState = {
	profileJobData: {},
	loading: false,
	error: false,
	actionLoading: false,
	actionError: false,
	actionMessage: '',
	blurredElements: {
		expectedAnnualSalary: false,
		employmentType: false,
		prefJobFunction: false,
		prefCity: false,
		prefJobExpertise: false,
	},
	errors: {
		expectedAnnualSalary: false,
		employmentType: false,
		prefJobFunction: false,
		prefCity: false,
		prefJobExpertise: false,
	},
};

function setActionsMessage(message) {
	return {
		type: SET_ACTIONS_MESSAGE,
		message,
	};
}
function updateActionsLoading() {
	return {
		type: UPDATE_ACTIONS_LOADER,
	};
}

function updateActionsFailure() {
	return {
		type: UPDATE_ACTIONS_ERROR,
	};
}

function updateActionsSuccess() {
	return {
		type: UPDATE_ACTIONS_SUCCESS,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function fetchProfileJobData() {
	return {
		type: FETCH_PROFILE_DATA,
	};
}
function fetchProfileJobDataSuccess(profileJobData) {
	return {
		type: FETCH_PROFILE_DATA_SUCCESS,
		profileJobData,
	};
}
function fetchProfileJobDataFailure() {
	return {
		type: FETCH_PROFILE_DATA_FAILURE,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case FETCH_PROFILE_DATA:
		return { ...state, loading: true };
	case FETCH_PROFILE_DATA_SUCCESS:
		return {
			...state, loading: false, profileJobData: { ...action.profileJobData },
		};
	case FETCH_PROFILE_DATA_FAILURE:
		return {
			...state, loading: false, error: true, profileJobData: {},
		};
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };
	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			expectedAnnualSalary: true,
			employmentType: true,
			prefJobFunction: true,
			prefCity: true,
			prefJobExpertise: true,
		};
		return { ...state, blurredElements };
	}
	case UPDATE_ACTIONS_ERROR:
		return {
			...state, actionLoading: false, actionError: true,
		};
	case UPDATE_ACTIONS_SUCCESS:
		return {
			...state, actionLoading: false, actionError: false,
		};
	case UPDATE_ACTIONS_LOADER:
		return {
			...state, actionLoading: true, actionError: false,
		};
	case SET_ACTIONS_MESSAGE:
		return {
			...state, actionMessage: action.message,
		};
	default:
		return state;
	}
}

const NewJobPreferencesPage = (props) => {
	const { match, history } = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		loading, errors, blurredElements, actionLoading, actionError, actionMessage,
	} = state;
	const employmentTypeList = [
		{ value: 'Full Time', label: 'Full Time' },
		{ value: 'Part Time', label: 'Part Time' },
	];
	const [validAnnualSalaryRange, setValidAnnualSalaryRange] = useState(false);
	const [profileData, setProfileData] = useState({
		expectedAnnualSalary: {
			min: '', max: '',
		},
		employmentType: {},
		prefJobFunction: '',
		prefCity: [],
		prefJobExpertise: [],
		prefJobExpOptionsList: [],
	});

	useEffect(() => {
		dispatch(updateErrors({
			expectedAnnualSalary: false,
			employmentType: false,
			prefJobFunction: false,
			prefCity: false,
			prefJobExpertise: false,
		}));
		dispatch(updateBlurredElement({
			expectedAnnualSalary: false,
			employmentType: false,
			prefJobFunction: false,
			prefCity: false,
			prefJobExpertise: false,
		}));
		dispatch(setAllElementsBlurred());
		if (match?.params?.profileId) {
			getProfileData(match.params.profileId);
		}
	}, []);

	async function getProfileData(profileId) {
		dispatch(fetchProfileJobData());
		const url = `${GET_PROFILE_DATA}${profileId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				const profileDetails = await response.json();
				if (profileDetails && profileDetails?.id?.length > 0) {
					setProfileData({
						...filterProfileData(profileDetails),
						expectedAnnualSalary: profileDetails.expectedAnnualSalary,
						prefJobFunction: {
							value: profileDetails.prefJobFunction,
							label: profileDetails.prefJobFunction,
						},
						employmentType: {
							value: profileDetails.employmentType,
							label: profileDetails.employmentType,
						},
						prefCity: returnAsyncFilterValue(profileDetails.prefCity),
						prefJobExpertise: returnAsyncFilterValue(profileDetails.prefJobExpertise),
					});
				}
				dispatch(fetchProfileJobDataSuccess(profileDetails));
			} else {
				dispatch(fetchProfileJobDataFailure());
			}
		} catch (err) {
			dispatch(fetchProfileJobDataFailure());
		}
	}
	const saveProfileDataForUpdate = async () => {
		dispatch(setActionsMessage(''));
		dispatch(updateActionsLoading());
		const url = UPDATE_TALENT_BASE_URL;
		const formData = {
			...profileData,
			profileId: match.params.profileId,
			expectedAnnualSalary: profileData.expectedAnnualSalary,
			prefJobFunction: profileData.prefJobFunction.label,
			prefCity: returnOnlyValueIncludedArray(profileData.prefCity),
			prefJobExpertise: returnOnlyValueIncludedArray(profileData.prefJobExpertise),
			employmentType: profileData.employmentType.label,
			switchFunctionIndustry: 'Yes',

		};
		const options = {
			method: 'PATCH',
			credentials: 'include',
			body: JSON.stringify(formData),
			headers: {
				'Content-Type': 'application/json',
			},
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				dispatch(updateActionsSuccess());
				dispatch(setActionsMessage('New Job Preferences Details are updated successfully'));
				setTimeout(() => {
					dispatch(setActionsMessage(''));
				}, 2000);
			} else {
				dispatch(updateActionsFailure());
				dispatch(setActionsMessage('Not Able To Update The Job profile'));
				setTimeout(() => {
					dispatch(updateActionsSuccess());
				}, 2000);
			}
		} catch (err) {
			dispatch(updateActionsFailure());
			dispatch(setActionsMessage('Not Able To Update The Job profile'));
			setTimeout(() => {
				dispatch(setActionsMessage(''));
				dispatch(updateActionsSuccess());
			}, 2000);
		}
	};
	const checkIfSalaryRangeIsValid = () => {
		if (profileData.expectedAnnualSalary && profileData.expectedAnnualSalary?.min?.toString()?.length > 0 && profileData.expectedAnnualSalary?.max?.toString()?.length > 0) {
			const salaryRange = parseInt(profileData.expectedAnnualSalary?.max) - parseInt(profileData.expectedAnnualSalary?.min);
			if (Math.sign(salaryRange) === -1) {
				setValidAnnualSalaryRange(true);
				return false;
			}
			setValidAnnualSalaryRange(false);
			return true;
		}
	};
	function validateForm() {
		checkIfSalaryRangeIsValid();
		return {
			expectedAnnualSalary: !(profileData.expectedAnnualSalary && profileData.expectedAnnualSalary?.min?.toString()?.length > 0 && profileData.expectedAnnualSalary?.max?.toString()?.length > 0),
			prefJobFunction: !(profileData.prefJobFunction && profileData.prefJobFunction?.value?.length > 0),
			prefJobExpertise: !(profileData.prefJobExpertise && profileData.prefJobExpertise?.length > 0),
			prefCity: !(profileData.prefCity && profileData.prefCity?.length > 0),
			employmentType: !(profileData.employmentType && profileData.employmentType?.value?.length > 0),
		};
	}
	const handleErrors = () => {
		const validation = validateForm();
		const errors = Object.keys(validation).reduce((acc, curr) => {
			if (validation[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		const validation = validateForm();
		const validated = !Object.keys(validation).some((i) => validation[i]);
		const validSalaryRange = checkIfSalaryRangeIsValid();
		handleErrors();
		if (validated && validSalaryRange) {
			saveProfileDataForUpdate();
		} else {
			dispatch(setAllElementsBlurred());
		}
	};
	const validateSalaryValue = (event) => {
		if (event.target.value.length === 1 && event.which === 48) {
			return event.preventDefault();
		}
		const validSalary = jobProfileContactInfoValidation(event.target.value, 5);
		/* eslint-disable no-useless-escape */
		return validSalary ? event.target.value.toString().replace(/,/g, '') : '';
	};

	const getJobExpertiseOptionList = async (caseVal, value) => {
		let result = [];
		result = await getDropdownOptions('secondary-job-function', 'secondary-job-function', value.label.toLowerCase());
		if (result && result?.length > 0) {
			if (caseVal === 1) {
				setProfileData({
					...profileData,
					prefJobFunction: value,
					prefJobExpertise: [],
					prefJobExpOptionsList: result,
				});
			}
		}
	};
	return (
		<div>
			{
				loading
					? <LoadingIcon />
					: <>
						<div>
							<h3 className="px-3">
								Edit New Job Preferences
							</h3>
							<form onSubmit={handleSubmit}>
								<Row className="m-2">
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Expected Annual Salary (min) *"
												hint="Expected Annual Salary (min)*"
											/>
											{/* eslint-disable no-useless-escape */}
											<Input
												type="text"
												className="form-control"
												value={profileData.expectedAnnualSalary.min.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}
												name="expectedAnnualSalaryMin"
												autoComplete="off"
												placeholder="eg: 3,60,000"
												onChange={(e) => {
													setProfileData(({
														...profileData,
														expectedAnnualSalary: {
															...profileData.expectedAnnualSalary,
															min: validateSalaryValue(e),
														},
													}));
													handleErrors();
												}}
											/>
											<ErrorMessage display={errors.expectedAnnualSalary && !(profileData.expectedAnnualSalary.min.length > 0)} element="Minimum Expected AnnualSalary" />
										</div>
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Expected Annual Salary (max) *"
												hint="Expected Annual Salary (max)*"
											/>
											{/* eslint-disable no-useless-escape */}
											<Input
												type="text"
												className="form-control"
												value={profileData.expectedAnnualSalary.max.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}
												name="expectedAnnualSalaryMax"
												autoComplete="off"
												placeholder="eg: 3,60,000"
												onChange={(e) => {
													setProfileData(({
														...profileData,
														expectedAnnualSalary: {
															...profileData.expectedAnnualSalary,
															max: validateSalaryValue(e),
														},
													}));
													handleErrors();
												}}
											/>
											<ErrorMessage display={errors.expectedAnnualSalary && !(profileData.expectedAnnualSalary.max.length > 0)} element="Maximum Expected AnnualSalary" />
										</div>
									</Col>
									<Col xs={12} className="text-center">
										{validAnnualSalaryRange && (
											<p className="error error-text text-normal text-center mb-2">
												Please Enter Valid Annual Salary Range!!!
											</p>
										)}
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="New job preference *"
												hint="New job preference"
											/>
											<AsyncSingleSelectWithDefault
												name="newJobPreference"
												placeholder="Select From List"
												defaultOptionsList
												options={(value) => filterAsyncSelectOptionsTrue(value, 'primary-job-function', '', null)}
												value={profileData.prefJobFunction}
												handleChange={(value) => {
													getJobExpertiseOptionList(1, value);
												}}
											/>
											<ErrorMessage display={errors.prefJobFunction} element="New Preference Job Function" />
										</div>
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="New Job Sub-Function Preference *"
												hint="New Job Sub-Function Preference"
											/>
											<AsyncMultiSelect
												placeholder="Select From List"
												defaultOptionsList={(profileData.prefJobExpOptionsList && profileData.prefJobExpOptionsList?.length > 0) ? profileData.prefJobExpOptionsList
													: true}
												options={(value) => filterAsyncSelectOptionsTrue(value, 'primary-job-function', '', profileData.prefJobFunction.label)}
												currentValues={profileData.prefJobExpertise}
												handleChange={(value) => {
													setProfileData({
														...profileData,
														prefJobExpertise: [...removeDuplicatesFromAsyncMultiSelect(value)],
													});
												}}
												profile="prefJobExpertise"
											/>
											<ErrorMessage display={errors.prefJobExpertise} element="New Job Sub-Function Preference" />
										</div>
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Full/Part Time *"
												hint="New job preference"
											/>
											<AsyncSingleSelectWithDefault
												name="fullPartTime"
												placeholder="Select From List"
												defaultOptionsList={employmentTypeList}
												options={(value) => filterAsyncSelectOptions(employmentTypeList, value)}
												value={profileData.employmentType}
												handleChange={(value) => {
													setProfileData({
														...profileData,
														employmentType: value,
													});
													handleErrors();
												}}
											/>
											<ErrorMessage display={errors.employmentType} element="Full/Part Time" />
										</div>
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Preferred City To Work From*"
												hint="Preferred City To Work From"
											/>
											<AsyncMultiSelect
												placeholder="Select From List"
												defaultOptionsList
												options={(value) => filterPrefCitiesOptions(value, 'pref-cities-from-india', '', null)}
												currentValues={profileData.prefCity}
												handleChange={(value) => {
													setProfileData({
														...profileData,
														prefCity: removeDuplicatesFromAsyncMultiSelect(value),
													});
													handleErrors();
												}}
												profile="prefCity"
											/>
											<ErrorMessage display={errors.prefCity} element="Preferred City To Work From" />
										</div>
									</Col>
									<Col xs={12} sm={12} className="mt-2 text-center">
										<Button
											success
											no_radius
											type="submit"
											style={{ width: '150px' }}
										>
											<b>Save Changes</b>
										</Button>
									</Col>
									<Col xs={12} sm={12}>
										{actionLoading && <LoadingIcon />}
									</Col>
									<Col xs={12} sm={12}>
										{(actionError && actionMessage) && <p className="error error-text text-normal text-center mb-2">
											{actionMessage}
										</p>}
										{(!actionError && actionMessage) && <p className="success-text text-normal text-center mb-2">
											{actionMessage}
										</p>}
									</Col>
								</Row>
							</form>
						</div>
					</>
			}
		</div>
	);
};

NewJobPreferencesPage.propTypes = {
	// status: T.number,
	history: T.object,
	match: T.object,
};
export default NewJobPreferencesPage;
