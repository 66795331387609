/* eslint-disable no-unused-vars */
import React, { useReducer, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import uuid from 'react-uuid';
import DatePicker from 'react-datepicker';

import { Row, Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import { DropDown, Input, Button } from '../Styles';
import {
	CURATED_SET_CONFIG_URL, SAVING_FAILURE_MESSAGE, SAVING_MESSAGE, SAVING_SUCCESS_MESSAGE,
} from '../../containers/TechSparksManager/constants';
import ToastMessage from '../ToastMessage';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { extractedCityName } from '../../utils/common';

const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
const UPDATE_ERRORS = 'UPDATE_ERRORS';
const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
const SET_FORM_DATA = 'SET_FORM_DATA';

const Card = styled.div`
	border: 1px solid #ddd;
	height: 200px;
	width: 200px;
	padding: 5px;
	margin: 0.25rem;
	background-color: #fff;
	cursor: pointer;
	font-size: 0.8rem;
	word-wrap: break-word;
	cursor: pointer;
	display: block;
	position: relative;
	overflow: auto;
`;

const initialState = {
	formData: {
		id: null,
		title: null,
		description: null,
		order: 1,
		tag: null,
		time: '',
		speakerName: '',
		speakerDesignation: '',
	},
	blurredElements: {
		title: false,
		description: false,
		order: false,
		tag: false,
	},
	errors: {
		title: false,
		description: false,
		order: false,
		tag: false,
	},
	formMessage: null,
};

function updateFormData(changedData) {
	return {
		type: UPDATE_FORM_DATA,
		changedData,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function setFormData(agenda) {
	return {
		type: SET_FORM_DATA,
		agenda,
	};
}

function setFormMessage(message) {
	return {
		type: SET_FORM_MESSAGE,
		message,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case SET_FORM_DATA:
		return { ...state, formData: action.agenda };

	case UPDATE_FORM_DATA: {
		const formData = { ...state.formData };
		const { changedData } = action;
		formData[changedData.name] = changedData.value;
		return { ...state, formData };
	}

	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };

	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			title: true,
			description: true,
			order: true,
			tag: true,
		};
		return { ...state, blurredElements };
	}

	case SET_FORM_MESSAGE:
		return { ...state, formMessage: action.message };

	default:
		return state;
	}
}

const CardWrapper = styled(Row)`
	margin-bottom: 2rem;
	border: 1px solid #bbb;
	background-color: #eee;
	padding: 0.5rem;
	width: 100%;
	position: relative;
	overflow: auto;
	height: 40vh;
	${(props) => props.source === 'fullPage' && css`
			height: 75vh;
	`}
`;
const DatePickerCol = styled(Col)`
		.react-datepicker-wrapper, .react-datepicker__input-container { display: block; }
		.react-datepicker-wrapper .react-datepicker__input-container input { width: 100% }
`;

const AgendaForm = (props) => {
	const {
		track, getTracks, unmountModal, city, agendasLength,
	} = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		formData, blurredElements, errors, formMessage,
	} = state;
	const [isButtonDisabled, disableButton] = useState(false);
	const [toUpdate, setToUpdate] = useState(false);
	const [agendas, setAgendas] = useState(false);
	const extractCityName = extractedCityName(city);

	useEffect(() => {
		if (track && track.agendas?.length > 0 && track?.agendas !== 'undefined') {
			setAgendas(track.agendas);
		}
		const changedData = {
			name: 'order',
			value: agendasLength,
		};
		dispatch(updateFormData(changedData));
	}, []);

	useEffect(() => {
		getErrors();
	}, [formData, blurredElements]);

	function onInputChange(e) {
		const changedData = {
			name: e.target.name,
			value: e.target.value,
		};
		dispatch(updateFormData(changedData));
	}

	function validateForm(agenda) {
		return {
			title: !agenda.title || agenda.title.length < 1,
			description: !agenda.description || agenda.description.length < 1,
			tag: !agenda.tag || agenda.tag.length < 1,
			order: !agenda.order || agenda.order.length < 1,
		};
	}

	function getErrors() {
		const validationErrors = validateForm(formData);
		const errors = Object.keys(validationErrors).reduce((acc, curr) => {
			if (validationErrors[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	}

	function onFormSubmit(e) {
		e.preventDefault();
		const validation = validateForm(formData);
		const validated = !Object.keys(validation).some((i) => validation[i]);
		if (validated) {
			saveAgenda();
		} else {
			dispatch(setAllElementsBlurred());
		}
	}

	function handleBlur(element) {
		dispatch(updateBlurredElement(element));
	}

	function getAgendaBody(agenda) {
		const formData = new FormData();
		if (toUpdate) {
			formData.append('id', agenda.id);
		} else {
			const id = uuid();
			formData.append('id', id);
		}
		formData.append('title', agenda.title);
		formData.append('description', agenda.description);
		formData.append('day', agenda.day);
		formData.append('order', agenda.order);
		formData.append('time', agenda.time);
		formData.append('tag', agenda.tag);
		formData.append('speakerName', agenda.speakerName);
		formData.append('speakerDesignation', agenda.speakerDesignation);
		return formData;
	}

	async function saveAgenda() {
		try {
			disableButton(true);
			dispatch(setFormMessage(SAVING_MESSAGE));
			const payload = getAgendaBody(formData);
			const options = {
				method: 'PUT',
				credentials: 'include',
				body: payload,
			};
			const url = `${CURATED_SET_CONFIG_URL}${city ? city === 'mumbai' ? `${city}_`
				: city && city.includes('techsparks_2023') ? `2023_${extractCityName}` : '' : ''}tracks/${toUpdate ? 'update' : 'create'}?subTask=agendas&trackId=${track.id}`;
			const response = await fetch(url, options);
			disableButton(false);
			if (response.status === 200) {
				dispatch(setFormMessage(SAVING_SUCCESS_MESSAGE));
				getTracks();
				unmountModal();
			} else {
				dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			}
		} catch (err) {
			dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			disableButton(false);
		}
	}

	async function handleDelete(agenda) {
		const url = `${CURATED_SET_CONFIG_URL}${city ? city === 'mumbai' ? `${city}_`
			: city && city.includes('techsparks_2023') ? `2023_${extractCityName}` : '' : ''}tracks/delete?id=${agenda.id}&subTask=agendas&trackId=${track.id}`;
		const options = {
			method: 'PUT',
			credentials: 'include',
		};
		const response = await fetch(url, options);
		if (response && response.status === 200) {
			const newAgendas = agendas.filter((currentAgenda) => currentAgenda.id !== agenda.id);
			setAgendas(newAgendas);
			getTracks();
		}
	}

	function renderFormMessage() {
		if (formMessage) {
			return (
				<ToastMessage
					toastData={formMessage}
					unmount={() => dispatch(setFormMessage(null))}
				/>
			);
		}
		return null;
	}

	return (
		<form onSubmit={onFormSubmit}>
			<CardWrapper>
				{agendas && agendas.length > 0 && agendas.map((agenda) => (
					<Card key={uuid()}>
						<div className="text-right">
							<i className="fa fa-pencil-square fa-lg" style={{ cursor: 'pointer' }} onClick={() => { setToUpdate(true); dispatch(setFormData(agenda)); }} />
							<i className="fa fa-trash fa-lg ml-3" style={{ cursor: 'pointer' }} onClick={() => handleDelete(agenda)} />
						</div>
						<h2>{agenda.title}</h2>
						<p>{agenda.description}</p>
						<p>Order: {agenda.order}</p>
					</Card>
				))}
			</CardWrapper>
			<Row>
				<Col sm={6}>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Title *
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="title"
								type="text"
								className="form-control"
								placeholder="Title of the agenda"
								onChange={onInputChange}
								value={formData.title ?? null}
								onBlur={() => handleBlur('title')}
							/>
							<ErrorMessage display={errors.title} element="Title" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Description *
						</Col>
						<Col sm={8} xs={12}>
							<textarea
								rows="4"
								className="global-form-control"
								name="description"
								placeholder="Description of the agenda"
								value={formData.description ?? null}
								onChange={onInputChange}
								onBlur={() => handleBlur('description')}
							/>
							<ErrorMessage display={errors.description} element="Description" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Order *
						</Col>
						<Col sm={8} xs={12}>
							{/* <DropDown
								className="mt-0"
								name="order"
								value={formData && formData.order ? formData.order : 1}
								onChange={onInputChange}
								onBlur={() => handleBlur('order')}
							>
								<option value={1}>1</option>
								<option value={2}>2</option>
								<option value={3}>3</option>
								<option value={4}>4</option>
								<option value={5}>5</option>
								<option value={6}>6</option>
								<option value={7}>7</option>
								<option value={8}>8</option>
								<option value={9}>9</option>
								<option value={10}>10</option>
							</DropDown> */}
							<Input
								name="order"
								type="number"
								className="form-control"
								placeholder="Order of an agenda"
								onChange={onInputChange}
								value={formData?.order}
								onBlur={() => handleBlur('order')}
							/>
							<p className="error mt-2 ml-1">NOTE: Please enter order which is not being used.</p>
							<ErrorMessage display={errors.order} element="Order" />
						</Col>
					</Row>
				</Col>
				<Col sm={6}>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Tag *
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="tag"
								type="text"
								className="form-control"
								placeholder="Tag of the agenda"
								onChange={onInputChange}
								value={formData.tag ?? null}
								onBlur={() => handleBlur('tag')}
							/>
							<ErrorMessage display={errors.tag} element="Tag" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Time
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="time"
								type="time"
								className="form-control"
								placeholder="Time"
								onChange={onInputChange}
								value={formData.time ?? null}
								style={{ cursor: 'pointer' }}
							/>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Speaker Name
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="speakerName"
								type="text"
								className="form-control"
								placeholder="Name of the speaker"
								onChange={onInputChange}
								value={formData.speakerName ?? null}
							/>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Speaker Designation
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="speakerDesignation"
								type="text"
								className="form-control"
								placeholder="Designation of the speaker"
								onChange={onInputChange}
								value={formData.speakerDesignation ?? null}
							/>
						</Col>
					</Row>
					<div className="text-right">
						<Button
							success
							type="submit"
							disabled={isButtonDisabled}
						>
							SAVE
						</Button>
					</div>
				</Col>
				{renderFormMessage()}
			</Row>
		</form>
	);
};

AgendaForm.propTypes = {
	track: T.object,
	getTracks: T.func,
	unmountModal: T.func,
	city: T.string,
	agendasLength: T.number,
};

export default AgendaForm;
