import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { USER_BASE_API_URL } from '../UserManager/constants';
import { LOAD_USER, INVITE_USER } from './type';
import {
	userLoadedSuccessfully,
	userLoadingError,
	inviteUserSuccessfully,
	inviteUserError,
} from './actions';

export function* loadUser() {
	const url = `${USER_BASE_API_URL}/user/info`;
	const options = {
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.status) {
				yield put(userLoadedSuccessfully(responseMessage.user));
			} else {
				yield put(userLoadingError(responseMessage));
			}
		}
	} catch (error) {
		yield put(userLoadingError(error));
	}
}

export function* inviteUser(action) {
	const url = `${USER_BASE_API_URL}/user/invite`;
	const { formData } = action;
	const options = {
		method: 'POST',
		credentials: 'include',
		body: formData,
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.status === 'success') {
				yield put(inviteUserSuccessfully(responseMessage.message));
			} else {
				yield put(inviteUserError(responseMessage.error));
			}
		}
	} catch (err) {
		yield put(inviteUserError(err));
	}
}

export default function* inviteUserSaga() {
	yield takeLatest(LOAD_USER, loadUser);
	yield takeLatest(INVITE_USER, inviteUser);
}
