import { Map } from 'immutable';
import {
	FETCH_JOBS,
	FETCH_JOBS_SUCCESS,
	FETCH_JOBS_FAILURE,
	FILTER_JOBS,
	FILTER_JOBS_SUCCESS,
	FILTER_JOBS_FAILURE,
	SET_JOBS_PAGE,
	CLEAR_JOBS_DATA,
	SET_STATUS,
} from './type';

const initialState = Map({
	jobs: [],
	jobsCount: null,
	loading: false,
	error: false,
	page: 1,
	status: 'ALL',
});

function jobsListReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_JOBS:
		return state
			.set('loading', true)
			.set('error', false);
	case FETCH_JOBS_SUCCESS:
		return state
			.set('loading', false)
			.set('jobs', action.jobs[0])
			.set('jobsCount', action.jobs[1]);
	case FETCH_JOBS_FAILURE:
		return state
			.set('loading', false)
			.set('jobs', [])
			.set('error', true);
	case FILTER_JOBS:
		return state
			.set('loading', true)
			.set('error', false);
	case FILTER_JOBS_SUCCESS:
		return state
			.set('loading', false)
			.set('jobs', action.jobs[0])
			.set('jobsCount', action.jobs[1]);
	case FILTER_JOBS_FAILURE:
		return state
			.set('loading', false)
			.set('jobs', [])
			.set('error', true);
	case SET_JOBS_PAGE:
		return state
			.set('page', action.page);
	case SET_STATUS:
		return state
			.set('status', action.status);
	case CLEAR_JOBS_DATA:
		return initialState;
	default:
		return state;
	}
}

export default jobsListReducer;
