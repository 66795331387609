import React from 'react';
import loadable from '@loadable/component';
import { WrapperContainer } from '../../Styles';
import LoadingIcon from '../../LoadingIcon/LoadingIcon';

export default loadable(() => import(/* webpackChunkName: "spotlight-manager" */ './index'), {
	fallback: (() => <WrapperContainer>
		<LoadingIcon />
	</WrapperContainer>)(),
});
