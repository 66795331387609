import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { FETCH_MARKETING_DATA } from './type';
import { MARKETING_DATA_BASE_API_URL } from './constants';
import { fetchMarketingDataSuccess, fetchMarketingDataFailure } from './actions';

export function* getMarketingData() {
	const url = `${MARKETING_DATA_BASE_API_URL}/marketing`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const data = yield response.json();
			yield put(fetchMarketingDataSuccess(data));
		}
	} catch (err) {
		yield put(fetchMarketingDataFailure(err));
	}
}

export default function* marketingData() {
	yield takeLatest(FETCH_MARKETING_DATA, getMarketingData);
}
