import React, {
	useEffect, useState, useReducer,
} from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import 'rc-pagination/assets/index.css';
import T from 'prop-types';
import Modal from 'react-modal';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CreatableSelect from 'react-select/creatable';
import {
	Button, Input,
	// WrapperContainer,
} from '../../../components/Styles';
import { ListItem, ListHeader } from '../../../components/EditAdminJobListComponents/index';
// import ToastMessage from '../../../components/ToastMessage';
import { TitleWithTooltip } from '../../../components/FormComponents';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import LoadingIcon from '../../../components/LoadingIcon/LoadingIcon';
import {
	GET_PROFILE_DATA,
	FETCH_PROFILE_DATA,
	FETCH_PROFILE_DATA_SUCCESS,
	FETCH_PROFILE_DATA_FAILURE,
	UPDATE_TALENT_BASE_URL,
	SET_ALL_ELEMENTS_BLURRED,
	UPDATE_ERRORS,
	UPDATE_BLURRED_ELEMENT,
	UPDATE_ACTIONS_ERROR,
	UPDATE_ACTIONS_SUCCESS,
	UPDATE_ACTIONS_LOADER,
	SET_ACTIONS_MESSAGE,
} from '../constants';
import {
	filterProfileData, jobProfileContactInfoValidation, removeDuplicatesFromAsyncMultiSelect, returnAsyncFilterValue, returnOnlyValueIncludedArray,
} from '../../../utils/common';

const initialState = {
	cityList: [],
	profileJobData: {},
	loading: false,
	error: false,
	actionLoading: false,
	actionError: false,
	actionMessage: '',
	blurredElements: {
		educationStartDate: false,
		institutionName: false,
		degreeType: false,
		degree: false,
		gpa: false,
		lastEducationDate: false,
		majors: false,
	},
	errors: {
		educationStartDate: false,
		institutionName: false,
		degreeType: false,
		degree: false,
		gpa: false,
		lastEducationDate: false,
		majors: false,
	},
};

function setActionsMessage(message) {
	return {
		type: SET_ACTIONS_MESSAGE,
		message,
	};
}
function updateActionsLoading() {
	return {
		type: UPDATE_ACTIONS_LOADER,
	};
}

function updateActionsFailure() {
	return {
		type: UPDATE_ACTIONS_ERROR,
	};
}

function updateActionsSuccess() {
	return {
		type: UPDATE_ACTIONS_SUCCESS,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function fetchProfileJobData() {
	return {
		type: FETCH_PROFILE_DATA,
	};
}
function fetchProfileJobDataSuccess(profileJobData) {
	return {
		type: FETCH_PROFILE_DATA_SUCCESS,
		profileJobData,
	};
}
function fetchProfileJobDataFailure() {
	return {
		type: FETCH_PROFILE_DATA_FAILURE,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case FETCH_PROFILE_DATA:
		return { ...state, loading: true };
	case FETCH_PROFILE_DATA_SUCCESS:
		return {
			...state, loading: false, profileJobData: { ...action.profileJobData },
		};
	case FETCH_PROFILE_DATA_FAILURE:
		return {
			...state, loading: false, error: true, profileJobData: {},
		};
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };
	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			educationStartDate: true,
			institutionName: true,
			degreeType: true,
			degree: true,
			gpa: true,
			lastEducationDate: true,
			majors: true,
		};
		return { ...state, blurredElements };
	}
	case UPDATE_ACTIONS_ERROR:
		return {
			...state, actionLoading: false, actionError: true,
		};
	case UPDATE_ACTIONS_SUCCESS:
		return {
			...state, actionLoading: false, actionError: false,
		};
	case UPDATE_ACTIONS_LOADER:
		return {
			...state, actionLoading: true, actionError: false,
		};
	case SET_ACTIONS_MESSAGE:
		return {
			...state, actionMessage: action.message,
		};
	default:
		return state;
	}
}

const EducationHistoryListPage = (props) => {
	const {
		match, history, updatedProfileJobData, updateEducationDetails,
	} = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		loading, profileJobData, actionLoading,
		actionError, actionMessage, errors, blurredElements,
	} = state;
	const [profileData, setProfileData] = useState({});
	const [showEditAddForm, setShowEditAddForm] = useState(false);
	const [showDeleteForm, setDeleteForm] = useState(false);
	const [selectedForm, setSelectedForm] = useState(0);
	const [selectedFormIndex, setSelectedFormIndex] = useState(-1);
	const [selectedEducationData, setSelectedEducationData] = useState({
		educationStartDate: '',
		institutionName: '',
		degreeType: {
			value: '',
			label: '',
		},
		degree: '',
		gpa: '',
		lastEducationDate: '',
		majors: [],
	});

	useEffect(() => {
		dispatch(updateErrors({
			educationStartDate: false,
			institutionName: false,
			degreeType: false,
			degree: false,
			gpa: false,
			lastEducationDate: false,
			majors: false,
		}));
		dispatch(updateBlurredElement({
			educationStartDate: false,
			institutionName: false,
			degreeType: false,
			degree: false,
			gpa: false,
			lastEducationDate: false,
			majors: false,
		}));
		dispatch(setAllElementsBlurred());
		if (match?.params?.profileId) {
			dispatch(fetchProfileJobData());
			// getProfileData(match.params.profileId);
			setTimeout(() => {
				dispatch(fetchProfileJobDataSuccess(updatedProfileJobData));
				setProfileData({
					...filterProfileData(updatedProfileJobData),
				});
			}, 1000);
		}
	}, []);

	useEffect(() => {
		dispatch(fetchProfileJobDataSuccess(updatedProfileJobData));
		setProfileData({
			...filterProfileData(updatedProfileJobData),
		});
	}, [updatedProfileJobData]);

	async function getProfileData(profileId) {
		dispatch(fetchProfileJobData());
		const url = `${GET_PROFILE_DATA}${profileId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				const profileDetails = await response.json();
				if (profileDetails && profileDetails?.id?.length > 0) {
					setProfileData({
						...filterProfileData(profileDetails),
					});
				}
				dispatch(fetchProfileJobDataSuccess(profileDetails));
			} else {
				dispatch(fetchProfileJobDataFailure());
			}
		} catch (err) {
			dispatch(fetchProfileJobDataFailure());
		}
	}
	const saveProfileDataForUpdate = async (educationData = []) => {
		dispatch(updateActionsLoading());
		dispatch(setActionsMessage(''));
		const url = UPDATE_TALENT_BASE_URL;
		let finalEducationDetails = educationData || [];
		if (selectedForm === 1 && selectedFormIndex > -1) {
			finalEducationDetails = profileJobData.educationDetails?.length > 0 ? [...profileJobData.educationDetails] : [];
			finalEducationDetails[selectedFormIndex] = {
				EducationStartDate: selectedEducationData.educationStartDate,
				SchoolName: selectedEducationData.institutionName,
				DegreeType: selectedEducationData.degreeType.label,
				Degree: selectedEducationData.degree.label,
				GPA: selectedEducationData.gpa,
				LastEducationDate: selectedEducationData.lastEducationDate,
				Majors: returnOnlyValueIncludedArray(selectedEducationData.majors) || [],
				Text: '',
			};
		} else if (selectedForm === 2) {
			finalEducationDetails = profileJobData.educationDetails?.length > 0 ? [...profileJobData.educationDetails] : [];
			finalEducationDetails.push({
				EducationStartDate: selectedEducationData.educationStartDate,
				SchoolName: selectedEducationData.institutionName,
				DegreeType: selectedEducationData.degreeType.label,
				Degree: selectedEducationData.degree.label,
				GPA: selectedEducationData.gpa,
				LastEducationDate: selectedEducationData.lastEducationDate,
				Majors: returnOnlyValueIncludedArray(selectedEducationData.majors) || [],
				Text: '',
			});
		}
		const formData = {
			...profileData,
			profileId: match.params.profileId,
			switchFunctionIndustry: 'Yes',
			prefIndustry: profileData?.prefIndustry ? profileData?.prefIndustry : '',
			prefCity: profileData?.prefCity ? profileData?.prefCity : [],
			educationDetails: [
				...finalEducationDetails,
			],
		};
		const options = {
			method: 'PATCH',
			credentials: 'include',
			body: JSON.stringify(formData),
			headers: {
				'Content-Type': 'application/json',
			},
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				dispatch(updateActionsSuccess());
				dispatch(setActionsMessage('Education History Details are updated successfully'));
				setTimeout(() => {
					closeModal();
					dispatch(setActionsMessage(''));
					selectedForm === 1 || selectedForm === 2 ? getProfileData(match.params.profileId) : '';
					updateEducationDetails(finalEducationDetails);
				}, 2000);
			} else {
				dispatch(updateActionsFailure());
				dispatch(setActionsMessage('Not Able To Update The Job profile'));
				setTimeout(() => {
					dispatch(updateActionsSuccess());
					dispatch(setActionsMessage(''));
					closeModal();
				}, 2000);
			}
		} catch (err) {
			dispatch(updateActionsFailure());
			dispatch(setActionsMessage('Not Able To Update The Job profile'));
			setTimeout(() => {
				closeModal();
				dispatch(setActionsMessage(''));
				dispatch(updateActionsSuccess());
			}, 2000);
		}
	};
	const closeModal = () => {
		setSelectedEducationData({
			educationStartDate: '',
			institutionName: '',
			degreeType: {
				value: '',
				label: '',
			},
			degree: '',
			gpa: '',
			lastEducationDate: '',
			majors: [],
			Text: '',
		});
		const removeAll = {
			educationStartDate: false,
			institutionName: false,
			degreeType: false,
			degree: false,
			gpa: false,
			lastEducationDate: false,
			majors: false,
		};
		setSelectedForm(0);
		dispatch(updateErrors(removeAll));
		dispatch(updateBlurredElement(removeAll));
		dispatch(setAllElementsBlurred());
		setShowEditAddForm(false);
		setDeleteForm(false);
		setSelectedFormIndex(-1);
	};
	// const validateMajorBasedOnDegreeType = () => {
	// 	if (selectedEducationData && (selectedEducationData?.degreeType?.label?.length > 0 && selectedEducationData?.degreeType?.label.toLowerCase() !== '10th or below'
	// 		&& selectedEducationData?.degreeType?.label.toLowerCase() !== '12th'
	// 		&& selectedEducationData?.degreeType?.label.toLowerCase() !== 'diploma')) {
	// 		return !(selectedEducationData.majors && selectedEducationData.majors?.length > 0);
	// 	}
	// 	return false;
	// };
	function validateForm() {
		return {
			// educationStartDate: !(selectedEducationData && selectedEducationData.educationStartDate && selectedEducationData.educationStartDate?.length > 0),
			// degreeType: !(selectedEducationData.degreeType && selectedEducationData.degreeType?.value?.length > 0),
			// lastEducationDate: !(selectedEducationData.lastEducationDate && selectedEducationData.lastEducationDate?.length > 0),
			// degree: !(selectedEducationData.degree && selectedEducationData.degree?.value?.length > 0),
			// gpa: !(selectedEducationData.gpa && selectedEducationData.gpa?.length > 0),
			institutionName: !(selectedEducationData.institutionName && selectedEducationData.institutionName?.length > 0),
			// majors: validateMajorBasedOnDegreeType(),
		};
	}
	const handleErrors = () => {
		const validation = validateForm();
		const errors = Object.keys(validation).reduce((acc, curr) => {
			if (validation[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		const validation = validateForm();
		const validated = !Object.keys(validation).some((i) => validation[i]);
		handleErrors();
		if (validated) {
			saveProfileDataForUpdate();
		} else {
			dispatch(setAllElementsBlurred());
		}
	};
	const validateEducationGPAValue = (event) => {
		const validWorkExpYear = jobProfileContactInfoValidation(event.target.value, 6);
		return validWorkExpYear ? event.target.value : '';
	};
	const reArrangeTheList = (index, caseVal) => {
		const finalArrangeResult = [...profileJobData?.educationDetails];
		const updatedProfileJobData = { ...profileJobData };
		if (caseVal === 1 && index > 0 && finalArrangeResult?.length > 1) {
			finalArrangeResult[index] = finalArrangeResult.splice(index - 1, 1, finalArrangeResult[index])[0];
		} else if (caseVal === 2 && index < (finalArrangeResult.length - 1) && finalArrangeResult?.length > 1) {
			finalArrangeResult[index] = finalArrangeResult.splice(index + 1, 1, finalArrangeResult[index])[0];
		}
		updatedProfileJobData.educationDetails = [...finalArrangeResult];
		dispatch(fetchProfileJobDataSuccess(updatedProfileJobData));
		saveProfileDataForUpdate(finalArrangeResult);
	};

	const deleteSelectedFormIndex = () => {
		const finalArrangeResult = [...profileJobData?.educationDetails];
		const updatedProfileJobData = { ...profileJobData };
		if (selectedFormIndex > -1 && finalArrangeResult?.length > 0) {
			finalArrangeResult.splice(selectedFormIndex, 1);
			updatedProfileJobData.educationDetails = [...finalArrangeResult];
			dispatch(fetchProfileJobDataSuccess(updatedProfileJobData));
			saveProfileDataForUpdate(finalArrangeResult);
		}
	};
	// const handleKeyDownForMajors = (event) => {
	// 	// const { inputValue, value } = this.state;
	// 	if (!selectedEducationData.inputValueForMajors) return;
	// 	switch (event.key) {
	// 		case 'Enter':
	// 		case 'Tab':
	// 			setSelectedEducationData({
	// 				majors: [...selectedEducationData.majors, {
	// 					label: selectedEducationData.inputValueForMajors,
	// 					value: selectedEducationData.inputValueForMajors
	// 				}],
	// 				inputValueForMajors: '',
	// 			});
	// 			event.preventDefault();
	// 	}
	// };
	const editOrAddNewEducationData = () => <Modal
		isOpen={showEditAddForm}
		onRequestClose={closeModal}
		shouldCloseOnOverlayClick={false}
		overlayClassName="modal-overlay"
		className="form-modal"
	>
		<Row>
			<Col md={10} xs={10}>
				<h2 className="w-50 text-left">
					Edit/Add Education Details
				</h2>
			</Col>
			<Col md={2} xs={2}>
				<div className="w-50 text-right">
					<button type="button" onClick={closeModal}>
						<i className="fas fa-times light-text disablePointerEvents" />
					</button>
				</div>
			</Col>
		</Row>
		<div>
			<form onSubmit={handleSubmit}>
				<Row className="m-2">
					<Col xs={12} sm={6}>
						<div className="pb-3">
							<TitleWithTooltip
								title="From Year"
								hint="From Year"
							/>
							<DatePicker
								selected={selectedEducationData.educationStartDate ? new Date(selectedEducationData.educationStartDate) : null}
								dateFormat="MMM yyyy"
								showMonthYearPicker
								className="meta-datepicker mt-sm-2"
								placeholderText="Click to select date"
								// eslint-disable-next-line
								onChange={(date) => {
									setSelectedEducationData(({
										...selectedEducationData,
										educationStartDate: moment(date).format('MMM YYYY'),
									}));
									// handleErrors();
								}}
							/>
							{/* <ErrorMessage display={errors.educationStartDate} element="From Year" /> */}
						</div>
					</Col>
					<Col xs={12} sm={6}>
						<div className="pb-3">
							<TitleWithTooltip
								title="To Year"
								hint="To Year"
							/>
							<DatePicker
								selected={selectedEducationData.lastEducationDate ? new Date(selectedEducationData.lastEducationDate) : null}
								dateFormat="MMM yyyy"
								showMonthYearPicker
								className="meta-datepicker mt-sm-2"
								placeholderText="Click to select date"
								minDate={selectedEducationData.educationStartDate ? new Date(moment(selectedEducationData.educationStartDate).add(1, 'month').toDate()) : null}
								// eslint-disable-next-line
								onChange={(date) => {
									setSelectedEducationData(({
										...selectedEducationData,
										lastEducationDate: moment(date).format('MMM YYYY'),
									}));
									// handleErrors();
								}}
							/>
							{/* <ErrorMessage display={errors.lastEducationDate} element="To Year" /> */}
						</div>
					</Col>
					<Col xs={12} sm={12}>
						<div className="pb-3">
							<TitleWithTooltip
								title="Institution Name *"
								hint="Institution Name"
							/>
							<Input
								type="text"
								className="form-control"
								value={selectedEducationData.institutionName}
								name="institutionName"
								placeholder="eg: SND University"
								autoComplete="off"
								maxLength="100"
								onChange={(event) => {
									setSelectedEducationData(({
										...selectedEducationData,
										institutionName: event.target.value,
										// majors: (selectedEducationData && (selectedEducationData?.degreeType?.label.toLowerCase() !== '10th or below'
										// 	&& selectedEducationData?.degreeType?.label.toLowerCase() !== '12th'
										// 	&& selectedEducationData?.degreeType?.label.toLowerCase() !== 'diploma')) ? returnAsyncFilterValue(selectedEducationData.majors) : [],
									}));
									// handleErrors();
								}}
							/>
							<ErrorMessage display={errors.institutionName} element="Institution Name" />
						</div>
					</Col>
					<Col xs={12} sm={6}>
						<div className="pb-3">
							<TitleWithTooltip
								title="Degree Type"
								hint="Degree Type"
							/>
							<Input
								type="text"
								className="form-control"
								value={selectedEducationData.degreeType.value}
								name="degreeTypeName"
								placeholder="eg: Graduate"
								autoComplete="off"
								maxLength="100"
								onChange={(event) => {
									setSelectedEducationData(({
										...selectedEducationData,
										degreeType: {
											value: event.target.value,
											label: event.target.value,
										},
									}));
								}}
							/>
							{/* <AsyncSingleSelectWithDefault
								name="degree-type"
								placeholder="Select From List"
								defaultOptionsList
								options={(value) => filterAsyncSelectOptionsTrue(value, 'education-type', '', null)}
								value={selectedEducationData.degreeType}
								handleChange={(value) => {
									setSelectedEducationData(({
										...selectedEducationData,
										degreeType: value,
									}));
								}}
							/> */}
							<ErrorMessage display={errors.degreeType} element="Education Degree Type" />
						</div>
					</Col>
					<Col xs={12} sm={6}>
						<div className="pb-3">
							<TitleWithTooltip
								title="Your Degree"
								hint="Your Degree"
							/>
							{/* {selectedEducationData && (selectedEducationData?.degreeType?.label
								&& selectedEducationData?.degreeType?.label?.length > 0 && selectedEducationData?.degreeType?.label.toLowerCase() === '10th or below'
								&& selectedEducationData?.degreeType?.label.toLowerCase() === '12th'
								&& selectedEducationData?.degreeType?.label.toLowerCase() === 'diploma')
								&& */}
							<Input
								type="text"
								className="form-control"
								value={selectedEducationData.degree.value}
								name="degreeNAme"
								placeholder="eg: PCMC"
								autoComplete="off"
								maxLength="100"
								onChange={(event) => {
									setSelectedEducationData(({
										...selectedEducationData,
										degree: {
											value: event.target.value,
											label: event.target.value,
										},
									}));
								}}
							/>
							<ErrorMessage display={errors.degree} element="Degree" />
							{/* } */}
							{/* <AsyncSingleSelectWithDefault
									name="education-degree"
									placeholder="Select From List"
									defaultOptionsList
									options={(value) => filterAsyncSelectOptionsTrue(value, 'education-degree-type', '', null)}
									value={selectedEducationData.degree}
									handleChange={(value) => {
										setSelectedEducationData(({
											...selectedEducationData,
											degree: value,
										}));
									}}
								/>
								<ErrorMessage display={errors.degree} element="Degree" /> */}
						</div>
					</Col>
					<Col xs={12} sm={6}>
						<div className="pb-3">
							<TitleWithTooltip
								title="GPA"
								hint="GPA"
							/>
							<Input
								type="text"
								className="form-control"
								value={selectedEducationData.gpa}
								name="educationGpa"
								autoComplete="off"
								maxLength="1000"
								placeholder="eg: 67.4"
								onChange={(e) => {
									setSelectedEducationData(({
										...selectedEducationData,
										gpa: validateEducationGPAValue(e),
									}));
									// handleErrors();
								}}
							/>
							<ErrorMessage display={errors.gpa} element="Education GPA" />
						</div>
					</Col>
					{/* {selectedEducationData && (selectedEducationData?.degreeType?.label && selectedEducationData?.degreeType?.label?.length > 0 && selectedEducationData?.degreeType?.label.toLowerCase() !== '10th or below' &&
						selectedEducationData?.degreeType?.label.toLowerCase() !== '12th' &&
						selectedEducationData?.degreeType?.label.toLowerCase() !== 'diploma') &&  */}
					<Col xs={12} sm={6}>
						<div className="pb-3">
							<TitleWithTooltip
								title="Education Majors"
								hint="Education Majors"
							/>
							<CreatableSelect
								isMulti
								// inputValue={selectedEducationData.majors}
								onChange={(value) => {
									setSelectedEducationData({
										...selectedEducationData,
										majors: removeDuplicatesFromAsyncMultiSelect(value),
									});
								}}
								placeholder="Type something and press enter..."
								value={selectedEducationData.majors}
							/>
							{/* <CreatableSelect
								inputValue={selectedEducationData.inputValueForMajors}
								isClearable
								isMulti
								menuIsOpen={false}
								onChange={(value) => {
									setSelectedEducationData({
										...selectedEducationData,
										majors: removeDuplicatesFromAsyncMultiSelect(value),
									});
								}}
								onInputChange={(value) => {
									setSelectedEducationData(({
										...selectedEducationData,
										inputValueForMajors: value,
									}));
									handleErrors();
								}}
								onKeyDown={(e) => {
									handleKeyDownForMajors(e);
								}}
								placeholder="Type something and press enter..."
								value={selectedEducationData.majors}
							/> */}
							{/* <AsyncMultiSelect
								placeholder="Select From List"
								defaultOptionsList={[]}
								options={(value) => {
									return [];
								}}
								currentValues={selectedEducationData.majors}
								handleChange={(value) => {
									setSelectedEducationData({
										...selectedEducationData,
										majors: removeDuplicatesFromAsyncMultiSelect(value),
									});
								}}
								profile="educationMajors"
							/> */}
							<ErrorMessage display={errors.majors} element="Education Majors" />
						</div>
					</Col>
					{/* } */}
					<Col xs={12} sm={12} className="mt-2 text-center">
						<Button
							success
							no_radius
							type="submit"
							style={{ width: '150px' }}
						>
							<b>Save Changes</b>
						</Button>
					</Col>
					<Col xs={12} sm={12}>
						{actionLoading && <LoadingIcon />}
					</Col>
					<Col xs={12} sm={12}>
						{(actionError && actionMessage) && <p className="error error-text text-normal text-center mb-2">
							{actionMessage}
						</p>}
						{(!actionError && actionMessage) && <p className="success-text text-normal text-center mb-2">
							{actionMessage}
						</p>}
					</Col>
				</Row>
			</form>
		</div>
	</Modal>;
	const deleteSelectedEducationData = () => <Modal
		isOpen={showDeleteForm}
		onRequestClose={closeModal}
		shouldCloseOnOverlayClick={false}
		overlayClassName="modal-overlay"
		className="form-modal"
	>
		<Row>
			<Col md={10} xs={10}>
				<h3 className="w-50 text-left">Do You Want To Delete?</h3>
			</Col>
			<Col md={2} xs={2}>
				<div className="w-50 text-right">
					<button type="button" onClick={closeModal}>
						<i className="fas fa-times light-text disablePointerEvents" />
					</button>
				</div>
			</Col>
		</Row>
		<div>
			<form>
				<Row className="m-2">
					<Col xs={12} sm={12} className="mt-2 text-center">
						<Button
							success
							no_radius
							type="button"
							style={{ width: '100px' }}
							onClick={() => {
								deleteSelectedFormIndex();
							}}
						>
							<b>Delete</b>
						</Button>
						<Button
							primary
							no_radius
							type="button"
							style={{ width: '100px' }}
							onClick={() => {
								setSelectedFormIndex(-1);
								setDeleteForm(false);
								setSelectedForm(0);
							}}
						>
							<b>Cancel</b>
						</Button>
					</Col>
					<Col xs={12} sm={12}>
						{actionLoading && <LoadingIcon />}
					</Col>
					<Col xs={12} sm={12}>
						{(actionError && actionMessage) && <p className="error error-text text-normal text-center mb-2">
							{actionMessage}
						</p>}
						{(!actionError && actionMessage) && <p className="success-text text-normal text-center mb-2">
							{actionMessage}
						</p>}
					</Col>
				</Row>
			</form>
		</div>
	</Modal>;
	const getEducationHistoryList = () => profileJobData?.educationDetails.map((item, index) => <ListItem
		slNo={index + 1}
		job={item}
		index={index}
		selectedStatus={1}
		moveUp={() => {
			reArrangeTheList(index, 1);
		}}
		moveDown={() => {
			reArrangeTheList(index, 2);
		}}
		openEditForm={() => {
			setSelectedEducationData({
				...item,
				indexVal: index,
				educationStartDate: item.EducationStartDate,
				institutionName: item.SchoolName,
				degreeType: {
					value: item.DegreeType,
					label: item.DegreeType,
				},
				degree: {
					value: item.Degree,
					label: item.Degree,
				},
				gpa: item.GPA,
				lastEducationDate: item.LastEducationDate,
				majors: returnAsyncFilterValue(item.Majors),
			});
			setSelectedFormIndex(index);
			setSelectedForm(1);
			setShowEditAddForm(true);
		}}
		deleteData={() => {
			setSelectedFormIndex(index);
			setDeleteForm(true);
			setSelectedForm(0);
		}}
	/>);
	const renderList = () => {
		if (profileJobData && profileJobData?.educationDetails?.length > 0) {
			return <div>
				<ListHeader selectedStatus={1} />
				{getEducationHistoryList()}
			</div>;
		}

		return (
			<Row style={{
				width: '100%', minHeight: '400px', justifyContent: 'center', alignItems: 'center',
			}}
			>
				<div>
					<Row>
						<Col xs={12} sm={12}>
							<div>
								<h1 style={{ color: '#bbb' }}>
									No Education History To Display
								</h1>
							</div>
						</Col>
					</Row>
				</div>
			</Row>
		);
	};
	// function handleBlur(element) {
	// 	handleErrors();
	// 	dispatch(updateBlurredElement(element));
	// }

	return (
		<div>
			{
				loading
					? <LoadingIcon />
					: <>
						<div>
							<Row>
								<Col xs={8} sm={8}>
									<h3 className="px-3">
										Edit Education History Details
									</h3>
								</Col>
								<Col xs={4} sm={4} className="text-right">
									<Button
										primary
										no_radius
										className="mb-0 mr-2"
										onClick={() => {
											setSelectedEducationData({
												Text: '',
												educationStartDate: '',
												institutionName: '',
												degreeType: {
													value: '',
													label: '',
												},
												degree: '',
												gpa: '',
												lastEducationDate: '',
												majors: [],
											});
											setSelectedForm(2);
											setShowEditAddForm(true);
										}}
									>
										<b>Add New Education Data</b>
									</Button>
								</Col>
							</Row>
							{renderList()}
						</div>
					</>
			}
			{editOrAddNewEducationData()}
			{deleteSelectedEducationData()}
		</div>
	);
};

EducationHistoryListPage.propTypes = {
	updatedProfileJobData: T.object,
	history: T.object,
	match: T.object,
	updateEducationDetails: T.func,
};
export default EducationHistoryListPage;
