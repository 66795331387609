import styled, { css } from 'styled-components';
import { Row } from 'react-styled-flexboxgrid';

const GalleryOptionsWrapper = styled(Row)`
		position: -webkit-sticky;
		position: sticky;
		margin: 0;
		top: 60px;
		z-index: 4;
		background-color: #fff;
		width: 100%;
		height: 100px;
		${(props) => ['videoEditor', 'textEditor'].includes(props.hoc) && css`
				top: 0;
	 `}
		@media (max-width: 1023px) {
				height: 150px; 
		}
`;

const ImageCardWrapper = styled.div`
		height: 230px;
		padding: 5px;
		margin: 0.25rem;
		background-color: #fff;
		width: 100%;
		cursor: pointer;
		font-size: 0.8rem;
		word-wrap: break-word;
		transition: all .1s ease-in-out;
		&:hover {
				border: 5px solid #5c87ff;
				padding: 2.5px;
				border-radius: 2px;
				transform: scale(1.1);
		}

`;

const GalleryWrapper = styled(Row)`
		margin-top: 2rem;
		border: 1px solid #bbb;
		background-color: #eee;
		padding: 0.5rem;
		width: 100%;
		position: relative;
		overflow: auto;
		height: 100vh;
		${(props) => props.source === 'fullPage' && css`
				height: 75vh;
	 `}
`;

const EmbedCodeBox = styled.textarea`
		resize: none;
		width: 100%;
		height: 150px;
		border: 1px solid #ced4da;
		padding: 0.5rem;
		border-radius: 0.25rem;
		color: #4a4a4a;
		:focus {
				border: 3px solid #ced4da;
				outline: none;
				}
`;

const DropContainer = styled.div`
		padding: 2em;
		border: 2px dashed #aaa;
		border-radius: 5px;
		text-align: center;
		margin: auto 0;
		width: 100%;
		& #uploader:hover {
				text-decoration: underline;
				cursor: pointer;
		}
`;

const Iframe = styled.iframe`
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
`;

export {
	GalleryOptionsWrapper,
	ImageCardWrapper,
	GalleryWrapper,
	EmbedCodeBox,
	DropContainer,
	Iframe,
};
