export const FETCH_STORY_LIST = 'FETCH_STORY_LIST';
export const FETCH_STORY_LIST_SUCCESS = 'FETCH_STORY_LIST_SUCCESS';
export const FETCH_STORY_LIST_ERROR = 'FETCH_STORY_LIST_ERROR';

export const SET_STORY_LIST_STORY_STATUS = 'SET_STORY_LIST_STORY_STATUS';
export const SET_STORY_LIST_SELECTED_BRAND = 'SET_STORY_LIST_SELECTED_BRAND';
export const SET_STORY_LIST_PAGE = 'SET_STORY_LIST_PAGE';

export const UPDATE_STORYLIST_STORYITEM_STATUS = 'UPDATE_STORYLIST_STORYITEM_STATUS';
export const UPDATE_STORYLIST_STORYITEM_STATUS_SUCCESS = 'UPDATE_STORYLIST_STORYITEM_STATUS_SUCCESS';
export const UPDATE_STORYLIST_STORYITEM_STATUS_ERROR = 'UPDATE_STORYLIST_STORYITEM_STATUS_ERROR';

export const FETCH_STORYLIST_BRAND = 'FETCH_STORYLIST_BRAND';
export const FETCH_STORYLIST_BRAND_SUCCESS = 'FETCH_STORYLIST_BRAND_SUCCESS';
export const FETCH_STORYLIST_BRAND_FAILURE = 'FETCH_STORYLIST_BRAND_FAILURE';
