export const BRAND_API_BASE_URL = '/api/v2/rw/brands';
export const EVENT_API_BASE_URL = '/api/v2/rw/events';

export const UPDATE_MESSAGE = { intent: 'info', message: 'Updating event...' };
export const UPDATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Event updated successfully' };
export const UPDATE_FAILURE_MESSAGE = { intent: 'danger', message: 'Event update failed. Something went wrong. Try again!' };

export const CREATE_MESSAGE = { intent: 'info', message: 'Creating event...' };
export const CREATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Event created successfully' };
export const CREATE_FAILURE_MESSAGE = { intent: 'danger', message: 'Event creation failed. Something went wrong. Try again!' };

export const FETCH_EVENT_FAILURE_MESSAGE = { intent: 'danger', message: 'Failed to fetch event. Something went wrong. Try again!' };

export const EVENT_DELETE_LOAD_MESSAGE = { intent: 'info', message: 'Deleting...' };
export const EVENT_DELETE_FAILURE_MESSAGE = { intent: 'danger', message: 'Error while deleting event. Something went wrong. Try again! ' };
