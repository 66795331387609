import { Map } from 'immutable';
import {
	LOAD_BRAND_ERROR, LOAD_BRAND_SUCCESS, CREATE_ALIAS_SUCCESS, CREATE_ALIAS_ERROR, UPDATE_FORM, UPDATE_LOGO, DISCARD_FORM, CREATE_ALIAS, LOAD_BRAND, UNMOUNT_ALIAS_FORM_MESSAGE,
} from './type';
import { ALIAS_CREATE_SAVE_MESSAGE, BRAND_LOADING_MESSAGE } from './constants';

const initialState = Map({
	imagePreviewUrl: '',
	file: {},
	formData: {
		name: '',
		username: '',
		brand: '',
		website_url: '',
		facebook_url: '',
		twitter_url: '',
		linkedin_url: '',
		google_url: '',
		bio: '',
		brandList: [],
	},
	showForm: true,
	formMessage: null,
	saving: false,
});

function createAliasReducer(state = initialState, action) {
	switch (action.type) {
	case LOAD_BRAND_SUCCESS: {
		const form = {
			...state.formData,
			brandList: action.brands.brandList,
		};
		return state
			.set('formData', form)
			.set('showForm', true)
			.set('formMessage', null)
			.set('saving', false);
	}

	case LOAD_BRAND_ERROR:
		return state
			.set('formMessage', { intent: 'danger', message: action.error })
			.set('showForm', false);

	case CREATE_ALIAS_SUCCESS: {
		const formData = { ...state.get('formData') };
		return state
			.set('formMessage', { intent: 'success', message: `Created alias ${formData.username} for brand ${formData.brand}` })
			.set('showForm', false);
	}
	case CREATE_ALIAS_ERROR:
		return state
			.set('formMessage', { intent: 'danger', message: action.failureResponse.message })
			.set('showForm', true)
			.set('saving', false);

	case UPDATE_FORM: {
		const formData = { ...state.get('formData') };
		formData[action.element] = action.value;
		return state
			.set('formData', formData);
	}

	case UPDATE_LOGO: {
		return state
			.set('file', action.file)
			.set('imagePreviewUrl', action.url);
	}

	case DISCARD_FORM: {
		const oldFormData = { ...state.get('formData') };
		const form = {
			...state.formData,
			brandList: oldFormData.brandList,
		};
		return state
			.set('file', {})
			.set('imagePreviewUrl', '')
			.set('formData', form);
	}

	case CREATE_ALIAS:
		return state
			.set('formMessage', ALIAS_CREATE_SAVE_MESSAGE)
			.set('saving', true);

	case LOAD_BRAND:
		return state
			.set('showForm', true)
			.set('formMessage', BRAND_LOADING_MESSAGE)
			.set('saving', true);

	case UNMOUNT_ALIAS_FORM_MESSAGE:
		return state
			.set('formMessage', null);

	default:
		return state;
	}
}

export default createAliasReducer;
