import React from 'react';
import T from 'prop-types';
import { Col } from 'react-styled-flexboxgrid';
import { ListHeaderWrapper } from '../../components/CompanyEditorComponents/styles';

const IPOListHeader = (props) => (
	<ListHeaderWrapper className="mt-4">
		<Col sm={2} xs={4} onClick={() => props.sortBy('company')} className="pointer">
			Company
			<i className={`ml-2 fas fa-chevron-${!props.sortedBy.company ? null : props.sortedBy.company === 'asc' ? 'up' : 'down'}`} />
		</Col>
		<Col sm={1} xs={2} onClick={() => props.sortBy('lotSize')} className="pointer">
			Lot Size
			<i className={`ml-2 fas fa-chevron-${!props.sortedBy.lotSize ? null : props.sortedBy.lotSize === 'asc' ? 'up' : 'down'}`} />
		</Col>
		<Col sm={1} xs={2}>
			Exchange
			<i className={`ml-2 fas fa-chevron-${!props.sortedBy.exchange ? null : props.sortedBy.exchange === 'asc' ? 'up' : 'down'}`} />
		</Col>
		<Col sm={1} xs={2} onClick={() => props.sortBy('issuePrice')} className="pointer">
			Issue Price
			<i className={`ml-2 fas fa-chevron-${!props.sortedBy.issuePrice ? null : props.sortedBy.issuePrice === 'asc' ? 'up' : 'down'}`} />
		</Col>
		<Col sm={1} xs={2} onClick={() => props.sortBy('issueSize')} className="pointer">
			Issue Size
			<i className={`ml-2 fas fa-chevron-${!props.sortedBy.issueSize ? null : props.sortedBy.issueSize === 'asc' ? 'up' : 'down'}`} />
		</Col>
		<Col sm={1} xs={2} onClick={() => props.sortBy('issuePrice')} className="pointer">
			Open
			<i className={`ml-2 fas fa-chevron-${!props.sortedBy.issuePrice ? null : props.sortedBy.issuePrice === 'asc' ? 'up' : 'down'}`} />
		</Col>
		<Col sm={1} xs={2} onClick={() => props.sortBy('issuePrice')} className="pointer">
			Close
			<i className={`ml-2 fas fa-chevron-${!props.sortedBy.issuePrice ? null : props.sortedBy.issuePrice === 'asc' ? 'up' : 'down'}`} />
		</Col>
		<Col sm={1} xs={1} md={2}>
			Actions
		</Col>
	</ListHeaderWrapper>
);

IPOListHeader.propTypes = {
	sortBy: T.func,
	sortedBy: T.object,

};

export default IPOListHeader;
