import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Col } from 'react-styled-flexboxgrid';
import PropTypes from 'prop-types';

const CURATED_SET_CONFIG_URL = '/api/v2/rw/curated/config/techsparks_';

const Tracks = (props) => {
	Tracks.propTypes = {
		city: PropTypes.string,
	};
	return (
		<Col md={3} sm={6} xs={12}>
			<Link to={`/techsparks/tracks/${props.city}`} className="d-block">
				<Card className="b-0 p-3">
					<div className="lg-text pb-2 mb-2 b-bottom separator">
						<span className="mr-2">
							<i name="desk-icon" className="fa fa-book brand-text" />
						</span>
						<span>Tracks</span>
					</div>
					<div className="mb-3 info">Create and Update Tracks</div>
					<div className="tu sm-text brand-text mb-2"><b>manage</b></div>
				</Card>
			</Link>
		</Col>
	);
};

const Speakers = (props) => {
	Speakers.propTypes = {
		city: PropTypes.string,
	};
	return (
		<Col md={3} sm={6} xs={12}>
			<Link to={`/techsparks/speakers/${props.city}`} className="d-block">
				<Card className="b-0 p-3">
					<div className="lg-text pb-2 mb-2 b-bottom separator">
						<span className="mr-2">
							<i name="desk-icon" className="fa fa-book brand-text" />
						</span>
						<span>Home Page Speakers</span>
					</div>
					<div className="mb-3 info">Add, update Speakers</div>
					<div className="tu sm-text brand-text mb-2"><b>manage</b></div>
				</Card>
			</Link>
		</Col>
	);
};

const ViewAllSpeakers = (props) => {
	ViewAllSpeakers.propTypes = {
		city: PropTypes.string,
	};
	return (
		<Col md={3} sm={6} xs={12}>
			<Link to={`/techsparks/view-all/speakers/${props.city}`} className="d-block">
				<Card className="b-0 p-3">
					<div className="lg-text pb-2 mb-2 b-bottom separator">
						<span className="mr-2">
							<i name="desk-icon" className="fa fa-book brand-text" />
						</span>
						<span>View All Speakers</span>
					</div>
					<div className="mb-3 info">Add, update Speakers</div>
					<div className="tu sm-text brand-text mb-2"><b>manage</b></div>
				</Card>
			</Link>
		</Col>
	);
};

const Agendas = (props) => {
	Agendas.propTypes = {
		city: PropTypes.string,
	};
	return (
		<Col md={3} sm={6} xs={12}>
			<Link to={`/techsparks/agendas/${props.city}`} className="d-block">
				<Card className="b-0 p-3">
					<div className="lg-text pb-2 mb-2 b-bottom separator">
						<span className="mr-2">
							<i name="desk-icon" className="fa fa-book brand-text" />
						</span>
						<span>Home Page Agendas</span>
					</div>
					<div className="mb-3 info">Add, update Agendas</div>
					<div className="tu sm-text brand-text mb-2"><b>manage</b></div>
				</Card>
			</Link>
		</Col>
	);
};

const Partners = (props) => {
	Partners.propTypes = {
		city: PropTypes.string,
	};
	return (
		<Col md={3} sm={6} xs={12}>
			<Link to={`/techsparks/partners/${props.city}`} className="d-block">
				<Card className="b-0 p-3">
					<div className="lg-text pb-2 mb-2 b-bottom separator">
						<span className="mr-2">
							<i name="desk-icon" className="fa fa-book brand-text" />
						</span>
						<span>Partners</span>
					</div>
					<div className="mb-3 info">Add, update Partners</div>
					<div className="tu sm-text brand-text mb-2"><b>manage</b></div>
				</Card>
			</Link>
		</Col>
	);
};

const Card = styled.div`
    border:1px solid #ddd;
    box-shadow : 0 0.1rem 0.3rem rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    padding:0.5rem;
    margin-bottom:1rem;
    word-wrap: break-word;
    min-height: 225px;
    > .description {
            font: 400 0.9rem "PT Sans", sans-serif;
            color:#666;
    }
    > .info {
            font-weight:400;
            font-family: "PT Sans", sans-serif;
    }
    .separator { border-color:#f9f9f9 !important; }
    > .brand-name {
            color:#ccc;
            font-size:1.2rem;
    }
`;

const SAVING_MESSAGE = { intent: 'info', message: 'Saving data...' };
const SAVING_SUCCESS_MESSAGE = { intent: 'success', message: 'Data Saved Successfully' };
const SAVING_FAILURE_MESSAGE = { intent: 'danger', message: 'Data Save Failed. Something went wrong. Try Again!' };
const OPTIONSLIST = [
	{
		name: 'Mumbai-2023',
		label: 'Mumbai-2023',
		key: 'Mumbai-2023',
		value: 'mumbai',
	}, {
		name: 'Bangalore-2022',
		label: 'Bangalore-2022',
		key: 'Bangalore-2022',
		value: 'bangalore',
	},
];

export {
	Agendas,
	Tracks,
	Speakers,
	ViewAllSpeakers,
	Partners,
	SAVING_MESSAGE,
	SAVING_SUCCESS_MESSAGE,
	SAVING_FAILURE_MESSAGE,
	CURATED_SET_CONFIG_URL,
	OPTIONSLIST,
};
