import React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Input, Button, WrapperContainer } from '../../components/Styles';

import TopicManagerHeader from '../../components/TopicManagerHeader/TopicManagerHeader';
import ImageHolder from '../../components/Cards/ImageHolder';
import {
	addNewPerson,
	formElementOnChange,
	createPerson,
	deletePerson,
	personSelect,
	updatePerson,
	unmountPeopleFormMessage,
	addPersonAward,
	removePersonAward,
	setInitialState,
	fetchPersonById,
} from './actions';
import {
	makeSelectGetOperation,
	makeSelectFormData,
	makeSelectSelectedPerson,
	makeSelectFormMessage,
	makeSelectLoading,
	makeSelectDropdownTitle,
	makeSelectUpdated,
	makeSelectOrigData,
} from './selectors';
import BannerImageUploader from '../../components/BannerImageUploader/BannerImageUploader';
import messages from './messages';
import { AsyncSingleSelect } from '../../components/FormComponents';
import closeSvg from '../../images/close.svg';
import { getDropdownOptions, validateImage } from '../../utils/common';
import TabHeader from '../../components/CompanyEditorComponents/TabHeader';
import { TAB_OPTIONS } from '../../components/CompanyEditorComponents/common';
import ToastMessage from '../../components/ToastMessage';

class PeopleManager extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			touched: {
				name: false,
				bio: false,
				image: false,
			},
			tabValue: 'currentData',
		};
	}

	componentDidMount() {
		const { pageTitle, match, fetchPersonById } = this.props;
		if (match.params.id) {
			fetchPersonById(match.params.id);
		}
		pageTitle('peopleManager');
	}

	componentWillUnmount() {
		this.props.setInitialState();
	}

	handleBlur(field) {
		this.setState({
			touched: { ...this.state.touched, [field]: true },
		});
	}

	displayErrors(field) {
		const errors = this.validateForm();
		const hasError = errors[field];
		const shouldShow = this.state.touched[field];
		return hasError ? shouldShow : false;
	}

	validateForm = () => {
		const { formData } = this.props;
		return {
			name: !(formData.name && formData.name.length > 1),
			bio: !(formData.bio && formData.bio.length > 1),
			image: !(formData.image && formData.image.url),
		};
	}

	onElementChange = (e) => {
		const { formData } = this.props;
		const form = e.target;
		let data = {
			name: form.name,
			value: form.value,
		};
		if (form.name === 'rank') {
			data = {
				name: 'awards',
				value: { ...formData.awards, rank: form.value },
			};
		} else if (form.name === 'description') {
			data = {
				name: 'awards',
				value: { ...formData.awards, description: form.value },
			};
		}
		this.props.formElementChange(data);
	}

	onUpload = ({ file, imagePreviewUrl }) => {
		const data = {
			name: 'imageFile',
			value: file,
		};
		const imageUrl = {
			url: imagePreviewUrl,
		};
		this.props.formElementChange(data);
		this.props.formElementChange({ name: 'image', value: imageUrl });
	}

	renderCoverImage() {
		return (<BannerImageUploader
			formElementChange={this.props.formElementChange}
			formData={this.props.formData}
		/>
		);
	}

	onSave = (e) => {
		e.preventDefault();
		const form = e.target;
		const errors = this.validateForm();
		const save = !Object.keys(errors).some((i) => errors[i]);
		const {
			operation, formData, triggerCreatePerson, triggerUpdatePerson, selectedPerson,
		} = this.props;

		const tempBanner = { ...formData.banner };
		const tempImage = { ...formData.image };
		delete tempBanner.url;
		delete tempImage.url;

		if (save) {
			let socialLinks = null;
			if (form.facebook.value || form.linkedin.value || form.twitter.value || form.wikipedia.value) {
				socialLinks = {
					facebook: form.facebook.value ? form.facebook.value : null,
					linkedin: form.linkedin.value ? form.linkedin.value : null,
					twitter: form.twitter.value ? form.twitter.value : null,
					wikipedia: form.wikipedia.value ? form.wikipedia.value : null,
				};
			}

			const data = new FormData();
			data.append('name', form.name.value);
			data.append('email', form.email.value);
			data.append('phoneNumber', form.phone.value);
			data.append('bio', form.bio.value);
			data.append('subtitle', form.subtitle.value);
			data.append('socialLinks', JSON.stringify(socialLinks));
			data.append('website', form.website.value);
			data.append('status', formData.status);
			const filteredAwards = formData.awards.filter((item) => item.name);
			data.append('awards', filteredAwards && filteredAwards.length > 0 ? JSON.stringify(filteredAwards) : null);
			if (formData.bannerImageFile) {
				data.append('banner', JSON.stringify(tempBanner));
				data.append('bannerImage', formData.bannerImageFile ? formData.bannerImageFile : null);
			}
			if (formData.imageFile) {
				data.append('profilePicture', JSON.stringify(tempImage));
				data.append('image', formData.imageFile ? formData.imageFile : null);
			}

			if (operation === 'add') triggerCreatePerson(data);

			else if (operation === 'edit') {
				triggerUpdatePerson(selectedPerson.id, data);
			}
		} else {
			this.setState({
				touched: {
					name: this.state.touched.name || true,
					bio: this.state.touched.bio || true,
					image: this.state.touched.image || true,
				},
			});
		}
	}

	onDelete = (e) => {
		const { triggerDeletePerson, selectedPerson } = this.props;
		e.preventDefault();
		triggerDeletePerson(selectedPerson.id);
	}

	onImageLoad = (e) => {
		if (this.props.formData.imageFile) {
			const data = { height: e.target.naturalHeight, width: e.target.naturalWidth };
			if (!validateImage(e.target.naturalHeight, e.target.naturalWidth)) {
				document.getElementById('file-profilePicture').value = '';
				this.props.formElementChange({
					name: 'image',
					value: {
						image: null,
					},
				});
				this.props.formElementChange({
					name: 'imageFile',
					value: null,
				});
			} else this.props.formElementChange({ name: 'image', value: { ...this.props.formData.image, ...data } });
		}
	}

	renderAwardFields() {
		const { formData, unchangedFormData } = this.props;
		const isDisabled = this.props.isInfoUpdated && this.state.tabValue === 'currentData';
		const data = this.state.tabValue === 'currentData' && this.props.isInfoUpdated ? unchangedFormData : formData;
		return data.awards && data.awards.length > 0 ? data.awards.map((award, index) => <Row className="mb-2" key={`award-${award.id}`}>
			<Col xs={8}>
				<AsyncSingleSelect
					disabled={isDisabled}
					placeholder="Search awards by name"
					options={(value) => getDropdownOptions('award', value)}
					value={award.label ? award : null}
					handleChange={(value) => this.props.formElementChange({ name: 'awards', value }, index)}
				/>
			</Col>
			<Col xs={3}>
				<Input
					disabled={isDisabled}
					placeholder="Rank"
					value={award.rank ? award.rank : ''}
					onChange={(e) => {
						this.props.formElementChange({
							name: 'awards',
							value: { rank: e.target.value < 1 ? Math.abs(e.target.value) : e.target.value },
						}, index);
					}}
					type="number"
					name="rank"
					min="0"
				/>
			</Col>
			<Col xs={1}>
				<img
					disabled={isDisabled}
					src={closeSvg}
					onClick={() => (isDisabled ? null : this.props.removeAward(index))}
					alt="close"
					width="10"
					height="10"
				/>
			</Col>
			<Col xs={11} className="mt-2 mb-2">
				<textarea
					disabled={isDisabled}
					rows="8"
					className="global-form-control"
					name="description"
					placeholder="Award description"
					value={award.description ? award.description : ''}
					onChange={(e) => this.props.formElementChange({
						name: 'awards',
						value: { description: e.target.value },
					}, index)}
				/>
			</Col>
		</Row>) : null;
	}

	renderForm() {
		const {
			operation, formData, unchangedFormData, dropdownTitle,
		} = this.props;
		const data = this.state.tabValue === 'currentData' && this.props.isInfoUpdated ? unchangedFormData : formData;
		const isDisabled = this.props.isInfoUpdated && this.state.tabValue === 'currentData';
		if (['add', 'edit'].includes(operation)) {
			return <form onSubmit={this.onSave}>
				{/* <Row className='mt-5 mb-5'>
					{this.renderCoverImage()}
				</Row> */}
				{operation === 'edit'
					? <Row className="mt-4">
						<Col>Status: <b>{data.status}</b></Col>
					</Row> : null}
				<Row className="mt-5 mb-5">
					<Col xs={12} md={8}>
						{this.renderFormMessage()}
						<Row className="mb-3">
							<Col sm={3} xs={12}>
								Name *
							</Col>
							<Col sm={9} xs={12}>
								<Input
									disabled={isDisabled}
									type="text"
									name="name"
									placeholder="Name of the influencer"
									value={data.name ? data.name : ''}
									onChange={this.onElementChange}
									onBlur={() => this.handleBlur('name')}
								/>
								{
									this.displayErrors('name')
										? <p className="error" id="file-upload-err"><FormattedMessage {...messages.nameErr} /></p>
										: null
								}
							</Col>
						</Row>
						<Row className="mb-3">
							<Col sm={3} xs={12}>
								Username
							</Col>
							<Col sm={9} xs={12}>
								<Input
									disabled
									type="text"
									name="userName"
									placeholder="Username of the influencer"
									value={dropdownTitle && dropdownTitle.username ? dropdownTitle.username : ''}
								/>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col sm={3} xs={12}>
								Email
							</Col>
							<Col sm={9} xs={12}>
								<Input
									disabled={isDisabled}
									type="email"
									name="email"
									placeholder="Email of the influencer"
									value={data.email ? data.email : ''}
									onChange={this.onElementChange}
								/>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col sm={3} xs={12}>
								Phone Number
							</Col>
							<Col sm={9} xs={12}>
								<Input
									disabled={isDisabled}
									type="number"
									name="phone"
									min={1}
									placeholder="Phone number of the influencer"
									value={data.phone ? data.phone : ''}
									onChange={this.onElementChange}
									maxLength="10"
								/>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col sm={3} xs={12}>
								Designation
							</Col>
							<Col sm={9} xs={12}>
								<textarea
									disabled={isDisabled}
									rows="4"
									className="global-form-control"
									name="subtitle"
									placeholder="Designation of the influencer"
									value={data.subtitle ? data.subtitle : ''}
									onChange={this.onElementChange}
									maxLength="1000"
								/>
								<div style={{ textAlign: 'right', color: '#aaa' }}>
									{data.subtitle ? data.subtitle.length : 0}/1000
								</div>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col sm={3} xs={12}>
								Bio *
							</Col>
							<Col sm={9} xs={12}>
								<textarea
									disabled={isDisabled}
									rows="8"
									className="global-form-control"
									name="bio"
									placeholder="This is the bio"
									value={data.bio ? data.bio : ''}
									onChange={this.onElementChange}
									onBlur={() => this.handleBlur('bio')}
								/>
								{
									this.displayErrors('bio')
										? <p className="error" id="file-upload-err"><FormattedMessage {...messages.bioErr} /></p>
										: null
								}
							</Col>
						</Row>
						<Row className="mb-3">
							<Col sm={3} xs={12}>
								Awards
							</Col>
							<Col sm={9} xs={12}>
								{data.awards && data.awards.length > 0
									? <>
										<Row className="text-bold mb-1">
											<Col sm={8}>Name</Col>
											<Col sm={3}>Rank</Col>
										</Row>
										{this.renderAwardFields()}
									</>
									: null}
								<Button
									disabled={isDisabled}
									type="button"
									onClick={this.props.addAward}
									success
									className="pull-right"
								>
									Add
								</Button>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col sm={3} xs={12}>
								Facebook
							</Col>
							<Col sm={9} xs={12}>
								<Input
									disabled={isDisabled}
									type="url"
									name="facebook"
									placeholder="Link to the influencer's Facebook profile"
									value={data.facebook ? data.facebook : ''}
									onChange={this.onElementChange}
								/>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col sm={3} xs={12}>
								LinkedIn
							</Col>
							<Col sm={9} xs={12}>
								<Input
									disabled={isDisabled}
									type="url"
									name="linkedin"
									placeholder="Link to the influencer's LinkedIn profile"
									value={data.linkedin ? data.linkedin : ''}
									onChange={this.onElementChange}
								/>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col sm={3} xs={12}>
								Twitter
							</Col>
							<Col sm={9} xs={12}>
								<Input
									disabled={isDisabled}
									type="url"
									name="twitter"
									placeholder="Link to the influencer's Twitter profile"
									value={data.twitter ? data.twitter : ''}
									onChange={this.onElementChange}
								/>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col sm={3} xs={12}>
								Wikipedia
							</Col>
							<Col sm={9} xs={12}>
								<Input
									disabled={isDisabled}
									type="url"
									name="wikipedia"
									placeholder="Link to the influencer's Wikipedia page"
									value={data.wikipedia ? data.wikipedia : ''}
									onChange={this.onElementChange}
								/>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col sm={3} xs={12}>
								Website
							</Col>
							<Col sm={9} xs={12}>
								<Input
									disabled={isDisabled}
									type="url"
									name="website"
									placeholder="Link to the influencer's website"
									value={data.website ? data.website : ''}
									onChange={this.onElementChange}
								/>
							</Col>
						</Row>
						{this.renderFormMessage()}
						<Row>
							<Col sm={3} xs={12} />
							<Col sm={9} xs={12}>

								<Button
									type="submit"
									success
									disabled={this.props.loading}
								>
									{this.props.operation === 'edit' ? 'UPDATE' : 'SAVE'}
								</Button>
								<Button
									light
									disabled={this.props.loading}
									className="ml-3"
									onClick={(e) => {
										e.preventDefault();
										this.props.addPerson();
									}}
								>
									Discard
								</Button>
								{/* {this.props.operation === 'edit' ?
									<Button
										type="button"
										primary
										id="deleteBtn"
										disabled={this.props.loading}
										onClick={this.onDelete}
										className='ml-3'
									>
										Delete
													</Button>
									: null} */}
							</Col>
						</Row>
					</Col>
					<Col xs={12} md={4} className="pl-5">
						<ImageHolder
							fileCallback={this.onUpload}
							imageUrl={data.image ? data.image.url : ''}
							autoDimensions
							onImageLoad={this.onImageLoad}
							onBlur={this.handleBlur}
							profileDesk
							disabled={isDisabled}
							name="profilePicture"
							squareDimension="14rem"
							clearImage={() => {
								document.getElementById('file-profilePicture').value = '';
								this.props.formElementChange({
									name: 'image',
									value: {
										image: null,
									},
								});
								this.props.formElementChange({
									name: 'imageFile',
									value: null,
								});
							}}
						/>
						{
							this.displayErrors('image')
								? <p className="error" id="file-upload-err"><FormattedMessage {...messages.imageErr} /></p>
								: null
						}
					</Col>

				</Row>
			</form>;
		}
		return (<Row className="mt-5 mb-5">
			<Col xs={12} md={8}>
				{this.props.formMessage && this.props.formMessage.message === 'Person Saved Successfully' ? this.renderFormMessage() : null}
			</Col>
		</Row>);
	}

	renderTabHeader() {
		return (
			<TabHeader
				tabs={TAB_OPTIONS}
				value={this.state.tabValue || TAB_OPTIONS[0].value}
				onTabSelect={(tabValue) => this.setState({ tabValue })}
				hoc="influencer"
			/>
		);
	}

	renderFormMessage() {
		const { formMessage, formMessageUnmount } = this.props;
		if (formMessage) {
			return (
				<ToastMessage
					toastData={formMessage}
					unmount={formMessageUnmount}
				/>
			);
		}
		return null;
	}

	render() {
		return (
			<div>
				<WrapperContainer className="mt-5">
					<TopicManagerHeader
						hoc="people"
						addNew={this.props.addPerson}
						itemSelected={this.props.selectedPerson !== null}
						selectedItem={this.props.onSelectPerson}
						title={this.props.dropdownTitle}
					/>
					{this.props.isInfoUpdated && ['add', 'edit'].includes(this.props.operation)
						? this.renderTabHeader()
						: null}
					{this.renderForm()}
				</WrapperContainer>
			</div>
		);
	}
}

export function mapDispatchToProps(dispatch) {
	return {
		addPerson: () => {
			if (document.getElementById('file-profilePicture')) {
				document.getElementById('file-profilePicture').value = '';
			}
			return dispatch(addNewPerson());
		},
		formElementChange: (data, index) => dispatch(formElementOnChange(data, index)),
		triggerCreatePerson: (data) => dispatch(createPerson(data)),
		triggerDeletePerson: (id) => dispatch(deletePerson(id)),
		onSelectPerson: (selectedPerson) => dispatch(personSelect(selectedPerson)),
		triggerUpdatePerson: (id, data) => dispatch(updatePerson(id, data)),
		formMessageUnmount: () => dispatch(unmountPeopleFormMessage()),
		addAward: () => dispatch(addPersonAward()),
		removeAward: (index) => dispatch(removePersonAward(index)),
		setInitialState: () => dispatch(setInitialState()),
		fetchPersonById: (id) => dispatch(fetchPersonById(id)),
	};
}

const mapStateToProps = createStructuredSelector({
	operation: makeSelectGetOperation(),
	formData: makeSelectFormData(),
	selectedPerson: makeSelectSelectedPerson(),
	formMessage: makeSelectFormMessage(),
	loading: makeSelectLoading(),
	dropdownTitle: makeSelectDropdownTitle(),
	isInfoUpdated: makeSelectUpdated(),
	unchangedFormData: makeSelectOrigData(),
});

PeopleManager.propTypes = {
	pageTitle: PropTypes.func,
	operation: PropTypes.string,
	onSelectPerson: PropTypes.func,
	selectedPerson: PropTypes.object,
	addPerson: PropTypes.func,
	formData: PropTypes.object,
	formElementChange: PropTypes.func,
	triggerCreatePerson: PropTypes.func,
	triggerUpdatePerson: PropTypes.func,
	triggerDeletePerson: PropTypes.func,
	formMessage: PropTypes.object,
	formMessageUnmount: PropTypes.func,
	match: PropTypes.object,
	fetchPersonById: PropTypes.func,
	setInitialState: PropTypes.func,
	isInfoUpdated: PropTypes.bool,
	loading: PropTypes.bool,
	addAward: PropTypes.func,
	removeAward: PropTypes.func,
	dropdownTitle: PropTypes.object,
	unchangedFormData: PropTypes.object,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withConnect(PeopleManager);
