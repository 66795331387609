import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import T from 'prop-types';

const DropdownButton = styled.button`
		background-color: #2196F3;
		color: white;
		outline: none;
		border: 1px solid #2196F3;
		border-right: 1px solid #fff;
		border-radius: 0.25rem 0 0 0.25rem;
		font-size: 0.9rem;
		line-height: 1.5;
		font-weight: 400;
		font-family:"PT Sans", sans-serif;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		user-select: none;
		padding: 0.375rem 0.75rem;
		min-width: 100px;
		:hover {
				background-color: #0b7dda;
		}
`;

const CaretButton = styled.button`
		background-color: #2196F3;
		color: white;
		border: none;
		outline: none;
		padding: 0.375rem 0.75rem;
		border: 1px solid #2196F3;
		border-radius: 0 0.25rem 0.25rem 0;
		font-size: 0.9rem;
		line-height: 1.5;
		:hover {
				background-color: #0b7dda;
		}
`;

const Dropdown = styled.div`
		position: absolute;
		display: inline-block;
`;

const Menu = styled.ul`
		display: none;
		position: absolute;
		background-color: #fff;
		min-width: 180px;
		z-index: 4;
		list-style: none;
		padding-left: 0;
		border: 1px solid #eee;
		bottom: 20px;
		@media(max-width: 769px) {
				right: 0;
		}
		${(props) => props.show && css`
				display: block;
		`}

		li {
				padding: 10px 16px;
				text-decoration: none;
				display: block;
				list-style: none;
		}

		.divider {
				border-bottom: 1px solid #eee;
		}

		li:hover {
				background-color: #ddd
		}
`;

const SplitButton = (props) => {
	const {
		title, options, onOptionSelect, loading, onActionClick,
	} = props;
	const [showMenu, setMenuVisibility] = useState(false);

	useEffect(() => {
		window.addEventListener('click', listenClickOutsideMenu);
		return () => {
			window.removeEventListener('click', listenClickOutsideMenu);
		};
	});

	function listenClickOutsideMenu(e) {
		if (!['status-menu', 'caret-btn'].includes(e.target.id)) {
			setMenuVisibility(false);
		}
	}

	function renderDropdownItems() {
		const MenuItems = options.map((item, index) => <li
			key={item.label}
			className={`dropdown-item ${index !== options.length - 1 ? 'divider' : ''}`}
			onClick={() => {
				onOptionSelect(item);
				setMenuVisibility(false);
			}}
		>
			{item.label}
		</li>);
		return MenuItems;
	}

	return (
		<div className="mr-5" style={{ position: 'relative' }}>
			<DropdownButton
				success
				onClick={() => {
					onActionClick();
					setMenuVisibility(false);
				}}
				disabled={loading}
			>
				{title}
			</DropdownButton>
			<Dropdown>
				<CaretButton
					id="caret-btn"
					onClick={() => setMenuVisibility(!showMenu)}
					disabled={loading}
				>
					{
						showMenu
							? <i className="fa fa-caret-down disablePointerEvents" />
							: <i className="fa fa-caret-up disablePointerEvents" />
					}
				</CaretButton>
				<Menu id="status-menu" show={showMenu}>
					{renderDropdownItems()}
				</Menu>
			</Dropdown>
		</div>
	);
};

SplitButton.propTypes = {
	title: T.string,
	options: T.array,
	loading: T.bool,
	onOptionSelect: T.func,
	onActionClick: T.func,
};

export default SplitButton;
