import React, { useEffect } from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import propTypes from 'prop-types';
import { WrapperContainer } from '../../components/Styles';
import CategoryListings from '../../components/ListingsComponent/CategoryListings';

const ListingsManager = (props) => {
	const { pageTitle } = props;
	useEffect(() => {
		pageTitle('viewAllCategories');
	}, []);

	return (
		<WrapperContainer>
			<Row>
				<Col xs={12}>
					<p style={{ fontSize: '18px' }}><strong>Category Listings Manager</strong></p>
				</Col>
			</Row>
			<Row>
				<CategoryListings pageTitle={pageTitle} {...props} />
			</Row>
		</WrapperContainer>
	);
};
export default ListingsManager;

ListingsManager.propTypes = {
	pageTitle: propTypes.func,
};
