import { Map } from 'immutable';
import { cloneDeep } from 'lodash';
import { teamRoles, managerialRoles } from './constants';
import {
	LOAD_PROFILES, LOAD_PROFILES_SUCCESS, LOAD_PROFILES_ERROR, LOAD_MORE_PROFILES, LOAD_MORE_PROFILES_SUCCESS, LOAD_MORE_PROFILES_ERROR, LOAD_FILTERED_PROFILES, LOAD_FILTERED_PROFILES_SUCCESS, LOAD_FILTERED_PROFILES_ERROR, SAVE_PROFILE_SUCCESS, SAVE_PROFILE_ERROR, SELECT_BRAND, SELECT_PROFILE, UPDATE_CHANGES, DISCARD_PROFILE, CLEAR_USER_MANAGER_DATA,
} from './type';

const initialState = Map({
	loaded: false,
	noUsers: false,
	userArray: [],
	onPage: 1,
	lastPage: undefined,
	filterCriteria: 'NAME',
	filterButtonClicked: false,
	originalProfileSelected: null,
	duplicateProfileSelected: null,
	brandSelected: '',
	profileNumber: 0,
	teamCheckboxList: teamRoles,
	duplicateTeamCheckboxList: teamRoles,
	managerCheckboxList: managerialRoles,
	duplicateManagerCheckboxList: managerialRoles,
	brandList: [],
	responseMessage: '',
	responseType: 'fail',
	filterByValue: '',
	loggedInUserRole: {},
	loading: false,
});

function deepCopy(obj) {
	return JSON.parse(JSON.stringify(obj));
}

function userReducer(state = initialState, action) {
	switch (action.type) {
	case SELECT_BRAND:
		return state
			.set('brandSelected', action.selectedBrand);

	case SELECT_PROFILE:
		return state
			.set('originalProfileSelected', cloneDeep(action.data.originalProfileSelected))
			.set('duplicateProfileSelected', cloneDeep(action.data.duplicateProfileSelected))
			.set('profileNumber', action.data.profileNumber)
			.set('responseMessage', '')
			.set('teamCheckboxList', cloneDeep(action.data.teamCheckboxList))
			.set('duplicateTeamCheckboxList', cloneDeep(action.data.duplicateTeamCheckboxList))
			.set('managerCheckboxList', cloneDeep(action.data.managerCheckboxList))
			.set('duplicateManagerCheckboxList', cloneDeep(action.data.duplicateManagerCheckboxList));
	case LOAD_PROFILES:
		return state
			.set('loaded', false);

	case LOAD_PROFILES_SUCCESS:
		return state
			.set('loaded', true)
			.set('lastPage', action.profiles.data.totalPages)
			.set('brandList', action.profiles.brandList)
			.set('loggedInUserRole', action.profiles.loggedInUserRole);

	case LOAD_PROFILES_ERROR:
		return state
			.set('loaded', true)
			.set('error', action.error);

	case LOAD_MORE_PROFILES:
		return state
			.set('loading', true);

	case LOAD_MORE_PROFILES_SUCCESS: {
		const userArray = state.get('userArray');
		const onPage = state.get('onPage');
		return state
			.set('loaded', true)
			.set('loading', false)
			.set('lastPage', action.profiles.totalPages)
			.set('onPage', onPage + 1)
			.set('userArray', userArray.concat(action.profiles.users));
	}

	case LOAD_MORE_PROFILES_ERROR:
		return state
			.set('loaded', true)
			.set('error', action.error);

	case LOAD_FILTERED_PROFILES:
		return state
			.set('loaded', false);

	case LOAD_FILTERED_PROFILES_SUCCESS:
		return state
			.set('loaded', true)
			.set('lastPage', action.profiles.data.totalPages)
			.set('originalProfileSelected', null)
			.set('duplicateProfileSelected', null)
			.set('profileNumber', 0)
			.set('brandSelected', null)
			.set('onPage', 1)
			.set('filterButtonClicked', true)
			.set('userArray', action.profiles.data.users)
			.set('noUsers', action.profiles.data.users.length === 0);

	case LOAD_FILTERED_PROFILES_ERROR:
		return state
			.set('loaded', true)
			.set('error', action.error);

	case SAVE_PROFILE_SUCCESS: {
		const userArray = state.get('userArray');
		const profileNumber = state.get('profileNumber');
		const duplicateProfileSelected = deepCopy(state.get('duplicateProfileSelected'));
		const teamCheckboxList = deepCopy(state.get('teamCheckboxList'));
		const managerCheckboxList = deepCopy(state.get('managerCheckboxList'));
		userArray[profileNumber] = duplicateProfileSelected;

		return state
			.set('originalProfileSelected', duplicateProfileSelected)
			.set('userArray', userArray)
			.set('duplicateTeamCheckboxList', teamCheckboxList)
			.set('duplicateManagerCheckboxList', managerCheckboxList)
			.set('responseType', 'success')
			.set('responseMessage', action.response.message);
	}

	case SAVE_PROFILE_ERROR: {
		const originalProfileSelected = deepCopy(state.get('originalProfileSelected'));
		const duplicateTeamCheckboxList = deepCopy(state.get('duplicateTeamCheckboxList'));
		const duplicateManagerCheckboxList = deepCopy(state.get('duplicateManagerCheckboxList'));
		duplicateManagerCheckboxList.companyManager.accessLevel = originalProfileSelected.roles.companyManager;
		duplicateManagerCheckboxList.liveFeedCardManager.accessLevel = originalProfileSelected.roles.liveFeedCardManager;
		return state
			.set('loaded', true)
			.set('duplicateProfileSelected', originalProfileSelected)
			.set('teamCheckboxList', duplicateTeamCheckboxList)
			.set('managerCheckboxList', duplicateManagerCheckboxList)
			.set('responseType', 'fail')
			.set('responseMessage', action.error.message);
	}

	case DISCARD_PROFILE:
		return state
			.set('duplicateProfileSelected', deepCopy(action.data.duplicateProfileSelected))
			.set('teamCheckboxList', action.data.teamCheckboxList)
			.set('managerCheckboxList', action.data.managerCheckboxList)
			.set('duplicateTeamCheckboxList', deepCopy(action.data.duplicateTeamCheckboxList))
			.set('duplicateManagerCheckboxList', deepCopy(action.data.duplicateManagerCheckboxList))
			.set('responseMessage', action.data.responseMessage);

	case UPDATE_CHANGES:
		return state
			.set(action.name, action.value);

	case CLEAR_USER_MANAGER_DATA:
		return initialState;

	default:
		return state;
	}
}

export default userReducer;
