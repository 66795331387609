import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import { ListItemWrapper, Button } from '../../WebinarComponents/styles';

const ListItem = (props) => {
	const {
		job, slNo, selectedStatus, openEditForm, deleteData, moveUp, moveDown,
	} = props;
	const {
		SchoolName, Degree, Description, EndDate, JobTitle, StartDate, EducationStartDate, LastEducationDate, CompanyName,
	} = job;
	return (
		<ListItemWrapper slNo={slNo}>
			<ReactTooltip />
			<Col xs={2} sm={1} md={1}>
				<span>{slNo}</span>
			</Col>
			{selectedStatus === 1 && (<Col xs={6} sm={1} md={1}>
				<p className="m-0" type="title">{EducationStartDate?.length > 0 ? moment(EducationStartDate).format('YYYY') : 'N/A'}</p>
			</Col>
			)}
			{selectedStatus === 1 && (<Col xs={6} sm={1} md={1}>
				<p className="m-0" type="title">{LastEducationDate?.length > 0 ? moment(LastEducationDate).format('YYYY') : 'N/A'}</p>
			</Col>
			)}
			{selectedStatus === 2 && (<Col xs={6} sm={1} md={1}>
				<p className="m-0" type="title">{StartDate?.length > 0 ? moment(StartDate).format('MMM DD YYYY') : 'N/A'}</p>
			</Col>
			)}
			{selectedStatus === 2 && (<Col xs={6} sm={1} md={1}>
				<p className="m-0" type="title">{EndDate?.length > 0 ? moment(EndDate).format('MMM DD YYYY') : 'N/A'}</p>
			</Col>
			)}
			{selectedStatus === 1 && (
				<Col xs={6} sm={3} md={3}>
					<p className="m-0" type="title">{SchoolName?.length > 0 ? `${SchoolName.substring(0, 60)}... ` : 'N/A'}</p>
				</Col>
			)}
			{selectedStatus === 1 && (
				<Col xs={6} sm={2} md={2}>
					<p className="m-0" type="title">{Degree?.length > 0 ? `${Degree.substring(0, 60)}... ` : 'N/A'}</p>
				</Col>
			)}
			{selectedStatus === 2 && (
				<Col xs={6} sm={2} md={2}>
					<p className="m-0" type="title">{JobTitle?.length > 0 ? `${JobTitle.substring(0, 60)}... ` : 'N/A'}</p>
				</Col>
			)}
			{selectedStatus === 2 && (
				<Col xs={6} sm={2} md={2}>
					<p className="m-0" type="title">{CompanyName?.length > 0 ? `${CompanyName.substring(0, 60)}... ` : 'N/A'}</p>
				</Col>
			)}
			{selectedStatus === 2 && (
				<Col xs={6} sm={3} md={3}>
					<p className="m-0" type="title">{Description?.length > 0 ? `${Description.substring(0, 60)}... ` : 'N/A'}</p>
				</Col>
			)}
			<Col xs={2} sm={2} md={2}>
				<Button view title="Edit" onClick={openEditForm}>
					<i className="fa fa-pencil-square fa-lg" />
				</Button>
				<Button view title="Delete" onClick={deleteData}>
					<i className="fa fa-times-circle fa-lg" />
				</Button>
				<Button view title="Move Up" onClick={moveUp}>
					<i className="fa fa-chevron-circle-up fa-lg" />
				</Button>
				<Button view title="Move Down" onClick={moveDown}>
					{/* <i className="fa fa-arrow-circle-up fa-lg" /> */}
					<i className="fa fa-chevron-circle-down fa-lg" />
				</Button>
			</Col>
		</ListItemWrapper>
	);
};

ListItem.propTypes = {
	slNo: T.number,
	job: T.object,
	selectedStatus: T.number,
	openEditForm: T.func,
	deleteData: T.func,
	moveUp: T.func,
	moveDown: T.func,
	// rejectJob: T.func,
	// viewBio: T.func,
};

export default ListItem;
