import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { BRAND_SPOTLIGHT_API_BASE_URL } from './constants';
import {
	createBrandSpotlightError, createBrandSpotlightSuccess, updateBrandSpotlightSuccess, updateBrandSpotlightError,
} from './actions';
import { CREATE_BRAND_SPOTLIGHT, UPDATE_BRAND_SPOTLIGHT } from './type';

export function* createBrandSpotlight(action) {
	const url = `${BRAND_SPOTLIGHT_API_BASE_URL}/create`;
	const formData = action.brandSpotlight;
	const options = {
		method: 'POST',
		credentials: 'include',
		body: formData,
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(createBrandSpotlightSuccess(responseMessage));
			} else {
				yield put(createBrandSpotlightError(responseMessage));
			}
		}
	} catch (err) {
		yield put(createBrandSpotlightError(err));
	}
}

export function* updateBrandSpotlight(action) {
	const url = `${BRAND_SPOTLIGHT_API_BASE_URL}/update/${action.brandSpotlightId}`;
	const formData = action.brandSpotlight;
	const options = {
		method: 'PUT',
		credentials: 'include',
		body: formData,
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(updateBrandSpotlightSuccess(responseMessage));
			} else {
				yield put(updateBrandSpotlightError(responseMessage));
			}
		}
	} catch (err) {
		yield put(updateBrandSpotlightError(err));
	}
}

export default function* brandSpotlightData() {
	yield takeLatest(CREATE_BRAND_SPOTLIGHT, createBrandSpotlight);
	yield takeLatest(UPDATE_BRAND_SPOTLIGHT, updateBrandSpotlight);
}
