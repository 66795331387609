export const FETCH_CUSTOM_FIELDS = 'FETCH_CUSTOM_FIELDS';
export const FETCH_CUSTOM_FIELDS_SUCCESS = 'FETCH_CUSTOM_FIELDS_SUCCESS';
export const FETCH_CUSTOM_FIELDS_FAILURE = 'FETCH_CUSTOM_FIELDS_FAILURE';

export const FILTER_CUSTOM_FIELDS = 'FILTER_CUSTOM_FIELDS';
export const FILTER_CUSTOM_FIELDS_SUCCESS = 'FILTER_CUSTOM_FIELDS_SUCCESS';
export const FILTER_CUSTOM_FIELDS_FAILURE = 'FILTER_CUSTOM_FIELDS_FAILURE';

export const SET_CUSTOM_FIELDS_PAGE = 'SET_CUSTOM_FIELDS_PAGE';
export const CLEAR_DATA = 'CLEAR_DATA';
