import { createSelector } from 'reselect';

const selectCities = (state) => state.get('city');

const selectOperation = () => createSelector(
	selectCities,
	(cityState) => cityState.get('operation'),
);

const makeSelectFormData = () => createSelector(
	selectCities,
	(cityState) => cityState.get('formData'),
);

const makeSelectFormMessage = () => createSelector(
	selectCities,
	(cityState) => cityState.get('formMessage'),
);

const makeSelectGetOperation = () => createSelector(
	selectCities,
	(cityState) => cityState.get('operation'),
);

const makeSelectUniqueSlug = () => createSelector(
	selectCities,
	(cityState) => cityState.get('uniqueSlug'),
);

const makeSelectSelectedCity = () => createSelector(
	selectCities,
	(cityState) => cityState.get('selectedCity'),
);

const makeSelectLoading = () => createSelector(
	selectCities,
	(cityState) => cityState.get('loading'),
);

const makeSelectDropdownTitle = () => createSelector(
	selectCities,
	(cityState) => cityState.get('dropdownTitle'),
);

export {
	selectOperation,
	makeSelectFormData,
	makeSelectFormMessage,
	makeSelectGetOperation,
	makeSelectUniqueSlug,
	makeSelectSelectedCity,
	makeSelectLoading,
	makeSelectDropdownTitle,
};
