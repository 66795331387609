import { Map } from 'immutable';
import { FETCH_DASHBOARD_USER_BRANDS, FETCH_DASHBOARD_USER_BRANDS_SUCCESS, FETCH_DASHBOARD_USER_BRANDS_ERROR } from './type';

const initialState = Map({
	brands: null,
	loading: false,
	error: false,
});

function dashboardReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_DASHBOARD_USER_BRANDS:
		return state
			.set('loading', true)
			.set('error', false)
			.set('brands', null);
	case FETCH_DASHBOARD_USER_BRANDS_SUCCESS:
		return state
			.set('loading', false)
			.set('error', false)
			.set('brands', action.brands);
	case FETCH_DASHBOARD_USER_BRANDS_ERROR:
		return state
			.set('loading', false)
			.set('error', true)
			.set('brands', null);
	default:
		return state;
	}
}

export default dashboardReducer;
