import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { BRAND_API_BASE_URL, EVENT_API_BASE_URL } from './constants';
import {
	FETCH_USER_BRANDS, CREATE_EVENT, FETCH_EVENT_DATA, UPDATE_EVENT_BY_ID, DELETE_EVENT,
} from './type';
import {
	fetchBrandSuccess, createEventSuccess, createEventFailure, fetchEventFailure, fetchEventSuccess, updateEventFailure, deleteEventFailure, updateEventSuccess, fetchEventData,
} from './actions';

export function* getUserBrands() {
	const url = `${BRAND_API_BASE_URL}/userbrands`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(push('/unauthorized'));
		} else {
			const brands = yield response.json();
			yield put(fetchBrandSuccess(brands));
		}
	} catch (err) {
		throw new Error(err);
	}
}

export function* getEventData(action) {
	try {
		const url = `${EVENT_API_BASE_URL}/id/${action.eventId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			yield put(fetchEventSuccess(responseMessage));
		}
	} catch (err) {
		yield put(fetchEventFailure(err));
	}
}

export function* updateEvent(action) {
	try {
		const url = `${EVENT_API_BASE_URL}/update/id/${action.id}`;
		const options = {
			method: 'PUT',
			credentials: 'include',
			body: action.data,
		};
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(updateEventSuccess());
				yield put(fetchEventData(action.id));
			} else {
				yield put(updateEventFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(updateEventFailure(err));
	}
}

export function* createEvent(action) {
	const url = `${EVENT_API_BASE_URL}/`;
	const formData = action.data;
	const options = {
		method: 'POST',
		body: formData,
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(createEventSuccess(responseMessage));
				yield put(push(`/event/editor/${responseMessage.data.brand}/${responseMessage.data.id}`));
			} else {
				yield put(createEventFailure(responseMessage));
			}
		}
	} catch (err) {
		console.log(err);
		yield put(createEventFailure(err));
	}
}

export function* deletEvent(action) {
	const url = `${EVENT_API_BASE_URL}/${action.id}`;
	const options = {
		method: 'DELETE',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(push('/manager/events/list'));
			} else {
				yield put(deleteEventFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(deleteEventFailure(err));
	}
}

export default function* eventEditorSaga() {
	yield takeLatest(FETCH_USER_BRANDS, getUserBrands);
	yield takeLatest(CREATE_EVENT, createEvent);
	yield takeLatest(FETCH_EVENT_DATA, getEventData);
	yield takeLatest(UPDATE_EVENT_BY_ID, updateEvent);
	yield takeLatest(DELETE_EVENT, deletEvent);
}
