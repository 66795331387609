import styled from 'styled-components';

const Container = styled.div`
		margin-top: 40px!important;
`;

const SecondSection = styled.div`
		margin-left: 3rem;
		@media screen and (max-width: 768px) {
				margin-left: 0px!important;
				margin-top: 3rem!important;
		}
`;

export { Container, SecondSection };
