import React from 'react';
import PropTypes from 'prop-types';
import { SuccessMessage, FailureMessage } from './styles';

class ResponseMessage extends React.Component {
	chooseMessageType() {
		const { message, type, embedElement } = this.props;
		if (type === 'success') {
			return <SuccessMessage>{embedElement}{`	${message}`}</SuccessMessage>;
		} if (type === 'fail') {
			return <FailureMessage>{embedElement}{`	${message}`}</FailureMessage>;
		}
		return null;
	}

	render() {
		return (this.chooseMessageType());
	}
}

ResponseMessage.defaultProps = {
	embedElement: null,
};

ResponseMessage.propTypes = {
	message: PropTypes.string,
	type: PropTypes.string,
	embedElement: PropTypes.object,
};

export default ResponseMessage;
