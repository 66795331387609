import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	setLoadedState, setLoadingState, setErrorState, setResponseErrorState, setResponseData,
} from './actions';
import { GET_CURATED_DATA } from './type';

const CURATED_SET_LIST_SIZE = 7;

function addExtraObjectForEmptyPosition(curatedSetStoryArray) {
	const newCuratedSetStoryArray = new Array(CURATED_SET_LIST_SIZE).fill(0).map((value, idx) => {
		const foundObject = curatedSetStoryArray.find((storyObject) => ((idx + 1) === storyObject.position));
		if (foundObject) {
			return foundObject;
		}
		return {};
	});
	return newCuratedSetStoryArray;
}

function addExpiryDate(storyArray, scheduledStoryArray) {
	return storyArray.map((storyObject, idx) => {
		const newStoryObject = storyObject;
		if (scheduledStoryArray[idx] && ((typeof scheduledStoryArray[idx]) === 'object') && scheduledStoryArray[idx].expiresBy) {
			newStoryObject.expiresBy = scheduledStoryArray[idx].expiresBy;
			return newStoryObject;
		}
		return newStoryObject;
	});
}

function* fetchCuratedData(action) {
	try {
		const options = {
			credentials: 'include',
		};

		yield put(setLoadingState());
		const response = yield call(fetch, `/api/v2/rw/curatedset?brand=${action.brand.id}&key=${action.brand.key}`, options);

		if (response.status === 404) {
			yield put(setResponseErrorState('Requested curated set record not found'));
		} else if ((response.status === 401) || (response.status === 403)) {
			yield put(push('/unauthorized'));
		} else if (response.status === 200) {
			const responseJson = yield response.json();
			if (responseJson.status === 'success') {
				const newCuratedSetList = responseJson.curatedSetList.map((curatedSetObject) => {
					const newCuratedSetObject = curatedSetObject;
					newCuratedSetObject.value = addExtraObjectForEmptyPosition(newCuratedSetObject.value);
					newCuratedSetObject.scheduledValue = addExtraObjectForEmptyPosition(newCuratedSetObject.scheduledValue);
					newCuratedSetObject.value = addExpiryDate(newCuratedSetObject.value, newCuratedSetObject.scheduledValue);
					newCuratedSetObject.brandSlug = action.brand.slug;
					return newCuratedSetObject;
				});
				yield put(setResponseData(newCuratedSetList));
			} else if (responseJson.status === 'fail') {
				yield put(setResponseErrorState(responseJson.error.message));
			}
		} else {
			yield put(setResponseErrorState('Some error occurred'));
		}

		yield put(setLoadedState());
	} catch (err) {
		console.log('---- \n\nError in fetching curated set record', err);
		yield put(setErrorState('Some error occurred'));
	}
}

export default function* trackFeaturedSetDispatch() {
	yield takeLatest(GET_CURATED_DATA, fetchCuratedData);
}
