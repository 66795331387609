import { Map } from 'immutable';
import {
	ON_IMAGE_EDITOR_FORM_DATA_CHANGE, CLEAR_IMAGE_EDITOR_FORM_DATA, SET_IMAGE_EDITOR_FORM_MESSAGE, FETCH_IMAGE_VERSIONS_SUCCESS, FETCH_IMAGE_VERSIONS, FETCH_IMAGE_VERSIONS_FAILURE, FETCH_IMAGE_BY_ID, FETCH_IMAGE_BY_ID_SUCCESS, FETCH_IMAGE_BY_ID_FAILURE, CHANGE_VIEW,
} from './type';

const initialState = Map({
	imageFormData: {
		title: null,
		tags: null,
		caption: null,
		imageUrl: null,
		height: null,
		width: null,
		parallax: false,
		link: null,
	},
	loading: false,
	error: false,
	imageVersions: null,
	selectedImage: null,
	formMessage: null,
	editImage: null,
	currentView: 'gallery',
});

function editorImageHandlerReducer(state = initialState, action) {
	switch (action.type) {
	case ON_IMAGE_EDITOR_FORM_DATA_CHANGE: {
		const imageFormData = { ...state.get('imageFormData') };
		const { changedData } = action;
		let selectedImage = { ...state.get('selectedImage') };
		if (['uploadedImage', 'selectedImage', 'croppedImage'].includes(changedData.name)) {
			imageFormData.imageUrl = changedData.imageUrl;
			imageFormData.height = changedData.height;
			imageFormData.width = changedData.width;
			if (changedData.name === 'selectedImage') {
				imageFormData.title = changedData.title;
				imageFormData.caption = changedData.caption;
				imageFormData.tags = changedData.tags;
				selectedImage = { ...changedData.selectedImage };
			}
		} else {
			imageFormData[changedData.name] = changedData.value;
		}
		return state
			.set('imageFormData', imageFormData)
			.set('selectedImage', selectedImage);
	}
	case SET_IMAGE_EDITOR_FORM_MESSAGE:
		return state
			.set('formMessage', action.message);
	case CLEAR_IMAGE_EDITOR_FORM_DATA:
		return initialState;
	case FETCH_IMAGE_VERSIONS:
		return state
			.set('loading', true);
	case FETCH_IMAGE_VERSIONS_SUCCESS:
		return state
			.set('loading', false)
			.set('imageVersions', action.imageVersions);
	case FETCH_IMAGE_VERSIONS_FAILURE:
		return state
			.set('loading', false);
	case FETCH_IMAGE_BY_ID:
		return state
			.set('loading', true);
	case FETCH_IMAGE_BY_ID_SUCCESS: {
		const { asset } = action;
		const { selectedImage } = action;
		const imageFormData = {
			title: selectedImage.title,
			tags: asset.tags,
			caption: selectedImage.caption,
			imageUrl: asset.slug,
			height: asset.height,
			width: asset.width,
			parallax: false,
		};
		return state
			.set('imageFormData', imageFormData)
			.set('loading', false)
			.set('currentView', 'finalizeImage');
	}
	case FETCH_IMAGE_BY_ID_FAILURE:
		return state
			.set('loading', false);
	case CHANGE_VIEW:
		return state
			.set('currentView', action.view);
	default:
		return state;
	}
}

export default editorImageHandlerReducer;
