import {
	CHANGE_PROMOTION_PAYLOAD, SEND_PROMOTION, SEND_PROMOTION_SUCCESS, SEND_PROMOTION_ERROR, UPDATE_DISPLAY_MESSAGE, CREATE_PROMOTION, CREATE_PROMOTION_SUCCESS, CREATE_PROMOTION_ERROR, LOAD_CHATBOTS, LOAD_CHATBOTS_SUCCESS, LOAD_CHATBOTS_ERROR, SET_STORY_SEARCH_SELECT, CLEAR_STORY_SEARCH_DATA, SET_STORY_SEARCH_LIST_END, SET_STORY_SEARCH_OFFSET, SET_STORY_SEARCH_RESULTS, SET_STORY_SEARCH_TITLE, SET_STORIES_LOADING, SET_STORIES_LOADED,
} from './type';

export function changePromotionPayload(itemName, itemValue) {
	return {
		type: CHANGE_PROMOTION_PAYLOAD,
		itemName,
		itemValue,
	};
}

export function sendPromotion(payload) {
	return {
		type: SEND_PROMOTION,
		payload,
	};
}

export function sendPromotionSuccess(response) {
	return {
		type: SEND_PROMOTION_SUCCESS,
		response,
	};
}

export function sendPromotionError(response) {
	return {
		type: SEND_PROMOTION_ERROR,
		response,
	};
}

export function updateDisplayMessage(message) {
	return {
		type: UPDATE_DISPLAY_MESSAGE,
		message,
	};
}

export function createPromotion(promotionForm) {
	return {
		type: CREATE_PROMOTION,
		promotionForm,
	};
}

export function createPromotionSuccess(response) {
	return {
		type: CREATE_PROMOTION_SUCCESS,
		response,
	};
}

export function createPromotionError(response) {
	return {
		type: CREATE_PROMOTION_ERROR,
		response,
	};
}

export function loadChatbots() {
	return {
		type: LOAD_CHATBOTS,
	};
}

export function loadChatbotsSuccess(result) {
	return {
		type: LOAD_CHATBOTS_SUCCESS,
		result,
	};
}

export function loadChatbotsError(error) {
	return {
		type: LOAD_CHATBOTS_ERROR,
		error,
	};
}

export function setStorySearchTitle(title) {
	return {
		type: SET_STORY_SEARCH_TITLE,
		title,
	};
}

export function setStorySearchResults(storySearchResults) {
	return {
		type: SET_STORY_SEARCH_RESULTS,
		storySearchResults,
	};
}

export function setStorySearchOffset(offset) {
	return {
		type: SET_STORY_SEARCH_OFFSET,
		offset,
	};
}

export function setStorySearchListEnd(value) {
	return {
		type: SET_STORY_SEARCH_LIST_END,
		value,
	};
}

export function setStorySearchSelect(position) {
	return {
		type: SET_STORY_SEARCH_SELECT,
		position,
	};
}

export function clearStorySearchData() {
	return {
		type: CLEAR_STORY_SEARCH_DATA,
	};
}

export function setStoriesLoading() {
	return {
		type: SET_STORIES_LOADING,
	};
}

export function setStoriesLoaded() {
	return {
		type: SET_STORIES_LOADED,
	};
}
