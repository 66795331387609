import {
	LOAD_USER, LOAD_USER_SUCCESS, LOAD_USER_ERROR, INVITE_USER, INVITE_USER_SUCCESS, INVITE_USER_ERROR, UPDATE_DATA, CLEAR_INVITE_USER_DATA,
} from './type';

export function loadUser() {
	return {
		type: LOAD_USER,
	};
}

export function userLoadedSuccessfully(user) {
	return {
		type: LOAD_USER_SUCCESS,
		user,
	};
}

export function userLoadingError(error) {
	return {
		type: LOAD_USER_ERROR,
		error,
	};
}

export function inviteUser(formData) {
	return {
		type: INVITE_USER,
		formData,
	};
}

export function inviteUserSuccessfully(message) {
	return {
		type: INVITE_USER_SUCCESS,
		message,
	};
}

export function inviteUserError(error) {
	return {
		type: INVITE_USER_ERROR,
		error,
	};
}

export function updateData(name, value) {
	return {
		type: UPDATE_DATA,
		name,
		value,
	};
}

export function setInitialState() {
	return {
		type: CLEAR_INVITE_USER_DATA,
	};
}
