import { AWARD_API_BASE_URL } from '../containers/AwardManager/constants';
import { VERTICAL_API_BASE_URL } from '../containers/VerticalManager/constants';
import { BRAND_SPOTLIGHT_API_BASE_URL } from '../containers/BrandSpotlightManager/constants';
import { CATEGORY_API_BASE_URL } from '../containers/CategoryManager/constants';
import { TAG_API_BASE_URL } from '../containers/TagManager/constants';
import { CITY_API_BASE_URL } from '../containers/CityManager/constants';
import { COMPANY_API_BASE_URL } from '../containers/CompanyEditor/constants';
import history from './history';

export async function isSlugUnique(slug, hoc, selectedItem, isUnique, locale) {
	try {
		let url = '';
		switch (hoc) {
		case 'award':
			url = `${AWARD_API_BASE_URL}/slug/${slug}`;
			break;
		case 'vertical':
			url = `${VERTICAL_API_BASE_URL}/slug/${slug}`;
			break;
		case 'brandSpotlight':
			url = `${BRAND_SPOTLIGHT_API_BASE_URL}/slug/${slug}`;
			break;
		case 'category':
			url = `${CATEGORY_API_BASE_URL}/slug/${locale}/${slug}`;
			break;
		case 'tag':
			url = `${TAG_API_BASE_URL}/slug/${locale}/${slug}`;
			break;
		case 'city':
			url = `${CITY_API_BASE_URL}/slug/${slug}`;
			break;
		default:
			url = '';
			break;
		}
		const options = {
			method: 'GET',
			credentials: 'include',
		};

		const apiResponse = await fetch(url, options);
		if (apiResponse.status === 401 || apiResponse.status === 403) {
			history.replace('/unauthorized');
		} else {
			const slugResponse = await apiResponse.json();
			if (slugResponse && Object.keys(slugResponse).length > 0) {
				if (selectedItem && selectedItem.slug === slug) {
					isUnique(true);
				} else {
					isUnique(false);
				}
			} else {
				isUnique(true);
			}
		}
	} catch (err) {
		console.log('Slug unique error: ', err);
	}
}

export const getDropdownOptions = async (hoc, key, params = null) => {
	if (/[/\\]/.test(key) || key.length < 3) return [];
	try {
		let url;
		switch (hoc) {
		case 'award':
			url = `${AWARD_API_BASE_URL}/key/editor/${key}`;
			break;
		case 'company':
			url = `${COMPANY_API_BASE_URL}/key/${key}${params ? `?source=${params}` : ''}`;
			break;
		case 'vertical':
			url = `${VERTICAL_API_BASE_URL}/key/${key}`;
			break;
		case 'location':
			url = `${CITY_API_BASE_URL}/key/${key}${params ? `?source=${params}` : ''}`;
			break;
		case 'people':
			url = `/api/v2/rw/influencer-profiles/key/${key}${params ? `?source=${params}` : ''}`;
			break;
		case 'revenue':
			url = `${COMPANY_API_BASE_URL}/revenue-stream/key/${key}`;
			break;
		case 'employee-size':
			url = '/api/v2/rw/curated_list/employee-size';
			break;
		case 'editors':
			// params -> brand
			url = `/api/v2/rw/authors/editor/${params}/key/${key}`;
			break;
		case 'brand-spotlight':
			url = `${BRAND_SPOTLIGHT_API_BASE_URL}/key/${key}`;
			break;
		case 'series':
			url = `/api/v2/rw/series/title/${key}`;
			break;
		case 'city':
			url = `/api/v2/rw/cities/key/${key}${params ? `?source=${params}` : ''}`;
			break;
		case 'user-email':
			url = `/api/v2/rw/user-manager/profile/data/1/?criteriaType=EMAIL&criteriaValue=${encodeURIComponent(key)}&source=dropdown${params ? `&hasStripeSub=${params.hasStripeSub}` : ''}`;
			break;
		case 'user-email-valid-stripe':
			url = `/api/v2/rw/user-manager/profile/data/1/?criteriaType=EMAIL&criteriaValue=${encodeURIComponent(key)}&source=dropdown&hasStripeSub=${params && params.hasStripeSub}`;
			break;
		case 'author-meta-users':
			url = `/api/v2/rw/authors/all?email=${encodeURIComponent(key)}`;
			break;
		case 'org-chart':
			url = `/api/v2/rw/org-chart/key/${key}`;
			break;
		case 'bod-org-chart':
			url = `/api/v3/org-chart?id=${key}`;
			break;
		case 'org-chart-preview':
			url = `/api/v3/people-profile?id=${key}`;
			break;
		case 'brand-categories':
			url = `/api/v2/rw/boni/brand-categories/key/${key}`;
			break;
		case 'brand-products':
			url = `/api/v2/rw/brand-products/key/${key}${params ? `?brand=${params}` : ''}`;
			break;
		case 'company-brand':
			url = `/api/v2/rw/company-brands/key/${key}`;
			break;
		case 'product-category':
			url = `/api/v2/rw/product-categories?limit=10&skip=0&name=${key}`;
			break;
		case 'boni-companyBrand':
			url = `/api/v2/rw/company-brands?limit=10&skip=0&name=${key}`;
			break;
		case 'boni-brandCategory':
			url = `/api/v2/rw/brandCategories?limit=10&skip=0&name=${key}&status=ACTIVE`;
			break;
			// case 'boni-productCategory':
			// 	url = `/api/v2/rw/productCategories?limit=10&skip=0&name=${key}`;
			// 	break;
		case 'primary-job-function':
			if (params) url = `/api/v2/job_curated_list/type/JOB-FUNCTION?typeFilter[]=${params}`;
			else url = '/api/v2/job_curated_list/type/JOB-FUNCTION';
			break;
		case 'secondary-job-function':
			if (params) url = `/api/v2/job_curated_list/type/SUB-JOB-FUNCTION?typeFilter[]=${params}`;
			else url = '/api/v2/job_curated_list/type/SUB-JOB-FUNCTION';
			break;
		case 'job-industry':
			url = '/api/v2/job_curated_list/type/INDUSTRY';
			break;
		case 'notice-period':
			url = '/api/v2/job_curated_list/type/NOTICE_PERIOD';
			break;
		case 'pref-cities-from-india':
			// url = '/api/v2/cities/india';
			url = '/api/v2/job_curated_list/type/TALENT-CITIES';
			break;
		case 'education-type':
			url = '/api/v2/job_curated_list/type/EDUCATION-TYPE';
			break;
		case 'education-degree-type':
			url = '/api/v2/job_curated_list/type/EDUCATION-DEGREE';
			break;
		case 'cities':
			url = '/api/v2/job_curated_list/type/TALENT-CITIES';
			break;
		case 'also-read':
			url = '/api/v2/dynamic_configurations_object?namespace=Story-AlsoRead';
			break;
		case 'tech-sparks-agend-speacker':
			url = `/api/v2/rw/curated/config/techsparks_viewall_speakers?brand=2&name=${key}`;
			break;
		case 'profile-bio-the-captable':
			url = `/api/v2/rw/categories/key/the-captable/${key}`;
			break;
		default:
			url = '';
			break;
		}
		const response = await fetch(url, {
			credentials: 'include',
		});
		let options = await response.json();
		if (hoc === 'product-category' || hoc === 'boni-companyBrand' || hoc === 'boni-brandCategory') {
			const newOpt = options.data.rows.map((value) => (
				{
					id: value.id,
					value: value.id,
					name: value.name,
					label: value.name,
				}
			));
			return newOpt;
		}
		if (hoc === 'cities') {
			options.push({
				id: 'others',
				label: 'Others',
				name: 'others',
				slug: 'others',
				value: 'others',
			});
		}
		if (hoc === 'pref-cities-from-india') {
			const extraList = [{
				id: 'I want "Work from home" jobs only',
				label: 'I want "Work from home" jobs only',
				name: 'I want "Work from home" jobs only',
				slug: 'I want "Work from home" jobs only',
				value: 'I want "Work from home" jobs only',
			}, {
				id: "I'm ok with any city",
				label: "I'm ok with any city",
				name: "I'm ok with any city",
				slug: "I'm ok with any city",
				value: "I'm ok with any city",
			}];
			options = [...options, ...extraList];
		}
		if (hoc === 'tech-sparks-agend-speacker') {
			const newOpt = options.map((speaker) => (
				{
					...speaker,
					value: speaker.id,
					label: speaker.name,
				}
			));
			return newOpt;
		}
		return options;
	} catch (err) {
		console.log('Fetch by key error: ', err);
	}
};

export function validateImage(height, width) {
	if (height !== width) {
		return alert(`Logo should be square! Current image is ${width}x${height}`);
	}
	if (height < 300 || width < 300) {
		return alert(`Logo should have a least resolution of 300x300. Current image is ${width}x${height}`);
	}
	return true;
}

export function validateImageFormat(file, validTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']) {
	// Relevent changes made in this function should be made in PWA/MyAccount
	return new Promise((resolve) => {
		try {
			let type;
			const reader = new FileReader();
			reader.onloadend = (e) => {
				const arr = (new Uint8Array(e.target.result)).subarray(0, 4);
				let header = '';
				for (let i = 0; i < arr.length; i++) {
					header += arr[i].toString(16);
				}

				switch (header.toLowerCase()) {
				case '89504e47':
					type = 'image/png';
					break;
				case '47494638':
					type = 'image/gif';
					break;
				case 'ffd8ffdb':
				case 'ffd8ffe0':
				case 'ffd8ffe1':
				case 'ffd8ffe2':
				case 'ffd8ffe3':
				case 'ffd8ffe8':
				case 'ffd8ffee':
				case '00028':
					type = 'image/jpeg';
					break;
				default:
					break;
				}

				if (!validTypes.includes(type)) {
					alert('Only .png, .jpeg, .jpg and .gif image files are supported');
					resolve(false);
				} else {
					resolve(true);
				}
			};
			reader.readAsArrayBuffer(file);
		} catch (err) {
			alert('Something went wrong, try later!');
			resolve(false);
			console.log('Error validating image', err);
		}
	});
}

export function managerialRoles(roles) {
	const userRoles = {
		config: roles.configManager && roles.configManager === 1,
		redirection: roles.redirectionManager && roles.redirectionManager === 1,
		notifications: roles.notificationsManager && roles.notificationsManager === 1,
		spotlight: roles.brandSpotlightManager && roles.brandSpotlightManager === 1,
		seriesManager: roles.seriesManager && roles.seriesManager === 1,
		companyManager: roles.companyManager && roles.companyManager >= 1,
		webinarManager: roles.webinarManager && roles.webinarManager === 1,
		jobsManager: roles.jobsManager && roles.jobsManager === 1,
		eventManager: roles.eventManager && roles.eventManager === 1,
		communityContentManager: roles.communityContentManager && roles.communityContentManager === 1,
		broadcastManager: roles.broadcastManager && roles.broadcastManager === 1,
		subscriptionManager: roles.subscriptionManager && roles.subscriptionManager === 1,
		orgChartManager: roles.orgChartManager && roles.orgChartManager === 1,
		brandsOfNewIndiaManager: roles.brandsOfNewIndiaManager && roles.brandsOfNewIndiaManager === 1,
		ysJobsManager: roles.ysJobsManager && roles.ysJobsManager === 1,
		adRefreshManager: roles.adRefreshManager && roles.adRefreshManager === 1,
		techSparksManager: roles.techSparksManager && roles.techSparksManager === 1,
		webStoryManager: roles.webStoryManager && roles.webStoryManager === 1,
		liveFeedCardManager: roles.liveFeedCardManager && roles.liveFeedCardManager >= 1,
		listingsCardManager: roles.listingsCardManager && roles.listingsCardManager >= 1,
		topicManager: roles.topicManager && roles.topicManager >= 1,
	};
	return userRoles;
}

export function validateImageDimension(image, minWidth, minHeight) {
	return image.naturalWidth >= minWidth && image.naturalHeight >= minHeight;
}

export function validateImageResolution(image, maxWidth, maxHeight) {
	// if (minHeight !== minWidth) {
	// 	return alert(`An image should be square! Current image is ${image.naturalWidth}x${image.naturalHeight}`);
	// }
	if (!(image.naturalWidth === maxWidth && image.naturalHeight === maxHeight)) {
		return alert(`Image is under required dimension of ${maxWidth}x${maxHeight}. Current image is ${image.naturalWidth}x${image.naturalHeight}`);
	}
	return true;
}
export const extractString = (stringText) => {
	const resultStr = stringText.replace(/bod-org-chart-|org-chart-/g, '');
	return resultStr;
};

export const removeDuplicateString = (stringText, addText, caseVal) => {
	let str1 = stringText.replace(/bod-org-chart-|org-chart-/g, '');
	caseVal === 1 ? str1 = `${addText}${str1}` : '';
	return str1;
};

export const getOrgChartNodesData = async (hoc, key) => {
	if (/[/\\]/.test(key) || key.length < 3) return [];
	try {
		let url;
		switch (hoc) {
		case 'org-chart':
			url = `/api/v2/rw/org-chart/key/${key}`;
			break;
		case 'bod-org-chart':
			url = `/api/v3/org-chart?id=${key}`;
			break;
		case 'org-chart-preview':
			url = `/api/v3/people-profile?id=${key}`;
			break;
		default:
			url = '';
			break;
		}
		const response = await fetch(url, {
			credentials: 'include',
		});
		const options = await response.json();
		return options;
	} catch (err) {
		console.log('Fetch by key error: ', err);
	}
};

export const jobProfileContactInfoValidation = (text, caseValue) => {
	/* eslint-disable no-useless-escape */
	const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	/* eslint-disable no-useless-escape */
	const phoneNumberRegex = /^(0|\+91)?[789]\d{9,10}$/;
	// ('(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}');
	/* eslint-disable no-useless-escape */
	const linkedInRegex = /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;
	// /^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/;
	/* eslint-disable no-useless-escape */
	const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;
	/* eslint-disable no-useless-escape */
	const salaryRegex = /^(?!0+(?:\.0+)?$)[0-9]+(?:[0-9]+)?$/;
	// /^[1-9][0-9]*(\.[0-9])?$/; toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")
	/* eslint-disable no-useless-escape */
	const workExperienceYearsRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
	// const urlRegex = new RegExp('(https:\/\/www\.|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?');
	switch (caseValue) {
	case 1:
		return emailRegex.test(text);
	case 2:
		return phoneNumberRegex.test(text);
	case 3:
		return urlRegex.test(text);
	case 4:
		return linkedInRegex.test(text);
		/* eslint-disable no-useless-escape */
	case 5:
		return salaryRegex.test(text.toString().replace(/,/g, ''));
	case 6:
		return workExperienceYearsRegex.test(text);
	default: return false;
	}
};

export const filterProfileData = (raw) => {
	// 'workHistory', 'educationDetails', 'status'
	const allowed = ['alreadyConnected', 'createdAt', 'id', 'updatedAt', 'userId', 'rejectReason', 'resumeLink', 'resumeParserRaw'];

	const result = Object.keys(raw)
		.filter((key) => !allowed.includes(key))
		.reduce((obj, key) => ({
			...obj,
			[key]: raw[key],
		}), {});
	// await Object.keys(raw)
	// .filter(key => !allowed.includes(key))
	// .reduce((obj, key) => {
	// 	return {
	// 		...obj,
	// 		[key]: raw[key]
	// 	};
	// });
	return result;
};

export const consoleLogss = () => {
	console.log('one');
};
export const filterAsyncSelectOptions = (mainArray, searchString) => mainArray.filter((item) => item.label.toLowerCase().includes(searchString.toLowerCase()));
export async function filterAsyncSelectOptionsTrue(searchString, hoc, key, params) {
	const result = await getDropdownOptions(hoc, hoc, params);
	if (result && result?.length > 0 && !searchString) {
		return result ?? [];
	} if (result && result?.length > 0 && searchString) {
		return filterAsyncSelectOptions(result, searchString);
	}
}

export async function filterPrefCitiesOptions(searchString, hoc, key, params) {
	const result = await getDropdownOptions(hoc, hoc, params);
	console.log('result', result);
	if (result && result?.length > 0 && !searchString) {
		const resultantArray = [];
		result.forEach((element) => {
			resultantArray.push({
				...element,
				label: element.name,
				value: element.id,
			});
		});
		return resultantArray ?? [];
	} if (result && result?.length > 0 && searchString) {
		const resultantArray = [];
		result.forEach((element) => {
			resultantArray.push({
				...element,
				label: element.name,
				value: element.id,
			});
		});
		return filterAsyncSelectOptions(resultantArray, searchString);
	}
}
export const filterAsyncMultiSelectOptions = (mainArray, responseArray) => {
	let result = [];
	responseArray.forEach((element) => {
		result = mainArray.filter((item) => item.name === element);
	});
	return result;
};

export const removeDuplicatesFromAsyncMultiSelect = (arrayData) => {
	if (arrayData && arrayData.length > 0) {
		const result = arrayData.reduce((unique, o) => {
			if (!unique.some((obj) => obj.label === o.label)) {
				unique.push(o);
			}
			return unique;
		}, []);
		return result;
	}
	return [];
};

export const returnOnlyValueIncludedArray = (arrayData) => {
	const result = [];
	if (arrayData && arrayData?.length > 0) {
		arrayData.forEach((element) => {
			if (element?.name && element?.name?.length > 0) result.push(element.name);
			else if (element?.value && element?.value?.length > 0) result.push(element.value);
		});
	}
	return result;
};

export const returnAsyncFilterValue = (responseArray) => {
	const resultantArray = [];
	responseArray.forEach((element) => {
		resultantArray.push({
			name: element,
			label: element,
			value: element,
		});
	});
	return resultantArray;
};

export const reArrangeTheList = (caseVal, arrayData, formData) => {
	const finalArrangeResult = [...arrayData];
	let index = -1;
	const currentFormData = { ...formData };
	let swapFormData = {};
	if (caseVal === 1 && finalArrangeResult?.length > 1 && currentFormData?.newOrder) {
		if (arrayData?.length > 1) {
			index = arrayData.findIndex((item) => item.order === currentFormData.order);
			index > -1 ? swapFormData = { ...arrayData[index] } : '';
			if (index > -1) {
				finalArrangeResult[index] = {
					...currentFormData,
					order: currentFormData.order,
					previousOrder: finalArrangeResult?.length,
					newOrder: '',
				};
				finalArrangeResult[finalArrangeResult?.length - 1] = {
					...swapFormData,
					order: finalArrangeResult?.length,
					previousOrder: currentFormData.order,
					newOrder: '',
				};
			}
		}
	} else if (caseVal === 2 && finalArrangeResult?.length > 1) {
		// finalArrangeResult[index] = finalArrangeResult.splice(index + 1, 1, finalArrangeResult[index])[0];
		const swapIndex = arrayData.findIndex((item) => item.order === currentFormData.previousOrder);
		index = arrayData.findIndex((item) => item.order === currentFormData.newOrder);
		index > -1 ? swapFormData = { ...arrayData[index] } : '';
		index > -1 ? finalArrangeResult[index] = {
			...currentFormData,
			order: currentFormData.newOrder,
			newOrder: '',
			previousOrder: currentFormData.order,
		} : '';
		swapIndex > -1 ? finalArrangeResult[swapIndex] = {
			...swapFormData,
			order: currentFormData.previousOrder,
			previousOrder: swapFormData.order,
			newOrder: '',
		} : '';
	}
	return [...finalArrangeResult];
};
export const isRoleAboveGivenValue = (roles, value) => {
	const keyArray = Object.keys(roles);
	let isRoleAbove = false;
	keyArray.forEach((ele) => {
		if (roles[ele] > value) {
			isRoleAbove = true;
		}
	});
	return isRoleAbove;
};

export const extractedCityName = (value) => {
	const resArray = value && value.includes('_') ? value.split('_') : '';
	if (resArray && resArray?.length > 2) { return `${resArray[2]}_`; }
	return '';
};
