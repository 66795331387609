import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import { ListItemWrapper } from '../../components/CompanyEditorComponents/styles';

const ListItem = (props) => {
	const { CPItem, onItemSelectAction, logType } = props;
	const {
		investee, investor, individual_investors, source, source_url, date, status, amount, funding_round,
		participant,
	} = CPItem;
	const formattedDate = new Date(date);
	return (
		<ListItemWrapper style={{ minHeight: '3rem' }}>
			<Col sm={1} xs={4}>
				<span>{formattedDate.toLocaleDateString('en-IN')}</span>
			</Col>
			{logType !== 'merger' ? <Col sm={2} xs={4}>
				<span>{investee}</span>
			</Col> : null}
			{logType !== 'merger' ? <Col sm={2} xs={4}>
				<span>{investor}</span>
			</Col> : null}
			{logType === 'funding'
				? <Col sm={2} xs={4}>
					<span>{individual_investors}</span>
				</Col> : null}
			{logType === 'merger'
				? <Col sm={2} xs={4}>
					<span>{participant}</span>
				</Col> : null}
			{logType === 'funding' || logType === 'acquisition'
				? <Col sm={1} xs={4}>
					<span>{amount}</span>
				</Col> : null}
			<Col sm={1} xs={4}>
				<span><a href={source_url}>{source}</a></span>
			</Col>
			{logType === 'funding' ? <Col sm={1} xs={4}>
				<span>{funding_round}</span>
			</Col> : null}
			<Col xs={1} sm={1} md={2}>
				<select className="global-form-control" value={status} onChange={(e) => onItemSelectAction(e, CPItem)}>
					<option value="UNUSED">UNUSED</option>
					<option value="USED">USED</option>
				</select>
			</Col>
		</ListItemWrapper>
	);
};

ListItem.propTypes = {
	onItemSelectAction: T.func,
	CPItem: T.object,
	investee: T.string,
	investor: T.string,
	individual_investors: T.string,
	source_url: T.string,
	source: T.string,
	date: T.string,
	logType: T.string,
};

export default ListItem;
