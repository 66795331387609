import {
	FETCH_CP_ITEMS,
	FETCH_CP_ITEMS_SUCCESS,
	FETCH_CP_ITEMS_FAILURE,
	FETCH_NEXT_PAGE,
	DISABLE_PROFILE_LIST_SEARCH,
	RUN_CP_LIST_SEARCH,
	RUN_CP_LIST_SEARCH_SUCCESS,
	RUN_CP_LIST_SEARCH_FAILURE,
	RUN_CP_LIST_SORT,
	DISABLE_PROFILE_LIST_SEARCH_FLAG,
	CLEAR_PROFILE_DESK_DATA,
	UPDATE_CP_ITEM_STATUS,
	UPDATE_CP_ITEM_STATUS_SUCCESS,
	UPDATE_CP_ITEM_STATUS_FAILURE,
} from './type';

export function fetchCPItems(limit, offset, sortBy = 'createdAt', orderBy = 'DESC', searchValue = null, profileType, logType) {
	return {
		type: FETCH_CP_ITEMS,
		limit,
		offset,
		sortBy,
		orderBy,
		searchValue,
		profileType,
		logType,
	};
}

export function fetchCPItemsSuccess(CPItems) {
	return {
		type: FETCH_CP_ITEMS_SUCCESS,
		CPItems,
	};
}

export function fetchCPItemsFailure(error) {
	return {
		type: FETCH_CP_ITEMS_FAILURE,
		error,
	};
}

export function updateCPItemStatus(newStatus, CPItem, logType) {
	return {
		type: UPDATE_CP_ITEM_STATUS,
		newStatus,
		CPItem,
		logType,
	};
}

export function updateCPItemStatusSuccess(responseMessage, id, newStatus) {
	return {
		type: UPDATE_CP_ITEM_STATUS_SUCCESS,
		id,
		newStatus,
		responseMessage,
	};
}

export function updateCPItemStatusFailure(error) {
	return {
		type: UPDATE_CP_ITEM_STATUS_FAILURE,
		error,
	};
}

export function fetchNextPage(limit) {
	return {
		type: FETCH_NEXT_PAGE,
		limit,
	};
}

export function disableListSearchFlag() {
	return {
		type: DISABLE_PROFILE_LIST_SEARCH_FLAG,
	};
}

export function disableListSearch() {
	return {
		type: DISABLE_PROFILE_LIST_SEARCH,
	};
}

export function runCPListSearch(searchValue, filterMonth, filterYear, limit, offset, logType) {
	return {
		type: RUN_CP_LIST_SEARCH,
		searchValue,
		filterMonth,
		filterYear,
		limit,
		offset,
		logType,
	};
}

export function runCPListSearchSuccess(searchResults) {
	return {
		type: RUN_CP_LIST_SEARCH_SUCCESS,
		searchResults,
	};
}

export function runCPListSearchFailure(error) {
	return {
		type: RUN_CP_LIST_SEARCH_FAILURE,
		error,
	};
}

export function runCPListSort(sortBy) {
	return {
		type: RUN_CP_LIST_SORT,
		sortBy,
	};
}

export function setInitialState() {
	return {
		type: CLEAR_PROFILE_DESK_DATA,
	};
}
