import React, {
	useEffect, useState, useReducer, Fragment,
} from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import 'rc-pagination/assets/index.css';
import T from 'prop-types';
import {
	Input, Button,
} from '../../../components/Styles';
// import ToastMessage from '../../../components/ToastMessage';
import { AsyncMultiSelect, AsyncSingleSelectWithDefault, TitleWithTooltip } from '../../../components/FormComponents';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import LoadingIcon from '../../../components/LoadingIcon/LoadingIcon';
import {
	GET_PROFILE_DATA,
	FETCH_PROFILE_DATA,
	FETCH_PROFILE_DATA_SUCCESS,
	FETCH_PROFILE_DATA_FAILURE,
	UPDATE_TALENT_BASE_URL,
	SET_ALL_ELEMENTS_BLURRED,
	UPDATE_ERRORS,
	UPDATE_BLURRED_ELEMENT,
	UPDATE_ACTIONS_ERROR,
	UPDATE_ACTIONS_SUCCESS,
	UPDATE_ACTIONS_LOADER,
	SET_ACTIONS_MESSAGE,
} from '../constants';
import {
	GET_EDUCATION_TYPE,
	GET_EDUCATION_DEGREE_TYPE,
} from './constants';
import {
	filterAsyncSelectOptions, returnOnlyValueIncludedArray, removeDuplicatesFromAsyncMultiSelect, filterProfileData,
} from '../../../utils/common';
import EducationHistoryListPage from '../EducationHistorylist';

const initialState = {
	cityList: [],
	profileJobData: {},
	loading: false,
	error: false,
	actionLoading: false,
	actionError: false,
	actionMessage: '',
	blurredElements: {
		degree: false,
		lastGraduationInstitute: false,
		highestEductionType: false,
	},
	errors: {
		degree: false,
		lastGraduationInstitute: false,
		highestEductionType: false,
	},
};

function setActionsMessage(message) {
	return {
		type: SET_ACTIONS_MESSAGE,
		message,
	};
}
function updateActionsLoading() {
	return {
		type: UPDATE_ACTIONS_LOADER,
	};
}

function updateActionsFailure() {
	return {
		type: UPDATE_ACTIONS_ERROR,
	};
}

function updateActionsSuccess() {
	return {
		type: UPDATE_ACTIONS_SUCCESS,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function fetchProfileJobData() {
	return {
		type: FETCH_PROFILE_DATA,
	};
}
function fetchProfileJobDataSuccess(profileJobData) {
	return {
		type: FETCH_PROFILE_DATA_SUCCESS,
		profileJobData,
	};
}
function fetchProfileJobDataFailure() {
	return {
		type: FETCH_PROFILE_DATA_FAILURE,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case FETCH_PROFILE_DATA:
		return { ...state, loading: true };
	case FETCH_PROFILE_DATA_SUCCESS:
		return {
			...state, loading: false, profileJobData: { ...action.profileJobData },
		};
	case FETCH_PROFILE_DATA_FAILURE:
		return {
			...state, loading: false, error: true, profileJobData: {},
		};
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };
	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			degree: true,
			lastGraduationInstitute: true,
			highestEductionType: true,
		};
		return { ...state, blurredElements };
	}
	case UPDATE_ACTIONS_ERROR:
		return {
			...state, actionLoading: false, actionError: true,
		};
	case UPDATE_ACTIONS_SUCCESS:
		return {
			...state, actionLoading: false, actionError: false,
		};
	case UPDATE_ACTIONS_LOADER:
		return {
			...state, actionLoading: true, actionError: false,
		};
	case SET_ACTIONS_MESSAGE:
		return {
			...state, actionMessage: action.message,
		};
	default:
		return state;
	}
}

const EducationHistoryProfilePage = (props) => {
	const { match, history } = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		loading, errors, blurredElements, actionLoading, actionError, actionMessage, profileJobData,
	} = state;
	const [educationTypeList, setEducationTypeList] = useState([]);
	const [degreeTypeList, setDegreeTypeList] = useState([]);
	const [profileData, setProfileData] = useState({
		degree: [],
		lastGraduationInstitute: '',
		highestEductionType: {},
	});

	useEffect(() => {
		dispatch(updateErrors({
			degree: false,
			lastGraduationInstitute: false,
			highestEductionType: false,
		}));
		dispatch(updateBlurredElement({
			degree: false,
			lastGraduationInstitute: false,
			highestEductionType: false,
		}));
		dispatch(setAllElementsBlurred());
		if (match?.params?.profileId) {
			getEducationTypeList();
			getEducationDegreeTypeList();
			getProfileData(match.params.profileId);
		}
	}, []);

	async function getEducationDegreeTypeList() {
		const url = `${GET_EDUCATION_DEGREE_TYPE}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
				return [];
			} if (response.status === 200) {
				const finalResult = await response.json();
				if (finalResult && finalResult?.length > 0) {
					setDegreeTypeList([...finalResult]);
					return finalResult;
				}
			} else {
				setDegreeTypeList([]);
			}
		} catch (err) {
			setDegreeTypeList([]);
		}
	}

	const returnAsyncFilterValue = (responseArray) => {
		const resultantArray = [];
		responseArray.forEach((element) => {
			resultantArray.push({
				name: element,
				label: element,
				value: element,
			});
		});
		return resultantArray;
	};
	async function getEducationTypeList() {
		const url = `${GET_EDUCATION_TYPE}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
				return [];
			} if (response.status === 200) {
				const finalResult = await response.json();
				if (finalResult && finalResult?.length > 0) {
					setEducationTypeList([...finalResult]);
					return finalResult;
				}
			} else {
				setEducationTypeList([]);
			}
		} catch (err) {
			setEducationTypeList([]);
		}
	}
	async function getProfileData(profileId) {
		dispatch(fetchProfileJobData());
		const url = `${GET_PROFILE_DATA}${profileId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				const profileDetails = await response.json();
				if (profileDetails && profileDetails?.id?.length > 0) {
					setProfileData({
						...filterProfileData(profileDetails),
						degree: returnAsyncFilterValue(profileDetails.degree),
						lastGraduationInstitute: profileDetails.lastGraduationInstitute,
						highestEductionType: {
							value: profileDetails.highestEductionType,
							label: profileDetails.highestEductionType,
						},
					});
				}
				dispatch(fetchProfileJobDataSuccess(profileDetails));
			} else {
				dispatch(fetchProfileJobDataFailure());
			}
		} catch (err) {
			dispatch(fetchProfileJobDataFailure());
		}
	}
	// function closeModal() {
	// 	setProfileData({});
	// 	setSelectedJob({});
	// 	dispatch(setPage(1));
	// 	dispatch(updateErrors({
	// 		headLine: false,
	// 		detailedBio: false,
	// 		shortBio: false,
	// 	}));
	// 	dispatch(updateBlurredElement({
	// 		headLine: false,
	// 		detailedBio: false,
	// 		shortBio: false,
	// 	}));
	// 	dispatch(setAllElementsBlurred());
	// 	setShowForm(false);
	// 	setShowRejectionReasonForm(false);
	// }
	const saveProfileDataForUpdate = async () => {
		dispatch(updateActionsLoading());
		dispatch(setActionsMessage(''));
		const url = UPDATE_TALENT_BASE_URL;
		const formData = {
			...profileData,
			profileId: match.params.profileId,
			highestEductionType: profileData.highestEductionType.label,
			degree: returnOnlyValueIncludedArray(profileData.degree),
			lastGraduationInstitute: profileData.lastGraduationInstitute,
			switchFunctionIndustry: 'Yes',
			prefIndustry: profileData?.prefIndustry ? profileData?.prefIndustry : '',
			prefCity: profileData?.prefCity ? profileData?.prefCity : [],
		};
		const options = {
			method: 'PATCH',
			credentials: 'include',
			body: JSON.stringify(formData),
			headers: {
				'Content-Type': 'application/json',
			},
		};
		try {
			// if ((profileData.detailedBio.trim().length > 500) || (profileData.headLine.trim().length > 500) || (profileData.shortBio.trim().length > 500)) {
			// 	dispatch(updateActionsSuccess());
			// 	dispatch(setActionsMessage(''));
			// 	return alert('The respective fields cannot have a length of more than 500 characters.');
			// }
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				dispatch(updateActionsSuccess());
				dispatch(fetchProfileJobDataSuccess({
					...profileJobData,
					educationDetails: profileData.educationDetails,
					profileId: match.params.profileId,
					highestEductionType: profileData.highestEductionType.label,
					degree: returnOnlyValueIncludedArray(profileData.degree),
					lastGraduationInstitute: profileData.lastGraduationInstitute,
					switchFunctionIndustry: 'Yes',
					prefIndustry: profileData?.prefIndustry ? profileData?.prefIndustry : '',
					prefCity: profileData?.prefCity ? profileData?.prefCity : [],
				}));
				dispatch(setActionsMessage('Education History Details are updated successfully'));
				setTimeout(() => {
					dispatch(setActionsMessage(''));
				}, 2000);
			} else {
				dispatch(updateActionsFailure());
				dispatch(setActionsMessage('Not Able To Update The Job profile'));
				setTimeout(() => {
					dispatch(updateActionsSuccess());
				}, 2000);
			}
		} catch (err) {
			dispatch(updateActionsFailure());
			dispatch(setActionsMessage('Not Able To Update The Job profile'));
			setTimeout(() => {
				dispatch(setActionsMessage(''));
				dispatch(updateActionsSuccess());
			}, 2000);
		}
	};
	function validateForm() {
		return {
			highestEductionType: !(profileData && profileData.highestEductionType && profileData.highestEductionType?.value?.length > 0),
			degree: !(profileData.degree && profileData.degree?.length > 0),
			lastGraduationInstitute: !(profileData.lastGraduationInstitute && profileData.lastGraduationInstitute?.length > 0),
		};
	}
	const handleErrors = () => {
		const validation = validateForm();
		const errors = Object.keys(validation).reduce((acc, curr) => {
			if (validation[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		const validation = validateForm();
		const validated = !Object.keys(validation).some((i) => validation[i]);
		handleErrors();
		if (validated) {
			saveProfileDataForUpdate();
		} else {
			dispatch(setAllElementsBlurred());
		}
	};
	const updateLatestEducationHistory = (data) => {
		setProfileData(({
			...profileData,
			educationDetails: [...data],
		}));
	};
	// function handleBlur(element) {
	// 	handleErrors();
	// 	dispatch(updateBlurredElement(element));
	// }

	return (
		<div>
			{
				loading
					? <LoadingIcon />
					: <>
						<div>
							{/* <Row>
								<Col xs={8} sm={8}>
									<h3 className="px-3">
										Edit Education Details
									</h3>
								</Col>
								<Col xs={4} sm={4} className="text-right">
									<Button
										primary
										no_radius
										className="mb-0 mr-2"
										onClick={() => window.open(`/admin-job/education-history/editor/${match.params.profileId}`)}
									>
										<b>VIEW EDUCATION HISTORY</b>
									</Button>
								</Col>
							</Row> */}
							<Row>
								<Col xs={12}>
									{profileJobData && <EducationHistoryListPage {...props} updatedProfileJobData={profileJobData} updateEducationDetails={updateLatestEducationHistory} />}
								</Col>
							</Row>
							<form onSubmit={handleSubmit}>
								<Row className="m-2">
									<Col xs={12} sm={12}>
										<h3 className="pt-3 pb-2">
											Edit Education Details
										</h3>
									</Col>
									<Col xs={12} sm={12}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Higher Education Type *"
												hint="Higher Education Type"
											/>
											<AsyncSingleSelectWithDefault
												name="higher-education-type"
												placeholder="Select From List"
												defaultOptionsList={educationTypeList}
												options={(value) => filterAsyncSelectOptions(educationTypeList, value)}
												value={profileData.highestEductionType}
												handleChange={(value) => {
													setProfileData({ ...profileData, highestEductionType: value });
												}}
											/>
											<ErrorMessage display={errors.highestEductionType} element="Higher Education Type" />
										</div>
									</Col>
									<Col xs={12} sm={12}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Your Degrees *"
												hint="Your Degrees"
											/>
											<AsyncMultiSelect
												placeholder="Select From List"
												defaultOptionsList={degreeTypeList}
												options={(value) => filterAsyncSelectOptions(degreeTypeList, value)}
												currentValues={profileData.degree}
												handleChange={(value) => {
													setProfileData({ ...profileData, degree: removeDuplicatesFromAsyncMultiSelect(value) });
												}}
												profile="educationDegree"
											/>
											<ErrorMessage display={errors.degree} element="Degree" />
										</div>
									</Col>
									<Col xs={12} sm={12}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Last Graduation Institute *"
												hint="Last Graduation Institute"
											/>
											<Input
												type="text"
												className="form-control"
												value={profileData.lastGraduationInstitute}
												name="candidateGraduationInstitute"
												autoComplete="off"
												maxLength="200"
												placeholder="eg: S.N.D.T University"
												onChange={(e) => {
													setProfileData(({
														...profileData,
														lastGraduationInstitute: e.target.value,
													}));
													handleErrors();
												}}
											/>
											<ErrorMessage display={errors.lastGraduationInstitute} element="Institute Name" />
										</div>
									</Col>
									<Col xs={12} sm={12} className="mt-2 text-center">
										<Button
											success
											no_radius
											type="submit"
											style={{ width: '150px' }}
										>
											<b>Save Changes</b>
										</Button>
									</Col>
									<Col xs={12} sm={12}>
										{actionLoading && <LoadingIcon />}
									</Col>
									<Col xs={12} sm={12}>
										{(actionError && actionMessage) && <p className="error error-text text-normal text-center mb-2">
											{actionMessage}
										</p>}
										{(!actionError && actionMessage) && <p className="success-text text-normal text-center mb-2">
											{actionMessage}
										</p>}
									</Col>
								</Row>
							</form>
						</div>
					</>
			}
		</div>
	);
};

EducationHistoryProfilePage.propTypes = {
	// status: T.number,
	history: T.object,
	match: T.object,
};
export default EducationHistoryProfilePage;
