/* eslint-disable no-unused-vars */
import React, {
	useEffect, useState, useReducer,
} from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import AsyncSelect from 'react-select/async';
import 'rc-pagination/assets/index.css';
import Pagination from 'rc-pagination';
import T from 'prop-types';
import DatePicker from 'react-datepicker';
import Modal from 'react-modal';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';

import {
	WrapperContainer, Input, DropDown, Button,
} from '../../components/Styles';
import { DESK_BASE_API_URL } from '../StoryDesk/constants';
import ListHeader from '../../components/BoniManager/BoniBrandOfTheDay/ListHeader';
import ListItem from '../../components/BoniManager/BoniBrandOfTheDay/ListItem';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';
import locale from '../WatchlistedStories/en_US';
import ToastMessage from '../../components/ToastMessage';
import { ConfirmationModal } from '../../components/FormComponents';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';

import {
	BRAND_OF_THE_DAY_BASE_URL,
	FETCH_BRANDS_OF_THE_DAY,
	FETCH_BRANDS_OF_THE_DAY_SUCCESS,
	FETCH_BRANDS_OF_THE_DAY_FAILURE,
	FILTER_BRANDS_OF_THE_DAY,
	FILTER_BRANDS_OF_THE_DAY_SUCCESS,
	FILTER_BRANDS_OF_THE_DAY_FAILURE,
	SET_BRANDS_OF_THE_DAY_PAGE,
	SET_FORM_MESSAGE,
	SAVING_MESSAGE,
	SAVING_SUCCESS_MESSAGE,
	SAVING_FAILURE_MESSAGE,
	UPDATE_BLURRED_ELEMENT,
	SET_ALL_ELEMENTS_BLURRED,
	UPDATE_ERRORS,
	ACTIVE_BRANDS_OF_THE_DAY_FAILURE_MESSAGE,
} from './constants';

const ActionCard = styled.div`
	box-shadow: 0 0rem 0.2rem rgba(0, 0, 0, 0.3);
	border-radius: 0.25rem;
	min-width: 100%;
`;

const Image = styled.img`
		width: 300px;
		border: solid #e5002d 2px;
		height: 100%
`;

const initialState = {
	brandsOfTheDay: [],
	brandsCount: null,
	loading: false,
	error: false,
	formMessage: null,
	page: 1,
	blurredElements: {
		story: false,
		status: false,
		date: false,
	},
	errors: {
		story: false,
		status: false,
		date: false,
	},
};

function fetchBrandOfTheDay() {
	return {
		type: FETCH_BRANDS_OF_THE_DAY,
	};
}

function fetchBrandOfTheDaySuccess(brandsOfTheDay) {
	return {
		type: FETCH_BRANDS_OF_THE_DAY_SUCCESS,
		brandsOfTheDay,
	};
}

function fetchBrandOfTheDayFailure(error) {
	return {
		type: FETCH_BRANDS_OF_THE_DAY_FAILURE,
		error,
	};
}

function filterBrandOfTheDay() {
	return {
		type: FILTER_BRANDS_OF_THE_DAY,
	};
}

function filterBrandOfTheDaySuccess(brandsOfTheDay) {
	return {
		type: FILTER_BRANDS_OF_THE_DAY_SUCCESS,
		brandsOfTheDay,
	};
}

function filterBrandOfTheDayFailure(error) {
	return {
		type: FILTER_BRANDS_OF_THE_DAY_FAILURE,
		error,
	};
}

function setFormMessage(message) {
	return {
		type: SET_FORM_MESSAGE,
		message,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function setPage(page) {
	return {
		type: SET_BRANDS_OF_THE_DAY_PAGE,
		page,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case FETCH_BRANDS_OF_THE_DAY:
		return { ...state, loading: true };
	case FETCH_BRANDS_OF_THE_DAY_SUCCESS: {
		const brandsOfTheDay = action.brandsOfTheDay[0].map((brandOfTheDay) => {
			const temp = { ...brandOfTheDay };
			temp.storyData = JSON.parse(brandOfTheDay['story.postContent']);
			temp.storyTitle = brandOfTheDay['story.title'];
			return temp;
		});
		return {
			...state, loading: false, brandsOfTheDay, brandsCount: action.brandsOfTheDay[1],
		};
	}
	case FETCH_BRANDS_OF_THE_DAY_FAILURE:
		return {
			...state, loading: false, error: true, brandsOfTheDay: [],
		};
	case FILTER_BRANDS_OF_THE_DAY:
		return { ...state, loading: true };
	case FILTER_BRANDS_OF_THE_DAY_SUCCESS: {
		const brandsOfTheDay = action.brandsOfTheDay[0].map((brandOfTheDay) => {
			const temp = { ...brandOfTheDay };
			temp.storyData = JSON.parse(brandOfTheDay['story.postContent']);
			temp.storyTitle = brandOfTheDay['story.title'];
			return temp;
		});
		return {
			...state, loading: false, brandsOfTheDay, brandsCount: action.brandsOfTheDay[1],
		};
	}
	case FILTER_BRANDS_OF_THE_DAY_FAILURE:
		return {
			...state, loading: true, error: true, brandsOfTheDay: [],
		};
	case SET_BRANDS_OF_THE_DAY_PAGE:
		return { ...state, page: action.page };

	case SET_FORM_MESSAGE:
		return { ...state, formMessage: action.message };
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };
	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			story: true,
			status: true,
			date: true,
		};
		return { ...state, blurredElements };
	}
	default:
		return state;
	}
}

const BrandOfTheDayManager = (props) => {
	const { pageTitle, history } = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		brandsOfTheDay, brandsCount, loading, page, formMessage, errors, blurredElements,
	} = state;
	const [searchValue, setSearchValue] = useState('');
	const [date, setDate] = useState(null);
	const [story, setStory] = useState(null);
	const [brandOfTheDayStatus, setBrandOfTheDayStatus] = useState('INACTIVE');
	const [updateStory, setUpdateStory] = useState(null);
	const [isButtonDisabled, disableButton] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const [toUpdate, setToUpdate] = useState(false);
	const [showDeleteConfirmation, setDeleteConfirmationOpen] = useState(false);
	const [deleteItemId, setDeleteItemId] = useState(null);
	const [isSingleActiveBOD, setIsSingleActiveBOD] = useState(false);
	const [status, setStatus] = useState('ALL');

	useEffect(() => {
		pageTitle('brandOfTheDay');
		getBrandOfTheDay(1, 10);
	}, []);

	useEffect(() => {
		getErrors();
	}, [story, blurredElements]);

	async function filterStories(searchValue) {
		const url = `${DESK_BASE_API_URL}/search`;
		const data = new FormData();
		data.append('type', 'title');
		data.append('brand', 'brands-of-new-india');
		data.append('status', 'published');
		data.append('searchValue', searchValue);
		data.append('limit', 10);
		const options = {
			method: 'POST',
			credentials: 'include',
			body: data,
		};
		const response = await fetch(url, options);
		if (response.status === 403 || response.status === 401) {
			history.push('/unauthorized');
		} else {
			const results = await response.json();
			if (results && results?.length > 0) {
				results.forEach((data) => {
					data.label = data.title;
					data.value = data.title.toLowerCase();
				});
			}
			return results;
		}
	}

	async function getBrandOfTheDay(offset, limit) {
		dispatch(fetchBrandOfTheDay());
		const url = `${BRAND_OF_THE_DAY_BASE_URL}/offset/${offset}/limit/${limit}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else {
				const brandsOfTheDay = await response.json();
				dispatch(fetchBrandOfTheDaySuccess(brandsOfTheDay));
			}
		} catch (err) {
			dispatch(fetchBrandOfTheDayFailure(err));
		}
	}

	async function filterBrandsOfTheDay(key, newStatus, newPage) {
		if (key) {
			key = key.trim();
			if (key.length < 1) {
				return;
			}
		}
		const searchKey = key || null;
		dispatch(filterBrandOfTheDay());
		const url = `${BRAND_OF_THE_DAY_BASE_URL}/key/${searchKey}`;
		const formData = new FormData();
		formData.append('page', newPage);
		formData.append('status', newStatus);
		const options = {
			method: 'POST',
			body: formData,
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else {
				const brandsOfTheDay = await response.json();
				dispatch(filterBrandOfTheDaySuccess(brandsOfTheDay));
			}
		} catch (err) {
			dispatch(filterBrandOfTheDayFailure(err));
		}
	}

	function handleStatusChange(e) {
		const newStatus = e.target.value;
		setStatus(newStatus);
		if (newStatus !== 'ALL') {
			dispatch(setPage(1));
			filterBrandsOfTheDay(searchValue, newStatus, 1);
		} else {
			getBrandOfTheDay(1, 10);
		}
	}

	// function handleSearch(e) {
	// 	setSearchValue(e.target.value);
	// 	const searchValue = e.target.value;
	// 	if (searchValue || status !== 'ALL') {
	// 		dispatch(setPage(1));
	// 		filterBrandsOfTheDay(searchValue, status, 1);
	// 	} else {
	// 		getBrandOfTheDay(1, 10);
	// 	}
	// }

	function onPageChange(page) {
		if (searchValue || status !== 'ALL') {
			filterBrandsOfTheDay(searchValue, status, page);
		} else {
			getBrandOfTheDay(page, 10);
		}
		dispatch(setPage(page));
	}

	function getBrandOfTheDayList() {
		return brandsOfTheDay.map((brandOfTheDay, index) => <ListItem
			key={brandOfTheDay.id}
			slNo={(page - 1) * 10 + index + 1}
			brandOfTheDay={brandOfTheDay}
			editItem={(data) => editBrandOfTheDay(data)}
			deleteItem={(id) => {
				setDeleteConfirmationOpen(true);
				setDeleteItemId(id);
			}}
		/>);
	}

	async function deleteBrandOfTheDay() {
		const url = `${BRAND_OF_THE_DAY_BASE_URL}/${deleteItemId}`;
		const options = {
			method: 'DELETE',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else {
				const brandsOfTheDay = await response.json();
				if (brandsOfTheDay.success) {
					setDeleteItemId(null);
					dispatch(setPage(1));
					setDeleteConfirmationOpen(false);
					getBrandOfTheDay(1, 10);
				}
			}
		} catch (err) {
			dispatch(fetchBrandOfTheDayFailure(err));
		}
	}

	function editBrandOfTheDay(data) {
		const newOject = cloneDeep(data);
		newOject.label = newOject.storyTitle;
		newOject.value = newOject.storyId;
		setToUpdate(true);
		setUpdateStory(newOject);
		setDate(newOject.date);
		setBrandOfTheDayStatus(newOject.status);
		setShowForm(true);
	}

	function renderList() {
		if (brandsOfTheDay && brandsOfTheDay.length > 0) {
			return <div>
				<ListHeader />
				{getBrandOfTheDayList()}
			</div>;
		}

		return (
			<Row style={{
				width: '100%', minHeight: '400px', justifyContent: 'center', alignItems: 'center',
			}}
			>
				<div>
					<h1 style={{ color: '#bbb' }}>
						No Brand Of The Day To Display
					</h1>
				</div>
			</Row>
		);
	}

	function validateForm() {
		return {
			story: !((updateStory && updateStory.storyId) || (story && story.storyId)),
			date: !date,
			status: !brandOfTheDayStatus,
		};
	}

	function getErrors() {
		const validationErrors = validateForm();
		const errors = Object.keys(validationErrors).reduce((acc, curr) => {
			if (validationErrors[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	}

	function getBrandOfTheDayRequestBody() {
		const formData = new FormData();
		if (toUpdate) {
			formData.append('brandId', updateStory.brandId);
			formData.append('storyId', updateStory.storyId);
		} else {
			const postContent = JSON.parse(story.postContent);
			formData.append('brandId', postContent.companyBrand.id);
			formData.append('storyId', story.storyId);
		}
		formData.append('date', date);
		formData.append('status', brandOfTheDayStatus);
		return formData;
	}

	async function saveBrandOfTheDay() {
		try {
			disableButton(true);
			dispatch(setFormMessage(SAVING_MESSAGE));
			const payload = getBrandOfTheDayRequestBody();
			let url;
			let options;
			if (toUpdate) {
				url = `${BRAND_OF_THE_DAY_BASE_URL}/update/id/${updateStory.id}`;
				options = {
					method: 'PUT',
					credentials: 'include',
					body: payload,
				};
			} else {
				url = `${BRAND_OF_THE_DAY_BASE_URL}/create`;
				options = {
					method: 'POST',
					credentials: 'include',
					body: payload,
				};
			}
			const response = await fetch(url, options);
			const parsed = await response.json();
			disableButton(false);
			if (parsed.status === 'success') {
				dispatch(setFormMessage(SAVING_SUCCESS_MESSAGE));
				closeModal();
			} else if (response.status === 400) {
				dispatch(setFormMessage(ACTIVE_BRANDS_OF_THE_DAY_FAILURE_MESSAGE));
			} else {
				dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			}
		} catch (err) {
			dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			disableButton(false);
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		const validation = validateForm();
		const validated = !Object.keys(validation).some((i) => validation[i]);
		if (validated) {
			saveBrandOfTheDay();
		} else {
			dispatch(setAllElementsBlurred());
		}
	};

	function renderFormMessage() {
		if (formMessage) {
			return (
				<ToastMessage
					toastData={formMessage}
					unmount={() => dispatch(setFormMessage(null))}
				/>
			);
		}
		return null;
	}

	function renderDeleteConfirmation() {
		return (
			<ConfirmationModal
				openDialog={showDeleteConfirmation}
				closeDialog={() => {
					setDeleteConfirmationOpen(false);
					setDeleteItemId(null);
				}}
				message="Are you sure you would like to delete this brand of the day record ? This action is irreversible."
				confirm={deleteBrandOfTheDay}
			/>
		);
	}

	function renderStoryDataVisualizer() {
		const newStory = toUpdate ? updateStory : story;
		const postContent = toUpdate ? updateStory.storyData : JSON.parse(newStory.postContent);
		const storyTitle = toUpdate ? updateStory.storyTitle : newStory.title;
		const { companyBrand } = postContent;

		return (
			<div className="m-2">
				<ActionCard className="p-3">
					<Row className="mb-sm-2">
						<Col xs={5} sm={5} md={3} className="mb-sm-2">
							Story title
						</Col>
						<Col xs={8} sm={10} md={8} className="mb-sm-2">
							<b>{`${storyTitle.substring(0, 50)}${storyTitle.length > 50 ? '...' : ''}`}</b>
						</Col>
					</Row>
					<Row className="mb-2">
						<Col xs={5} sm={5} md={3} className="mb-sm-2">
							Brand name
						</Col>
						<Col xs={8} sm={10} md={5} className="mb-sm-2">
							{companyBrand.name}
						</Col>
						<Col xs={8} sm={10} md={4} className="mb-sm-2">
							<Input
								type="color"
								value={companyBrand.brandColor}
								disabled
							/>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							Story thumbnail
						</Col>
						<Col md={6}>
							Brand logo
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<Image alt="Story thumbnail" src={postContent.thumbnail} />
						</Col>
						<Col md={6}>
							<Image alt="Brand logo" src={companyBrand.logo} />
						</Col>
					</Row>
				</ActionCard>
			</div>
		);
	}

	function handleBlur(element) {
		dispatch(updateBlurredElement(element));
	}

	async function fetchActiveBrandOfTheDayByDate(date) {
		try {
			const url = BRAND_OF_THE_DAY_BASE_URL;
			const formData = new FormData();
			formData.append('date', date);
			if (updateStory) {
				formData.append('id', updateStory.id);
			}
			const options = {
				method: 'POST',
				body: formData,
				credentials: 'include',
			};
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else {
				return await response.json();
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function handleDate(date) {
		disableButton(true);
		const newDate = new Date(date);
		newDate.setHours(newDate.getHours() + 6);
		setDate(newDate);
		const data = await fetchActiveBrandOfTheDayByDate(newDate);
		if (data && data.status === 'success' && brandOfTheDayStatus === 'ACTIVE') {
			setBrandOfTheDayStatus('INACTIVE');
			setIsSingleActiveBOD(true);
		} else {
			setIsSingleActiveBOD(false);
		}
		disableButton(false);
	}

	function renderFormModal() {
		return <Modal
			isOpen={showForm}
			onRequestClose={closeModal}
			overlayClassName="modal-overlay"
			className="form-modal"
		>
			<Row>
				<Col md={10} xs={10}>
					<h2 className="w-50 text-left">New Brand Of The Day</h2>
				</Col>
				<Col md={2} xs={2}>
					<div className="w-50 text-right">
						<button type="button" onClick={closeModal}>
							<i className="fas fa-times light-text disablePointerEvents" />
						</button>
					</div>
				</Col>
			</Row>
			<div>
				{renderFormMessage()}
				<form onSubmit={handleSubmit}>
					<Row middle="xs" className="mt-2">
						<Col md={4} sm={10} className="pt-3">
							Select story *
						</Col>
						<Col className="" xs={8} md={8}>
							<AsyncSelect
								isClearable
								loadOptions={(key) => filterStories(key)}
								placeholder="Search brands of new india story by name"
								onChange={(story) => setStory(story)}
								value={toUpdate ? updateStory : story}
								onBlur={() => handleBlur('story')}
							/>
							<ErrorMessage display={errors.story} element="Story" />
						</Col>
					</Row>

					{updateStory || story
						? <> {renderStoryDataVisualizer()} </>
						: null}

					<Row middle="xs" className="mt-2">
						<Col md={4} sm={6} xs={12} className="mb-lg-0 mb-2">
							Status *
						</Col>
						<Col md={8} sm={12} xs={12}>
							<DropDown
								className="mt-0"
								onChange={(e) => setBrandOfTheDayStatus(e.target.value)}
								value={brandOfTheDayStatus}
							>
								<option value="INACTIVE">INACTIVE</option>
								<option value="ACTIVE">ACTIVE</option>
							</DropDown>
						</Col>
					</Row>
					<Row middle="xs" className="mt-2">
						<Col md={4} sm={6} xs={12} className="mb-lg-0 mb-2">
							Date *
						</Col>
						<Col md={8} sm={12} xs={12}>
							<DatePicker
								onChange={(date) => handleDate(date)}
								selected={date ? new Date(date) : null}
								dateFormat="dd/MM/yyyy"
								className="brand-datepicker"
								placeholderText="Select date"
								autoComplete="off"
								onBlur={() => handleBlur('date')}
								required
							/>
							<ErrorMessage display={errors.date} element="Date" />
						</Col>
						<ErrorMessage display={isSingleActiveBOD} className="mt-3" message="There is an ACTIVE brand of the day with this date. Setting status as INACTIVE" />
					</Row>

					<Row className="m-2">
						<Col xs={12} sm={12} className="mt-2 text-center">
							<Button
								success
								no_radius
								type="submit"
								disabled={isButtonDisabled}
								style={{ width: '100px' }}
							>
								<b>SAVE</b>
							</Button>
						</Col>
					</Row>
				</form>
			</div>
		</Modal>;
	}

	function closeModal() {
		setStory(null);
		setDate(null);
		setUpdateStory(null);
		setToUpdate(false);
		dispatch(setPage(1));
		if (status !== 'ALL') {
			filterBrandsOfTheDay(null, status, 1);
		} else {
			getBrandOfTheDay(1, 10);
		}
		dispatch(setFormMessage(null));
		setShowForm(false);
		setIsSingleActiveBOD(false);
		setBrandOfTheDayStatus('INACTIVE');
	}

	return (
		<WrapperContainer>
			<Row className="mb-4">
				<Col sm={5} xs={12} className="mb-2">
					<p className="mb-0">A list of brands of the day related to Brands of new India</p>
				</Col>
				<Col sm={7} xs={12}>
					<Row style={{ justifyContent: 'flex-end' }} className="mr-1 mb-2">
						<p className="text-bold mb-0 pt-1">Filter By Status</p>
						<Col sm={3} xs={12} className="mb-2">
							<DropDown className="mt-0" onChange={handleStatusChange}>
								<option value="ALL">ALL</option>
								<option value="ACTIVE">ACTIVE</option>
								<option value="INACTIVE">INACTIVE</option>
							</DropDown>
						</Col>
						{/* <Col sm={4} xs={12} className="mb-2">
							<Input
								id="search-box"
								type="text"
								className="form-control"
								placeholder="Search brand of the day"
								value={searchValue}
								onChange={handleSearch}
							/>
						</Col> */}
						<Col sm={3} xs={12} className="mb-2">
							<Button
								primary
								no_radius
								className="mb-0"
								onClick={() => setShowForm(true)}
							>
								<b>ADD BRAND OF THE DAY</b>
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
			{loading
				? <LoadingIcon />
				: <>
					{renderList()}
					{
						brandsOfTheDay && brandsOfTheDay.length > 0
							? <Row className="mt-3 pr-4" style={{ justifyContent: 'flex-end' }}>
								<Pagination onChange={onPageChange} current={page} total={brandsCount} locale={locale} />
							</Row>
							: null
					}
				</>}
			{renderFormModal()}
			{renderDeleteConfirmation()}
		</WrapperContainer>
	);
};

BrandOfTheDayManager.propTypes = {
	history: T.object,
	pageTitle: T.func,
};

export default BrandOfTheDayManager;
