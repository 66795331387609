import React from 'react';
import styled, { css } from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';
import PropTypes from 'prop-types';
import { TitleWithTooltip } from './index';

const Card = styled(Row)`
		width: 100%;
		box-shadow: ${(props) => (props.noShadow ? 0 : '1px 2px 3px 0px #DDDDDD')};
		padding: ${(props) => (props.noPadding ? 0 : '1rem')};
		min-height: ${(props) => (props.noHeight ? 0 : '120px')};
		${(props) => props.customPadding && css`
				padding: ${props.customPadding};
		`}
`;

const SubTitle = styled.div`
		font-size: 0.9rem;
`;

const FlexDiv = styled.div`
		display: flex;
		flex-direction: row;
`;

const TogglerCard = (props) => {
	TogglerCard.propTypes = {
		title: PropTypes.string,
		subtitle: PropTypes.string,
		hint: PropTypes.string,
		onToggleChange: PropTypes.func,
		checked: PropTypes.bool,
		disabled: PropTypes.bool,
		noShadow: PropTypes.bool,
		noPadding: PropTypes.bool,
		noHeight: PropTypes.bool,
		customPadding: PropTypes.string,
		children: PropTypes.array,
	};

	return <Card xs={12} noShadow={props.noShadow} noPadding={props.noPadding} noHeight={props.noHeight} customPadding={props.customPadding}>
		<Col xs={12}>
			<TitleWithTooltip title={props.title} hint={props.hint} />
		</Col>
		<Col xs={12} className="mt-2">
			<FlexDiv>
				<div className="pr-3">
					<label className="switch">
						<input
							type="checkbox"
							checked={props.checked || false}
							onChange={(e) => {
								props.onToggleChange(e.target.checked);
							}}
							disabled={props.disabled}
						/>
						<span className={`${props.disabled ? 'slider-disabled' : ''} slider round`} />
					</label>
				</div>
				<div>
					<SubTitle>{props.subtitle}</SubTitle>
				</div>
			</FlexDiv>
			{props.children ? <Row>{props.children}</Row> : null}
		</Col>
	</Card>;
};

export default TogglerCard;
