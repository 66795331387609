import { defineMessages } from 'react-intl';

export default defineMessages({
	name: {
		id: 'creatorstudio.components.FormInstance.name',
		defaultMessage: 'Name *',
	},
	slug: {
		id: 'creatorstudio.components.FormInstance.slug',
		defaultMessage: 'Slug/URL *',
	},
	website: {
		id: 'creatorstudio.components.FormInstance.website',
		defaultMessage: 'Website *',
	},
	description: {
		id: 'creatorstudio.components.FormInstance.description',
		defaultMessage: 'Description',
	},
	image: {
		id: 'creatorstudio.components.FormInstance.image',
		defaultMessage: 'Image',
	},
	compulsoryImage: {
		id: 'creatorstudio.components.FormInstance.image',
		defaultMessage: 'Image *',
	},
	upload: {
		id: 'creatorstudio.components.FormInstance.upload',
		defaultMessage: 'Upload a image',
	},
	locale: {
		id: 'creatorstudio.components.FormInstance.locale',
		defaultMessage: 'Locale *',
	},
	internalBrand: {
		id: 'creatorstudio.components.FormInstance.internalBrand',
		defaultMessage: 'Internal Brand',
	},
	save: {
		id: 'creatorstudio.components.FormInstance.save',
		defaultMessage: 'SAVE',
	},
	discard: {
		id: 'creatorstudio.components.FormInstance.discard',
		defaultMessage: 'DISCARD',
	},
	delete: {
		id: 'creatorstudio.components.FormInstance.delete',
		defaultMessage: 'DELETE',
	},
	replace: {
		id: 'creatorstudio.components.FormInstance.replace',
		defaultMessage: 'REPLACE',
	},
	nameErr: {
		id: 'creatorstudio.components.FormInstance.nameErr',
		defaultMessage: '* Name cannot be empty',
	},
	slugEmptyErr: {
		id: 'creatorstudio.components.FormInstance.slugEmptyErr',
		defaultMessage: '* Slug cannot be empty',
	},
	slugCaseErr: {
		id: 'creatorstudio.components.FormInstance.slugCaseErr',
		defaultMessage: '* Slug cannot contain capital letters',
	},
	slugUniqueErr: {
		id: 'creatorstudio.components.FormInstance.slugUniqueErr',
		defaultMessage: '* This slug is already taken. Pick a unique slug',
	},
	slugSymbolErr: {
		id: 'creatorstudio.components.FormInstance.slugSymbolErr',
		defaultMessage: '* Slug cannot contain symbols',
	},
	localeErr: {
		id: 'creatorstudio.components.FormInstance.localeErr',
		defaultMessage: '* Locale cannot be empty',
	},
	internalErr: {
		id: 'creatorstudio.components.FormInstance.internalErr',
		defaultMessage: '* Type of brand must be selected',
	},
	websiteEmptyErr: {
		id: 'creatorstudio.components.FormInstance.websiteEmptyErr',
		defaultMessage: '* Website cannot be empty',
	},
	websiteFormatErr: {
		id: 'creatorstudio.components.FormInstance.websiteFormatErr',
		defaultMessage: '* Website URL format is incorrect',
	},
	fileUploadErr: {
		id: 'creatorstudio.components.FormInstance.fileUploadErr',
		defaultMessage: '* Brand logo is required',
	},
	typeErr: {
		id: 'creatorstudio.components.FormInstance.typeErr',
		defaultMessage: '* Type is required',
	},
	dateErr: {
		id: 'creatorstudio.components.FormInstance.dateErr',
		defaultMessage: '* Date is required',
	},
	verticalErr: {
		id: 'creatorstudio.components.FormInstance.verticalErr',
		defaultMessage: '* Alternate vertical is required',
	},
});
