import { createSelector } from 'reselect';

const selectRoute = (state) => state.get('router');
const selectApp = (state) => state.get('app');

const makeSelectLocation = () => createSelector(
	selectRoute,
	(routeState) => routeState.get('location').toJS(),
);

const makeSelectAppUser = () => createSelector(
	selectApp,
	(appState) => appState.get('user'),
);

const makeSelectAppError = () => createSelector(
	selectApp,
	(appState) => appState.get('error'),
);

const makeSelectAppLoading = () => createSelector(
	selectApp,
	(appState) => appState.get('loading'),
);

const makeSelectAppPageName = () => createSelector(
	selectApp,
	(appState) => appState.get('pageName'),
);

const makeSelectOnlineStatus = () => createSelector(
	selectApp,
	(appState) => appState.get('online'),
);

const makeSelectUserBrands = () => createSelector(
	selectApp,
	(appState) => appState.get('userBrands'),
);

const makeSelectCurrentBrand = () => createSelector(
	selectApp,
	(appState) => appState.get('currentBrand'),
);

export {
	makeSelectLocation,
	makeSelectAppUser,
	makeSelectAppError,
	makeSelectAppLoading,
	makeSelectAppPageName,
	makeSelectOnlineStatus,
	makeSelectUserBrands,
	makeSelectCurrentBrand,
};
