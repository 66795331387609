/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { Button, HeaderWrapper } from '../Styles';
import {
	HeaderContainer, LogoIcon, NavItem, Header, NavWrapper, UserMenu, UserMenuItem,
} from './styles';
import { Row, Col } from '../Responsive';
import csIcon from '../../images/ic_cs_small.svg';
import profileImagePlaceholder from '../../images/user.svg';
import { WifiIcon, WifiSlashIcon } from '../Icons';
import { makeSelectAppPageName, makeSelectAppUser } from '../../containers/App/selectors';

const MY_ACCOUNT_BASE_URL = process.env.REACT_APP_SERVICE_PWA;

class DashboardHeader extends React.PureComponent {
	constructor(props) {
		super(props);
		this.renderNavContent = this.renderNavContent.bind(this);
		this.renderNavMenu = this.renderNavMenu.bind(this);
		this.renderPageName = this.renderPageName.bind(this);
		this.handleUserMenuClick = this.handleUserMenuClick.bind(this);
		this.handleOutsideClick = this.handleOutsideClick.bind(this);
		this.getOnlineStatus = this.getOnlineStatus.bind(this);
		this.state = {
			userMenu: false,
		};
	}

	componentDidMount() {
		document.addEventListener('click', this.handleOutsideClick, false);
		// const hwConfig = {
		//		 selector: 'div.announcements', // CSS selector where to inject the badge
		//		 account: 'xGYWOJ',
		// };
		// setTimeout(() => window.Headway.init(hwConfig), 5000);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleOutsideClick, false);
	}

	getOnlineStatus() {
		if (this.props.page === 'editor') {
			if (this.props.online) {
				return <WifiIcon />;
			}
			return <WifiSlashIcon />;
		}
		return null;
	}

	handleUserMenuClick() {
		this.setState({ userMenu: !this.state.userMenu });
	}

	handleOutsideClick(e) {
		if (e.target.id === 'user-menu') {
			return;
		}
		this.setState({ userMenu: false });
	}

	renderNavContent() {
		const { page } = this.props;
		const titles = {
			dashboard: this.renderNavMenu(),
			stories: this.renderNavMenu(),
			mediaManager: this.renderPageName('Media Manager'),
			verticalManager: this.renderPageName('Vertical Manager'),
			awardManager: this.renderPageName('Award Manager'),
			peopleManager: this.renderPageName('Influencer Manager'),
			cityManager: this.renderPageName('City Manager'),
			category: this.renderPageName('Category Manager'),
			tag: this.renderPageName('Tag Manager'),
			brand: this.renderPageName('Brand Manager'),
			desk: this.renderPageName('Story Desk'),
			companyDesk: this.renderPageName('Company Desk'),
			influencerDesk: this.renderPageName('Influencer Desk'),
			user: this.renderPageName('User Manager'),
			dynamicConfiguration: this.renderPageName('Dynamic Configuration'),
			videos: this.renderPageName('Video Manager'),
			redirection: this.renderPageName('Redirection Manager'),
			brandSpotlight: this.renderPageName('Brand Spotlight Manager'),
			adhocUpload: this.renderPageName('Adhoc Uploader'),
			watchlistedStories: this.renderPageName('Watchlisted Stories'),
			rssFeedCreator: this.renderPageName('Watchlist Feed Creator'),
			editor: this.renderPageName('Editor'),
			messengerPromotionsManager: this.renderPageName('Messenger Promotions'),
			messengerPromotionForm: this.renderPageName('New Messenger Promotion'),
			notification: this.renderPageName('Notification Manager'),
			unauthorized: this.renderPageName('Unauthorized'),
			storyPreview: this.renderPageName('Story Preview'),
			featuredSet: this.renderPageName('Page Curations'),
			seriesManager: this.renderPageName('Series Manager'),
			storyVersions: this.renderPageName('Story Versions'),
			marketingData: this.renderPageName('Marketing Data'),
			activitystream: this.renderPageName('Activity Stream'),
			videoEditor: this.renderPageName('Video Editor'),
			companyManager: this.renderPageName('Company Manager'),
			companyEditor: this.renderPageName('Company Editor'),
			companyList: this.renderPageName('View Companies'),
			influencerList: this.renderPageName('View Influencers'),
			tagStories: this.renderPageName('Tag Stories'),
			tagCompanyToStories: this.renderPageName('Tag Company To Stories'),
			tagInfluencerToStories: this.renderPageName('Tag Influencer To Stories'),
			errorPage: this.renderPageName('Error'),
			profileClaimDesk: this.renderPageName('Profile Claim Desk'),
			fundingLogs: this.renderPageName('Automated Funding Logs'),
			acquisitionLogs: this.renderPageName('Automated Acquisition Logs'),
			mergerLogs: this.renderPageName('Automated Merger Logs'),
			ipoLogs: this.renderPageName('Automated IPO Logs'),
			webinarManager: this.renderPageName('Webinar Manager'),
			customFields: this.renderPageName('Custom Fields'),
			webinars: this.renderPageName('Webinars'),
			userManager: this.renderPageName('User Manager'),
			inviteUser: this.renderPageName('Invite User'),
			createAlias: this.renderPageName('Create Alias'),
			updateAlias: this.renderPageName('Update Alias'),
			companyConnectDesk: this.renderPageName('Company Connect Desk'),
			jobEditor: this.renderPageName('Job Editor'),
			jobsList: this.renderPageName('Jobs'),
			eventEditor: this.renderPageName('Event Editor'),
			communityContentList: this.renderPageName('Community Content'),
			events: this.renderPageName('Events'),
			broadcastManager: this.renderPageName('Broadcast Manager'),
			awardsQuestions: this.renderPageName('Awards Question Manager'),
			subscriptionManager: this.renderPageName('Subscription Manager'),
			subscriptionEdit: this.renderPageName('Subscription Editor'),
			subscriptionList: this.renderPageName('Subscription List'),
			subscriptionStatus: this.renderPageName('Subscription Status'),
			dissociateAuthorProfile: this.renderPageName('Dissociate Author Profile'),
			orgChartList: this.renderPageName('Org Chart'),
			orgChartEditor: this.renderPageName('Org Chart Editor'),
			cmsPageCurationSet: this.renderPageName('CMS Page Curation'),
			boniManager: this.renderPageName('BONI Manager'),
			boniBrandManager: this.renderPageName('BONI Brand Manager'),
			webstoriesManager: this.renderPageName('Web Stories Manager'),
			writeWebstory: this.renderPageName('Write Web Stories'),
			brandOfTheDay: this.renderPageName('Brand of The Day Manager'),
			brandCategory: this.renderPageName('Brand Category Manager'),
			brands: this.renderPageName('Brands Manager'),
			externalLinkManager: this.renderPageName('External Links Manager'),
			productCategory: this.renderPageName('Product Categories Manager'),
			productManager: this.renderPageName('Brand Product Manager'),
			spotlightMonth: this.renderPageName('Spotlight Of The Month Manager'),
			webStoryManager: this.renderPageName('Web Stories Manager'),
			brandCategoryEditor: this.renderPageName('Brand Category Manager Editor'),
			brandEditor: this.renderPageName('Brands Manager Editor'),
			externalLinkEditor: this.renderPageName('External Links Manager Editor'),
			productCategoryEditor: this.renderPageName('Product Categories Manager Editor'),
			productEditor: this.renderPageName('Brand Product Manager Editor'),
			spotlightEditor: this.renderPageName('Spotlight Of The Month Manager Editor'),
			webstoryEditor: this.renderPageName('Web Stories Manager Editor'),
			adminJobs: this.renderPageName('Admin Jobs Page'),
			techSparksManager: this.renderPageName('Offline Event Manager'),
			tracks: this.renderPageName('Tracks'),
			speakers: this.renderPageName('Home Page Speakers'),
			viewAllSpeakers: this.renderPageName('View All Speakers'),
			viewAllPartners: this.renderPageName('View All Partners'),
			agendas: this.renderPageName('Home Page Agendas'),
			adRefresh: this.renderPageName('AD Refresh Config Manager'),
			liveFeedManager: this.renderPageName('Live Feed Manager'),
			viewAllCategories: this.renderPageName('Listing Category Manager'),
			viewAllProfileListings: this.renderPageName('Listing Profile Manager'),
			profileBio: this.renderPageName('Profile Bio Page'),
			topicManager: this.renderPageName('Topic Manager'),
			captableGiftCodeManager: this.renderPageName('Captable Gift Code Manager'),
			redemptionGiftCodeManager: this.renderPageName('Redemption Gift Code Manager'),
			404: this.renderPageName('Not Found'),
		};

		return titles[page];
	}

	renderNavMenu() {
		const { user, page } = this.props;
		return (
			<NavWrapper>
				{
					user.employee
						? <Link to="/" className="xs-hide">
							<NavItem className={page === 'dashboard' ? 'active' : ''}>
								Dashboard
							</NavItem>
						</Link>
						: null
				}
				<Link to="/stories">
					<NavItem className={page === 'stories' ? 'active' : ''}>
						Stories
					</NavItem>
				</Link>
				<Link to="/profile/bio">
					<NavItem>Profile Bio</NavItem>
				</Link>
				{/* <NavItem className="xs-hide sm-hide">
										<div className="announcements">
												<i className="fa fa-bullhorn" aria-hidden="true"></i>
										</div>
								</NavItem> */}
			</NavWrapper>
		);
	}

	renderPageName(pageName) {
		const pathnameSplit = this.props.location.pathname.split('/');
		if (['Vertical Manager', 'Award Manager', 'Influencer Manager', 'City Manager', 'Company Desk', 'Company Editor', 'View Companies', 'Tag Stories', 'Tag Company To Stories', 'Tag Influencer To Stories', 'Influencer Desk', 'View Influencers', 'Profile Claim Desk', 'Awards Question Manager'].includes(pageName)) {
			return (
				<div style={{
					display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center',
				}}
				>
					<h2>{pageName}</h2>
					<Link to="/manager/company" rel="noopener noreferrer">
						<Button primary primary_outline btn_xs><b>Go to Company Dashboard</b></Button>
					</Link>
				</div>
			);
		}
		if (['Webinars', 'Custom Fields'].includes(pageName) || (pathnameSplit[1] === 'editor' && pathnameSplit[2] === 'webinar')) {
			return (
				<div style={{
					display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center',
				}}
				>
					<h2>{pageName}</h2>
					<Link to="/manager/webinar" rel="noopener noreferrer">
						<Button primary primary_outline btn_xs><b>Go back to Webinar Dashboard</b></Button>
					</Link>
				</div>
			);
		}
		if (['Invite User', 'Create Alias', 'Update Alias'].includes(pageName)) {
			return (
				<div style={{
					display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center',
				}}
				>
					<h2>{pageName}</h2>
					<Link to="/manager/user" rel="noopener noreferrer">
						<Button primary primary_outline btn_xs><b>Go back to User Manager</b></Button>
					</Link>
				</div>
			);
		}
		if (['Job Editor'].includes(pageName)) {
			return (
				<div style={{
					display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center',
				}}
				>
					<h2>{pageName}</h2>
					<Link to="/manager/jobs/list" rel="noopener noreferrer">
						<Button primary primary_outline btn_xs><b>Go back to Jobs Manager</b></Button>
					</Link>
				</div>
			);
		}
		if (['Subscription List', 'Subscription Editor', 'Subscription Status'].includes(pageName)) {
			return (
				<div style={{
					display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center',
				}}
				>
					<h2>{pageName}</h2>
					<Link to="/manager/subscription" rel="noopener noreferrer">
						<Button primary primary_outline btn_xs><b>Go back to Subscriptions Manager</b></Button>
					</Link>
				</div>
			);
		}
		if (['Brands', 'Brands Category', 'Product Category', 'Spotlight Of Month', 'Product Manager', 'Webstory Manager', 'External Link Manager', 'Brand of The Day Manager', 'Brand Editor',
			'Brand Category Editor', 'Product Category Editor', 'Product Editor', 'Spotlight Of Month Editor', 'Webstory Editor', 'External Link Editor',
		].includes(pageName)) {
			return (
				<div style={{
					display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center',
				}}
				>
					<h2>{pageName}</h2>
					<Link to="/manager/boni" rel="noopener noreferrer">
						<Button primary primary_outline btn_xs><b>Go Back to BONI Dashboard</b></Button>
					</Link>
				</div>
			);
		}
		if (['Listing Profile Manager',
		].includes(pageName)) {
			return (
				<div style={{
					display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center',
				}}
				>
					<h2>{pageName}</h2>
					<Link to="/manager/category-listings" rel="noopener noreferrer">
						<Button primary primary_outline btn_xs><b>Go Back to Listings Category Dashboard</b></Button>
					</Link>
				</div>
			);
		}
		if (['Web Stories Manager Editor',
		].includes(pageName)) {
			return (
				<div style={{
					display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center',
				}}
				>
					<h2>{pageName}</h2>
					<Link to="/dashboard/web-stories" rel="noopener noreferrer">
						<Button primary primary_outline btn_xs><b>Go Back to Dashboard Web Stories</b></Button>
					</Link>
				</div>
			);
		}
		if (['Tracks', 'Home Page Speakers', 'View All Speakers', 'View All Partners', 'Home Page Agendas',
		].includes(pageName)) {
			return (
				<div style={{
					display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center',
				}}
				>
					<h2>{pageName}</h2>
					<Button
						primary
						primary_outline
						btn_xs
						onClick={() => {
							const extractCityName = window.location.pathname.split('/');
							const lastIndex = extractCityName && extractCityName?.length > 0 ? extractCityName.length - 1 : '';
							if (window.location.pathname && extractCityName && extractCityName[lastIndex] === ('mumbai')) {
								this.props.history.push('/manager/offlineEvent/mumbai');
							} else if (window.location.pathname && extractCityName && extractCityName[lastIndex] === ('bangalore')) {
								this.props.history.push('/manager/offlineEvent/bangalore');
							} else if (window.location.pathname && extractCityName && extractCityName[lastIndex] === ('techsparks_2023')) {
								this.props.history.push('/manager/offlineEvent/techsparks_2023');
							} else if (window.location.pathname && extractCityName && extractCityName[lastIndex] === ('techsparks_2023_bangalore')) {
								this.props.history.push('/manager/offlineEvent/techsparks_2023_bangalore');
							} else if (window.location.pathname && extractCityName && extractCityName[lastIndex] === ('techsparks_2023_delhi')) {
								this.props.history.push('/manager/offlineEvent/techsparks_2023_delhi');
							} else if (window.location.pathname && extractCityName && extractCityName[lastIndex] === ('techsparks_2023_mumbai')) {
								this.props.history.push('/manager/offlineEvent/techsparks_2023_mumbai');
							} else {
								this.props.history.push('/manager/offlineEvent');
							}
						}}
					>
						<b>Go Back to Offline Event Manager Dashboard</b>
					</Button>
				</div>
			);
		}
		if (['Redemption Gift Code Manager',
		].includes(pageName)) {
			return (
				<div style={{
					display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center',
				}}
				>
					<h2>{pageName}</h2>
					<Link to="/manager/captable/gift-codes" rel="noopener noreferrer">
						<Button primary primary_outline btn_xs><b>Go Back to Dashboard Captable Gift Codes Manager</b></Button>
					</Link>
				</div>
			);
		}
		return (
			<div>
				<h2>{pageName}</h2>
			</div>
		);
	}

	render() {
		const { logout, user } = this.props;
		return (
			<Header>
				<HeaderWrapper>
					<HeaderContainer>
						<Col xs={2} sm={1}>
							<Link to="/">
								<LogoIcon src={csIcon} alt="Logo" title="Creator Studio" />
							</Link>
						</Col>
						<Col xs={8} sm={9.5}>
							<Row style={{ alignItems: 'center' }}>
								{this.renderNavContent()}
							</Row>
						</Col>
						<Col sm={0.5} className="xs-hide">
							{this.getOnlineStatus()}
						</Col>
						<Col xs={2} sm={1} className="text-right">
							<div style={{ position: 'relative' }}>
								<Button className="b-0 m-0" id="user-menu" onClick={this.handleUserMenuClick}>
									<img className="profile-img" src={user.profileImg && user.profileImg.length > 0 ? user.profileImg : profileImagePlaceholder} alt="Profile" id="user-menu" onClick={this.handleUserMenuClick} />
								</Button>
								<UserMenu style={{ display: `${this.state.userMenu ? 'block' : 'none'}` }}>
									<a href={`${MY_ACCOUNT_BASE_URL}/my-account`} style={{ width: '100%' }}>
										<UserMenuItem>{user.name}</UserMenuItem>
									</a>
									<button type="button" className="text-left p-0 w-100" onClick={logout}><UserMenuItem>Logout</UserMenuItem></button>
								</UserMenu>
							</div>
						</Col>
					</HeaderContainer>
				</HeaderWrapper>
			</Header>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	page: makeSelectAppPageName(),
	user: makeSelectAppUser(),
});

DashboardHeader.propTypes = {
	page: PropTypes.string,
	logout: PropTypes.func,
	user: PropTypes.object,
	online: PropTypes.bool,
	location: PropTypes.object,
	history: PropTypes.object,
};

export default connect(mapStateToProps)(DashboardHeader);
