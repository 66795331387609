import { createSelector } from 'reselect';

const selectRedirections = (state) => state.get('redirection');

const makeSelectRedirections = () => createSelector(
	selectRedirections,
	(redirectionState) => redirectionState.get('redirections'),
);

const makeSelectRedirectionLoading = () => createSelector(
	selectRedirections,
	(redirectionState) => redirectionState.get('loading'),
);

const makeSelectRedirectionError = () => createSelector(
	selectRedirections,
	(redirectionState) => redirectionState.get('error'),
);

const makeSelectSelectedRedirection = () => createSelector(
	selectRedirections,
	(redirectionState) => redirectionState.get('selectedRedirection'),
);

const makeSelectEditRedirection = () => createSelector(
	selectRedirections,
	(redirectionState) => redirectionState.get('editRedirection'),
);

const makeSelectRedirectionSearchValue = () => createSelector(
	selectRedirections,
	(redirectionState) => redirectionState.get('searchValue'),
);

const makeSelectRedirectionFormData = () => createSelector(
	selectRedirections,
	(redirectionState) => redirectionState.get('formData'),
);

const makeSelectRedirectionFormMessage = () => createSelector(
	selectRedirections,
	(redirectionState) => redirectionState.get('formMessage'),
);

const makeSelectRedirectionTablePage = () => createSelector(
	selectRedirections,
	(redirectionState) => redirectionState.get('tablePage'),
);

export {
	makeSelectRedirections,
	makeSelectEditRedirection,
	makeSelectRedirectionError,
	makeSelectRedirectionFormData,
	makeSelectRedirectionLoading,
	makeSelectRedirectionSearchValue,
	makeSelectSelectedRedirection,
	makeSelectRedirectionFormMessage,
	makeSelectRedirectionTablePage,
};
