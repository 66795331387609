import { Map } from 'immutable';
import {
	USER_LOGOUT_SUCCESS, USER_LOGOUT_ERROR, SET_PAGE_NAME, SET_ONLINE_STATUS, FETCH_APP_DATA, FETCH_APP_DATA_SUCCESS, FETCH_APP_DATA_FAILURE, SET_CURRENT_BRAND,
} from './type';

const initialState = Map({
	user: null,
	loading: true,
	error: null,
	pageName: null,
	online: true,
	userBrands: null,
	currentBrand: null,
});

function appReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_APP_DATA:
		return state
			.set('loading', true);
	case FETCH_APP_DATA_SUCCESS:
		return state
			.set('userBrands', action.userBrands)
			.set('user', action.user)
			.set('loading', false);
	case FETCH_APP_DATA_FAILURE:
		return state
			.set('loading', false)
			.set('error', action.error);
	case USER_LOGOUT_SUCCESS: {
		const user = {
			id: null,
			name: null,
			email: null,
			profileImg: null,
		};
		return state
			.set('user', user)
			.set('error', null);
	}
	case USER_LOGOUT_ERROR:
		return state
			.set('error', action.error);
	case SET_PAGE_NAME:
		return state
			.set('pageName', action.page);
	case SET_ONLINE_STATUS:
		return state
			.set('online', action.value);
	case SET_CURRENT_BRAND:
		return state
			.set('currentBrand', action.brand);
	default:
		return state;
	}
}

export default appReducer;
