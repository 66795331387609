import React from 'react';
import Tooltip from 'react-tooltip';
import styled from 'styled-components';
import T from 'prop-types';
import InfoIcon from '../../images/info.png';

const Title = styled.div`
		font-size: 1rem;
		font-weight: 700;
`;

const Image = styled.img`
		height: 15px;
		width: 15px;
		display: inline-block !important;
`;

const TitleWithToolTip = (props) => <div>
	{!props.for && <>
		<Tooltip effect="solid" data-multiline place={props.position ? props.position : 'top'} className="tooltip-width" />
		<Title>
			{props.title ? props.title : null}
			&ensp;
			<Image src={InfoIcon} data-tip={props.hint} />
		</Title>
	</>}
	{props.for && <>
		<Title>
			{props.title ? props.title : null}
			&ensp;
			<Image src={InfoIcon} data-for={props.for} data-tip />
		</Title>
		<Tooltip
			id={props.for}
			aria-haspopup="true"
			effect="solid"
			data-multiline
			place={props.position ? props.position : 'top'}
			className="tooltip-width"
		>
			<div dangerouslySetInnerHTML={{ __html: props.hint }} />
		</Tooltip>
	</>}
</div>;

TitleWithToolTip.propTypes = {
	hint: T.string,
	position: T.string,
	title: T.string,
	for: T.string,
};

export default TitleWithToolTip;
