export const CATEGORY_API_BASE_URL = '/api/v2/rw/categories';
export const CATEGORY_API_BASE_URL_UNPROTECTED = '/api/v2/category';
export const STORY_METADATA_BASE_URL = '/api/v2/rw/storymetadata';
export const BASE_URL = process.env.REACT_APP_SERVICE_PWA;
export const REDIRECTION_BASE_API_URL = '/api/v2/rw/redirections';

export const CATEGORY_CREATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Category Saved Successfully' };
export const CATEGORY_CREATE_LOAD_MESSAGE = { intent: 'info', message: 'Saving...' };
export const CATEGORY_UPDATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Category Updated Successfully' };
export const CATEGORY_UPDATE_LOAD_MESSAGE = { intent: 'info', message: 'Updating...' };
export const CATEGORY_DELETE_SUCCESS_MESSAGE = { intent: 'success', message: 'Category Deleted Successfully' };
export const CATEGORY_DELETE_LOAD_MESSAGE = { intent: 'info', message: 'Deleting...' };
export const CATEGORY_REPLACE_SUCCESS_MESSAGE = { intent: 'success', message: 'Category replaced successfully' };
export const CATEGORY_REPLACE_LOAD_MESSAGE = { intent: 'info', message: 'Replacing...' };
export const CATEGORY_ERROR_MESSAGE = { intent: 'danger', message: 'ERROR!!!' };
export const CATEGORY_REDIRECTION_ERROR = { intent: 'danger', message: 'Category redirection unsuccessful' };
export const CATEGORY_LOADING_MESSAGE = { intent: 'info', message: 'Loading...' };
