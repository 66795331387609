import React, { Fragment } from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';
import Tabs from '../Tabs';
import { Button } from '../Styles';

const Bar = styled(Row)`
		width: 100%;
		box-shadow: 1px 2px 3px 0px #DDDDDD;
		margin: 0;
		align-items: center;
`;

const TabHeader = (props) => {
	const {
		tabs, value, onTabSelect, updatesCount, addNewLabel, hoc, onAddNew, disableAddButton,
	} = props;

	return (
		<Bar>
			<Col xs={12} md={4} className="p-0">
				<Tabs
					tabs={tabs}
					value={value}
					onSelect={onTabSelect}
					updatesCount={updatesCount}
					noShadow
				/>
			</Col>
			{
				!['basicInfo', 'influencer'].includes(hoc) && !disableAddButton
					? <>
						<Col xs={12} md={4}>
							{/* <Input
																value={searchValue}
																placeholder={searchPlaceholder}
																onChange={onSearchKeywordChange}
														/> */}
						</Col>
						<Col xs={12} md={4} className="text-right">
							<Button
								primary
								onClick={onAddNew}
								no_radius
								className="mb-0"
							>
								<b>{addNewLabel}</b>
							</Button>
						</Col>
					</>
					: null
			}
		</Bar>
	);
};

TabHeader.propTypes = {
	hoc: T.string,
	onAddNew: T.func,
	tabs: T.array,
	value: T.string,
	onTabSelect: T.func,
	updatesCount: T.number,
	disableAddButton: T.bool,
	addNewLabel: T.string,
};

export default TabHeader;
