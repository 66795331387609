export const SELECT_BRAND_SPOTLIGHT = 'SELECT_BRAND_SPOTLIGHT';
export const ADD_BRAND_SPOTLIGHT = 'ADD_BRAND_SPOTLIGHT';
export const BRAND_SPOTLIGHT_ELEMENT_ON_CHANGE = 'BRAND_SPOTLIGHT_ELEMENT_ON_CHANGE';
export const WRITE_BRAND_SPOTLIGHT_SLUG = 'WRITE_BRAND_SPOTLIGHT_SLUG';
export const CREATE_BRAND_SPOTLIGHT = 'CREATE_BRAND_SPOTLIGHT';
export const CREATE_BRAND_SPOTLIGHT_SUCCESS = 'CREATE_BRAND_SPOTLIGHT_SUCCESS';
export const CREATE_BRAND_SPOTLIGHT_ERROR = 'CREATE_BRAND_SPOTLIGHT_ERROR';

export const UPDATE_BRAND_SPOTLIGHT = 'UPDATE_BRAND_SPOTLIGHT';
export const UPDATE_BRAND_SPOTLIGHT_SUCCESS = 'UPDATE_BRAND_SPOTLIGHT_SUCCESS';
export const UPDATE_BRAND_SPOTLIGHT_ERROR = 'UPDATE_BRAND_SPOTLIGHT_ERROR';

export const UNMOUNT_BRAND_SPOTLIGHT_FORM_MESSAGE = 'UNMOUNT_BRAND_SPOTLIGHT_FORM_MESSAGE';
export const SET_BRAND_SPOTLIGHT_SLUG_UNIQUE_VALUE = 'SET_BRAND_SPOTLIGHT_SLUG_UNIQUE_VALUE';
export const CLEAR_BRAND_SPOTLIGHT_DATA = 'CLEAR_BRAND_SPOTLIGHT_DATA';
