import { fromJS } from 'immutable';

export const CHATBOTS_API_BASE_URL = '/api/v2/rw/messenger-promotions/chatbots';
export const PROMOTIONS_API_BASE_URL = '/api/v2/rw/messenger-promotions';

export const promotionForm = fromJS({
	title: '',
	subtitle: '',
	cta: '',
	brand: 2,
	media: '',
});

export const promotionItems = [
	{
		name: 'brand',
		displayName: 'Brand',
		type: 'dropdown',
	},
	{
		name: 'title',
		displayName: 'Title',
		type: 'text',
	},
	{
		name: 'subtitle',
		displayName: 'Description',
		type: 'text',
	},
	{
		name: 'cta',
		displayName: 'Call to Action',
		type: 'text',
	},
	{
		name: 'media',
		displayName: 'Feature Image',
		type: 'text',
	},
];
