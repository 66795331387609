import { createSelector } from 'reselect';

const selectProfileList = (state) => state.get('profileList');

const makeSelectListProfiles = () => createSelector(
	selectProfileList,
	(profileListState) => profileListState.get('allProfiles'),
);

const makeSelectProfileListLoading = () => createSelector(
	selectProfileList,
	(profileListState) => profileListState.get('loading'),
);

const makeSelectProfileListLimit = () => createSelector(
	selectProfileList,
	(profileListState) => profileListState.get('limit'),
);

const makeSelectProfileListOffset = () => createSelector(
	selectProfileList,
	(profileListState) => profileListState.get('offset'),
);

const makeSelectFetchedProfiles = () => createSelector(
	selectProfileList,
	(profileListState) => profileListState.get('profiles'),
);

const makeSelectProfileListSearchType = () => createSelector(
	selectProfileList,
	(profileListState) => profileListState.get('searchType'),
);

const makeSelectProfileListSearch = () => createSelector(
	selectProfileList,
	(profileListState) => profileListState.get('search'),
);

const makeSelectProfileListSearchValue = () => createSelector(
	selectProfileList,
	(profileListState) => profileListState.get('searchValue'),
);

const makeSelectProfileListSortValue = () => createSelector(
	selectProfileList,
	(profileListState) => profileListState.get('sortValue'),
);

export {
	makeSelectListProfiles,
	// makeSelectProfileListPage,
	makeSelectProfileListLoading,
	makeSelectProfileListLimit,
	makeSelectProfileListOffset,
	makeSelectFetchedProfiles,
	makeSelectProfileListSearchType,
	makeSelectProfileListSearch,
	makeSelectProfileListSearchValue,
	makeSelectProfileListSortValue,
};
