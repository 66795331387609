/* eslint-disable no-unused-vars */
import React, {
	useReducer, useEffect, useState, useRef, useMemo,
} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Row, Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import { Input, Button } from '../Styles';
import { ImageHolder, AsyncMultiSelect } from '../FormComponents';
// import { validateImage } from '../../utils/common';
import {
	SAVING_FAILURE_MESSAGE, SAVING_MESSAGE, SAVING_SUCCESS_MESSAGE, toolbarOptions, TOPICS_BASE_URL,
} from '../../containers/TopicManager/constants';
import ToastMessage from '../ToastMessage';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import { generateStoryUrlSlug } from '../../utils/storyUrlGeneration';
import { getDropdownOptions } from '../../utils/common';

const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
const UPDATE_ERRORS = 'UPDATE_ERRORS';
const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
const SET_FORM_DATA = 'SET_FORM_DATA';

const { Quill } = ReactQuill;
const Link = Quill.import('formats/link');
Link.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'radar', 'rdar', 'smb', 'sms'];
class CustomLink extends Link {
	static sanitize(url) {
		if (url.startsWith('fmp')) {
			return url;
		}
		const value = super.sanitize(url);
		return value;
	}
	// static create(value) {
	// 	const node = super.create(value);
	// 	value = this.sanitize(value);
	// 	node.setAttribute('href', value);
	// 	node.setAttribute('rel', 'noopener nofollow');
	// 	if (!value.startsWith('https://yourstory.com')) {
	// 		node.setAttribute('target', '_blank');
	// 	}
	// 	return node;
	// }
	// // static formats(domNode) {
	// // 	return domNode.getAttribute('href');
	// // }
	// // static sanitize(url) {
	// // 	return sanitize(url, this.PROTOCOL_WHITELIST) ? url : this.SANITIZED_URL;
	// // }
	// // format(name, value) {
	// // 	if (name !== this.statics.blotName || !value) {
	// // 		super.format(name, value);
	// // 	} else {
	// // 		this.domNode.setAttribute('href', this.constructor.sanitize(value));
	// // 	}
	// // }
	// static sanitize(url) {
	// 	// Run default sanitize method from Quill
	// 	const sanitizedUrl = super.sanitize(url);

	// 	// Not whitelisted URL based on protocol so, let's return `blank`
	// 	if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl;

	// 	// Verify if the URL already have a whitelisted protocol
	// 	const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some(function(protocol) {
	// 	  return sanitizedUrl.startsWith(protocol);
	// 	})

	// 	if (hasWhitelistedProtocol) return sanitizedUrl;

	// 	// if not, then append only 'http' to not to be a relative URL
	// 	return `http://${sanitizedUrl}`;
	// }
}
// CustomLink.SANITIZED_URL = 'about:blank';
// CustomLink.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel'];

// function sanitize(url, protocols) {
// 	const anchor = document.createElement('a');
// 	anchor.href = url;
// 	const protocol = anchor.href.slice(0, anchor.href.indexOf(':'));
// 	return protocols.indexOf(protocol) > -1;
// }
Quill.register(CustomLink);
// overwritting counter module
// Quill.register('modules/counter', (quill) => {
// 	const container = document.querySelector('#TopicDescriptionCounter');
// 	quill.on('text-change', () => {
// 		const text = quill.getText();
// 		const length = text?.length > 0 ? quill.getText()?.length - 1 : 0;
// 		container.innerText = `${length} ${length === 1 ? '/ 800' : '/ 800'}`;
// 	});
// });

const initialState = {
	formData: {
		id: null,
		description: '',
		image: '',
		name: null,
		slug: '',
		minDescription: 0,
		maxDescription: 0,
		companies: [],
	},
	blurredElements: {
		description: false,
		image: false,
		name: false,
		slug: false,
		minDescription: false,
		maxDescription: false,
		companies: false,
	},
	errors: {
		description: false,
		image: false,
		name: false,
		slug: false,
		minDescription: false,
		maxDescription: false,
		companies: false,
	},
	formMessage: null,
};

function updateFormData(changedData) {
	return {
		type: UPDATE_FORM_DATA,
		changedData,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function setFormData(topic) {
	return {
		type: SET_FORM_DATA,
		topic,
	};
}

function setFormMessage(message) {
	return {
		type: SET_FORM_MESSAGE,
		message,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case SET_FORM_DATA:
		return { ...state, formData: action.topic };

	case UPDATE_FORM_DATA: {
		const formData = { ...state.formData };
		const { changedData } = action;
		formData[changedData.name] = changedData.value;
		return { ...state, formData };
	}

	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };

	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			description: true,
			image: true,
			name: true,
			slug: true,
			minDescription: true,
			maxDescription: true,
			companies: true,
		};
		return { ...state, blurredElements };
	}

	case SET_FORM_MESSAGE:
		return { ...state, formMessage: action.message };

	default:
		return state;
	}
}

const TopicForm = (props) => {
	const {
		topic, getTopicListings, unmountModal,
	} = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		formData, blurredElements, errors, formMessage,
	} = state;
	const [isButtonDisabled, disableButton] = useState(false);
	const [toImageUpdate, setToImageUpdate] = useState(false);
	const quillRef = useRef(null);
	// const [descriptionCount, setDescriptionCount] = useState(0);
	const modules = useMemo(
		() => ({
			toolbar: {
				container: toolbarOptions,
			},
			history: {
				delay: 500,
				maxStack: 100,
			},
			// counter: {
			// 	container: '#TopicDescriptionCounter',
			// 	unit: 'character',
			// },
		}),
		[],
	);
	// 	history: {
	// 		delay: 500,
	// 		maxStack: 100,
	// 	},
	// 	counter: {
	// 		container: '#TopicDescriptionCounter',
	// 		unit: 'character',
	// 	},
	// 	toolbar: toolbarOptions,
	// }}

	useEffect(() => {
		if (topic || topic?.id) {
			const TopicData = { ...topic, image: { url: topic?.image, file: null } };
			dispatch(setFormData(TopicData));
			if ((quillRef.current !== undefined && quillRef.current !== null && TopicData.description?.length > 0)) {
				quillRef.current.editor.clipboard.dangerouslyPasteHTML(TopicData?.description?.replaceAll('&nbsp;', ' '));
				addRangeForQuillEditor(quillRef);
				// quillRef.current.editor.container.innerHTML = TopicData.description;
			}
		}
	}, []);

	useEffect(() => {
		getErrors();
	}, [formData, blurredElements]);

	function onInputChange(e) {
		const changedData = {
			name: e.target.name,
			value: e.target.value,
		};
		dispatch(updateFormData(changedData));
	}

	function validateForm(topic) {
		return {
			// image: !topic.image || topic.image.length < 1,
			// designation: !topic.designation || topic.designation.length < 1,
			description: !(topic && topic.description && topic.description?.length > 0 && topic.description !== '<p><br></p>'),
			// company: !topic.company || topic.company.length < 1,
			name: !topic.name || (topic && topic.name && topic?.name?.length > 60),
			slug: !topic.slug || topic.slug.length < 1,
			minDescription: !topic.minDescription || (topic && topic.minDescription && topic?.minDescription < 100),
			maxDescription: !topic.maxDescription || (topic && topic.maxDescription && topic?.maxDescription > 250),
		};
	}

	function getErrors() {
		const validationErrors = validateForm(formData);
		const errors = Object.keys(validationErrors).reduce((acc, curr) => {
			if (validationErrors[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	}

	function getTopicBody(topic) {
		// const formData = new FormData();
		const formData = {
			slug: '',
			description: '',
			image: '',
			name: '',
			companies: [],
		};
		// const id = uuid();
		// if (toUpdate) {
		// 	formData.id = topic?.id ?? id;
		// 	// formData.append('id', Topic?.id || id);
		// } else {
		// 	formData.id = id;
		// 	// formData.append('id', id);
		// }
		formData.name = topic.name;
		formData.slug = topic.slug;
		formData.companies = returnCompanyIdArr(topic.companies);
		formData.description = topic.description && topic.description === '<p><br></p>' ? '' : topic?.description.replaceAll(/\s/g, '&nbsp;');
		formData.image = topic.image && topic.image.file ? topic.image.url : topic.image.url || '';
		return formData;
	}

	function onFormSubmit(e) {
		e.preventDefault();
		const validation = validateForm(formData);
		const validated = !Object.keys(validation).some((i) => validation[i]);
		if (validated) {
			saveTopic();
		} else {
			dispatch(setAllElementsBlurred());
		}
	}

	function handleBlur(element) {
		dispatch(updateBlurredElement(element));
	}

	async function saveTopic() {
		try {
			disableButton(true);
			dispatch(setFormMessage(SAVING_MESSAGE));
			const payload = getTopicBody(formData);
			const options = {
				method: topic?.id ? 'PATCH' : 'POST',
				credentials: 'include',
				body: JSON.stringify(payload),
				headers: {
					'Content-Type': 'application/json',
				},
			};
			const url = `${topic?.id ? `${TOPICS_BASE_URL}/${topic?.id}` : `${TOPICS_BASE_URL}`}`;
			const response = await fetch(url, options);
			disableButton(false);
			if (response.status === 200) {
				dispatch(setFormMessage(SAVING_SUCCESS_MESSAGE));
				getTopicListings();
				unmountModal();
			} else {
				dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			}
		} catch (err) {
			dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			disableButton(false);
			console.log('err', err);
		}
	}

	const uploadImages = async ({ file }) => {
		setToImageUpdate(true);
		try {
			const newForm = new FormData();
			newForm.append('imageFile', file);
			const options = {
				method: 'POST',
				credentials: 'include',
				body: newForm,
			};
			const resp = await fetch('/api/v2/rw/assets/upload/image', options);
			const imageUrl = await resp.json();
			setToImageUpdate(false);
			dispatch(updateFormData({
				name: 'image',
				value: {
					url: imageUrl,
					file,
				},
			}));
		} catch (err) {
			console.log('S3 image upload error: ', err);
			setToImageUpdate(false);
		}
	};

	async function onUpload(e) {
		e.preventDefault();
		const file = e.target.files[0];
		if (file?.size && (file?.size / 1024 / 1024 > 5)) {
			return alert(`File size greater than 5 MB (${(file?.size / 1024 / 1024).toFixed(2)} MB)!`);
		}
		const reader = new FileReader();
		reader.onloadend = () => {
			uploadImages({ file });
		};
		reader.readAsDataURL(file);
	}

	function onUploadClick(e) {
		e.preventDefault();
		const logoInput = document.getElementById('Topic-listing-image');
		logoInput.click();
	}

	function onImageLoad(e) {
		if (formData.image.file) {
			const data = { height: e.target.naturalHeight, width: e.target.naturalWidth };

			if (!validateImage(data.height, data.width)) {
				document.getElementById('Topic-listing-image').value = '';
				dispatch(updateFormData({
					name: 'image',
					value: {
						url: null,
						file: null,
					},
				}));
			} else dispatch(updateFormData({ name: 'image', value: { ...formData.image, ...data } }));
		}
	}

	function validateImage(height, width) {
		if (height !== width) {
			return alert(`Topic Pic should be square! Current image is ${width}x${height}`);
		} if (height < 200 && width < 200) {
			return alert(`Topic Pic should have a least resolution of 200x200. Current image is ${width}x${height}`);
		} if (height > 1024 && width > 1024) {
			return alert(`Topic Pic has exceeding resolution of 1024x1024. Current image is ${width}x${height}`);
		}
		return true;
	}

	// update Topic Description
	function updateTopicDescriptionEditorData(content) {
		let text = '';
		let length = 0;
		if (quillRef.current !== undefined && quillRef.current !== null) {
			const textWords = quillRef.current.editor.getText();
			const lengthOfWOrds = textWords.match(/\S+/g) ? textWords.match(/\S+/g).length : 0;
			if (lengthOfWOrds < 100) {
				const res = quillRef.current.editor.getText().trim()?.length === 0;
				dispatch(updateFormData({ name: 'description', value: res ? '' : quillRef.current.editor.root.innerHTML }));
			} else {
				dispatch(updateFormData({ name: 'description', value: quillRef.current.editor.root.innerHTML }));
			}
			dispatch(updateFormData({ name: 'minDescription', value: lengthOfWOrds }));
			dispatch(updateFormData({ name: 'maxDescription', value: lengthOfWOrds }));
		} else {
			text = content.replace(/<\/?[^>]+(>|$)/g, '');
			length = text.length > 0 ? text.length : 0;
			dispatch(updateFormData({ name: 'minDescription', value: length === 0 ? 0 : length }));
			dispatch(updateFormData({ name: 'maxDescription', value: length === 0 ? 0 : length }));
			dispatch(updateFormData({ name: 'description', value: content }));
		}

		// if ((quillRef.current !== undefined && quillRef.current !== null) && quillRef.current.editor.getLength() > 800) {
		// 	quillRef.current.editor.deleteText(800, quillRef.current.editor.getLength());
		// 	text = quillRef.current.editor.getText();
		// 	length = text.length > 0 ? quillRef.current.editor.getText().length - 1 : 0;
		// 	dispatch(updateFormData({ name: 'minDescription', value: length }));
		// 	dispatch(updateFormData({ name: 'description', value: quillRef.current.editor.root.innerHTML }));
		// } else if ((quillRef.current !== undefined && quillRef.current !== null) && quillRef.current.editor.getLength() < 800) {
		// 	const res = quillRef.current.editor.getText().trim()?.length === 0;
		// 	text = quillRef.current.editor.getText();
		// 	length = text.length > 0 ? quillRef.current.editor.getText().length - 1 : 0;
		// 	dispatch(updateFormData({ name: 'minDescription', value: length === 0 ? 0 : length }));
		// 	dispatch(updateFormData({ name: 'description', value: res ? '' : quillRef.current.editor.root.innerHTML }));
		// } else {
		// 	text = content.replace(/<\/?[^>]+(>|$)/g, '');
		// 	length = text.length > 0 ? text.length : 0;
		// 	dispatch(updateFormData({ name: 'minDescription', value: length === 0 ? 0 : length }));
		// 	dispatch(updateFormData({ name: 'description', value: content }));
		// }
	}

	function addRangeForQuillEditor(quillRef) {
		const range = quillRef.current.editor.getSelection();
		// quillRef.current.focus();
		const textWords = quillRef.current.editor.getText();
		const lengthOfWOrds = textWords.match(/\S+/g) ? textWords.match(/\S+/g).length : 0;
		dispatch(updateFormData({ name: 'minDescription', value: lengthOfWOrds }));
		dispatch(updateFormData({ name: 'maxDescription', value: lengthOfWOrds }));
		(range && range?.index) ? quillRef.current.editor.setSelection(range.index + 1, Quill.sources.SILENT) : '';
	}

	function renderFormMessage() {
		if (formMessage) {
			return (
				<ToastMessage
					toastData={formMessage}
					unmount={() => dispatch(setFormMessage(null))}
				/>
			);
		}
		return null;
	}

	const returnCompanyIdArr = (value) => {
		const filteredValue = [];
		if (value?.length > 0) {
			value.forEach((item) => {
				filteredValue.push(item?.id);
			});
		}
		return filteredValue;
	};

	return (
		<form onSubmit={onFormSubmit}>
			{renderFormMessage()}
			<Row>
				<Col xs={12} style={{ textAlign: 'left', color: 'red' }}>
					<p>Note: Please check Slug and Name they are should be unique among all list data.</p>
				</Col>
				<Col sm={12}>
					<Row className="mb-3 mt-2">
						<Col sm={4} xs={12}>
							Image
						</Col>
						<Col xs={12} sm={8}>
							<Input
								id="Topic-listing-image"
								type="file"
								style={{ display: 'none' }}
								name="image"
								onChange={onUpload}
								accept="image/png, image/jpeg"
								onBlur={() => handleBlur('image')}
							/>
							{(formData && !formData?.image?.url && toImageUpdate)
								? <LoadingIcon />
								: <ImageHolder
										showImageUploader={onUploadClick}
										imageUrl={formData && formData.image?.url ? formData.image?.url : null}
										onImageLoad={onImageLoad}
										squareDimension="14rem"
										clearImage={() => {
											document.getElementById('Topic-listing-image').value = '';
											dispatch(updateFormData({ name: 'image', value: null }));
										}}
								/>}
							<ErrorMessage display={errors.image} element="Topic Image" />
						</Col>
					</Row>
					{/* name  */}
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Name *
						</Col>
						 <Col sm={8} xs={12}>
							<Input
								name="name"
								type="text"
								maxLength={60}
								className="form-control"
								placeholder="Name"
								onChange={(event) => {
									const slugName = `${event.target.value ? `${generateStoryUrlSlug(event.target.value)}` : ''}`;
									const changedData = {
										name: 'slug',
										value: slugName,
									};
									dispatch(updateFormData(changedData));
									onInputChange(event);
								}}
								value={formData.name ?? ''}
								onBlur={() => handleBlur('name')}
							/>
							<Row>
								<Col xs={8}>
									<ErrorMessage display={errors.name} element="Name" />
									{(errors.name && formData?.name?.length > 60) && <p className="text-danger" style={{ color: 'red', fontSize: '13px' }}>
										Maximum 60 characters be there for name
									</p>}
								</Col>
								<Col xs={4}>
									<div style={{
										textAlign: 'right',
										color: formData?.name && formData.name?.length > 60 ? '#ff0000' : '#aaa',
									}}
									>
										{(formData && formData?.name) ? formData.name.length : 0}/60
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
					{/*  */}
					{/* slug */}
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Slug *
						</Col>
						 <Col sm={8} xs={12}>
							<Input
								name="slug"
								type="text"
								className="form-control"
								placeholder="Slug"
								maxLength={1000}
								// onChange={() => {
								// 	const changedData = {
								// 		name: 'slug',
								// 		value: generateStoryUrlSlug(formData.name),
								// 	};
								// 	dispatch(updateFormData(changedData));
								// }}
								value={formData.slug ? formData.slug : ''}
								onBlur={() => handleBlur('slug')}
								disabled
							/>
							{/* {formData?.slug && formData.slug?.length > 100 && <p className='text-danger' style={{color: 'red', fontSize: '11px'}}>
								Topic Slug should be less than or equal to 100 characters
							</p>} */}
							{/* <div style={{
								textAlign: 'right',
								color: formData?.slug && formData.slug?.length >= 100 ? '#ff0000' : '#aaa',
							}}
							>
								{(formData && formData?.slug) ? formData.slug.length : 0}/100
							</div> */}
							<ErrorMessage display={errors.slug} element="Slug" />
						</Col>
					</Row>
					{/*  */}
					{/* companies */}
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Company
						</Col>
						<Col sm={8} xs={12}>
							<AsyncMultiSelect
								name="company"
								placeholder="Search companies by name"
								options={(value) => getDropdownOptions('company', value, 'story-editor')}
								currentValues={(formData && formData.companies) ? formData.companies : ''}
								handleChange={(value) => {
									const changedData = {
										name: 'companies',
										value,
									};
									dispatch(updateFormData(changedData));
								}}
							/>
						</Col>
					</Row>
					{/*  */}
					{/* description */}
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Description *
						</Col>
						<Col sm={8} xs={12}>
							<ReactQuill
								id="topicDescription"
								name="description"
								ref={(el) => {
									quillRef.current = el;
								}}
								style={{ height: '10rem', marginBottom: '10%' }}
								placeholder="The Topic summary"
								defaultValue={(formData && formData.description) ? formData.description : ''}
								onChange={(content) => {
									updateTopicDescriptionEditorData(content);
									// const changedData = {
									// 	name: 'description',
									// 	value: content,
									// };
									// dispatch(updateFormData(changedData));
								}}
								// modules={{
								// 	history: {
								// 		delay: 500,
								// 		maxStack: 100,
								// 	},
								// 	counter: {
								// 		container: '#TopicDescriptionCounter',
								// 		unit: 'character',
								// 	},
								// 	toolbar: toolbarOptions,
								// }}
								modules={modules}
								onBlur={() => handleBlur('description')}
							/>
							<Row style={{
								marginTop: '10%',
							}}
							>
								<Col xs={8}>
									<ErrorMessage display={errors.description} element="Description" />
									{(errors.minDescription && formData.minDescription < 100) && <p className="text-danger" style={{ color: 'red', fontSize: '13px' }}>
										Minimum 100 Words be there for description
									</p>}
									{(errors.maxDescription && formData.maxDescription > 250) && <p className="text-danger" style={{ color: 'red', fontSize: '13px' }}>
										Maximum 250 Words be there for description
									</p>}
								</Col>
								<Col xs={4}>
									<div
										id="TopicDescriptionCounter"
										style={{
											textAlign: 'right',
											color: formData?.maxDescription && formData.maxDescription > 250 ? '#ff0000' : '#aaa',
										}}
									>
										{formData.minDescription} / 250
										{/* {formData && formData.comment ? retrunFormDataCommentLength() <= 800 ? retrunFormDataCommentLength() : 800 : 0}/800 */}
									</div>
								</Col>
							</Row>
							{/* <ErrorMessage display={errors.description} element="Description" /> */}
						</Col>
					</Row>
					{/*  */}
					<div className="text-right">
						<Button
							success
							type="submit"
							disabled={isButtonDisabled}
						>
							SAVE
						</Button>
					</div>
				</Col>
				{renderFormMessage()}
			</Row>
		</form>
	);
};

TopicForm.propTypes = {
	topic: T.object,
	unmountModal: T.func,
	getTopicListings: T.func,
	// topicIndex: T.number,
};

export default TopicForm;
