import {
	call, put, takeLatest, select,
} from 'redux-saga/effects';
import { FETCH_CONNECT_REQUESTS_BY_STATUS, UPDATE_CONNECT_REQUEST_STATUS, FILTER_CONNECT_REQUESTS_BY_COMPANY } from './type';
import { COMPANY_CONNECTS_BASE_API_URL } from './constants';
import { redirectTo } from '../../utils/accessRedirection';
import { makeSelectLocation } from '../App/selectors';
import {
	fetchConnectRequestsByStatusSuccess, fetchConnectRequestsByStatusFailure, updateConnectRequestStatusSuccess, updateConnectRequestStatusFailure, fetchConnectRequestsByStatus, filterConnectRequestsByCompanySuccess, filterConnectRequestsByCompanyFailure,
} from './actions';

let CURRENT_LOCATION;

function* getRequestsByStatus(action) {
	const url = `${COMPANY_CONNECTS_BASE_API_URL}/status/${action.status.toLowerCase()}/page/${action.page}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const connectRequeststs = yield response.json();
			yield put(fetchConnectRequestsByStatusSuccess(connectRequeststs));
		}
	} catch (err) {
		yield put(fetchConnectRequestsByStatusFailure(err));
	}
}

function* updateConnectRequestStatus(action) {
	const url = `${COMPANY_CONNECTS_BASE_API_URL}/status/${action.requestId}`;
	const payload = new FormData();
	payload.append('status', action.status);
	payload.append('comment', action.comment);
	const options = {
		method: 'PUT',
		credentials: 'include',
		body: payload,
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const connectRequeststs = yield response.json();
			yield put(updateConnectRequestStatusSuccess(connectRequeststs));
			yield put(fetchConnectRequestsByStatus(action.currentTab, action.page));
		}
	} catch (err) {
		yield put(updateConnectRequestStatusFailure(err));
	}
}

function* filterConnectRequests(action) {
	const url = `${COMPANY_CONNECTS_BASE_API_URL}/filter/status/${action.status}/page/${action.page}`;
	const options = {
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'POST',
		credentials: 'include',
		body: JSON.stringify({ company: action.company }),
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const connectRequeststs = yield response.json();
			yield put(filterConnectRequestsByCompanySuccess(connectRequeststs));
		}
	} catch (err) {
		yield put(filterConnectRequestsByCompanyFailure(err));
	}
}

export default function* companyConnectDeskSaga() {
	const location = yield select(makeSelectLocation());
	CURRENT_LOCATION = location.pathname;
	yield takeLatest(FETCH_CONNECT_REQUESTS_BY_STATUS, getRequestsByStatus);
	yield takeLatest(UPDATE_CONNECT_REQUEST_STATUS, updateConnectRequestStatus);
	yield takeLatest(FILTER_CONNECT_REQUESTS_BY_COMPANY, filterConnectRequests);
}
