import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	CREATE_TAG, UPDATE_TAG, DELETE_TAG, REPLACE_TAG,
} from './type';
import {
	createTagFailure, createTagSuccess, updateTagSuccess, deleteTagSuccess, deleteTagFailure, replaceTagSuccess, replaceTagFailure, tagRedirectionFailure, updateTagFailure, tagSelect,
} from './actions';
import { TAG_API_BASE_URL, STORY_METADATA_BASE_URL, REDIRECTION_BASE_API_URL } from './constants';

export function* createTag(action) {
	const url = `${TAG_API_BASE_URL}/`;
	const formData = action.tag;
	const options = {
		method: 'POST',
		body: formData,
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(createTagSuccess(responseMessage));
				yield put(tagSelect(null));
			} else {
				yield put(createTagFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(createTagFailure(err));
	}
}

export function* updateTag(action) {
	const url = `${TAG_API_BASE_URL}/id/${action.id}`;
	const formData = action.updatedData;
	const options = {
		method: 'PUT',
		body: formData,
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(updateTagSuccess(responseMessage));
				yield put(tagSelect(null));
			} else {
				yield put(updateTagFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(updateTagFailure(err));
	}
}

export function* deleteTag(action) {
	const url = `${TAG_API_BASE_URL}/${action.locale}/${action.id}`;
	const options = {
		method: 'DELETE',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(deleteTagSuccess(responseMessage));
				yield put(tagSelect(null));
			} else {
				yield put(deleteTagFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(deleteTagFailure(err));
	}
}

export function* replaceTag(action) {
	const url = `${STORY_METADATA_BASE_URL}/topic/replace`;
	const body = { key: action.key, locale: action.locale, target: { existing: action.existing, replacement: action.replacement ? action.replacement : null } };
	const options = {
		method: 'PUT',
		credentials: 'include',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	};
	try {
		const response = yield call(fetch, url, options);
		const responseMessage = yield response.json();

		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else if (body.target.replacement) {
			const url = `${REDIRECTION_BASE_API_URL}/save`;
			const options = {
				headers: { 'Content-Type': 'application/json' },
				method: 'POST',
				credentials: 'include',
				body: JSON.stringify(action.redirection),
			};
			const redirectResponse = yield call(fetch, url, options);
			const redirectResponseMessage = yield redirectResponse.json();
			if (responseMessage.success) {
				if (redirectResponseMessage.success) {
					yield put(replaceTagSuccess(responseMessage));
					yield put(tagSelect(null));
				} else {
					yield put(tagRedirectionFailure(redirectResponseMessage));
				}
			} else {
				yield put(replaceTagFailure(responseMessage));
			}
		} else {
			yield put(deleteTagSuccess(responseMessage));
			yield put(tagSelect(null));
		}
	} catch (err) {
		console.log(err);
		yield put(deleteTagFailure(err));
	}
}

export default function* tagsData() {
	yield takeLatest(CREATE_TAG, createTag);
	yield takeLatest(UPDATE_TAG, updateTag);
	yield takeLatest(DELETE_TAG, deleteTag);
	yield takeLatest(REPLACE_TAG, replaceTag);
}
