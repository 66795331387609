import React from 'react';
import T from 'prop-types';
import { Col } from 'react-styled-flexboxgrid';
import { ListHeaderWrapper } from '../../components/CompanyEditorComponents/styles';

const ListHeader = (props) => {
	const { logType } = props;
	return (
		<ListHeaderWrapper className="mt-4">
			<Col sm={1} xs={2} onClick={() => props.sortBy('date')}>
				Date
				<i className={`ml-2 fas fa-chevron-${!props.sortedBy.date ? null : props.sortedBy.date === 'asc' ? 'up' : 'down'}`} />
			</Col>
			{logType === 'merger' ? <Col sm={2} xs={4}>
				Participants
			</Col> : null}
			{logType !== 'merger'
				? <Col sm={2} xs={4} onClick={() => props.sortBy('investee')} className="pointer">
					Investee
					<i className={`ml-2 fas fa-chevron-${!props.sortedBy.investee ? null : props.sortedBy.investee === 'asc' ? 'up' : 'down'}`} />
				</Col> : null}
			{logType !== 'merger'
				? <Col sm={2} xs={4}>
					Investors
				</Col> : null}
			{logType === 'funding' ? <Col sm={2} xs={4} className="pointer">Indiviual Investors</Col> : null}
			{logType !== 'merger'
				? <Col sm={1} xs={4} onClick={() => props.sortBy('amount')} className="pointer">
					Amount
					<i className={`ml-2 fas fa-chevron-${!props.sortedBy.amount ? null : props.sortedBy.amount === 'asc' ? 'up' : 'down'}`} />
				</Col> : null}
			<Col sm={1} xs={2} className="pointer">
				Source
			</Col>
			{logType === 'funding' ? <Col sm={1} xs={2} className="pointer">Round</Col> : null}
			<Col sm={2} xs={4} className="pointer">
				Actions
			</Col>
		</ListHeaderWrapper>
	);
};

ListHeader.propTypes = {
	sortBy: T.func,
	sortedBy: T.object,
	logType: T.string,
};

export default ListHeader;
