import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { CustomTab, Input, InputGroup } from '../Styles';
import { Row, Col } from '../Responsive';
import messages from './messages';

class DeskHeader extends React.PureComponent {
	constructor(props) {
		super(props);
		this.onTabSelect = this.onTabSelect.bind(this);
		this.cancelSearch = this.cancelSearch.bind(this);
		this.onFilterProfiles = this.onFilterProfiles.bind(this);
		this.getDefaultDate = this.getDefaultDate.bind(this);
		this.state = {
			searchValue: '',
			to: this.getDefaultDate(1),
			from: this.getDefaultDate(-1),
			searchType: 'Title',
		};
	}

	onTabSelect(newStatus) {
		const { onTabSelect, status } = this.props;
		switch (newStatus) {
		case 'inReview':
			if (status !== 'inReview') {
				onTabSelect('inReview');
			}
			break;
		case 'updatesForReview':
			if (status !== 'updatesForReview') {
				onTabSelect('updatesForReview');
			}
			break;
		case 'published':
			if (status !== 'published') {
				onTabSelect('published');
			}
			break;
		case 'verified':
			if (status !== 'verified') {
				onTabSelect('verified');
			}
			break;
		case 'trashed':
			if (status !== 'trashed') {
				onTabSelect('trashed');
			}
			break;

		default:
			break;
		}
	}

	onFilterProfiles(e) {
		e.preventDefault();
		const searchType = this.state.searchType.toLowerCase();
		switch (searchType.toLowerCase()) {
		case 'title': {
			const { searchValue } = this.state;
			this.props.filterProfiles(searchType, searchValue, this.props.profile);
			break;
		}
		case 'date': {
			const searchValue = [];
			const from = `${this.state.from}T23:59:59`;
			const to = `${this.state.to}T23:59:59`;
			searchValue.push(from);
			searchValue.push(to);
			this.props.filterProfiles(searchType, JSON.stringify(searchValue), this.props.profile);
			break;
		}
		default:
			break;
		}
	}

	getDefaultDate(key) {
		const d = new Date();
		let month = (d.getMonth() + 1);
		let day = d.getDate() + key;
		const year = d.getFullYear();
		if (month.toString().length < 2) {
			month = `0${month}`;
		}
		if (day.toString().length < 2) {
			day = `0${day}`;
		}
		return [year, month, day].join('-');
	}

	cancelSearch() {
		this.setState({
			searchValue: '',
			to: this.getDefaultDate(1),
			from: this.getDefaultDate(-1),
		});
		this.props.cancelSearch();
	}

	render() {
		const { search, status } = this.props;
		return (
			<div style={{ width: '100%' }}>
				<Row className="mb-3">
					<Col sm={6} xs={12} className="md-text">
						{this.props.profile === 'company' ? <FormattedMessage {...messages.companyDeskCaption} /> : <FormattedMessage {...messages.influencerDeskCaption} />}
					</Col>

				</Row>
				<Row>
					<CustomTab>
						<Col xs={12} md={12} className="sm-text">
							<Row>
								<Col xs={12} md={7}>
									{!this.props.listProfiles
										? <ul className="tab-list">
											<li id="inReview-btn" className={`p-0 ${status.toLowerCase() === 'inreview' ? 'active' : ''}`}>
												<button
													className="pt-1 pl-3 pr-3 pb-1"
													type="button"
													onClick={() => this.onTabSelect('inReview')}
												>
													<FormattedMessage {...messages.inReview} />
												</button>
											</li>
											<li id="updates-for-review-btn" className={`p-0 ${status.toLowerCase() === 'updatesforreview' ? 'active' : ''}`}>
												<button
													className="pt-1 pl-3 pr-3 pb-1"
													type="button"
													onClick={() => this.onTabSelect('updatesForReview')}
												>
													<FormattedMessage {...messages.updatesForReview} />
												</button>
											</li>
											<li id="published-btn" className={`p-0 ${status === 'published' ? 'active' : ''}`}>
												<button
													className="pt-1 pl-3 pr-3 pb-1"
													type="button"
													onClick={() => this.onTabSelect('published')}
												>
													<FormattedMessage {...messages.published} />
												</button>
											</li>
											<li id="verified-btn" className={`p-0 ${status === 'verified' ? 'active' : ''}`}>
												<button
													className="pt-1 pl-3 pr-3 pb-1"
													type="button"
													onClick={() => this.onTabSelect('verified')}
												>
													<FormattedMessage {...messages.verified} />
												</button>
											</li>
											<li id="trashed-btn" className={`p-0 ${status === 'trashed' ? 'active' : ''}`}>
												<button
													className="pt-1 pl-3 pr-3 pb-1"
													type="button"
													onClick={() => this.onTabSelect('trashed')}
												>
													<FormattedMessage {...messages.trashed} />
												</button>
											</li>
										</ul> : null}
								</Col>
								<Col xs={12} md={5}>
									<form onSubmit={this.onFilterProfiles}>
										<InputGroup className="search-box">
											{/* TBD ------ FILTERBYDATES */}
											{/* <div className="prepend-group">
																								<span className="prepend-group-text p-0">
																										<select
																												id="search-type-dropdown"
																												onChange={(e) => this.setState({ searchType: e.target.value })}
																										>
																												<option value="Title">Title</option>
																												<option value="Date">Date</option>
																										</select>
																								</span>
																						</div> */}
											{
												this.state.searchType !== 'Date'
													? <Col id="search-box-div" xs={6} md={8} className="p-0">
														{/* <Input
																														placeholder="Search..."
																														type="text"
																														className="form-control"
																														id="search-box"
																														value={this.state.searchValue}
																														onChange={(e) => this.setState({ searchValue: e.target.value })}
																												/> */}
														<Input
															placeholder="Search by name"
															type="text"
															className="form-control"
															id="search-box"
															value={this.state.searchValue}
															onChange={(e) => this.setState({ searchValue: e.target.value })}
														/>
													</Col>
													: null
											}
											{
												this.state.searchType === 'Date'
													? <div id="date-picker-div" className="date-picker">
														<label
															htmlFor="from-date-picker"
															className="xs-hide sm-hide"
															style={{ position: 'absolute', top: '-18px' }}
														>
															From
														</label>
														<Input
															id="from-date-picker"
															type="date"
															className="element"
															placeholder="From"
															value={this.state.from}
															onChange={(e) => this.setState({ from: e.target.value })}
															required="required"
														/>
														<label
															htmlFor="to-date-picker"
															className="xs-hide sm-hide"
															style={{ position: 'absolute', top: '-18px' }}
														>
															To
														</label>
														<Input
															id="to-date-picker"
															type="date"
															className="element"
															placeholder="To"
															value={this.state.to}
															onChange={(e) => this.setState({ to: e.target.value })}
															required="required"
														/>
													</div>
													: null
											}
											<div className="append-group">
												{
													search
														? <span
																id="cancel-search-btn"
																role="presentation"
																className="float-icon"
																onClick={this.cancelSearch}
														>
															<i className="fa fa-times" />
														</span>
														: null
												}
												<span className="append-group-text brand-bg p-0">
													<button
														type="button"
														className="m-0 white-text"
														onClick={this.onFilterProfiles}
													>
														<i className="fa fa-search" />
													</button>
												</span>
											</div>
										</InputGroup>
									</form>
								</Col>
							</Row>
						</Col>
					</CustomTab>
				</Row>
			</div>
		);
	}
}

DeskHeader.propTypes = {
	status: PropTypes.string,
	profile: PropTypes.string,
	onTabSelect: PropTypes.func,
	search: PropTypes.bool,
	cancelSearch: PropTypes.func,
	filterProfiles: PropTypes.func,
	listProfiles: PropTypes.bool,
};

export default DeskHeader;
