/* eslint-disable no-unused-vars */
import React, { useReducer, useEffect, useState } from 'react';
import uuid from 'react-uuid';

import { Row, Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import { Input, Button } from '../Styles';
import {
	CURATED_SET_CONFIG_URL, regex, regex1, SAVING_FAILURE_MESSAGE, SAVING_MESSAGE, SAVING_SUCCESS_MESSAGE,
} from '../../containers/ListingsManager/constants';
import ToastMessage from '../ToastMessage';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { reArrangeTheList } from '../../utils/common';

const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
const UPDATE_ERRORS = 'UPDATE_ERRORS';
const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
const SET_FORM_DATA = 'SET_FORM_DATA';

const initialState = {
	formData: {
		id: null,
		name: null,
		description: null,
		order: null,
		newOrder: null,
		previousOrder: null,
		// image: null,
		// order: 1,
		// linkedInUrl: null,
	},
	blurredElements: {
		name: false,
		description: false,
		order: false,
		newOrder: false,
		previousOrder: false,
	},
	errors: {
		name: false,
		description: false,
		order: false,
		newOrder: false,
		previousOrder: false,
	},
	formMessage: null,
};

function updateFormData(changedData) {
	return {
		type: UPDATE_FORM_DATA,
		changedData,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function setFormData(category) {
	return {
		type: SET_FORM_DATA,
		category,
	};
}

function setFormMessage(message) {
	return {
		type: SET_FORM_MESSAGE,
		message,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case SET_FORM_DATA:
		return { ...state, formData: action.category };

	case UPDATE_FORM_DATA: {
		const formData = { ...state.formData };
		const { changedData } = action;
		formData[changedData.name] = changedData.value;
		return { ...state, formData };
	}

	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };

	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			name: true,
			description: true,
			order: true,
			newOrder: true,
			previousOrder: true,
		};
		return { ...state, blurredElements };
	}

	case SET_FORM_MESSAGE:
		return { ...state, formMessage: action.message };

	default:
		return state;
	}
}

const CategoryForm = (props) => {
	const {
		category, getCategories, unmountModal, categoriesList, categoryIndex,
	} = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		formData, blurredElements, errors, formMessage,
	} = state;
	const [isButtonDisabled, disableButton] = useState(false);
	const [toUpdate, setToUpdate] = useState(false);

	useEffect(() => {
		if (category || category?.id) {
			const categoryData = { ...category, newOrder: '' };
			dispatch(setFormData(categoryData));
			setToUpdate(true);
		}
	}, []);

	useEffect(() => {
		getErrors();
	}, [formData, blurredElements]);

	function onInputChange(e) {
		const changedData = {
			name: e.target.name,
			value: e.target.value,
		};
		dispatch(updateFormData(changedData));
	}

	function validateForm(category) {
		return {
			name: !category.name || category.name.length < 1,
			description: !category.description || category.description.length < 1,
			newOrder: (category?.newOrder && !regex.test(category.newOrder) && regex1.test(category.newOrder)) || false,
			order: (!category.order) || (category.order.length < 1) || (category?.order && !regex.test(category.order) && regex1.test(category.order)) || false,
		};
	}

	function getErrors() {
		const validationErrors = validateForm(formData);
		const errors = Object.keys(validationErrors).reduce((acc, curr) => {
			if (validationErrors[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	}

	function getCategoryBody(category) {
		const formData = {
			name: '',
			id: '',
			description: '',
			order: '',
			newOrder: '',
			previousOrder: '',
			userProfile: [],
			// slug: ''
		};
		const id = uuid();
		if (toUpdate) {
			formData.id = category?.id || id;
		} else {
			formData.id = id;
		}
		formData.name = category.name;
		formData.description = category.description;
		formData.order = parseInt(categoriesList?.length > 0 ? category.order : 1);
		formData.newOrder = (category?.newOrder) ? parseInt(category.newOrder) : category.order ? parseInt(category.order) : '';
		const index = categoriesList.findIndex((item) => item.order === formData.order);
		if (index < 0) {
			formData.order = parseInt(categoryIndex === -1 ? categoriesList?.length + 1 : category.order);
		}
		// index < 0 ? formData.order = parseInt((categoriesList?.length > 0 && categoryIndex === -1) ? categoriesList?.length + 1 : category.order) : category.order;
		formData.previousOrder = formData.order;
		formData.userProfile = category.userProfile?.length > 0 ? category.userProfile : [];
		// formData.append('order', category.order);
		return formData;
	}

	function onFormSubmit(e) {
		e.preventDefault();
		const validation = validateForm(formData);
		const validated = !Object.keys(validation).some((i) => validation[i]);
		if (validated) {
			saveCategory();
		} else {
			dispatch(setAllElementsBlurred());
		}
	}

	function handleBlur(element) {
		dispatch(updateBlurredElement(element));
	}

	async function saveCategory() {
		try {
			disableButton(true);
			dispatch(setFormMessage(SAVING_MESSAGE));
			const newCategoryPayload = getCategoryBody(formData);
			const payload = { category: [...categoriesList] ?? [] };
			if (categoryIndex === -1) {
				payload.category.push({ ...newCategoryPayload, userProfile: [] });
				// reArrangeTheList(1, categoriesList, newCategoryPayload);
				payload.category?.length > 1 && newCategoryPayload.newOrder ? payload.category = reArrangeTheList(1, payload.category, newCategoryPayload) : '';
			} else {
				payload.category[categoryIndex] = {
					...newCategoryPayload,
					// userProfile: [...payload.category[categoryIndex]?.userProfile || []],
				};
				// reArrangeTheList(2, categoriesList, newCategoryPayload);
				payload.category?.length > 1 && formData.newOrder ? payload.category = reArrangeTheList(2, payload.category, newCategoryPayload) : '';
			}
			const options = {
				method: 'PUT',
				credentials: 'include',
				body: JSON.stringify(payload),
				headers: {
					'Content-Type': 'application/json',
				},
			};
			const url = `${CURATED_SET_CONFIG_URL}/create`;
			const response = await fetch(url, options);
			disableButton(false);
			if (response.status === 200) {
				dispatch(setFormMessage(SAVING_SUCCESS_MESSAGE));
				getCategories();
				unmountModal();
			} else {
				dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			}
		} catch (err) {
			dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			disableButton(false);
		}
	}

	function renderFormMessage() {
		if (formMessage) {
			return (
				<ToastMessage
					toastData={formMessage}
					unmount={() => dispatch(setFormMessage(null))}
				/>
			);
		}
		return null;
	}

	return (
		<form onSubmit={onFormSubmit}>
			{renderFormMessage()}
			<Row>
				<Col sm={12}>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Name *
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="name"
								type="text"
								className="form-control"
								placeholder="Name of the category"
								onChange={onInputChange}
								value={formData.name ?? ''}
								onBlur={() => handleBlur('name')}
							/>
							<ErrorMessage display={errors.name} element="Name" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Description *
						</Col>
						<Col sm={8} xs={12}>
							<textarea
								rows="4"
								className="global-form-control"
								name="description"
								placeholder="Description of category"
								onChange={onInputChange}
								value={formData.description ?? ''}
								onBlur={() => handleBlur('description')}
							/>
							<ErrorMessage display={errors.description} element="Description" />
						</Col>
					</Row>
					{/*  order */}
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Order *
						</Col>
						 <Col sm={8} xs={12}>
							<Input
								name="order"
								type="text"
								className="form-control"
								placeholder="Order of the category"
								onChange={onInputChange}
								value={formData.order ?? ''}
								onKeyPress={(event) => {
									if ((!/[0-9]/.test(event.key))) {
										event.preventDefault();
									} else if (!regex.test(event.target.value) && regex1.test(event.target.value)) {
										event.preventDefault();
									}
								}}
								onBlur={() => handleBlur('order')}
								disabled={categoryIndex > -1}
							/>
							<p className="error mt-2 ml-1">NOTE: Please pick order value should be more than zero.</p>
							<ErrorMessage display={errors.order} element="Order" />
						</Col>
					</Row>
					{/*  */}
					{/*  change previous order */}
					{categoryIndex > -1 && <Row className="mb-3">
						<Col sm={4} xs={12}>
							Change Previous Order
						</Col>
						 <Col sm={8} xs={12}>
							<Input
								name="newOrder"
								type="text"
								className="form-control"
								placeholder="Change Previous Order"
								onKeyPress={(event) => {
									if ((!/[0-9]/.test(event.key))) {
										event.preventDefault();
									} else if (!regex.test(event.target.value) && regex1.test(event.target.value)) {
										event.preventDefault();
									}
								}}
								onChange={onInputChange}
								value={formData.newOrder ?? ''}
								onBlur={() => handleBlur('newOrder')}
							/>
							<p className="error mt-2 ml-1">NOTE: Please pick new order value should be more than zero.</p>
							{/* <ErrorMessage display={errors.order} element="Order" /> */}
						</Col>
					</Row>}
					{/*  */}
					{/* <Row className="mb-3">
						<Col sm={4} xs={12}>
							Order *
						</Col>
						{(hoc === 'mumbai_viewall_categorys' || city === 'mumbai')
							? <Col sm={8} xs={12}>
								<Input
									name="order"
									type="number"
									className="form-control"
									placeholder="Order of the category"
									onChange={onInputChange}
									value={formData.order ?? 1}
									onBlur={() => handleBlur('order')}
								/>
								<p className="error mt-2 ml-1">NOTE: Please pick order which is not being used.</p>
								<ErrorMessage display={errors.Order} element="Order" />
							</Col>
							: <Col sm={8} xs={12}>
								<DropDown
									className="mt-0"
									name="order"
									value={formData && formData.order ? formData.order : 1}
									onChange={onInputChange}
									onBlur={() => handleBlur('order')}
								>
									<option value={1}>1</option>
									<option value={2}>2</option>
									<option value={3}>3</option>
									<option value={4}>4</option>
									<option value={5}>5</option>
									<option value={6}>6</option>
									<option value={7}>7</option>
									<option value={8}>8</option>
									<option value={9}>9</option>
									<option value={10}>10</option>
									<option value={11}>11</option>
									<option value={12}>12</option>
									<option value={13}>13</option>
								</DropDown>
								<p className="error mt-2 ml-1">NOTE: Please pick order which is not being used.</p>
								<ErrorMessage display={errors.Order} element="Order" />
							</Col>}
					</Row> */}
					<div className="text-right">
						<Button
							success
							type="submit"
							disabled={isButtonDisabled}
						>
							SAVE
						</Button>
					</div>
				</Col>
				{renderFormMessage()}
			</Row>
		</form>
	);
};

CategoryForm.propTypes = {
	category: T.object,
	unmountModal: T.func,
	getCategories: T.func,
	categoriesList: T.array,
	categoryIndex: T.number,
};

export default CategoryForm;
