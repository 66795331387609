import React from 'react';
import { getCurrencyUnitValue } from '../../containers/CompanyEditor/functions';

export const TAB_OPTIONS = [
	{
		name: 'All Data',
		value: 'currentData',
	},
	{
		name: 'Updates',
		value: 'updates',
	},
];

export const FUNDING_ROUNDS = ['ANGEL', 'CONVERTIBLE-NOTE', 'CORPORATE-ROUND', 'DEBT-AND-EQUITY', 'DEBT-FINANCING', 'EQUITY-CROWDFUNDING', 'GRANT', 'INITIAL-COIN-OFFERING', 'NON-EQUITY-ASSISTANCE', 'POST-IPO-DEBT', 'POST-IPO-EQUITY', 'POST-IPO-SECONDARY', 'PRE-SEED', 'PRIVATE-EQUITY', 'PRODUCT-CROWDFUNDING', 'SECONDARY-MARKET', 'SEED', 'SERIES-A', 'SERIES-B', 'SERIES-C', 'SERIES-D', 'SERIES-E', 'SERIES-F', 'SERIES-G', 'SERIES-H', 'SERIES-I', 'SERIES-J', 'SERIES-K', 'SERIES-L', 'VENTURE-ROUND', 'PRE-SERIES-A', 'EQUITY', 'OTHER'];

const renderRoundDropdownItems = () => {
	const rounds = ['Select Round', ...FUNDING_ROUNDS];
	const DropDownItems = rounds.map((round, index) => <option key={round} value={index === 0 ? index : round}>{round}</option>);
	return DropDownItems;
};

const renderCurrencyDropdownItems = () => {
	const currencies = ['USD', 'EUR', 'GBP', 'INR', 'JPY', 'CNY'];
	const DropDownItems = currencies.map((currency) => <option key={currency} value={currency}>{currency}</option>);
	return DropDownItems;
};

function getAcquisitionRequestBody(acquisition, companyId) {
	const parsedAmount = acquisition.amount ? getCurrencyUnitValue(Number(acquisition.amount)) : null;
	const formData = new FormData();
	formData.append('acquiredBy', acquisition.acquiredBy.id);
	formData.append('description', acquisition.description);
	formData.append('currency', acquisition.amount ? acquisition.currency : null);
	formData.append('unit', acquisition.amount ? parsedAmount.unit : null);
	formData.append('value', acquisition.amount ? parsedAmount.value : null);
	formData.append('acquiredDate', acquisition.acquiredDate);
	formData.append('acquiredDateFormat', acquisition.acquiredDateFormat);
	formData.append('companyId', companyId);
	if (acquisition.id) {
		formData.append('id', acquisition.id);
	}
	if (acquisition.updateId) {
		formData.append('updateId', acquisition.updateId);
	}
	return formData;
}

function getFundingRequestBody(funding, companyId) {
	const parsedAmount = funding.amount ? getCurrencyUnitValue(Number(funding.amount)) : null;
	const formData = new FormData();
	formData.append('name', funding.name);
	formData.append('round', funding.round);
	formData.append('description', funding.description);
	formData.append('currency', funding.amount ? funding.currency : null);
	formData.append('fundingDate', funding.fundingDate);
	formData.append('fundingDateFormat', funding.fundingDateFormat);
	formData.append('unit', funding.amount ? parsedAmount.unit : null);
	formData.append('value', funding.amount ? parsedAmount.value : null);
	formData.append('companyId', companyId);
	if (funding.id) {
		formData.append('id', funding.id);
	}
	if (funding.updateId) {
		formData.append('updateId', funding.updateId);
	}
	return formData;
}

function getFundingMetadataRequestBody(fundingMetadata, fundingId) {
	const parsedAmount = fundingMetadata.amount ? getCurrencyUnitValue(Number(fundingMetadata.amount)) : null;
	const formData = new FormData();
	formData.append('investorType', fundingMetadata.investorType);
	formData.append('investorId', fundingMetadata.investor.id);
	formData.append('comments', fundingMetadata.comments);
	formData.append('currency', fundingMetadata.amount ? fundingMetadata.currency : null);
	formData.append('unit', fundingMetadata.amount ? parsedAmount.unit : null);
	formData.append('value', fundingMetadata.amount ? parsedAmount.value : null);
	formData.append('fundingId', fundingId);
	if (fundingMetadata.id) {
		formData.append('id', fundingMetadata.id);
	}
	if (fundingMetadata.updateId) {
		formData.append('updateId', fundingMetadata.updateId);
	}
	return formData;
}

function getFinanceRequestBody(finance, companyId) {
	const parsedAmount = finance.amount ? getCurrencyUnitValue(Number(finance.amount)) : null;

	const formData = new FormData();
	formData.append('startYear', finance.startYear);
	formData.append('endYear', finance.endYear);
	formData.append('timePeriod', finance.timePeriod);
	formData.append('type', finance.type);
	formData.append('currency', finance.amount ? finance.currency : null);
	formData.append('unit', finance.amount ? parsedAmount.unit : null);
	formData.append('value', finance.amount ? parsedAmount.value : null);
	formData.append('companyId', companyId);
	if (finance.id) {
		formData.append('id', finance.id);
	}
	if (finance.updateId) {
		formData.append('updateId', finance.updateId);
	}
	return formData;
}

function getCompanyPeopleRequestBody(person, companyId) {
	const formData = new FormData();
	formData.append('influencerId', person.influencer.id);
	formData.append('role', person.role);
	formData.append('designation', person.designation);
	formData.append('endDate', person.endDate);
	formData.append('endDateFormat', person.dateFormat);
	formData.append('startDate', person.startDate);
	formData.append('startDateFormat', person.dateFormat);
	formData.append('companyId', companyId);
	if (person.id) {
		formData.append('id', person.id);
	}
	if (person.updateId) {
		formData.append('updateId', person.updateId);
	}
	return formData;
}

function getCompanyProductRequestBody(product, companyId, logo) {
	const formData = new FormData();
	formData.append('name', product.name);
	formData.append('sectorId', product.sector.id);
	formData.append('type', product.type);
	formData.append('description', product.description);
	formData.append('website', product.website);
	formData.append('companyId', companyId);
	if (logo) {
		formData.append('logo', JSON.stringify(logo));
	} else {
		formData.append('logo', JSON.stringify(product.logo));
	}
	if (product.id) {
		formData.append('id', product.id);
	}
	if (!product.id && product.offers && product.offers.length > 0) {
		product.offers.forEach((offer) => {
			offer.companyId = companyId;
		});
		formData.append('offers', JSON.stringify(product.offers));
	}
	if (product.updateId) {
		formData.append('updateId', product.updateId);
	}
	return formData;
}

function getCompanyLocationRequestBody(location, companyId) {
	const formData = new FormData();
	formData.append('city', location.city.id);
	formData.append('latitude', location.latitude);
	formData.append('longitude', location.longitude);
	formData.append('googleMapLink', location.googleMapLink);
	formData.append('pincode', location.pincode);
	formData.append('addressLine1', location.addressLine1);
	formData.append('addressLine2', location.addressLine2);
	formData.append('employeeCount', location.employeeCount);
	formData.append('phoneNo', location.phoneNo);
	formData.append('companyId', companyId);
	if (location.id) {
		formData.append('id', location.id);
	}
	if (location.updateId) {
		formData.append('updateId', location.updateId);
	}
	return formData;
}

function getCompanyMilestoneRequestBody(milestone, companyId) {
	const formData = new FormData();
	formData.append('milestoneType', milestone.milestoneType.id);
	formData.append('date', milestone.milestoneDate);
	formData.append('dateFormat', milestone.milestoneDateFormat);
	formData.append('description', milestone.description);
	formData.append('url', milestone.url);
	formData.append('companyId', companyId);
	if (milestone.id) {
		formData.append('id', milestone.id);
	}
	if (milestone.updateId) {
		formData.append('updateId', milestone.updateId);
	}
	return formData;
}

function getCompanyAudienceRequestBody(audience, companyId) {
	const formData = new FormData();
	formData.append('audienceType', JSON.stringify(audience.audienceType));
	formData.append('description', audience.description);
	formData.append('geolocations', JSON.stringify(audience.geolocations));
	if (audience.audienceType.value === 'Business') {
		formData.append('businessSizes', JSON.stringify(audience.businessSizes));
		formData.append('businessSectors', JSON.stringify(audience.businessSectors));
	} else if (audience.audienceType.value === 'Consumer') {
		formData.append('ageRanges', JSON.stringify(audience.ageRanges));
		formData.append('incomeClasses', JSON.stringify(audience.incomeClasses));
	}
	formData.append('companyId', companyId);
	if (audience.id) {
		formData.append('id', audience.id);
	}
	if (audience.updateId) {
		formData.append('updateId', audience.updateId);
	}
	return formData;
}

function getOfferRequestBody(offerData, productId, companyId, minimal) {
	const formData = new FormData();
	formData.append('companyId', companyId);
	if (offerData.updateId) {
		formData.append('updateId', offerData.updateId);
	}
	if (minimal) {
		return formData;
	}
	formData.append('description', offerData.description);
	formData.append('image', offerData.image ? JSON.stringify(offerData.image) : null);
	formData.append('status', offerData.status);
	formData.append('cta_text', offerData.ctaText);
	formData.append('cta_link', offerData.ctaLink);
	formData.append('productId', productId);
	if (offerData.id) {
		formData.append('id', offerData.id);
	}
	return formData;
}

// function hasFieldChanged(fieldName, prevValue, curData, dropDownName) {
//		 if (curData.id) {
//				 let hasChangedAr = [];
//				 if (curData.hasChanged) {
//						 hasChangedAr = [...curData.hasChanged];
//				 }
//				 if (fieldName === dropDownName && curData[dropDownName] && prevValue[dropDownName]) {
//						 if (prevValue[dropDownName].value != curData[dropDownName].value) {
//								 if (!hasChangedAr.includes(dropDownName)) {
//										 hasChangedAr.push(dropDownName)
//								 }
//								 return hasChangedAr

//						 }
//						 else {
//								 if (hasChangedAr.includes(dropDownName)) {
//										 hasChangedAr.splice(hasChangedAr.indexOf(dropDownName), 1)
//								 }
//								 return hasChangedAr

//						 }
//				 }
//				 else {
//						 if (prevValue[fieldName] != curData[fieldName]) {
//								 if (!hasChangedAr.includes(fieldName)) {
//										 hasChangedAr.push(fieldName)
//								 }
//								 return hasChangedAr

//						 }
//						 else {
//								 if (hasChangedAr.includes(fieldName)) {

//										 hasChangedAr.splice(hasChangedAr.indexOf(fieldName), 1)
//								 }
//								 return hasChangedAr

//						 }
//				 }
//		 }
//		 else
//				 return ['new']
// }

export {
	renderCurrencyDropdownItems,
	renderRoundDropdownItems,
	getAcquisitionRequestBody,
	getFundingRequestBody,
	getFinanceRequestBody,
	getCompanyPeopleRequestBody,
	getCompanyProductRequestBody,
	getCompanyLocationRequestBody,
	getFundingMetadataRequestBody,
	getCompanyMilestoneRequestBody,
	getCompanyAudienceRequestBody,
	getOfferRequestBody,
};
