import { Map } from 'immutable';
import {
	SHOW_COPY_ELEMENT, LOADING_LIST, LOAD_ADHOC_URL, LOADING_STATE, ADD_TO_LIST_ON_UPLOAD, CLEAR_STATE,
} from './type';
import { getFullDate } from '../../utils/date_maker';

const initialState = Map({
	loading: null,
	message: null,
	list: null,
	pageNo: 1,
	dateArray: null,
	listLoad: null,
	endOfList: null,
	showCopyElement: false,
});

function fileUploadReducer(state = initialState, action) {
	switch (action.type) {
	case LOADING_LIST: return state.set('listLoad', true);
	case LOAD_ADHOC_URL: return state.set('list', action.list).set('pageNo', action.pageNo).set('dateArray', action.dateArray).set('listLoad', false)
		.set('endOfList', action.end);
	case LOADING_STATE: return state.set('loading', action.status);
	case ADD_TO_LIST_ON_UPLOAD: {
		let oldArray = state.get('list');
		let dateArray = [];
		if (!oldArray || (oldArray && oldArray.length === 0)) {
			oldArray = [action.item];
			if (action.item && action.item.createdAt) {
				dateArray = [getFullDate(action.item.createdAt)];
			}
			return state.set('list', oldArray).set('dateArray', dateArray);
		}
		oldArray = [action.item, ...oldArray];
		return state.set('list', oldArray);
	}
	case SHOW_COPY_ELEMENT: return state.set('showCopyElement', action.value);
	case CLEAR_STATE: return initialState;
	default: return state;
	}
}

export default fileUploadReducer;
