export const SELECT_VERTICAL = 'SELECT_VERTICAL';
export const ADD_VERTICAL = 'ADD_VERTICAL';
export const VERTICAL_ELEMENT_ON_CHANGE = 'VERTICAL_ELEMENT_ON_CHANGE';

export const ADD_NEW_VERTICAL = 'ADD_NEW_VERTICAL';

export const CREATE_VERTICAL = 'CREATE_VERTICAL';
export const CREATE_VERTICAL_SUCCESS = 'CREATE_VERTICAL_SUCCESS';
export const CREATE_VERTICAL_ERROR = 'CREATE_VERTICAL_ERROR';

export const UPDATE_VERTICAL = 'UPDATE_VERTICAL';
export const UPDATE_VERTICAL_SUCCESS = 'UPDATE_VERTICAL_SUCCESS';
export const UPDATE_VERTICAL_ERROR = 'UPDATE_VERTICAL_ERROR';

export const DELETE_VERTICAL = 'DELETE_VERTICAL';
export const DELETE_VERTICAL_SUCCESS = 'DELETE_VERTICAL_SUCCESS';
export const DELETE_VERTICAL_ERROR = 'DELETE_VERTICAL_ERROR';

export const UNMOUNT_VERTICAL_FORM_MESSAGE = 'UNMOUNT_VERTICAL_FORM_MESSAGE';
export const SET_VERTICAL_SLUG_UNIQUE_VALUE = 'SET_VERTICAL_SLUG_UNIQUE_VALUE';

export const CLEAR_VERTICAL_MANAGER_DATA = 'CLEAR_VERTICAL_MANAGER_DATA';

export const REPLACE_VERTICAL = 'REPLACE_VERTICAL';
export const REPLACE_VERTICAL_SUCCESS = 'REPLACE_VERTICAL_SUCCESS';
export const REPLACE_VERTICAL_ERROR = 'REPLACE_VERTICAL_ERROR';
