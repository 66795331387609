export const FETCH_MEDIA_BY_BRAND_AND_TYPE = 'FETCH_MEDIA_BY_BRAND_AND_TYPE';
export const FETCH_MEDIA_BY_BRAND_AND_TYPE_SUCCESS = 'FETCH_MEDIA_BY_BRAND_AND_TYPE_SUCCESS';
export const FETCH_MEDIA_BY_BRAND_AND_TYPE_FAILURE = 'FETCH_MEDIA_BY_BRAND_AND_TYPE_FAILURE';

export const SET_MEDIA_GALLERY_PAGE_NO = 'SET_MEDIA_GALLERY_PAGE_NO';
export const SORT_MEDIA_BY = 'SORT_MEDIA_BY';

export const SET_MEDIA_SEARCH_VALUE = 'SET_MEDIA_SEARCH_VALUE';
export const FILTER_MEDIA = 'FILTER_MEDIA';
export const FILTER_MEDIA_SUCCESS = 'FILTER_MEDIA_SUCCESS';
export const FILTER_MEDIA_FAILURE = 'FILTER_MEDIA_FAILURE';

export const SET_GALLERY_UPDATED_TIME = 'SET_GALLERY_UPDATED_TIME';
