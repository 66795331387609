import T from 'prop-types';
import React, { useState, useEffect, useReducer } from 'react';
import Modal from 'react-modal';
import { Row, Col } from 'react-styled-flexboxgrid';
import ToastMessage from '../ToastMessage';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { Button, Input } from '../Styles';
import { Switch, SwitchLabel, Slider } from '../DynamicConfigEditor/styles';

const BROADCAST_MANAGER_API = '/api/v2/rw/broadcast-manager';

const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
const UPDATE_ERRORS = 'UPDATE_ERRORS';
const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
const SET_FORM_DATA = 'SET_FORM_DATA';

const SAVING_MESSAGE = { intent: 'info', message: 'Saving...' };
const SAVING_FAILURE_MESSAGE = { intent: 'danger', message: 'Something went wrong!' };

const initialState = {
	formData: {
		broadcast: null,
		duration: null,
		displayDelay: null,
	},
	blurredElements: {
		duration: null,
		mobile: null,
		desktop: null,

	},
	errors: {
		duration: null,
		mobile: null,
		desktop: null,
	},
	formMessage: null,
};

function updateFormData(changedData) {
	return {
		type: UPDATE_FORM_DATA,
		changedData,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function setFormMessage(message) {
	return {
		type: SET_FORM_MESSAGE,
		message,
	};
}

function setFormData(data) {
	return {
		type: SET_FORM_DATA,
		data,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case SET_FORM_DATA:
		return { ...state, formData: { ...action.data } };
	case UPDATE_FORM_DATA: {
		const formData = { ...state.formData };
		const { changedData } = action;
		const key = changedData.name;
		if (key === 'displayDelay') {
			formData[key] = { ...formData[key], ...action.changedData.value };
		} else { formData[key] = changedData.value; }
		return { ...state, formData };
	}

	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}

	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };

	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			duration: true,
			mobile: true,
			desktop: true,
		};
		return { ...state, blurredElements };
	}

	case SET_FORM_MESSAGE:
		return { ...state, formMessage: action.message };

	default:
		return state;
	}
}

const BroadcastGlobalConfigForm = (props) => {
	const { open, close, data } = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const { formData, blurredElements, formMessage } = state;
	const [actionsDisabled, disableActions] = useState(false);

	useEffect(() => {
		dispatch(setFormData(data));
	}, [data]);

	useEffect(() => {
		getErrors();
	}, [formData, blurredElements]);

	function getErrors() {
		const validationErrors = validateForm(formData);
		const errors = Object.keys(validationErrors).reduce((acc, curr) => {
			acc[curr] = validationErrors[curr] && blurredElements[curr];
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	}

	function validateForm() {
		const { duration, displayDelay } = formData;
		return {
			duration: !duration,
			mobile: displayDelay && !displayDelay.mobile,
			desktop: displayDelay && !displayDelay.desktop,
		};
	}

	function handleBlur(element) {
		dispatch(updateBlurredElement(element));
	}

	function closeModal(unchanged) {
		close(unchanged);
	}

	function onInputChange(e) {
		const { name, value } = e.target;
		return dispatch(updateFormData({ name, value }));
	}

	function onDisplayDelayChange(e) {
		const { name, value } = e.target;
		const changedData = {
			name: 'displayDelay',
			value: { [name]: value },
		};
		return dispatch(updateFormData(changedData));
	}

	function renderFormMessage() {
		if (formMessage) {
			return (
				<ToastMessage
					toastData={formMessage}
					unmount={() => dispatch(setFormMessage(null))}
				/>
			);
		}
	}

	function handleToggle() {
		dispatch(updateFormData({ name: 'broadcast', value: !formData.broadcast }));
	}

	function displayErrors(field) {
		const errors = validateForm();
		const hasError = errors[field];
		return hasError ? state.blurredElements[field] : false;
	}

	async function onFormSubmit(e) {
		e.preventDefault();
		const validation = validateForm(formData);
		const validated = !Object.keys(validation).some((i) => validation[i]);
		if (validated) {
			saveContent();
		} else {
			dispatch(setAllElementsBlurred());
		}
	}

	async function saveContent() {
		try {
			const { broadcast, duration, displayDelay } = formData;

			disableActions(true);
			dispatch(setFormMessage(SAVING_MESSAGE));
			const payload = new FormData();
			const delay = {};
			delay.mobile = displayDelay.mobile * 1000;
			delay.desktop = displayDelay.desktop * 1000;

			payload.append('duration', duration * 1000);
			payload.append('broadcast', broadcast);
			payload.append('displayDelay', JSON.stringify(delay));

			const URL = `${BROADCAST_MANAGER_API}/update-global-config`;
			const options = {
				method: 'PUT',
				credentials: 'include',
				body: payload,
			};
			const response = await fetch(URL, options);
			const parsed = await response.json();
			disableActions(false);
			if (parsed.success) {
				setTimeout(() => closeModal(false), 1000);
			} else {
				dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			}
		} catch (err) {
			console.log('Error saving', err);
			dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			disableActions(false);
		}
	}

	return (
		<Modal
			style={{ content: { overflow: 'scroll', maxHeight: '95vh' } }}
			isOpen={open}
			onRequestClose={closeModal}
			contentLabel="Add content"
			overlayClassName="modal-overlay"
			className="form-modal"
			shouldCloseOnOverlayClick={false}
		>
			<div className="w_100 mb-4 text-right">
				<button type="button" onClick={() => closeModal(true)}>
					<i className="fas fa-times light-text disablePointerEvents" />
				</button>
			</div>
			<div>
				<h2>Update broadcast global config</h2>
				{renderFormMessage()}
				<form onSubmit={onFormSubmit}>
					<Row>
						<Col xs={12} sm={12} md={12}>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Duration *
								</Col>
								<Col xs={12} sm={6}>
									<Input
										name="duration"
										type="number"
										className="form-control"
										placeholder="Duration in seconds"
										value={formData && formData.duration ? formData.duration : ''}
										onChange={onInputChange}
										onBlur={() => handleBlur('duration')}
										disabled={actionsDisabled}
									/>
									<ErrorMessage display={displayErrors('duration')} element="Duration" />
								</Col>
								<Col xs={12} sm={3}>
									seconds
								</Col>
							</Row>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Display delay
								</Col>
								<Col xs={12} sm={9}>
									<Row className="mb-3">
										<Col xs={12} sm={2}>
											Mobile *
										</Col>
										<Col xs={12} sm={8}>
											<Input
												name="mobile"
												type="number"
												className="form-control"
												placeholder="Mobile display delay in seconds"
												value={formData && formData.displayDelay && formData.displayDelay.mobile ? formData.displayDelay.mobile : ''}
												onChange={onDisplayDelayChange}
												onBlur={() => handleBlur('mobile')}
												disabled={actionsDisabled}
											/>
											<ErrorMessage display={displayErrors('mobile')} element="Mobile display delay" />
										</Col>
										<Col xs={12} sm={2}>
											seconds
										</Col>
									</Row>
									<Row className="mb-3">
										<Col xs={12} sm={2}>
											Desktop *
										</Col>
										<Col xs={12} sm={8}>
											<Input
												name="desktop"
												type="number"
												className="form-control"
												placeholder="Desktop display delay in seconds"
												value={formData && formData.displayDelay && formData.displayDelay.desktop ? formData.displayDelay.desktop : ''}
												onChange={onDisplayDelayChange}
												onBlur={() => handleBlur('desktop')}
												disabled={actionsDisabled}
											/>
											<ErrorMessage display={displayErrors('desktop')} element="Desktop display delay" />
										</Col>
										<Col xs={12} sm={2}>
											seconds
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Broadcast
								</Col>
								<Col xs={12} sm={9}>
									<Switch
										id="toggle-btn"
										style={{ backgroundColor: !formData.broadcast ? '#e5002d' : '#28a745' }}
										onClick={() => handleToggle()}
									>
										<SwitchLabel backgroundColor="#28a745">ON</SwitchLabel>
										<SwitchLabel backgroundColor="#e5002d">OFF</SwitchLabel>
										{formData.broadcast
											? <Slider id="slider" style={{ transform: 'translateX(50px)' }} />
											: <Slider id="slider" />}
									</Switch>
								</Col>
							</Row>
						</Col>
					</Row>
					<Col xs={12} sm={12} className="text-center">
						<Button
							type="submit"
							success
							no_radius
							disabled={actionsDisabled}
							style={{ width: '100px' }}
						>
							<b>SAVE</b>
						</Button>
					</Col>
				</form>
			</div>
		</Modal>
	);
};

BroadcastGlobalConfigForm.propTypes = {
	open: T.bool,
	close: T.func,
	data: T.object,
};
export default BroadcastGlobalConfigForm;
