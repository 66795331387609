import React from 'react';
import T from 'prop-types';
import { ToastWrapper, CloseButton, Message } from './styles';

const ToastMessage = (props) => {
	ToastMessage.propTypes = {
		toastData: T.object,
		unmount: T.func,
	};
	const { toastData, unmount } = props;
	return (
		<ToastWrapper intent={toastData.intent}>
			<CloseButton onClick={unmount}>
				<i className="fas fa-times" />
			</CloseButton>
			<Message dangerouslySetInnerHTML={{ __html: toastData.message }} />
		</ToastWrapper>
	);
};

export default ToastMessage;
