import React from 'react';
import T from 'prop-types';
import styled, { css } from 'styled-components';
import imgPlaceholder from '../../images/img-placeholder.png';

const TopicCheckboxCard = (props) => {
	const { thumbnail, tagged } = props;
	const title = props.title || '';
	// if (title && title.length > 10) {
	//		 title = title.substring(0, 50) + '...';
	// }
	return (
		<CardWrapper tagged={tagged}>
			<div style={{ width: '30%' }}>
				<Thumbnail
					src={thumbnail || imgPlaceholder}
					alt="thumbnail"
				/>
			</div>

			<div className="ml-3 ml-xl-0 pr-3" style={{ width: '70%' }}>
				<h4>{title}</h4>
			</div>
		</CardWrapper>
	);
};

TopicCheckboxCard.propTypes = {
	title: T.string,
	thumbnail: T.string,
	tagged: T.bool,
};

export default TopicCheckboxCard;

const Thumbnail = styled.img`
		width: 5rem;
		height: 5rem;
`;

const CardWrapper = styled.div`
		width: 100%;
		display: flex;
		position: relative;
		box-shadow: 2px 2px 4px 0px #CCCCCC;
		align-items: center;
		cursor: pointer;
		${(props) => props.tagged && css`
				border: 5px solid #e5002d;
				border-radius: 0.25rem;
		`}
`;
