import {
	call, put, takeLatest, select,
} from 'redux-saga/effects';
import {
	CREATE_NEW_ASSET, UPDATE_MEDIA, FETCH_MEDIA_ASSET_BY_ID, FETCH_STORIES_BY_MEDIA_ID,
} from './type';
import { ASSET_BASE_API_URL, VIDEO_BASE_API_URL } from './constants';
import {
	createNewAssetSuccess, createNewAssetFailure, fetchStoriesByMediaIdSuccess, updateMediaFailure, updateMediaSuccess, fetchMediaAssetByIdFailure, fetchMediaAssetByIdSuccess, fetchStoriesByMediaId, fetchStoriesByMediaIdFailure,
} from './actions';
import { redirectTo } from '../../utils/accessRedirection';
import { makeSelectLocation } from '../App/selectors';

let CURRENT_LOCATION;

export function* getMediaById(action) {
	const url = `${ASSET_BASE_API_URL}/id/${action.id}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403].includes(response.status)) {
			redirectTo(response.status, CURRENT_LOCATION);
		} else if (response.status === 500) {
			yield put(fetchMediaAssetByIdFailure('error'));
		} else {
			const asset = yield response.json();
			const { mediaId } = asset;
			yield put(fetchMediaAssetByIdSuccess(asset));
			yield put(fetchStoriesByMediaId(mediaId));
		}
	} catch (err) {
		yield put(fetchMediaAssetByIdFailure(err));
	}
}

export function* createAsset(action) {
	const url = `${VIDEO_BASE_API_URL}/jwplayer/create`;
	const options = {
		method: 'POST',
		credentials: 'include',
		body: action.asset,
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403].includes(response.status)) {
			redirectTo(response.status, CURRENT_LOCATION);
		} else if (response.status === 500) {
			yield put(createNewAssetFailure('error'));
		} else {
			const parsedResponse = yield response.json();
			if (parsedResponse.status === 'success') {
				yield put(createNewAssetSuccess(parsedResponse));
			} else {
				yield put(createNewAssetFailure('error'));
			}
		}
	} catch (err) {
		yield put(createNewAssetFailure(err));
	}
}

export function* getStoriesByMediaId(action) {
	const url = `${ASSET_BASE_API_URL}/usedIn/mediaId/${action.mediaId}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			redirectTo(response.status, CURRENT_LOCATION);
		} else {
			const usedIdStories = yield response.json();
			yield put(fetchStoriesByMediaIdSuccess(usedIdStories));
		}
	} catch (err) {
		yield put(fetchStoriesByMediaIdFailure(err));
	}
}

export function* updateMedia(action) {
	const url = `${VIDEO_BASE_API_URL}/update/${action.mediaId}`;
	const options = {
		method: 'POST',
		credentials: 'include',
		body: action.media,
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403].includes(response.status)) {
			redirectTo(response.status, CURRENT_LOCATION);
		} else if (response.status === 500) {
			yield put(updateMediaFailure('error'));
		} else {
			const parsedResponse = yield response.json();
			if (parsedResponse.status === 'success') {
				yield put(updateMediaSuccess(parsedResponse));
			} else {
				yield put(updateMediaFailure('error'));
			}
		}
	} catch (err) {
		yield put(updateMediaFailure(err));
	}
}

export default function* mediaEditorData() {
	const location = yield select(makeSelectLocation());
	CURRENT_LOCATION = location.pathname;
	yield takeLatest(CREATE_NEW_ASSET, createAsset);
	yield takeLatest(FETCH_STORIES_BY_MEDIA_ID, getStoriesByMediaId);
	yield takeLatest(UPDATE_MEDIA, updateMedia);
	yield takeLatest(FETCH_MEDIA_ASSET_BY_ID, getMediaById);
}
