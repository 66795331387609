export const FETCH_CLAIMS_BY_STATUS = 'FETCH_CLAIMS_BY_STATUS';
export const FETCH_CLAIMS_BY_STATUS_SUCCESS = 'FETCH_CLAIMS_BY_STATUS_SUCCESS';
export const FETCH_CLAIMS_BY_STATUS_FAILURE = 'FETCH_CLAIMS_BY_STATUS_FAILURE';

export const UPDATE_CLAIM_STATUS = 'UPDATE_CLAIM_STATUS';
export const UPDATE_CLAIM_STATUS_SUCCESS = 'UPDATE_CLAIM_STATUS_SUCCESS';
export const UPDATE_CLAIM_STATUS_FAILURE = 'UPDATE_CLAIM_STATUS_FAILURE';

export const ON_TAB_CHANGE = 'ON_TAB_CHANGE';
export const SET_PAGE = 'SET_PAGE';

export const FILTER_CLAIMS_BY_COMPANY = 'FILTER_CLAIMS_BY_COMPANY';
export const FILTER_CLAIMS_BY_COMPANY_SUCCESS = 'FILTER_CLAIMS_BY_COMPANY_SUCCESS';
export const FILTER_CLAIMS_BY_COMPANY_FAILURE = 'FILTER_CLAIMS_BY_COMPANY_FAILURE';
