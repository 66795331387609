import React from 'react';
import T from 'prop-types';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { DropdownOptionContainer, CenteredText } from './styles';

const AsyncMultiSelect = (props) => {
	const {
		placeholder, handleChange, currentValues, options, disabled, profile, handleBlur, defaultOptionsList = [],
	} = props;
	const renderImage = (profile, data) => {
		let url = null;
		if (profile === 'author') {
			if (data.profilePicture) {
				url = data.profilePicture;
			}
		} else if (profile === 'influencer') {
			if (data.profilePicture && data.profilePicture.url) {
				({ url } = data.profilePicture);
			}
		} else if (data.logo) {
			url = data.logo;
		} else if (data.imagelocation) {
			url = data.imagelocation;
		}
		if (url) {
			return <img src={url} height={32} width={32} alt={data.label} />;
		}
		return null;
	};

	const Option = (props) => {
		Option.propTypes = {
			innerProps: T.object,
			data: T.object,
		};
		const {
			innerProps,
			data,
		} = props;
		return (
			<DropdownOptionContainer {...innerProps} className="p-2" title={profile === 'influencer' ? data.username : ''}>
				<CenteredText>{data.label}</CenteredText>
				{renderImage(profile, data)}
			</DropdownOptionContainer>
		);
	};
	const MultiValueContainer = (props) => (
		<span>
			<components.MultiValueContainer {...props} />
		</span>
	);
	return (
		<AsyncSelect
			value={currentValues}
			loadOptions={options}
			components={profile ? { Option } : { MultiValueContainer }}
			defaultOptions={defaultOptionsList}
			cacheOptions
			isMulti
			className="basic-multi-select"
			classNamePrefix="select"
			placeholder={placeholder}
			isClearable
			onChange={(value) => handleChange(value)}
			isDisabled={disabled}
			onBlur={handleBlur}
		/>
	);
};

AsyncMultiSelect.defaultProps = {
	disabled: false,
};

AsyncMultiSelect.propTypes = {
	placeholder: T.string,
	handleChange: T.func,
	currentValues: T.array,
	options: T.func,
	disabled: T.bool,
	profile: T.string,
	handleBlur: T.func,
	defaultOptionsList: T.array || T.bool,
};

export default AsyncMultiSelect;
