import {
	CREATE_JOB,
	CREATE_JOB_SUCCESS,
	CREATE_JOB_FAILURE,
	UPDATE_JOB,
	UPDATE_JOB_SUCCESS,
	UPDATE_JOB_FAILURE,
	FETCH_JOB_DATA,
	FETCH_JOB_DATA_SUCCESS,
	FETCH_JOB_DATA_FAILURE,
	UPDATE_JOB_FROM_DATA,
	UPDATE_FORM_MESSAGE,
	SHOW_CONFIRMATION_MODAL,
	HANDLE_BLUR,
	SET_ALL_ELEMENTS_BLUR,
	JOB_EDITOR_HAS_ERRORS,
	SET_JOB_EDITOR_DOC_CONVERSION_DATA,
	UPDATE_JOB_EDITOR_DOC_CONVERSION_DATA,
} from './type';

export function onJobFormDataChange(changedData) {
	return {
		type: UPDATE_JOB_FROM_DATA,
		changedData,
	};
}

export function fetchJobData(jobId) {
	return {
		type: FETCH_JOB_DATA,
		jobId,
	};
}

export function fetchJobDataSuccess(job) {
	return {
		type: FETCH_JOB_DATA_SUCCESS,
		job,
	};
}

export function fetchJobDataFailure(error) {
	return {
		type: FETCH_JOB_DATA_FAILURE,
		error,
	};
}

export function createJob(job) {
	return {
		type: CREATE_JOB,
		job,
	};
}

export function createJobSuccess(successResponse) {
	return {
		type: CREATE_JOB_SUCCESS,
		successResponse,
	};
}

export function createJobFailure(error) {
	return {
		type: CREATE_JOB_FAILURE,
		error,
	};
}

export function updateJob(id, job) {
	return {
		type: UPDATE_JOB,
		id,
		job,
	};
}

export function updateJobSuccess(successResponse) {
	return {
		type: UPDATE_JOB_SUCCESS,
		successResponse,
	};
}

export function updateJobFailure(error) {
	return {
		type: UPDATE_JOB_FAILURE,
		error,
	};
}

export function updateFormMessage(message) {
	return {
		type: UPDATE_FORM_MESSAGE,
		message,
	};
}

export function setConfirmationModal(value) {
	return {
		type: SHOW_CONFIRMATION_MODAL,
		value,
	};
}

export function handleBlur(element) {
	return {
		type: HANDLE_BLUR,
		element,
	};
}

export function setAllElementsBlur() {
	return {
		type: SET_ALL_ELEMENTS_BLUR,
	};
}

export function jobEditorHasErrors(errors) {
	return {
		type: JOB_EDITOR_HAS_ERRORS,
		errors,
	};
}

export function setJobEditorDocConversion(value) {
	return {
		type: SET_JOB_EDITOR_DOC_CONVERSION_DATA,
		value,
	};
}

export function updateJobEditorDocConversion(value) {
	return {
		type: UPDATE_JOB_EDITOR_DOC_CONVERSION_DATA,
		value,
	};
}
