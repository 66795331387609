import {
	LOAD_PROMOTIONS_START, LOAD_PROMOTIONS_ERROR, LOAD_PROMOTIONS_SUCCESS, LOAD_PROMOTIONS_UPDATE_SUCCESS, UPDATE_DISPLAY_MESSAGE, LOAD_CHATBOTS, LOAD_CHATBOTS_SUCCESS, LOAD_CHATBOTS_ERROR,
} from './type';

export function loadPromotionsStart(brand, startDate, endDate, limit, offset, update) {
	return {
		type: LOAD_PROMOTIONS_START,
		brand,
		startDate,
		endDate,
		limit,
		offset,
		update,
	};
}

export function loadPromotionsError(error) {
	return {
		type: LOAD_PROMOTIONS_ERROR,
		error,
	};
}

export function loadPromotionsSuccess(result) {
	return {
		type: LOAD_PROMOTIONS_SUCCESS,
		result,
	};
}

export function loadPromotionsUpdateSuccess(result) {
	return {
		type: LOAD_PROMOTIONS_UPDATE_SUCCESS,
		result,
	};
}

export function updateDisplayMessage(message) {
	return {
		type: UPDATE_DISPLAY_MESSAGE,
		message,
	};
}

export function loadChatbots() {
	return {
		type: LOAD_CHATBOTS,
	};
}

export function loadChatbotsError(error) {
	return {
		type: LOAD_CHATBOTS_ERROR,
		error,
	};
}

export function loadChatbotsSuccess(result) {
	return {
		type: LOAD_CHATBOTS_SUCCESS,
		result,
	};
}
