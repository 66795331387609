import React, {
	useEffect, useState, useReducer, Fragment,
} from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import 'rc-pagination/assets/index.css';
import T from 'prop-types';
import Modal from 'react-modal';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {
	Button, Input,
	// WrapperContainer,
} from '../../../components/Styles';
import { ListItem, ListHeader } from '../../../components/EditAdminJobListComponents/index';
// import ToastMessage from '../../../components/ToastMessage';
import { TitleWithTooltip } from '../../../components/FormComponents';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import LoadingIcon from '../../../components/LoadingIcon/LoadingIcon';
import {
	GET_PROFILE_DATA,
	FETCH_PROFILE_DATA,
	FETCH_PROFILE_DATA_SUCCESS,
	FETCH_PROFILE_DATA_FAILURE,
	UPDATE_TALENT_BASE_URL,
	SET_ALL_ELEMENTS_BLURRED,
	UPDATE_ERRORS,
	UPDATE_BLURRED_ELEMENT,
	UPDATE_ACTIONS_ERROR,
	UPDATE_ACTIONS_SUCCESS,
	UPDATE_ACTIONS_LOADER,
	SET_ACTIONS_MESSAGE,
} from '../constants';
import {
	filterProfileData,
} from '../../../utils/common';

const initialState = {
	cityList: [],
	profileJobData: {},
	loading: false,
	error: false,
	actionLoading: false,
	actionError: false,
	actionMessage: '',
	blurredElements: {
		startDate: false,
		endDate: false,
		jobTitle: false,
		description: false,
		companyName: false,
	},
	errors: {
		startDate: false,
		endDate: false,
		jobTitle: false,
		description: false,
		companyName: false,
	},
};

function setActionsMessage(message) {
	return {
		type: SET_ACTIONS_MESSAGE,
		message,
	};
}
function updateActionsLoading() {
	return {
		type: UPDATE_ACTIONS_LOADER,
	};
}

function updateActionsFailure() {
	return {
		type: UPDATE_ACTIONS_ERROR,
	};
}

function updateActionsSuccess() {
	return {
		type: UPDATE_ACTIONS_SUCCESS,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function fetchProfileJobData() {
	return {
		type: FETCH_PROFILE_DATA,
	};
}
function fetchProfileJobDataSuccess(profileJobData) {
	return {
		type: FETCH_PROFILE_DATA_SUCCESS,
		profileJobData,
	};
}
function fetchProfileJobDataFailure() {
	return {
		type: FETCH_PROFILE_DATA_FAILURE,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case FETCH_PROFILE_DATA:
		return { ...state, loading: true };
	case FETCH_PROFILE_DATA_SUCCESS:
		return {
			...state, loading: false, profileJobData: { ...action.profileJobData },
		};
	case FETCH_PROFILE_DATA_FAILURE:
		return {
			...state, loading: false, error: true, profileJobData: {},
		};
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };
	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			startDate: true,
			endDate: true,
			jobTitle: true,
			description: true,
			companyName: true,
		};
		return { ...state, blurredElements };
	}
	case UPDATE_ACTIONS_ERROR:
		return {
			...state, actionLoading: false, actionError: true,
		};
	case UPDATE_ACTIONS_SUCCESS:
		return {
			...state, actionLoading: false, actionError: false,
		};
	case UPDATE_ACTIONS_LOADER:
		return {
			...state, actionLoading: true, actionError: false,
		};
	case SET_ACTIONS_MESSAGE:
		return {
			...state, actionMessage: action.message,
		};
	default:
		return state;
	}
}

const WorkExperienceListPage = (props) => {
	const {
		match, history, updatedProfileJobData, updateWorkHistoryDetails,
	} = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		loading, profileJobData, actionLoading,
		actionError, actionMessage, errors, blurredElements,
	} = state;
	const [profileData, setProfileData] = useState({});
	const [showEditAddForm, setShowEditAddForm] = useState(false);
	const [showDeleteForm, setDeleteForm] = useState(false);
	const [selectedForm, setSelectedForm] = useState(0);
	const [selectedFormIndex, setSelectedFormIndex] = useState(-1);
	const [selectedWorkHistoryData, setSelectedWorkHistoryData] = useState({
		startDate: '',
		endDate: '',
		jobTitle: '',
		description: '',
		companyName: '',
		currentDate: false,
	});

	useEffect(() => {
		dispatch(updateErrors({
			startDate: false,
			endDate: false,
			jobTitle: false,
			description: false,
			companyName: false,
		}));
		dispatch(updateBlurredElement({
			startDate: false,
			endDate: false,
			jobTitle: false,
			description: false,
			companyName: false,
		}));
		dispatch(setAllElementsBlurred());
		if (match?.params?.profileId) {
			dispatch(fetchProfileJobData());
			// getProfileData(match.params.profileId);
			setTimeout(() => {
				dispatch(fetchProfileJobDataSuccess(updatedProfileJobData));
				setProfileData({
					...filterProfileData(updatedProfileJobData),
				});
			}, 1000);
		}
	}, []);

	useEffect(() => {
		dispatch(fetchProfileJobDataSuccess(updatedProfileJobData));
		setProfileData({
			...filterProfileData(updatedProfileJobData),
		});
	}, [updatedProfileJobData]);

	async function getProfileData(profileId) {
		dispatch(fetchProfileJobData());
		const url = `${GET_PROFILE_DATA}${profileId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				const profileDetails = await response.json();
				if (profileDetails && profileDetails?.id?.length > 0) {
					setProfileData({
						...filterProfileData(profileDetails),
					});
				}
				dispatch(fetchProfileJobDataSuccess(profileDetails));
			} else {
				dispatch(fetchProfileJobDataFailure());
			}
		} catch (err) {
			dispatch(fetchProfileJobDataFailure());
		}
	}
	const saveProfileDataForUpdate = async (workHistoryData = []) => {
		dispatch(updateActionsLoading());
		dispatch(setActionsMessage(''));
		const url = UPDATE_TALENT_BASE_URL;
		let finalWorkHistoryDetails = workHistoryData || [];
		if (selectedForm === 1 && selectedFormIndex > -1) {
			finalWorkHistoryDetails = profileJobData.workHistory?.length > 0 ? [...profileJobData.workHistory] : [];
			finalWorkHistoryDetails[selectedFormIndex] = {
				StartDate: selectedWorkHistoryData.startDate,
				EndDate: selectedWorkHistoryData.endDate,
				JobTitle: selectedWorkHistoryData.jobTitle,
				Description: selectedWorkHistoryData.description,
				CompanyName: selectedWorkHistoryData.companyName,
				IsCurrent: selectedWorkHistoryData.currentDate,
			};
		} else if (selectedForm === 2) {
			finalWorkHistoryDetails = profileJobData.workHistory?.length > 0 ? [...profileJobData.workHistory] : [];
			finalWorkHistoryDetails.push({
				StartDate: selectedWorkHistoryData.startDate,
				EndDate: selectedWorkHistoryData.endDate,
				JobTitle: selectedWorkHistoryData.jobTitle,
				Description: selectedWorkHistoryData.description,
				CompanyName: selectedWorkHistoryData.companyName,
				IsCurrent: selectedWorkHistoryData.currentDate,
			});
		}
		const formData = {
			...profileData,
			profileId: match.params.profileId,
			switchFunctionIndustry: 'Yes',
			prefIndustry: profileData?.prefIndustry ? profileData?.prefIndustry : '',
			prefCity: profileData?.prefCity ? profileData?.prefCity : [],
			workHistory: [
				...finalWorkHistoryDetails,
			],
		};
		const options = {
			method: 'PATCH',
			credentials: 'include',
			body: JSON.stringify(formData),
			headers: {
				'Content-Type': 'application/json',
			},
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				dispatch(updateActionsSuccess());
				dispatch(setActionsMessage('Work Experiences Details are updated successfully'));
				setTimeout(() => {
					closeModal();
					dispatch(setActionsMessage(''));
					selectedForm === 1 || selectedForm === 2 ? getProfileData(match.params.profileId) : '';
					updateWorkHistoryDetails(finalWorkHistoryDetails);
				}, 2000);
			} else {
				dispatch(updateActionsFailure());
				dispatch(setActionsMessage('Not Able To Update The Job profile'));
				setTimeout(() => {
					dispatch(updateActionsSuccess());
					dispatch(setActionsMessage(''));
					closeModal();
				}, 2000);
			}
		} catch (err) {
			dispatch(updateActionsFailure());
			dispatch(setActionsMessage('Not Able To Update The Job profile'));
			setTimeout(() => {
				closeModal();
				dispatch(setActionsMessage(''));
				dispatch(updateActionsSuccess());
			}, 2000);
		}
	};
	const closeModal = () => {
		setSelectedWorkHistoryData({
			startDate: '',
			endDate: '',
			jobTitle: '',
			description: '',
			companyName: '',
		});
		const removeAll = {
			startDate: false,
			endDate: false,
			jobTitle: false,
			description: false,
			companyName: false,
		};
		setSelectedForm(0);
		dispatch(updateErrors(removeAll));
		dispatch(updateBlurredElement(removeAll));
		dispatch(setAllElementsBlurred());
		setShowEditAddForm(false);
		setDeleteForm(false);
		setSelectedFormIndex(-1);
	};

	function validateForm() {
		return {
			startDate: !(selectedWorkHistoryData && selectedWorkHistoryData.startDate && selectedWorkHistoryData.startDate?.length > 0),
			// endDate: !(selectedWorkHistoryData.endDate && selectedWorkHistoryData.endDate?.length > 0),
			jobTitle: !(selectedWorkHistoryData.jobTitle && selectedWorkHistoryData.jobTitle?.length > 0),
			// description: !(selectedWorkHistoryData.description && selectedWorkHistoryData.description?.length > 0),
			companyName: !(selectedWorkHistoryData.companyName && selectedWorkHistoryData.companyName?.length > 0),
		};
	}
	const handleErrors = () => {
		const validation = validateForm();
		const errors = Object.keys(validation).reduce((acc, curr) => {
			if (validation[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		const validation = validateForm();
		const validated = !Object.keys(validation).some((i) => validation[i]);
		handleErrors();
		if (validated) {
			saveProfileDataForUpdate();
		} else {
			dispatch(setAllElementsBlurred());
		}
	};
	const reArrangeTheList = (index, caseVal) => {
		const finalArrangeResult = [...profileJobData?.workHistory];
		const updatedProfileJobData = { ...profileJobData };
		if (caseVal === 1 && index > 0 && finalArrangeResult?.length > 1) {
			finalArrangeResult[index] = finalArrangeResult.splice(index - 1, 1, finalArrangeResult[index])[0];
		} else if (caseVal === 2 && index < (finalArrangeResult.length - 1) && finalArrangeResult?.length > 1) {
			finalArrangeResult[index] = finalArrangeResult.splice(index + 1, 1, finalArrangeResult[index])[0];
		}
		updatedProfileJobData.workHistory = [...finalArrangeResult];
		dispatch(fetchProfileJobDataSuccess(updatedProfileJobData));
		saveProfileDataForUpdate(finalArrangeResult);
	};

	const deleteSelectedFormIndex = () => {
		const finalArrangeResult = [...profileJobData?.workHistory];
		const updatedProfileJobData = { ...profileJobData };
		if (selectedFormIndex > -1 && finalArrangeResult?.length > 0) {
			finalArrangeResult.splice(selectedFormIndex, 1);
			updatedProfileJobData.workHistory = [...finalArrangeResult];
			dispatch(fetchProfileJobDataSuccess(updatedProfileJobData));
			saveProfileDataForUpdate(finalArrangeResult);
		}
	};
	const editOrAddNewWorkHistoryData = () => <Modal
		isOpen={showEditAddForm}
		onRequestClose={closeModal}
		overlayClassName="modal-overlay"
		shouldCloseOnOverlayClick={false}
		className="form-modal"
	>
		<Row>
			<Col md={10} xs={10}>
				<h2 className="w-50 text-left">
					Edit/Add Work History Details
				</h2>
			</Col>
			<Col md={2} xs={2}>
				<div className="w-50 text-right">
					<button type="button" onClick={closeModal}>
						<i className="fas fa-times light-text disablePointerEvents" />
					</button>
				</div>
			</Col>
		</Row>
		<div>
			<form onSubmit={handleSubmit}>
				<Row className="m-2">
					<Col xs={12} sm={6}>
						<div className="pb-3">
							<TitleWithTooltip
								title="From Year *"
								hint="From Year"
							/>
							<DatePicker
								selected={selectedWorkHistoryData.startDate ? new Date(selectedWorkHistoryData.startDate) : null}
								dateFormat="MMM dd yyyy"
								// showMonthYearPicker
								className="meta-datepicker-work  mt-sm-2"
								placeholderText="Click to select date"
								// eslint-disable-next-line
								onChange={(date) => {
									setSelectedWorkHistoryData(({
										...selectedWorkHistoryData,
										startDate: moment(date).format('MMM DD YYYY'),
									}));
									// handleErrors();
								}}
							/>
							<ErrorMessage display={errors.startDate} element="From Year" />
						</div>
					</Col>
					<Col xs={12} sm={6}>
						<div className="pb-3">
							<Row>
								<Col xs={6}>
									<TitleWithTooltip
										title="Current"
										hint="Select Current Date If you still working on current company"
									/>
									<label className="switch mr-2 mt-2 ml-2">
										<input
											type="checkbox"
											checked={selectedWorkHistoryData.currentDate}
											onChange={(e) => {
												setSelectedWorkHistoryData(({
													...selectedWorkHistoryData,
													currentDate: e.target.checked,
													endDate: e.target.checked ? '' : null,
												}));
											}}
										/>
										<span className={`${selectedWorkHistoryData.currentDate ? '' : 'slider-disabled'} slider round`} />
									</label>
								</Col>
								{!selectedWorkHistoryData.currentDate && (
									<Col xs={6}>
										<TitleWithTooltip
											title="To Year"
											hint="To Year"
										/>
										<DatePicker
											selected={selectedWorkHistoryData.endDate ? new Date(selectedWorkHistoryData.endDate) : null}
											dateFormat="MMM dd yyyy"
											minDate={selectedWorkHistoryData.startDate ? new Date(moment(selectedWorkHistoryData.startDate).add(1, 'days').toDate()) : null}
											// showMonthYearPicker
											className="meta-datepicker-work  mt-sm-2"
											placeholderText="Click to select date"
											// eslint-disable-next-line
											onChange={(date) => {
												setSelectedWorkHistoryData(({
													...selectedWorkHistoryData,
													endDate: moment(date).format('MMM DD YYYY'),
												}));
												// handleErrors();
											}}
										/>
									</Col>
								)}
							</Row>
							{/* <ErrorMessage display={errors.endDate} element="To Year" /> */}
						</div>
					</Col>
					<Col xs={12} sm={12}>
						<div className="pb-3">
							<TitleWithTooltip
								title="Job Title Name *"
								hint="Job Title Name"
							/>
							<Input
								type="text"
								className="form-control"
								value={selectedWorkHistoryData.jobTitle}
								name="jobTitleName"
								placeholder="eg: Engineer"
								autoComplete="off"
								maxLength="100"
								onChange={(event) => {
									setSelectedWorkHistoryData(({
										...selectedWorkHistoryData,
										jobTitle: event.target.value,
									}));
									// handleErrors();
								}}
							/>
							<ErrorMessage display={errors.jobTitle} element="Job Title Name" />
						</div>
					</Col>
					<Col xs={12} sm={12}>
						<div className="pb-3">
							<TitleWithTooltip
								title="Company Name *"
								hint="Company Name"
							/>
							<Input
								type="text"
								className="form-control"
								value={selectedWorkHistoryData.companyName}
								name="companyName"
								placeholder="eg: StartBucks"
								autoComplete="off"
								maxLength="1000"
								onChange={(event) => {
									setSelectedWorkHistoryData(({
										...selectedWorkHistoryData,
										companyName: event.target.value,
									}));
									// handleErrors();
								}}
							/>
							<ErrorMessage display={errors.companyName} element="Company Name" />
						</div>
					</Col>
					<Col xs={12} sm={12}>
						<div className="pb-3">
							<TitleWithTooltip
								title="Job Description"
								hint="Job Description"
							/>
							<textarea
								rows="3"
								className="global-form-control"
								name="jobDescription"
								placeholder="Job Description"
								value={selectedWorkHistoryData.description}
								maxLength={1000}
								onChange={(e) => {
									setSelectedWorkHistoryData(({
										...selectedWorkHistoryData,
										description: e.target.value,
									}));
									// handleErrors();
								}}
							/>
							<div style={{ textAlign: 'right', color: selectedWorkHistoryData.description && selectedWorkHistoryData.description.length > 1000 ? '#ff0000' : '#aaa' }}>
								{selectedWorkHistoryData && selectedWorkHistoryData.description ? selectedWorkHistoryData.description.length : 0}/1000
							</div>
							{/* <ErrorMessage display={errors.description} element="Job Description" /> */}
						</div>
					</Col>
					<Col xs={12} sm={12} className="mt-2 text-center">
						<Button
							success
							no_radius
							type="submit"
							style={{ width: '150px' }}
						>
							<b>Save Changes</b>
						</Button>
					</Col>
					<Col xs={12} sm={12}>
						{actionLoading && <LoadingIcon />}
					</Col>
					<Col xs={12} sm={12}>
						{(actionError && actionMessage) && <p className="error error-text text-normal text-center mb-2">
							{actionMessage}
						</p>}
						{(!actionError && actionMessage) && <p className="success-text text-normal text-center mb-2">
							{actionMessage}
						</p>}
					</Col>
				</Row>
			</form>
		</div>
	</Modal>;
	const deleteSelectedWorkHistoryData = () => <Modal
		isOpen={showDeleteForm}
		onRequestClose={closeModal}
		shouldCloseOnOverlayClick={false}
		overlayClassName="modal-overlay"
		className="form-modal"
	>
		<Row>
			<Col md={10} xs={10}>
				<h3 className="w-50 text-left">Do You Want To Delete?</h3>
			</Col>
			<Col md={2} xs={2}>
				<div className="w-50 text-right">
					<button type="button" onClick={closeModal}>
						<i className="fas fa-times light-text disablePointerEvents" />
					</button>
				</div>
			</Col>
		</Row>
		<div>
			<form>
				<Row className="m-2">
					<Col xs={12} sm={12} className="mt-2 text-center">
						<Button
							success
							no_radius
							type="button"
							style={{ width: '100px' }}
							onClick={() => {
								deleteSelectedFormIndex();
							}}
						>
							<b>Delete</b>
						</Button>
						<Button
							primary
							no_radius
							type="button"
							style={{ width: '100px' }}
							onClick={() => {
								setSelectedFormIndex(-1);
								setDeleteForm(false);
								setSelectedForm(0);
							}}
						>
							<b>Cancel</b>
						</Button>
					</Col>
					<Col xs={12} sm={12}>
						{actionLoading && <LoadingIcon />}
					</Col>
					<Col xs={12} sm={12}>
						{(actionError && actionMessage) && <p className="error error-text text-normal text-center mb-2">
							{actionMessage}
						</p>}
						{(!actionError && actionMessage) && <p className="success-text text-normal text-center mb-2">
							{actionMessage}
						</p>}
					</Col>
				</Row>
			</form>
		</div>
	</Modal>;
	const getWorkHistoryList = () => profileJobData?.workHistory.map((item, index) => <ListItem
		slNo={index + 1}
		job={item}
		index={index}
		selectedStatus={2}
		moveUp={() => {
			reArrangeTheList(index, 1);
		}}
		moveDown={() => {
			reArrangeTheList(index, 2);
		}}
		openEditForm={() => {
			setSelectedWorkHistoryData({
				...item,
				indexVal: index,
				startDate: item.StartDate,
				endDate: item.EndDate,
				jobTitle: item.JobTitle,
				description: item.Description,
				companyName: item.CompanyName,
				currentDate: item?.IsCurrent || false,
			});
			setSelectedFormIndex(index);
			setSelectedForm(1);
			setShowEditAddForm(true);
		}}
		deleteData={() => {
			setSelectedFormIndex(index);
			setDeleteForm(true);
			setSelectedForm(0);
		}}
	/>);
	const renderList = () => {
		if (profileJobData && profileJobData?.workHistory?.length > 0) {
			return <div>
				<ListHeader selectedStatus={2} />
				{getWorkHistoryList()}
			</div>;
		}

		return (
			<Row style={{
				width: '100%', minHeight: '400px', justifyContent: 'center', alignItems: 'center',
			}}
			>
				<div>
					<Row>
						<Col xs={12} sm={12}>
							<div>
								<h1 style={{ color: '#bbb' }}>
									No Work History To Display
								</h1>
							</div>
						</Col>
					</Row>
				</div>
			</Row>
		);
	};
	// function handleBlur(element) {
	// 	handleErrors();
	// 	dispatch(updateBlurredElement(element));
	// }

	return (
		<div>
			{
				loading
					? <LoadingIcon />
					: <>
						<div>
							<Row>
								<Col xs={8} sm={8}>
									<h3 className="px-3">
										Edit Work History Details
									</h3>
								</Col>
								<Col xs={4} sm={4} className="text-right">
									<Button
										primary
										no_radius
										className="mb-0 mr-2"
										onClick={() => {
											setSelectedWorkHistoryData({
												startDate: '',
												endDate: '',
												jobTitle: '',
												description: '',
											});
											setSelectedForm(2);
											setShowEditAddForm(true);
										}}
									>
										<b>Add New Work History Data</b>
									</Button>
								</Col>
							</Row>
							{renderList()}
						</div>
					</>
			}
			{editOrAddNewWorkHistoryData()}
			{deleteSelectedWorkHistoryData()}
		</div>
	);
};

WorkExperienceListPage.propTypes = {
	updatedProfileJobData: T.object,
	history: T.object,
	match: T.object,
	updateWorkHistoryDetails: T.func,
};
export default WorkExperienceListPage;
