import {
	SET_TAB_STATE, SET_LOADING_STATE, SET_ERROR_STATE, GET_API_STATE, SET_API_STATE, SET_TAB_DATA_STATE, SET_SEARCH_STORIES, GET_SEARCH_STORIES, SET_SAVE_STATE, SET_FILE_STATE, SET_PAGE_MODE, GET_SERIES_DATA_BY_SLUG, SET_INITIAL_STATE,
} from './type';

export function setLoadingState(value) {
	return {
		type: SET_LOADING_STATE,
		value,
	};
}

export function setErrorState(value) {
	return {
		type: SET_ERROR_STATE,
		value,
	};
}

export function setTabState(value) {
	return {
		type: SET_TAB_STATE,
		value,
	};
}

export function setAPIState(value) {
	return {
		type: SET_API_STATE,
		value,
	};
}

export function getAPIState(value) {
	return {
		type: GET_API_STATE,
		value,
	};
}

export function setTabDataState(value) {
	return {
		type: SET_TAB_DATA_STATE,
		value,
	};
}

export function setSearchModal(value) {
	return {
		type: SET_SEARCH_STORIES,
		value,
	};
}

export function getSearchModal(list, title, offset, loadMore, notIn) {
	return {
		type: GET_SEARCH_STORIES,
		title,
		offset,
		list,
		loadMore,
		notIn,
	};
}

export function setSave(value) {
	return {
		type: SET_SAVE_STATE,
		value,
	};
}

export function setFile(value) {
	return {
		type: SET_FILE_STATE,
		value,
	};
}

export function setPageMode(value) {
	return {
		type: SET_PAGE_MODE,
		value,
	};
}

export function getSeriesData(value) {
	return {
		type: GET_SERIES_DATA_BY_SLUG,
		value,
	};
}

export function setInitialState() {
	return {
		type: SET_INITIAL_STATE,
	};
}
