import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import T from 'prop-types';
import styled from 'styled-components';
import { Button } from '../Styles';

const TextArea = styled.textarea`
		border: 1px solid #ced4da;
		padding: 0.5rem;
		border-radius: 0.25rem;
		width: 350px;
		:focus {
				border: 3px solid #ced4da;
				outline: none;
		}
`;

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
	},
};

const CommentModal = (props) => {
	const {
		openDialog, closeDialog, addCommentAndSubmit, comment, onCommentChange,
	} = props;
	const [error, showError] = useState(false);

	useEffect(() => {
		if (comment && comment.length > 0) {
			showError(false);
		}
	}, [comment]);

	function onAddComment(e) {
		e.preventDefault();
		if (!comment || comment.length < 1) {
			showError(true);
		} else {
			addCommentAndSubmit(comment);
			closeDialog();
		}
	}

	return (
		<Modal
			isOpen={openDialog}
			onRequestClose={closeDialog}
			style={customStyles}
			contentLabel="Confirmation Dialog"
			overlayClassName="modal-overlay"
		>
			<div>
				<form onSubmit={onAddComment}>
					<TextArea
						name="comment"
						placeholder="Add a comment"
						maxLength="1000"
						autoComplete="off"
						cols="10"
						rows="5"
						value={comment || ''}
						onChange={(e) => onCommentChange(e.target.value)}
					/>
					{
						error
							? <p className="error-text">
								* This field cannot be empty
							</p>
							: null
					}
					<div className="text-right">
						<Button type="submit" success className="mr-3">Submit</Button>
						<Button light onClick={closeDialog}>Cancel</Button>
					</div>
				</form>

			</div>
		</Modal>
	);
};

CommentModal.propTypes = {
	openDialog: T.bool,
	closeDialog: T.func,
	addCommentAndSubmit: T.func,
	comment: T.string,
	onCommentChange: T.func,
};

export default CommentModal;
