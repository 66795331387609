import { Map } from 'immutable';
import {
	ADD_NEW_CITY,
	CITY_ELEMENT_ON_CHANGE,
	CREATE_CITY,
	CREATE_CITY_SUCCESS,
	CREATE_CITY_ERROR,
	SELECT_CITY,
	UNMOUNT_CITY_FORM_MESSAGE,
	SET_CITY_SLUG_UNIQUE_VALUE,
	UPDATE_CITY,
	UPDATE_CITY_ERROR,
	UPDATE_CITY_SUCCESS,
	DELETE_CITY,
	DELETE_CITY_ERROR,
	DELETE_CITY_SUCCESS,
	CLEAR_CITY_MANAGER_DATA,
} from './type';

import {
	CITY_CREATE_LOAD_MESSAGE,
	CITY_CREATE_SUCCESS_MESSAGE,
	CITY_ERROR_MESSAGE,
	CITY_DELETE_LOAD_MESSAGE,
	CITY_DELETE_SUCCESS_MESSAGE,
	CITY_UPDATE_SUCCESS_MESSAGE,
	CITY_UPDATE_LOAD_MESSAGE,
} from './constants';

const initialState = Map({
	operation: null,
	formData: {
		name: '',
		slug: '',
		state: '',
		country: '',
	},
	selectedCity: null,
	uniqueSlug: false,
	formMessage: null,
	loading: false,
});

function cityReducer(state = initialState, action) {
	switch (action.type) {
	case SELECT_CITY: {
		const { selectedCity } = action;
		if (!selectedCity || Object.keys(selectedCity).length < 1) {
			const form = {
				name: '',
				slug: '',
				state: '',
				country: '',
			};
			return state
				.set('operation', null)
				.set('selectedCity', null)
				.set('uniqueSlug', true)
				.set('formData', form)
				.set('dropdownTitle', null);
		}

		const form = {
			name: action.selectedCity.name,
			slug: action.selectedCity.slug,
			state: action.selectedCity.state,
			country: action.selectedCity.country,
		};
		return state
			.set('selectedCity', action.selectedCity)
			.set('formData', form)
			.set('operation', 'edit')
			.set('uniqueSlug', true)
			.set('formMessage', null)
			.set('dropdownTitle', action.selectedCity);
	}
	case ADD_NEW_CITY: {
		const formData = {
			name: '',
			slug: '',
			state: '',
			country: '',
		};
		return state
			.set('operation', 'add')
			.set('formData', formData)
			.set('selectedCity', null)
			.set('uniqueSlug', false)
			.set('formMessage', null)
			.set('dropdownTitle', null);
	}
	case CITY_ELEMENT_ON_CHANGE: {
		const newState = { ...state.get('formData') };
		newState[action.elementData.name] = action.elementData.value;
		if (action.elementData.name === 'name') {
			const slug = (action.elementData.value).replace(/ /g, '-').replace(/[^A-Za-z0-9-]/g, '').toLowerCase();
			newState.slug = slug;
		}
		if (action.elementData.name === 'slug') {
			const slug = (action.elementData.value).replace(/ /g, '-').replace(/[^A-Za-z0-9-]/g, '');
			newState.slug = slug;
		}
		return state
			.set('formData', newState);
	}
	case CREATE_CITY:
		return state
			.set('formMessage', CITY_CREATE_LOAD_MESSAGE)
			.set('loading', true);
	case CREATE_CITY_SUCCESS: {
		return state
			.set('selectedCity', null)
			.set('formMessage', CITY_CREATE_SUCCESS_MESSAGE)
			.set('loading', false);
	}
	case CREATE_CITY_ERROR:
		return state
			.set('formMessage', CITY_ERROR_MESSAGE)
			.set('loading', false);
	case UNMOUNT_CITY_FORM_MESSAGE:
		return state
			.set('formMessage', null);
	case SET_CITY_SLUG_UNIQUE_VALUE:
		return state
			.set('uniqueSlug', action.value);
	case UPDATE_CITY:
		return state
			.set('formMessage', CITY_UPDATE_LOAD_MESSAGE)
			.set('loading', true);
	case UPDATE_CITY_SUCCESS:
		return state
			.set('selectedCity', null)
			.set('formMessage', CITY_UPDATE_SUCCESS_MESSAGE)
			.set('loading', false);
	case UPDATE_CITY_ERROR:
		return state
			.set('formMessage', CITY_ERROR_MESSAGE)
			.set('loading', false);
	case DELETE_CITY:
		return state
			.set('formMessage', CITY_DELETE_LOAD_MESSAGE)
			.set('loading', true);
	case DELETE_CITY_SUCCESS:
		return state
			.set('selectedCity', null)
			.set('formMessage', CITY_DELETE_SUCCESS_MESSAGE)
			.set('loading', false);
	case DELETE_CITY_ERROR:
		return state
			.set('formMessage', CITY_ERROR_MESSAGE)
			.set('loading', false);
	case CLEAR_CITY_MANAGER_DATA:
		return initialState;
	default:
		return state;
	}
}

export default cityReducer;
