import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { USER_BASE_API_URL } from './constants';
import {
	LOAD_PROFILES, LOAD_MORE_PROFILES, SAVE_PROFILE, LOAD_FILTERED_PROFILES,
} from './type';
import {
	profilesLoadedSuccessfully,
	profilesLoadingError,
	saveProfileSuccessfully,
	saveProfileError,
	loadFilteredProfilesSuccessfully,
	loadFilteredProfilesError,
	loadMoreProfilesSuccessfully,
	loadMoreProfilesError,
} from './actions';

export function* loadProfiles(action) {
	const url = `${USER_BASE_API_URL}/profile/data/${action.page}`;
	const options = {
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.status) {
				yield put(profilesLoadedSuccessfully(responseMessage));
			} else {
				yield put(profilesLoadingError(responseMessage));
			}
		}
	} catch (error) {
		yield put(profilesLoadingError(error));
	}
}

export function* loadMoreProfiles(action) {
	const url = `${USER_BASE_API_URL}/profile/data/${action.onPage + 1}${action.queryString}`;
	const options = {
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.status) {
				yield put(loadMoreProfilesSuccessfully(responseMessage.data));
			} else {
				yield put(loadMoreProfilesError(responseMessage));
			}
		}
	} catch (error) {
		yield put(loadMoreProfilesError(error));
	}
}

export function* loadFilteredProfiles(action) {
	const url = `${USER_BASE_API_URL}/profile/data/${action.page}?criteriaType=${action.filterCriteria}&criteriaValue=${encodeURIComponent(action.filterByValue)}`;
	const options = {
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.status) {
				yield put(loadFilteredProfilesSuccessfully(responseMessage));
			} else {
				yield put(loadFilteredProfilesError(responseMessage));
			}
		}
	} catch (error) {
		yield put(loadFilteredProfilesError(error));
	}
}

export function* saveProfile(action) {
	const url = `${USER_BASE_API_URL}/update-role`;
	const options = {
		method: 'POST',
		credentials: 'include',
		body: action.data,
	};
	try {
		const response = yield call(fetch, url, options);
		const responseMessage = yield response.json();
		if (responseMessage.status === 'success') {
			yield put(saveProfileSuccessfully(responseMessage));
		} else {
			yield put(saveProfileError(responseMessage.error));
		}
	} catch (error) {
		yield put(saveProfileError(error));
	}
}

export default function* peopleData() {
	yield takeLatest(LOAD_PROFILES, loadProfiles);
	yield takeLatest(LOAD_MORE_PROFILES, loadMoreProfiles);
	yield takeLatest(SAVE_PROFILE, saveProfile);
	yield takeLatest(LOAD_FILTERED_PROFILES, loadFilteredProfiles);
}
