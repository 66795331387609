export const TOPICS_BASE_URL = '/api/v2/rw/topic';
export const FILTER_TOPICS = 'FILTER_TOPICS';
export const FETCH_TOPICS_SUCCESS = 'FETCH_TOPICS_SUCCESS';
export const FETCH_TOPICS_FAILURE = 'FETCH_TOPICS_FAILURE';
export const FETCH_TOPICS = 'FETCH_TOPICS';
export const FILTER_TOPICS_SUCCESS = 'FILTER_TOPICS_SUCCESS';
export const FILTER_TOPICS_FAILURE = 'FILTER_TOPICS_FAILURE';
export const SET_TOPICS_PAGE = 'SET_TOPICS_PAGE';
export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
export const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
export const UPDATE_ACTIONS_ERROR = 'UPDATE_ACTIONS_ERROR';
export const UPDATE_ACTIONS_SUCCESS = 'UPDATE_ACTIONS_SUCCESS';
export const UPDATE_ACTIONS_LOADER = 'UPDATE_ACTIONS_LOADER';
export const SET_ACTIONS_MESSAGE = 'SET_ACTIONS_MESSAGE';
export const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
export const SET_ELEMENTS_BLURRED = 'SET_ELEMENTS_BLURRED';
export const SAVING_MESSAGE = { intent: 'info', message: 'Saving data...' };
export const SAVING_SUCCESS_MESSAGE = { intent: 'success', message: 'Data Saved Successfully' };
export const SAVING_FAILURE_MESSAGE = { intent: 'danger', message: 'Data Save Failed. Something went wrong. Try Again!.' };

export const toolbarOptions = [
	['bold', 'italic', 'underline'],
	['link'],
	// [{ list: 'ordered' }, { list: 'bullet' }],
	// [{ script: 'sub' }, { script: 'super' }],
	// [{ indent: '-1' }, { indent: '+1' }],
	// [{ direction: 'rtl' }],
	// ['clean'],
];
