export const GET_JOB_STRENGTH_TYPE = '/api/v2/job_curated_list/type/JOB-STRENGTH';

// api for searching city
// preprod.yourstory.com / api / v2 / cities / key / benga
// benga is the search text which entered by user

// api for getting full list by country
// preprod.yourstory.com / api / v2 / cities / india

// Industry dropdown
// preprod.yourstory.com / api / v2 / job_curated_list / type / INDUSTRY

// Primary Job Function / Primary Job Expertise
// preprod.yourstory.com / api / v2 / job_curated_list / type / JOB - FUNCTION

// Secondary Job Function / Secondary Job Expertise
// preprod.yourstory.com / api / v2 / job_curated_list / type / SUB - JOB - FUNCTION ? typeFilter[] = design & typeFilter[]=administration
// typeFilter is to filter values based primary selection in this field need to send slug of primary job function or expertise

// Education Type
// preprod.yourstory.com / api / v2 / job_curated_list / type / EDUCATION - TYPE

// Degrees
// preprod.yourstory.com / api / v2 / job_curated_list / type / EDUCATION - DEGREE

// Notice Period
// preprod.yourstory.com / api / v2 / job_curated_list / type / NOTICE_PERIOD

// Strength
// preprod.yourstory.com / api / v2 / job_curated_list / type / JOB - STRENGTH
