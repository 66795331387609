import { Map } from 'immutable';

import {
	LOAD_ALL_BRANDS, LOAD_ALL_BRANDS_SUCCESS, LOAD_ALL_BRANDS_ERROR, SELECT_BRAND, ADD_BRAND, CREATE_BRAND, CREATE_BRAND_SUCCESS, CREATE_BRAND_ERROR, UPDATE_BRAND, UPDATE_BRAND_SUCCESS, UPDATE_BRAND_ERROR, DELETE_BRAND, DELETE_BRAND_SUCCESS, DELETE_BRAND_ERROR, BRAND_ELEMENT_ON_CHANGE, WRITE_BRAND_SLUG, UNMOUNT_BRAND_FORM_MESSAGE,
} from './type';

import {
	BRAND_CREATE_LOAD_MESSAGE, BRAND_CREATE_SUCCESS_MESSAGE, BRAND_UPDATE_LOAD_MESSAGE, BRAND_UPDATE_SUCCESS_MESSAGE, BRAND_DELETE_LOADING_MESSAGE, BRAND_DELETE_SUCCESS_MESSAGE, BRAND_ERROR_MESSAGE,
} from './constants';

const initialState = Map({
	loading: false,
	error: false,
	allBrands: null,
	selectedBrand: null,
	formData: {
		name: '',
		slug: '',
		description: '',
		image: '',
		locale: '',
		internal: true,
	},
	addBrand: false,
	editBrand: false,
	dropdownTitle: 'SELECT BRAND',
	writeSlug: false,
	uniqueSlug: false,
	formMessage: null,
});

function brandReducer(state = initialState, action) {
	switch (action.type) {
	case LOAD_ALL_BRANDS:
		return state
			.set('loading', true)
			.set('error', false)
			.set('addBrand', false)
			.set('editBrand', false)
			.set('dropdownTitle', 'SELECT BRAND');
	case LOAD_ALL_BRANDS_SUCCESS:
		return state
			.set('loading', false)
			.set('error', false)
			.set('addBrand', false)
			.set('editBrand', false)
			.set('dropdownTitle', 'SELECT BRAND')
			.set('allBrands', action.allBrands);
	case LOAD_ALL_BRANDS_ERROR:
		return state
			.set('loading', false)
			.set('error', action.error)
			.set('addBrand', false)
			.set('editBrand', false);
	case SELECT_BRAND: {
		const selected = action.selectedBrand.name;
		if (selected === state.get('dropdownTitle')) {
			const form = {
				name: '',
				slug: '',
				description: '',
				image: '',
				locale: '',
				internal: true,
			};
			return state
				.set('loading', false)
				.set('error', false)
				.set('addBrand', false)
				.set('editBrand', false)
				.set('selectedBrand', null)
				.set('writeSlug', false)
				.set('uniqueSlug', true)
				.set('formData', form);
		}
		const form = {
			name: action.selectedBrand.name,
			slug: action.selectedBrand.slug,
			description: action.selectedBrand.description,
			image: action.selectedBrand.imagelocation,
			locale: action.selectedBrand.locale,
			internal: action.selectedBrand.internal,
		};
		return state
			.set('selectedBrand', action.selectedBrand)
			.set('formData', form)
			.set('editBrand', true)
			.set('addBrand', false)
			.set('writeSlug', false)
			.set('uniqueSlug', true)
			.set('formMessage', null);
	}
	case ADD_BRAND: {
		const form = {
			name: '',
			slug: '',
			description: '',
			image: '',
			locale: '',
			internal: true,
		};
		return state
			.set('addBrand', true)
			.set('editBrand', false)
			.set('selectedBrand', null)
			.set('dropdownTitle', 'SELECT BRAND')
			.set('formData', form)
			.set('writeSlug', false)
			.set('uniqueSlug', false)
			.set('formMessage', null);
	}
	case CREATE_BRAND:
		return state
			.set('formMessage', BRAND_CREATE_LOAD_MESSAGE);
	case CREATE_BRAND_SUCCESS:
		return state
			.set('formMessage', BRAND_CREATE_SUCCESS_MESSAGE);
	case CREATE_BRAND_ERROR:
		return state
			.set('formMessage', BRAND_ERROR_MESSAGE);
	case UPDATE_BRAND:
		return state
			.set('formMessage', BRAND_UPDATE_LOAD_MESSAGE);
	case UPDATE_BRAND_SUCCESS:
		return state
			.set('selectedBrand', null)
			.set('formMessage', BRAND_UPDATE_SUCCESS_MESSAGE);
	case UPDATE_BRAND_ERROR:
		return state
			.set('formMessage', BRAND_ERROR_MESSAGE);
	case DELETE_BRAND:
		return state
			.set('formMessage', BRAND_DELETE_LOADING_MESSAGE);
	case DELETE_BRAND_SUCCESS:
		return state
			.set('selectedBrand', null)
			.set('formMessage', BRAND_DELETE_SUCCESS_MESSAGE);
	case DELETE_BRAND_ERROR:
		return state
			.set('formMessage', BRAND_ERROR_MESSAGE);
	case BRAND_ELEMENT_ON_CHANGE: {
		const newState = { ...state.get('formData') };
		newState[action.elementData.name] = action.elementData.value;
		if (action.elementData.name === 'name') {
			// eslint-disable-next-line
				newState.name = (action.elementData.value).replace(/[@#!$%^&*()_+|~=`{}[\]:";'<>?,.\/]/g, '');
			if (state.get('writeSlug') === false && /[A-Za-z0-9-]/g.test(action.elementData.value)) {
				const slug = (action.elementData.value).replace(/ /g, '-').replace(/[^A-Za-z0-9-]/g, '').toLowerCase();
				newState.slug = slug;
			}
		}
		if (action.elementData.name === 'slug') {
			if (state.get('writeSlug') === true) {
				const slug = (action.elementData.value).replace(/ /g, '-').replace(/[^A-Za-z0-9-]/g, '');
				newState.slug = slug;
			}
		}
		return state
			.set('formData', newState)
			.set('uniqueSlug', isSlugUnique(state, newState));
	}
	case WRITE_BRAND_SLUG: {
		if (state.get('selectedBrand')) {
			return state
				.set('writeSlug', false);
		}
		return state
			.set('writeSlug', true);
	}
	case UNMOUNT_BRAND_FORM_MESSAGE:
		return state
			.set('formMessage', null);
	default:
		return state;
	}
}

const isSlugUnique = (state, newState) => {
	if (state.get('editBrand')) {
		if (state.get('selectedBrand').slug === newState.slug) {
			return true;
		}
	}
	return checkSlug(state, newState);
};

const checkSlug = (state, newState) => {
	const allBrands = state.get('allBrands');
	let flag = 0;
	allBrands.forEach((brand) => {
		if (brand.slug === newState.slug) {
			flag = 1;
		}
	});
	if (flag === 1) {
		return false;
	}
	return true;
};

export default brandReducer;
