import React, {
	useEffect, useState, useReducer, Fragment,
} from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import 'rc-pagination/assets/index.css';
import Pagination from 'rc-pagination';
import T from 'prop-types';
import {
	Input, Button, WrapperContainer,
} from '../../components/Styles';
// import ToastMessage from '../../components/ToastMessage';
import { CaptabelGiftCodesListHeader, CaptabelGiftCodesList } from '../../components/CaptableGiftCodesComponent/index';
import locale from '../WatchlistedStories/en_US';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';
import {
	CAPTABLE_GIFT_CODES_BASE_URL,
	CAPTABLE_GIFT_CODE_STATUS_BASE_URL,
	FETCH_CAPTABLE_GIFT_CODES,
	FETCH_CAPTABLE_GIFT_CODES_SUCCESS,
	FETCH_CAPTABLE_GIFT_CODES_FAILURE,
	FILTER_CAPTABLE_GIFT_CODES,
	FILTER_CAPTABLE_GIFT_CODES_SUCCESS,
	FILTER_CAPTABLE_GIFT_CODES_FAILURE,
	SET_CAPTABLE_GIFT_CODES_PAGE,
	SET_ALL_ELEMENTS_BLURRED,
	UPDATE_ERRORS,
	UPDATE_BLURRED_ELEMENT,
	UPDATE_ACTIONS_ERROR,
	UPDATE_ACTIONS_SUCCESS,
	UPDATE_ACTIONS_LOADER,
	SET_ACTIONS_MESSAGE,
	SET_FORM_DATA,
	UPDATE_FORM_DATA,
	SET_ELEMENTS_BLURRED,
} from './constants';

5;

const initialState = {
	captableGiftCodes: [],
	giftCodesCount: null,
	loading: false,
	error: false,
	page: 1,
	actionLoading: false,
	actionError: false,
	actionMessage: '',
	formData: {
		name: null,
		designation: null,
		giftCode: null,
		status: null,
	},
	blurredElements: {
		name: false,
		designation: false,
		giftCode: false,
		status: false,
	},
	errors: {
		name: false,
		designation: false,
		giftCode: false,
		status: false,
	},
};

function setBlurredElements(blurredElements) {
	return {
		type: SET_ELEMENTS_BLURRED,
		blurredElements,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}
function fetchCaptableGiftCodes() {
	return {
		type: FETCH_CAPTABLE_GIFT_CODES,
	};
}

function fetchCaptableGiftCodesSuccess(captableGiftCodes) {
	return {
		type: FETCH_CAPTABLE_GIFT_CODES_SUCCESS,
		captableGiftCodes,
	};
}

function fetchCaptableGiftCodesFailure(error) {
	return {
		type: FETCH_CAPTABLE_GIFT_CODES_FAILURE,
		error,
	};
}

function setPage(page) {
	return {
		type: SET_CAPTABLE_GIFT_CODES_PAGE,
		page,
	};
}

function setFormData(giftCode) {
	return {
		type: SET_FORM_DATA,
		giftCode,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case SET_FORM_DATA:
		return { ...state, formData: action.giftCode };

	case UPDATE_FORM_DATA: {
		const formData = { ...state.formData };
		const { changedData } = action;
		formData[changedData.name] = changedData.value;
		return { ...state, formData };
	}

	case FETCH_CAPTABLE_GIFT_CODES:
		return { ...state, loading: true };
	case FETCH_CAPTABLE_GIFT_CODES_SUCCESS:
		return {
			...state, loading: false, captableGiftCodes: [...action.captableGiftCodes?.rows], giftCodesCount: action.captableGiftCodes?.count,
		};
	case FETCH_CAPTABLE_GIFT_CODES_FAILURE:
		return {
			...state, loading: false, error: true, captableGiftCodes: [],
		};
	case FILTER_CAPTABLE_GIFT_CODES:
		return { ...state, loading: true };
	case FILTER_CAPTABLE_GIFT_CODES_SUCCESS:
		return {
			...state, loading: false, captableGiftCodes: [...action.captableGiftCodes?.rows], giftCodesCount: action.captableGiftCodes?.count,
		};
	case FILTER_CAPTABLE_GIFT_CODES_FAILURE:
		return {
			...state, loading: true, error: true, captableGiftCodes: [],
		};
	case SET_CAPTABLE_GIFT_CODES_PAGE:
		return { ...state, page: action.page };
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };
	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			name: true,
			designation: true,
			giftCode: true,
			status: true,
		};
		return { ...state, blurredElements };
	}
	case SET_ELEMENTS_BLURRED: {
		return { ...state, blurredElements: action.blurredElements };
	}
	case UPDATE_ACTIONS_ERROR:
		return {
			...state, actionLoading: false, actionError: true,
		};
	case UPDATE_ACTIONS_SUCCESS:
		return {
			...state, actionLoading: false, actionError: false,
		};
	case UPDATE_ACTIONS_LOADER:
		return {
			...state, actionLoading: true, actionError: false,
		};
	case SET_ACTIONS_MESSAGE:
		return {
			...state, actionMessage: action.message,
		};
	default:
		return state;
	}
}

const CaptableGiftCodesManager = (props) => {
	const {
		history, pageTitle,
	} = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		captableGiftCodes, loading, giftCodesCount, page, blurredElements, formData,
	} = state;
	const [searchValue, setSearchValue] = useState('');
	const [selectedStatus, setSelectedStatus] = useState('ACTIVE');

	useEffect(() => {
		getCaptableGiftCodes((page - 1) * 10, 10, '', selectedStatus);
		pageTitle('captableGiftCodeManager');
	}, []);

	useEffect(() => {
		handleErrors();
	}, [formData, blurredElements]);

	async function getCaptableGiftCodes(offset, limit, searchVal = searchValue, filterVal = selectedStatus) {
		dispatch(fetchCaptableGiftCodes());
		const url = `${CAPTABLE_GIFT_CODES_BASE_URL}?offset=${offset}&limit=${limit}&search=${searchVal}&status=${filterVal}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				const CaptableGiftCodes = await response.json();
				dispatch(fetchCaptableGiftCodesSuccess(CaptableGiftCodes));
			} else {
				dispatch(fetchCaptableGiftCodesFailure());
			}
		} catch (err) {
			dispatch(fetchCaptableGiftCodesFailure(err));
		}
	}

	const handleActions = async ({
		id,
		method,
		selectStatus,
		callBack,
		callBackFromToolTipMessage,
	}) => {
		const options = {
			method,
			credentials: 'include',
			body: JSON.stringify({
				giftCodeId: id,
				status: selectStatus,
			}),
			headers: { 'Content-Type': 'application/json' },
		};
		const postParam = {
			DELETE: `${CAPTABLE_GIFT_CODES_BASE_URL}/${id}`,
			PATCH: `${CAPTABLE_GIFT_CODE_STATUS_BASE_URL}`,
		};
		if (callBackFromToolTipMessage) callBackFromToolTipMessage(1);
		try {
			const response = await fetch(`${postParam[method]}`, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
				if (callBack) callBack();
			} else if (response.status === 201 || response.status === 200) {
				if (callBackFromToolTipMessage) callBackFromToolTipMessage(2);
				setTimeout(() => {
					closeModal();
					getCaptableGiftCodes((page - 1) * 10, 10, '', selectedStatus);
					if (callBack) callBack();
				}, 2000);
			} else {
				if (callBackFromToolTipMessage) callBackFromToolTipMessage(3);
				setTimeout(() => {
					closeModal();
					if (callBack) callBack();
				}, 2000);
			}
		} catch (err) {
			setTimeout(() => {
				closeModal();
				if (callBackFromToolTipMessage) callBackFromToolTipMessage(3);
				if (callBack) callBack();
			}, 2000);
		}
	};

	function handleSearch(e) {
		e.preventDefault();
		const { value } = e.target;
		getCaptableGiftCodes(0, 10, value, selectedStatus);
		dispatch(setPage(1));
	}

	function onPageChange(page) {
		getCaptableGiftCodes((page - 1) * 10, 10, '', selectedStatus);
		dispatch(setPage(page));
	}

	function closeModal() {
		dispatch(setPage(1));
		dispatch(updateErrors({
			name: false,
			designation: false,
			giftCode: false,
			status: false,
		}));
		dispatch(setBlurredElements({
			name: false,
			designation: false,
			giftCode: false,
			status: false,
		}));
		dispatch(setAllElementsBlurred());
		dispatch(setFormData({}));
	}

	function validateForm() {
		return {
			// name: !(formData && formData.name && formData.name?.length > 0),
			// designation: !(formData && formData.designation && formData.designation?.length > 0),
			giftCode: !(formData && formData.giftCode && formData.giftCode?.length > 0),
			status: !(formData && formData.status && formData.status?.length > 0),
		};
	}
	const handleErrors = () => {
		const validation = validateForm();
		const errors = Object.keys(validation).reduce((acc, curr) => {
			if (validation[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	};

	function onStatusChange(event) {
		const value = event;
		setSelectedStatus(value);
		getCaptableGiftCodes((page - 1) * 10, 10, searchValue, value);
		dispatch(setPage(1));
	}

	function getCaptableGiftCodesManager() {
		return captableGiftCodes.map((giftCode, index) => <CaptabelGiftCodesList
			key={giftCode.id}
			id={giftCode.id}
			history={history}
			slNo={(page - 1) * 10 + index + 1}
			giftCodeData={giftCode}
			handleActions={handleActions}
			index={index}
			selectedStatusOfGiftCode={selectedStatus}
		/>);
	}

	function renderList() {
		if (captableGiftCodes && captableGiftCodes.length > 0) {
			return <div>
				<CaptabelGiftCodesListHeader selectedStatus={selectedStatus} />
				{getCaptableGiftCodesManager()}
			</div>;
		}

		return (
			<Row style={{
				width: '100%', minHeight: '400px', justifyContent: 'center', alignItems: 'center',
			}}
			>
				<div>
					<h1 style={{ color: '#bbb' }}>
						No Captable Gift Codes To Display
					</h1>
				</div>
			</Row>
		);
	}

	return (
		<WrapperContainer>
			<Row className="">
				<Col sm={4} xs={12} className="mb-2">
					<h2>Captable Gift Codes</h2>
				</Col>
				<Col sm={8} xs={12}>
					<Row style={{ justifyContent: 'flex-end' }} className="mr-1 mb-2">
						{/* <p className="text-bold mb-0 pt-1">Filter</p> */}
						{/* <Col sm={3} xs={12} className="mb-2">
							<DropDown className="mt-0" onChange={onStatusChange}>
								<option value="DRAFT">DRAFT</option>
								<option value="INREVIEW">INREVIEW</option>
								{user.roles.liveFeedCardManager === 2 && <option value="PUBLISHED">PUBLISHED</option>}
								{user.roles.liveFeedCardManager === 2 && <option value="UNPUBLISHED">UNPUBLISHED</option>}
							</DropDown>
						</Col> */}
						<Col sm={4} xs={12} className="mb-2">
							<Input
								id="search-box"
								type="text"
								className="form-control"
								placeholder="Search By Gift Code"
								value={searchValue}
								onChange={(e) => {
									handleSearch(e);
									setSearchValue(e.target.value);
								}}
							/>
						</Col>
						<Col sm={2} xs={12} style={{ justifyContent: 'flex-end', display: 'none' }}>
							<Button
								primary
								no_radius
								className="mb-0 mr-2"
								onClick={() => {
									dispatch(setFormData({}));
									dispatch(updateErrors({
										name: false,
										designation: false,
										giftCode: false,
										status: false,
									}));
									dispatch(setBlurredElements({
										name: false,
										designation: false,
										giftCode: false,
										status: false,
									}));
								}}
							>
								<b>ADD NEW GIFT CODE</b>
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
			{
				loading
					? <LoadingIcon />
					: <>
						<Row>
							<Col
								xs={1.7}
								className={`nav-text ${selectedStatus === 'ACTIVE' ? 'nav-b-a' : 'nav-b'}`}
								onClick={() => onStatusChange('ACTIVE')}
							>
								ACTIVE
							</Col>
							<Col
								xs={1.7}
								className={`nav-text ${selectedStatus === 'DELETED' ? 'nav-b-a' : 'nav-b'}`}
								onClick={() => onStatusChange('DELETED')}
							>
								DELETED
							</Col>
							<Col
								xs={1.7}
								className={`nav-text ${selectedStatus === 'EXPIRED' ? 'nav-b-a' : 'nav-b'}`}
								onClick={() => onStatusChange('EXPIRED')}
							>
								EXPIRED
							</Col>
						</Row>
						{renderList()}
						{captableGiftCodes && captableGiftCodes.length > 0
							? <div>
								<Row className="mt-3 pr-4" style={{ justifyContent: 'flex-end' }}>
									<Pagination onChange={onPageChange} current={page} total={giftCodesCount} locale={locale} />
								</Row>
							</div>
							: null}
					</>
			}
			{/* {editGiftCodeOfTheJob()} */}
		</WrapperContainer>
	);
};

CaptableGiftCodesManager.propTypes = {
	history: T.object,
	pageTitle: T.func,
};
export default CaptableGiftCodesManager;
