/* eslint-disable no-useless-escape */
import cheerio from 'cheerio';
import moment from 'moment-timezone';
import { EDITOR_API_BASE_URL, EDITOR_VERSION, CATEGORY_SLUG_ARRAY } from './constants';
import { STORY_LIST_BASE_API_URL } from '../StoryList/constants';

const { clone, merge } = require('lodash');

// get `editorFormData` from api response data
const getEditorFormData = (story, metadata, brand) => {
	const formData = {
		storyId: story.storyId,
		title: story.title,
		slug: story.slug,
		subtitle: story.subtitle,
		postJson: story.postJson,
		imported: story.imported,
		publishedAt: story.publishedAt,
		storyType: story.type,
		articleType: story.articleType,
		protected: story.protected,
		storyShortHeading: story.storyShortHeading,
	};
	if (story.type === 'RICH') {
		formData.plagiarismCheck = story.plagiarismCheck;
	}
	if (['WEBINAR', 'VIDEO'].includes(story.type)) {
		formData.mediaId = story.mediaId;
		formData.videoPlatform = story.videoPlatform;
		formData.series = story.series;
	}

	if (!['en_us', 'en_gb'].includes(brand.locale.toLowerCase())) {
		formData.postContent = story.postContent.normalize('NFC');
	} else {
		formData.postContent = story.postContent;
	}
	if (metadata.banner && metadata.banner.length > 0) {
		formData.banner = metadata.banner;
		formData.parallaxBanner = metadata.parallaxBanner;
	}
	return formData;
};

// check is the brand locale is english
const isLocaleEnglish = (brand) => {
	if (['en_us', 'en_gb'].includes(brand.locale.toLowerCase())) {
		return true;
	}
	return false;
};

// generate rich story url prefix
// changes made in the function has to be made in corresponding API function as well
const generateRichStoryUrlPrefix = (brand) => {
	try {
		const brandSlug = brand.slug;
		const CURRENT_MONTH = `0${(new Date().getMonth() + 1).toString()}`.slice(-2);
		const CURRENT_YEAR = new Date().getFullYear();
		const domain = process.env.REACT_APP_SERVICE_PWA;
		const captableDomain = process.env.REACT_APP_SERVICE_CAPTABLE_PWA;
		const boniDomain = process.env.REACT_APP_SERVICE_BRANDS_OF_NEW_INDIA_PWA;
		let prefix;
		switch (brandSlug) {
		case 'yourstory':
			prefix = `${domain}/${CURRENT_YEAR}/${CURRENT_MONTH}/`;
			break;
		case 'herstory':
		case 'socialstory':
		case 'germany':
		case 'deutschland':
		case 'enterprise-story':
			prefix = `${domain}/${brandSlug}/${CURRENT_YEAR}/${CURRENT_MONTH}/`;
			break;
		case 'the-captable':
			prefix = `${captableDomain}/${CURRENT_YEAR}/${CURRENT_MONTH}/`;
			break;
		case 'brands-of-new-india':
			prefix = `${boniDomain}/${CURRENT_YEAR}/${CURRENT_MONTH}/`;
			break;
		default:
			prefix = `${domain}/${brandSlug}/`;
			break;
		}
		return prefix;
	} catch (err) {
		console.log('Story url prefix generate error:', err.message);
	}
};

// generate video story url prefix
// changes made in the function has to be made in corresponding API function as well
const generateVideoStoryUrlPrefix = () => {
	try {
		const domain = process.env.REACT_APP_SERVICE_PWA;
		const url = `${domain}/video/`;
		return url;
	} catch (err) {
		console.log('Video story url prefix generate error: ', err.message);
	}
};

// generate story url slug
const generateStoryUrlSlug = (title) => {
	try {
		const slug = stringToSlug(title);
		return slug;
	} catch (err) {
		console.log('Story url slug generate error:', err.message);
	}
};

// this function generates slug from title
const stringToSlug = (str) => {
	let mstr = str.replace(/^\s+|\s+$/g, ''); // trim
	mstr = mstr.toLowerCase();
	const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
	const to = 'aaaaeeeeiiiioooouuuunc------';
	for (let i = 0, l = from.length; i < l; i += 1) {
		mstr = mstr.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
	}
	mstr = mstr.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
		.replace(/\s+/g, '-') // collapse whitespace and replace by -
		.replace(/-+/g, '-'); // collapse dashes
	mstr = mstr.slice(0, 50);
	// if (mstr !== '') {
	//		 mstr = `${mstr}-${Math.random().toString(36).substr(2, 10)}`;
	// }
	return mstr;
};

// get video story slug
const getVideoStorySlug = async (title, brand) => {
	let slug;
	if (!['en_us', 'en_gb'].includes(brand.locale.toLowerCase())) {
		try {
			const url = `${EDITOR_API_BASE_URL}/translate`;
			const formData = new FormData();
			formData.append('text', title);
			const options = {
				method: 'POST',
				credentials: 'include',
				body: formData,
			};
			const response = await fetch(url, options);
			const translation = await response.json();
			slug = generateStoryUrlSlug(translation);
		} catch (err) {
			console.log('-----Google Translate Error:', err);
		}
	} else {
		slug = generateStoryUrlSlug(title);
	}
	return slug;
};

const checkIsUserEditor = (user, brand) => {
	const brandRoles = user.roles.brand;
	if (brandRoles[brand.slug] >= 4) {
		return true;
	}
	return false;
};

const checkIsUserStoryAuthor = (user, storyCreatedBy) => {
	if (user.id === storyCreatedBy) {
		return true;
	}
	return false;
};

const getTimeToRead = (plainText) => {
	const length = plainText.match(/\S+/g) ? plainText.match(/\S+/g).length : 0;
	const timeToRead = Math.ceil(length / 200);
	return timeToRead;
};

const validateEditorFormData = (editorFormData, formMetadata, isContentDuplicate, selectedBrand, isUserEditor, webinar, newStatus, addedToTopPicks, boniComposer = {}) => {
	try {
		const forRichTypeStoryCheck = editorFormData.storyType === 'RICH' && formMetadata.category && selectedBrand.slug === 'enterprise-story' && CATEGORY_SLUG_ARRAY.includes(formMetadata.category.slug);
		const isCompulsory = ['enterprise-story', 'the-decrypting-story', 'yourstory'].includes(selectedBrand.slug) && !['GLOSSARY'].includes(editorFormData.storyType);
		let brandSectionError = false;
		if (selectedBrand.slug === 'brands-of-new-india' && boniComposer.brandSection) {
			boniComposer.brandSection.map((section) => {
				if (!section.question || section.question.length < 1 || !section.answer || section.answer.length < 1 || !section.banner || section.banner.length < 1) {
					brandSectionError = true;
					return true;
				}
				return false;
			});
		}
		return {
			title: (!editorFormData.title || editorFormData.title.length < 1),
			brand: !selectedBrand,
			category: ['RICH', 'WEB_STORY'].includes(editorFormData.storyType) && selectedBrand.slug !== 'brands-of-new-india' ? selectedBrand.internal ? !formMetadata.category : (isUserEditor ? !formMetadata.category : false) : false,
			storyUrlSlug: (!formMetadata.storyUrlSlug || formMetadata.storyUrlSlug.length < 1 || formMetadata.storyUrlSlug.toString().match(/[A-Z!@#$%^&*(),.?":{}|/<>_';`~\[\]\\+=\ ]/g)) || false,
			canonicalUrl: isContentDuplicate ? (!formMetadata.canonicalUrl || formMetadata.canonicalUrl.length < 1) : false,
			author: selectedBrand.internal ? (!formMetadata.author || formMetadata.author.length < 1) : (isUserEditor ? (!formMetadata.author || formMetadata.author.length < 1) : false),
			facebookTitle: (!formMetadata.facebookTitle || formMetadata.facebookTitle.length < 1),
			facebookExcerpt: (!formMetadata.facebookExcerpt || formMetadata.facebookExcerpt.length < 1),
			facebookFeaturedImage: !['GLOSSARY'].includes(editorFormData.storyType) && !formMetadata.facebookFeaturedImage,
			// plagiarismCheck: !editorFormData.plagiarismCheck,
			twitterTitle: (!formMetadata.twitterTitle || formMetadata.twitterTitle.length < 1),
			twitterExcerpt: (!formMetadata.twitterExcerpt || formMetadata.twitterExcerpt.length < 1),
			twitterFeaturedImage: !['GLOSSARY'].includes(editorFormData.storyType) && !formMetadata.twitterFeaturedImage,
			searchTitle: (!formMetadata.searchTitle || formMetadata.searchTitle.length < 1),
			searchExcerpt: (!formMetadata.searchExcerpt || formMetadata.searchExcerpt.length < 1),
			searchFeaturedImage: !['GLOSSARY'].includes(editorFormData.storyType) && !formMetadata.searchFeaturedImage,
			thumbnail: !['GLOSSARY'].includes(editorFormData.storyType) && (!formMetadata.thumbnail || formMetadata.thumbnail.length < 1),
			// thumbnailSquare: !['GLOSSARY'].includes(editorFormData.storyType) && (!formMetadata.thumbnailSquare || formMetadata.thumbnailSquare.length < 1),
			// thumbnailDouble: !['GLOSSARY'].includes(editorFormData.storyType) && (!formMetadata.thumbnailDouble || formMetadata.thumbnailDouble.length < 1),
			// thumbnailVideo: !['GLOSSARY'].includes(editorFormData.storyType) && (!formMetadata.thumbnailVideo || formMetadata.thumbnailVideo.length < 1),
			portraitThumbnail: ['VIDEO', 'WEBINAR'].includes(editorFormData.storyType) || forRichTypeStoryCheck ? (!formMetadata.portraitThumbnail || formMetadata.portraitThumbnail.length < 1) : false,
			speakers: ['WEBINAR'].includes(editorFormData.storyType) ? !(formMetadata.speakers && formMetadata.speakers.length) : false,
			featuredVideo: editorFormData.storyType === 'VIDEO' ? (!editorFormData.mediaId || editorFormData.mediaId.length < 1) : false,
			articleType: !['WEB_STORY', 'GLOSSARY'].includes(editorFormData.storyType) && selectedBrand.internal && selectedBrand.slug !== 'brands-of-new-india' ? editorFormData.articleType === null || editorFormData.articleType.value === null : false,
			sectors: !['WEB_STORY', 'GLOSSARY'].includes(editorFormData.storyType) && selectedBrand.internal && selectedBrand.slug !== 'brands-of-new-india' ? formMetadata.sectors.length < 1 : false,
			marketingPost: !['WEB_STORY', 'GLOSSARY'].includes(editorFormData.storyType) && selectedBrand.internal ? formMetadata.marketingPost.length < 1 : false,
			marketingComments: !['WEB_STORY', 'GLOSSARY'].includes(editorFormData.storyType) && selectedBrand.internal ? formMetadata.marketingComments.length < 1 : false,
			startTime: editorFormData.storyType === 'WEBINAR' ? !(webinar && webinar.startTime) : false,
			endTime: editorFormData.storyType === 'WEBINAR' ? !(webinar && webinar.endTime) : false,
			curatedCategory: ['VIDEO', 'WEBINAR'].includes(editorFormData.storyType) ? !(formMetadata && formMetadata.curatedCategory) : false,
			timeDiff: editorFormData.storyType === 'WEBINAR' ? (webinar && webinar.startTime && webinar.endTime) ? (webinar.startTime >= webinar.endTime) : false : false,
			scheduled: editorFormData.publishedAt && new Date(editorFormData.publishedAt) > new Date() && newStatus === 'PUBLISHED',
			protected: editorFormData.protected ? (isNaN(parseInt(formMetadata.paywallLimit)) || parseInt(formMetadata.paywallLimit) < 1 || parseInt(formMetadata.paywallLimit) > editorFormData.totalParagraphCount) : false,
			articleTopics: ['VIDEO', 'WEBINAR'].includes(editorFormData.storyType) && selectedBrand.slug !== 'brands-of-new-india' ? formMetadata.articleTopics.length < 1 : false,
			editorsPickTitle: addedToTopPicks && (!formMetadata.editorsPickTitle || formMetadata.editorsPickTitle.length < 1),
			editorsPickSubtitle: addedToTopPicks && (!formMetadata.editorsPickSubtitle || formMetadata.editorsPickSubtitle.length < 1),
			editorsPickStartTime: addedToTopPicks && (!formMetadata.editorsPickStartTime || formMetadata.editorsPickStartTime.length < 1 || isNaN(new Date(formMetadata.editorsPickStartTime)) || moment(formMetadata.editorsPickStartTime).format('YYYY-MM-DD') === '1970-01-01'),
			editorsPickEndTime: addedToTopPicks && (!formMetadata.editorsPickEndTime || formMetadata.editorsPickStartTime.length < 1 || isNaN(new Date(formMetadata.editorsPickStartTime)) || moment(formMetadata.editorsPickEndTime).format('YYYY-MM-DD') === '1970-01-01'),
			editorsPickParentUrl: addedToTopPicks && (formMetadata.editorsPickParentUrl && !isUrlValid(formMetadata.editorsPickParentUrl)),
			richArticleTopic: ['RICH'].includes(editorFormData.storyType) && selectedBrand.slug !== 'the-captable' && selectedBrand.slug !== 'brands-of-new-india' && selectedBrand.internal ? formMetadata.richArticleTopic === null || formMetadata.richArticleTopic.value === null : false,
			// paywallSubtype: editorFormData.protected && (!formMetadata.paywallSubtype || formMetadata.paywallSubtype === '0') && formMetadata.paywallType !== 'login',
			tags: isCompulsory && formMetadata.tags.length === 0,
			storyFormat: selectedBrand.slug === 'the-captable' && (!formMetadata.storyFormat || formMetadata.storyFormat === '0'),
			subtitle: isCompulsory && (!editorFormData.subtitle || editorFormData.subtitle.length < 1),
			companyBrand: !!(editorFormData.storyType === 'RICH' && selectedBrand.internal && selectedBrand.slug === 'brands-of-new-india' && !formMetadata.companyBrand),
			insideLookTitle: boniComposer && boniComposer.addInsideLook && (!boniComposer.insideLookTitle || boniComposer.insideLookTitle.length < 1),
			insideLookDesc: boniComposer && boniComposer.addInsideLook && (!boniComposer.insideLookDesc || boniComposer.insideLookDesc === '<p><br></p>'),
			offerHeading: boniComposer && boniComposer.addOffer && (!boniComposer.offerHeading || boniComposer.offerHeading.length < 1),
			offerSubHeading: boniComposer && boniComposer.addOffer && (!boniComposer.offerSubHeading || boniComposer.offerSubHeading.length < 1),
			offerCta: boniComposer && boniComposer.addOffer && (!boniComposer.offerCta || boniComposer.offerCta.length < 1),
			productTitle: boniComposer && boniComposer.addProduct && (!boniComposer.productTitle || boniComposer.productTitle.length < 1),
			productQuestion: boniComposer && boniComposer.addProduct && (!boniComposer.productQuestion || boniComposer.productQuestion.length < 1),
			productAnswer: boniComposer && boniComposer.addProduct && (!boniComposer.productAnswer || boniComposer.productAnswer.length < 1),
			products: boniComposer && boniComposer.addProduct && (!boniComposer.products || boniComposer.products.length < 2 || !(boniComposer.products.length % 2 === 0)),
			brandSection: boniComposer && boniComposer.brandSection && (!boniComposer.brandSection || brandSectionError),
			prelude: boniComposer && boniComposer.prelude && boniComposer.prelude === '<p><br></p>',
			contentType: !['WEB_STORY'].includes(editorFormData.storyType) && selectedBrand.internal && !(formMetadata.contentType && formMetadata.contentType.value),
			storySchemaType: selectedBrand.internal && !(formMetadata.storySchemaType && formMetadata.storySchemaType.length > 0),
			translatedFromUrl: formMetadata.translatedStory && (!formMetadata.translatedFromUrl || formMetadata.translatedFromUrl.length < 1 || (formMetadata.translatedFromUrl && !isUrlValid(formMetadata.translatedFromUrl))),
			storyShortHeading: !['GLOSSARY'].includes(editorFormData.storyType) && (!formMetadata.storyShortHeading || formMetadata.storyShortHeading.length < 1),
			'schemaData.HowTo.StepsText': (selectedBrand.internal && formMetadata.storySchemaType === 'how_to_story'
				&& !(formMetadata['schemaData.HowTo.StepsText'] && formMetadata['schemaData.HowTo.StepsText'].length > 0)) || false,
			webStoryUrl: (['WEB_STORY'].includes(editorFormData.storyType) && (!formMetadata.webStoryUrl || formMetadata.webStoryUrl.length < 1)) || false,
			// parentGlossary: ['GLOSSARY'].includes(editorFormData.storyType) ? !(formMetadata.parentGlossary) : false,
		};
	} catch (err) {
		console.log('Validation function error: ', err);
	}
};

const getEditorErrors = (touched, editorFormData, formMetadata, selectedBrand, isContentDuplicate, isUserEditor, webinar, newStatus, addedToTopPicks, boniComposer = {}) => {
	const validationErrors = validateEditorFormData(editorFormData, formMetadata, isContentDuplicate, selectedBrand, isUserEditor, webinar, newStatus, addedToTopPicks, boniComposer);
	const errors = Object.keys(validationErrors).reduce((acc, curr) => {
		if (['facebookTitle', 'facebookExcerpt', 'facebookFeaturedImage'].includes(curr)) {
			if (validationErrors[curr] && touched.facebook) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
		} else if (['twitterTitle', 'twitterExcerpt', 'twitterFeaturedImage'].includes(curr)) {
			if (validationErrors[curr] && touched.twitter) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
		} else if (['searchTitle', 'searchExcerpt', 'searchFeaturedImage'].includes(curr)) {
			if (validationErrors[curr] && touched.search) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
		} else if (validationErrors[curr] && touched[curr]) {
			acc[curr] = true;
		} else {
			acc[curr] = false;
		}
		if (touched.startTime && touched.endTime && validationErrors.timeDiff) {
			acc.timeDiff = true;
		}
		return acc;
	}, {});
	return errors;
};

const getSeoAnalysisRequestBody = (editorFormData, focusKeyphrase, formMetadata) => {
	const formData = new FormData();
	formData.append('storyTitle', editorFormData.title);
	formData.append('storySlug', editorFormData.slug);
	formData.append('storyUrl', formMetadata.storyUrl);
	formData.append('storyText', formMetadata.plainText);
	formData.append('focusKeyphrase', focusKeyphrase);
	formData.append('facebookMetaDescription', formMetadata.facebookExcerpt);
	formData.append('twitterMetaDescription', formMetadata.twitterExcerpt);
	formData.append('searchMetaDescription', formMetadata.searchExcerpt);
	return formData;
};

const getStoryUpdateRequestBody = (editorFormData, metadata, selectedBrand, status, webinarInfo, registrationForm, snippetsData, boniComposer, editStory) => {
	const formMetadata = { ...metadata };
	const formData = new FormData();
	const glossaryMetaData = { ...metadata };
	formData.append('storyId', editorFormData.storyId);
	formData.append('title', editorFormData.title);
	formData.append('subtitle', editorFormData.subtitle);
	formData.append('slug', editorFormData.slug);
	formData.append('type', editorFormData.storyType);
	formData.append('status', status);
	formData.append('postContent', selectedBrand.slug === 'brands-of-new-india' ? JSON.stringify(boniComposer || {}) : getPostContent(editorFormData.postContent, formMetadata, editStory, editorFormData));
	formData.append('postJson', selectedBrand.slug === 'brands-of-new-india' ? JSON.stringify(boniComposer || {}) : JSON.stringify(editorFormData.postJson));
	formData.append('layout', getStoryLayout(editorFormData.storyType, selectedBrand));
	formData.append('brand', selectedBrand.id);
	formData.append('editor_version', EDITOR_VERSION);
	formData.append('mediaId', editorFormData.mediaId);
	formData.append('seriesId', editorFormData.series ? editorFormData.series.id : null);
	formData.append('videoPlatform', editorFormData.videoPlatform);
	formData.append('articleType', editorFormData.articleType ? editorFormData.articleType.value : null);
	formData.append('protected', !!editorFormData.protected);
	formData.append('storyShortHeading', editorFormData.storyShortHeading);

	if (editorFormData.publishedAt) {
		formData.append('publishedAt', editorFormData.publishedAt);
	} else if (status === 'PUBLISHED') {
		formData.append('publishedAt', new Date().toISOString());
	}

	if (status === 'NEEDSEDIT') {
		formData.append('reworkComment', editorFormData.editorComment);
	}
	if (status === 'REJECTED') {
		formData.append('rejectedComment', editorFormData.editorComment);
	}
	if (status === 'TRASHED') {
		formData.append('removalComment', editorFormData.editorComment);
	}

	const embedsFlag = checkEmbeds(editorFormData);
	formMetadata.hasTwitterEmbed = embedsFlag.twitter;
	formMetadata.hasInstagramEmbed = embedsFlag.instagram;
	formMetadata.hasSoundCloudEmbed = embedsFlag.soundcloud;

	if (editorFormData.storyType === 'WEBINAR') {
		const webinar = getWebinarRequestBody(webinarInfo);
		const webinarReg = getRegistrationRequestBody(registrationForm, webinarInfo && webinarInfo.id);
		formData.append('webinar', JSON.stringify(webinar));
		formData.append('webinarRegistration', webinarReg ? JSON.stringify(webinarReg) : null);
	}

	formMetadata.curatedTags = [];
	if (formMetadata.tag1) {
		formMetadata.curatedTags.push(formMetadata.tag1.id);
	}
	if (formMetadata.tag2) {
		formMetadata.curatedTags.push(formMetadata.tag2.id);
	}
	if (snippetsData) {
		const nonEmpty = snippetsData.snippets && snippetsData.snippets.length ? snippetsData.snippets.filter((item) => item.videoId && item.videoPlatform) : null;
		const trailer = snippetsData.trailer && snippetsData.trailer.length ? snippetsData.trailer.filter((item) => item.videoId && item.videoPlatform) : null;
		formData.append('snippets', JSON.stringify({ snippets: nonEmpty, trailer }));
	}

	const mainObj = {
		thumbnailVideo: {
			slug: formMetadata?.thumbnailVideo?.slug ? formMetadata.thumbnailVideo.slug : '',
		},
		thumbnailDouble: {
			slug: formMetadata?.thumbnailDouble?.slug ? formMetadata.thumbnailDouble.slug : '',
		},
		thumbnailSquare: {
			slug: formMetadata?.thumbnailSquare?.slug ? formMetadata.thumbnailSquare.slug : '',
		},
	};

	formMetadata.thumbnailVideo = mainObj.thumbnailVideo;
	formMetadata.thumbnailDouble = mainObj.thumbnailDouble;
	formMetadata.thumbnailSquare = mainObj.thumbnailSquare;

	if (editorFormData.series && editorFormData.series.id) {
		// metadata supports multiple series hence array
		formMetadata.series = [editorFormData.series.id];
	} else {
		formMetadata.series = null;
	}
	if (formMetadata.speakers && formMetadata.speakers.length && editorFormData.storyType !== 'GLOSSARY') {
		formMetadata.speakers = formMetadata.speakers.map((item) => item.id);
	}
	if (formMetadata.moderators && formMetadata.moderators.length) {
		formMetadata.moderators = formMetadata.moderators.map((item) => item.id);
	}
	if (formMetadata.brandSpotlightSponsors && formMetadata.brandSpotlightSponsors.length) {
		formMetadata.brandSpotlightSponsors = formMetadata.brandSpotlightSponsors.map((item) => item.id);
	}
	if (editorFormData.totalParagraphCount < 1) {
		formMetadata.paywallLimit = null;
	}
	if (formMetadata['schemaData.HowTo.StepsText'] && formMetadata['schemaData.HowTo.StepsText']?.length > 0) {
		formMetadata['schemaData.HowTo.StepsText'] = formMetadata['schemaData.HowTo.StepsText'].trim();
	}
	// delete unused properties from meta data section for glossary stories
	if (editorFormData.storyType === 'GLOSSARY') {
		delete glossaryMetaData.paywallType;
		delete glossaryMetaData.paywallLimit;
		delete glossaryMetaData.paywallSubtype;
		delete glossaryMetaData.storyFormat;
		delete glossaryMetaData.canonicalUrl;
		delete glossaryMetaData.category;
		delete glossaryMetaData.tag1;
		delete glossaryMetaData.tag2;
		delete glossaryMetaData.videoDuration;
		delete glossaryMetaData.videoThumbnail;
		glossaryMetaData.parentGlossary = glossaryMetaData?.parentGlossary?.id?.toString() || glossaryMetaData?.parentGlossary?.toString();
		formData.append('metadata', JSON.stringify(glossaryMetaData));
	} else {
		formData.append('metadata', JSON.stringify(formMetadata));
	}
	return formData;
};

const getPostContent = (postContent, formMetadata, editStory, editorFormData) => {
	if (postContent.toString().includes('quill-content')) {
		return postContent.replaceAll('<p><br></p>', "<p style='margin: 30px 0px;'></p>").toString();
	}
	return JSON.stringify(`<div class="quill-content" style="position: relative;">${sanitizeData(postContent, formMetadata, editStory, editorFormData)}</div>`);
};

const getStoryLayout = (storyType, selectedBrand) => {
	const brandSlug = selectedBrand.slug;
	if (storyType === 'RICH') {
		if (['yourstory', 'herstory', 'socialstory', 'germany', 'deutschland'].includes(brandSlug)) {
			return 'DEFAULT';
		}
		if (['journal'].includes(brandSlug)) {
			return 'PREMIUM';
		}
		if (['mystory', 'smbstory', 'asamiya', 'bangla', 'gujarati', 'hindi', 'kannada', 'malayalam', 'marathi', 'odia', 'punjabi', 'tamil', 'telugu', 'urdu', 'korea'].includes(brandSlug)) {
			return 'BASIC';
		}
		if (['weekender'].includes(brandSlug)) {
			return 'DEFAULT-BANNER';
		}
	}
	if (storyType === 'VIDEO') {
		return 'VIDEO-SIMPLE';
	}
	if (storyType === 'WEBINAR') {
		return 'WEBINAR-DEFAULT';
	}
};

const sanitizeData = (htmlData, formMetadata, editStory) => {
	const $ = cheerio.load(htmlData);
	const ysGlufWrrapperStyle = `
		position: sticky;
    	margin: 0px !important;
	`;
	$('.tweet').each((i, ele) => {
		const blockquote = ele.attribs['data-html'];
		$(ele.firstChild.firstChild).replaceWith($(blockquote));
	});
	$('.inline_link').each((i, ele) => {
		$(ele).attr('data-tagposition', (i + 1));
		$(ele).attr('data-position', (i + 1));
		$(ele).attr('data-clicktext', ele.children[0].data);
		$(ele).attr('data-sectiontype', 'article body');
		$(ele).attr('data-emailid', editStory['user.primary_email'] || '');
		$(ele).addClass('body_main_link');
	});
	$('.ysGulfPressQueryParagraph').each((i, ele) => {
		$(ele).attr('style', ysGlufWrrapperStyle);
	});
	$('.company-widget').each((i, ele) => {
		$(ele).addClass('body_main_company');
	});
	// key take ways data
	$('.key_take_away_container').each((i, ele) => {
		$(ele).attr('data-clicktext', '');
		$(ele).attr('data-clickurl', '');
		$(ele).attr('data-pageurl', formMetadata?.storyUrl || '');
		$(ele).attr('data-sectiontype', 'Key Takeaways');
		$(ele).attr('data-emailid', editStory['user.primary_email'] || '');
	});
	// inline links inside key take aways container
	$('.key_take_away_container .inline_link').each((i, ele) => {
		$(ele).attr('data-sectiontype', 'Key Takeaways');
		$(ele).addClass('key_takeaway_link');
		$(ele).removeClass('body_main_link');
	});
	// company widget inside key take aways container
	$('.key_take_away_container .company-widget').each((i, ele) => {
		$(ele).addClass('key_takeaway_company');
		$(ele).removeClass('body_main_company');
	});
	// also read section
	// $('.alsoReadTitleDescr').each((i, ele) => {
	// 	$(ele).attr('data-position', (i + 1));
	// 	$(ele).attr('data-sectiontype', 'also read');
	// 	$(ele).attr('data-emailid', editStory['user.primary_email'] || '');
	// });
	$('.alsoread').each((i, ele) => {
		$(ele).attr('data-position', (i + 1));
		$(ele).attr('data-sectiontype', 'also read');
		$(ele).attr('data-emailid', editStory['user.primary_email'] || '');
	});
	$('.anchorStyle').each((i, ele) => {
		$(ele).attr('data-position', (i + 1));
		$(ele).attr('data-sectiontype', 'also read');
		$(ele).attr('data-emailid', editStory['user.primary_email'] || '');
	});
	$('.thumbnailWrapper').each((i, ele) => {
		$(ele).attr('data-position', (i + 1));
		$(ele).attr('data-sectiontype', 'also read');
		$(ele).attr('data-emailid', editStory['user.primary_email'] || '');
	});
	$('.alsoReadHeadText').each((i, ele) => {
		$(ele).attr('data-position', (i + 1));
		$(ele).attr('data-sectiontype', 'also read');
		$(ele).attr('data-emailid', editStory['user.primary_email'] || '');
	});
	$('.alsoReadMainTitleText').each((i, ele) => {
		$(ele).attr('data-position', (i + 1));
		$(ele).attr('data-sectiontype', 'also read');
		$(ele).attr('data-emailid', editStory['user.primary_email'] || '');
	});
	$('.alsoReadTitleImage').each((i, ele) => {
		$(ele).attr('data-position', (i + 1));
		$(ele).attr('data-sectiontype', 'also read');
		$(ele).attr('data-emailid', editStory['user.primary_email'] || '');
	});
	// to add a ids for scrolling feature
	// if (editorFormData.storyType === 'GLOSSARY') {
	// 	$('.keyTakeAWaysContainer ul li').each((i, ele) => {
	// 		$(ele).wrapInner(`<a class="scroll glossary-story-scroll" href="#${$(ele).text().toLocaleLowerCase().replaceAll(' ', '-')}"></a>`);
	// 	});
	// 	$('.keyTakeAWaysContainer ol li').each((i, ele) => {
	// 		$(ele).wrapInner(`<a class="scroll glossary-story-scroll" href="#${$(ele).text().toLocaleLowerCase().replaceAll(' ', '-')}"></a>`);
	// 	});
	// 	$('ul li').each((i, ele) => {
	// 		if ($(ele).has('a')?.length <= 0) {
	// 			$(ele).attr('id', `${$(ele).text().toLocaleLowerCase().replaceAll(' ', '-')}`);
	// 			$(ele).attr('class', 'glossary-story-list-item');
	// 		}
	// 	});
	// 	$('ol li').each((i, ele) => {
	// 		if ($(ele).has('a')?.length <= 0) {
	// 			$(ele).attr('id', `${$(ele).text().toLocaleLowerCase().replaceAll(' ', '-')}`);
	// 			$(ele).attr('class', 'glossary-story-list-item');
	// 		}
	// 	});
	// 	$('h2').each((i, ele) => {
	// 		$(ele).attr('id', `${$(ele).text().toLocaleLowerCase().replaceAll(' ', '-')}`);
	// 	});
	// }
	// alsoReadHeadText alsoReadMainTitleText alsoReadTitleImage
	return $('body').html().toString().replaceAll('<p><br></p>', "<p style='margin: 30px 0px;'></p>")
		.toString();
};

// const addClickeTextDataForLinkBlot = (htmlData) => {
// 	const $ = cheerio.load(htmlData);
// 	$('.inline_link').each((i, ele) => {
// 		$(ele).attr('data-tagposition', (i + 1));
// 		$(ele).attr('data-clicktext', ele.children[0].data);
// 	});
// 	return $('body').html().toString();
// };

const checkEmbeds = (editorFormData) => {
	const { ops } = editorFormData.postJson;
	let twitter = false;
	let instagram = false;
	let soundcloud = false;
	if (ops) {
		ops.forEach((op) => {
			if (op.insert.tweet) {
				twitter = true;
			}
			if (op.insert.insta) {
				instagram = true;
			}
			if (op.insert.soundcloud) {
				soundcloud = true;
			}
		});
	}
	return { twitter, instagram, soundcloud };
};

// Pick only the passed keys, and update accordingly
const formatDataForState = (state, value) => {
	let stateCopy = clone(state, true);
	if (!stateCopy) {
		stateCopy = {};
	}
	if (value) {
		stateCopy = addData(stateCopy, value);
		return stateCopy;
	}
	return stateCopy;
};

const addData = (stateCopy, valueCopy) => merge(stateCopy, valueCopy);

function getWebinarRequestBody(basicInfo) {
	if (basicInfo.startTime && basicInfo.endTime) {
		const body = {
			id: basicInfo.id,
			startTime: basicInfo.startTime,
			startTime_local: moment(basicInfo.startTime).tz('Asia/Calcutta').format(),
			endTime: basicInfo.endTime,
			disclaimer: null,
			disclaimerPostJson: null,
			webinarEmbed: null,
			liveStreamConfig: null,
			endedWebinar: basicInfo.status === 'ENDED',
		};
		if (basicInfo.disclaimer && basicInfo.disclaimer.replace(/<(.|\n)*?>/g, '').trim().length) {
			body.disclaimer = basicInfo.disclaimer ? getDisclaimerPostContent(basicInfo.disclaimer) : '';
			body.disclaimerPostJson = JSON.stringify(basicInfo.disclaimerPostJson);
		}
		if (basicInfo.webinarEmbed && basicInfo.webinarEmbed.length) {
			body.webinarEmbed = JSON.stringify(basicInfo.webinarEmbed);
		}
		if (basicInfo.liveStreamConfig) {
			body.liveStreamConfig = JSON.stringify(basicInfo.liveStreamConfig);
		}
		return body;
	}
	return null;
}

const getDisclaimerPostContent = (postContent) => {
	if (postContent.toString().includes('quill-content')) {
		return JSON.stringify(`${postContent}`);
	}
	return JSON.stringify(`<div class="quill-content" style="position: relative;">${postContent}</div>`);
};

function getRegistrationRequestBody(registrationForm, webinarId) {
	const modified = webinarId ? registrationForm.filter((item) => item.changed) : registrationForm.filter((item) => item.changed || item.selected);
	if (modified && modified.length) return modified;
	return null;
}

const validateWebinarTab = (editorData, basicInfo) => {
	try {
		return ({
			title: !(editorData && editorData.title),
			startTime: !(basicInfo && basicInfo.startTime),
			endTime: !(basicInfo && basicInfo.endTime),
			timeDiff: basicInfo.startTime >= basicInfo.endTime,
		});
	} catch (err) {
		console.log('Webinar validation function error: ', err);
	}
};

const isStorySlugUnique = async (slug, storyId) => {
	try {
		if (slug) {
			const url = `${STORY_LIST_BASE_API_URL}/slug/${slug}?storyId=${storyId}`;
			const response = await fetch(url, {
				method: 'GET',
				credentials: 'include',
			});
			const slugResponse = await response.json();
			if (slugResponse && slugResponse.slug === slug) {
				return false;
			}
			return true;
		}
		return true;
	} catch (err) {
		console.log('slug unique check error', err);
	}
};

function isUrlValid(userInput) {
	const res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
	return !!res;
}

export {
	getEditorFormData,
	isLocaleEnglish,
	generateRichStoryUrlPrefix,
	generateStoryUrlSlug,
	generateVideoStoryUrlPrefix,
	getVideoStorySlug,
	checkIsUserEditor,
	checkIsUserStoryAuthor,
	getTimeToRead,
	getSeoAnalysisRequestBody,
	getEditorErrors,
	validateEditorFormData,
	getStoryUpdateRequestBody,
	formatDataForState,
	validateWebinarTab,
	isStorySlugUnique,
	isUrlValid,
};
