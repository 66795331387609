import { Map } from 'immutable';
import _ from 'lodash';
import {
	LOAD_ALIASES, LOAD_ALIASES_ERROR, LOAD_ALIASES_SUCCESS, LOAD_FILTERED_ALIASES, LOAD_FILTERED_ALIASES_ERROR, LOAD_FILTERED_ALIASES_SUCCESS, PROFILE_SELECT, UPDATE_PROFILE, SAVE_ALIAS, SAVE_ALIAS_SUCCESS, SAVE_ALIAS_ERROR, DISCARD_CHANGES, CHANGE_DROPDOWN, CLEAR_UPDATE_ALIAS_DATA, UNMOUNT_ALIAS_FORM_MESSAGE,
} from './type';
import { ALIAS_UPDATE_SAVE_MESSAGE, ALIAS_UPDATE_SUCCESS_MESSAGE, ALIAS_FETCH_ERROR } from './constants';

const initialState = Map({
	dropDown: {
		value: 'NAME',
		searchValue: '',
	},
	data: {
		loading: true,
		aliasList: [],
		pageNo: 1,
		scrollLoading: false,
		endOfList: false,
		saving: false,
	},
	editedData: {
		selectedAliasIndex: null,
		selectedAliasObject: null,
	},
	showForm: true,
	formMessage: null,
});

function updateAliasReducer(state = initialState, action) {
	switch (action.type) {
	case LOAD_ALIASES: {
		const data = { ...state.get('data') };
		data.loading = true;
		return state
			.set('data', data)
			.set('formMessage', null);
	}

	case LOAD_ALIASES_SUCCESS: {
		const data = { ...state.get('data') };
		data.aliasList = action.aliases.aliases;
		data.loading = false;
		if (action.aliases.aliases.length < 20) {
			data.endOfList = true;
		} else {
			data.endOfList = false;
		}
		return state
			.set('data', data)
			.set('formMessage', null);
	}

	case LOAD_ALIASES_ERROR: {
		const data = { ...state.get('data') };
		data.loading = false;
		data.endOfList = true;

		return state
			.set('data', data)
			.set('formMessage', ALIAS_FETCH_ERROR);
	}

	case LOAD_FILTERED_ALIASES: {
		const data = { ...state.get('data') };
		data.loading = true;
		data.scrollLoading = false;
		return state
			.set('data', data)
			.set('formMessage', null);
	}

	case LOAD_FILTERED_ALIASES_SUCCESS: {
		const data = { ...state.get('data') };
		data.aliasList = data.aliasList.concat(action.aliases.aliases);
		data.loading = false;
		data.scrollLoading = false;
		if (action.aliases.aliases.length < 20) {
			data.endOfList = true;
		} else {
			data.endOfList = false;
		}
		return state
			.set('data', data)
			.set('formMessage', null);
	}

	case LOAD_FILTERED_ALIASES_ERROR: {
		const data = { ...state.get('data') };
		data.loading = false;
		data.endOfList = true;
		data.scrollLoading = false;

		const error = { ...state.get('error') };
		error.status = true;
		error.type = 'RESPONSE';
		error.message = 'Failed to fetch aliases !!!';
		return state
			.set('data', data)
			.set('formMessage', null);
	}

	case PROFILE_SELECT: {
		const data = { ...state.get('data') };
		const editedData = { ...state.get('editedData') };
		editedData.selectedAliasIndex = action.index;
		editedData.selectedAliasObject = _.cloneDeep(data.aliasList[action.index]);

		return state
			.set('editedData', editedData)
			.set('showForm', true)
			.set('formMessage', null);
	}

	case UPDATE_PROFILE: {
		const editedData = { ...state.get('editedData') };
		editedData.selectedAliasObject[action.variableName] = action.value;
		return state
			.set('editedData', editedData);
	}

	case SAVE_ALIAS: {
		const data = { ...state.get('data') };
		data.saving = true;

		return state
			.set('data', data)
			.set('formMessage', ALIAS_UPDATE_SAVE_MESSAGE);
	}

	case SAVE_ALIAS_SUCCESS: {
		const editedData = { ...state.get('editedData') };
		const data = { ...state.get('data') };
		data.loading = false;
		data.saving = false;
		data.aliasList[editedData.selectedAliasIndex] = _.cloneDeep(editedData.selectedAliasObject);

		return state
			.set('data', data)
			.set('showForm', false)
			.set('formMessage', ALIAS_UPDATE_SUCCESS_MESSAGE);
	}

	case SAVE_ALIAS_ERROR: {
		const data = { ...state.get('data') };
		data.loading = false;
		data.endOfList = true;
		data.saving = false;

		const message = action.message ? action.message : 'Failed to save alias !!!';
		return state
			.set('data', data)
			.set('formMessage', { intent: 'danger', message });
	}

	case CHANGE_DROPDOWN: {
		const dropDown = { ...state.get('dropDown') };
		dropDown[action.variableName] = action.value;
		return state
			.set('dropDown', dropDown);
	}

	case DISCARD_CHANGES: {
		const data = { ...state.get('data') };
		const editedData = { ...state.get('editedData') };
		editedData.selectedAliasObject = _.cloneDeep(data.aliasList[editedData.selectedAliasIndex]);
		return state
			.set('editedData', editedData);
	}

	case CLEAR_UPDATE_ALIAS_DATA:
		return initialState;

	case UNMOUNT_ALIAS_FORM_MESSAGE:
		return state
			.set('formMessage', null);

	default:
		return state;
	}
}

export default updateAliasReducer;
