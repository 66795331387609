/* eslint-disable no-useless-escape */
import React, {
	useState, useEffect, useReducer, useRef,
} from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import { Button, Input } from '../Styles';
import ToastMessage from '../ToastMessage';
import {
	PEOPLE_API_BASE_URL, PERSON_CREATE_SUCCESS_MESSAGE, PERSON_CREATE_LOAD_MESSAGE, PERSON_ERROR_MESSAGE,
} from '../../containers/PeopleManager/constants';

import { validateImage, validateImageFormat } from '../../utils/common';
import ImageHolder from './ImageHolder';

const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
const UPDATE_ERRORS = 'UPDATE_ERRORS';
const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
const CLEAR_DATA = 'CLEAR_DATA';

const initialState = {
	formData: {
		name: null,
		bio: null,
		subtitle: null,
		profilePicture: null,
		socialLinks: {
			facebook: null,
			twitter: null,
			linkedin: null,
			wikipedia: null,
		},
	},
	blurredElements: {
		name: null,
		subtitle: null,
		profilePicture: null,
	},
	errors: {
		name: null,
		subtitle: null,
		profilePicture: null,
	},
	formMessage: null,
};

function updateFormData(changedData) {
	return {
		type: UPDATE_FORM_DATA,
		changedData,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function setFormMessage(message) {
	return {
		type: SET_FORM_MESSAGE,
		message,
	};
}

function clearData() {
	return {
		type: CLEAR_DATA,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case UPDATE_FORM_DATA: {
		const formData = { ...state.formData };
		const { changedData } = action;
		if (['facebook', 'twitter', 'linkedin', 'wikipedia'].includes(changedData.name)) {
			if (!formData.socialLinks) formData.socialLinks = {};
			formData.socialLinks[changedData.name] = changedData.value;
		} else {
			formData[changedData.name] = changedData.value;
		}
		return { ...state, formData };
	}

	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };

	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			name: true,
			subtitle: true,
			profilePicture: true,
		};
		return { ...state, blurredElements };
	}

	case SET_FORM_MESSAGE:
		return { ...state, formMessage: action.message };

	case CLEAR_DATA: {
		const newState = {
			...initialState,
			formData: {
				...initialState.formData,
				socialLinks: {
					facebook: null,
					twitter: null,
					linkedin: null,
					wikipedia: null,
				},
			},
		};
		return newState;
	}

	default:
		return state;
	}
}

function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

const InfluencerForm = (props) => {
	const {
		open, close, addedInfluencer, hoc, storyType,
	} = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		formData, blurredElements, errors, formMessage,
	} = state;
	const [isButtonDisabled, disableButton] = useState(false);
	const prevIsButtonDisabled = usePrevious(isButtonDisabled);
	const profilePicture = ['WEBINAR', 'VIDEO'].includes(storyType) ? 'Profile Picture *' : 'Profile Picture';

	useEffect(() => {
		getErrors();
	}, [formData, blurredElements]);

	useEffect(() => {
		if (!prevIsButtonDisabled && isButtonDisabled) {
			createInfluencer();
		}
	}, [isButtonDisabled]);

	function onInputChange(e) {
		const changedData = {
			name: e.target.name,
			value: e.target.value,
		};
		return dispatch(updateFormData(changedData));
	}

	function validateForm(influencer) {
		return {
			name: !influencer.name || (influencer.name && influencer.name.length < 1),
			subtitle: hoc !== 'company-editor' ? !influencer.subtitle || (influencer.subtitle && (influencer.subtitle.length < 1 || influencer.subtitle.length > 1000)) : false,
			profilePicture: ['WEBINAR', 'VIDEO'].includes(storyType) && !influencer.profilePicture,
		};
	}

	function handleBlur(element) {
		dispatch(updateBlurredElement(element));
	}

	function getErrors() {
		const validationErrors = validateForm(formData);
		const errors = Object.keys(validationErrors).reduce((acc, curr) => {
			if (validationErrors[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	}

	async function onFormSubmit(e) {
		e.preventDefault();
		const validation = validateForm(formData);
		const validated = !Object.keys(validation).some((i) => validation[i]);
		if (validated) {
			disableButton(true);
		} else {
			dispatch(setAllElementsBlurred());
		}
	}

	async function createInfluencer() {
		try {
			dispatch(setFormMessage(PERSON_CREATE_LOAD_MESSAGE));
			const payload = getRequestBody();
			const URL = `${PEOPLE_API_BASE_URL}/create?source=${hoc}`;
			const options = {
				method: 'POST',
				credentials: 'include',
				body: payload,
			};
			const response = await fetch(URL, options);
			const parsed = await response.json();
			disableButton(false);
			if (parsed.success) {
				const influencer = {
					id: parsed.data.id,
					value: parsed.data.id,
					label: parsed.data.name,
					bio: parsed.data.bio,
					profilePicture: parsed.data.profilePicture,
					username: parsed.data.username,
				};
				dispatch(setFormMessage(PERSON_CREATE_SUCCESS_MESSAGE));
				addedInfluencer(influencer);
				closeModal();
			} else {
				dispatch(setFormMessage(PERSON_ERROR_MESSAGE));
			}
		} catch (err) {
			dispatch(setFormMessage(PERSON_ERROR_MESSAGE));
			disableButton(false);
		}
	}

	function getRequestBody() {
		const payload = new FormData();
		payload.append('name', formData.name);
		payload.append('bio', formData.bio);
		payload.append('subtitle', formData.subtitle);
		payload.append('socialLinks', JSON.stringify(formData.socialLinks));
		if (formData.profilePicture && formData.profilePicture.file) {
			payload.append('image', formData.profilePicture.file);
			payload.append('profilePicture', JSON.stringify({
				width: formData.profilePicture.width,
				height: formData.profilePicture.height,
			}));
		}
		payload.append('status', 'PUBLISHED');
		return payload;
	}

	async function onUpload(e) {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		if (await validateImageFormat(file)) {
			reader.onloadend = () => {
				dispatch(updateFormData({
					name: 'profilePicture',
					value: {
						url: reader.result,
						file,
					},
				}));
			};
			reader.readAsDataURL(file);
		}
	}

	function onUploadClick(e) {
		e.preventDefault();
		const profilePicture = document.getElementById('profilePicture');
		profilePicture.click();
	}

	function onImageLoad(e) {
		if (formData.profilePicture.file) {
			const data = { height: e.target.naturalHeight, width: e.target.naturalWidth };
			if (!validateImage(data.height, data.width)) {
				document.getElementById('profilePicture').value = '';
				dispatch(updateFormData({
					name: 'profilePicture',
					value: {
						url: null,
						file: null,
					},
				}));
			} else {
				dispatch(updateFormData({
					name: 'profilePicture',
					value: {
						...formData.profilePicture,
						...data,
					},
				}));
			}
		}
	}

	function closeModal() {
		dispatch(clearData());
		close();
	}

	function renderFormMessage() {
		if (formMessage) {
			return (
				<ToastMessage
					toastData={formMessage}
					unmount={() => dispatch(setFormMessage(null))}
				/>
			);
		}
		return null;
	}

	return (
		<Modal
			isOpen={open}
			onRequestClose={closeModal}
			contentLabel="Add Influencer"
			overlayClassName="modal-overlay"
			className="form-modal"
		>
			<div className="w_100 mb-4 text-right">
				<button type="button" onClick={closeModal}>
					<i className="fas fa-times light-text disablePointerEvents" />
				</button>
			</div>
			<div>
				<h2>Add Influencer</h2>
				{renderFormMessage()}
				<form onSubmit={onFormSubmit}>
					<Row>
						<Col xs={12} sm={12} md={7}>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Name *
								</Col>
								<Col xs={12} sm={9}>
									<Input
										name="name"
										type="text"
										className="form-control"
										placeholder="Name of the influencer"
										value={formData && formData.name ? formData.name : ''}
										onChange={onInputChange}
										onBlur={() => handleBlur('name')}
									/>
									{
										errors.name
											? <p className="error error-text text-normal">
												* Name cannot be empty
											</p>
											: null
									}
								</Col>
							</Row>
							{hoc === 'story-editor'
								? <Row className="mb-3">
									<Col xs={12} sm={3}>
										Designation *
									</Col>
									<Col xs={12} sm={9}>
										<Input
											name="subtitle"
											type="text"
											className="form-control"
											placeholder="Designation of the influencer"
											value={formData && formData.subtitle ? formData.subtitle : ''}
											onChange={onInputChange}
											onBlur={() => handleBlur('subtitle')}
										/>
										{
											errors.subtitle
												? formData && !formData.subtitle ? <p className="error error-text text-normal">
													* Designation cannot be empty
												</p> : <p className="error error-text text-normal">
													* Designation cannot be more that 1000 character
												</p>
												: null
										}
									</Col>
								</Row>
								: null}
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Bio
								</Col>
								<Col xs={12} sm={9}>
									<textarea
										rows="6"
										className="global-form-control"
										name="bio"
										placeholder="Biography of the influencer"
										value={formData && formData.bio ? formData.bio : ''}
										onChange={onInputChange}
										onBlur={() => handleBlur('bio')}
									/>
									{/* {
																				errors.bio
																						? <p className="error error-text text-normal">
																								* Bio cannot be empty
																						</p>
																						: null
																		} */}
								</Col>
							</Row>
							<p><b>Social Links:</b></p>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Facebook
								</Col>
								<Col xs={12} sm={9}>
									<Input
										name="facebook"
										type="url"
										className="form-control"
										placeholder="URL of influencer's Facebook page"
										value={formData && formData.socialLinks.facebook ? formData.socialLinks.facebook : ''}
										onChange={onInputChange}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Twitter
								</Col>
								<Col xs={12} sm={9}>
									<Input
										name="twitter"
										type="url"
										className="form-control"
										placeholder="URL of influencer's Twitter page"
										value={formData && formData.socialLinks.twitter ? formData.socialLinks.twitter : ''}
										onChange={onInputChange}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									LinkedIn
								</Col>
								<Col xs={12} sm={9}>
									<Input
										name="linkedin"
										type="url"
										className="form-control"
										placeholder="URL of influencer's LinkedIn page"
										value={formData && formData.socialLinks.linkedin ? formData.socialLinks.linkedin : ''}
										onChange={onInputChange}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col xs={12} sm={3}>
									Wikipedia
								</Col>
								<Col xs={12} sm={9}>
									<Input
										name="wikipedia"
										type="url"
										className="form-control"
										placeholder="URL of influencer's Wikipedia page"
										value={formData && formData.socialLinks.wikipedia ? formData.socialLinks.wikipedia : ''}
										onChange={onInputChange}
									/>
								</Col>
							</Row>
						</Col>
						<Col xs={12} sm={12} md={4}>
							<ImageWrapper className="mb-3">
								{profilePicture}
								<Input
									id="profilePicture"
									type="file"
									style={{ display: 'none' }}
									name="profilePicture"
									onChange={onUpload}
									accept="image/png, image/jpeg"
								/>
								<ImageHolder
									showImageUploader={onUploadClick}
									imageUrl={formData && formData.profilePicture ? formData.profilePicture.url : null}
									onImageLoad={onImageLoad}
									squareDimension="13rem"
								/>
								{
									errors.profilePicture
										? <p className="error error-text text-normal">
											* Profile Picture cannot be empty
										</p>
										: null
								}
							</ImageWrapper>
						</Col>
					</Row>
					<Col xs={12} sm={8}>
						<div className="w_100 text-right">
							<Button
								type="submit"
								success
								no_radius
								disabled={isButtonDisabled}
								style={{ width: '100px' }}
							>
								<b>SAVE</b>
							</Button>
						</div>
					</Col>
				</form>
			</div>
		</Modal>
	);
};

InfluencerForm.propTypes = {
	open: T.bool,
	close: T.func,
	addedInfluencer: T.func,
	hoc: T.string,
	storyType: T.string,
};

export default InfluencerForm;

const ImageWrapper = styled.div`
		padding-left: 2rem;
		@media (max-width: 768px) {					 
				padding-left: 0;
		}
`;
