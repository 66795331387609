import {
	FETCH_JOBS,
	FETCH_JOBS_SUCCESS,
	FETCH_JOBS_FAILURE,
	FILTER_JOBS,
	FILTER_JOBS_SUCCESS,
	FILTER_JOBS_FAILURE,
	SET_JOBS_PAGE,
	CLEAR_JOBS_DATA,
	SET_STATUS,
} from './type';

export function fetchJobs(offset, limit) {
	return {
		type: FETCH_JOBS,
		offset,
		limit,
	};
}

export function fetchJobsSuccess(jobs) {
	return {
		type: FETCH_JOBS_SUCCESS,
		jobs,
	};
}

export function fetchJobsFailure(error) {
	return {
		type: FETCH_JOBS_FAILURE,
		error,
	};
}

export function filterJobs(key, status, page) {
	return {
		type: FILTER_JOBS,
		key,
		status,
		page,

	};
}

export function filterJobsSuccess(jobs) {
	return {
		type: FILTER_JOBS_SUCCESS,
		jobs,
	};
}

export function filterJobsFailure(error) {
	return {
		type: FILTER_JOBS_FAILURE,
		error,
	};
}

export function setPage(page) {
	return {
		type: SET_JOBS_PAGE,
		page,
	};
}

export function setStatus(status) {
	return {
		type: SET_STATUS,
		status,
	};
}
export function setInitialState() {
	return {
		type: CLEAR_JOBS_DATA,
	};
}
