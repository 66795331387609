import { fromJS } from 'immutable';

import { promotionForm } from './constants';

import {
	SEND_PROMOTION, SEND_PROMOTION_SUCCESS, SEND_PROMOTION_ERROR, CHANGE_PROMOTION_PAYLOAD, UPDATE_DISPLAY_MESSAGE, LOAD_CHATBOTS, LOAD_CHATBOTS_SUCCESS, LOAD_CHATBOTS_ERROR, CLEAR_STORY_SEARCH_DATA, SET_STORY_SEARCH_TITLE, SET_STORY_SEARCH_RESULTS, SET_STORY_SEARCH_LIST_END, SET_STORY_SEARCH_OFFSET, SET_STORY_SEARCH_SELECT, SET_STORIES_LOADING, SET_STORIES_LOADED,
} from './type';

const initialState = fromJS({
	loading: false,
	sending: false,
	error: false,
	promotionForm,
	displayMessage: null,
	readText: 'Read',
	reachableAudience: '0',
	chatbots: [],
	storiesLoading: null,
	storySearchTitle: '',
	storySearchResults: null,
	storySearchOffset: null,
	storySearchSelect: null,
	storySearchListEnd: false,
});

function messengerPromotionFormReducer(state = initialState, action) {
	switch (action.type) {
	case SEND_PROMOTION:
		return state
			.set('sending', true);
	case CHANGE_PROMOTION_PAYLOAD:
		if (action.itemName === 'brand') {
			const botInfo = state.get('chatbots').filter((item) => item.id === action.itemValue);
			if (botInfo && botInfo[0] && botInfo[0].chatbotData) {
				return state
					.set('readText', botInfo[0].chatbotData.messages.generic.read)
					.set('reachableAudience', botInfo[0].reachableAudience)
					.setIn(['promotionForm', action.itemName], action.itemValue);
			}
		}
		return state
			.setIn(['promotionForm', action.itemName], action.itemValue);
	case SEND_PROMOTION_SUCCESS:
		return state
			.set('sending', false)
			.set('error', false)
			.setIn(['promotionForm', 'title'], '')
			.setIn(['promotionForm', 'subtitle'], '')
			.setIn(['promotionForm', 'cta'], '')
			.setIn(['promotionForm', 'media'], '')
			.set('displayMessage', action.response.message);
	case SEND_PROMOTION_ERROR:
		return state
			.set('sending', false)
			.set('error', action.error)
			.set('displayMessage', action.response.message);
	case UPDATE_DISPLAY_MESSAGE:
		return state
			.set('displayMessage', action.message);
	case LOAD_CHATBOTS:
		return state
			.set('loading', true)
			.set('error', false);
	case LOAD_CHATBOTS_SUCCESS: {
		const botInfo = action.result.filter((item) => item.id === state.getIn(['promotionForm', 'brand']));
		if (botInfo && botInfo[0] && botInfo[0].chatbotData) {
			return state
				.set('readText', botInfo[0].chatbotData.messages.generic.read)
				.set('reachableAudience', botInfo[0].reachableAudience)
				.set('loading', false)
				.set('error', false)
				.set('chatbots', action.result);
		}
		return state
			.set('loading', false)
			.set('error', false)
			.set('chatbots', action.result);
	}
	case LOAD_CHATBOTS_ERROR:
		return state
			.set('loading', false)
			.set('error', action.error);
	case CLEAR_STORY_SEARCH_DATA:
		return state
			.set('storySearchTitle', '')
			.set('storySearchResults', null)
			.set('storySearchSelect', null)
			.set('storySearchOffset', null)
			.set('storySearchListEnd', false)
			.set('modalLoading', null);
	case SET_STORY_SEARCH_TITLE:
		return state
			.set('storySearchTitle', action.title);
	case SET_STORY_SEARCH_RESULTS:
		return state
			.set('storySearchResults', action.storySearchResults);
	case SET_STORY_SEARCH_OFFSET:
		if (typeof action.offset === 'number') {
			return state
				.set('storySearchOffset', action.offset);
		}
		return state;
	case SET_STORY_SEARCH_LIST_END:
		return state
			.set('storySearchListEnd', action.value);
	case SET_STORY_SEARCH_SELECT:
		return state
			.set('storySearchSelect', action.position);
	case SET_STORIES_LOADING:
		return state.set('storiesLoading', true);
	case SET_STORIES_LOADED:
		return state.set('storiesLoading', false);
	default:
		return state;
	}
}

export default messengerPromotionFormReducer;
