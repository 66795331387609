// brand Id to brand
const brandIdToBrand = (id, brands) => {
	const brand = brands.reduce((acc, curr) => {
		if (curr.id === id) {
			acc = curr;
		}
		return acc;
	}, {});
	return brand;
};

// brand Slug to brand
const brandSlugToBrand = (slug, brands) => {
	const brand = brands.reduce((acc, curr) => {
		if (curr.slug === slug) {
			acc = curr;
		}
		return acc;
	});
	return brand;
};

export { brandIdToBrand, brandSlugToBrand };
