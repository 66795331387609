import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col } from '../Responsive';
import imgPlaceholder from '../../images/img-placeholder.png';

const TopicCard = (props) => (
	<CardWrapper>
		<Col xs={5} className="pl-1">
			<Thumbnail
				src={props.thumbnail ? props.thumbnail : imgPlaceholder}
				alt="thumbnail"
			/>
		</Col>
		<Col
			xs={7}
			className="pl-2"
			style={{
				display: 'flex',
				justifyContent: 'flex-start',
				alignItems: 'center',
			}}
		>
			<h3>{props.name ? props.name : ''}</h3>
		</Col>
	</CardWrapper>
);

TopicCard.propTypes = {
	thumbnail: PropTypes.string,
	name: PropTypes.string,
};

const Thumbnail = styled.img`
		width: 90px;
		height: 90px;
`;

const CardWrapper = styled(Row)`
		width: 100%;
		min-height: 100px;
		box-shadow: 2px 2px 4px 0px #CCCCCC;
		align-items: center;
`;

export default TopicCard;
