import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { ListHeaderWrapper } from '../../BroadcastManager/styles';

const ListHeader = () => (
	<ListHeaderWrapper>
		<Col xs={1} sm={1}>
			S.No
		</Col>
		<Col xs={3} sm={1}>
			Story Id
		</Col>
		<Col xs={3} sm={2}>
			Story Title
		</Col>
		<Col xs={2} sm={2}>
			Brand
		</Col>
		<Col xs={2} sm={2}>
			Date
		</Col>
		<Col xs={2} sm={1}>
			Status
		</Col>
		<Col xs={3} sm={2}>
			Actions
		</Col>
	</ListHeaderWrapper>
);

export default ListHeader;
