import {
	FETCH_EDITOR_GALLERY_MEDIA, FETCH_EDITOR_GALLERY_MEDIA_SUCCESS, FETCH_EDITOR_GALLERY_MEDIA_FAILURE, SET_EDITOR_GALLERY_PAGE_NO, SORT_EDITOR_GALLERY_MEDIA_BY, FILTER_EDITOR_GALLERY_MEDIA, FILTER_EDITOR_GALLERY_MEDIA_SUCCESS, FILTER_EDITOR_GALLERY_MEDIA_FAILURE, SET_EDITOR_GALLERY_SEARCH_VALUE, SET_EDITOR_GALLERY_UPDATED_TIME, SET_EDITOR_GALLERY_FETCH_BY, SET_EDITOR_GALLERY_MEDIA_TYPE, CLEAR_EDITOR_GALLERY_DATA, SET_EDITOR_GALLERY_FETCH_BY_VALUE,
} from './type';

export function fetchEditorGalleryMedia(fetchData, concat) {
	return {
		type: FETCH_EDITOR_GALLERY_MEDIA,
		fetchData,
		concat,
	};
}

export function fetchEditorGalleryMediaSuccess(media, concat) {
	return {
		type: FETCH_EDITOR_GALLERY_MEDIA_SUCCESS,
		media,
		concat,
	};
}

export function fetchEditorGalleryMediaFailure(error) {
	return {
		type: FETCH_EDITOR_GALLERY_MEDIA_FAILURE,
		error,
	};
}

export function setEditorGalleryPageNo(page) {
	return {
		type: SET_EDITOR_GALLERY_PAGE_NO,
		page,
	};
}

export function sortEditorGalleryMediaBy(sortBy) {
	return {
		type: SORT_EDITOR_GALLERY_MEDIA_BY,
		sortBy,
	};
}

export function filterEditorGalleryMedia(searchData, concat) {
	return {
		type: FILTER_EDITOR_GALLERY_MEDIA,
		searchData,
		concat,
	};
}

export function filterEditorGalleryMediaSuccess(media, concat) {
	return {
		type: FILTER_EDITOR_GALLERY_MEDIA_SUCCESS,
		media,
		concat,
	};
}

export function filterEditorGalleryMediaFailure(error) {
	return {
		type: FILTER_EDITOR_GALLERY_MEDIA_FAILURE,
		error,
	};
}

export function setEditorGallerySearchValue(value) {
	return {
		type: SET_EDITOR_GALLERY_SEARCH_VALUE,
		value,
	};
}

export function setEditorGalleryUpdatedTime(time) {
	return {
		type: SET_EDITOR_GALLERY_UPDATED_TIME,
		time,
	};
}

export function setEditorGalleryFetchBy(fetchBy) {
	return {
		type: SET_EDITOR_GALLERY_FETCH_BY,
		fetchBy,
	};
}

export function setEditorGalleryFetchByValue(fetchByValue) {
	return {
		type: SET_EDITOR_GALLERY_FETCH_BY_VALUE,
		fetchByValue,
	};
}

export function setEditorGalleryMediaType(mediaType) {
	return {
		type: SET_EDITOR_GALLERY_MEDIA_TYPE,
		mediaType,
	};
}

export function clearEditorGalleryData() {
	return {
		type: CLEAR_EDITOR_GALLERY_DATA,
	};
}
