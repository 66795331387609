export const LOADING_STATE = 'LOADING_STATE';
export const LOADED_STATE = 'LOADED_STATE';
export const SET_ERROR_STATE = 'SET_ERROR_STATE';
export const SET_RESPONSE_ERROR_STATE = 'SET_RESPONSE_ERROR_STATE';
export const SET_CURATED_DATA = 'SET_CURATED_DATA';
export const GET_CURATED_DATA = 'GET_CURATED_DATA';
export const SET_SELECTED_BRAND_VALUE = 'SET_SELECTED_BRAND_VALUE';
export const SET_CURATED_SET_LIST = 'SET_CURATED_SET_LIST';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SET_STORY_SEARCH_TITLE = 'SET_STORY_SEARCH_TITLE';
export const SET_STORY_SEARCH_RESULTS = 'SET_STORY_SEARCH_RESULTS';
export const SET_MODAL_LOADING = 'SET_MODAL_LOADING';
export const SET_MODAL_LOADED = 'SET_MODAL_LOADED';
export const DISCARD_CHANGES = 'DISCARD_CHANGES';
export const SET_STORY_SEARCH_OFFSET = 'SET_STORY_SEARCH_OFFSET';
export const SET_END_OF_STORY_SEARCH_LIST = 'SET_END_OF_STORY_SEARCH_LIST';
export const SET_STORY_SEARCH_SELECT = 'SET_STORY_SEARCH_SELECT';
export const SET_SAVING = 'SET_SAVING';
export const SET_SAVING_RESPONSE = 'SET_SAVING_RESPONSE';
export const CLEAR_STORY_SEARCH_DATA = 'CLEAR_STORY_SEARCH_DATA';
export const RESET_PAGE = 'RESET_PAGE';
