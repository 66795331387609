import React, {
	useEffect, useState, useReducer, Fragment,
} from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import 'rc-pagination/assets/index.css';
import Pagination from 'rc-pagination';
import T from 'prop-types';
import Modal from 'react-modal';
import {
	Input, Button,
} from '../../../components/Styles';
// import ToastMessage from '../../../components/ToastMessage';
import { TitleWithTooltip } from '../../../components/FormComponents';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import { ListItem, ListHeader } from '../../../components/AdminJobListComponents/index';
import locale from '../../WatchlistedStories/en_US';
import LoadingIcon from '../../../components/LoadingIcon/LoadingIcon';
import {
	REJECTED_BASE_URL,
	FETCH_REJECTED,
	FETCH_REJECTED_SUCCESS,
	FETCH_REJECTED_FAILURE,
	FILTER_REJECTED,
	FILTER_REJECTED_SUCCESS,
	FILTER_REJECTED_FAILURE,
	SET_REJECTED_PAGE,
	SET_ALL_ELEMENTS_BLURRED,
	UPDATE_ERRORS,
	UPDATE_BLURRED_ELEMENT,
	UPDATE_ACTIONS_ERROR,
	UPDATE_ACTIONS_SUCCESS,
	UPDATE_ACTIONS_LOADER,
	SET_ACTIONS_MESSAGE,
} from './constants';

const initialState = {
	rejectedJobs: [],
	jobsCount: null,
	loading: false,
	error: false,
	page: 1,
	actionLoading: false,
	actionError: false,
	actionMessage: '',
	blurredElements: {
		introduction: false,
		detailedBio: false,
		existingBio: false,
	},
	errors: {
		introduction: false,
		detailedBio: false,
		existingBio: false,
	},
};

function setActionsMessage(message) {
	return {
		type: SET_ACTIONS_MESSAGE,
		message,
	};
}

function updateActionsLoading() {
	return {
		type: UPDATE_ACTIONS_LOADER,
	};
}

function updateActionsFailure() {
	return {
		type: UPDATE_ACTIONS_ERROR,
	};
}

function updateActionsSuccess() {
	return {
		type: UPDATE_ACTIONS_SUCCESS,
	};
}
function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}
function fetchRejectedJobs() {
	return {
		type: FETCH_REJECTED,
	};
}

function fetchRejectedJobsSuccess(rejectedJobs) {
	return {
		type: FETCH_REJECTED_SUCCESS,
		rejectedJobs,
	};
}

function fetchRejectedJobsFailure(error) {
	return {
		type: FETCH_REJECTED_FAILURE,
		error,
	};
}

function setPage(page) {
	return {
		type: SET_REJECTED_PAGE,
		page,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case FETCH_REJECTED:
		return { ...state, loading: true };
	case FETCH_REJECTED_SUCCESS:
		return {
			...state, loading: false, rejectedJobs: action.rejectedJobs?.rows, jobsCount: action.rejectedJobs?.count,
		};
	case FETCH_REJECTED_FAILURE:
		return {
			...state, loading: false, error: true, rejectedJobs: [],
		};
	case FILTER_REJECTED:
		return { ...state, loading: true };
	case FILTER_REJECTED_SUCCESS:
		return {
			...state, loading: false, rejectedJobs: action.rejectedJobs?.rows, jobsCount: action.rejectedJobs?.count,
		};
	case FILTER_REJECTED_FAILURE:
		return {
			...state, loading: true, error: true, rejectedJobs: [],
		};
	case SET_REJECTED_PAGE:
		return { ...state, page: action.page };
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };
	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			introduction: true,
			detailedBio: true,
			existingBio: true,
		};
		return { ...state, blurredElements };
	}
	case UPDATE_ACTIONS_ERROR:
		return {
			...state, actionLoading: false, actionError: true,
		};
	case UPDATE_ACTIONS_SUCCESS:
		return {
			...state, actionLoading: false, actionError: false,
		};
	case UPDATE_ACTIONS_LOADER:
		return {
			...state, actionLoading: true, actionError: false,
		};
	case SET_ACTIONS_MESSAGE:
		return {
			...state, actionMessage: action.message,
		};
	default:
		return state;
	}
}

const RejectedJobsList = (props) => {
	const { status, history } = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		rejectedJobs, loading, jobsCount, page, errors, blurredElements, actionLoading, actionError, actionMessage,
	} = state;
	const [searchValue, setSearchValue] = useState('');
	const [showAcceptForm, setShowAcceptJobForm] = useState(false);
	const [showViewBioForm, setShowViewBioForm] = useState(false);
	const [selectedJob, setSelectedJob] = useState({});
	const [acceptJobData, setAcceptJobData] = useState({
		introduction: '',
		detailedBio: '',
		existingBio: '',
	});
	// const [filterBy, setFilterBy] = useState('select');
	// const [filterValue, setFilterValue] = useState(null);

	useEffect(() => {
		dispatch(updateErrors({
			introduction: false,
			detailedBio: false,
			existingBio: false,
		}));
		dispatch(updateBlurredElement({
			introduction: false,
			detailedBio: false,
			existingBio: false,
		}));
		dispatch(setAllElementsBlurred());
		// pageTitle('rejectedJobs');
		getRejectedJobs((page - 1) * 10, 10);
	}, []);

	async function getRejectedJobs(offset, limit, searchVal = searchValue) {
		dispatch(fetchRejectedJobs());
		const url = `${REJECTED_BASE_URL}?status=${status}&offset=${offset}&limit=${limit}&search=${searchVal}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				const rejectedJobs = await response.json();
				dispatch(fetchRejectedJobsSuccess(rejectedJobs));
			} else {
				dispatch(fetchRejectedJobsFailure());
			}
		} catch (err) {
			dispatch(fetchRejectedJobsFailure(err));
		}
	}

	// async function filterRejectedJobs(key, newPage) {
	// 	if (key) {
	// 		key = key;
	// 		if (key.length < 1) {
	// 			return;
	// 		}
	// 	}
	// 	dispatch(filterRejectedJob());
	// 	const url = `${REJECTED_BASE_URL}/key/${key || null}`;
	// 	const formData = new FormData();
	// 	formData.append('page', newPage);
	// 	// formData.append('filterBy', filterBy);
	// 	// formData.append('filterValue', filterValue);
	// 	const options = {
	// 		method: 'POST',
	// 		body: formData,
	// 		credentials: 'include',
	// 	};
	// 	try {
	// 		const response = await fetch(url, options);
	// 		if (response.status === 401 || response.status === 403) {
	// 			history.push('/unauthorized');
	// 		} else {
	// 			const rejectedJobs = await response.json();
	// 			dispatch(filterRejectedJobsSuccess(rejectedJobs));
	// 		}
	// 	} catch (err) {
	// 		dispatch(filterRejectedJobsFailure(err));
	// 	}
	// }

	function handleSearch(e) {
		e.preventDefault();
		const { value } = e.target;
		getRejectedJobs(0, 10, value);
		dispatch(setPage(1));
	}

	// function handleFilterValueChange(e) {
	// 	const { value } = e.target;
	// 	setFilterValue(value);
	// 	if (value && filterBy !== 'select') {
	// 		filterrejectedJobs(searchValue, filterBy, value, 1);
	// 	} else {
	// 		filterrejectedJobs(searchValue, 'select', null, 1);
	// 	}
	// 	dispatch(setPage(1));
	// }

	// function handleFilterByChange(e) {
	// 	const { value } = e.target;
	// 	setFilterBy(value);
	// 	if (value === 'featured' || value === 'partnership') {
	// 		const newFilterValue = ![false, 'false'].includes(filterValue);
	// 		setFilterValue(newFilterValue);
	// 		filterrejectedJobs(searchValue, value, newFilterValue, 1);
	// 	} else if (value === 'startTime' || value === 'endTime') {
	// 		const newFilterValue = filterValue || 'ASC';
	// 		setFilterValue(newFilterValue);
	// 		filterrejectedJobs(searchValue, value, newFilterValue, 1);
	// 	} else {
	// 		getrejectedJobs(1, 10);
	// 	}
	// 	dispatch(setPage(1));
	// }

	function onPageChange(page) {
		getRejectedJobs((page - 1) * 10, 10);
		dispatch(setPage(page));
	}

	function closeModal() {
		setAcceptJobData({});
		setSelectedJob({});
		dispatch(setPage(1));
		dispatch(updateErrors({
			introduction: false,
			detailedBio: false,
			existingBio: false,
		}));
		dispatch(updateBlurredElement({
			introduction: false,
			detailedBio: false,
			existingBio: false,
		}));
		dispatch(setAllElementsBlurred());
		setShowAcceptJobForm(false);
		setShowViewBioForm(false);
	}
	const saveAcceptJobApproval = async () => {
		dispatch(updateActionsLoading());
		const url = `${REJECTED_BASE_URL}/status`;
		const formData = {
			profileId: selectedJob.id,
			status: 2,
			detailedBio: acceptJobData.detailedBio,
			headLine: acceptJobData.introduction,
			rejectReason: '',
		};
		const options = {
			method: 'PATCH',
			credentials: 'include',
			body: JSON.stringify(formData),
			headers: {
				'Content-Type': 'application/json',
			},
		};
		try {
			// if ((acceptJobData.detailedBio.length > 250) || (acceptJobData.introduction.length > 250)) {
			// 	dispatch(updateActionsSuccess());
			// 	dispatch(setActionsMessage(''));
			// 	return alert('The respective fields cannot have a length of more than 250 characters.');
			// }
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				getRejectedJobs((page - 1) * 10, 10);
				dispatch(updateActionsSuccess());
				if (showAcceptForm) {
					dispatch(setActionsMessage('The accept approval for the job is successfully done.'));
				}
				setTimeout(() => {
					closeModal();
					dispatch(setActionsMessage(''));
				}, 2000);
			} else {
				dispatch(updateActionsFailure());
				if (showAcceptForm) {
					dispatch(setActionsMessage('Not Able To Update The Reject Reason'));
				}
				setTimeout(() => {
					dispatch(updateActionsSuccess());
					dispatch(setActionsMessage(''));
					closeModal();
				}, 2000);
			}
		} catch (err) {
			dispatch(updateActionsFailure());
			if (showAcceptForm) {
				dispatch(setActionsMessage('Not Able To Update The Job'));
			}
			setTimeout(() => {
				dispatch(updateActionsSuccess());
				closeModal();
			}, 2000);
		}
	};
	function validateForm() {
		return {
			introduction: !(acceptJobData && acceptJobData.introduction && acceptJobData.introduction?.length > 0),
			detailedBio: !(acceptJobData.detailedBio && acceptJobData.detailedBio?.length > 0),
			existingBio: !(acceptJobData.existingBio && acceptJobData.existingBio?.length > 0),
		};
	}
	const handleErrors = () => {
		const validation = validateForm();
		const errors = Object.keys(validation).reduce((acc, curr) => {
			if (validation[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		const validation = validateForm();
		const validated = !Object.keys(validation).some((i) => validation[i]);
		handleErrors();
		if (validated) {
			saveAcceptJobApproval();
		} else {
			dispatch(setAllElementsBlurred());
		}
	};

	function handleBlur(element) {
		handleErrors();
		dispatch(updateBlurredElement(element));
	}

	function getRejectedJobsList() {
		return rejectedJobs.map((job, index) => <ListItem
			key={job.id}
			history={history}
			slNo={(page - 1) * 10 + index + 1}
			job={job}
			acceptJob={() => {
				setShowAcceptJobForm(true);
				setSelectedJob(job);
				setAcceptJobData(({
					introduction: job.headLine,
					detailedBio: job.detailedBio,
					existingBio: job.shortBio,
				}));
			}}
			viewBio={() => {
				setShowViewBioForm(true);
				setSelectedJob(job);
			}}
			index={index}
			selectedStatus={status}
		/>);
	}

	const viewBioOfTheJob = () => <Modal
		isOpen={showViewBioForm}
		onRequestClose={closeModal}
		shouldCloseOnOverlayClick={false}
		overlayClassName="modal-overlay"
		className="form-modal"
	>
		<Row>
			<Col md={10} xs={10}>
				<h2 className="w-50 text-left">Bio Of The Job</h2>
			</Col>
			<Col md={2} xs={2}>
				<div className="w-50 text-right">
					<button type="button" onClick={closeModal}>
						<i className="fas fa-times light-text disablePointerEvents" />
					</button>
				</div>
			</Col>
		</Row>
		<div>
			<form>
				<Row className="m-2">
					<Col xs={12} sm={12}>
						<div className="pb-3">
							<TitleWithTooltip
								title="Existing Bio *"
								hint="Existing Bio"
							/>
							{/* <Input
								type="text"
								readOnly
								className="form-control"
								value={selectedJob.shortBio}
								name="bioName"
								autoComplete="off"
								maxLength="100"
							/> */}
							<textarea
								rows="3"
								readOnly
								className="global-form-control"
								name="bioName"
								placeholder="Existing Bio Of The Job"
								value={selectedJob.shortBio}
							/>
							<div style={{ textAlign: 'right', color: selectedJob.shortBio && selectedJob.shortBio.length > 250 ? '#aaa' : '#aaa' }}>
								{selectedJob && selectedJob.shortBio ? selectedJob.shortBio.length : 0}
							</div>
						</div>
					</Col>
					<Col xs={12} sm={12}>
						<div className="pb-3 pt-3">
							<TitleWithTooltip
								title="Introduction *"
								hint="This is the introduction of the job."
							/>
							<textarea
								rows="3"
								readOnly
								className="global-form-control"
								name="introduction"
								placeholder="Description/Summary Of The Job"
								value={selectedJob.headLine}
								maxLength={50}
							/>
							<div style={{ textAlign: 'right', color: selectedJob.headLine && selectedJob.headLine.length > 50 ? '#ff0000' : '#aaa' }}>
								{selectedJob && selectedJob.headLine ? selectedJob.headLine.length : 0}/50
							</div>
						</div>
					</Col>
					<Col xs={12} sm={12}>
						<div className="pb-3 pt-3">
							<TitleWithTooltip
								title="Detailed Bio *"
								hint="This is the Detailed Bio of the job."
							/>
							<textarea
								rows="3"
								readOnly
								className="global-form-control"
								name="detailedBio"
								placeholder="Detailed Bio of the job"
								value={selectedJob.detailedBio}
							/>
							<div style={{ textAlign: 'right', color: selectedJob.detailedBio && selectedJob.detailedBio.length > 250 ? '#aaa' : '#aaa' }}>
								{selectedJob && selectedJob.detailedBio ? selectedJob.detailedBio.length : 0}/250
							</div>
						</div>
					</Col>
				</Row>
			</form>
		</div>
	</Modal>;

	const acceptJobForApprove = () => <Modal
		isOpen={showAcceptForm}
		onRequestClose={closeModal}
		shouldCloseOnOverlayClick={false}
		overlayClassName="modal-overlay"
		className="form-modal"
	>
		<Row>
			<Col md={10} xs={10}>
				<h2 className="w-50 text-left">Accept the job profile</h2>
			</Col>
			<Col md={2} xs={2}>
				<div className="w-50 text-right">
					<button type="button" onClick={closeModal}>
						<i className="fas fa-times light-text disablePointerEvents" />
					</button>
				</div>
			</Col>
		</Row>
		<div>
			<form onSubmit={handleSubmit}>
				<Row className="m-2">
					<Col xs={12} sm={12}>
						<div className="pb-3">
							<TitleWithTooltip
								title="Existing Bio *"
								hint="Existing Bio"
							/>
							<textarea
								rows="3"
								readOnly
								className="global-form-control"
								name="bioName"
								placeholder="Existing Bio Of The Job"
								value={selectedJob.shortBio}
								maxLength={250}
							/>
							<ErrorMessage display={errors.existingBio} element="Bio Name" />
						</div>
					</Col>
					<Col xs={12} sm={12}>
						<div className="pb-3 pt-3">
							<TitleWithTooltip
								title="Introduction *"
								hint="This is the introduction of the job."
							/>
							<p className="m-0 longText">
								<textarea
									rows="3"
									className="global-form-control"
									name="introduction"
									placeholder="Description/Summary Of The Job"
									value={acceptJobData.introduction}
									maxLength={250}
									onChange={(e) => {
										setAcceptJobData(({
											...acceptJobData,
											introduction: e.target.value,
										}));
									}}
									onBlur={() => handleBlur('introduction')}
								/>
							</p>
							<div style={{ textAlign: 'right', color: acceptJobData.introduction && acceptJobData.introduction.length > 250 ? '#ff0000' : '#aaa' }}>
								{acceptJobData && acceptJobData.introduction ? acceptJobData.introduction.length : 0}/250
							</div>
							<ErrorMessage display={errors.introduction} element="Introduction" />
						</div>
					</Col>
					<Col xs={12} sm={12}>
						<div className="pb-3 pt-3">
							<TitleWithTooltip
								title="Detailed Bio *"
								hint="This is the Detailed Bio of the job."
							/>
							<p className="m-0 longText">
								<textarea
									rows="3"
									className="global-form-control"
									name="detailedBio"
									placeholder="Detailed Bio of the job"
									value={acceptJobData.detailedBio}
									maxLength={250}
									onChange={(e) => {
										setAcceptJobData(({
											...acceptJobData,
											detailedBio: e.target.value,
										}));
									}}
									onBlur={() => handleBlur('detailedBio')}
								/>
							</p>
							<div style={{ textAlign: 'right', color: acceptJobData.detailedBio && acceptJobData.detailedBio.length > 250 ? '#ff0000' : '#aaa' }}>
								{acceptJobData && acceptJobData.detailedBio ? acceptJobData.detailedBio.length : 0}/250
							</div>
							<ErrorMessage display={errors.detailedBio} element="Detailed Bio" />
						</div>
					</Col>
					<Col xs={12} sm={12} className="mt-2 text-center">
						<Button
							success
							no_radius
							type="submit"
							style={{ width: '100px' }}
						>
							<b>APPROVE</b>
						</Button>
					</Col>
					<Col xs={12} sm={12}>
						{actionLoading && <LoadingIcon />}
					</Col>
					<Col xs={12} sm={12}>
						{(actionError && actionMessage) && <p className="error error-text text-normal text-center mb-2">
							{actionMessage}
						</p>}
						{(!actionError && actionMessage) && <p className="success-text text-normal text-center mb-2">
							{actionMessage}
						</p>}
					</Col>
				</Row>
			</form>
		</div>
	</Modal>;

	function renderList() {
		if (rejectedJobs && rejectedJobs.length > 0) {
			return <div>
				<ListHeader selectedStatus={status} />
				{getRejectedJobsList()}
			</div>;
		}

		return (
			<Row style={{
				width: '100%', minHeight: '400px', justifyContent: 'center', alignItems: 'center',
			}}
			>
				<div>
					<h1 style={{ color: '#bbb' }}>
						No Rejected Jobs To Display
					</h1>
				</div>
			</Row>
		);
	}

	return (
		<div>
			<Row className="">
				<Col sm={4} xs={12} className="mb-2">
					<p>Rejected Jobs to be displayed on Admin Job pages</p>
				</Col>
				<Col sm={8} xs={12}>
					<Row style={{ justifyContent: 'flex-end' }} className="mr-1 mb-2">
						<Col sm={4} xs={12} className="mb-2">
							<Input
								id="search-box"
								type="text"
								className="form-control"
								placeholder="Search By Email/Name/PhoneNumber"
								value={searchValue}
								onChange={(e) => {
									handleSearch(e);
									setSearchValue(e.target.value);
								}}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
			{
				loading
					? <LoadingIcon />
					: <>
						{renderList()}
						{rejectedJobs && rejectedJobs.length > 0
							? <div>
								<Row className="mt-3 pr-4" style={{ justifyContent: 'flex-end' }}>
									<Pagination onChange={onPageChange} current={page} total={jobsCount} locale={locale} />
								</Row>
							</div>
							: null}
					</>
			}
			{viewBioOfTheJob()}
			{acceptJobForApprove()}
		</div>
	);
};

RejectedJobsList.propTypes = {
	status: T.number,
	history: T.object,
};
export default RejectedJobsList;
