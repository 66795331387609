import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '../Styles';

const AlertModal = (props) => (
	<Modal>
		<div className="modal-float">
			<div className="modal-wrapper">
				<div className="modal-content">
					<div className="text-center">
						<p dangerouslySetInnerHTML={{ __html: props.comment }} />
					</div>
					<div className="text-right">
						<Button
							primary
							className="m-1"
							onClick={() => props.confirm(true)}
						>
							Confirm
						</Button>
						<Button
							light
							className="m-1"
							onClick={() => props.confirm(false)}
						>
							Cancel
						</Button>
					</div>
				</div>
			</div>
		</div>
	</Modal>
);

AlertModal.propTypes = {
	comment: PropTypes.string,
	confirm: PropTypes.func,
};

export default AlertModal;
