export const LOAD_PROFILES = 'LOAD_PROFILES';
export const LOAD_PROFILES_ERROR = 'LOAD_PROFILES_ERROR';
export const LOAD_PROFILES_SUCCESS = 'LOAD_PROFILES_SUCCESS';

export const LOAD_MORE_PROFILES = 'LOAD_MORE_PROFILES';
export const LOAD_MORE_PROFILES_ERROR = 'LOAD_MORE_PROFILES_ERROR';
export const LOAD_MORE_PROFILES_SUCCESS = 'LOAD_MORE_PROFILES_SUCCESS';

export const LOAD_FILTERED_PROFILES = 'LOAD_FILTERED_PROFILES';
export const LOAD_FILTERED_PROFILES_ERROR = 'LOAD_FILTERED_PROFILES_ERROR';
export const LOAD_FILTERED_PROFILES_SUCCESS = 'LOAD_FILTERED_PROFILES_SUCCESS';

export const SAVE_PROFILE = 'SAVE_PROFILE';
export const SAVE_PROFILE_ERROR = 'SAVE_PROFILE_ERROR';
export const SAVE_PROFILE_SUCCESS = 'SAVE_PROFILE_SUCCESS';

export const SELECT_BRAND = 'SELECT_BRAND';
export const SELECT_PROFILE = 'SELECT_PROFILE';
export const UPDATE_CHANGES = 'UPDATE_CHANGES';
export const DISCARD_PROFILE = 'DISCARD_PROFILE';

export const CLEAR_USER_MANAGER_DATA = 'CLEAR_USER_MANAGER_DATA';
