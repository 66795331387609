export const ON_COMPANY_TAB_SELECT = 'ON_COMPANY_TAB_SELECT';
export const ON_BASIC_INFO_HEADER_TAB_SELECT = 'ON_BASIC_INFO_HEADER_TAB_SELECT';

export const UPDATE_COMPANY_FORM_DATA = 'UPDATE_COMPANY_FORM_DATA';
export const UPDATE_COMPANY_METADATA_FORM_DATA = 'UPDATE_COMPANY_METADATA_FORM_DATA';
export const UPDATE_BASIC_INFO_INPUT_BLUR = 'UPDATE_BASIC_INFO_INPUT_BLUR';
export const UPDATE_BASIC_INFO_ERRORS = 'UPDATE_BASIC_INFO_ERRORS';
export const UPDATE_FORM_MESSAGE = 'UPDATE_FORM_MESSAGE';
export const SET_ALL_BASIC_INFO_ELEMENTS_BLUR = 'SET_ALL_BASIC_INFO_ELEMENTS_BLUR';

export const CREATE_COMPANY = 'CREATE_COMPANY';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAILURE = 'CREATE_COMPANY_FAILURE';

export const UPDATE_BASIC_INFO = 'UPDATE_BASIC_INFO';
export const UPDATE_BASIC_INFO_SUCCESS = 'UPDATE_BASIC_INFO_SUCCESS';
export const UPDATE_BASIC_INFO_FAILURE = 'UPDATE_BASIC_INFO_FAILURE';

export const TRASH_COMPANY = 'TRASH_COMPANY';
export const TRASH_COMPANY_SUCCESS = 'TRASH_COMPANY_SUCCESS';
export const TRASH_COMPANY_FAILURE = 'TRASH_COMPANY_FAILURE';

export const FETCH_COMPANY_EDITOR_DATA = 'FETCH_COMPANY_EDITOR_DATA';

export const FETCH_BASIC_INFO = 'FETCH_BASIC_INFO';
export const FETCH_BASIC_INFO_SUCCESS = 'FETCH_BASIC_INFO_SUCCESS';
export const FETCH_BASIC_INFO_FAILURE = 'FETCH_BASIC_INFO_FAILURE';

export const FETCH_ACQUISITIONS = 'FETCH_ACQUISITIONS';
export const FETCH_ACQUISITIONS_SUCCESS = 'FETCH_ACQUISITIONS_SUCCESS';
export const FETCH_ACQUISITIONS_FAILURE = 'FETCH_ACQUISITIONS_FAILURE';

export const FETCH_COMPANY_FINANCE = 'FETCH_COMPANY_FINANCE';
export const FETCH_COMPANY_FINANCE_SUCCESS = 'FETCH_COMPANY_FINANCE_SUCCESS';
export const FETCH_COMPANY_FINANCE_FAILURE = 'FETCH_COMPANY_FINANCE_FAILURE';

export const FETCH_COMPANY_PEOPLE = 'FETCH_COMPANY_PEOPLE';
export const FETCH_COMPANY_PEOPLE_SUCCESS = 'FETCH_COMPANY_PEOPLE_SUCCESS';
export const FETCH_COMPANY_PEOPLE_FAILURE = 'FETCH_COMPANY_PEOPLE_FAILURE';

export const FETCH_FUNDING = 'FETCH_FUNDING';
export const FETCH_FUNDING_SUCCESS = 'FETCH_FUNDING_SUCCESS';
export const FETCH_FUNDING_FAILURE = 'FETCH_FUNDING_FAILURE';

export const FETCH_COMPANY_PRODUCTS = 'FETCH_COMPANY_PRODUCTS';
export const FETCH_COMPANY_PRODUCTS_SUCCESS = 'FETCH_COMPANY_PRODUCTS_SUCCESS';
export const FETCH_COMPANY_PRODUCTS_FAILURE = 'FETCH_COMPANY_PRODUCTS_FAILURE';

export const FETCH_COMPANY_LOCATIONS = 'FETCH_COMPANY_LOCATIONS';
export const FETCH_COMPANY_LOCATIONS_SUCCESS = 'FETCH_COMPANY_LOCATIONS_SUCCESS';
export const FETCH_COMPANY_LOCATIONS_FAILURE = 'FETCH_COMPANY_LOCATIONS_FAILURE';

export const CLEAR_COMPANY_EDITOR_DATA = 'CLEAR_COMPANY_EDITOR_DATA';

export const ADD_COMPANY_AWARD = 'ADD_COMPANY_AWARD';
export const REMOVE_COMPANY_AWARD = 'REMOVE_COMPANY_AWARD';

export const FETCH_AUDIENCE = 'FETCH_AUDIENCE';
export const FETCH_AUDIENCE_SUCCESS = 'FETCH_AUDIENCE_SUCCESS';
export const FETCH_AUDIENCE_FAILURE = 'FETCH_AUDIENCE_FAILURE';

export const FETCH_MILESTONES = 'FETCH_MILESTONES';
export const FETCH_MILESTONES_SUCCESS = 'FETCH_MILESTONES_SUCCESS';
export const FETCH_MILESTONES_FAILURE = 'FETCH_MILESTONES_FAILURE';

export const FETCH_CURATED_LIST_TYPES = 'FETCH_CURATED_LIST_TYPES';
export const FETCH_CURATED_LIST_TYPES_SUCCESS = 'FETCH_CURATED_LIST_TYPES_SUCCESS';
export const FETCH_CURATED_LIST_TYPES_ERROR = 'FETCH_CURATED_LIST_TYPES_ERROR';

export const FETCH_PROFILE_UPDATE_NOTES = 'FETCH_PROFILE_UPDATE_NOTES';
export const FETCH_PROFILE_UPDATE_NOTES_SUCCESS = 'FETCH_PROFILE_UPDATE_NOTES_SUCCESS';
export const FETCH_PROFILE_UPDATE_NOTES_FAILURE = 'FETCH_PROFILE_UPDATE_NOTES_FAILURE';

export const SET_TOP_PICKS = 'SET_TOP_PICKS';
export const CLEAR_TOP_PICKS = 'CLEAR_TOP_PICKS';
