import React, { useReducer, useEffect, useState } from 'react';
import uuid from 'react-uuid';
import Modal from 'react-modal';
import { Row, Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import moment from 'moment';
import { DropDown, Input, Button } from '../Styles';
import ToastMessage from '../ToastMessage';
import {
	CURATED_SET_CONFIG_URL, SAVING_FAILURE_MESSAGE, SAVING_MESSAGE, SAVING_SUCCESS_MESSAGE,
} from '../../containers/TechSparksManager/constants';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import TechSparksAgendSpeakersCard from '../FormComponents/TechSparksAgendSpeakers';
import { extractedCityName } from '../../utils/common';

const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
const UPDATE_ERRORS = 'UPDATE_ERRORS';
const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
const SET_FORM_DATA = 'SET_FORM_DATA';
const UPDATE_SPEAKER_FORM_DATA = 'UPDATE_SPEAKER_FORM_DATA';
	<option value={'7|Stage'.toUpperCase()}>Stage</option>;
const tracksOptions = [
	{ name: 'Stage', value: '7|Stage' },
	{ name: 'Stage 1', value: '11|Stage 1' },
	{ name: 'Stage 2', value: '21|Stage 2' },
	{ name: "Stage 3: India's Cleantech Opportunity", value: "12|Stage 3: India's Cleantech Opportunity" },
	{ name: 'Stage 2: Pioneers of Frontier Tech', value: '13|Stage 2: Pioneers of Frontier Tech' },
	{ name: 'Stage 2: D2C Playbook', value: '14|Stage 2: D2C Playbook' },
	{ name: 'Stage 1: AI Unravelled', value: '15|Stage 1: AI Unravelled' },
	{ name: 'Stage 3: Growth hacks for Scale', value: '16|Stage 3: Growth hacks for Scale' },
	{ name: "Stage 2: India's Electric Dream", value: "17|Stage 2: India's Electric Dream" },
	{ name: 'Stage 3: Building Category Leadership', value: '18|Stage 3: Building Category Leadership' },
	{ name: 'Stage 2: The Blockchain Outlook', value: '19|Stage 2: The Blockchain Outlook' },
	{ name: 'Stage 2: Creators.Inc', value: '20|Stage2: Creators.Inc' },
	{ name: "Stage 1: India's Fintech Future", value: "22|Stage 1: India's Fintech Future" },

];
const initialStateFormData = {
	id: null,
	title: '',
	description: '',
	order: '',
	day: 'DAY1',
	tag: '',
	time: '',
	speakerName: '',
	speakerDesignation: '',
	speakers: '',
	track: '1|Main Stage - A'.toUpperCase(),
	index: '',
};

const initialState = {
	formData: {
		id: null,
		title: '',
		description: '',
		order: '',
		day: 'DAY1',
		tag: '',
		time: '',
		speakerName: '',
		speakerDesignation: '',
		speakers: '',
		track: '1|Main Stage - A'.toUpperCase(),
		index: '',
	},
	blurredElements: {
		title: false,
		description: false,
		order: false,
		tag: false,
		speakerName: false,
		speakerDesignation: false,
	},
	errors: {
		title: false,
		description: false,
		order: false,
		tag: false,
		speakerName: false,
		speakerDesignation: false,
	},
	formMessage: null,
};

function updateFormData(changedData) {
	return {
		type: UPDATE_FORM_DATA,
		changedData,
	};
}

function updateSpeakerFormData(changedData) {
	return {
		type: UPDATE_SPEAKER_FORM_DATA,
		changedData,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function setFormData(agenda) {
	return {
		type: SET_FORM_DATA,
		agenda,
	};
}

function setFormMessage(message) {
	return {
		type: SET_FORM_MESSAGE,
		message,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case SET_FORM_DATA:
		return {
			...state,
			formData: {
				...action.agenda,
				speakers: action?.agenda?.speakers && JSON.parse(action?.agenda?.speakers)?.length > 0 ? JSON.parse(action?.agenda?.speakers) : [],
				track: action?.agenda?.track && action?.agenda?.track?.length > 0 ? action?.agenda?.track : '1|Main Stage - A'.toUpperCase(),
				time: action?.agenda?.time && action?.agenda?.time?.length > 0 ? moment(action?.agenda?.time, 'hh:mm A').format('HH:mm') : '',
			},
		};

	case UPDATE_FORM_DATA: {
		const formData = { ...state.formData };
		formData[action.changedData.name] = action.changedData.value;
		return { ...state, formData };
	}

	case UPDATE_SPEAKER_FORM_DATA: {
		return {
			...state,
			formData: {
				...state.formData,
				...action.changedData,
			},
		};
	}

	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };

	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			title: true,
			description: true,
			order: true,
			day: true,
			tag: true,
			speakerName: true,
			speakerDesignation: true,
		};
		return { ...state, blurredElements };
	}

	case SET_FORM_MESSAGE:
		return { ...state, formMessage: action.message };

	default:
		return state;
	}
}

const AgendaForm = (props) => {
	const {
		agenda, getAgendas, unmountModal, isOpen, city, agendasLength,
	} = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		formData, blurredElements, errors, formMessage,
	} = state;
	const [isButtonDisabled, disableButton] = useState(false);
	const [toUpdate, setToUpdate] = useState(false);
	const [showEditOrAddNewSpeakerForm, setEditOrAddNewSpeakerForm] = useState(false);

	useEffect(() => {
		if (agenda && agenda.id) {
			dispatch(setFormData(agenda));
			setToUpdate(true);
			dispatch(setFormMessage(null));
		} else {
			dispatch(setFormData({ ...initialStateFormData }));
			setToUpdate(false);
			dispatch(setFormMessage(null));
			dispatch(updateErrors({
				title: false,
				description: false,
				order: false,
				tag: false,
				speakerName: false,
				speakerDesignation: false,
			}));
			const changedData = {
				name: 'order',
				value: agendasLength,
			};
			dispatch(updateFormData(changedData));
		}
	}, [isOpen]);

	useEffect(() => {
		if (agenda && agenda.id) {
			getErrors();
		}
	}, [formData, blurredElements]);

	function onInputChange(e) {
		const changedData = {
			name: e.target.name,
			value: e.target.value,
		};
		if (e.target.name === 'day') {
			const changedTrackData = {
				name: 'track',
				value: '1|Main Stage - A'.toUpperCase(),
			};
			dispatch(updateFormData(changedTrackData));
		}
		dispatch(updateFormData(changedData));
	}

	function validateForm(agenda) {
		if (showEditOrAddNewSpeakerForm) {
			return {
				speakerName: !agenda.speakerName || agenda.speakerName.length < 1,
				// speakerDesignation: !agenda.speakerDesignation || agenda.speakerDesignation.length < 1,
			};
		}
		return {
			title: !agenda.title || agenda.title.length < 1,
			description: !agenda.description || agenda.description.length < 1,
			tag: !agenda.tag || agenda.tag.length < 1,
			day: !agenda.day || agenda.day.length < 1,
			track: !agenda.track || agenda.track.length < 1,
			order: !agenda.order || agenda.order.length < 1,
		};
	}

	function getErrors() {
		const validationErrors = validateForm(formData);
		const errors = Object.keys(validationErrors).reduce((acc, curr) => {
			if (validationErrors[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	}

	function onFormSubmit(e) {
		e.preventDefault();
		const validation = validateForm(formData);
		const validated = !Object.keys(validation).some((i) => validation[i]);
		getErrors();
		if (validated) {
			saveAgenda();
		} else {
			dispatch(setAllElementsBlurred());
		}
	}

	function handleBlur(element) {
		dispatch(updateBlurredElement(element));
	}

	function getAgendaBody(agenda) {
		const formData = new FormData();
		if (toUpdate) {
			formData.append('id', agenda.id);
		} else {
			const id = uuid();
			formData.append('id', id);
		}
		formData.append('title', agenda.title);
		formData.append('description', agenda.description);
		formData.append('day', agenda.day);
		formData.append('order', agenda.order);
		formData.append('time', agenda?.time ? moment(agenda.time, 'HH:mm').format('hh:mm A') : '');
		formData.append('tag', agenda.tag);
		formData.append('speakerName', agenda.speakerName);
		formData.append('speakers', JSON.stringify(agenda.speakers));
		formData.append('speakerDesignation', agenda.speakerDesignation);
		formData.append('track', agenda.track.toUpperCase());
		return formData;
	}

	async function saveAgenda() {
		try {
			disableButton(true);
			dispatch(setFormMessage(SAVING_MESSAGE));
			const payload = getAgendaBody(formData);
			const extractCityName = extractedCityName(city);
			const url = `${CURATED_SET_CONFIG_URL}${city === 'mumbai' ? `${city}_`
				: city && city.includes('techsparks_2023') ? `2023_${extractCityName}` : ''}agendas/${toUpdate ? 'update' : 'create'}`;
			const options = {
				method: 'PUT',
				credentials: 'include',
				body: payload,
			};
			const response = await fetch(url, options);
			disableButton(false);
			if (response && response.status === 200) {
				dispatch(setFormMessage(SAVING_SUCCESS_MESSAGE));
				getAgendas();
				unmountModal();
			} else {
				dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			}
			setTimeout(() => {
				dispatch(setFormMessage(null));
			}, 2000);
		} catch (err) {
			dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			setTimeout(() => {
				dispatch(setFormMessage(null));
			}, 2000);
			disableButton(false);
		}
	}

	function renderFormMessage() {
		if (formMessage) {
			return (
				<ToastMessage
					toastData={formMessage}
					unmount={() => dispatch(setFormMessage(null))}
				/>
			);
		}
		return null;
	}
	function handleEditOrDelete(caseVal, index, item) {
		if (caseVal === 1) {
			const changedData = {
				index,
				speakerName: item.name,
				speakerDesignation: item.designation,
			};
			dispatch(updateSpeakerFormData(changedData));
			setEditOrAddNewSpeakerForm(true);
		} else {
			const speakersData = [...formData.speakers];
			if (index > -1) {
				speakersData.splice(index, 1);
				const changedData = {
					name: 'speakers',
					value: [...speakersData],
				};
				dispatch(updateFormData(changedData));
			}
		}
	}
	function closeSpeakerFormModal() {
		setEditOrAddNewSpeakerForm(false);
	}
	function handleSubmit(e) {
		e.preventDefault();
		const validation = validateForm(formData);
		const validated = !Object.keys(validation).some((i) => validation[i]);
		getErrors();
		if (validated) {
			const speakersData = [...formData.speakers];
			if (formData.index === -1) {
				speakersData.push({
					name: formData.speakerName,
					designation: formData.speakerDesignation,
				});
				const changedData = {
					name: 'speakers',
					value: [...speakersData],
				};
				dispatch(updateFormData(changedData));
			} else {
				speakersData[formData.index].name = formData.speakerName;
				speakersData[formData.index].designation = formData.speakerDesignation;
				const changedData = {
					name: 'speakers',
					value: [...speakersData],
				};
				dispatch(updateFormData(changedData));
			}
			setEditOrAddNewSpeakerForm(false);
		} else {
			dispatch(setAllElementsBlurred());
		}
	}

	const closeAgendaForm = () => {
		unmountModal();
		dispatch(setFormData({ ...initialStateFormData }));
		setToUpdate(true);
	};
	const EditOrAddNewSpeakerModal = () => <Modal
		isOpen={showEditOrAddNewSpeakerForm}
		shouldCloseOnOverlayClick={false}
		onRequestClose={closeSpeakerFormModal}
		overlayClassName="modal-overlay"
		className="form-modal"
	>
		<Row>
			<Col md={10} xs={10}>
				<h2 className="w-50 text-left">Edit/Add New Speaker</h2>
			</Col>
			<Col md={2} xs={2}>
				<div className="w-50 text-right">
					<button type="button" onClick={closeSpeakerFormModal}>
						<i className="fas fa-times light-text disablePointerEvents" />
					</button>
				</div>
			</Col>
		</Row>
		<div>
			<form>
				<Row className="mb-3">
					<Col sm={4} xs={12}>Speaker Name *</Col>
					<Col sm={8} xs={12}>
						<Input
							name="speakerName"
							type="text"
							className="form-control"
							placeholder="Name of the speaker"
							onChange={onInputChange}
							value={formData.speakerName ?? ''}
						/>
						<ErrorMessage display={errors.speakerName} element="Speaker Name" />
					</Col>
				</Row>
				<Row className="mb-3">
					<Col sm={4} xs={12}>
						Speaker Designation
					</Col>
					<Col sm={8} xs={12}>
						<Input
							name="speakerDesignation"
							type="text"
							className="form-control"
							placeholder="Designation of the speaker"
							onChange={onInputChange}
							value={formData.speakerDesignation ?? ''}
						/>
					</Col>
					{/* <ErrorMessage display={errors.speakerDesignation} element="Speaker Designation" /> */}
				</Row>
				<div className="text-right">
					<Button
						success
						type="button"
						onClick={handleSubmit}
					>
						SAVE SPEAKER
					</Button>
				</div>
			</form>
		</div>
	</Modal>;

	const EditOrAddNewAgendaForm = () => <Modal
		isOpen={isOpen}
		onRequestClose={() => closeAgendaForm()}
		overlayClassName="modal-overlay"
		className="form-modal"
	>
		<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className="mb-2">
			<h3>New Agenda</h3>
			<button type="button" onClick={() => closeAgendaForm()}>
				<i className="fas fa-times light-text disablePointerEvents" />
			</button>
		</div>
		<form onSubmit={onFormSubmit}>
			{renderFormMessage()}
			<Row>
				<Col sm={12}>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Title *
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="title"
								type="text"
								className="form-control"
								placeholder="Title of the agenda"
								onChange={onInputChange}
								value={formData.title ?? ''}
								onBlur={() => handleBlur('title')}
							/>
							<ErrorMessage display={errors.title} element="Title" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Description *
						</Col>
						<Col sm={8} xs={12}>
							<textarea
								rows="4"
								className="global-form-control"
								name="description"
								placeholder="Description of the agenda"
								value={formData.description ?? ''}
								onChange={onInputChange}
								onBlur={() => handleBlur('description')}
							/>
							<ErrorMessage display={errors.description} element="Description" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Order *
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="order"
								type="number"
								className="form-control"
								placeholder="Order of an agenda"
								onChange={onInputChange}
								value={formData?.order}
								onBlur={() => handleBlur('order')}
							/>
							<p className="error mt-2 ml-1">NOTE: Please enter order which is not being used.</p>
							<ErrorMessage display={errors.order} element="Order" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Day *
						</Col>
						<Col sm={8} xs={12}>
							<DropDown
								className="mt-0"
								name="day"
								value={formData && formData.day ? formData.day : 'DAY1'}
								onChange={onInputChange}
								onBlur={() => handleBlur('day')}
							>
								<option value="DAY1">DAY1</option>
								<option value="DAY2">DAY2</option>
								<option value="DAY3">DAY3</option>
								{/* <option value="Nov 10, Thu">DAY1</option>
									<option value="Nov 11, Fri">DAY2</option>
									<option value="Nov 12, Sat">DAY3</option> */}
							</DropDown>
							<ErrorMessage display={errors.day} element="Day" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Tag *
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="tag"
								type="text"
								className="form-control"
								placeholder="Tag of the agenda"
								onChange={onInputChange}
								value={formData.tag ?? ''}
								onBlur={() => handleBlur('tag')}
							/>
							<ErrorMessage display={errors.tag} element="Tag" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Track *
						</Col>
						<Col sm={8} xs={12}>
							<DropDown
								className="mt-0"
								name="track"
								value={formData && formData.track ? formData.track : '1|Main Stage - A'.toUpperCase()}
								onChange={onInputChange}
								onBlur={() => handleBlur('track')}
							>
								<option value={'1|Main Stage - A'.toUpperCase()}>Main Stage - A</option>
								<option value={'2|Main Stage - B'.toUpperCase()}>Main Stage - B</option>
								<option value={'The D2C Playbook Track (Invite Only)'.toUpperCase()}>The D2C Playbook Track (Invite Only)</option>
								{city.includes('bangalore') && <option value={'8|Main Track 1'.toUpperCase()}>Main Track 1</option>}
								{city.includes('bangalore') && <option value={'9|Main Track 2'.toUpperCase()}>Main Track 2</option>}
								{/* day 1 */}
								{formData.day === 'DAY1' && <option value={'3|Unravelling creator economy'.toUpperCase()}>Unravelling creator economy</option>}
								{formData.day === 'DAY1' && <option value={"4|India's GameTech play".toUpperCase()}>India's GameTech play</option>}
								{(formData.day === 'DAY1' && city.includes('mumbai')) && <option value={'3|Stage 1'.toUpperCase()}>Stage 1</option>}
								{(formData.day === 'DAY1' && city.includes('mumbai')) && <option value={'4|Stage 2'.toUpperCase()}>Stage 2</option>}
								{(formData.day === 'DAY1' && city.includes('mumbai')) && <option value={'5|The Web 3.0 Track'.toUpperCase()}>The Web 3.0 Track</option>}
								{/* day 2 */}
								{formData.day === 'DAY2' && <option value={'3|Scaling your startup the right way'.toUpperCase()}>Scaling your startup the right way</option>}
								{formData.day === 'DAY2' && <option value={'4|Big Tech, Big Data: Future of Tech'.toUpperCase()}>Big Tech, Big Data: Future of Tech</option>}
								{formData.day === 'DAY2' && <option value={"5|India's Web3 opportunity".toUpperCase()}>India's Web3 opportunity</option>}
								{(formData.day === 'DAY2' && city.includes('mumbai')) && <option value={'6|Stage 1'.toUpperCase()}>Stage 1</option>}
								{(formData.day === 'DAY2' && city.includes('mumbai')) && <option value={'8|Stage 2'.toUpperCase()}>Stage 2</option>}
								{(formData.day === 'DAY2' && city.includes('mumbai')) && <option value={'9|Product & Tech'.toUpperCase()}>Product & Tech</option>}
								{(formData.day === 'DAY2' && city.includes('mumbai')) && <option value={'10|Retail & e-commerce'.toUpperCase()}>Retail & E-Commerce</option>}
								{/* day 3 */}
								{formData.day === 'DAY3' && <option value={'3|Mother-verse'.toUpperCase()}>Mother-verse</option>}
								{formData.day === 'DAY3' && <option value={'4|Future of Indian SaaS'.toUpperCase()}>Future of Indian SaaS</option>}
								{formData.day === 'DAY3' && <option value={'5|India 2025: Outlook'.toUpperCase()}>India 2025: Outlook</option>}
								{formData.day === 'DAY3' && <option value={'6|Frenzy of fintech'.toUpperCase()}>Frenzy of fintech</option>}
								{tracksOptions.map((item) => <option value={item.value.toUpperCase()}>
									{item.name}
								</option>)}
							</DropDown>
							<ErrorMessage display={errors.track} element="track" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Time
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="time"
								type="time"
								className="form-control"
								placeholder="Time"
								onChange={onInputChange}
								value={formData.time ?? ''}
							/>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Speakers
						</Col>
						<Col sm={8} xs={12}>
							<Button
								success
								type="button"
								onClick={() => {
									setEditOrAddNewSpeakerForm(true);
									const changedIndex = {
										name: 'index',
										value: -1,
									};
									const changedData = {
										index: -1,
										speakerName: '',
										speakerDesignation: '',
									};
									dispatch(updateFormData(changedIndex));
									dispatch(updateSpeakerFormData(changedData));
								}}
							>
								Add More Speakers
							</Button>
							{/* <AsyncMultiSelect
									placeholder="Search speakers by name"
									options={(value) => getDropdownOptions('tech-sparks-agend-speacker', value, 'tech-sparks-agend-speacker')}
									currentValues={formData.speakers}
									handleChange={(value) => {
										const changedData = {
											name: 'speakers',
											value,
										};
										dispatch(updateFormData(changedData));
									}}
									profile="tech-spark-agend-speakers"
									onBlur={() => handleBlur('speakers')}
								/> */}
						</Col>
						{formData.speakers && formData.speakers.length > 0 && <Row
							xs={12}
							style={
								formData?.speakers?.length > 0 ? {
									overflowY: 'auto',
									height: '120px',
								} : {}
							}
						>
								{formData.speakers && formData.speakers.length ? formData.speakers.map((item, index) => <Col
									xs={12}
									sm={6}
									key={item.name + uuid()}
								>
									<TechSparksAgendSpeakersCard
										speaker
										speakerData={item}
										type="speaker"
										handleEditOrDelete={(caseVal) => {
											handleEditOrDelete(caseVal, index, item);
										}}
									/>
								    </Col>) : ''}
							</Row>}
					</Row>
					<Row className="mb-3 d-none">
						<Col sm={4} xs={12}>
							Speaker Designation
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="speakerDesignation"
								type="text"
								className="form-control"
								placeholder="Designation of the speaker"
								onChange={onInputChange}
								value={formData.speakerDesignation ?? ''}
							/>
						</Col>
					</Row>
					<div className="text-right">
						<Button
							success
							type="submit"
							disabled={isButtonDisabled}
						>
							SAVE
						</Button>
					</div>
				</Col>
				{renderFormMessage()}
			</Row>
		</form>
	</Modal>;
	return (
		<>
			{EditOrAddNewAgendaForm()}
			{EditOrAddNewSpeakerModal()}
		</>
	);
};

AgendaForm.propTypes = {
	agenda: T.object,
	getAgendas: T.func,
	unmountModal: T.func,
	isOpen: T.bool,
	city: T.string,
	agendasLength: T.number,
};

export default AgendaForm;
