import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { ListHeaderWrapper } from '../BoniManager/BoniProductCategory/style';

const ProductListHeader = () => (
	<ListHeaderWrapper>
		<Col xs={false} md={1} className="text-center">
			S.No
		</Col>
		<Col xs={6} md={3} className="text-center">
			Title
		</Col>
		<Col xs={false} md={3} className="text-center">
			Updated At
		</Col>
		<Col xs={false} md={3} className="text-center">
			Url
		</Col>
		{/* <Col xs={false} md={2} className="text-center d-none">
			Brand Category
		</Col>
		<Col xs={false} md={1} className="text-center d-none">
			Brand
		</Col> */}
		{/* <Col xs={false} md={1} className="text-center">
			Status
		</Col> */}
		<Col xs={false} md={2} className="text-center">
			Action
		</Col>
	</ListHeaderWrapper>
);

export default ProductListHeader;
