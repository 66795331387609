import {
	FETCH_DESK_STORIES_BY_BRAND_AND_STATUS, FETCH_DESK_STORIES_BY_BRAND_AND_STATUS_SUCCESS, FETCH_DESK_STORIES_BY_BRAND_AND_STATUS_FAILURE, SET_DESK_PAGE, SET_DESK_STORIES_STATUS, FETCH_DESK_BRANDS, FETCH_DESK_BRANDS_SUCCESS, FETCH_DESK_BRANDS_FAILURE, FETCH_PENDING_APPROVAL_COUNT, UPDATE_DESK_STORY_STATUS, UPDATE_DESK_STORY_STATUS_SUCCESS, UPDATE_DESK_STORY_STATUS_FAILURE, FETCH_PENDING_APPROVAL_COUNT_SUCCESS, FETCH_PENDING_APPROVAL_COUNT_FAILURE, DISABLE_DESK_SEARCH_FLAG, RUN_DESK_SEARCH, DISPLAY_DESK_COMMENT_BOX, HIDE_DESK_COMMENT_BOX, ADD_DESK_COMMENT, SELECT_DESK_BRAND, RUN_DESK_SEARCH_SUCCESS, RUN_DESK_SEARCH_FAILURE, CHECK_PLAGIARISM,
} from './type';

export function fetchDeskStoriesByBrandAndStatus(brand, status, page, storyType) {
	return {
		type: FETCH_DESK_STORIES_BY_BRAND_AND_STATUS,
		brand,
		status,
		page,
		storyType,
	};
}

export function fetchDeskStoriesByBrandAndStatusSuccess(stories) {
	return {
		type: FETCH_DESK_STORIES_BY_BRAND_AND_STATUS_SUCCESS,
		stories,
	};
}

export function fetchDeskStoriesByBrandAndStatusFailure(error) {
	return {
		type: FETCH_DESK_STORIES_BY_BRAND_AND_STATUS_FAILURE,
		error,
	};
}

export function setDeskPage(page) {
	return {
		type: SET_DESK_PAGE,
		page,
	};
}

export function setDeskStoriesStatus(status) {
	return {
		type: SET_DESK_STORIES_STATUS,
		status,
	};
}

export function fetchDeskBrands(slugs) {
	return {
		type: FETCH_DESK_BRANDS,
		slugs,
	};
}

export function fetchDeskBrandsSuccess(brands) {
	return {
		type: FETCH_DESK_BRANDS_SUCCESS,
		brands,
	};
}

export function fetchDeskBrandsFailure(error) {
	return {
		type: FETCH_DESK_BRANDS_FAILURE,
		error,
	};
}

export function fetchPendingApprovalCount(brand) {
	return {
		type: FETCH_PENDING_APPROVAL_COUNT,
		brand,
	};
}

export function fetchPendingApprovalCountSuccess(pending) {
	return {
		type: FETCH_PENDING_APPROVAL_COUNT_SUCCESS,
		count: pending,
	};
}

export function fetchPendingApprovalCountFailure(error) {
	return {
		type: FETCH_PENDING_APPROVAL_COUNT_FAILURE,
		error,
	};
}

export function updateDeskStoryStatus(brand, story, newStatus, page, comment) {
	return {
		type: UPDATE_DESK_STORY_STATUS,
		brand,
		story,
		newStatus,
		page,
		comment,
	};
}

export function updateDeskStoryStatusSuccess(responseMessage) {
	return {
		type: UPDATE_DESK_STORY_STATUS_SUCCESS,
		responseMessage,
	};
}

export function updateDeskStoryStatusFailure(error) {
	return {
		type: UPDATE_DESK_STORY_STATUS_FAILURE,
		error,
	};
}

export function disableDeskSearchFlag() {
	return {
		type: DISABLE_DESK_SEARCH_FLAG,
	};
}

export function runDeskSearch(searchType, searchValue, status, brand, limit, storyType) {
	return {
		type: RUN_DESK_SEARCH,
		status,
		brand,
		searchType,
		searchValue,
		limit,
		storyType,
	};
}

export function runDeskSearchSuccess(searchResults) {
	return {
		type: RUN_DESK_SEARCH_SUCCESS,
		searchResults,
	};
}

export function runDeskSearchFailure(error) {
	return {
		type: RUN_DESK_SEARCH_FAILURE,
		error,
	};
}

export function displayDeskCommentBox(story, newStatus) {
	return {
		type: DISPLAY_DESK_COMMENT_BOX,
		story,
		newStatus,
	};
}

export function hideDeskCommentBox() {
	return {
		type: HIDE_DESK_COMMENT_BOX,
	};
}

export function addDeskComment(comment) {
	return {
		type: ADD_DESK_COMMENT,
		comment,
	};
}

export function selectDeskBrand(selectedBrand) {
	return {
		type: SELECT_DESK_BRAND,
		selectedBrand,
	};
}

export function checkPlagiarism(storyId) {
	return {
		type: CHECK_PLAGIARISM,
		storyId,
	};
}
