import { Map } from 'immutable';

import {
	GET_LATEST_ACTIVITY_STREAM,
	GET_LATEST_ACTIVITY_STREAM_ERROR,
	GET_LATEST_ACTIVITY_STREAM_SUCCESS,
	GET_ALL_ACTIVITY_STREAM,
	GET_ALL_ACTIVITY_STREAM_ERROR,
	GET_ALL_ACTIVITY_STREAM_SUCCESS,
	LOAD_ALL_BRANDS,
	LOAD_ALL_BRANDS_SUCCESS,
	LOAD_ALL_BRANDS_ERROR,
	SELECT_BRAND,
} from './type';

const initialState = Map({
	loading: false,
	error: false,
	latest: [],
	all: [],
	displayMessage: null,
	allBrands: [],
	selectedBrand: {},
	formData: {},
	formMessage: null,
	dropdownTitle: 'SELECT BRAND',
});

function activityStreamReducer(state = initialState, action) {
	switch (action.type) {
	case GET_LATEST_ACTIVITY_STREAM:
		return state
			.set('loading', true);
	case GET_LATEST_ACTIVITY_STREAM_ERROR:
		return state
			.set('loading', false)
			.set('error', action.error);
	case GET_LATEST_ACTIVITY_STREAM_SUCCESS:
		return state
			.set('loading', false)
			.set('latest', action.latest);
	case GET_ALL_ACTIVITY_STREAM:
		return state
			.set('loading', true);
	case GET_ALL_ACTIVITY_STREAM_ERROR:
		return state
			.set('loading', false)
			.set('error', action.error);
	case GET_ALL_ACTIVITY_STREAM_SUCCESS:
		if (action.clearStream) {
			return state
				.set('loading', false)
				.set('all', action.all);
		}
		return state
			.set('loading', false)
			.update('all', (all) => all.concat(action.all));
	case LOAD_ALL_BRANDS:
		return state
			.set('loading', true)
			.set('error', false)
			.set('dropdownTitle', 'SELECT BRAND');
	case LOAD_ALL_BRANDS_SUCCESS:
		return state
			.set('loading', false)
			.set('error', false)
			.set('dropdownTitle', 'SELECT BRAND')
			.set('allBrands', action.allBrands);
	case LOAD_ALL_BRANDS_ERROR:
		return state
			.set('loading', false)
			.set('error', action.error);
	case SELECT_BRAND: {
		const selected = action.selectedBrand.name;
		if (selected === state.get('dropdownTitle')) {
			const form = {
				name: '',
				slug: '',
				description: '',
				image: '',
				locale: '',
				internal: true,
			};
			return state
				.set('loading', false)
				.set('error', false)
				.set('selectedBrand', null)
				.set('formData', form);
		}
		const form = {
			name: action.selectedBrand.name,
			slug: action.selectedBrand.slug,
			description: action.selectedBrand.description,
			image: action.selectedBrand.imagelocation,
			locale: action.selectedBrand.locale,
			internal: action.selectedBrand.internal,
		};
		return state
			.set('selectedBrand', action.selectedBrand)
			.set('formData', form)
			.set('formMessage', null);
	}
	default:
		return state;
	}
}

export default activityStreamReducer;
