import {
	call, put, takeLatest, select,
} from 'redux-saga/effects';
import { FETCH_EDITOR_GALLERY_MEDIA, FILTER_EDITOR_GALLERY_MEDIA } from './type';
import { ASSET_BASE_API_URL } from './constants';
import {
	fetchEditorGalleryMediaFailure, fetchEditorGalleryMediaSuccess, filterEditorGalleryMediaFailure, filterEditorGalleryMediaSuccess, setEditorGalleryUpdatedTime,
} from './actions';
import { redirectTo } from '../../../utils/accessRedirection';
import { makeSelectLocation } from '../../../containers/App/selectors';

let CURRENT_LOCATION;

export function* getEditorGalleryMedia(action) {
	const url = `${ASSET_BASE_API_URL}/editor/gallery`;
	const options = {
		method: 'POST',
		credentials: 'include',
		body: action.fetchData,
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			redirectTo(response.status, CURRENT_LOCATION);
		} else {
			const media = yield response.json();
			yield put(fetchEditorGalleryMediaSuccess(media, action.concat));
			const time = new Date().toLocaleTimeString('en-gb');
			yield put(setEditorGalleryUpdatedTime(time));
		}
	} catch (err) {
		yield put(fetchEditorGalleryMediaFailure(err));
		redirectTo(500, CURRENT_LOCATION);
	}
}

export function* searchMedia(action) {
	const url = `${ASSET_BASE_API_URL}/filter/editor/gallery`;
	const options = {
		method: 'POST',
		credentials: 'include',
		body: action.searchData,
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403].includes(response.status)) {
			redirectTo(response.status, CURRENT_LOCATION);
		} else if (response.status === 500) {
			yield put(filterEditorGalleryMediaFailure('error'));
		} else {
			const parsedResponse = yield response.json();
			yield put(filterEditorGalleryMediaSuccess(parsedResponse, (action.concat)));
		}
	} catch (err) {
		yield put(filterEditorGalleryMediaFailure(err));
	}
}

export default function* editorGalleryData() {
	const location = yield select(makeSelectLocation());
	CURRENT_LOCATION = location.pathname;
	yield takeLatest(FETCH_EDITOR_GALLERY_MEDIA, getEditorGalleryMedia);
	yield takeLatest(FILTER_EDITOR_GALLERY_MEDIA, searchMedia);
}
