import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { LOAD_PROMOTIONS_START, LOAD_CHATBOTS } from './type';
import {
	loadPromotionsError, loadPromotionsSuccess, loadPromotionsUpdateSuccess, loadChatbotsError, loadChatbotsSuccess,
} from './actions';
import { PROMOTIONS_API_BASE_URL, CHATBOTS_API_BASE_URL } from './constants';

export function* getPromotions(actionObject) {
	const {
		brand, startDate, endDate, limit, offset, update,
	} = actionObject;
	const url = `${PROMOTIONS_API_BASE_URL}?limit=${limit}&offset=${offset}&brand=${brand}&start_date=${startDate}&end_date=${endDate}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const result = yield response.json();
			if (update) {
				yield put(loadPromotionsUpdateSuccess(result));
			} else {
				yield put(loadPromotionsSuccess(result));
			}
		}
	} catch (err) {
		yield put(loadPromotionsError(err));
	}
}

export function* getChatbots() {
	const url = `${CHATBOTS_API_BASE_URL}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const chatbots = yield response.json();
			yield put(loadChatbotsSuccess(chatbots));
		}
	} catch (err) {
		yield put(loadChatbotsError(err));
	}
}

export default function* messengerPromotionsManagerData() {
	yield takeLatest(LOAD_PROMOTIONS_START, getPromotions);
	yield takeLatest(LOAD_CHATBOTS, getChatbots);
}
