import {
	LOAD_ALL_BRANDS, LOAD_ALL_BRANDS_SUCCESS, LOAD_ALL_BRANDS_ERROR, SELECT_BRAND, ADD_BRAND, BRAND_ELEMENT_ON_CHANGE, WRITE_BRAND_SLUG, CREATE_BRAND, CREATE_BRAND_SUCCESS, CREATE_BRAND_ERROR, UPDATE_BRAND, UPDATE_BRAND_SUCCESS, UPDATE_BRAND_ERROR, DELETE_BRAND, DELETE_BRAND_SUCCESS, DELETE_BRAND_ERROR, UNMOUNT_BRAND_FORM_MESSAGE,
} from './type';

export function loadAllBrands() {
	return {
		type: LOAD_ALL_BRANDS,
	};
}

export function brandsLoadedSuccessfully(allBrands) {
	return {
		type: LOAD_ALL_BRANDS_SUCCESS,
		allBrands,
	};
}

export function brandsLoadingError(error) {
	return {
		type: LOAD_ALL_BRANDS_ERROR,
		error,
	};
}

export function brandSelect(selectedBrand) {
	return {
		type: SELECT_BRAND,
		selectedBrand,
	};
}

export function initiateAddBrand() {
	return {
		type: ADD_BRAND,
	};
}

export function onBrandFormElementChange(elementData) {
	return {
		type: BRAND_ELEMENT_ON_CHANGE,
		elementData,
	};
}

export function writeBrandSlug() {
	return {
		type: WRITE_BRAND_SLUG,
	};
}

export function createBrand(brand) {
	return {
		type: CREATE_BRAND,
		brand,
	};
}

export function createBrandSuccess(successResponse) {
	return {
		type: CREATE_BRAND_SUCCESS,
		successResponse,
	};
}

export function createBrandFailure(failureResponse) {
	return {
		type: CREATE_BRAND_ERROR,
		failureResponse,
	};
}

export function updateBrand(id, updatedData) {
	return {
		type: UPDATE_BRAND,
		id,
		updatedData,
	};
}

export function updateBrandSuccess(successResponse) {
	return {
		type: UPDATE_BRAND_SUCCESS,
		successResponse,
	};
}

export function updateBrandFailure(failureResponse) {
	return {
		type: UPDATE_BRAND_ERROR,
		failureResponse,
	};
}

export function deleteBrand(id) {
	return {
		type: DELETE_BRAND,
		id,
	};
}

export function deleteBrandSuccess(successResponse) {
	return {
		type: DELETE_BRAND_SUCCESS,
		successResponse,
	};
}

export function deleteBrandFailure(failureResponse) {
	return {
		type: DELETE_BRAND_ERROR,
		failureResponse,
	};
}

export function unmountBrandFormMessage() {
	return {
		type: UNMOUNT_BRAND_FORM_MESSAGE,
	};
}
