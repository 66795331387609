import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { CITY_API_BASE_URL } from './constants';
import {
	createCityFailure, createCitySuccess, citySelect, updateCitySuccess, deleteCitySuccess, deleteCityFailure, updateCityFailure,
} from './actions';
import { CREATE_CITY, DELETE_CITY, UPDATE_CITY } from './type';

export function* createNewCity(action) {
	const url = `${CITY_API_BASE_URL}/`;
	const formData = action.city;
	const options = {
		method: 'POST',
		body: formData,
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(createCitySuccess(responseMessage));
				yield put(citySelect(null));
			} else {
				yield put(createCityFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(createCityFailure(err));
	}
}

export function* updateCity(action) {
	const url = `${CITY_API_BASE_URL}/id/${action.id}`;
	const formData = action.updatedData;
	const options = {
		method: 'PUT',
		body: formData,
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(updateCitySuccess(responseMessage));
				yield put(citySelect(null));
			} else {
				yield put(updateCityFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(updateCityFailure(err));
	}
}

export function* deleteCity(action) {
	const url = `${CITY_API_BASE_URL}/${action.id}`;
	const options = {
		method: 'DELETE',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(deleteCitySuccess(responseMessage));
				yield put(citySelect(null));
			} else {
				yield put(deleteCityFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(deleteCityFailure(err));
	}
}

export default function* citiesData() {
	yield takeLatest(CREATE_CITY, createNewCity);
	yield takeLatest(UPDATE_CITY, updateCity);
	yield takeLatest(DELETE_CITY, deleteCity);
}
