import styled from 'styled-components';

const Card = styled.div`
		height: 285px!important;
		width: 300px!important;
		background-color: white!important;
		box-shadow: 0px 2px 3px 0px grey;
`;

const Image = styled.img`
		width: 300px!important;
		height: 150px!important;
`;

const ImagePlaceHolder = styled.div`
		width: 300px!important;
		height: 150px!important;
		background-color: #eee;
		text-align: center;
		vertical-align: middle;
		line-height: 150px;
		font-weight: bold;
		color: grey;
`;

const Text = styled.div`
		font-weight: bold;
		font-size: 14px;
		padding: 5px 17px;
		min-height: 90px;
		overflow: hidden;
		position: relative;
		line-height: 1.1rem;
		max-height: 4.5rem;
		text-align: justify;
		margin-right: -1em;
		padding-right: 1em;
		&:before {
				content: '...';
				position: absolute;
				right: 0;
				bottom: 0;
		}
		&:after {
				content: '';
				position: absolute;
				right: 0;
				width: 1em;
				height: 1em;
				margin-top: 0.2em;
				background: $bgColor;
		}
`;

const Hr = styled.hr`
		width: 90%;
		height: 1px;
		border: none;
		background-color: #efefef;
`;

const Option = styled.div`
		padding: 2px!important;
		position: relative!important;
		height: 28px;
		#date-picker-element{
				display: none;
		}
`;

const DeleteIcon = styled.img`
		position: absolute;
		top: 0px;
		padding-left: 12px;
		cursor: pointer;
`;

const MoveUpIcon = styled.img`
		height: 18px;
		width: 18px;
		cursor: pointer; 
		position: absolute;
		top: 20px;
		left: 16px;	 
`;

const MoveDownIcon = styled.img`
		height: 18px;
		width: 18px; 
		cursor: pointer;
		position: absolute;
		top: 95px; 
		left: 16px;
`;

const Move = styled.div`
		position: relative;
		background-color: #fafafa;
		min-height: 100%;
		min-width: 100%;
`;

const Content = styled.div`
		width: 100%;
		height: 135px;
`;

const Section = styled.div`
`;

const CalenderDiv = styled.span`
		position: absolute;
		left: 52px;
		top: -4px;
		cursor: pointer;
`;

const DateText = styled.span`
		font-weight: bold;
		font-size: 0.8rem;
		margin-top: 2px;
		margin-left: 4px;
`;

const PositionText = styled.div`
	position: absolute;
	font-weight: bold;
	right: 4px;
	top: 2px;
	font-size: 0.8rem;
	color: white;
`;

const BrandText = styled.div`
		position: absolute;
		font-weight: bold;
		left: 4px;
		top: 2px;
		font-size: 0.8rem;
		color: white;
`;

const ImageSection = styled.div`
		position: relative;
`;

export {
	BrandText, ImageSection, PositionText, DateText, Card, Image, ImagePlaceHolder, Text, Hr, Option, DeleteIcon, MoveUpIcon, MoveDownIcon, Move, Content, Section, CalenderDiv,
};
