import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';

const SpeakerCard = styled(Row)`
		min-height: 80px;
		height: auto;
		width: 250px;
		border-radius: 5px;
		background: #f1f1f1;
		box-shadow: 1px 2px 3px 0px #DDDDDD;
		align-items: center;
		${(props) => !props.speaker && css`
				background-color: #f5f5f0;
		`}
`;

const TechSparksAgendSpeakersCard = (props) => {
	const { speaker, speakerData, handleEditOrDelete } = props;
	TechSparksAgendSpeakersCard.propTypes = {
		speakerData: PropTypes.object,
		speaker: PropTypes.bool,
		handleEditOrDelete: PropTypes.func,
	};
	return (
		<div>
			<SpeakerCard className="ml-1 mr-1 mt-3" speaker={speaker}>
				<Col xs={12}>
					<div className="text-right">
						<i className="fa fa-pencil-square fa-lg" style={{ cursor: 'pointer' }} onClick={() => handleEditOrDelete(1)} />
						<i className="fa fa-trash fa-lg ml-3" style={{ cursor: 'pointer' }} onClick={() => handleEditOrDelete(2)} />
					</div>
					<p className="m-0">
						<b>Name: {speakerData.name || 'N/A'}</b>
					</p>
					<p className="m-0">
						Designation: {speakerData.designation || 'N/A'}
					</p>
				</Col>
			</SpeakerCard>
		</div>
	);
};

export default TechSparksAgendSpeakersCard;
