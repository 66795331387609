import React, {
	useEffect, useState, useReducer, Fragment,
} from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import 'rc-pagination/assets/index.css';
import T from 'prop-types';
import {
	Input, Button,
} from '../../../components/Styles';
// import ToastMessage from '../../../components/ToastMessage';
import { AsyncSingleSelectWithDefault, TitleWithTooltip } from '../../../components/FormComponents';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import LoadingIcon from '../../../components/LoadingIcon/LoadingIcon';
import {
	// GET_CITY_LIST,
	GET_PROFILE_DATA,
	FETCH_PROFILE_DATA,
	FETCH_PROFILE_DATA_SUCCESS,
	FETCH_PROFILE_DATA_FAILURE,
	UPDATE_TALENT_BASE_URL,
	SET_ALL_ELEMENTS_BLURRED,
	UPDATE_ERRORS,
	UPDATE_BLURRED_ELEMENT,
	UPDATE_ACTIONS_ERROR,
	UPDATE_ACTIONS_SUCCESS,
	UPDATE_ACTIONS_LOADER,
	SET_ACTIONS_MESSAGE,
} from '../constants';
import { jobProfileContactInfoValidation, filterProfileData, filterAsyncSelectOptionsTrue } from '../../../utils/common';

const initialState = {
	cityList: [],
	profileJobData: {},
	loading: false,
	error: false,
	actionLoading: false,
	actionError: false,
	actionMessage: '',
	blurredElements: {
		headLine: false,
		detailedBio: false,
		shortBio: false,
		name: false,
		currentCity: false,
		emailId: false,
		phoneNo: false,
	},
	errors: {
		headLine: false,
		detailedBio: false,
		shortBio: false,
		name: false,
		currentCity: false,
		emailId: false,
		phoneNo: false,
	},
};

function setActionsMessage(message) {
	return {
		type: SET_ACTIONS_MESSAGE,
		message,
	};
}
function updateActionsLoading() {
	return {
		type: UPDATE_ACTIONS_LOADER,
	};
}

function updateActionsFailure() {
	return {
		type: UPDATE_ACTIONS_ERROR,
	};
}

function updateActionsSuccess() {
	return {
		type: UPDATE_ACTIONS_SUCCESS,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function fetchProfileJobData() {
	return {
		type: FETCH_PROFILE_DATA,
	};
}
function fetchProfileJobDataSuccess(profileJobData) {
	return {
		type: FETCH_PROFILE_DATA_SUCCESS,
		profileJobData,
	};
}
function fetchProfileJobDataFailure() {
	return {
		type: FETCH_PROFILE_DATA_FAILURE,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case FETCH_PROFILE_DATA:
		return { ...state, loading: true };
	case FETCH_PROFILE_DATA_SUCCESS:
		return {
			...state, loading: false, profileJobData: { ...action.profileJobData },
		};
	case FETCH_PROFILE_DATA_FAILURE:
		return {
			...state, loading: false, error: true, profileJobData: {},
		};
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };
	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			headLine: true,
			detailedBio: true,
			shortBio: true,
			name: true,
			currentCity: true,
			emailId: true,
			phoneNo: true,
		};
		return { ...state, blurredElements };
	}
	case UPDATE_ACTIONS_ERROR:
		return {
			...state, actionLoading: false, actionError: true,
		};
	case UPDATE_ACTIONS_SUCCESS:
		return {
			...state, actionLoading: false, actionError: false,
		};
	case UPDATE_ACTIONS_LOADER:
		return {
			...state, actionLoading: true, actionError: false,
		};
	case SET_ACTIONS_MESSAGE:
		return {
			...state, actionMessage: action.message,
		};
	default:
		return state;
	}
}

const ContactInfoAndProfileBioPage = (props) => {
	const { match, history } = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		loading, errors, blurredElements, actionLoading, actionError, actionMessage,
	} = state;
	const [validEmail, setValidEmail] = useState(true);
	const [validPhoneNumber, setValidPhoneNumber] = useState(true);
	const [profileData, setProfileData] = useState({
		name: '',
		currentCity: {},
		emailId: '',
		phoneNo: '',
		headLine: '',
		detailedBio: '',
		shortBio: '',
	});
	// const [filterBy, setFilterBy] = useState('select');
	// const [filterValue, setFilterValue] = useState(null);

	useEffect(() => {
		dispatch(updateErrors({
			headLine: false,
			detailedBio: false,
			shortBio: false,
			name: false,
			currentCity: false,
			emailId: false,
			phoneNo: false,
		}));
		dispatch(updateBlurredElement({
			headLine: false,
			detailedBio: false,
			shortBio: false,
			name: false,
			currentCity: false,
			emailId: false,
			phoneNo: false,
		}));
		dispatch(setAllElementsBlurred());
		if (match?.params?.profileId) {
			getProfileData(match.params.profileId);
		}
		// pageTitle('pendingApprovalJobs');
		// getPendingApprovalJobs((page - 1) * 10, 10);
	}, []);

	// async function getCityList(searchVal) {
	// 	const url = `${GET_CITY_LIST}/${searchVal}`;
	// 	const options = {
	// 		method: 'GET',
	// 		credentials: 'include',
	// 	};
	// 	try {
	// 		const response = await fetch(url, options);
	// 		if (response.status === 401 || response.status === 403) {
	// 			history.push('/unauthorized');
	// 			return [];
	// 		} if (response.status === 200) {
	// 			let finalResult = await response.json();
	// 			if (finalResult && finalResult?.length > 0) {
	// 				finalResult = finalResult.forEach((item) => {
	// 					item.value = item.name;
	// 					item.label = item.name;
	// 				});
	// 				return finalResult;
	// 			}
	// 		} else {
	// 			return [];
	// 		}
	// 	} catch (err) {
	// 		return [];
	// 	}
	// }
	async function getProfileData(profileId) {
		dispatch(fetchProfileJobData());
		const url = `${GET_PROFILE_DATA}${profileId}`;
		const options = {
			method: 'GET',
			credentials: 'include',
		};
		try {
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				const profileDetails = await response.json();
				if (profileDetails && profileDetails?.id?.length > 0) {
					setProfileData({
						...filterProfileData(profileDetails),
						name: profileDetails.name,
						emailId: profileDetails.emailId,
						phoneNo: profileDetails.phoneNo,
						currentCity: {
							value: profileDetails.currentCity,
							label: profileDetails.currentCity,
						},
						detailedBio: profileDetails.detailedBio,
						shortBio: profileDetails.shortBio,
						headLine: profileDetails.headLine,
					});
				}
				dispatch(fetchProfileJobDataSuccess(profileDetails));
			} else {
				dispatch(fetchProfileJobDataFailure());
			}
		} catch (err) {
			dispatch(fetchProfileJobDataFailure());
		}
	}
	const saveProfileDataForUpdate = async () => {
		dispatch(setActionsMessage(''));
		dispatch(updateActionsLoading());
		const url = UPDATE_TALENT_BASE_URL;
		const formData = {
			...profileData,
			profileId: match.params.profileId,
			detailedBio: profileData.detailedBio,
			headLine: profileData.headLine,
			name: profileData.name,
			emailId: profileData.emailId,
			phoneNo: profileData.phoneNo,
			currentCity: profileData.currentCity.value,
			shortBio: profileData.shortBio,
			switchFunctionIndustry: 'Yes',
			prefIndustry: profileData?.prefIndustry ? profileData?.prefIndustry : '',
			prefCity: profileData?.prefCity ? profileData?.prefCity : [],
		};
		const options = {
			method: 'PATCH',
			credentials: 'include',
			body: JSON.stringify(formData),
			headers: {
				'Content-Type': 'application/json',
			},
		};
		try {
			// if ((profileData.detailedBio.length > 500) || (profileData.headLine.length > 500) || (profileData.shortBio.length > 500)) {
			// 	dispatch(updateActionsSuccess());
			// 	dispatch(setActionsMessage(''));
			// 	return alert('The respective fields cannot have a length of more than 500 characters.');
			// }
			const response = await fetch(url, options);
			if (response.status === 401 || response.status === 403) {
				history.push('/unauthorized');
			} else if (response.status === 200) {
				dispatch(updateActionsSuccess());
				dispatch(setActionsMessage('Contact Information and Profile Bio are updated successfully'));
				setTimeout(() => {
					dispatch(setActionsMessage(''));
				}, 2000);
			} else {
				dispatch(updateActionsFailure());
				dispatch(setActionsMessage('Not Able To Update The Job profile'));
				setTimeout(() => {
					dispatch(updateActionsSuccess());
				}, 2000);
			}
		} catch (err) {
			dispatch(updateActionsFailure());
			dispatch(setActionsMessage('Not Able To Update The Job profile'));
			setTimeout(() => {
				dispatch(setActionsMessage(''));
				dispatch(updateActionsSuccess());
			}, 2000);
		}
	};
	function validateForm() {
		return {
			name: !(profileData && profileData.name && profileData.name?.length > 0),
			currentCity: !(profileData.currentCity && profileData.currentCity?.value?.length > 0),
			emailId: !(profileData.emailId && profileData.emailId?.length > 0),
			phoneNo: !(profileData.phoneNo && profileData.phoneNo?.length > 0),
			headLine: !(profileData && profileData.headLine && profileData.headLine?.length > 0),
			detailedBio: !(profileData.detailedBio && profileData.detailedBio?.length > 0),
			shortBio: !(profileData.shortBio && profileData.shortBio?.length > 0),
		};
	}
	const handleErrors = () => {
		const validation = validateForm();
		const errors = Object.keys(validation).reduce((acc, curr) => {
			if (validation[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		const validation = validateForm();
		const validated = !Object.keys(validation).some((i) => validation[i]);
		handleErrors();
		if (validated && validEmail && validPhoneNumber) {
			saveProfileDataForUpdate();
		} else {
			dispatch(setAllElementsBlurred());
		}
	};

	function handleBlur(element) {
		handleErrors();
		dispatch(updateBlurredElement(element));
	}

	return (
		<div>
			{
				loading
					? <LoadingIcon />
					: <>
						<div>
							<h3 className="px-3">
								Edit Contact Information and Profile Bio
							</h3>
							<form onSubmit={handleSubmit}>
								<Row className="m-2">
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Candidate Name*"
												hint="Candidate Name"
											/>
											<Input
												type="text"
												className="form-control"
												value={profileData.name}
												name="candidateName"
												autoComplete="off"
												maxLength="100"
												onChange={(e) => {
													setProfileData(({
														...profileData,
														name: e.target.value,
													}));
													handleErrors();
												}}
											/>
											<ErrorMessage display={errors.name} element="Name" />
										</div>
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Current City *"
												hint="Current City"
											/>
											<AsyncSingleSelectWithDefault
												name="current-city"
												placeholder="Select From List"
												defaultOptionsList
												options={(value) => filterAsyncSelectOptionsTrue(value, 'cities', '', null)}
												value={profileData.currentCity}
												handleChange={(value) => {
													setProfileData({ ...profileData, currentCity: value });
												}}
											/>
											{/* <AsyncSingleSelect
												name="current-city"
												placeholder="Select From List"
												options={(value) => getCityList(value)}
												value={profileData.currentCity}
												handleChange={(value) => {
													setProfileData({ ...profileData, currentCity: value });
												}}
											/> */}
											<ErrorMessage display={errors.currentCity} element="Bio Name" />
										</div>
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Candidate Email*"
												hint="Candidate Email"
											/>
											<Input
												type="text"
												className="form-control"
												value={profileData.emailId}
												name="candidateEmail"
												autoComplete="off"
												maxLength="100"
												placeholder="eg: google@gmail.com"
												onChange={(e) => {
													setProfileData(({
														...profileData,
														emailId: e.target.value,
													}));
													handleErrors();
													if (e.target.value?.length > 0) {
														setValidEmail(jobProfileContactInfoValidation(e.target.value, 1));
													} else {
														setValidEmail(true);
													}
												}}
											/>
											{!validEmail && (
												<p className="error error-text text-normal text-center mb-2">
													Email Id is Invalid!!!
												</p>
											)}
											<ErrorMessage display={errors.emailId} element="Email Id" />
										</div>
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Candidate Phone Number*"
												hint="Candidate Phone Number"
											/>
											<Input
												type="text"
												className="form-control"
												value={profileData.phoneNo}
												name="candidatePhoneNumber"
												autoComplete="off"
												placeholder="eg: 9900561230"
												maxLength="100"
												onChange={(e) => {
													setProfileData(({
														...profileData,
														phoneNo: e.target.value,
													}));
													handleErrors();
													if (e.target.value?.length > 0) {
														setValidPhoneNumber(jobProfileContactInfoValidation(e.target.value, 2));
													} else {
														setValidPhoneNumber(true);
													}
												}}
											/>
											{!validPhoneNumber && (
												<p className="error error-text text-normal text-center mb-2">
													Phone Number is Invalid!!!
												</p>
											)}
											<ErrorMessage display={errors.phoneNo} element="Phone Number" />
										</div>
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Existing Bio *"
												hint="Existing Bio"
											/>
											<textarea
												rows="3"
												className="global-form-control"
												name="bioName"
												placeholder="Existing Bio Of The Job"
												value={profileData.shortBio}
												onChange={(e) => {
													setProfileData(({
														...profileData,
														shortBio: e.target.value,
													}));
													handleErrors();
												}}
											/>
											<div style={{ textAlign: 'right', color: profileData.shortBio && profileData.shortBio.length > 500 ? '#aaa' : '#aaa' }}>
												{profileData && profileData.shortBio ? profileData.shortBio.length : 0}
											</div>
											<ErrorMessage display={errors.shortBio} element="Bio Name" />
										</div>
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Head Line *"
												hint="This is the head line of the job."
											/>
											<p className="m-0 longText">
												<textarea
													rows="3"
													className="global-form-control"
													name="Head Line"
													placeholder="Description/Summary Of The Job"
													value={profileData.headLine}
													onChange={(e) => {
														setProfileData(({
															...profileData,
															headLine: e.target.value,
														}));
														handleErrors();
													}}
													maxLength={50}
													onBlur={() => handleBlur('introduction')}
												/>
											</p>
											<div style={{ textAlign: 'right', color: profileData.headLine && profileData.headLine.length > 50 ? '#ff0000' : '#aaa' }}>
												{profileData && profileData.headLine ? profileData.headLine.length : 0}/50
											</div>
											<ErrorMessage display={errors.headLine} element="Introduction" />
										</div>
									</Col>
									<Col xs={12} sm={6}>
										<div className="pb-3">
											<TitleWithTooltip
												title="Detailed Bio *"
												hint="This is the Detailed Bio of the job."
											/>
											<textarea
												rows="3"
												className="global-form-control"
												name="detailedBio"
												placeholder="Detailed Bio of the job"
												value={profileData.detailedBio}
												onChange={(e) => {
													setProfileData(({
														...profileData,
														detailedBio: e.target.value,
													}));
													handleErrors();
												}}
												onBlur={() => handleBlur('detailedBio')}
											/>
											<div style={{ textAlign: 'right', color: profileData.detailedBio && profileData.detailedBio.length > 500 ? '#aaa' : '#aaa' }}>
												{profileData && profileData.detailedBio ? profileData.detailedBio.length : 0}
											</div>
											<ErrorMessage display={errors.detailedBio} element="Detailed Bio" />
										</div>
									</Col>
									<Col xs={12} sm={12} className="mt-2 text-center">
										<Button
											success
											no_radius
											type="submit"
											style={{ width: '150px' }}
										>
											<b>Save Changes</b>
										</Button>
									</Col>
									<Col xs={12} sm={12}>
										{actionLoading && <LoadingIcon />}
									</Col>
									<Col xs={12} sm={12}>
										{(actionError && actionMessage) && <p className="error error-text text-normal text-center mb-2">
											{actionMessage}
										</p>}
										{(!actionError && actionMessage) && <p className="success-text text-normal text-center mb-2">
											{actionMessage}
										</p>}
									</Col>
								</Row>
							</form>
						</div>
					</>
			}
		</div>
	);
};

ContactInfoAndProfileBioPage.propTypes = {
	// status: T.number,
	history: T.object,
	match: T.object,
};
export default ContactInfoAndProfileBioPage;
