import { Map } from 'immutable';
import {
	FETCH_CP_ITEMS,
	FETCH_CP_ITEMS_FAILURE,
	FETCH_CP_ITEMS_SUCCESS,
	FETCH_NEXT_PAGE,
	DISABLE_PROFILE_LIST_SEARCH,
	RUN_CP_LIST_SEARCH,
	RUN_CP_LIST_SEARCH_SUCCESS,
	RUN_CP_LIST_SORT,
	DISABLE_PROFILE_LIST_SEARCH_FLAG,
	CLEAR_PROFILE_DESK_DATA,
	UPDATE_CP_ITEM_STATUS_SUCCESS,
} from './type';

const initialState = Map({
	CPItems: [],
	loading: false,
	limit: 10,
	offset: 0,
	error: null,
	allCPItems: [],
	search: false,
	searchValue: null,
	searchType: null,
	sortValue: 'updatedAt',
});

function ProfileFundingListReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_CP_ITEMS: {
		if (state.get('offset') === 0) {
			return state
				.set('loading', true)
				.set('error', false)
				.set('allCPItems', [])
				.set('CPItems', []);
		}
		return state
			.set('loading', true)
			.set('error', false);
	}
	case FETCH_CP_ITEMS_SUCCESS: {
		let CPItems = state.get('allCPItems');
		CPItems = CPItems.concat(action.CPItems);
		return state
			.set('loading', false)
			.set('allCPItems', CPItems)
			.set('CPItems', action.CPItems);
	}
	case FETCH_CP_ITEMS_FAILURE:
		return state
			.set('loading', false)
			.set('error', action.error)
			.set('CPItems', null);
	case FETCH_NEXT_PAGE: {
		const offset = Number(state.get('offset'));
		return state
			.set('limit', action.limit)
			.set('offset', offset + action.limit);
	}
	case UPDATE_CP_ITEM_STATUS_SUCCESS: {
		const allCPItems = [...state.get('allCPItems')];
		allCPItems.forEach((element, index) => {
			if (element.id === action.id) {
				allCPItems[index].status = action.newStatus;
			}
		});
		return state.set('allCPItems', allCPItems);
	}

	case DISABLE_PROFILE_LIST_SEARCH:
		return state
			.set('allCPItems', [])
			.set('CPItems', [])
			.set('searchValue', null)
			.set('search', false)
			.set('offset', 0)
			.set('limit', 10)
			.set('searchType', null);
	case DISABLE_PROFILE_LIST_SEARCH_FLAG:
		return state
			.set('search', false);
	case RUN_CP_LIST_SEARCH: {
		if (state.get('search') && action.searchValue === state.get('searchValue')) {
			return state
				.set('searchType', action.searchType)
				.set('loading', true);
		}
		return state
			.set('allCPItems', [])
			.set('CPItems', [])
			.set('search', true)
			.set('searchValue', action.searchValue)
			.set('filterMonth', action.filterMonth)
			.set('filterYear', action.filterYear)
			.set('searchType', action.searchType)
			.set('loading', true)
			.set('offset', 0);
	}
	case RUN_CP_LIST_SEARCH_SUCCESS: {
		let CPItems = state.get('allCPItems');

		CPItems = CPItems.concat(action.searchResults);
		return state
			.set('CPItems', action.searchResults)
			.set('allCPItems', CPItems)
			.set('search', true)
			.set('loading', false);
	}
	case RUN_CP_LIST_SORT: {
		return state
			.set('allCPItems', [])
			.set('offset', 0)
			.set('CPItems', [])
			.set('loading', true)
			.set('sortValue', action.sortBy);
	}
	case CLEAR_PROFILE_DESK_DATA: {
		return initialState;
	}
	default: return state;
	}
}

export default ProfileFundingListReducer;
