import {
	call, put, takeLatest, select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { FETCH_ALL_REDIRECTIONS, CREATE_REDIRECTION, FILTER_REDIRECTION_TABLE } from './type';
import { REDIRECTION_BASE_API_URL } from './constants';
import {
	createRedirectionSuccess, createRedirectionFailure, setRedirectionFormMessage, fetchAllRedirectionsSuccess, fetchAllRedirectionsFailure, clearRedirectionForm, fetchAllRedirections, filterRedirectionTableFailure, filterRedirectionTableSuccess,
} from './actions';
import { makeSelectRedirectionTablePage } from './selectors';

export function* getAllRedirections(action) {
	const url = `${REDIRECTION_BASE_API_URL}/page/${action.page}`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			yield put(fetchAllRedirectionsSuccess(responseMessage));
		}
	} catch (err) {
		yield put(fetchAllRedirectionsFailure(err));
	}
}

export function* saveRedirection(action) {
	yield put(setRedirectionFormMessage('Saving'));
	const url = `${REDIRECTION_BASE_API_URL}/save`;
	const options = {
		method: 'POST',
		credentials: 'include',
		body: action.redirection,
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(createRedirectionSuccess(responseMessage));
				yield put(setRedirectionFormMessage('Saved'));
				const page = yield select(makeSelectRedirectionTablePage());
				yield put(fetchAllRedirections(page));
			} else {
				yield put(createRedirectionFailure(responseMessage));
				yield put(setRedirectionFormMessage('Error'));
			}
			yield call(delay);
			yield put(clearRedirectionForm());
			yield put(setRedirectionFormMessage(null));
		}
	} catch (err) {
		yield put(createRedirectionFailure(err));
	}
}

function delay() {
	return new Promise((resolve) => {
		setTimeout(() => resolve(), 3000);
	});
}

export function* filterTable(action) {
	const url = `${REDIRECTION_BASE_API_URL}/search`;
	const data = new FormData();
	data.append('filter', action.filter);
	data.append('limit', action.page);
	const options = {
		method: 'POST',
		credentials: 'include',
		body: data,
	};

	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const redirections = yield response.json();
			yield put(filterRedirectionTableSuccess(redirections));
		}
	} catch (err) {
		yield put(filterRedirectionTableFailure(err));
	}
}

export default function* redirectionData() {
	yield takeLatest(FETCH_ALL_REDIRECTIONS, getAllRedirections);
	yield takeLatest(CREATE_REDIRECTION, saveRedirection);
	yield takeLatest(FILTER_REDIRECTION_TABLE, filterTable);
}
