import {
	call, put, takeLatest, select,
} from 'redux-saga/effects';
import { USER_LOGOUT, FETCH_APP_DATA } from './type';
import { fetchAppDataFailure, fetchAppDataSuccess } from './actions';
// import { BASE_URL } from '../../utils/constants'; userLogoutError
import { BRAND_API_BASE_URL } from '../BrandManager/constants';
import { makeSelectLocation } from './selectors';
import { redirectTo } from '../../utils/accessRedirection';

const MY_ACCOUNT_BASE_URL = process.env.REACT_APP_SERVICE_PWA;
const USER_API_BASE_URL = '/api/v2/rw/users';
let CURRENT_LOCATION;

export function* getUserInfo() {
	const url = `${USER_API_BASE_URL}/`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const userInfo = yield response.json();
			return userInfo;
		}
	} catch (err) {
		throw new Error(err);
	}
}

export function* logUserOut() {
	yield put(window.location.assign(`${MY_ACCOUNT_BASE_URL}/logout`));
	// const url = `${USER_API_BASE_URL}/logout`;
	// const options = {
	// 	method: 'GET',
	// 	credentials: 'include',
	// };
	// try {
	// 	const response = yield call(fetch, url, options);
	// 	if (response.status === 200) {
	// 		// window.location.assign(`${MY_ACCOUNT_BASE_URL}/?redirect_url=${BASE_URL}`);
	// 		window.location.assign(`${MY_ACCOUNT_BASE_URL}`);
	// 	}
	// } catch (err) {
	// 	yield put(userLogoutError(err));
	// }
}

export function* getUserBrands() {
	const url = `${BRAND_API_BASE_URL}/userbrands`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if ([401, 403, 500].includes(response.status)) {
			yield put(redirectTo(response.status, CURRENT_LOCATION));
		} else {
			const brands = yield response.json();
			return brands;
		}
	} catch (err) {
		throw new Error(err);
	}
}

export function* getAppData() {
	try {
		const user = yield getUserInfo();
		const userBrands = yield getUserBrands();
		yield put(fetchAppDataSuccess(user, userBrands));
	} catch (err) {
		yield put(fetchAppDataFailure(err));
	}
}

export default function* userData() {
	const location = yield select(makeSelectLocation());
	CURRENT_LOCATION = location.pathname;
	yield takeLatest(USER_LOGOUT, logUserOut);
	yield takeLatest(FETCH_APP_DATA, getAppData);
}
