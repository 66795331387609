export const VERTICAL_API_BASE_URL = '/api/v2/rw/verticals';

export const VERTICAL_LOADING_MESSAGE = 'Loading...';
export const VERTICAL_CREATE_SUCCESS_MESSAGE = 'Vertical Saved Successfully';
export const VERTICAL_CREATE_LOAD_MESSAGE = 'Saving...';
export const VERTICAL_UPDATE_SUCCESS_MESSAGE = 'Vertical Updated Successfully';
export const VERTICAL_UPDATE_LOAD_MESSAGE = 'Updating...';
export const VERTICAL_DELETE_SUCCESS_MESSAGE = 'Vertical Deleted Successfully';
export const VERTICAL_DELETE_LOAD_MESSAGE = 'Deleting...';
export const VERTICAL_ERROR_MESSAGE = 'ERROR!!!';
export const VERTICAL_REPLACE_SUCCESS_MESSAGE = 'Vertical replaced successfully';
export const VERTICAL_REPLACE_LOAD_MESSAGE = 'Processing...';
export const VERTICAL_REDIRECTION_ERROR = 'Vertical redirection unsuccessful';
