import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { AWARD_API_BASE_URL } from './constants';
import {
	createAwardFailure, createAwardSuccess, awardSelect, updateAwardSuccess, deleteAwardSuccess, deleteAwardFailure, updateAwardFailure,
} from './actions';
import { CREATE_AWARD, DELETE_AWARD, UPDATE_AWARD } from './type';

export function* createNewAward(action) {
	const url = `${AWARD_API_BASE_URL}/`;
	const formData = action.award;
	const options = {
		method: 'POST',
		body: formData,
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(createAwardSuccess(responseMessage));
				yield put(awardSelect(null));
			} else {
				yield put(createAwardFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(createAwardFailure(err));
	}
}

export function* updateAward(action) {
	const url = `${AWARD_API_BASE_URL}/id/${action.id}`;
	const formData = action.updatedData;
	const options = {
		method: 'PUT',
		body: formData,
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(updateAwardSuccess(responseMessage));
				yield put(awardSelect(null));
			} else {
				yield put(updateAwardFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(updateAwardFailure(err));
	}
}

export function* deleteAward(action) {
	const url = `${AWARD_API_BASE_URL}/${action.id}`;
	const options = {
		method: 'DELETE',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(deleteAwardSuccess(responseMessage));
				yield put(awardSelect(null));
			} else {
				yield put(deleteAwardFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(deleteAwardFailure(err));
	}
}

export default function* awardsData() {
	yield takeLatest(CREATE_AWARD, createNewAward);
	yield takeLatest(UPDATE_AWARD, updateAward);
	yield takeLatest(DELETE_AWARD, deleteAward);
}
