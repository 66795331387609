import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	setLoadingState, setErrorState, setAPIState, setSearchModal, setTabDataState,
} from './actions';
import { GET_API_STATE, GET_SEARCH_STORIES, GET_SERIES_DATA_BY_SLUG } from './type';

const UTIL = require('../../utils/utility_function');

function* getAPIData() {
	try {
		const options = {
			credentials: 'include',
		};

		yield put(setLoadingState({ status: true, type: 'RESPONSE' }));

		const response = yield call(fetch, '/api/v2/rw/series/create', options);

		if ((response.status === 401) || (response.status === 403)) {
			yield put(push('/unauthorized'));
		} else if (response.status === 200) {
			const responseJson = yield response.json();
			yield put(setAPIState(responseJson));
		} else {
			yield put(setErrorState({ status: true, type: 'RESPONSE', message: 'Could not load API data' }));
		}

		yield put(setLoadingState({ status: false, type: null }));
	} catch (err) {
		console.log('ERROR IN SeriesCreate', err);
		yield put(setLoadingState({ status: false, type: null }));
		yield put(setErrorState({ status: true, type: 'RESPONSE', message: err.message }));
	}
}

function* getStoriesByTitle({
	searchList, title, offset = 0, loadMore, notIn,
}) {
	try {
		const options = {
			credentials: 'include',
			headers: new Headers({ 'Content-Type': 'application/json' }),
			method: 'POST',
			body: JSON.stringify({
				offset,
				title,
				limit: 10,
				notIn: JSON.stringify(notIn),
			}),
		};

		yield put(setSearchModal({ loading: true }));

		const response = yield call(fetch, '/api/v2/rw/allstories/search', options);

		if ((response.status === 401) || (response.status === 403)) {
			yield put(push('/unauthorized'));
		} else if (response.status === 200) {
			const responseJson = yield response.json();
			if (loadMore && UTIL.checkIfArray(searchList)) {
				const stateObj = { offset, searchList: searchList.concat(responseJson.stories) };
				if (('length' in responseJson.stories) && (responseJson.stories.length < 10)) {
					stateObj.end = true;
					if (searchList.length > 0) {
						yield put(setSearchModal(stateObj));
					} else {
						stateObj.selected = null;
						yield put(setSearchModal(stateObj));
					}
				} else {
					stateObj.end = false;
					if (searchList.length > 0) {
						yield put(setSearchModal(stateObj));
					} else {
						stateObj.selected = null;
						yield put(setSearchModal(stateObj));
					}
				}
			} else if (('length' in responseJson.stories) && (responseJson.stories.length < 10)) {
				yield put(setSearchModal({ offset, searchList: responseJson.stories, end: true }));
			} else {
				yield put(setSearchModal({
					offset, searchList: responseJson.stories, end: false, selected: null,
				}));
			}
		} else {
			yield put(setErrorState({ status: true, type: 'RESPONSE', message: 'Could not load stories search API data' }));
		}

		yield put(setSearchModal({ loading: false }));
	} catch (err) {
		console.log('ERROR IN getStoriesByTitle IN SERIES-CREATE', err);
		yield put(setSearchModal({ loading: false }));
		yield put(setErrorState({ status: true, type: 'RESPONSE', message: err.message }));
	}
}

function* getSeriesDataBySlug(action) {
	try {
		const options = {
			credentials: 'include',
		};

		yield put(setLoadingState({ status: true, type: 'PAGE' }));

		const response = yield call(fetch, `/api/v2/rw/series/${action.value}`, options);

		if ((response.status === 401) || (response.status === 403)) {
			yield put(push('/unauthorized'));
		} else if (response.status === 200) {
			const responseJson = yield response.json();
			const { data } = responseJson;
			const compose = {
				brand: data.brand,
				title: data.title,
				description: data.description,
				plainText: data.plainText,
				slug: data.slug,
				contentUrl: data.contentUrl,
				thumbnail: data.thumbnail,
				status: data.status,
				trailer: data.trailer ? data.trailer : {},
				curatedTags: data.curatedTags ? data.curatedTags : [],
				banner: data.banner ? data.banner : {},
				isRegistrationRequired: !!data.isRegistrationRequired,
			};
			const finalize = {
				brandSpotlightSponsors: data.brandSpotlightSponsors,
				facebook: data.facebook,
				twitter: data.twitter,
				search: data.search,
			};
			const { options } = data;
			if (options.cta.title || options.cta.link) {
				options.cta.enable = true;
			}

			if (!(data.title && data.brand && data.slug)) {
				yield put(setErrorState({ status: true, type: 'RESPONSE', message: 'Failed to load series data' }));
			} else {
				yield put(setTabDataState({ compose, finalize, options }));
				const listHash = data.list && data.list.length ? data.list.reduce((acc, val) => {
					acc[val.id] = { existingValue: true, currentValue: true };
					return acc;
				}, {}) : {};
				yield put(setSearchModal({ list: data.list, listHash }));
			}
		} else {
			yield put(setErrorState({ status: true, type: 'RESPONSE', message: 'Failed to load series data' }));
		}

		yield put(setLoadingState({ status: false, type: null }));
	} catch (err) {
		console.log('ERROR IN getSeriesDataBySlug IN SERIES-CREATE', err);
		yield put(setLoadingState({ status: false, type: null }));
		yield put(setErrorState({ status: true, type: 'RESPONSE', message: err.message }));
	}
}

export default function* trackSeriesManagerDispatch() {
	yield takeLatest(GET_API_STATE, getAPIData);
	yield takeLatest(GET_SEARCH_STORIES, getStoriesByTitle);
	yield takeLatest(GET_SERIES_DATA_BY_SLUG, getSeriesDataBySlug);
}
