import React, { useState } from 'react';
import styled from 'styled-components';
import T from 'prop-types';
import { Spacer, Input, Button } from '../Styles';

const Card = styled.div`
		background-color: #efefef;
		padding: 15px;
		border-radius: 5px;
		width: 400px;
`;

const PreviewEditBlock = (props) => {
	const {
		title, excerpt, type, updateFormMetadata, close, handleBlur, reflectOnAll,
	} = props;
	const [titleValue, setTitle] = useState(title);
	const [excerptValue, setExcerpt] = useState(excerpt);

	function onSave() {
		let changedData = {};
		if (reflectOnAll) {
			const types = ['twitter', 'facebook', 'search'];
			types.forEach((type) => {
				changedData = {
					name: 'socialPreview',
					titleKey: `${type}Title`,
					title: titleValue,
					excerptKey: `${type}Excerpt`,
					excerpt: excerptValue,
				};
				updateFormMetadata(changedData);
			});
		} else {
			changedData = {
				name: 'socialPreview',
				titleKey: `${type}Title`,
				title: titleValue,
				excerptKey: `${type}Excerpt`,
				excerpt: excerptValue,
			};
			updateFormMetadata(changedData);
		}
		close();
	}

	function onDiscard() {
		setTitle(title || '');
		setExcerpt(excerpt || '');
	}

	return (
		<Card onBlur={handleBlur}>
			<p className="mb-0"><b>Title</b></p>
			<Input
				type="text"
				className="form-control brr-0"
				value={titleValue || ''}
				name="title"
				autoComplete="off"
				placeholder="Title"
				onChange={(e) => setTitle(e.target.value)}
			/>
			<p className="mt-2 mb-0"><b>Excerpt</b></p>
			<textarea
				type="text"
				className="global-form-control border brr-0 p-1"
				rows={4}
				placeholder="Excerpt (Maximum 300 characters)"
				name="excerpt"
				value={excerptValue || ''}
				onChange={(e) => setExcerpt(e.target.value)}
				autoComplete="off"
				maxLength="300"
			/>
			<Spacer height={0.5} />
			<Button success onClick={onSave} className="mr-3">Save</Button>
			<Button secondary onClick={onDiscard}>Discard</Button>
		</Card>
	);
};

PreviewEditBlock.propTypes = {
	title: T.string,
	excerpt: T.string,
	type: T.string,
	updateFormMetadata: T.func,
	close: T.func,
	handleBlur: T.func,
	reflectOnAll: T.bool,
};

export default PreviewEditBlock;
