import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import { ListItemWrapper } from '../../components/CompanyEditorComponents/styles';

const IPOListItem = (props) => {
	const { ipoItem, onItemSelectAction } = props;
	const {
		company, lotSize, exchange, open, close, issuePrice, issueSize, status,
	} = ipoItem;
	return (
		<ListItemWrapper style={{ minHeight: '3rem' }}>
			<Col sm={2} xs={4}>
				<span>{company}</span>
			</Col>
			<Col sm={1} xs={2}>
				<span>{lotSize}</span>
			</Col>
			<Col sm={1} xs={2}>
				<span>{exchange}</span>
			</Col>
			<Col sm={1} xs={2}>
				<span>{issuePrice}</span>
			</Col>
			<Col sm={1} xs={2}>
				<span>₹{issueSize}</span>
			</Col>
			<Col sm={1} xs={2}>
				<span>{new Date(open).toLocaleDateString('en-IN')}</span>
			</Col>
			<Col sm={1} xs={2}>
				<span>{new Date(close).toLocaleDateString('en-IN')}</span>
			</Col>
			<Col xs={1} sm={1} md={2}>
				<select className="global-form-control" value={status} onChange={(e) => onItemSelectAction(e, ipoItem)}>
					<option value="none" selected disabled hidden>{status}</option>
					<option value="UNUSED">UNUSED</option>
					<option value="USED">USED</option>
				</select>
			</Col>
		</ListItemWrapper>
	);
};

IPOListItem.propTypes = {
	lotSize: T.string,
	open: T.string,
	ipoItem: T.object,
	company: T.string,
	close: T.string,
	issuePrice: T.string,
	issueSize: T.string,
	onItemSelectAction: T.func,
	status: T.string,
};

export default IPOListItem;
