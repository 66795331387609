import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { SEND_NOTIFICATION, CREATE_NOTIFICATION } from './type';
import {
	sendNotificationSuccess, sendNotificationError, createNotificationSuccess, createNotificationError, createNotification,
} from './actions';
import { NOTIFICATIONS_API_BASE_URL } from './constants';

export function* sendNotfication(action) {
	const url = `${NOTIFICATIONS_API_BASE_URL}/send`;
	const { payload } = action;
	const options = {
		method: 'POST',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(payload),
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const result = yield response.json();
			yield put(sendNotificationSuccess(result));
			yield put(createNotification(action.payload));
		}
	} catch (err) {
		yield put(sendNotificationError(err));
	}
}

export function* createNotificationRow(action) {
	const url = `${NOTIFICATIONS_API_BASE_URL}/save`;
	const sent = new Date().toISOString();
	const formData = new FormData();
	formData.append('title', action.notification.title);
	formData.append('body', action.notification.body);
	formData.append('cta', action.notification.click_action);
	formData.append('topic', action.notification.topic);
	formData.append('sent_at', sent);
	const options = {
		method: 'POST',
		credentials: 'include',
		body: formData,
	};

	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response === 403) {
			yield put(push('/unauthorized'));
		} else {
			const result = yield response.json();
			if (result.success) {
				yield put(createNotificationSuccess(result));
			} else {
				yield put(createNotificationError(response));
			}
		}
	} catch (err) {
		yield put(createNotificationError(err));
	}
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* notificationsManagerData() {
	yield takeLatest(SEND_NOTIFICATION, sendNotfication);
	yield takeLatest(CREATE_NOTIFICATION, createNotificationRow);
}
