export const REDEMPTION_DONE_LIST_BASE_URL = '/api/v2/rw/captable/giftCode/claims';
export const REDEMPTION_DONE_LIST_STATUS_BASE_URL = '/api/v2/rw/captable/giftCode/claims';

export const FILTER_REDEMPTION_DONE_LIST = 'FILTER_REDEMPTION_DONE_LIST';
export const FETCH_REDEMPTION_DONE_LIST_SUCCESS = 'FETCH_REDEMPTION_DONE_LIST_SUCCESS';
export const FETCH_REDEMPTION_DONE_LIST_FAILURE = 'FETCH_REDEMPTION_DONE_LIST_FAILURE';
export const FETCH_REDEMPTION_DONE_LIST = 'FETCH_REDEMPTION_DONE_LIST';
export const FILTER_REDEMPTION_DONE_LIST_SUCCESS = 'FILTER_REDEMPTION_DONE_LIST_SUCCESS';
export const FILTER_REDEMPTION_DONE_LIST_FAILURE = 'FILTER_REDEMPTION_DONE_LIST_FAILURE';
export const SET_REDEMPTION_DONE_LIST_PAGE = 'SET_REDEMPTION_DONE_LIST_PAGE';
export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
export const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
export const UPDATE_ACTIONS_ERROR = 'UPDATE_ACTIONS_ERROR';
export const UPDATE_ACTIONS_SUCCESS = 'UPDATE_ACTIONS_SUCCESS';
export const UPDATE_ACTIONS_LOADER = 'UPDATE_ACTIONS_LOADER';
export const SET_ACTIONS_MESSAGE = 'SET_ACTIONS_MESSAGE';
export const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
export const SET_ELEMENTS_BLURRED = 'SET_ELEMENTS_BLURRED';
export const SAVING_MESSAGE = { intent: 'info', message: 'Saving data...' };
export const SAVING_SUCCESS_MESSAGE = { intent: 'success', message: 'Data Saved Successfully' };
export const SAVING_FAILURE_MESSAGE = { intent: 'danger', message: 'Data Save Failed. Something went wrong. Try Again!' };
