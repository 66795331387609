import { Map } from 'immutable';
import {
	FETCH_ALL_FEED, FETCH_ALL_FEED_SUCCESS, FETCH_ALL_FEED_ERROR, CREATE_FEED, CREATE_FEED_SUCCESS, CREATE_FEED_ERROR, UPDATE_FEED, UPDATE_FEED_ERROR, DELETE_FEED_SUCCESS, DELETE_FEED_ERROR, DELETE_FEED, FEED_ELEMENT_ON_CHANGE, UNMOUNT_FEED_FORM_MESSAGE, UPDATE_FEED_SUCCESS, ADD_NEW_FEED, ON_FEED_SELECTED,
} from './type';
import {
	CREATE_FEED_LOAD_MESSAGE, CREATE_FEED_SUCCESS_MESSAGE, FEED_ERROR_MESSAGE, UPDATE_FEED_LOAD_MESSAGE, UPDATE_FEED_SUCCESS_MESSAGE, DELETE_FEED_LOAD_MESSAGE, DELETE_FEED_SUCCESS_MESSAGE,
} from './constants';

const SELECT_FEED_TITLE = 'SELECT FEED';

const initialState = Map({
	loading: false,
	error: false,
	allFeeds: null,
	selectedFeed: null,
	formData: {
		title: '',
		feedUrl: '',
		description: '',
		logoUrl: '',
	},
	addFeed: false,
	editFeed: false,
	dropdownTitle: SELECT_FEED_TITLE,
	formMessage: null,
});

function feedCreatorReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_ALL_FEED:
		return state
			.set('loading', true)
			.set('error', false)
			.set('addFeed', false)
			.set('editFeed', false)
			.set('dropdownTitle', SELECT_FEED_TITLE);
	case FETCH_ALL_FEED_SUCCESS:
		return state
			.set('loading', false)
			.set('error', false)
			.set('addFeed', false)
			.set('editFeed', false)
			.set('dropdownTitle', SELECT_FEED_TITLE)
			.set('allFeeds', action.feeds);
	case FETCH_ALL_FEED_ERROR:
		return state
			.set('loading', false)
			.set('error', action.error)
			.set('addFeed', false)
			.set('editFeed', false);
	case ON_FEED_SELECTED: {
		const selected = action.selectedFeed.name;
		if (selected === SELECT_FEED_TITLE) {
			const form = {
				title: '',
				feedUrl: '',
				description: '',
				logoUrl: '',
			};
			return state
				.set('loading', false)
				.set('error', false)
				.set('addFeed', false)
				.set('editFeed', false)
				.set('selectedFeed', null)
				.set('formData', form);
		}
		const form = {
			title: action.selectedFeed.title,
			feedUrl: action.selectedFeed.feedUrl,
			description: action.selectedFeed.description,
			logoUrl: action.selectedFeed.logoUrl,
		};
		return state
			.set('selectedFeed', action.selectedFeed)
			.set('formData', form)
			.set('editFeed', true)
			.set('addFeed', false)
			.set('formMessage', null);
	}
	case ADD_NEW_FEED: {
		const form = {
			title: '',
			feedUrl: '',
			description: '',
			logoUrl: '',
		};
		return state
			.set('addFeed', true)
			.set('editFeed', false)
			.set('selectedFeed', null)
			.set('formData', form)
			.set('formMessage', null);
	}
	case CREATE_FEED:
		return state
			.set('formMessage', CREATE_FEED_LOAD_MESSAGE);
	case CREATE_FEED_SUCCESS:
		return state
			.set('formMessage', CREATE_FEED_SUCCESS_MESSAGE);
	case CREATE_FEED_ERROR:
		return state
			.set('formMessage', FEED_ERROR_MESSAGE);
	case UPDATE_FEED:
		return state
			.set('formMessage', UPDATE_FEED_LOAD_MESSAGE);
	case UPDATE_FEED_SUCCESS:
		return state
			.set('formMessage', UPDATE_FEED_SUCCESS_MESSAGE)
			.set('selectedFeed', null);
	case UPDATE_FEED_ERROR:
		return state
			.set('formMessage', FEED_ERROR_MESSAGE);
	case DELETE_FEED:
		return state
			.set('formMessage', DELETE_FEED_LOAD_MESSAGE);
	case DELETE_FEED_SUCCESS:
		return state
			.set('formMessage', DELETE_FEED_SUCCESS_MESSAGE);
	case DELETE_FEED_ERROR:
		return state
			.set('formMessage', FEED_ERROR_MESSAGE);
	case FEED_ELEMENT_ON_CHANGE: {
		const newState = { ...state.get('formData') };
		newState[action.changedData.name] = action.changedData.value;
		return state
			.set('formData', newState);
	}
	case UNMOUNT_FEED_FORM_MESSAGE:
		return state
			.set('formMessage', null);
	default:
		return state;
	}
}

export default feedCreatorReducer;
