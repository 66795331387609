import { createSelector } from 'reselect';

const jobEditor = (state) => state.get('jobEditor');

const selectJobFormData = () => createSelector(
	jobEditor,
	(editorState) => editorState.get('formData'),
);

const selectJobFormMessage = () => createSelector(
	jobEditor,
	(editorState) => editorState.get('formMessage'),
);

const selectShowConfirmationModal = () => createSelector(
	jobEditor,
	(editorState) => editorState.get('showConfirmationModal'),
);

const selectFormTouched = () => createSelector(
	jobEditor,
	(editorState) => editorState.get('formTouched'),
);

const selectHasErrors = () => createSelector(
	jobEditor,
	(editorState) => editorState.get('hasErrors'),
);

const selectJobDocConversion = () => createSelector(
	jobEditor,
	(editorState) => editorState.get('docConversion'),
);

export {
	selectJobFormData,
	selectJobFormMessage,
	selectShowConfirmationModal,
	selectFormTouched,
	selectHasErrors,
	selectJobDocConversion,
};
