import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	setLoadingState, setErrorState, setSeriesData, setYSTVSeriesData,
} from './actions';
import {
	GET_SERIES_DATA,
	GET_YSTV_SERIES_DATA,
	PUT_YSTV_SERIES_DATA,
} from './type';

function* getSeriesData() {
	try {
		const options = {
			credentials: 'include',
		};

		yield put(setLoadingState({ status: true, type: 'RESPONSE' }));

		const response = yield call(fetch, '/api/v2/rw/series', options);

		if (response.status === 404) {
			yield put(setErrorState({ status: true, type: 'RESPONSE', message: 'Could not load series data' }));
		} else if ((response.status === 401) || (response.status === 403)) {
			yield put(push('/unauthorized'));
		} else if (response.status === 200) {
			const responseJson = yield response.json();
			yield put(setSeriesData(responseJson.data));
		}

		yield put(setLoadingState({ status: false, type: null }));
	} catch (err) {
		yield put(setErrorState({ status: true, type: 'RESPONSE', message: err.message }));
	}
}

function* getYSTVSeriesData() {
	try {
		const options = {
			credentials: 'include',
		};

		yield put(setLoadingState({ status: true, type: 'RESPONSE' }));
		// https://studio-preprod.yourstory.com/api/v2/rw/curated/config/ystv_landing_page_sections?brand=2,
		// api/v2/curated_set?key=ystv_landing_page_sections&brand=yourstory
		const response = yield call(fetch, 'api/v2/rw/curated/config/ystv_landing_page_sections?brand=2', options);
		if (response.status === 404) {
			yield put(setErrorState({ status: true, type: 'RESPONSE', message: 'Could not load series data' }));
		} else if ((response.status === 401) || (response.status === 403)) {
			yield put(push('/unauthorized'));
		} else if (response.status === 200) {
			const responseJson = yield response.json();
			yield put(setYSTVSeriesData(responseJson));
		}

		yield put(setLoadingState({ status: false, type: null }));
	} catch (err) {
		yield put(setErrorState({ status: true, type: 'RESPONSE', message: err.message }));
	}
}
function* changeYSTVSeriesDataPosition(action) {
	const apiReqData = {
		value: [...action.value],
	};
	try {
		const options = {
			credentials: 'include',
			method: 'PUT',
			body: JSON.stringify(apiReqData.value),
			headers: { 'Content-Type': 'application/json' },
		};
		yield put(setLoadingState({ status: true, type: 'RESPONSE' }));

		const response = yield call(fetch, 'api/v2/rw/curated/config/ystv_landing_page_sections/update', options);
		if (response.status === 404) {
			yield put(setErrorState({ status: true, type: 'RESPONSE', message: 'Could not load series data' }));
		} else if ((response.status === 401) || (response.status === 403)) {
			yield put(push('/unauthorized'));
		} else if (response.status === 200) {
			yield getYSTVSeriesData();
		}

		yield put(setLoadingState({ status: false, type: null }));
	} catch (err) {
		yield put(setErrorState({ status: true, type: 'RESPONSE', message: err.message }));
	}
}

export default function* trackSeriesManagerDispatch() {
	yield takeLatest(GET_SERIES_DATA, getSeriesData);
	yield takeLatest(GET_YSTV_SERIES_DATA, getYSTVSeriesData);
	yield takeLatest(PUT_YSTV_SERIES_DATA, changeYSTVSeriesDataPosition);
}
