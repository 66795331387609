export const FETCH_EDITOR_GALLERY_MEDIA = 'FETCH_EDITOR_GALLERY_MEDIA';
export const FETCH_EDITOR_GALLERY_MEDIA_SUCCESS = 'FETCH_EDITOR_GALLERY_MEDIA_SUCCESS';
export const FETCH_EDITOR_GALLERY_MEDIA_FAILURE = 'FETCH_EDITOR_GALLERY_MEDIA_FAILURE';

export const SET_EDITOR_GALLERY_PAGE_NO = 'SET_EDITOR_GALLERY_PAGE_NO';
export const SET_EDITOR_GALLERY_MEDIA_TYPE = 'SET_EDITOR_GALLERY_MEDIA_TYPE';
export const SORT_EDITOR_GALLERY_MEDIA_BY = 'SORT_EDITOR_GALLERY_MEDIA_BY';

export const SET_EDITOR_GALLERY_SEARCH_VALUE = 'SET_EDITOR_GALLERY_SEARCH_VALUE';
export const FILTER_EDITOR_GALLERY_MEDIA = 'FILTER_EDITOR_GALLERY_MEDIA';
export const FILTER_EDITOR_GALLERY_MEDIA_SUCCESS = 'FILTER_EDITOR_GALLERY_MEDIA_SUCCESS';
export const FILTER_EDITOR_GALLERY_MEDIA_FAILURE = 'FILTER_EDITOR_GALLERY_MEDIA_FAILURE';

export const SET_EDITOR_GALLERY_UPDATED_TIME = 'SET_EDITOR_GALLERY_UPDATED_TIME';
export const SET_EDITOR_GALLERY_FETCH_BY = 'SET_EDITOR_GALLERY_FETCH_BY';
export const SET_EDITOR_GALLERY_FETCH_BY_VALUE = 'SET_EDITOR_GALLERY_FETCH_BY_VALUE';
export const CLEAR_EDITOR_GALLERY_DATA = 'CLEAR_EDITOR_GALLERY_DATA';
