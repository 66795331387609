import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import ImagePlaceholderIcon from '../../images/image_placeholder.png';
import { Button } from '../Styles';
import closeSvg from '../../images/close.svg';

const ImagePlaceholder = styled.div`
		background-color: #efefef;
		height: 200px;
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		align-items: center;
		cursor: pointer;
		border-radius: ${(props) => (props.upperRounded ? '24px 24px 0px 0px' : '0px')}
		${(props) => props.dimension && css`
				height: ${props.dimension.height};
				width: ${props.dimension.width};
		`}
`;

const Image = styled.img`
		height: ${(props) => (props.mediaImages ? 'auto' : '200px')};
		width: 100%;
		cursor: pointer;
		border-radius: ${(props) => (props.upperRounded ? '24px 24px 0px 0px' : '0px')}
		${(props) => props.dimension && css`
				height: ${props.dimension.height};
				width: ${props.dimension.width};
		`}
		:hover {
				opacity: 0.6;
		}
`;

const ImageIcon = styled.img`
		height: 50px;
		width: 50px;
`;

const ImageHelpText = styled.div`
		margin-top: 5px;
		font-size: 0.8rem;
		color: #000;
`;

const CloseButton = styled.img`
		position: absolute;
		top: 5%;
		right: 5%;
		filter: brightness(0.5);
		:hover {
				opacity: 0.6;
		}`;

const Container = styled.div`
		position: relative;
		${(props) => props.dimension && css`
				height: ${props.dimension.height};
				width: ${props.dimension.width};
		`}
`;
const ImageHolder = (props) => {
	function getHelpText() {
		return <ImageHelpText>Click to select or upload image</ImageHelpText>;
	}

	function getImageElement() {
		let element = null;
		const {
			showImageUploader, upperRounded, imageUrl, onImageLoad, squareDimension, clearImage, portrait, mediaImages,
		} = props;
		const dimension = { height: portrait ? `${(16 * 150) / 9}px` : squareDimension, width: portrait ? '100%' : squareDimension };
		if (imageUrl) {
			element = <Container dimension={dimension}>
				<Image upperRounded={upperRounded} src={imageUrl} onLoad={onImageLoad} dimension={dimension} mediaImages={mediaImages} />
				{clearImage ? <CloseButton
					className="close-btn"
					src={closeSvg}
					alt="close"
					width="20"
					height="20"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						clearImage();
					}}
				/> : null}
			</Container>;
		} else {
			element = (<ImagePlaceholder upperRounded={upperRounded} dimension={dimension}>
				<ImageIcon src={ImagePlaceholderIcon} />
				{getHelpText()}
			</ImagePlaceholder>);
		}

		return (<>
			<Button
				w_100
				className="b-0 m-0 p-0"
				onClick={showImageUploader}
				disabled={props.disabled}
			>
				{element}
			</Button>
		</>);
	}
	return getImageElement();
};

ImageHolder.propTypes = {
	imageUrl: PropTypes.string,
	upperRounded: PropTypes.bool,
	showImageUploader: PropTypes.func,
	onImageLoad: PropTypes.func,
	disabled: PropTypes.bool,
};

ImageHolder.defaultProps = {
	disabled: false,
};

export default ImageHolder;
