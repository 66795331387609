import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../Responsive';
import { Button, DropDown } from '../Styles';
import messages from './messages';
import { CATEGORY_API_BASE_URL } from '../../containers/CategoryManager/constants';
import { TAG_API_BASE_URL } from '../../containers/TagManager/constants';
import { BRAND_SPOTLIGHT_API_BASE_URL } from '../../containers/BrandSpotlightManager/constants';
import { VERTICAL_API_BASE_URL } from '../../containers/VerticalManager/constants';
import { AWARD_API_BASE_URL } from '../../containers/AwardManager/constants';
import { PEOPLE_API_BASE_URL } from '../../containers/PeopleManager/constants';
import { CITY_API_BASE_URL } from '../../containers/CityManager/constants';

class TopicManagerHeader extends React.PureComponent { //	eslint-disable-line react/prefer-stateless-function
	constructor(props) {
		super(props);
		this.onItemSelect = this.onItemSelect.bind(this);
		this.displayCaption = this.displayCaption.bind(this);
		this.renderDropdownItem = this.renderDropdownItem.bind(this);
		this.getOptions = this.getOptions.bind(this);
		this.state = {
		};
	}

	onItemSelect(e) {
		const item = e.target.value;
		this.props.selectedItem(JSON.parse(item));
	}

	async getOptions(inputValue, callback) {
		if (/[/\\]/.test(inputValue) || inputValue.length < 3) return [];
		try {
			let { hoc } = this.props;
			const { locale } = this.props;
			let url;

			if (locale === 'the-captable' && ['tag', 'category'].includes(hoc)) {
				hoc = hoc === 'tag' ? 'brandTag' : 'brandCategory';
			}
			if (hoc === 'category') {
				url = `${CATEGORY_API_BASE_URL}/locale/${locale}/${inputValue}`;
			}
			if (hoc === 'tag') {
				url = `${TAG_API_BASE_URL}/locale/${locale}/${inputValue}`;
			}
			if (hoc === 'brandSpotlight') {
				url = `${BRAND_SPOTLIGHT_API_BASE_URL}/key/${inputValue}`;
			}
			if (hoc === 'vertical') {
				url = `${VERTICAL_API_BASE_URL}/key/${inputValue}`;
			}
			if (hoc === 'award') {
				url = `${AWARD_API_BASE_URL}/key/manager/${inputValue}`;
			}
			if (hoc === 'people') {
				url = `${PEOPLE_API_BASE_URL}/key/${inputValue}?source=manager`;
			}
			if (hoc === 'city') {
				url = `${CITY_API_BASE_URL}/key/${inputValue}`;
			}
			if (hoc === 'brandTag') {
				url = `${TAG_API_BASE_URL}/key/the-captable/${inputValue}`;
			}
			if (hoc === 'brandCategory') {
				url = `${CATEGORY_API_BASE_URL}/key/the-captable/${inputValue}`;
			}
			const options = {
				method: 'GET',
				credentials: 'include',
			};

			const apiResponse = await fetch(url, options);
			const selectOptions = await apiResponse.json();
			callback(selectOptions);
		} catch (err) {
			console.log('Options error: ', err);
		}
	}

	getAyncSelectPlaceholder() {
		const { hoc } = this.props;
		switch (hoc) {
		case 'category':
			return <FormattedMessage {...messages.categorySelectPlaceholder} />;
		case 'tag':
			return <FormattedMessage {...messages.tagSelectPlaceholder} />;
		case 'brandSpotlight':
			return <FormattedMessage {...messages.brandSpotlightSelectPlaceholder} />;
		case 'vertical':
			return <FormattedMessage {...messages.verticalSelectPlaceholder} />;
		case 'award':
			return <FormattedMessage {...messages.awardSelectPlaceholder} />;
		case 'people':
			return <FormattedMessage {...messages.peopleSelectPlaceholder} />;
		case 'city':
			return <FormattedMessage {...messages.citySelectPlaceholder} />;
		default:
			return null;
		}
	}

	displayCaption() {
		const { hoc } = this.props;
		switch (hoc) {
		case 'category':
			return <FormattedMessage {...messages.categoriesCaption} />;
		case 'tag':
			return <FormattedMessage {...messages.tagsCaption} />;
		case 'brand':
			return <FormattedMessage {...messages.brandsCaption} />;
		case 'brandSpotlight':
			return <FormattedMessage {...messages.brandSpotlightCaption} />;
		case 'dynamic_config':
			return <FormattedMessage {...messages.configCaption} />;
		case 'rssFeedCreator':
			return <FormattedMessage {...messages.rssFeedCreatorCaption} />;
		case 'vertical':
			return <FormattedMessage {...messages.verticalCaption} />;
		case 'award':
			return <FormattedMessage {...messages.awardCaption} />;
		case 'people':
			return <FormattedMessage {...messages.peopleCaption} />;
		case 'city':
			return <FormattedMessage {...messages.cityCaption} />;
		default:
			return null;
		}
	}

	renderDropdownItem() {
		const { hoc, allItems } = this.props;
		let DropDownItem;
		switch (hoc) {
		case 'brand':
			DropDownItem = allItems ? allItems.map((item) => <option key={item.id} value={JSON.stringify(item)}>{item.name}</option>) : null;
			return DropDownItem;
		case 'rssFeedCreator':
			DropDownItem = allItems ? allItems.map((item) => <option key={item.id} value={JSON.stringify(item)}>{item.title}</option>) : null;
			return DropDownItem;
		case 'dynamic_config':
			// eslint-disable-next-line react/no-array-index-key
			DropDownItem = allItems ? allItems.map((item, index) => <option key={index + 1} value={JSON.stringify(item)}>{item}</option>) : null;
			return DropDownItem;
		default:
			return null;
		}
	}

	render() {
		const { hoc, itemSelected } = this.props;
		return (
			<Row middle="xs" className="mt-4">
				<Col md={8} sm={8} xs={12}>
					<Col md={8} sm={10} className="pl-0">
						{this.displayCaption()}
					</Col>
					<Col className="mt-3 pl-0" xs={8} md={5}>
						{
							['category', 'tag', 'brandSpotlight', 'vertical', 'award', 'people', 'city'].includes(hoc)
								? <AsyncSelect
										isClearable
										loadOptions={this.getOptions}
										placeholder={this.getAyncSelectPlaceholder()}
										onChange={this.props.selectedItem}
										value={this.props.title ? this.props.title : null}
								/>
								: <DropDown id="dropdown" onChange={(e) => this.onItemSelect(e)}>
									<option
										value={JSON.stringify({ name: this.props.title })}
										selected={itemSelected === false ? 'selected' : ''}
									>
										{this.props.title}
									</option>
									{this.renderDropdownItem()}
								</DropDown>
						}
					</Col>
				</Col>
				{
					hoc !== 'dynamic_config'
						? <Col md={4} sm={4} xs={12}>
							<Col md={8} xs={8} className="pt-sm-4">
								<Button
									id="add-new-btn"
									primary
									btn_rounded
									w_100
									onClick={this.props.addNew}
								>
									ADD NEW
								</Button>
							</Col>
						</Col>
						: null
				}
			</Row>
		);
	}
}

TopicManagerHeader.propTypes = {
	allItems: PropTypes.array,
	selectedItem: PropTypes.func,
	addNew: PropTypes.func,
	title: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	hoc: PropTypes.string,
	itemSelected: PropTypes.bool,
	selectedBrand: PropTypes.object,
	locale: PropTypes.string,
};

export default TopicManagerHeader;
