const monthNames = [
	'Jan', 'Feb', 'Mar',
	'Apr', 'May', 'June', 'July',
	'Aug', 'Sep', 'Oct',
	'Nov', 'Dec',
];

exports.getFullDate = (date) => {
	try {
		const date1 = new Date(date);
		const day = date1.getDate();
		const monthIndex = date1.getMonth();
		const year = date1.getFullYear();

		return `${day} ${monthNames[monthIndex]} ${year}`;
	} catch (err) {
		return date;
	}
};

exports.getFormatedDateWithTime = (date) => {
	let hours = date.getHours();
	let minutes = date.getMinutes();
	const ampm = hours >= 12 ? 'pm' : 'am';
	hours %= 12;
	hours = hours || 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? `0${minutes}` : minutes;
	const strTime = `${hours}:${minutes} ${ampm}`;
	return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}	${strTime}`;
};

exports.compareDatesWithoutTime = (date1, date2) => {
	const dateOne = new Date(new Date(date1).getFullYear(), (new Date(date1).getMonth() + 1), new Date(date1).getDate());
	const dateTwo = new Date(new Date(date2).getFullYear(), (new Date(date2).getMonth() + 1), new Date(date2).getDate());
	if ((dateOne - dateTwo) === 0) {
		return 'same';
	} if ((dateOne > dateTwo)) {
		return 'greater';
	}
	return 'smaller';
};
