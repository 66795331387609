import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import { ListItemWrapper } from '../../components/CompanyEditorComponents/styles';
import { Button } from '../../components/Styles';

const ListItem = (props) => {
	const { profile, profileType } = props;
	const {
		name, status, createdByUser, updatedAt,
	} = profile;
	return (
		<ListItemWrapper style={{ minHeight: '3rem' }}>
			<Col sm={3} xs={4}>
				<span>{name}</span>
			</Col>
			<Col sm={2} xs={4}>
				<span>{status}</span>
			</Col>
			<Col sm={2} xs={4}>
				<span>{createdByUser && createdByUser.name ? createdByUser.name : ''}</span>
			</Col>
			<Col sm={3} xs={2}>
				<span>{new Date(updatedAt).toLocaleString('en-gb')}</span>
			</Col>
			<Col sm={2} xs={4}>
				<a
					href={profileType === 'company' ? `/company/editor/${profile.id}` : `/manager/company/influencers/${profile.id}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<Button className="mb-0" primary primary_outline btn_xs><b>VIEW/EDIT</b></Button>
				</a>
			</Col>
		</ListItemWrapper>
	);
};

ListItem.propTypes = {
	profile: T.object,
	profileType: T.string,
};

export default ListItem;
