/* eslint-disable no-unused-vars */
import React, {
	useReducer, useEffect, useState, useRef, useMemo,
} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import uuid from 'react-uuid';

import { Row, Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import { Input, Button } from '../Styles';
import { ImageHolder } from '../FormComponents';
// import { validateImage } from '../../utils/common';
import {
	CURATED_SET_CONFIG_URL, regex, regex1, emailRegex, SAVING_FAILURE_MESSAGE, SAVING_MESSAGE, SAVING_SUCCESS_MESSAGE, toolbarOptions,
} from '../../containers/ListingsManager/constants';
import ToastMessage from '../ToastMessage';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import { reArrangeTheList } from '../../utils/common';

const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
const UPDATE_ERRORS = 'UPDATE_ERRORS';
const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
const SET_FORM_DATA = 'SET_FORM_DATA';

const { Quill } = ReactQuill;
// overwritting counter module
Quill.register('modules/counter', (quill) => {
	const container = document.querySelector('#profileDescriptionCounter');
	quill.on('text-change', () => {
		const text = quill.getText();
		const length = text?.length > 0 ? quill.getText()?.length - 1 : 0;
		container.innerText = `${length} ${length === 1 ? '/ 200' : '/ 200'}`;
	});
});

const initialState = {
	formData: {
		id: null,
		articleLink: '',
		description: '',
		designation: '',
		image: '',
		order: 1,
		company: '',
		linkedInLink: '',
		instagramLink: '',
		gmailLink: '',
		twitterLink: '',
		name: '',
		facebookLink: '',
		newOrder: null,
		previousOrder: null,
	},
	blurredElements: {
		articleLink: false,
		description: false,
		image: false,
		order: false,
		designation: false,
		company: false,
		linkedInLink: false,
		instagramLink: false,
		gmailLink: false,
		twitterLink: false,
		name: false,
		facebookLink: false,
		newOrder: false,
		previousOrder: false,
	},
	errors: {
		articleLink: false,
		description: false,
		image: false,
		order: false,
		designation: false,
		company: false,
		linkedInLink: false,
		instagramLink: false,
		gmailLink: false,
		twitterLink: false,
		name: false,
		facebookLink: false,
		newOrder: false,
		previousOrder: false,
	},
	formMessage: null,
};

function updateFormData(changedData) {
	return {
		type: UPDATE_FORM_DATA,
		changedData,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function setFormData(profile) {
	return {
		type: SET_FORM_DATA,
		profile,
	};
}

function setFormMessage(message) {
	return {
		type: SET_FORM_MESSAGE,
		message,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case SET_FORM_DATA:
		return { ...state, formData: action.profile };

	case UPDATE_FORM_DATA: {
		const formData = { ...state.formData };
		const { changedData } = action;
		formData[changedData.name] = changedData.value;
		return { ...state, formData };
	}

	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}
	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };

	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			articleLink: true,
			description: true,
			image: true,
			order: true,
			designation: true,
			company: true,
			linkedInLink: true,
			instagramLink: true,
			gmailLink: true,
			twitterLink: true,
			name: true,
			facebookLink: true,
			newOrder: true,
			previousOrder: true,
		};
		return { ...state, blurredElements };
	}

	case SET_FORM_MESSAGE:
		return { ...state, formMessage: action.message };

	default:
		return state;
	}
}

const ProfileForm = (props) => {
	const {
		profile, getProfileListings, unmountModal,	profileIndex,
		categoryIndex, categoryList,
	} = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		formData, blurredElements, errors, formMessage,
	} = state;
	const [isButtonDisabled, disableButton] = useState(false);
	const [toUpdate, setToUpdate] = useState(false);
	const [toImageUpdate, setToImageUpdate] = useState(false);
	const quillRef = useRef(null);
	const modules = useMemo(
		() => ({
			toolbar: {
				container: toolbarOptions,
			},
			history: {
				delay: 500,
				maxStack: 100,
			},
			counter: {
				container: '#profileDescriptionCounter',
				unit: 'character',
			},
		}),
		[],
	);
	// 	history: {
	// 		delay: 500,
	// 		maxStack: 100,
	// 	},
	// 	counter: {
	// 		container: '#profileDescriptionCounter',
	// 		unit: 'character',
	// 	},
	// 	toolbar: toolbarOptions,
	// }}

	useEffect(() => {
		if (profile || profile?.id) {
			const profileData = { ...profile, newOrder: '' };
			dispatch(setFormData(profileData));
			if ((quillRef.current !== undefined && quillRef.current !== null && profileData.description?.length > 0)) {
				quillRef.current.editor.clipboard.dangerouslyPasteHTML(profileData.description);
				addRangeForQuillEditor(quillRef);
				// quillRef.current.editor.container.innerHTML = profileData.description;
			}
			setToUpdate(true);
		}
	}, []);

	useEffect(() => {
		getErrors();
	}, [formData, blurredElements]);

	function onInputChange(e) {
		const changedData = {
			name: e.target.name,
			value: e.target.value,
		};
		dispatch(updateFormData(changedData));
		// if (changedData.name === 'instagramLink' && validateURLs(url, 'instagram')) {
		// 	return alert('Enter a valid Instagram URL');
		// } else if (changedData.name === 'twitterLink' && validateURLs(url, 'twitter')) {
		// 	return alert('Enter a valid Twitter URL');
		// } else if (changedData.name === 'linkedInLink' && validateURLs(url, 'linkedIn')) {
		// 	return alert('Enter a valid LinkedIn URL');
		// } else if (changedData.name === 'gmailLink' && validateURLs(url, 'gmail')) {
		// 	return alert('Enter a valid Gmail URL');
		// }
	}

	function validateForm(profile) {
		return {
			articleLink: !profile.articleLink || profile.articleLink.length < 1,
			// description: !profile.description || profile.description.length < 1,
			image: !profile.image || profile.image.length < 1,
			// designation: !profile.designation || profile.designation.length < 1,
			// description: (profile && profile.description && profile.description?.length > 0 && profile.description !== '<p><br></p>'),
			// company: !profile.company || profile.company.length < 1,
			name: !profile.name || profile.name.length < 1,
			linkedInLink: (profile.linkedInLink && profile.linkedInLink.length > 1 && !validateURLs(profile.linkedInLink, 'linkedIn')) || false,
			instagramLink: (profile.instagramLink && profile.instagramLink.length > 1 && !validateURLs(profile.instagramLink, 'instagram')) || false,
			gmailLink: (profile.gmailLink && profile.gmailLink.length > 1 && !emailRegex.test(profile.gmailLink)) || false,
			twitterLink: (profile.twitterLink && profile.twitterLink.length > 1 && !validateURLs(profile.twitterLink, 'twitter')) || false,
			facebookLink: (profile.facebookLink && profile.facebookLink.length > 1 && !validateURLs(profile.facebookLink, 'facebook')) || false,
			newOrder: (profile?.newOrder && !regex.test(profile.newOrder) && regex1.test(profile.newOrder)) || false,
			order: (!profile.order) || (profile.order.length < 1) || (profile?.order && !regex.test(profile.order) && regex1.test(profile.order)) || false,
		};
	}

	function getErrors() {
		const validationErrors = validateForm(formData);
		const errors = Object.keys(validationErrors).reduce((acc, curr) => {
			if (validationErrors[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	}

	function getProfileBody(profile) {
		// const formData = new FormData();
		const formData = {
			id: null,
			articleLink: '',
			description: '',
			designation: '',
			image: '',
			order: '',
			company: '',
			linkedInLink: '',
			instagramLink: '',
			gmailLink: '',
			twitterLink: '',
			name: '',
			facebookLink: '',
		};
		const id = uuid();
		if (toUpdate) {
			formData.id = profile?.id ?? id;
			// formData.append('id', profile?.id || id);
		} else {
			formData.id = id;
			// formData.append('id', id);
		}
		// formData.append('name', profile.name);
		// formData.append('description', profile.description);
		// formData.append('designation', profile.designation);
		// formData.append('order', profile.order);
		// formData.append('articleLink', profile?.articleLink);
		// formData.append('company', profile.company);
		// formData.append('linkedInLink', profile.linkedInLink);
		// formData.append('instagramLink', profile.instagramLink);
		// formData.append('gmailLink', profile.gmailLink);
		// formData.append('twitterLink', profile.twitterLink);
		// formData.append('image', profile.image && profile.image.file ? {
		// 	url: profile.image.url,
		// 	key: profile.image.file.name
		// } : {
		// 	url: profile.image.url,
		// 	key: profile.image.file.name
		// });
		// const blob = new Blob();
		formData.name = profile.name;
		formData.description = profile.description && profile.description === '<p><br></p>' ? '' : profile.description;
		formData.image = profile.image && profile.image.file ? {
			url: profile.image.url,
			key: profile?.image?.file?.name,
		} : {
			url: profile.image.url || '',
			key: profile?.image?.file?.name || '',
		};
		formData.designation = profile.designation;
		formData.articleLink = profile.articleLink;
		formData.company = profile.company;
		formData.linkedInLink = profile.linkedInLink;
		formData.instagramLink = profile.instagramLink;
		formData.gmailLink = profile.gmailLink;
		formData.twitterLink = profile.twitterLink;
		formData.facebookLink = profile.facebookLink;
		formData.order = parseInt(profile.order);
		const index = categoryList[categoryIndex].userProfile.findIndex((item) => item.order === formData.order);
		if (index < 0) {
			formData.order = parseInt(profileIndex === -1 ? categoryList[categoryIndex].userProfile?.length + 1 : profile.order);
		}
		formData.newOrder = (profile?.newOrder) ? parseInt(profile.newOrder) : formData.order ? parseInt(formData.order) : '';
		// index < 0 ? formData.order = parseInt((categoriesList?.length > 0 && categoryIndex === -1) ? categoriesList?.length + 1 : category.order) : category.order;
		formData.previousOrder = formData.order;
		return formData;
	}

	function onFormSubmit(e) {
		e.preventDefault();
		const validation = validateForm(formData);
		const validated = !Object.keys(validation).some((i) => validation[i]);
		if (validated) {
			saveProfile();
		} else {
			dispatch(setAllElementsBlurred());
		}
	}

	function handleBlur(element) {
		dispatch(updateBlurredElement(element));
	}

	async function saveProfile() {
		try {
			disableButton(true);
			dispatch(setFormMessage(SAVING_MESSAGE));
			const newProfilePayload = getProfileBody(formData);
			const payload = { category: [...categoryList] ?? [] };
			if (profileIndex === -1) {
				payload.category[categoryIndex].userProfile.push(newProfilePayload);
				payload.category[categoryIndex].userProfile?.length > 1 && newProfilePayload.newOrder ? payload.category[categoryIndex].userProfile = reArrangeTheList(1, payload.category[categoryIndex].userProfile, newProfilePayload) : '';
			} else {
				payload.category[categoryIndex].userProfile[profileIndex] = newProfilePayload;
				// reArrangeTheList(2, payload.category[categoryIndex].userProfile, newProfilePayload);
				payload.category[categoryIndex].userProfile?.length > 1 && formData.newOrder ? payload.category[categoryIndex].userProfile = reArrangeTheList(2, payload.category[categoryIndex].userProfile, newProfilePayload) : '';
			}
			const options = {
				method: 'PUT',
				credentials: 'include',
				body: JSON.stringify(payload),
				headers: {
					'Content-Type': 'application/json',
				},
			};
			const url = `${CURATED_SET_CONFIG_URL}/create`;
			const response = await fetch(url, options);
			disableButton(false);
			if (response.status === 200) {
				dispatch(setFormMessage(SAVING_SUCCESS_MESSAGE));
				getProfileListings();
				unmountModal();
			} else {
				dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			}
		} catch (err) {
			dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			disableButton(false);
			console.log('err', err);
		}
	}

	const uploadImages = async ({ file }) => {
		setToImageUpdate(true);
		try {
			const newForm = new FormData();
			newForm.append('imageFile', file);
			const options = {
				method: 'POST',
				credentials: 'include',
				body: newForm,
			};
			const resp = await fetch('/api/v2/rw/assets/upload/image', options);
			const imageUrl = await resp.json();
			setToImageUpdate(false);
			dispatch(updateFormData({
				name: 'image',
				value: {
					url: imageUrl,
					file,
				},
			}));
		} catch (err) {
			console.log('S3 image upload error: ', err);
			setToImageUpdate(false);
		}
	};

	async function onUpload(e) {
		e.preventDefault();
		const file = e.target.files[0];
		if (file?.size && (file?.size / 1024 / 1024 > 5)) {
			return alert(`File size greater than 5 MB (${(file?.size / 1024 / 1024).toFixed(2)} MB)!`);
		}
		const reader = new FileReader();
		reader.onloadend = () => {
			uploadImages({ file });
		};
		reader.readAsDataURL(file);
	}

	function onUploadClick(e) {
		e.preventDefault();
		const logoInput = document.getElementById('profile-listing-image');
		logoInput.click();
	}

	function onImageLoad(e) {
		if (formData.image.file) {
			const data = { height: e.target.naturalHeight, width: e.target.naturalWidth };
			dispatch(updateFormData({
				name: 'image',
				value: {
					...formData.image,
					...data,
				},
			}));
		}
	}

	// added url validation for social link
	function validateURLs(url, type) {
		let urlFormats;
		let result = false;
		switch (type) {
		case 'facebook':
			urlFormats = ['https://www.facebook.com', 'http://www.facebook.com', 'https://facebook.com', 'http://facebook.com'];
			break;
		case 'twitter':
			urlFormats = ['https://www.twitter.com', 'http://www.twitter.com', 'https://twitter.com', 'http://twitter.com'];
			break;
		case 'instagram':
			urlFormats = ['https://www.instagram.com', 'http://www.instagram.com', 'https://instagram.com', 'http://instagram.com'];
			break;
		case 'gmail':
			urlFormats = ['https://www.mail.google.com', 'http://www.mail.google.com', 'https://mail.google.com', 'http://mail.google.com'];
			break;
			// https://www.linkedin.com/in
		case 'linkedIn':
			urlFormats = ['https://www.linkedin.com', 'http://www.linkedin.com', 'https://linkedin.com', 'http://linkedin.com'];
			break;
		default:
			break;
		}
		// for (const format of urlFormats) {
		// 	if (url.startsWith(format)) {
		// 		return true;
		// 	}
		// }
		urlFormats.forEach((format) => {
			if (url && url?.length > 0 && url.startsWith(format)) {
				result = true;
			}
		});
		return result;
	}
	//
	// update Profile Description
	function updateProfileDescriptionEditorData(content) {
		if ((quillRef.current !== undefined && quillRef.current !== null) && quillRef.current.editor.getLength() > 200) {
			quillRef.current.editor.deleteText(200, quillRef.current.editor.getLength());
			// addRangeForQuillEditor(quillRef);
			dispatch(updateFormData({ name: 'description', value: quillRef.current.editor.root.innerHTML.replaceAll(/\s/g, '&nbsp;') }));
		} else if ((quillRef.current !== undefined && quillRef.current !== null) && quillRef.current.editor.getLength() < 200) {
			const res = quillRef.current.editor.getText().trim()?.length === 0;
			// addRangeForQuillEditor(quillRef);
			dispatch(updateFormData({ name: 'description', value: res ? '' : quillRef.current.editor.root.innerHTML.replaceAll(/\s/g, '&nbsp;') }));
		} else {
			// addRangeForQuillEditor(quillRef);
			dispatch(updateFormData({ name: 'description', value: content.replaceAll(/\s/g, '&nbsp;') }));
		}
	}

	function addRangeForQuillEditor(quillRef) {
		const range = quillRef.current.editor.getSelection();
		// quillRef.current.focus();
		(range && range?.index) ? quillRef.current.editor.setSelection(range.index + 1, Quill.sources.SILENT) : '';
	}

	function renderFormMessage() {
		if (formMessage) {
			return (
				<ToastMessage
					toastData={formMessage}
					unmount={() => dispatch(setFormMessage(null))}
				/>
			);
		}
		return null;
	}

	return (
		<form onSubmit={onFormSubmit}>
			{renderFormMessage()}
			<Row>
				<Col sm={12}>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Profile Pic *
						</Col>
						<Col xs={12} sm={8}>
							<Input
								id="profile-listing-image"
								type="file"
								style={{ display: 'none' }}
								name="image"
								onChange={onUpload}
								accept="image/png, image/jpeg"
								onBlur={() => handleBlur('image')}
							/>
							{(formData && !formData?.image?.url && toImageUpdate)
								? <LoadingIcon />
								: <ImageHolder
										showImageUploader={onUploadClick}
										imageUrl={formData && formData.image ? formData.image.url : null}
										onImageLoad={onImageLoad}
										squareDimension="13rem"
										clearImage={() => {
											document.getElementById('profile-listing-image').value = '';
											dispatch(updateFormData({ name: 'image', value: null }));
										}}
								/>}
							<ErrorMessage display={errors.image} element="Profile Image" />
						</Col>
					</Row>
					{/* name  */}
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Name *
						</Col>
						 <Col sm={8} xs={12}>
							<Input
								name="name"
								type="text"
								className="form-control"
								placeholder="Name"
								onChange={onInputChange}
								value={formData.name ?? ''}
								onBlur={() => handleBlur('name')}
							/>
							<ErrorMessage display={errors.name} element="Name" />
						</Col>
					</Row>
					{/*  */}
					{/* article link */}
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Redirection URL *
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="articleLink"
								type="url"
								className="form-control"
								placeholder="Article Link"
								onChange={onInputChange}
								value={formData.articleLink ?? ''}
								onBlur={() => handleBlur('articleLink')}
							/>
							<ErrorMessage display={errors.articleLink} element="Redirection URL" />
						</Col>
					</Row>
					{/*  */}
					{/* description */}
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Description
						</Col>
						<Col sm={8} xs={12}>
							{/* <textarea
								rows="4"
								className="global-form-control"
								name="description"
								placeholder="Description"
								onChange={onInputChange}
								value={formData.description ?? ''}
								onBlur={() => handleBlur('description')}
							/> */}
							<ReactQuill
								id="listingProfileDescription"
								name="description"
								ref={(el) => {
									quillRef.current = el;
								}}
								style={{ height: '10rem', marginBottom: '10%' }}
								placeholder="The profile summary"
								defaultValue={(formData && formData.description) ? formData.description : formData?.description}
								onChange={(content) => {
									updateProfileDescriptionEditorData(content);
									// const changedData = {
									// 	name: 'description',
									// 	value: content,
									// };
									// dispatch(updateFormData(changedData));
								}}
								// modules={{
								// 	history: {
								// 		delay: 500,
								// 		maxStack: 100,
								// 	},
								// 	counter: {
								// 		container: '#profileDescriptionCounter',
								// 		unit: 'character',
								// 	},
								// 	toolbar: toolbarOptions,
								// }}
								modules={modules}
								onBlur={() => handleBlur('description')}
							/>
							<Row style={{
								marginTop: '10%',
							}}
							>
								<Col xs={8}>
									{/* <ErrorMessage display={errors.description} element="Description" /> */}
								</Col>
								<Col xs={4}>
									<div
										id="profileDescriptionCounter"
										style={{
											textAlign: 'right',
											color: '#aaa',
										}}
									>
										0 / 200
										{/* {formData && formData.comment ? retrunFormDataCommentLength() <= 200 ? retrunFormDataCommentLength() : 200 : 0}/200 */}
									</div>
								</Col>
							</Row>
							{/* <ErrorMessage display={errors.description} element="Description" /> */}
						</Col>
					</Row>
					{/*  */}
					{/*  order */}
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Order *
						</Col>
						 <Col sm={8} xs={12}>
							<Input
								name="order"
								type="text"
								className="form-control"
								placeholder="Order of the profile user"
								onChange={onInputChange}
								value={formData.order ?? ''}
								onKeyPress={(event) => {
									if ((!/[0-9]/.test(event.key))) {
										event.preventDefault();
									} else if (!regex.test(event.target.value) && regex1.test(event.target.value)) {
										event.preventDefault();
									}
								}}
								disabled={profileIndex > -1}
								onBlur={() => handleBlur('order')}
							/>
							<p className="error mt-2 ml-1">NOTE: Please pick order value should be more than zero.</p>
							<ErrorMessage display={errors.order} element="Order" />
						</Col>
					</Row>
					{/*  */}
					{/*  change previous order */}
					{profileIndex > -1 && <Row className="mb-3">
						<Col sm={4} xs={12}>
							Change Previous Order
						</Col>
						 <Col sm={8} xs={12}>
							<Input
								name="newOrder"
								type="text"
								className="form-control"
								placeholder="Change Previous Order"
								onKeyPress={(event) => {
									if ((!/[0-9]/.test(event.key))) {
										event.preventDefault();
									} else if (!regex.test(event.target.value) && regex1.test(event.target.value)) {
										event.preventDefault();
									}
								}}
								onChange={onInputChange}
								value={formData.newOrder ?? ''}
								onBlur={() => handleBlur('newOrder')}
							/>
							<p className="error mt-2 ml-1">NOTE: Please pick new order value should be more than zero.</p>
							<ErrorMessage display={errors.newOrder} element="New Order" />
						</Col>
					</Row>}
					{/*  */}
					{/* company name */}
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Company Name
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="company"
								type="text"
								className="form-control"
								placeholder="Company Name of the profile user"
								onChange={onInputChange}
								value={formData.company ?? ''}
								onBlur={() => handleBlur('company')}
							/>
							{/* <ErrorMessage display={errors.company} element="Company" /> */}
						</Col>
					</Row>
					{/*  */}
					{/*  designation */}
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Designation
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="designation"
								type="text"
								className="form-control"
								placeholder="Designation Name of the profile user"
								onChange={onInputChange}
								value={formData.designation ?? ''}
								onBlur={() => handleBlur('designation')}
							/>
							{/* <ErrorMessage display={errors.designation} element="Designation" /> */}
						</Col>
					</Row>
					{/*  */}
					{/* instagram link */}
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Instagram URL
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="instagramLink"
								type="url"
								className="form-control"
								placeholder="Instagram Link"
								onChange={onInputChange}
								value={formData.instagramLink ?? ''}
								onBlur={() => handleBlur('instagramLink')}
							/>
							{errors.instagramLink && <p className="error mt-2 ml-1">Enter Proper Instagram URL</p>}
							{/* <ErrorMessage display={errors.url} element="Redirection URL" /> */}
						</Col>
					</Row>
					{/*  */}
					{/* lined in link */}
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							LinkedIn URL
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="linkedInLink"
								type="url"
								className="form-control"
								placeholder="LinkedIn Link"
								onChange={onInputChange}
								value={formData.linkedInLink ?? ''}
								onBlur={() => handleBlur('linkedInLink')}
							/>
							{errors.linkedInLink && <p className="error mt-2 ml-1">Enter Proper LinkedIn URL</p>}
							{/* <ErrorMessage display={errors.url} element="Redirection URL" /> */}
						</Col>
					</Row>
					{/*  */}
					{/* gmailLink */}
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Email Id
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="gmailLink"
								type="text"
								className="form-control"
								placeholder="Email Id"
								onChange={onInputChange}
								value={formData.gmailLink ?? ''}
								onBlur={() => handleBlur('gmailLink')}
							/>
							{errors.gmailLink && <p className="error mt-2 ml-1">Enter Proper Email Id</p>}
							{/* <ErrorMessage display={errors.url} element="Redirection URL" /> */}
						</Col>
					</Row>
					{/*  */}
					{/* twitter link */}
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Twitter URL
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="twitterLink"
								type="url"
								className="form-control"
								placeholder="Twitter Link"
								onChange={onInputChange}
								value={formData.twitterLink ?? ''}
								onBlur={() => handleBlur('twitterLink')}
							/>
							{errors.twitterLink && <p className="error mt-2 ml-1">Enter Proper Twitter URL</p>}
							{/* <ErrorMessage display={errors.url} element="Redirection URL" /> */}
						</Col>
					</Row>
					{/*  */}
					{/* twitter link */}
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Facebook URL
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="facebookLink"
								type="url"
								className="form-control"
								placeholder="facebook Link"
								onChange={onInputChange}
								value={formData.facebookLink ?? ''}
								onBlur={() => handleBlur('facebookLink')}
							/>
							{errors.facebookLink && <p className="error mt-2 ml-1">Enter Proper Facebook URL</p>}
							{/* <ErrorMessage display={errors.url} element="Redirection URL" /> */}
						</Col>
					</Row>
					{/*  */}
					<div className="text-right">
						<Button
							success
							type="submit"
							disabled={isButtonDisabled}
						>
							SAVE
						</Button>
					</div>
				</Col>
				{renderFormMessage()}
			</Row>
		</form>
	);
};

ProfileForm.propTypes = {
	profile: T.object,
	unmountModal: T.func,
	getProfileListings: T.func,
	profileIndex: T.number,
	categoryIndex: T.number,
	categoryList: T.array,
};

export default ProfileForm;
