export const ON_MEDIA_FORM_DATA_CHANGE = 'ON_MEDIA_FORM_DATA_CHANGE';
export const ON_MEDIA_FILE_SELECTED = 'ON_MEDIA_FILE_SELECTED';

export const FETCH_MEDIA_ASSET_BY_ID = 'FETCH_MEDIA_ASSET_BY_ID';
export const FETCH_MEDIA_ASSET_BY_ID_SUCCESS = 'FETCH_MEDIA_ASSET_BY_ID_SUCCESS';
export const FETCH_MEDIA_ASSET_BY_ID_FAILURE = 'FETCH_MEDIA_ASSET_BY_ID_FALURE';

export const CREATE_NEW_ASSET = 'CREATE_NEW_ASSET';
export const CREATE_NEW_ASSET_SUCCESS = 'CREATE_NEW_ASSET_SUCCES';
export const CREATE_NEW_ASSET_FAILURE = 'CREATE_NEW_ASSET_FAILURE';

export const UNMOUNT_MEDIA_FORM_MESSAGE = 'UNMOUNT_MEDIA_FORM_MESSAGE';
export const ON_MEDIA_ASSET_SELECTED = 'ON_MEDIA_ASSET_SELECTED';

export const FETCH_STORIES_BY_MEDIA_ID = 'FETCH_STORIES_BY_MEDIA_ID';
export const FETCH_STORIES_BY_MEDIA_ID_SUCCESS = 'FETCH_STORIES_BY_MEDIA_ID_SUCCESS';
export const FETCH_STORIES_BY_MEDIA_ID_FAILURE = 'FETCH_STORIES_BY_MEDIA_ID_FAILURE';

export const CLEAR_MEDIA_EDITOR_DATA = 'CLEAR_MEDIA_EDITOR_DATA';

export const UPDATE_MEDIA = 'UPDATE_MEDIA';
export const UPDATE_MEDIA_SUCCESS = 'UPDATE_MEDIA_SUCCESS';
export const UPDATE_MEDIA_FAILURE = 'UPDATE_MEDIA_FAILURE';

export const START_UPLOADING_MEDIA = 'START_UPLOADING_MEDIA';

export const DISPLAY_MEDIA_EDITOR = 'DISPLAY_MEDIA_EDITOR';
export const DISPLAY_MEDIA_PREVIEW = 'DISPLAY_MEDIA_PREVIEW';
