/* eslint-disable no-unused-vars */
import { Map } from 'immutable';
import {
	FETCH_MEDIA_BY_BRAND_AND_TYPE, FETCH_MEDIA_BY_BRAND_AND_TYPE_SUCCESS, FETCH_MEDIA_BY_BRAND_AND_TYPE_FAILURE, SET_MEDIA_GALLERY_PAGE_NO, SORT_MEDIA_BY, SET_MEDIA_SEARCH_VALUE, SET_GALLERY_UPDATED_TIME, FILTER_MEDIA, FILTER_MEDIA_SUCCESS,
} from './type';

const initialState = Map({
	allMedia: [],
	sortBy: 'recent',
	loading: false,
	error: false,
	page: 1,
	mediaType: 'VIDEO',
	formMessage: null,
	searchValue: null,
	galleryUpdatedAt: null,
});

function mediaGalleryReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_MEDIA_BY_BRAND_AND_TYPE:
		return state
			.set('loading', true);
	case FETCH_MEDIA_BY_BRAND_AND_TYPE_SUCCESS: {
		const { concat } = action;
		let allMedia;
		if (concat) {
			const existingMedia = state.get('allMedia');
			allMedia = existingMedia.concat(action.media);
		} else {
			allMedia = action.media;
		}
		return state
			.set('allMedia', allMedia)
			.set('loading', false);
	}
	case FETCH_MEDIA_BY_BRAND_AND_TYPE_FAILURE:
		return state
			.set('loading', false)
			.set('error', action.error);
	case SET_MEDIA_GALLERY_PAGE_NO:
		return state
			.set('page', action.page);
	case SORT_MEDIA_BY:
		return state
			.set('sortBy', action.sortBy);
	case SET_MEDIA_SEARCH_VALUE:
		return state
			.set('searchValue', action.value);
	case SET_GALLERY_UPDATED_TIME:
		return state
			.set('galleryUpdatedAt', action.time);
	case FILTER_MEDIA:
		return state
			.set('loading', true);
	case FILTER_MEDIA_SUCCESS: {
		const { concat } = action;
		let allMedia;
		if (concat) {
			const existingMedia = state.get('allMedia');
			allMedia = existingMedia.concat(action.media);
		} else {
			allMedia = action.media;
		}
		return state
			.set('loading', false)
			.set('allMedia', allMedia);
	}
	default:
		return state;
	}
}

export default mediaGalleryReducer;
