import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { RSS_FEED_LIST_BASE_URL } from './constants';
import {
	fetchAllFeedError, fetchAllFeedSuccess, createFeedError, createFeedSuccess, fetchAllFeed, deleteFeedError, updateFeedSucces, updateFeedError, deleteFeedSuccess,
} from './actions';
import {
	FETCH_ALL_FEED, CREATE_FEED, UPDATE_FEED, DELETE_FEED,
} from './type';

export function* getAllFeeds() {
	const url = `${RSS_FEED_LIST_BASE_URL}/`;
	const options = {
		method: 'GET',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const allFeeds = yield response.json();
			yield put(fetchAllFeedSuccess(allFeeds));
		}
	} catch (err) {
		yield put(fetchAllFeedError(err));
	}
}

export function* createFeed(action) {
	const url = `${RSS_FEED_LIST_BASE_URL}/`;
	const formData = action.feed;
	const options = {
		method: 'POST',
		body: formData,
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.statu === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(createFeedSuccess(responseMessage));
				yield put(fetchAllFeed());
			} else {
				yield put(createFeedError(responseMessage));
			}
		}
	} catch (err) {
		yield put(createFeedError(err));
	}
}

export function* updateFeed(action) {
	const url = `${RSS_FEED_LIST_BASE_URL}/${action.feedId}`;
	const formData = action.updatedFeed;
	const options = {
		method: 'PUT',
		body: formData,
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(updateFeedSucces(responseMessage));
				yield put(fetchAllFeed());
			} else {
				yield put(updateFeedError(responseMessage));
			}
		}
	} catch (err) {
		yield put(deleteFeedError(err));
	}
}

export function* deleteFeed(action) {
	const url = `${RSS_FEED_LIST_BASE_URL}/${action.feedId}`;
	const options = {
		method: 'DELETE',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(deleteFeedSuccess(responseMessage));
				yield put(fetchAllFeed());
			} else {
				yield put(deleteFeedError(responseMessage));
			}
		}
	} catch (err) {
		yield put(deleteFeedError(err));
	}
}

export default function* rssCreatorData() {
	yield takeLatest(FETCH_ALL_FEED, getAllFeeds);
	yield takeLatest(CREATE_FEED, createFeed);
	yield takeLatest(UPDATE_FEED, updateFeed);
	yield takeLatest(DELETE_FEED, deleteFeed);
}
