import React from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = (props) => {
	const { element, message } = props;
	return (
		<div>
			{
				props.display
					? <p className="error error-text text-normal mb-2">
						* {message || `${element} is required`}
					</p>
					: null
			}
		</div>
	);
};

ErrorMessage.propTypes = {
	display: PropTypes.bool,
	element: PropTypes.string,
	message: PropTypes.string,
};
export default ErrorMessage;
