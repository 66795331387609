import { createSelector } from 'reselect';

const selectCPItemList = (state) => state.get('fundingList');

const makeSelectSelectedLogType = () => createSelector(
	selectCPItemList,
	(CPListState) => CPListState.get('logType'),
);

const makeSelectListCPItems = () => createSelector(
	selectCPItemList,
	(CPListState) => CPListState.get('allCPItems'),
);

const makeSelectCPItemListLoading = () => createSelector(
	selectCPItemList,
	(CPListState) => CPListState.get('loading'),
);

const makeSelectCPItemListLimit = () => createSelector(
	selectCPItemList,
	(CPListState) => CPListState.get('limit'),
);

const makeSelectCPItemListOffset = () => createSelector(
	selectCPItemList,
	(CPListState) => CPListState.get('offset'),
);

const makeSelectFetchedCPItems = () => createSelector(
	selectCPItemList,
	(CPListState) => CPListState.get('CPItems'),
);

const makeSelectCPItemListSearchType = () => createSelector(
	selectCPItemList,
	(CPListState) => CPListState.get('searchType'),
);

const makeSelectCPItemListSearch = () => createSelector(
	selectCPItemList,
	(CPListState) => CPListState.get('search'),
);

const makeSelectCPItemListSearchValue = () => createSelector(
	selectCPItemList,
	(CPListState) => CPListState.get('searchValue'),
);

const makeSelectCPItemListSearchFilterMonth = () => createSelector(
	selectCPItemList,
	(CPListState) => CPListState.get('filterMonth'),
);

const makeSelectCPItemListSearchFilterYear = () => createSelector(
	selectCPItemList,
	(CPListState) => CPListState.get('filterYear'),
);

const makeSelectCPItemListSortValue = () => createSelector(
	selectCPItemList,
	(CPListState) => CPListState.get('sortValue'),
);

export {
	makeSelectListCPItems,
	// makeSelectCPItemListPage,
	makeSelectSelectedLogType,
	makeSelectCPItemListLoading,
	makeSelectCPItemListLimit,
	makeSelectCPItemListOffset,
	makeSelectFetchedCPItems,
	makeSelectCPItemListSearchType,
	makeSelectCPItemListSearch,
	makeSelectCPItemListSearchFilterMonth,
	makeSelectCPItemListSearchFilterYear,
	makeSelectCPItemListSearchValue,
	makeSelectCPItemListSortValue,
};
