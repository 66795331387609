import { fork } from 'redux-saga/effects';
import appSaga from '../containers/App/saga';
import dashboardSaga from '../containers/Dashboard/saga';
import storyListSaga from '../containers/StoryList/saga';
import categorySaga from '../containers/CategoryManager/saga';
import tagSaga from '../containers/TagManager/saga';
import brandSaga from '../containers/BrandManager/saga';
import dynamicConfigSaga from '../containers/ConfigManager/saga';
import fileUploadSaga from '../containers/UploadPage/saga';
import featuredSetSaga from '../containers/FeaturedSet/saga';
import deskSaga from '../containers/StoryDesk/saga';
import storyPreviewSaga from '../containers/StoryPreview/saga';
import redirectionSaga from '../containers/RedirectionEngine/saga';
import brandSpotlightSaga from '../containers/BrandSpotlightManager/saga';
import messengerPromotionFormSaga from '../containers/MessengerPromotionForm/saga';
import messengerPromotionsManagerSaga from '../containers/MessengerPromotionsManager/saga';
import notificationsSaga from '../containers/NotificationsManager/saga';
import rssFeedCreatorSaga from '../containers/RssFeedCreator/saga';
import rssFeedContentSaga from '../containers/WatchlistedStories/saga';
import storyVersionsSaga from '../containers/StoryVersions/saga';
import marketingDataSaga from '../containers/MarketingData/saga';
import activityStreamSaga from '../containers/ActivityStream/saga';
import seriesManagerSaga from '../containers/SeriesManager/saga';
import seriesCreateSaga from '../containers/SeriesCreate/saga';
import mediaGallerySaga from '../containers/MediaManagerGallery/saga';
import mediaEditorSaga from '../containers/MediaManagerEditor/saga';
import editorMediaGallerySaga from '../components/EditorMediaGallery/utils/saga';
import editorImageHandlerSaga from '../components/EditorImageHandler/utils/saga';
import editorSaga from '../containers/EditorPage/saga';
import verticalSaga from '../containers/VerticalManager/saga';
import companyEditorSaga from '../containers/CompanyEditor/saga';
import awardSaga from '../containers/AwardManager/saga';
import peopleSaga from '../containers/PeopleManager/saga';
import citySaga from '../containers/CityManager/saga';
import profileDeskSaga from '../containers/ProfileDesk/saga';
import profileListSaga from '../containers/ProfileList/saga';
import tagStoriesSaga from '../containers/TagStories/saga';
import multipleStoriesTagger from '../containers/MultipleStoriesTagger/saga';
import profileClaimDeskSaga from '../containers/ProfileClaimDesk/saga';
import customFieldsSaga from '../containers/CustomFields/saga';
import userSaga from '../containers/UserManager/saga';
import createAliasSaga from '../containers/CreateAliasPage/saga';
import updateAliasSaga from '../containers/UpdateAliasPage/saga';
import inviteUserSaga from '../containers/InviteUser/saga';
import companyConnectDesk from '../containers/CompanyConnectDesk/saga';
import jobEditorSaga from '../containers/JobEditor/saga';
import jobsListSaga from '../containers/JobsList/saga';
import eventEditorSaga from '../containers/EventEditor/saga';
import profileFundingListSaga from '../containers/FundingList/saga';
import orgChartEditorSaga from '../containers/OrgChartEditor/saga';
import cmsPageCurationSetSaga from '../containers/CMSPageCurationSet/saga';

export default function* rootSaga() {
	yield fork(appSaga);
	yield fork(dashboardSaga);
	yield fork(storyListSaga);
	yield fork(categorySaga);
	yield fork(tagSaga);
	yield fork(brandSaga);
	yield fork(dynamicConfigSaga);
	yield fork(fileUploadSaga);
	yield fork(featuredSetSaga);
	yield fork(seriesManagerSaga);
	yield fork(seriesCreateSaga);
	yield fork(deskSaga);
	yield fork(storyPreviewSaga);
	yield fork(redirectionSaga);
	yield fork(brandSpotlightSaga);
	yield fork(messengerPromotionFormSaga);
	yield fork(messengerPromotionsManagerSaga);
	yield fork(notificationsSaga);
	yield fork(rssFeedContentSaga);
	yield fork(rssFeedCreatorSaga);
	yield fork(storyVersionsSaga);
	yield fork(marketingDataSaga);
	yield fork(activityStreamSaga);
	yield fork(mediaGallerySaga);
	yield fork(mediaEditorSaga);
	yield fork(editorMediaGallerySaga);
	yield fork(editorImageHandlerSaga);
	yield fork(editorSaga);
	yield fork(verticalSaga);
	yield fork(companyEditorSaga);
	yield fork(awardSaga);
	yield fork(peopleSaga);
	yield fork(citySaga);
	yield fork(profileDeskSaga);
	yield fork(profileListSaga);
	yield fork(profileFundingListSaga);
	yield fork(tagStoriesSaga);
	yield fork(multipleStoriesTagger);
	yield fork(profileClaimDeskSaga);
	yield fork(customFieldsSaga);
	yield fork(userSaga);
	yield fork(createAliasSaga);
	yield fork(updateAliasSaga);
	yield fork(inviteUserSaga);
	yield fork(companyConnectDesk);
	yield fork(jobEditorSaga);
	yield fork(jobsListSaga);
	yield fork(eventEditorSaga);
	yield fork(orgChartEditorSaga);
	yield fork(cmsPageCurationSetSaga);
}
