import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { ListItemWrapper, Button } from '../../WebinarComponents/styles';

const ListItem = (props) => {
	const {
		brandOfTheDay, slNo, editItem, deleteItem,
	} = props;
	const {
		storyId, status, storyTitle, storyData, date,
	} = brandOfTheDay;
	const brandName = (storyData.companyBrand && storyData.companyBrand.name) ?? 'brand data not found';
	return (
		<ListItemWrapper slNo={slNo}>
			<ReactTooltip />
			<Col xs={2} md={1}>
				{slNo}
			</Col>
			<Col xs={2} md={1}>
				{storyId}
			</Col>
			<Col xs={2} md={2}>
				{storyTitle}
			</Col>
			<Col xs={2} md={2}>
				{brandName}
			</Col>
			<Col xs={2} md={2}>
				{date}
			</Col>
			<Col xs={3} md={1}>
				{status}
			</Col>
			<Col xs={1} md={1}>
				<Button view onClick={() => editItem(brandOfTheDay)}>
					<i className="fa fa-pencil-square fa-lg" />
				</Button>
				<Button onClick={() => deleteItem(brandOfTheDay.id)}>
					<i className="fas fa-trash fa-lg" />
				</Button>
			</Col>
		</ListItemWrapper>
	);
};

ListItem.propTypes = {
	slNo: T.number,
	brandOfTheDay: T.object,
	editItem: T.func,
	deleteItem: T.func,
};

export default ListItem;
