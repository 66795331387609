import React from 'react';
import styled from 'styled-components';
import T from 'prop-types';
import ImagePlaceholderIcon from '../../images/image_placeholder.png';

const Card = styled.div`
		position: relative;
		width: 400px;
		box-shadow: 1px 2px 3px 0px #DDDDDD;
		word-wrap: break-word;
`;

const Thumbnail = styled.img`
		width: 100%;
		height: 200px;
`;

const CategoryPill = styled.span`
		min-width: 50px;
		color: #e5002d;
		background-color: #fdf5f4;
		border-radius: 0.25rem;
		text-align: center;
		padding: 0.25rem 0.5rem;
		font-size: 0.9rem;
`;

const Bottom = styled.div`
		min-height: 1.8rem;
		margin: auto;
`;

const PlaceholderIconContainer = styled.div`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
`;

const PlaceholderIcon = styled.img`
		transition: fill 0.25s;
		height: 50px;
		width: 50px;
`;

const StoryPreviewCard = (props) => {
	const { storyData } = props;
	const thumbnail = storyData && storyData.thumbnail ? storyData.thumbnail : null;
	const title = storyData && storyData.title ? `${storyData.title.substring(0, 100)}${storyData.title.length > 80 ? '...' : ''}` : 'Title';
	const publishedAt = storyData && storyData.publishedAt ? new Date(storyData.publishedAt).toDateString() : new Date().toDateString();
	const category = storyData && storyData.category ? storyData.category.toUpperCase() : 'CATEGORY';

	return (
		<Card>
			<div
				style={{
					position: 'relative',
					width: '100%',
					borderRadius: '0.5rem 0.5rem 0 0',
				}}
			>
				{
					thumbnail
						? <Thumbnail
								src={thumbnail}
								alt="Website thumbnail"
						/>
						: <div style={{
							width: '100%',
							height: '200px',
							backgroundColor: '#efefef',
						}}
						/>
				}
				{
					thumbnail
						? null
						: <PlaceholderIconContainer>
							<PlaceholderIcon src={ImagePlaceholderIcon} />
						</PlaceholderIconContainer>
				}

			</div>
			<div className="p-2" style={{ minHeight: '200px' }}>
				<div style={{ minHeight: '140px' }}>
					<p className="mb-1">{publishedAt.toUpperCase()}</p>
					<p className="lg-text"><b>{title}</b></p>
				</div>
				<Bottom className="mt-2">
					<CategoryPill>
						{category}
					</CategoryPill>
				</Bottom>
			</div>
		</Card>
	);
};

StoryPreviewCard.propTypes = {
	storyData: T.object,
};

export default StoryPreviewCard;
