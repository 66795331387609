import {
	CREATE_REDIRECTION, CREATE_REDIRECTION_SUCCESS, CREATE_REDIRECTION_FAILURE, SET_REDIRECTION_FORM_MESSAGE, REDIRECTION_ELEMENT_ON_CHANGE, CLEAR_REDIRECTION_FORM, FETCH_ALL_REDIRECTIONS, FETCH_ALL_REDIRECTIONS_SUCCESS, FETCH_ALL_REDIRECTIONS_FAILURE, SET_REDIRECTION_TABLE_PAGE, FILTER_REDIRECTION_TABLE, SELECT_REDIRECTION, FILTER_REDIRECTION_TABLE_SUCCESS, FILTER_REDIRECTION_TABLE_FAILURE,
} from './type';

export function fetchAllRedirections(page) {
	return {
		type: FETCH_ALL_REDIRECTIONS,
		page,
	};
}

export function fetchAllRedirectionsSuccess(allRedirections) {
	return {
		type: FETCH_ALL_REDIRECTIONS_SUCCESS,
		allRedirections,
	};
}

export function fetchAllRedirectionsFailure(error) {
	return {
		type: FETCH_ALL_REDIRECTIONS_FAILURE,
		error,
	};
}

export function createRedirection(redirection) {
	return {
		type: CREATE_REDIRECTION,
		redirection,
	};
}

export function createRedirectionSuccess(responseMessage) {
	return {
		type: CREATE_REDIRECTION_SUCCESS,
		responseMessage,
	};
}

export function createRedirectionFailure(error) {
	return {
		type: CREATE_REDIRECTION_FAILURE,
		error,
	};
}

export function setRedirectionFormMessage(message) {
	return {
		type: SET_REDIRECTION_FORM_MESSAGE,
		message,
	};
}

export function redirectionElementOnChange(element) {
	return {
		type: REDIRECTION_ELEMENT_ON_CHANGE,
		element,
	};
}

export function clearRedirectionForm() {
	return {
		type: CLEAR_REDIRECTION_FORM,
	};
}

export function setRedirectionTablePage(page) {
	return {
		type: SET_REDIRECTION_TABLE_PAGE,
		page,
	};
}

export function filterRedirectionTable(filter, page) {
	return {
		type: FILTER_REDIRECTION_TABLE,
		filter,
		page,
	};
}

export function filterRedirectionTableSuccess(redirections) {
	return {
		type: FILTER_REDIRECTION_TABLE_SUCCESS,
		redirections,
	};
}

export function filterRedirectionTableFailure(error) {
	return {
		type: FILTER_REDIRECTION_TABLE_FAILURE,
		error,
	};
}

export function selectRedirection(redirection) {
	return {
		type: SELECT_REDIRECTION,
		redirection,
	};
}
