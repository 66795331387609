import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { USER_BASE_API_URL } from '../UserManager/constants';
import { LOAD_ALIASES, LOAD_FILTERED_ALIASES, SAVE_ALIAS } from './type';
import {
	aliasesLoadingError,
	aliasesLoadedSuccessfully,
	filteredAliasesLoadedSuccessfully,
	filteredAliasesLoadingError,
	saveProfileSuccessfully,
	saveProfileError,
} from './actions';

export function* loadAliases(action) {
	const value = action.value ? action.value : 'NAME';
	const searchValue = action.searchValue ? action.searchValue : '';
	const url = `${USER_BASE_API_URL}/alias/list?page=1&type=${value}&value=${searchValue}`;
	const options = {
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.status) {
				yield put(aliasesLoadedSuccessfully(responseMessage.data));
			} else {
				yield put(aliasesLoadingError(responseMessage));
			}
		}
	} catch (error) {
		yield put(aliasesLoadingError(error));
	}
}

export function* loadFilteredAliases(action) {
	const url = `${USER_BASE_API_URL}/alias/list?page=${action.pageNo}&type=${action.dropDown.value}&value=${action.dropDown.searchValue}`;
	const options = {
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.status) {
				yield put(filteredAliasesLoadedSuccessfully(responseMessage.data));
			} else {
				yield put(filteredAliasesLoadingError(responseMessage));
			}
		}
	} catch (error) {
		yield put(filteredAliasesLoadingError(error));
	}
}

export function* saveProfile(action) {
	const url = `${USER_BASE_API_URL}/alias/update`;
	const { formData } = action;
	const options = {
		method: 'POST',
		credentials: 'include',
		body: formData,
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.status === 'success') {
				yield put(saveProfileSuccessfully(responseMessage.message));
			} else {
				yield put(saveProfileError(responseMessage.error));
			}
		}
	} catch (err) {
		yield put(saveProfileError(err));
	}
}

export default function* updateAliasSaga() {
	yield takeLatest(LOAD_ALIASES, loadAliases);
	yield takeLatest(LOAD_FILTERED_ALIASES, loadFilteredAliases);
	yield takeLatest(SAVE_ALIAS, saveProfile);
}
