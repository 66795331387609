import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { PEOPLE_API_BASE_URL } from './constants';
import {
	createPersonFailure, createPersonSuccess, personSelect, updatePersonSuccess, deletePersonSuccess, deletePersonFailure, updatePersonFailure,
} from './actions';
import {
	CREATE_PERSON, DELETE_PERSON, UPDATE_PERSON, FETCH_PERSON_BY_ID,
} from './type';

export function* createNewPerson(action) {
	const url = `${PEOPLE_API_BASE_URL}/create`;
	const formData = action.person;
	const options = {
		method: 'POST',
		body: formData,
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(createPersonSuccess(responseMessage));
				yield put(personSelect(null));
			} else {
				yield put(createPersonFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(createPersonFailure(err));
	}
}

export function* updatePerson(action) {
	const url = `${PEOPLE_API_BASE_URL}/id/${action.id}`;
	const formData = action.updatedData;
	const options = {
		method: 'PUT',
		body: formData,
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(updatePersonSuccess(responseMessage));
			} else {
				yield put(updatePersonFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(updatePersonFailure(err));
	}
}

export function* deletePerson(action) {
	const url = `${PEOPLE_API_BASE_URL}/${action.id}`;
	const options = {
		method: 'DELETE',
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.success) {
				yield put(deletePersonSuccess(responseMessage));
				yield put(personSelect(null));
			} else {
				yield put(deletePersonFailure(responseMessage));
			}
		}
	} catch (err) {
		yield put(deletePersonFailure(err));
	}
}

export function* fetchInfluencerById(action) {
	const url = `${PEOPLE_API_BASE_URL}/id/${action.id}`;
	const options = {
		credentials: 'include',
	};
	try {
		const response = yield call(fetch, url, options);
		if (response.status === 401 || response.status === 403) {
			yield put(push('/unauthorized'));
		} else {
			const responseMessage = yield response.json();
			if (responseMessage.id) {
				yield put(personSelect(responseMessage));
			}
		}
	} catch (err) {
		console.log('Person fetch by ID failed', err);
	}
}

export default function* peopleData() {
	yield takeLatest(CREATE_PERSON, createNewPerson);
	yield takeLatest(UPDATE_PERSON, updatePerson);
	yield takeLatest(DELETE_PERSON, deletePerson);
	yield takeLatest(FETCH_PERSON_BY_ID, fetchInfluencerById);
}
