import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import ImagePlaceholderIcon from '../../../images/image_placeholder.png';
import closeSvg from '../../../images/close.svg';
import { validateImageFormat } from '../../../utils/common';

const ImagePlaceholder = styled.div`
		background-color: #efefef;
		height: 180px;
		width: 350px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		align-items: center;
		cursor: pointer;
		border-radius: ${(props) => (props.upperRounded ? '24px 24px 0px 0px' : '0px')};
		pointer-events: ${(props) => (props.disabled ? 'none' : 'default')};
		${(props) => props.squareDimension && css`
				height: ${props.squareDimension};
				width: ${props.squareDimension};
		`}
`;

const Image = styled.img`
		height: ${(props) => (props.src && props.autoDimensions ? 'auto' : '180px')};
		width: ${(props) => (props.src && props.autoDimensions ? 'auto' : '350px')};
		max-height: 320px;
		max-width: 300px;
		cursor: pointer;
		border-radius: ${(props) => (props.upperRounded ? '24px 24px 0px 0px' : '0px')}
		:hover {
				opacity: 0.6;
		}
		${(props) => props.squareDimension && css`
				height: ${props.squareDimension};
				width: ${props.squareDimension};
		`}
`;

const ImageIcon = styled.img`
		height: 50px;
		width: 50px;
`;

const ImageHelpText = styled.div`
		margin-top: 5px;
		font-size: 0.8rem;
`;

const ImageErrorText = styled.div`
		color: red;
		margin-top: 5px;
		font-size: 0.8rem;
`;

const Input = styled.input`
		display: none;
		height: 180px;
		width: 350px;
		cursor: pointer;
`;

const CloseButton = styled.img`
		position: absolute;
		top: 5%;
		right: 5%;
		filter: brightness(0.5);
		cursor: pointer;
		:hover {
				opacity: 0.6;
		}
`;

const Container = styled.div`
		position: relative;
		${(props) => props.squareDimension && css`
				height: ${props.squareDimension};
				width: ${props.squareDimension};
		`}
`;

class ImageHolder extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			file: null,
			error: null,
		};
		this.handleImageChange = this.handleImageChange.bind(this);
		this.getImageElement = this.getImageElement.bind(this);
		this.getHelpText = this.getHelpText.bind(this);
	}

	getHelpText() {
		if (this.state.error) {
			return <ImageErrorText>{this.state.error}</ImageErrorText>;
		}
		return <ImageHelpText>Click to select or upload image</ImageHelpText>;
	}

	renderCloseButton() {
		if (this.props.clearImage) {
			return <CloseButton
				className="close-btn"
				src={closeSvg}
				alt="close"
				width="20"
				height="20"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					this.props.clearImage();
				}}
			/>;
		}
	}

	async handleImageChange(e) {
		try {
			e.preventDefault();

			const reader = new FileReader();
			const file = e.target.files[0];
			if (await validateImageFormat(file)) {
				reader.onloadend = () => {
					this.setState({
						...this.state, file, imagePreviewUrl: reader.result, error: null,
					});
					this.props.fileCallback({ file, imagePreviewUrl: reader.result, error: null });
				};
			} else {
				this.setState({ ...this.state, error: '*Please choose a valid image type' });
				this.props.fileCallback({ ...this.state, error: '*Please choose a valid image type' });
			}

			reader.readAsDataURL(file);
		} catch (err) {
			console.log('File read failed', err);
		}
	}

	getImageElement() {
		let element = null;

		if (this.state.imagePreviewUrl && !this.props.profileDesk) {
			element = <Container squareDimension={this.props.squareDimension}>
				<Image
					upperRounded={this.props.upperRounded}
					src={this.state.imagePreviewUrl}
					autoDimensions={this.props.autoDimensions}
					onLoad={this.props.onImageLoad}
					disabled={this.props.disabled}
					squareDimension={this.props.squareDimension}
				/>
				{this.renderCloseButton()}
			</Container>;
		} else if (this.props.imageUrl) {
			element = <Container squareDimension={this.props.squareDimension}>
				<Image
					upperRounded={this.props.upperRounded}
					src={this.props.imageUrl}
					autoDimensions={this.props.autoDimensions}
					onLoad={this.props.imageUrl ? this.props.onImageLoad : null}
					disabled={this.props.disabled}
					squareDimension={this.props.squareDimension}
				/>
				{this.renderCloseButton()}
			</Container>;
		} else {
			element = (<ImagePlaceholder
				upperRounded={this.props.upperRounded}
				disabled={this.props.disabled}
				squareDimension={this.props.squareDimension}
			>
				<ImageIcon src={ImagePlaceholderIcon} />
				{this.getHelpText()}
			</ImagePlaceholder>);
		}

		return (<>
			<Input
				name={`file-${this.props.name}`}
				id={`file-${this.props.name}`}
				type="file"
				onChange={(e) => this.handleImageChange(e)}
				disabled={this.props.disabled}
				accept="image/png, image/jpeg"
			/>
			<label htmlFor={`file-${this.props.name}`}>
				{element}
			</label>
		</>);
	}

	render() {
		return this.getImageElement();
	}
}

ImageHolder.propTypes = {
	name: PropTypes.string,
	fileCallback: PropTypes.func.isRequired,
	imageUrl: PropTypes.string,
	upperRounded: PropTypes.bool,
	disabled: PropTypes.bool,
	squareDimension: PropTypes.string,
	clearImage: PropTypes.func,
	autoDimensions: PropTypes.bool,
	onImageLoad: PropTypes.func,
	profileDesk: PropTypes.bool,
};

export default ImageHolder;
