import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import T from 'prop-types';

import { Row, Col } from '../../components/Responsive';
import { WrapperContainer, Button } from '../../components/Styles';
import {
	makeSelectListCPItems, makeSelectCPItemListLoading, makeSelectCPItemListLimit, makeSelectCPItemListOffset, makeSelectFetchedCPItems, makeSelectCPItemListSearch, makeSelectCPItemListSearchType, makeSelectCPItemListSearchValue, makeSelectCPItemListSortValue, makeSelectCPItemListSearchFilterMonth, makeSelectCPItemListSearchFilterYear, makeSelectSelectedLogType,
} from './selectors';
import {
	fetchCPItems, fetchNextPage, runCPListSearch, disableListSearchFlag, runCPListSort, disableListSearch, setInitialState, updateCPItemStatus,
} from './actions';
import ListHeader from './ListHeader';
import ListItem from './ListItem';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';
import FundingListOptions from '../../components/Assets/FundingListOptions';
import IPOListHeader from './IPO_ListHeader';
import IPOListItem from './IPO_ListItem';

class ProfileFundingList extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			sortedBy: {
				investee: null,
				amount: null,
				publishedAt: null,
			},
		};
	}

	componentDidMount() {
		const {
			getCPItems, limit, offset, match, sortBy, module, pageTitle,
		} = this.props;
		pageTitle(`${module}Logs`);
		getCPItems(limit, offset, sortBy, 'DESC', null, match.params.CPItem, module);
	}

	componentWillUnmount() {
		this.props.setInitialState();
	}

	updateStatus = (selectedOption, CPItem) => {
		const { updateNewCPItemStatus, module } = this.props;
		updateNewCPItemStatus(selectedOption.target.value.toString().toUpperCase(), CPItem, module);
	}

	renderProfiles = () => {
		const { CPItems, module } = this.props;
		if (CPItems && CPItems.length > 0) {
			return CPItems.map((CPItem) => (module === 'ipo' ? <IPOListItem
				key={CPItem.id}
				ipoItem={CPItem}
				onItemSelectAction={this.updateStatus}
			/>
				:	<ListItem
						key={CPItem.id}
						CPItem={CPItem}
						onItemSelectAction={this.updateStatus}
						logType={module}
				/>));
		}
		return (
			<div className="w_100 text-center">
				<h2 className="light-text">No profiles to display</h2>
			</div>
		);
	}

	sortBy = (field) => {
		const {
			disableSearchFlag, initiateSort, getCPItems, searchValue, match, module,
		} = this.props;
		disableSearchFlag();
		const sortedBy = {
			investee: null, status: null, createdBy: null, updatedAt: null,
		};
		if (this.state.sortedBy[field]) {
			if (this.state.sortedBy[field] === 'asc') sortedBy[field] = 'desc';
			else sortedBy[field] = 'asc';
		} else sortedBy[field] = 'desc';
		this.setState({ sortedBy });
		initiateSort(field);
		getCPItems(10, 0, field, sortedBy[field], searchValue, match.params.CPItem, module);
	}

	onShowMoreProfiles() {
		const {
			limit, offset, getCPItems, fetchNextPage, searchFlag, initiateListSearch, searchValue, sortValue, match, filterMonth, filterYear, module,
		} = this.props;
		fetchNextPage(limit);
		if (searchFlag) {
			initiateListSearch(searchValue, filterMonth, filterYear, limit, offset + 10, module);
		} else {
			getCPItems(limit, offset + 10, sortValue, this.state.sortedBy[sortValue], searchValue, match.params.CPItem, module);
		}
	}

	runFilter = (searchValue, filterMonth, filterYear) => {
		const {
			initiateListSearch, limit, offset, cancelSearch, module,
		} = this.props;
		if (this.props.searchValue || this.props.filterMonth || this.props.filterYear) {
			if (this.props.searchValue !== searchValue || this.props.filterMonth !== filterMonth || this.props.filterYear !== filterYear) {
				cancelSearch();
				initiateListSearch(searchValue, filterMonth, filterYear, limit, 0, module);
			} else {
				return null;
			}
		} else initiateListSearch(searchValue, filterMonth, filterYear, 10, offset, module);
	}

	onSearchClear = () => {
		const {
			getCPItems, cancelSearch, match, module,
		} = this.props;
		cancelSearch();
		getCPItems(10, 0, 'updatedAt', 'DESC', null, match.params.CPItem, module);
	}

	render() {
		const {
			CPItems, loading, limit, offset, fetchedCPItems, module,
		} = this.props;
		return (
			<div>
				<WrapperContainer className="mt-5">
					<FundingListOptions
						filterFundingItems={this.runFilter}
						onSearchClear={this.onSearchClear}
						module={module}
					/>
					{this.props.module === 'ipo' ? <IPOListHeader
						sortBy={this.sortBy}
						sortedBy={this.state.sortedBy}
					/>
						: <ListHeader
								sortBy={this.sortBy}
								sortedBy={this.state.sortedBy}
								logType={module}
						/>}
					{this.renderProfiles()}
					<Row>
						{
							loading && limit > 10
								? <LoadingIcon />
								: null
						}
					</Row>
					{
						loading || fetchedCPItems.length < 1 || (CPItems.length - offset < limit)
							? null
							: <Row center="xs">
								<Col xs={6} md={2}>
									<Button
										className="mt-4"
										primary
										btn_rounded
										w_100
										id="more-profiles-btn"
										onClick={() => this.onShowMoreProfiles()}
									>
										Show More Logs
									</Button>
								</Col>
							</Row>
					}
				</WrapperContainer>

			</div>
		);
	}
}

export function mapDispatchToProps(dispatch) {
	return {
		getCPItems: (limit, offset, sortBy, orderBy, searchValue, fundingItem, selectedItemType, module) => dispatch(fetchCPItems(limit, offset, sortBy, orderBy, searchValue, fundingItem, selectedItemType, module)),
		fetchNextPage: (pageNo) => dispatch(fetchNextPage(pageNo)),
		initiateListSearch: (searchValue, filterMonth, filterYear, limit, offset, module) => dispatch(runCPListSearch(searchValue, filterMonth, filterYear, limit, offset, module)),
		cancelSearch: () => dispatch(disableListSearch()),
		initiateSort: (sortBy) => dispatch(runCPListSort(sortBy)),
		disableSearchFlag: () => dispatch(disableListSearchFlag()),
		setInitialState: () => dispatch(setInitialState()),
		updateNewCPItemStatus: (newStatus, fundingItem, module) => dispatch(updateCPItemStatus(newStatus, fundingItem, module)),
	};
}

const mapStateToProps = createStructuredSelector({
	CPItems: makeSelectListCPItems(),
	loading: makeSelectCPItemListLoading(),
	limit: makeSelectCPItemListLimit(),
	offset: makeSelectCPItemListOffset(),
	fetchedCPItems: makeSelectFetchedCPItems(),
	searchFlag: makeSelectCPItemListSearch(),
	searchType: makeSelectCPItemListSearchType(),
	searchValue: makeSelectCPItemListSearchValue(),
	sortValue: makeSelectCPItemListSortValue(),
	filterMonth: makeSelectCPItemListSearchFilterMonth(),
	filterYear: makeSelectCPItemListSearchFilterYear(),
	logType: makeSelectSelectedLogType(),
});

ProfileFundingList.propTypes = {
	CPItems: T.array,
	getCPItems: T.func,
	fetchNextPage: T.func,
	updateNewCPItemStatus: T.func,
	initiateListSearch: T.func,
	cancelSearch: T.func,
	initiateSort: T.func,
	disableSearchFlag: T.func,
	setInitialState: T.func,
	searchValue: T.string,
	match: T.object,
	sortBy: T.string,
	pageTitle: T.func,
	module: T.string,
	limit: T.number,
	offset: T.number,
	searchFlag: T.bool,
	sortValue: T.string,
	filterMonth: T.string,
	filterYear: T.string,
	loading: T.bool,
	fetchedCPItems: T.array,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(ProfileFundingList);
