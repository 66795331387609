/* eslint-disable no-unused-vars */
import React, { useReducer, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import uuid from 'react-uuid';

import { Row, Col } from 'react-styled-flexboxgrid';
import T from 'prop-types';
import { DropDown, Input, Button } from '../Styles';
import { AsyncSingleSelect, ImageHolder } from '../FormComponents';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import ToastMessage from '../ToastMessage';
import {
	CURATED_SET_CONFIG_URL, SAVING_FAILURE_MESSAGE, SAVING_MESSAGE, SAVING_SUCCESS_MESSAGE,
} from '../../containers/TechSparksManager/constants';
import { generateSlug } from '../../utils/utility_function';
import { extractedCityName } from '../../utils/common';

const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const UPDATE_BLURRED_ELEMENT = 'UPDATE_BLURRED_ELEMENT';
const SET_ALL_ELEMENTS_BLURRED = 'SET_ALL_ELEMENTS_BLURRED';
const UPDATE_ERRORS = 'UPDATE_ERRORS';
const SET_FORM_MESSAGE = 'SET_FORM_MESSAGE';
const SET_FORM_DATA = 'SET_FORM_DATA';

const initialState = {
	formData: {
		id: null,
		title: null,
		slug: null,
		description: null,
		Date: new Date(),
		endTime: null,
		image: null,
	},
	blurredElements: {
		title: false,
		slug: false,
		description: false,
		date: false,
		endTime: false,
		image: false,
	},
	errors: {
		title: false,
		slug: false,
		description: false,
		date: false,
		endTime: false,
		image: false,
	},
	formMessage: null,
};

function updateFormData(changedData) {
	return {
		type: UPDATE_FORM_DATA,
		changedData,
	};
}

function updateBlurredElement(element) {
	return {
		type: UPDATE_BLURRED_ELEMENT,
		element,
	};
}

function setAllElementsBlurred() {
	return {
		type: SET_ALL_ELEMENTS_BLURRED,
	};
}

function updateErrors(errors) {
	return {
		type: UPDATE_ERRORS,
		errors,
	};
}

function setFormData(track) {
	return {
		type: SET_FORM_DATA,
		track,
	};
}

function setFormMessage(message) {
	return {
		type: SET_FORM_MESSAGE,
		message,
	};
}

function reducer(state, action) {
	switch (action.type) {
	case SET_FORM_DATA:
		return { ...state, formData: action.track };

	case UPDATE_FORM_DATA: {
		const formData = { ...state.formData };
		const { changedData } = action;
		formData[changedData.name] = changedData.value;
		return { ...state, formData };
	}

	case UPDATE_BLURRED_ELEMENT: {
		const blurredElements = { ...state.blurredElements };
		const { element } = action;
		blurredElements[element] = true;
		return { ...state, blurredElements };
	}

	case UPDATE_ERRORS:
		return { ...state, errors: action.errors };

	case SET_ALL_ELEMENTS_BLURRED: {
		const blurredElements = {
			title: true,
			slug: true,
			description: true,
			date: true,
			endTime: true,
			image: true,
		};
		return { ...state, blurredElements };
	}

	case SET_FORM_MESSAGE:
		return { ...state, formMessage: action.message };

	default:
		return state;
	}
}

const TrackForm = (props) => {
	const {
		track, getTracks, unmountModal, city,
	} = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		formData, blurredElements, errors, formMessage,
	} = state;
	const [isButtonDisabled, disableButton] = useState(false);
	const [toUpdate, setToUpdate] = useState(false);
	const [minEndTime, setMinEndTime] = useState('');
	const [maxEndTime, setMaxEndTime] = useState('');

	useEffect(() => {
		if (track && track.id) {
			dispatch(setFormData(track));
			setToUpdate(true);
		}
	}, []);

	useEffect(() => {
		getErrors();
	}, [formData, blurredElements]);

	function onInputChange(e) {
		const changedData = {};
		if (e.name === 'date' || e.name === 'endTime') {
			if (e.value && moment(e.value).format('YYYY-MM-DD') !== '1970-01-01') {
				changedData.name = e.name;
				changedData.value = e.value;
			}
		} else if (e.target?.name === 'slug') {
			changedData.name = e.target.name;
			changedData.value = generateSlug(e.target.value);
		} else {
			changedData.name = e.target.name;
			changedData.value = e.target.value;
		}
		dispatch(updateFormData(changedData));
	}

	function validateForm(track) {
		return {
			title: !track.title || track.title.length < 1,
			description: !track.description || track.description.length < 1,
			date: !track.date || track.date.length < 1,
			slug: !track.slug || track.slug.length < 1,
			image: !track.image || track.image.length < 1,
			endTime: !track.endTime || track.endTime.length < 1,
		};
	}

	function getErrors() {
		const validationErrors = validateForm(formData);
		const errors = Object.keys(validationErrors).reduce((acc, curr) => {
			if (validationErrors[curr] && blurredElements[curr]) {
				acc[curr] = true;
			} else {
				acc[curr] = false;
			}
			return acc;
		}, {});
		dispatch(updateErrors(errors));
	}

	async function onFormSubmit(e) {
		e.preventDefault();
		const validation = validateForm(formData);
		const validated = !Object.keys(validation).some((i) => validation[i]);
		if (validated) {
			await saveTrack();
		} else {
			dispatch(setAllElementsBlurred());
		}
	}

	function handleBlur(element) {
		dispatch(updateBlurredElement(element));
	}

	function getTrackBody(track) {
		const formData = new FormData();
		if (toUpdate) {
			formData.append('id', track.id);
		} else {
			const id = uuid();
			formData.append('id', id);
		}
		formData.append('title', track.title);
		formData.append('description', track.description);
		formData.append('date', track.date);
		formData.append('endTime', track.endTime);
		formData.append('slug', track.slug);
		formData.append('speakers', toUpdate ? JSON.stringify(track.speakers) : track.speakers);
		formData.append('agendas', toUpdate ? JSON.stringify(track.agendas) : track.agendas);
		formData.append('image', track.image && track.image.file ? track.image.file : JSON.stringify(track.image));
		return formData;
	}

	async function saveTrack() {
		try {
			disableButton(true);
			dispatch(setFormMessage(SAVING_MESSAGE));
			const extractCityName = extractedCityName(city);
			const payload = getTrackBody(formData);
			const url = `${CURATED_SET_CONFIG_URL}${`${city === 'mumbai' ? `${city}_`
				: city && city.includes('techsparks_2023') ? `2023_${extractCityName}` : ''}tracks`}/${toUpdate ? 'update' : 'create'}`;
			const options = {
				method: 'PUT',
				credentials: 'include',
				body: payload,
			};
			const response = await fetch(url, options);
			disableButton(false);
			if (response && response.status === 200) {
				dispatch(setFormMessage(SAVING_SUCCESS_MESSAGE));
				getTracks();
				unmountModal();
			} else {
				dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			}
		} catch (err) {
			dispatch(setFormMessage(SAVING_FAILURE_MESSAGE));
			disableButton(false);
		}
	}

	function renderFormMessage() {
		if (formMessage) {
			return (
				<ToastMessage
					toastData={formMessage}
					unmount={() => dispatch(setFormMessage(null))}
				/>
			);
		}
		return null;
	}

	async function onUpload(e) {
		e.preventDefault();
		const file = e.target.files[0];
		if (file.size / 1024 / 1024 > 5) {
			return alert(`File size greater than 5 MB (${(file.size / 1024 / 1024).toFixed(2)} MB)!`);
		}
		const reader = new FileReader();
		reader.onloadend = () => {
			dispatch(updateFormData({
				name: 'image',
				value: {
					url: reader.result,
					file,
				},
			}));
		};
		reader.readAsDataURL(file);
	}

	function onUploadClick(e) {
		e.preventDefault();
		const logoInput = document.getElementById('track-image');
		logoInput.click();
	}

	function onImageLoad(e) {
		if (formData.image.file) {
			const data = { height: e.target.naturalHeight, width: e.target.naturalWidth };
			dispatch(updateFormData({
				name: 'image',
				value: {
					...formData.image,
					...data,
				},
			}));
		}
	}

	return (
		<form onSubmit={onFormSubmit}>
			{renderFormMessage()}
			<Row>
				<Col sm={12}>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Image *
						</Col>
						<Col xs={12} sm={8}>
							<Input
								id="track-image"
								type="file"
								style={{ display: 'none' }}
								name="image"
								onChange={onUpload}
								accept="image/png, image/jpeg"
								onBlur={() => handleBlur('image')}
							/>
							<ImageHolder
								showImageUploader={onUploadClick}
								imageUrl={formData && formData.image ? formData.image.url : null}
								onImageLoad={onImageLoad}
								squareDimension="13rem"
								clearImage={() => {
									document.getElementById('track-image').value = '';
									dispatch(updateFormData({ name: 'image', value: null }));
								}}
							/>
							<ErrorMessage display={errors.image} element="Image" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Title *
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="title"
								type="text"
								className="form-control"
								placeholder="Title of track"
								onChange={onInputChange}
								value={formData.title ?? ''}
								onBlur={() => handleBlur('title')}
							/>
							<ErrorMessage display={errors.title} element="Title" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Slug *
						</Col>
						<Col sm={8} xs={12}>
							<Input
								name="slug"
								type="text"
								className="form-control"
								placeholder="Slug of track"
								onChange={onInputChange}
								value={formData.slug ?? ''}
								onBlur={() => handleBlur('slug')}
							/>
							<ErrorMessage display={errors.slug} element="Slug" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Description *
						</Col>
						<Col sm={8} xs={12}>
							<textarea
								rows="4"
								name="description"
								className="global-form-control"
								placeholder="Description of the track"
								value={formData.description ?? ''}
								onChange={onInputChange}
								onBlur={() => handleBlur('description')}
							/>
							<ErrorMessage display={errors.description} element="Description" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							Date and Time *
						</Col>
						<Col>
							<DatePicker
								className="p-1"
								name="date"
								dateFormat="dd/MM/yyyy hh:mm"
								showTimeSelect
								selected={formData.date ? new Date(formData.date) : ''}
								placeholderText="Click to select Date"
								autoComplete="off"
								onChange={(value) => {
									onInputChange({ name: 'date', value: new Date(value) });
									if (formData.endTime && (new Date(value).toLocaleDateString('en-GB') !== new Date(formData.endTime).toLocaleDateString('en-GB'))) {
										setMinEndTime(undefined);
										setMaxEndTime(undefined);
									} else {
										const date = new Date() > new Date(value) ? new Date() : new Date(value);
										const time = moment(date).tz('Asia/Calcutta').format('hh:mm').split(':');
										setMinEndTime(new Date(new Date(value).setHours(time[0], time[1], 0, 0)));
										setMaxEndTime(new Date(new Date(value).setHours(23, 59, 0, 0)));
									}
								}}
								onBlur={() => handleBlur('date')}
							/>
							<ErrorMessage display={errors.date} element="Date and Time" />
						</Col>
					</Row>
					<Row className="mb-3">
						<Col sm={4} xs={12}>
							endTime *
						</Col>
						<Col>
							<DatePicker
								className="p-1"
								name="endTime"
								dateFormat="hh:mm"
								showTimeSelect
								showTimeSelectOnly
								timeCaption="Time"
								selected={formData.endTime ? new Date(formData.endTime) : ''}
								placeholderText="Click to select end time"
								autoComplete="off"
								onChange={(value) => onInputChange({ name: 'endTime', value: new Date(value) })}
								onBlur={() => handleBlur('endTime')}
								startDate={formData.date ? new Date(formData.date) : ''}
								minTime={minEndTime}
								maxTime={maxEndTime}
							/>
							<ErrorMessage display={errors.endTime} element="End time" />
						</Col>
					</Row>
					<div className="text-right">
						<Button
							success
							type="submit"
							disabled={isButtonDisabled}
						>
							SAVE
						</Button>
					</div>
				</Col>
				{renderFormMessage()}
			</Row>
		</form>
	);
};

TrackForm.propTypes = {
	track: T.object,
	getTracks: T.func,
	unmountModal: T.func,
	city: T.string,
};

export default TrackForm;
