import { Map } from 'immutable';
import { FETCH_MARKETING_DATA, FETCH_MARKETING_DATA_SUCCESS, FETCH_MARKETING_DATA_FAILURE } from './type';

const initialState = Map({
	loading: false,
	error: false,
	marketingData: null,
});

function marketingDataReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_MARKETING_DATA:
		return state
			.set('loading', true);
	case FETCH_MARKETING_DATA_SUCCESS:
		return state
			.set('loading', false)
			.set('error', false)
			.set('marketingData', action.data);
	case FETCH_MARKETING_DATA_FAILURE:
		return state
			.set('loading', false)
			.set('error', action.error);
	default:
		return state;
	}
}

export default marketingDataReducer;
