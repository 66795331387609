import {
	EDITOR_SET_USER_ROLE,
	EDITOR_ON_TAB_CHANGE,
	FETCH_EDITOR_DATA,
	FETCH_EDITOR_DATA_SUCCESS,
	FETCH_EDITOR_DATA_FAILURE,
	EDITOR_ON_FORM_DATA_CHANGE,
	EDITOR_ON_FORM_METADATA_CHANGE,
	EDITOR_HANDLE_BLUR,
	CREATE_STORY,
	CREATE_STORY_SUCCESS,
	CREATE_STORY_FAILURE,
	SET_CONTENT_DUPLICATE,
	SET_DEFAULT_SOCIAL_EXCERPT,
	SET_DEFAULT_SOCIAL_TITLE,
	SET_DEFAULT_SOCIAL_IMAGE,
	CLEAR_EDITOR_DATA,
	EDITOR_HAS_ERRORS,
	EDITOR_SET_ALL_ELEMENTS_BLUR,
	SET_EDITOR_FORM_MESSAGE,
	EDITOR_RUN_SEO_ANALYSIS,
	EDITOR_RUN_SEO_ANALYSIS_SUCCESS,
	EDITOR_RUN_SEO_ANALYSIS_ERROR,
	EDITOR_ON_FOCUS_KEYPHRASE_CHANGE,
	SET_EDITOR_ACTION,
	UPDATE_STORY,
	UPDATE_STORY_SUCCESS,
	UPDATE_STORY_FAILURE,
	EDITOR_RUN_PLAGIARISM_CHECK,
	EDITOR_RUN_PLAGIARISM_CHECK_SUCCESS,
	EDITOR_RUN_PLAGIARISM_CHECK_FAILURE,
	SET_EDITOR_SOCKET_DATA,
	UPDATE_EDITOR_DOC_CONVERTED_DELTA,
	UPDATE_EDITOR_DOC_CONVERSION_DATA,
	SET_EDITOR_DOC_CONVERSION_DATA,
	FETCH_EDITOR_STORY_REVISIONS,
	FETCH_EDITOR_STORY_REVISIONS_SUCCESS,
	FETCH_EDITOR_STORY_REVISIONS_FAILURE,
	ADD_YOUTUBE_VIDEO,
	ADD_YOUTUBE_VIDEO_SUCCESS,
	ADD_YOUTUBE_VIDEO_FAILURE,
	REPLACE_VIDEO,
	ADD_VIDEO_FROM_GALLERY,
	AUTO_SAVE_STORY,
	AUTO_SAVE_STORY_SUCCESS,
	AUTO_SAVE_STORY_FAILURE,
	CLEAR_AUTO_SAVE_MESSAGE,
	UPDATE_SNIPPETS,
	UPDATE_WEBINAR_BASIC_INFO,
	UPDATE_WEBINAR_REGISTRATION_FORM_DATA,
	FORM_ELEMENT_ORDER_CHANGE,
	FETCH_WEBINAR_QUESTIONS,
	FETCH_WEBINAR_QUESTIONS_SUCCESS,
	CHECK_FOR_NEW_QUESTIONS,
	CHECK_FOR_NEW_QUESTIONS_SUCCESS,
	GENERATE_WEBINAR_REPORT,
	DOWNLOAD_WEBINAR_REPORT,
	REPORT_DOWNLOAD_COMPLETE,
	REPORT_GENERATE_COMPLETE,
	FETCH_LIVE_STREAM_TYPES,
	FETCH_LIVE_STREAM_TYPES_SUCCESS,
	FETCH_LIVE_STREAM_TYPES_FAILURE,
	SET_UNIQUE_SLUG,
	CLEAR_TOP_PICKS_DATA,
	SET_TOP_PICK,
	BONI_COMPOSER_CHANGE,
	BONI_COMPOSER_BRNAD_SECTION_CHANGE,
	EDITOR_ON_BRAND_CHANGE,
} from './type';

export function editorSetUserRole(editor, author) {
	return {
		type: EDITOR_SET_USER_ROLE,
		editor,
		author,
	};
}

export function editorOnTabChange(tab) {
	return {
		type: EDITOR_ON_TAB_CHANGE,
		tab,
	};
}

export function editorOnBrandChange(brand) {
	return {
		type: EDITOR_ON_BRAND_CHANGE,
		brand,
	};
}

export function fetchEditorData(brand, storyType, storyId) {
	return {
		type: FETCH_EDITOR_DATA,
		brand,
		storyType,
		storyId,
	};
}

export function fetchEditorDataSuccess({
	story, editorFormData, metadata, plagiarismData, curatedLists, snippets, webinar, registrationForm, liveStreamTypes, paywallSubtypes, currentBrand,
}) {
	return {
		type: FETCH_EDITOR_DATA_SUCCESS,
		story,
		editorFormData,
		metadata,
		plagiarismData,
		curatedLists,
		snippets,
		webinar,
		registrationForm,
		liveStreamTypes,
		paywallSubtypes,
		currentBrand,
	};
}
export function fetchEditorDataFailure(error) {
	return {
		type: FETCH_EDITOR_DATA_FAILURE,
		error,
	};
}

export function onEditorFormDataChange(changedData) {
	return {
		type: EDITOR_ON_FORM_DATA_CHANGE,
		changedData,
	};
}

export function onEditorFormMetadataChange(changedData) {
	return {
		type: EDITOR_ON_FORM_METADATA_CHANGE,
		changedData,
	};
}

export function editorHandlerBlur(element) {
	return {
		type: EDITOR_HANDLE_BLUR,
		element,
	};
}

export function createStory(brand, storyType) {
	return {
		type: CREATE_STORY,
		brand,
		storyType,
	};
}

export function createStorySuccess() {
	return {
		type: CREATE_STORY_SUCCESS,
	};
}

export function createStoryFailure(error) {
	return {
		type: CREATE_STORY_FAILURE,
		error,
	};
}

export function setContentDuplicate(value) {
	return {
		type: SET_CONTENT_DUPLICATE,
		value,
	};
}

export function setDefaultSocialExcerpt(excerpt) {
	return {
		type: SET_DEFAULT_SOCIAL_EXCERPT,
		excerpt,
	};
}

export function setDefaultSocialTitle(title) {
	return {
		type: SET_DEFAULT_SOCIAL_TITLE,
		title,
	};
}

export function setDefaultSocialImage(image) {
	return {
		type: SET_DEFAULT_SOCIAL_IMAGE,
		image,
	};
}

export function clearEditorData() {
	return {
		type: CLEAR_EDITOR_DATA,
	};
}

export function editorHasErrors(errors) {
	return {
		type: EDITOR_HAS_ERRORS,
		errors,
	};
}

export function editorSetAllElementsBlur() {
	return {
		type: EDITOR_SET_ALL_ELEMENTS_BLUR,
	};
}

export function setEditorFormMessage(message) {
	return {
		type: SET_EDITOR_FORM_MESSAGE,
		message,
	};
}

export function editorRunSeoAnalysis(storyId, content) {
	return {
		type: EDITOR_RUN_SEO_ANALYSIS,
		storyId,
		content,
	};
}

export function editorRunSeoAnalysisSuccess(analysis) {
	return {
		type: EDITOR_RUN_SEO_ANALYSIS_SUCCESS,
		analysis,
	};
}

export function editorRunSeoAnalysisError(error) {
	return {
		type: EDITOR_RUN_SEO_ANALYSIS_ERROR,
		error,
	};
}

export function editorOnFocusKeyphraseChange(keyphrase) {
	return {
		type: EDITOR_ON_FOCUS_KEYPHRASE_CHANGE,
		keyphrase,
	};
}

export function setEditorAction(value) {
	return {
		type: SET_EDITOR_ACTION,
		value,
	};
}

export function updateStory(storyId, story, status) {
	return {
		type: UPDATE_STORY,
		storyId,
		story,
		status,
	};
}

export function updateStorySuccess(successResponse, status) {
	return {
		type: UPDATE_STORY_SUCCESS,
		successResponse,
		status,
	};
}

export function updateStoryFailure(error, status) {
	return {
		type: UPDATE_STORY_FAILURE,
		error,
		status,
	};
}

export function runEditorPlagiarism(storyId, storyContent) {
	return {
		type: EDITOR_RUN_PLAGIARISM_CHECK,
		storyId,
		storyContent,
	};
}

export function runEditorPlagiarismSuccess(plagiarismData) {
	return {
		type: EDITOR_RUN_PLAGIARISM_CHECK_SUCCESS,
		plagiarismData,
	};
}

export function runEditorPlagiarismFailure(error) {
	return {
		type: EDITOR_RUN_PLAGIARISM_CHECK_FAILURE,
		error,
	};
}

export function setEditorSocketData(data) {
	return {
		type: SET_EDITOR_SOCKET_DATA,
		data,
	};
}

export function setEditorDocConversion(value) {
	return {
		type: SET_EDITOR_DOC_CONVERSION_DATA,
		value,
	};
}

export function updateEditorDocConversion(value) {
	return {
		type: UPDATE_EDITOR_DOC_CONVERSION_DATA,
		value,
	};
}

export function updateEditorDocConvertedDelta(value) {
	return {
		type: UPDATE_EDITOR_DOC_CONVERTED_DELTA,
		value,
	};
}

export function fetchEditorStoryRevisions(storyId) {
	return {
		type: FETCH_EDITOR_STORY_REVISIONS,
		storyId,
	};
}

export function fetchEditorStoryRevisionsSuccess(revisions) {
	return {
		type: FETCH_EDITOR_STORY_REVISIONS_SUCCESS,
		revisions,
	};
}

export function fetchEditorStoryRevisionsFailure(error) {
	return {
		type: FETCH_EDITOR_STORY_REVISIONS_FAILURE,
		error,
	};
}

export function addYoutubeVideo(youtubeData) {
	return {
		type: ADD_YOUTUBE_VIDEO,
		youtubeData,
	};
}

export function addYoutubeVideoSuccess(asset) {
	return {
		type: ADD_YOUTUBE_VIDEO_SUCCESS,
		asset,
	};
}

export function addYoutubeVideoFailure(error) {
	return {
		type: ADD_YOUTUBE_VIDEO_FAILURE,
		error,
	};
}

export function replaceVideo() {
	return {
		type: REPLACE_VIDEO,
	};
}

export function addVideoFromGallery(videoData) {
	return {
		type: ADD_VIDEO_FROM_GALLERY,
		videoData,
	};
}

export function autoSaveStory(storyId, story) {
	return {
		type: AUTO_SAVE_STORY,
		storyId,
		story,
	};
}

export function autoSaveStorySuccess(successResponse) {
	return {
		type: AUTO_SAVE_STORY_SUCCESS,
		successResponse,
	};
}

export function autoSaveStoryFailure(error) {
	return {
		type: AUTO_SAVE_STORY_FAILURE,
		error,
	};
}

export function clearAutoSaveMessage() {
	return {
		type: CLEAR_AUTO_SAVE_MESSAGE,
	};
}

export function updateSnippetData(snippetType, changedData, index) {
	return {
		type: UPDATE_SNIPPETS,
		snippetType,
		changedData,
		index,
	};
}

export function updateWebinarBasicInfo(changedData) {
	return {
		type: UPDATE_WEBINAR_BASIC_INFO,
		changedData,
	};
}

export function updateRegistrationFormData(changedData, added) {
	return {
		type: UPDATE_WEBINAR_REGISTRATION_FORM_DATA,
		changedData,
		added,
	};
}

export function changeOrder(curr, final) {
	return {
		type: FORM_ELEMENT_ORDER_CHANGE,
		curr,
		final,
	};
}

export function fetchQuestions(webinarId, limit, offset, reset) {
	return {
		type: FETCH_WEBINAR_QUESTIONS,
		webinarId,
		limit,
		offset,
		reset,
	};
}

export function fetchQuestionsSuccess({
	questions, offset, reset, questionsCount,
}) {
	return {
		type: FETCH_WEBINAR_QUESTIONS_SUCCESS,
		questionsCount,
		questions,
		offset,
		reset,
	};
}

export function checkForQuestions(webinarId, latestDate) {
	return {
		type: CHECK_FOR_NEW_QUESTIONS,
		webinarId,
		latestDate,
	};
}

export function checkForQuestionsSuccess(count) {
	return {
		type: CHECK_FOR_NEW_QUESTIONS_SUCCESS,
		count,
	};
}

export function generateWebinarReport(webinarId, storyId) {
	return {
		type: GENERATE_WEBINAR_REPORT,
		webinarId,
		storyId,
	};
}

export function downloadWebinarReport(webinarId, fileName) {
	return {
		type: DOWNLOAD_WEBINAR_REPORT,
		webinarId,
		fileName,
	};
}

export function generateWebinarReportComplete(message, report, error) {
	return {
		type: REPORT_GENERATE_COMPLETE,
		message,
		report,
		error,
	};
}

export function downloadWebinarReportComplete(message, report, error) {
	return {
		type: REPORT_DOWNLOAD_COMPLETE,
		message,
		report,
		error,
	};
}

export function getLiveStreamTypes(id, startTime, endTime) {
	return {
		type: FETCH_LIVE_STREAM_TYPES,
		id,
		startTime,
		endTime,
	};
}

export function getLiveStreamTypesSuccess(streamTypes) {
	return {
		type: FETCH_LIVE_STREAM_TYPES_SUCCESS,
		streamTypes,
	};
}

export function getLiveStreamTypesFailure(error) {
	return {
		type: FETCH_LIVE_STREAM_TYPES_FAILURE,
		error,
	};
}

export function setSlugUnique(value) {
	return {
		type: SET_UNIQUE_SLUG,
		value,
	};
}

export function clearTopPicksData() {
	return {
		type: CLEAR_TOP_PICKS_DATA,
	};
}

export function setTopPicks(value) {
	return {
		type: SET_TOP_PICK,
		value,
	};
}

export function onBoniComposerChange(changedData) {
	return {
		type: BONI_COMPOSER_CHANGE,
		changedData,
	};
}

export function onBoniComposerBrnadSectionChange(changedData) {
	return {
		type: BONI_COMPOSER_BRNAD_SECTION_CHANGE,
		changedData,
	};
}
