import { Map } from 'immutable';
import {
	LOADING_STATE, LOADED_STATE, SET_ERROR_STATE, SET_RESPONSE_ERROR_STATE, SET_CURATED_DATA, SET_SELECTED_BRAND_VALUE, SET_CURATED_SET_LIST, OPEN_MODAL, CLOSE_MODAL, SET_STORY_SEARCH_TITLE, SET_STORY_SEARCH_RESULTS, SET_MODAL_LOADING, SET_MODAL_LOADED, DISCARD_CHANGES, SET_STORY_SEARCH_OFFSET, SET_END_OF_STORY_SEARCH_LIST, SET_STORY_SEARCH_SELECT, SET_SAVING, SET_SAVING_RESPONSE, CLEAR_STORY_SEARCH_DATA, RESET_PAGE,
} from './type';

const initialState = Map({
	loading: null,
	saving: null,
	error: false,
	errorResponse: false,
	message: '',
	savingResponseType: '',
	savingMessage: '',
	brandList: null,
	selectedBrandValue: {},
	curatedSetList: null,
	duplicateCuratedSetList: null,
	openModalFor: '',
	modalLoading: null,
	storySearchTitle: '',
	storySearchResults: null,
	storySearchOffset: null,
	storySearchSelect: null,
	endOfStorySearchList: false,
});

function featuredSetReducer(state = initialState, action) {
	switch (action.type) {
	case LOADING_STATE: return state.set('loading', true).set('error', false).set('errorResponse', false);
	case LOADED_STATE: return state.set('loading', false);
	case SET_ERROR_STATE: return state.set('error', true).set('message', action.message);
	case SET_RESPONSE_ERROR_STATE: return state.set('errorResponse', true).set('message', action.message);
	case SET_CURATED_DATA: {
		const curList = state.get('curatedSetList') ? state.get('curatedSetList') : [];
		return state.set('curatedSetList', [...curList, ...action.curatedSetList]).set('duplicateCuratedSetList', [...curList, ...action.curatedSetList]);
	}
	case SET_CURATED_SET_LIST: return state.set('curatedSetList', action.curatedSetList);
	case SET_SELECTED_BRAND_VALUE: {
		const { brandList } = action;
		let selectedBrandValue = brandList.find((brandObj) => (brandObj.value === action.selectedBrandValue));
		if (!selectedBrandValue) {
			selectedBrandValue = {};
		}
		return state.set('selectedBrandValue', selectedBrandValue).set('savingResponseType', '').set('savingMessage', '').set('storySearchTitle', '')
			.set('storySearchResults', null)
			.set('storySearchSelect', null)
			.set('storySearchOffset', null)
			.set('endOfStorySearchList', false)
			.set('modalLoading', null);
	}
	case CLEAR_STORY_SEARCH_DATA: {
		return state.set('storySearchTitle', '').set('storySearchResults', null).set('storySearchSelect', null).set('storySearchOffset', null)
			.set('endOfStorySearchList', false)
			.set('modalLoading', null);
	}
	case OPEN_MODAL: return state.set('openModalFor', action.openModalFor);
	case CLOSE_MODAL: return state.set('openModalFor', '');
	case SET_STORY_SEARCH_TITLE: return state.set('storySearchTitle', action.title);
	case SET_STORY_SEARCH_RESULTS: return state.set('storySearchResults', action.storySearchResults);
	case SET_MODAL_LOADING: return state.set('modalLoading', true);
	case SET_MODAL_LOADED: return state.set('modalLoading', false);
	case DISCARD_CHANGES: return state.set('curatedSetList', state.get('duplicateCuratedSetList'));
	case SET_STORY_SEARCH_OFFSET: {
		if (typeof action.offset === 'number') {
			return state.set('storySearchOffset', action.offset);
		}
		return state;
	}
	case SET_END_OF_STORY_SEARCH_LIST: return state.set('endOfStorySearchList', action.value);
	case SET_STORY_SEARCH_SELECT: return state.set('storySearchSelect', action.position);
	case SET_SAVING: return state.set('saving', action.status);
	case SET_SAVING_RESPONSE: return state.set('savingResponseType', action.messageType).set('savingMessage', action.message);
	case RESET_PAGE: return initialState;
	default: return state;
	}
}

export default featuredSetReducer;
