import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import appReducer from '../containers/App/reducer';
import dashboardReducer from '../containers/Dashboard/reducer';
import storyListReducer from '../containers/StoryList/reducer';
import languageProviderReducer from '../containers/LanguageProvider/reducer';
import categoryReducer from '../containers/CategoryManager/reducer';
import tagReducer from '../containers/TagManager/reducer';
import brandReducer from '../containers/BrandManager/reducer';
import dynamicConfigReducer from '../containers/ConfigManager/reducer';
import fileUploadReducer from '../containers/UploadPage/reducer';
import featuredSetReducer from '../containers/FeaturedSet/reducer';
import deskReducer from '../containers/StoryDesk/reducer';
import storyPreviewReducer from '../containers/StoryPreview/reducer';
import redirectionReducer from '../containers/RedirectionEngine/reducer';
import brandSpotlightReducer from '../containers/BrandSpotlightManager/reducer';
import messengerPromotionFormReducer from '../containers/MessengerPromotionForm/reducer';
import messengerPromotionsManagerReducer from '../containers/MessengerPromotionsManager/reducer';
import notificationsReducer from '../containers/NotificationsManager/reducer';
import rssFeedCreatorReducer from '../containers/RssFeedCreator/reducer';
import rssFeedContentReducer from '../containers/WatchlistedStories/reducer';
import storyVersionsReducer from '../containers/StoryVersions/reducer';
import marketingDataReducer from '../containers/MarketingData/reducer';
import activityStreamReducer from '../containers/ActivityStream/reducer';
import seriesManagerReducer from '../containers/SeriesManager/reducer';
import seriesCreateReducer from '../containers/SeriesCreate/reducer';
import mediaGalleryReducer from '../containers/MediaManagerGallery/reducer';
import mediaEditorReducer from '../containers/MediaManagerEditor/reducer';
import editorMediaGalleryReducer from '../components/EditorMediaGallery/utils/reducer';
import editorImageHandlerReducer from '../components/EditorImageHandler/utils/reducer';
import editorReducer from '../containers/EditorPage/reducer';
import verticalReducer from '../containers/VerticalManager/reducer';
import companyEditorReducer from '../containers/CompanyEditor/reducer';
import awardReducer from '../containers/AwardManager/reducer';
import peopleReducer from '../containers/PeopleManager/reducer';
import cityReducer from '../containers/CityManager/reducer';
import profileDeskReducer from '../containers/ProfileDesk/reducer';
import ProfileListReducer from '../containers/ProfileList/reducer';
import tagStoriesReducer from '../containers/TagStories/reducer';
import multipleStoriesTaggerReducer from '../containers/MultipleStoriesTagger/reducer';
import profileClaimDeskReducer from '../containers/ProfileClaimDesk/reducer';
import customFieldsReducer from '../containers/CustomFields/reducer';
import userReducer from '../containers/UserManager/reducer';
import createAliasReducer from '../containers/CreateAliasPage/reducer';
import updateAliasReducer from '../containers/UpdateAliasPage/reducer';
import inviteUserReducer from '../containers/InviteUser/reducer';
import companyConnectReducer from '../containers/CompanyConnectDesk/reducer';
import jobEditorReducer from '../containers/JobEditor/reducer';
import jobsListReducer from '../containers/JobsList/reducer';
import eventEditorReducer from '../containers/EventEditor/reducer';
import ProfileFundingListReducer from '../containers/FundingList/reducer';
import orgChartEditorReducer from '../containers/OrgChartEditor/reducer';
import CMSPageCurationSetReducer from '../containers/CMSPageCurationSet/reducer';

const rootReducer = (history) => combineReducers({
	router: connectRouter(history),
	app: appReducer,
	dashboard: dashboardReducer,
	storyList: storyListReducer,
	language: languageProviderReducer,
	category: categoryReducer,
	tag: tagReducer,
	brand: brandReducer,
	dynamicConfig: dynamicConfigReducer,
	fileUpload: fileUploadReducer,
	featuredSet: featuredSetReducer,
	desk: deskReducer,
	storyPreview: storyPreviewReducer,
	redirection: redirectionReducer,
	brandSpotlight: brandSpotlightReducer,
	messengerPromotionForm: messengerPromotionFormReducer,
	messengerPromotionsManager: messengerPromotionsManagerReducer,
	notifications: notificationsReducer,
	rssFeedCreator: rssFeedCreatorReducer,
	rssFeedContent: rssFeedContentReducer,
	storyVersions: storyVersionsReducer,
	marketingData: marketingDataReducer,
	activityStream: activityStreamReducer,
	seriesManager: seriesManagerReducer,
	seriesCreate: seriesCreateReducer,
	mediaGallery: mediaGalleryReducer,
	mediaEditor: mediaEditorReducer,
	editorMediaGallery: editorMediaGalleryReducer,
	editorImageHandler: editorImageHandlerReducer,
	editor: editorReducer,
	vertical: verticalReducer,
	companyEditor: companyEditorReducer,
	award: awardReducer,
	people: peopleReducer,
	city: cityReducer,
	profileDesk: profileDeskReducer,
	profileList: ProfileListReducer,
	tagStories: tagStoriesReducer,
	multipleStoriesTagger: multipleStoriesTaggerReducer,
	profileClaimDesk: profileClaimDeskReducer,
	customFields: customFieldsReducer,
	user: userReducer,
	createAlias: createAliasReducer,
	updateAlias: updateAliasReducer,
	inviteUser: inviteUserReducer,
	companyConnectDesk: companyConnectReducer,
	jobEditor: jobEditorReducer,
	jobsList: jobsListReducer,
	eventEditor: eventEditorReducer,
	fundingList: ProfileFundingListReducer,
	orgChartEditor: orgChartEditorReducer,
	cmsPageCurations: CMSPageCurationSetReducer,
});

export default rootReducer;
