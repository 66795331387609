import React from 'react';

const WifiSlashIcon = () => (<i title="Offline">
	<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<line fill="none" stroke="#FF0000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" x1="1" x2="23" y1="1" y2="23" />
		<path d="M16.72,11.06A10.94,10.94,0,0,1,19,12.55" fill="none" stroke="#FF0000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
		<path d="M5,12.55a10.94,10.94,0,0,1,5.17-2.39" fill="none" stroke="#FF0000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
		<path d="M10.71,5.05A16,16,0,0,1,22.58,9" fill="none" stroke="#FF0000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
		<path d="M1.42,9a15.91,15.91,0,0,1,4.7-2.88" fill="none" stroke="#FF0000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
		<path d="M8.53,16.11a6,6,0,0,1,6.95,0" fill="none" stroke="#FF0000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
		<line fill="none" stroke="#FF0000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" x1="12" x2="12" y1="20" y2="20" />
	</svg>
</i>
);

export default WifiSlashIcon;
