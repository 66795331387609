export const GET_LATEST_ACTIVITY_STREAM = 'GET_LATEST_ACTIVITY_STREAM';
export const GET_LATEST_ACTIVITY_STREAM_ERROR = 'GET_LATEST_ACTIVITY_STREAM_ERROR';
export const GET_LATEST_ACTIVITY_STREAM_SUCCESS = 'GET_LATEST_ACTIVITY_STREAM_SUCCESS';

export const GET_ALL_ACTIVITY_STREAM = 'GET_ALL_ACTIVITY_STREAM';
export const GET_ALL_ACTIVITY_STREAM_ERROR = 'GET_ALL_ACTIVITY_STREAM_ERROR';
export const GET_ALL_ACTIVITY_STREAM_SUCCESS = 'GET_ALL_ACTIVITY_STREAM_SUCCESS';

export const LOAD_ALL_BRANDS = 'LOAD_ALL_BRANDS';
export const LOAD_ALL_BRANDS_ERROR = 'LOAD_ALL_BRANDS_ERROR';
export const LOAD_ALL_BRANDS_SUCCESS = 'LOAD_ALL_BRANDS_SUCCESS';

export const SELECT_BRAND = 'SELECT_BRAND';
