export const FETCH_ALL_FEED = 'FETCH_ALL_FEED';
export const FETCH_ALL_FEED_SUCCESS = 'FETCH_ALL_FEED_SUCCESS';
export const FETCH_ALL_FEED_ERROR = 'FETCH_ALL_FEED_ERROR';

export const CREATE_FEED = 'CREATE_FEED';
export const CREATE_FEED_SUCCESS = 'CREATE_FEED_SUCCESS';
export const CREATE_FEED_ERROR = 'CREATE_FEED_ERROR';

export const UPDATE_FEED = 'UPDATED_FEED';
export const UPDATE_FEED_SUCCESS = 'UPDATE_FEED_SUCCESS';
export const UPDATE_FEED_ERROR = 'UPDATE_FEED_ERROR';

export const DELETE_FEED = 'DELETE_FEED';
export const DELETE_FEED_SUCCESS = 'DELETE_FEED_SUCCESS';
export const DELETE_FEED_ERROR = 'DELETE_FEED_ERROR';

export const UNMOUNT_FEED_FORM_MESSAGE = 'UNMOUNT_FEED_FORM_MESSAGE';
export const ON_FEED_SELECTED = 'ON_FEED_SELECTED';
export const ADD_NEW_FEED = 'ADD_NEW_FEED';
export const FEED_ELEMENT_ON_CHANGE = 'FEED_ELEMENT_ON_CHANGE';
