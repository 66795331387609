import { Map } from 'immutable';
import {
	CREATE_JOB,
	UPDATE_JOB,
	HANDLE_BLUR,
	SET_ALL_ELEMENTS_BLUR,
	UPDATE_JOB_FROM_DATA,
	FETCH_JOB_DATA_SUCCESS,
	UPDATE_FORM_MESSAGE,
	CREATE_JOB_FAILURE,
	CREATE_JOB_SUCCESS,
	UPDATE_JOB_SUCCESS,
	UPDATE_JOB_FAILURE,
	SHOW_CONFIRMATION_MODAL,
	JOB_EDITOR_HAS_ERRORS,
	UPDATE_JOB_EDITOR_DOC_CONVERSION_DATA,
} from './type';
import {
	CREATE_MESSAGE,
	CREATE_SUCCESS_MESSAGE,
	CREATE_FAILURE_MESSAGE,
	UPDATE_MESSAGE,
	UPDATE_SUCCESS_MESSAGE,
	UPDATE_FAILURE_MESSAGE,
} from './constants';
import { formatDataForState } from '../EditorPage/functions';

const initialState = Map({
	formData: {
		title: '',
		location: '',
		email_to: '',
		active: false,
		details: '',
		updatedBy: null,
		postJson: null,
	},
	formTouched: {
		title: false,
		location: false,
		email_to: false,
		details: false,
	},
	hasErrors: {
		title: false,
		location: false,
		email_to: false,
		details: false,
	},
	formMessage: null,
	showConfirmationModal: false,
});

function jobEditorReducer(state = initialState, action) {
	switch (action.type) {
	case CREATE_JOB:
		return state
			.set('formMessage', CREATE_MESSAGE);

	case UPDATE_JOB:
		return state
			.set('formMessage', UPDATE_MESSAGE);

	case UPDATE_JOB_FROM_DATA: {
		const { changedData } = action;
		const formData = { ...state.get('formData') };
		formData[changedData.name] = changedData.value;
		return state
			.set('formData', formData);
	}

	case FETCH_JOB_DATA_SUCCESS:
		return state
			.set('formData', action.job);

	case CREATE_JOB_SUCCESS:
		return state
			.set('formMessage', CREATE_SUCCESS_MESSAGE)
			.set('showConfirmationModal', false);

	case UPDATE_JOB_SUCCESS:
		return state
			.set('formMessage', UPDATE_SUCCESS_MESSAGE)
			.set('showConfirmationModal', false);

	case CREATE_JOB_FAILURE:
		return state
			.set('formMessage', CREATE_FAILURE_MESSAGE)
			.set('showConfirmationModal', false);

	case UPDATE_JOB_FAILURE:
		return state
			.set('formMessage', UPDATE_FAILURE_MESSAGE)
			.set('showConfirmationModal', false);

	case UPDATE_FORM_MESSAGE:
		return state
			.set('formMessage', action.message);

	case SHOW_CONFIRMATION_MODAL:
		return state
			.set('showConfirmationModal', action.value);

	case HANDLE_BLUR: {
		const touched = { ...state.get('formTouched') };
		touched[action.element] = true;
		return state
			.set('formTouched', touched);
	}

	case SET_ALL_ELEMENTS_BLUR: {
		const touched = {
			title: true,
			location: true,
			email_to: true,
			details: true,
		};
		return state
			.set('formTouched', touched);
	}

	case JOB_EDITOR_HAS_ERRORS:
		return state
			.set('hasErrors', action.errors);

	case UPDATE_JOB_EDITOR_DOC_CONVERSION_DATA: {
		return state
			.set('docConversion', formatDataForState(state.get('docConversion'), action.value));
	}

	default:
		return state;
	}
}

export default jobEditorReducer;
