const React = require('react');
const { QuillDeltaToHtmlConverter } = require('quill-delta-to-html');
const bcrypt = require('bcrypt-nodejs');
const { clone, merge } = require('lodash');
const ImageBlot = require('../components/EditorComponents/QuillEditor/Blots/ImageBlot');

function trimAnyCharacter(s, c) {
	let ch = c;
	if (ch === ']') ch = '\\]';
	if (ch === '\\') ch = '\\\\';
	return s.replace(new RegExp(
		`^[${ch}]+|[${ch}]+$`, 'g',
	), '');
}

function extractSlugFromUrl(url) {
	let urlToBeModified = url;
	const n = urlToBeModified.indexOf('?');
	urlToBeModified = urlToBeModified.substring(0, n !== -1 ? n : urlToBeModified.length);
	urlToBeModified = trimAnyCharacter(urlToBeModified, '/');
	return urlToBeModified.split('/')[urlToBeModified.split('/').length - 1].replace(/\//g, '');
}

function hashSecret(SECRET_KEY) {
	return bcrypt.hashSync(SECRET_KEY, bcrypt.genSaltSync(8), null);
}

function compareHash(SECRET_KEY, receivedHash) {
	return bcrypt.compareSync(SECRET_KEY, receivedHash);
}

function isAboveAuthor(user) {
	let isAbove = false;

	if (((typeof user) === 'object') && ('roles' in user) && ((typeof user.roles) === 'object') && ('brand' in user.roles) && ((typeof user.roles.brand) === 'object') && (Object.keys(user.roles.brand).length > 0)) {
		Object.keys(user.roles.brand).forEach((key) => {
			if (user.roles.brand[key] > 1) {
				isAbove = true;
			}
		});
	}
	return isAbove;
}

function checkNested(obj, ...args) {
	let copyObj = obj;

	for (let i = 0; i < args.length; i += 1) {
		if (!copyObj || !(args[i] in copyObj)) {
			return false;
		}
		copyObj = copyObj[args[i]];
	}
	return true;
}

function checkType(value, valueType) {
	if (value === null) {
		if (valueType === 'null') {
			return true;
		}
		return false;
	}

	if (value === undefined) {
		if (valueType === 'undefined') {
			return true;
		}
		return false;
	}

	if ((!value) || ((typeof value) !== valueType)) {
		return false;
	}
	return true;
}

function checkNestedAndReturnValue(obj, ...args) {
	let copyObj = obj;
	let value = null;

	for (let i = 0; i < args.length; i += 1) {
		if (!copyObj || !(args[i] in copyObj)) {
			return null;
		}
		copyObj = copyObj[args[i]];

		if (i === (args.length - 1)) {
			value = copyObj;
		}
	}
	return value;
}

function checkNestedAndType(obj, ...args) {
	let copyObj = obj;
	let value = null;

	for (let i = 0; i < (args.length - 1); i += 1) {
		if (!copyObj || !(args[i] in copyObj)) {
			return null;
		}
		copyObj = copyObj[args[i]];

		if (i === (args.length - 2)) {
			value = (checkType(copyObj, (args[args.length - 1])));
		}
	}
	return value;
}

function checkIfArray(value, length = 0) {
	if (value && checkType(value, 'object') && ('length' in value) && (value.length >= length)) {
		return true;
	}
	return false;
}

function urlBelongsTo(url, stringValue) {
	if (checkType(url, 'string')) {
		return url.includes(stringValue);
	}
	return false;
}

function generateObjectArray(valueList) {
	return valueList.map((value, idx) => ({ key: idx, label: value.toUpperCase(), value }));
}

// Pick only the passed keys, and update accordingly
const formatDataForState = (state, value) => {
	let stateCopy = clone(state, true);
	if (!stateCopy) {
		stateCopy = {};
	}
	if (value) {
		stateCopy = addData(stateCopy, value);
		return stateCopy;
	}
	return stateCopy;
};

const addData = (stateCopy, valueCopy) => {
	if (valueCopy && valueCopy.compose && valueCopy.compose.curatedTags) {
		if (stateCopy && stateCopy.compose) delete stateCopy.compose.curatedTags;
	}
	if (valueCopy && valueCopy.finalize && valueCopy.finalize.brandSpotlightSponsors) {
		if (stateCopy && stateCopy.finalize) delete stateCopy.finalize.brandSpotlightSponsors;
	}
	if (valueCopy && valueCopy.searchModal && valueCopy.searchModal.brandSpotlightSponsors) {
		if (stateCopy && stateCopy.searchModal) delete stateCopy.searchModal.listHash;
	}
	return merge(stateCopy, valueCopy);
};

function timeNowInIndianStandard(dateISO) {
	const date = new Date(Date.parse(dateISO));
	// convert to milliseconds, add local time zone offset and get UTC time in milliseconds
	const utcTime = date.getTime() + (date.getTimezoneOffset() * 60000);
	// time offset for New Delhi is +5.5
	const timeOffset = 5.5;
	// create new Date object for a different timezone using supplied its GMT offset.
	const NewDelhiTime = new Date(utcTime + (3600000 * timeOffset));
	return NewDelhiTime.toString().split('GMT')[0];
}

const caplitalizeFirstChar = (name) => {
	const first = name[0].toUpperCase();
	const rest = name.substr(1, name.length);
	return first + rest;
};

function uploadData(url, type, options = {}, data = {}) {
	try {
		if (type === 'FORM') {
			const formData = new FormData();
			Object.keys(data).forEach((key) => {
				formData.append(key, data[key]);
			});
			options.body = formData;
		} else if (type === 'POST') {
			options.body = JSON.stringify(data);
		}

		let statusCode = null;
		return fetch(url, options)
			.then((response) => {
				statusCode = response.status;
				return response.json();
			})
			.then((responseJson) => {
				if (statusCode === 200) {
					return { type: 'SUCCESS', message: responseJson.message, response: responseJson };
				}
				const message = checkNested(responseJson, 'err', 'message') ? responseJson.err.message : null;
				return { type: 'FAIL', message, response: null };
			});
	} catch (err) {
		console.log('ERROR UPLOADING DATA', err);
		return Promise.resolve({ type: 'ERROR', message: err.message });
	}
}

function XMLHttpsUpload(url, data) {
	try {
		const progressEvent = (evt) => {
			const percentComplete = Math.floor((evt.loaded / evt.total) * 100);
			console.log('PROGRESS', percentComplete);
		};

		const handleEvent = (evt) => {
			console.log('Event', evt);
		};

		return new Promise((resolve) => {
			const xhr = new XMLHttpRequest();
			xhr.upload.onprogress = progressEvent;
			xhr.addEventListener('abort', handleEvent);
			xhr.addEventListener('error', handleEvent);

			const formData = wrapInFormData(data);

			xhr.open('POST', url, true);
			xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

			xhr.onreadystatechange = function () {
				if (xhr.readyState === 4) {
					const response = JSON.parse(xhr.response);
					if (xhr.status === 200) {
						return resolve({ type: 'SUCCESS', data: response, message: response.message });
					}
					return resolve({ type: 'FAIL', data: response, message: response.error.message });
				}
			};

			xhr.send(formData);
		});
	} catch (err) {
		console.log('ERROR', err);
		return Promise.resolve({ type: 'ERROR', message: err.message });
	}
}

function wrapInFormData(obj = {}) {
	const newFormData = new FormData();
	Object.keys(obj).forEach((key) => {
		newFormData.append(key, obj[key]);
	});
	return newFormData;
}

function convertDocDeltaToBlotDelta(delta) {
	const converter = new QuillDeltaToHtmlConverter(delta);
	converter.renderCustomWith((customOp) => {
		if (customOp.insert.type === 'image') {
			const val = customOp.insert.value;
			return ImageBlot.create({ url: val.url, alt: val.alt, caption: val.caption });
		}
		return <p>
			Invalid image
		</p>;
	});

	const html = converter.convert();
	return html;
}

function startUrlDownload(url, newWindow) {
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', true);
	if (newWindow) {
		link.setAttribute('target', '_blank');
	}
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

function validPhoneNumberCheck(value) {
	if (!value) {
		return true;
	}

	const regex = /^(?=.*[0-9])[- +()0-9]+$/;
	if (regex.test(value)) {
		return true;
	}
	return false;
}

function isValidEmailAddress(emailAddress) {
	const pattern = new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i);
	return pattern.test(emailAddress);
}

const generateSlug = (name) => name.toLowerCase().replace(/[^a-zA-Z0-9 -]/g, '').replace(/ /g, '-');

export {
	convertDocDeltaToBlotDelta,
	XMLHttpsUpload,
	wrapInFormData,
	uploadData,
	caplitalizeFirstChar,
	timeNowInIndianStandard,
	checkNestedAndType,
	checkNestedAndReturnValue,
	formatDataForState,
	generateObjectArray,
	urlBelongsTo,
	checkNested,
	checkType,
	checkIfArray,
	isAboveAuthor,
	hashSecret,
	compareHash,
	trimAnyCharacter,
	extractSlugFromUrl,
	startUrlDownload,
	validPhoneNumberCheck,
	isValidEmailAddress,
	generateSlug,
};
