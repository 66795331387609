export const TAG_API_BASE_URL = '/api/v2/rw/tags';
export const TAG_API_BASE_URL_UNPROTECTED = '/api/v2/tag';
export const STORY_METADATA_BASE_URL = '/api/v2/rw/storymetadata';
export const TAG_REDIRECTION_URL = '/tag/';
export const BASE_URL = process.env.REACT_APP_SERVICE_PWA;
export const REDIRECTION_BASE_API_URL = '/api/v2/rw/redirections';

export const TAG_LOADING_MESSAGE = { intent: 'info', message: 'Loading...' };
export const TAG_CREATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Tag Saved Successfully' };
export const TAG_CREATE_LOAD_MESSAGE = { intent: 'info', message: 'Saving...' };
export const TAG_UPDATE_SUCCESS_MESSAGE = { intent: 'success', message: 'Tag Updated Successfully' };
export const TAG_UPDATE_LOAD_MESSAGE = { intent: 'info', message: 'Updating...' };
export const TAG_DELETE_SUCCESS_MESSAGE = { intent: 'success', message: 'Tag Deleted Successfully' };
export const TAG_DELETE_LOAD_MESSAGE = { intent: 'info', message: 'Deleting...' };
export const TAG_ERROR_MESSAGE = { intent: 'danger', message: 'ERROR!!!' };
export const TAG_REPLACE_SUCCESS_MESSAGE = { intent: 'success', message: 'Tag replaced successfully' };
export const TAG_REPLACE_LOAD_MESSAGE = { intent: 'info', message: 'Processing...' };
export const TAG_REDIRECTION_ERROR = { intent: 'danger', message: 'Tag redirection unsuccessful' };
