export const COMPANY_API_BASE_URL = '/api/v2/rw/companies';
export const COMPANY_PEOPLE_API_BASE_URL = '/api/v2/rw/company_people';
export const COMPANY_LOCATIONS_API_BASE_URL = '/api/v2/rw/company_locations';
export const COMPANY_PRODUCTS_API_BASE_URL = '/api/v2/rw/company_products';
export const ACQUISITIONS_API_BASE_URL = '/api/v2/rw/acquisitions';
export const COMPANY_UPDATES_API_BASE_URL = '/api/v2/rw/company-updates';
export const COMPANY_FINANCE_API_BASE_URL = '/api/v2/rw/company-finance';
export const FUNDING_API_BASE_URL = '/api/v2/rw/funding';
export const FUNDING_METADATA_API_BASE_URL = '/api/v2/rw/funding-metadata';
export const COMPANY_MILESTONES_API_BASE_URL = '/api/v2/rw/milestones';
export const COMPANY_AUDIENCE_API_BASE_URL = '/api/v2/rw/company_audience';
export const CURATED_LIST_API_BASE_URL = '/api/v2/rw/curated_list';
export const COMPANY_OFFER_API_BASE_URL = '/api/v2/rw/company_offers';

export const TABS_NAME = [
	{
		name: 'Basic Information',
		value: 'basic',
	},
	{
		name: 'Funding',
		value: 'funding',
	},
	{
		name: 'Acquisitions',
		value: 'acquisitions',
	},
	{
		name: 'Finance',
		value: 'finance',
	},
	{
		name: 'People',
		value: 'people',
	},
	{
		name: 'Products',
		value: 'products',
	},
	{
		name: 'Locations',
		value: 'locations',
	},
	{
		name: 'Milestones',
		value: 'milestones',
	},
	{
		name: 'Audience',
		value: 'audience',
	},
];

export const SAVING_MESSAGE = { intent: 'info', message: 'Saving data...' };
export const SAVING_SUCCESS_MESSAGE = { intent: 'success', message: 'Data Saved Successfully' };
export const SAVING_FAILURE_MESSAGE = { intent: 'danger', message: 'Data Save Failed. Something went wrong. Try Again!' };
